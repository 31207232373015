import { cva, cx } from "class-variance-authority";
import React, {
  forwardRef,
  type InputHTMLAttributes,
  type ReactNode,
  type Ref,
} from "react";
import { extendTailwindMerge } from "tailwind-merge";

import { Typography } from "../foundation";
import { useId } from "../foundation/base";

const twMerge = extendTailwindMerge({ prefix: "dali-" });

export interface ToggleProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "size"> {
  /**
   * Sets the controlled state of the toggle
   */
  checked?: boolean;
  /**
   * Optionally, a unique ID string used by a form
   */
  id?: string;
  /**
   * A mandatory label for the toggle
   */
  label: ReactNode;
  /**
   * A mandatory value for the toggle
   */
  value: string | number;
  /**
   * The size variant of the toggle
   */
  size?: "sm" | "lg";
}

const toggleStyles = cva(
  [
    // track styles
    "dali-relative dali-transition-colors dali-rounded-full group-focus-within:dali-ring-2",
    "group-focus-within:dali-ring-offset-2 group-focus-within:dali-ring-base-black dali-transition-all",
    // track handle styles
    "before:dali-absolute before:dali-bg-base-white before:dali-aspect-square before:dali-rounded-full",
    "before:dali-top-0.5 before:dali-left-0.5 before:dali-transition-all",
  ],
  {
    variants: {
      checked: {
        true: "dali-bg-malachite-700 before:dali-translate-x-full",
        false: "dali-bg-base-black",
      },
      size: {
        sm: "dali-h-4 dali-w-[29px] before:dali-h-3",
        lg: "dali-h-6 dali-w-11 before:dali-h-5",
      },
      disabled: {
        true: "dali-bg-carbon-300",
        false: "",
      },
    },
    compoundVariants: [],
  },
);

export const Toggle = forwardRef(
  (props: ToggleProps, ref: Ref<HTMLInputElement>) => {
    const { checked, disabled, label, size = "lg", ...rest } = props;
    const { className: hash, ...pass } = rest;

    const idToUse = useId("toggle", props);
    const labelId = `${idToUse}-label`;

    return (
      <div
        className={cx("dali-flex dali-items-center dali-gap-2 dali-group", {
          "dali-cursor-pointer": !disabled,
          "dali-cursor-not-allowed": disabled,
        })}
      >
        <div
          className={cx(
            twMerge(toggleStyles({ checked, size, disabled })),
            hash,
          )}
        >
          <input
            ref={ref}
            type="checkbox"
            className={cx(
              "mg-toggle__native-control",
              "dali-appearance-none dali-absolute dali-inset-0",
              {
                "dali-cursor-pointer": !disabled,
                "dali-cursor-not-allowed": disabled,
              },
            )}
            role="switch"
            id={idToUse}
            checked={checked}
            disabled={disabled}
            {...pass}
          />
        </div>

        <label
          id={labelId}
          htmlFor={idToUse}
          className={cx({
            "dali-cursor-pointer": !disabled,
            "dali-cursor-not-allowed": disabled,
          })}
        >
          <Typography
            className={cx("dali-transition-colors", {
              "dali-text-carbon-300": disabled,
              "dali-text-base-black": !disabled,
            })}
            size={size === "lg" ? "lg" : "base"}
          >
            {label}
          </Typography>
        </label>
      </div>
    );
  },
);

Toggle.displayName = "Toggle";
