// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ButtonNew, Icon, Typography } from "@mg/dali/src";
import cx from "classnames";
import { type FC, useState, type DragEvent, useRef, useEffect } from "react";

import { useUI } from "../contexts/ui";

interface ImageUploadProps {
  value?: string | null;
  onUpload: (file: File | null) => void;
  isLoading?: boolean;
}

export const ImageUpload: FC<ImageUploadProps> = ({
  onUpload,
  value,
  isLoading,
}) => {
  const { notify } = useUI();
  const [uploadedImage, setUploadedImage] =
    useState<ImageUploadProps["value"]>(value);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setUploadedImage(value);
  }, [value]);

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const { files } = e.dataTransfer;
    if (files == null || files.length === 0) {
      return;
    }

    const tempImagePreview = window.URL.createObjectURL(files[0]);

    const img = new Image();
    img.src = tempImagePreview;
    setUploadedImage(tempImagePreview);
    processFiles(files);
  };

  const processFiles = (files: FileList) => {
    if (files.length) {
      const file = files[0];
      if (file.type.startsWith("image/")) {
        onUpload(file);
      }
    }
  };

  const handleChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { files, value } = evt.target as HTMLInputElement;

    if (value === "" || files == null || files.length === 0) {
      return;
    }

    const tempImagePreview = window.URL.createObjectURL(files[0]);

    const img = new Image();
    img.src = tempImagePreview;

    img.onload = () => {
      if (img.width < 600) {
        return notify({
          title: "Image Upload Unsuccessful",
          message:
            "The width of your image must be greater than or equal to 600px",
          timeout: 3500,
          variant: "error",
        });
      }
      if (files[0].size > 4000000) {
        return notify({
          title: "Image Upload Unsuccessful",
          message:
            "The file size of your image must be less than or equal to 4MB",
          timeout: 3500,
          variant: "error",
        });
      }
      if (img.width >= 600 && files[0].size <= 4000000) {
        setUploadedImage(tempImagePreview);
        processFiles(files);
      }
    };
  };

  const handleClick = () => {
    inputRef.current?.click();
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" || e.key === " ") {
      handleClick();
    }
  };
  const iconClasses = cx(
    "text-base-black absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2",
    { hidden: uploadedImage },
  );
  return (
    <div className="grid gap-2.5">
      <div
        className="relative h-[168px] w-[300px] cursor-pointer bg-carbon-200 md:h-[336px] md:w-[600px]"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={handleClick}
        onKeyDown={handleKeyPress}
        tabIndex={0}
        role="button"
      >
        <Icon.PlusCircle className={iconClasses} size={32} />
        {isLoading ? (
          <div className="flex size-full items-center justify-center">
            <div className="size-20 animate-spin rounded-full border-4 border-malachite-600 border-t-transparent" />
          </div>
        ) : uploadedImage ? (
          <img
            src={uploadedImage}
            alt="Uploaded content"
            className="size-full object-cover"
          />
        ) : null}
      </div>

      <div className="flex items-center gap-2">
        <input
          type="file"
          accept="image/*"
          onChange={handleChange}
          style={{ display: "none" }}
          ref={inputRef}
        />
        <ButtonNew variant="ghost" theme="primary" onClick={handleClick}>
          <Icon.UploadSimple /> {uploadedImage ? "Replace" : "Upload"} Image
        </ButtonNew>
      </div>
    </div>
  );
};
