import { useQuery, useMutation } from "@tanstack/react-query";

import { switchEnterprise } from "../../services/auth";
import { createEnterprise, getEnterprises } from "../../services/enterprises";
import { useAppDispatch } from "../hooks";
import { login } from "../slices/auth";

export function useCreateEnterprise() {
  const mutation = useMutation({
    mutationKey: ["create-enterprise"],
    mutationFn: createEnterprise,
  });

  return mutation;
}

export function useEnterprises() {
  return useQuery({
    queryKey: ["enterprises"],
    queryFn: getEnterprises,
  });
}

export function useSwitchEnterprise() {
  const dispatch = useAppDispatch();
  return useMutation({
    mutationKey: ["switch-enterprise"],
    mutationFn: switchEnterprise,
    onSuccess: (data) => {
      dispatch(login({ token: data.token }));
    },
  });
}
