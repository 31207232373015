import { ButtonNew, Icon, Typography } from "@mg/dali/src";
import { EnterpriseProfileType } from "@mg/schemas/src/commons";
import { useMutation } from "@tanstack/react-query";
// eslint-disable-next-line import/named, @typescript-eslint/no-unused-vars
import { Link, Route, createRoute, useNavigate } from "@tanstack/react-router";
import cx from "classnames";
import { useState } from "react";

import { introExplanationRoute } from "./intro-explanation";
import { nameAvatarRoute } from "./name-avatar";

import { updateUserProfile } from "../../../services/userProfile";
import { requiresAuth } from "../../../utils/auth";
import { useAppSelector } from "../../../utils/hooks";
import { useCurrentUserProfile } from "../../../utils/queries/userProfile";
import { BottomNavigation } from "../components/BottomNavigation";
import { onboardingRoute } from "../route";

const Button = ({
  children,
  active,
  onClick,
  icon,
}: {
  children: React.ReactNode;
  active: boolean;
  onClick: () => void;
  icon: React.ReactNode;
}) => {
  const buttonClasses = cx(
    "border-2 p-3 border-carbon-300 rounded-2xl flex items-center gap-4 text-left",
    {
      "border-malachite-600 hover:border-malachite-600 border-2": active,
      "hover:bg-carbon-50": !active,
    },
  );
  return (
    <button
      className={buttonClasses}
      onClick={onClick}
      style={
        active
          ? { boxShadow: " 0px 0px 30px 5px rgba(127, 204, 31, 0.40) inset" }
          : {}
      }
    >
      <div className="rounded-lg border-2 border-carbon-300 bg-base-white p-4">
        {active ? <Icon.CheckSquare weight="fill" size={40} /> : icon}
      </div>
      <Typography size="xl" weight="bold">
        {children}
      </Typography>
    </button>
  );
};

export const defineRoleRoute = createRoute({
  getParentRoute: () => onboardingRoute,
  path: "define-role",
  beforeLoad() {
    requiresAuth();
  },
  component: function Component() {
    const user = useAppSelector((state) => state.auth.value);
    const [activeButton, setActiveButton] = useState<number | null>(null);
    const profileMutation = useMutation({
      mutationFn: updateUserProfile,
    });
    const navigate = useNavigate();
    const profile = useCurrentUserProfile();
    return (
      <div className="flex h-dvh flex-col">
        <div className="grid size-full overflow-auto p-6 text-center md:px-10 md:py-20">
          <div className="mx-auto md:max-w-2xl">
            <Typography weight="bold" className="text-4xl md:text-6xl">
              Define Your Role
            </Typography>
            <div className="mt-6 grid gap-4">
              <Typography className="text-base md:text-lg">
                Are you an individual talent or a creative powerhouse team?
                Selecting your role helps us tailor your {user?.company}{" "}
                experience to match you with the best opportunities.
              </Typography>

              <Button
                active={activeButton === 0}
                onClick={() => setActiveButton(0)}
                icon={<Icon.Palette size={40} />}
              >
                Individual (Freelancer, Contractor)
              </Button>
              <Button
                icon={<Icon.Buildings size={40} />}
                active={activeButton === 1}
                onClick={() => setActiveButton(1)}
              >
                Team (Studio, Agency)
              </Button>
            </div>
          </div>
        </div>

        <BottomNavigation activeStep={0}>
          <div className="flex items-center justify-between p-6">
            <Link to={introExplanationRoute.to}>
              <ButtonNew size="sm" variant="ghost">
                Back
              </ButtonNew>
            </Link>

            <nav className="flex gap-4">
              <ButtonNew
                size="sm"
                isLoading={profileMutation.isPending}
                disabled={activeButton == null}
                onClick={() => {
                  profileMutation.mutate(
                    {
                      role:
                        activeButton === 0
                          ? EnterpriseProfileType.CONNECT_FREELANCER
                          : EnterpriseProfileType.CONNECT_AGENCY,
                    },
                    {
                      onSuccess: () => {
                        profile.refetch().then(() => {
                          navigate({ to: nameAvatarRoute.to });
                        });
                      },
                    },
                  );
                }}
              >
                Next: Your Name &amp; Avatar
              </ButtonNew>
            </nav>
          </div>
        </BottomNavigation>
      </div>
    );
  },
});
