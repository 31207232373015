import { ButtonNew, Checkbox, Textarea, Typography } from "@mg/dali/src";
import { type RevisionBoard } from "@mg/schemas/src/commons";
import * as Dialog from "@radix-ui/react-dialog";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useSearch } from "@tanstack/react-router";
import { type ChangeEventHandler, useState } from "react";

import { AvatarWithInitials } from "../../../components/AvatarWithInitials";
import { useAppSelector } from "../../../utils/hooks";
import { assetForUser } from "../../../utils/imageHandler";
import {
  useEditProjectMutation,
  useReviewRevisionMutation,
} from "../../../utils/queries/projects";
import { useReversedIndex } from "../../../utils/tldraw/revisions";
import { useTicket } from "../routes/ticket";

type DesignDialogProps = {
  open: boolean;
  isReviewer?: boolean;
  onOpenChange(open: boolean): void;
};

export const ApproveRevisionDialog = (props: DesignDialogProps) => {
  const reviewRevisionMutation = useReviewRevisionMutation();
  const ticket = useAppSelector((state) => state.ticket.value);
  const ticketMutation = useEditProjectMutation(ticket?._id as string);
  const { refreshTicket } = useTicket();
  const [selections, setSelections] = useState<Set<string>>(new Set());
  const [note, setNote] = useState<string>("");
  const isReviewer = props.isReviewer ?? false;

  const activeRevisionIndex = useReversedIndex();
  const handleChange: ChangeEventHandler<HTMLInputElement> = (evt) => {
    const { currentTarget } = evt;
    const value = currentTarget.value as string | number;

    if (value == null || currentTarget.nodeName !== "INPUT") {
      return;
    }

    if (selections.has(value as string)) {
      selections.delete(value as string);
    } else {
      selections.add(value as string);
    }

    const result = Array.from(selections);

    setSelections(new Set(result));
  };
  async function handleSubmit() {
    if (ticket == null) return;
    reviewRevisionMutation.mutateAsync(
      {
        payload: {
          ticketId: ticket._id as string,
          boardId: ticket.revisionBoards?.[activeRevisionIndex]?._id as string,
          creatives: Array.from(selections),
          approval: selections.size > 0,
          description: note,
        },
      },
      {
        onSuccess: () => {
          props.onOpenChange(false);
          refreshTicket();
        },
      },
    );
  }

  const activeCreatives =
    ticket?.revisionBoards?.reduce<RevisionBoard["createdBy"][]>(
      (acc, board) => {
        if (!acc.length) {
          return [board.createdBy];
        } else if (
          //@ts-expect-error TS2339: incorrectly typed from BE
          !acc.find((creative) => creative._id === board.createdBy._id)
        ) {
          return [...acc, board.createdBy];
        }
        return acc;
      },
      [],
    ) ?? [];

  return (
    <Dialog.Root {...props}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-10 bg-base-black/50 data-[state=open]:animate-fade-in" />
        <Dialog.Content className="fixed left-1/2 top-1/2 z-[12] grid w-[360px] max-w-2xl -translate-x-1/2 -translate-y-1/2 gap-4 rounded-lg bg-base-white p-4 text-base-black shadow-2xl focus:outline-none data-[state=open]:animate-dialog-content-show">
          <Dialog.Title>
            <Typography size="2xl" weight="medium">
              Finish Review
            </Typography>
          </Dialog.Title>
          <Dialog.Description>
            <Typography weight="medium">Note</Typography>
            <Textarea
              className="py-1"
              onChange={(e) => setNote(e.target.value)}
            />
            {isReviewer && (
              <>
                <Typography className="mt-3" weight="medium">
                  Mark as Approved
                </Typography>
                {activeCreatives?.map((creative) => (
                  <div
                    // @ts-expect-error TS2339: incorrectly typed from BE
                    key={creative._id}
                    className="flex items-center gap-1 px-4 py-2"
                  >
                    <Checkbox
                      // @ts-expect-error TS2339: incorrectly typed from BE
                      key={"checkbox" + creative._id}
                      // @ts-expect-error TS2339: incorrectly typed from BE
                      id={"checkbox" + creative._id}
                      size="sm"
                      // @ts-expect-error TS2339: incorrectly typed from BE
                      value={String(creative._id)}
                      // @ts-expect-error TS2339: incorrectly typed from BE
                      checked={selections.has(creative._id)}
                      // @ts-expect-error TS2339: incorrectly typed from BE
                      onChange={handleChange}
                      label=""
                      className="rounded-sm border-carbon-200"
                    />
                    <AvatarWithInitials
                      // @ts-expect-error TS2339: incorrectly typed from BE
                      avatar={assetForUser(creative.avatar)}
                      // @ts-expect-error TS2339: incorrectly typed from BE
                      name={creative.name ?? ""}
                      size={8}
                      square
                    />
                    {/* @ts-expect-error TS2339: incorrectly typed from BE */}
                    <Typography>{creative.name}</Typography>
                  </div>
                ))}
              </>
            )}
          </Dialog.Description>
          <div className="flex justify-end gap-3">
            <ButtonNew
              type="submit"
              className="bg-egyptian-blue-600"
              onClick={handleSubmit}
              isLoading={
                reviewRevisionMutation.isPending ?? ticketMutation.isPending
              }
            >
              Send Review
            </ButtonNew>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

ApproveRevisionDialog.displayName = "ApproveRevisionDialog";
