import { Button, Dialog, Flex } from "@radix-ui/themes";

import { useAppSelector } from "../../../utils/hooks";

type DeleteTicketDialogProps = {
  ticketId?: string;
  onDelete(ticketId: string): void;
  onCancel(): void;
  isPending: boolean;
};

export function DeleteTicketDialog(props: DeleteTicketDialogProps) {
  const { isPending, onCancel, onDelete, ticketId } = props;
  const tickets = useAppSelector((state) => state.punttProjects.tickets);

  const found = tickets.find((t) => t._id === ticketId);

  return (
    <Dialog.Root open={ticketId != null}>
      <Dialog.Content size="1" maxWidth="300px">
        <Dialog.Title size="2">Delete ticket?</Dialog.Title>
        <Dialog.Description size="1">
          Are you sure you want to delete {found?.title}?
        </Dialog.Description>

        <Flex gap="2" mt="3" justify="end">
          <Button
            size="1"
            variant="soft"
            color="gray"
            onClick={() => onCancel()}
          >
            Cancel
          </Button>
          <Button
            size="1"
            color="red"
            onClick={() => onDelete(ticketId as string)}
            disabled={isPending}
            loading={isPending}
          >
            Delete
          </Button>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
}
