// eslint-disable-next-line import/named, @typescript-eslint/no-unused-vars
import { Route, createRoute } from "@tanstack/react-router";

import { requiresAuth } from "../../../utils/auth";
import { WhatDoWeCallYou } from "../components/WhatDoWeCallYou";
import { onboardingRoute } from "../route";

export const nameAvatarRoute = createRoute({
  getParentRoute: () => onboardingRoute,
  path: "what-do-we-call-you",
  beforeLoad() {
    requiresAuth();
  },
  component: WhatDoWeCallYou,
});
