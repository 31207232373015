import {
  // eslint-disable-next-line import/named
  createRoute,
} from "@tanstack/react-router";

import { TutorialTickets } from "./view";

import { authLayoutRoute } from "../auth-layout/route";

export const tutorialTicketsRoute = createRoute({
  getParentRoute: () => authLayoutRoute,
  path: "tutorial-tickets",
  component: TutorialTickets,
});
