import {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Button,
  ButtonNew,
  Icon,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Input,
  Select,
  Typography,
} from "@mg/dali/src";
import { useMutation, useQuery } from "@tanstack/react-query";
// eslint-disable-next-line import/named, @typescript-eslint/no-unused-vars
import { useNavigate, Route, createRoute } from "@tanstack/react-router";
import cx from "classnames";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { EditorState } from "draft-js";
import { type FormEvent, useState, useCallback, useEffect } from "react";

import { useUI } from "../../../../contexts/ui";
import { getBrands, getConceptsByParent } from "../../../../services/concepts";
import {
  getUserProfile,
  updateUserProfile,
} from "../../../../services/userProfile";
import { requiresAuth } from "../../../../utils/auth";
import { useAppDispatch } from "../../../../utils/hooks";
import { useConcepts } from "../../../../utils/queries/concepts";
import { useCurrentUserProfile } from "../../../../utils/queries/userProfile";
import { setUserProfile } from "../../../../utils/slices/userProfile";
import { editProfileRoute } from "../../base-edit-route";
import { userProfileRoute } from "../../route";

export const myExpertiseEditRoute = createRoute({
  getParentRoute: () => editProfileRoute,
  beforeLoad() {
    requiresAuth();
  },
  path: "my-expertise",
  component: function Component() {
    const navigate = useNavigate();
    const { notify } = useUI();
    const { data: profile } = useCurrentUserProfile();
    const { data: toolOptions } = useConcepts("tool", "tools");
    const { data: industryOptions } = useConcepts("industry", "industries");
    const { data: specialtiesOptions } = useConcepts(
      "parent-skill",
      "specialties",
    );
    const { data: skillsOptions } = useConcepts("skill", "skills");

    const brandsQuery = useQuery({
      queryKey: ["brands"],
      queryFn: getBrands,
    });

    const brandsConcepts = brandsQuery.data?.map((brand: string) => ({
      label: brand,
      value: brand,
    }));
    const [softSkills, setSoftSkills] = useState<string[]>(
      profile?.softSkills ?? [],
    );
    const [tools, setTools] = useState<string[]>(profile?.tools ?? []);
    const [skills, setSkills] = useState<string[]>(profile?.skills ?? []);
    const [industries, setIndustries] = useState<string[]>(
      profile?.industries ?? [],
    );

    const [specialties, setSpecialties] = useState<string[]>(
      profile?.specialties ?? [],
    );
    const [deliverables1, setDeliverables1] = useState<string[]>([]);
    const [deliverables2, setDeliverables2] = useState<string[]>([]);
    const [deliverables3, setDeliverables3] = useState<string[]>([]);

    const { data: specialtyOneDeliverablesOptions } = useQuery({
      queryKey: ["specialty-children-1", specialties[0]],
      queryFn: () =>
        getConceptsByParent(
          encodeURIComponent(specialties[0].replace(",", "#")),
        ),
      enabled: specialties[0] != null,
    });
    const { data: specialtyTwoDeliverablesOptions } = useQuery({
      queryKey: ["specialty-children-2", specialties[1]],
      queryFn: () =>
        getConceptsByParent(
          encodeURIComponent(specialties[1].replace(",", "#")),
        ),
      enabled: specialties[1] != null,
    });
    const { data: specialtyThreeDeliverablesOptions } = useQuery({
      queryKey: ["specialty-children-3", specialties[2]],
      queryFn: () =>
        getConceptsByParent(
          encodeURIComponent(specialties[2].replace(",", "#")),
        ),
      enabled: specialties[2] != null,
    });
    const [brands, setBrands] = useState<string[]>(profile?.brands ?? []);
    const [isError, setIsError] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const profileMutation = useMutation({
      mutationFn: updateUserProfile,
    });
    const refetchProfile = useMutation({
      mutationFn: getUserProfile,
    });

    useEffect(() => {
      if (profile?.brands?.length) {
        setBrands(profile.brands);
      }
      if (profile?.softSkills?.length) {
        setSoftSkills(profile.softSkills);
      }
      if (profile?.tools?.length) {
        setTools(profile.tools);
      }
      if (profile?.industries?.length) {
        setIndustries(profile.industries);
      }
      if (profile?.specialties?.length) {
        setSpecialties(profile.specialties);
      }
      if (profile?.skills?.length) {
        setSkills(profile.skills);
      }
    }, [profile]);
    useEffect(
      () => {
        if (!profile?.specialties?.length) return;

        if (specialtyOneDeliverablesOptions?.length && !deliverables1.length) {
          setDeliverables1(
            specialtyOneDeliverablesOptions
              .filter((s) => profile.deliverables?.includes(s.label))
              .map((spec) => spec.label),
          );
        }
        if (
          profile.specialties[1] &&
          specialtyTwoDeliverablesOptions?.length &&
          !deliverables2.length
        ) {
          setDeliverables2(
            specialtyTwoDeliverablesOptions
              .filter((s) => profile.deliverables?.includes(s.label))
              .map((spec) => spec.label),
          );
        }
        if (
          profile.specialties[2] &&
          specialtyThreeDeliverablesOptions?.length &&
          !deliverables3.length
        ) {
          setDeliverables3(
            specialtyThreeDeliverablesOptions
              .filter((s) => profile.deliverables?.includes(s.label))
              .map((spec) => spec.label),
          );
        }
        if (!specialties[0]) {
          setDeliverables1([]);
        }
        if (!specialties[1]) {
          setDeliverables2([]);
        }
        if (!specialties[2]) {
          setDeliverables3([]);
        }
      },
      // we dont want this to update with deliverables or specialties, so disabling the warning
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        profile,
        specialtyOneDeliverablesOptions,
        specialtyTwoDeliverablesOptions,
        specialtyThreeDeliverablesOptions,
      ],
    );

    const formIsValid = useCallback(
      (field?: string) => {
        const invCheck = {
          specialties: specialties.length,
          deliverables1: deliverables1.length,
        };
        if (field) return !!invCheck[field as keyof typeof invCheck];
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const [key, value] of Object.entries(invCheck)) {
          if (!value) {
            return false;
          }
        }
        return true;
      },
      [specialties, deliverables1],
    );
    function handleSubmit(e: FormEvent) {
      e.preventDefault();
      if (!formIsValid()) return setIsError(true);
      else setIsError(false);

      profileMutation.mutate(
        {
          deliverables: [...deliverables1, ...deliverables2, ...deliverables3],
          softSkills,
          tools,
          industries,
          brands,
          specialties,
          skills,
        },
        {
          onSuccess() {
            setDeliverables1([]);
            setDeliverables2([]);
            setDeliverables3([]);
            setSpecialties([]);
            setSoftSkills([]);
            setTools([]);
            setIndustries([]);
            refetchProfile.mutate(undefined, {
              onSuccess(data) {
                dispatch(setUserProfile(data));
                notify({
                  variant: "success",
                  title: `Saved!`,
                  message: `You have successfully saved your "My Expertise" section!`,
                  leadingIcon: (
                    <Icon.CheckCircle color="rgb(var(--base-black))" />
                  ),
                });
              },
            });
          },
        },
      );
    }

    if (!profile) return null;

    return (
      <form
        className="flex w-full flex-col gap-6 px-10 py-9 pb-24"
        onSubmit={handleSubmit}
      >
        <div className="flex justify-between">
          <Typography size="2xl" weight="bold">
            My Expertise
          </Typography>
          <div className="flex gap-x-6">
            <ButtonNew
              theme="primary"
              variant="ghost"
              onClick={() => navigate({ to: userProfileRoute.to })}
            >
              Back
            </ButtonNew>
            <ButtonNew
              theme="primary"
              variant="filled"
              disabled={profileMutation.isPending}
              type="submit"
            >
              Save
            </ButtonNew>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-y-6 border-b border-base-black py-6 md:grid-cols-3 md:gap-x-6">
          <div className="col-span-3">
            <Typography>
              You can select up to three (3) Specialties. Each Specialty has a
              number of Deliverables that will help refine your profile and
              represent your abilities.
            </Typography>
          </div>
          <div className="col-span-1">
            <Select<false>
              label="Specialty 1 *"
              placeholder="Search or Select"
              disabled={!specialtiesOptions || profileMutation.isPending}
              options={specialtiesOptions ?? []}
              name="specialty1"
              value={
                specialties[0]
                  ? { value: specialties[0], label: specialties[0] }
                  : undefined
              }
              onChange={({ value }) =>
                setSpecialties(() => {
                  const newSpecialties = [...specialties];
                  newSpecialties[0] = value as string;
                  return newSpecialties;
                })
              }
              invalid={isError && !formIsValid("specialties")}
              key={`spec1-${specialties[0]}`}
            />
          </div>
          <div className="col-span-2 flex items-start">
            <Select<true>
              label="Deliverables"
              placeholder="Search or Select"
              disabled={!specialties[0] || profileMutation.isPending}
              options={
                specialtyOneDeliverablesOptions?.map((spec) => ({
                  label: spec.label,
                  value: spec.label,
                })) ?? []
              }
              multiple
              name="deliverables1"
              value={deliverables1.map((s) => ({ value: s, label: s }))}
              onChange={(values) =>
                setDeliverables1(values.map((v) => v.value as string))
              }
              invalid={isError && !formIsValid("deliverables1")}
              className="w-full"
              key={`deli1-${deliverables1.join(",")}`}
            />
            <button
              onClick={(e) => {
                e.preventDefault();
                setSpecialties(() => {
                  const newSpecialties = [...specialties];
                  newSpecialties.splice(0, 1);
                  return newSpecialties;
                });
                setDeliverables1(deliverables2);
                setDeliverables2(deliverables3);
              }}
              disabled={!specialties[0]}
            >
              <Icon.XCircle
                size={24}
                className={cx({ "text-carbon-300": !specialties[0] })}
              />
            </button>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-y-6 border-b border-base-black pb-6 md:grid-cols-3 md:gap-x-6">
          <div className="col-span-1">
            <Select<false>
              label="Specialty 2"
              placeholder="Search or Select"
              disabled={
                !specialties[0] ||
                !specialtiesOptions ||
                profileMutation.isPending
              }
              options={
                specialtiesOptions?.filter((spec) => {
                  if (
                    (specialties[0] && spec.value === specialties[0]) ||
                    (specialties[2] && spec.value === specialties[2])
                  ) {
                    return false;
                  }
                  return true;
                }) ?? []
              }
              name="specialty2"
              value={
                specialties[1]
                  ? { value: specialties[1], label: specialties[1] }
                  : undefined
              }
              onChange={({ value }) =>
                setSpecialties(() => {
                  const newSpecialties = [...specialties];
                  newSpecialties[1] = value as string;
                  return newSpecialties;
                })
              }
              key={`spec2-${specialties[1]}`}
            />
          </div>
          <div className="col-span-2 flex items-start">
            <Select<true>
              label="Deliverables"
              placeholder="Search or Select"
              disabled={!specialties[1] || profileMutation.isPending}
              options={
                specialtyTwoDeliverablesOptions?.map((spec) => ({
                  label: spec.label,
                  value: spec.label,
                })) ?? []
              }
              multiple
              name="deliverables2"
              value={deliverables2.map((s) => ({ value: s, label: s }))}
              onChange={(values) =>
                setDeliverables2(values.map((v) => v.value as string))
              }
              invalid={isError && !formIsValid("deliverables2")}
              key={`deli2-${deliverables2.join(",")}`}
              className="w-full"
            />
            <button
              onClick={(e) => {
                e.preventDefault();
                setSpecialties(() => {
                  const newSpecialties = [...specialties];
                  newSpecialties.splice(1, 1);
                  return newSpecialties;
                });
                setDeliverables2(deliverables3);
              }}
              disabled={!specialties[1]}
            >
              <Icon.XCircle
                size={24}
                className={cx({ "text-carbon-300": !specialties[1] })}
              />
            </button>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-y-6 border-b border-base-black pb-6 md:grid-cols-3 md:gap-x-6">
          <div className="col-span-1">
            <Select<false>
              label="Specialty 3"
              placeholder="Search or Select"
              disabled={
                !specialties[0] ||
                !specialties[1] ||
                !specialtiesOptions ||
                profileMutation.isPending
              }
              options={
                specialtiesOptions?.filter((spec) => {
                  if (
                    (specialties[0] && spec.value === specialties[0]) ||
                    (specialties[1] && spec.value === specialties[1])
                  ) {
                    return false;
                  }
                  return true;
                }) ?? []
              }
              name="specialty3"
              value={
                specialties[2]
                  ? { value: specialties[2], label: specialties[2] }
                  : undefined
              }
              onChange={({ value }) =>
                setSpecialties(() => {
                  const newSpecialties = [...specialties];
                  newSpecialties[2] = value as string;
                  return newSpecialties;
                })
              }
              key={`spec3-${specialties[2]}`}
            />
          </div>
          <div className="col-span-2 flex items-start">
            <Select<true>
              label="Deliverables"
              placeholder="Search or Select"
              disabled={!specialties[2] || profileMutation.isPending}
              options={
                specialtyThreeDeliverablesOptions?.map((spec) => ({
                  label: spec.label,
                  value: spec.label,
                })) ?? []
              }
              multiple
              name="deliverables3"
              value={deliverables3.map((s) => ({ value: s, label: s }))}
              onChange={(values) =>
                setDeliverables3(values.map((v) => v.value as string))
              }
              className="w-full"
              key={`deli3-${deliverables3.join(",")}`}
            />
            <button
              onClick={(e) => {
                e.preventDefault();
                setDeliverables3([]);
                setSpecialties(() => {
                  const newSpecialties = [...specialties];
                  newSpecialties.splice(2, 1);

                  return newSpecialties;
                });
              }}
              disabled={!specialties[2]}
            >
              <Icon.XCircle
                size={24}
                className={cx({ "text-carbon-300": !specialties[2] })}
              />
            </button>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-y-6 md:gap-x-6">
          <Select<true>
            label="Skills"
            placeholder="Search or Select"
            disabled={!skillsOptions || profileMutation.isPending}
            options={skillsOptions ?? []}
            multiple
            name="skills"
            value={skills.map((s) => ({ value: s, label: s }))}
            onChange={(values) =>
              setSkills(values.map((v) => v.value as string))
            }
            allowCustom
          />
          {/* had to make this a flex flex-col because grid was causing the options to be rendered at bottom of the screen */}
          <Select<true>
            label="Tools"
            placeholder="Search or Select"
            disabled={!toolOptions || profileMutation.isPending}
            options={toolOptions ?? []}
            multiple
            name="tools"
            value={tools.map((s) => ({ value: s, label: s }))}
            onChange={(values) =>
              setTools(values.map((v) => v.value as string))
            }
            allowCustom
          />
          <Select<true>
            label="Industries"
            placeholder="Search or Select"
            disabled={!industryOptions || profileMutation.isPending}
            options={industryOptions ?? []}
            multiple
            name="industries"
            value={industries.map((s) => ({ value: s, label: s }))}
            onChange={(values) =>
              setIndustries(values.map((v) => v.value as string))
            }
            allowCustom
          />
          <Select<true>
            label="Brands"
            placeholder="Search or Select"
            disabled={!brandsConcepts || profileMutation.isPending}
            options={brandsConcepts ?? []}
            multiple
            name="brands"
            value={brands.map((s) => ({ value: s, label: s }))}
            onChange={(values) =>
              setBrands(values.map((v) => v.value as string))
            }
            allowCustom
          />
        </div>
      </form>
    );
  },
  errorComponent() {
    return "Uh oh! Something went wrong, please try refreshing the page. If the problem persists, please contact support.";
  },
});
