import { cva, cx } from "class-variance-authority";
import React, {
  forwardRef,
  type InputHTMLAttributes,
  type ReactNode,
  useEffect,
  useRef,
  type Ref,
} from "react";
import { extendTailwindMerge } from "tailwind-merge";

import { Icon, Typography } from "../foundation";
import { useId } from "../foundation/base";

export interface CheckboxProps
  extends Omit<InputHTMLAttributes<HTMLLabelElement>, "size"> {
  /**
   * Force the checkbox's checked state; optional when indeterminate, required otherwise
   */
  checked?: boolean;
  /**
   * Optionally, disable the checkbox
   */
  disabled?: boolean;
  /**
   * A unique ID for the checkbox element used by the form
   */
  id?: string;
  /**
   * Optionally, set the checkbox to an indeterminate state.
   */
  indeterminate?: boolean;
  /**
   * A mandatory label for the checkbox; rendered to the right of the checkbox.
   * Keep in mind that this should be a ReactNode and the typography styles must be
   * applied via the node's `className` prop
   */
  label: ReactNode;
  /**
   * A value for the checkbox; optional when indeterminate, required otherwise
   */
  value?: string;
  /**
   * Optionally, set the size of the checkbox control and label
   */
  size?: "sm" | "lg";
  onClickLabel?: (e: React.MouseEvent<HTMLLabelElement>) => void;
}

const twMerge = extendTailwindMerge({ prefix: "dali-" });

const checkboxStyles = cva(
  [
    "dali-relative dali-transition-all dali-rounded-sm group-focus-within:dali-ring-2",
    "group-focus-within:dali-ring-offset-2 group-focus-within:dali-ring-base-black dali-aspect-square",
    "dali-grid dali-place-content-center dali-border-2 [&>svg]:dali-h-full [&>svg]:dali-aspect-square",
  ],
  {
    variants: {
      size: {
        sm: ["dali-h-3.5 dali-mt-1.5"],
        lg: ["dali-h-6"],
      },
      checked: {
        true: ["dali-bg-malachite-700", "dali-border-malachite-700"],
        false: ["dali-border-base-black"],
      },
      indeterminate: {
        true: ["dali-bg-malachite-700", "dali-border-malachite-700"],
        false: [],
      },
      disabled: {
        true: [],
        false: [],
      },
    },
    compoundVariants: [
      // neither checked nor indeterminate
      {
        checked: false,
        indeterminate: false,
        className: "[&>svg]:dali-hidden",
      },
      // disabled states
      {
        checked: false,
        indeterminate: false,
        disabled: true,
        className: "dali-border-carbon-300",
      },
      {
        checked: true,
        indeterminate: false,
        disabled: true,
        className: "dali-bg-carbon-300 dali-border-carbon-300",
      },
      {
        checked: false,
        indeterminate: true,
        disabled: true,
        className: "dali-bg-carbon-300 dali-border-carbon-300",
      },
      {
        checked: true,
        indeterminate: true,
        disabled: true,
        className: "dali-bg-carbon-300 dali-border-carbon-300",
      },
    ],
  },
);

export const Checkbox = forwardRef(
  (props: CheckboxProps, ref: Ref<HTMLLabelElement> | null) => {
    const {
      checked = false,
      disabled = false,
      indeterminate = false,
      label,
      value,
      size = "lg",
      ...rest
    } = props;
    const checkboxRef = useRef<HTMLInputElement>(null);
    const { className: hash, onChange, onClickLabel, ...pass } = rest;
    const idToUse = useId("checkbox", props);

    const controlIconSizes: Record<
      NonNullable<CheckboxProps["size"]>,
      number
    > = {
      sm: 12,
      lg: 20,
    };

    /**
     * Checkboxes cannot set their `indeterminate` state as
     * either a DOM attribute or a React prop. It _must_ be
     * set on the Element itself
     */
    useEffect(() => {
      if (checkboxRef.current != null) {
        checkboxRef.current.indeterminate = indeterminate;
      }
    }, [indeterminate, checkboxRef]);

    /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
    return (
      <label
        id={`${idToUse}-label`}
        htmlFor={idToUse}
        className={cx(
          "mg-checkbox dali-flex dali-items-start dali-gap-2 dali-group",
          {
            "dali-cursor-pointer": !disabled,
            "dali-cursor-not-allowed": disabled,
          },
        )}
        ref={ref}
        onClick={(e) => (onClickLabel ? onClickLabel(e) : undefined)}
      >
        <div
          className={cx(
            twMerge(checkboxStyles({ size, indeterminate, disabled, checked })),
            hash,
          )}
        >
          <Icon.IconContext.Provider
            value={{
              size: controlIconSizes[size],
              color: "rgb(var(--base-white))",
              weight: "bold",
              className: "dali-shrink-0",
            }}
          >
            <input
              type="checkbox"
              className={cx(
                "mg-checkbox__native-control",
                "dali-appearance-none dali-absolute dali-inset-0",
                {
                  "dali-cursor-pointer": !disabled,
                  "dali-cursor-not-allowed": disabled,
                },
              )}
              id={idToUse}
              checked={checked}
              onChange={onChange}
              disabled={disabled}
              value={value}
              ref={checkboxRef}
              {...pass}
            />
            {!indeterminate ? <Icon.Check /> : <Icon.Minus />}
          </Icon.IconContext.Provider>
        </div>
        <Typography
          className={cx("dali-transition-colors", {
            "dali-text-carbon-300": disabled,
            "dali-text-base-black": !disabled,
          })}
          size={size === "lg" ? "lg" : "base"}
        >
          {label}
        </Typography>
      </label>
    );
    /* eslint-enable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
  },
);

Checkbox.displayName = "Checkbox";
