import { configureStore } from "@reduxjs/toolkit";

import { aiChatSlice } from "./slices/aiChat";
import { authSlice } from "./slices/auth";
import { conceptsSlice } from "./slices/concepts";
import { listsSlice } from "./slices/lists";
import { messagesSlice } from "./slices/messages";
import { networkSlice } from "./slices/network";
import { onboardingSlice } from "./slices/onboarding";
import { profileSlice } from "./slices/profile";
import { punttProjectsSlice } from "./slices/punttProjects";
import { ticketSlice } from "./slices/ticket";
import { UISlice } from "./slices/ui";
import { userProfileSlice } from "./slices/userProfile";
import { workedWithSlice } from "./slices/workedWith";

export const store = configureStore({
  reducer: {
    aiChat: aiChatSlice.reducer,
    auth: authSlice.reducer,
    concepts: conceptsSlice.reducer,
    lists: listsSlice.reducer,
    messages: messagesSlice.reducer,
    network: networkSlice.reducer,
    onboarding: onboardingSlice.reducer,
    profile: profileSlice.reducer,
    punttProjects: punttProjectsSlice.reducer,
    ticket: ticketSlice.reducer,
    ui: UISlice.reducer,
    userProfile: userProfileSlice.reducer,
    workedWith: workedWithSlice.reducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
