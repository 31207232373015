import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

import { getConceptsByType } from "../../services/concepts";
import { useAppDispatch, useAppSelector } from "../hooks";
import { type ConceptsState, setConcept } from "../slices/concepts";

export function useConcepts(conceptType: string, target: keyof ConceptsState) {
  const dispatch = useAppDispatch();
  const concept = useAppSelector((state) => state.concepts[target]);

  const query = useQuery({
    queryKey: [conceptType],
    queryFn: () => getConceptsByType(conceptType),
    enabled: concept == null || !concept.length,
  });

  useEffect(() => {
    if (query.data != null) {
      dispatch(setConcept({ target, value: query.data }));
    }
  }, [query.data, dispatch, target]);

  return concept?.length ? { data: concept } : query;
}
