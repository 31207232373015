import { Typography, ButtonNew } from "@mg/dali/src";
import * as Dialog from "@radix-ui/react-dialog";

import { SpreadSheetImporter } from "../SpreadSheetImporter";

type LinkedinCSVImportDialogProps = {
  open: boolean;
  onOpenChange(open: boolean): void;
};
export const LinkedinCSVImportDialog = (
  props: LinkedinCSVImportDialogProps,
) => {
  return (
    <Dialog.Root {...props}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-[5] bg-base-black/50 data-[state=open]:animate-fade-in" />
        <Dialog.Content className="fixed left-1/2 top-1/2 z-[6] grid w-[90vw] max-w-lg -translate-x-1/2 -translate-y-1/2 gap-6 rounded-lg bg-base-white p-6 text-base-black shadow-2xl focus:outline-none data-[state=open]:animate-dialog-content-show">
          <Dialog.Title asChild>
            <Typography size="2xl" weight="medium">
              Linkedin CSV Import
            </Typography>
          </Dialog.Title>

          <Typography>
            <a
              href="https://www.linkedin.com/mypreferences/d/download-my-data"
              className="font-bold underline"
              target="_blank"
              rel="noreferrer"
            >
              Request a data export from LinkedIn.
            </a>{" "}
            Do a custom export and select &quot;Connections&quot;. Once LinkedIn
            sends you an email that your export is ready, head back to that same
            page to download the CSV file.
          </Typography>

          <SpreadSheetImporter />

          <div className="flex gap-4">
            <ButtonNew>Import</ButtonNew>

            <Dialog.Close asChild>
              <ButtonNew variant="outlined">Cancel</ButtonNew>
            </Dialog.Close>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

LinkedinCSVImportDialog.displayName = "LinkedinCSVImportDialog";
