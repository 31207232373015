import { Trash } from "@phosphor-icons/react";
import {
  Badge,
  Button,
  Card,
  Dialog,
  Flex,
  Grid,
  IconButton,
  Text,
  VisuallyHidden,
} from "@radix-ui/themes";
import { useSearch } from "@tanstack/react-router";
import cx from "classnames";
import { useMemo } from "react";

import { assetForUser } from "../../../../../utils/imageHandler";

type VersionManagerDialogProps = {
  open: boolean;
  onOpenChange(open: boolean): void;
  onDeleteVersion(revisionId: string, index: number): void;
  revisions: { _id: string; title: string; thumbnail?: string }[];
  isPending: boolean;
  deletingId?: string;
};

export function VersionManagerDialog(props: VersionManagerDialogProps) {
  const {
    open,
    onOpenChange,
    onDeleteVersion,
    revisions,
    isPending,
    deletingId,
  } = props;

  const { revIndex } = useSearch({ strict: false });

  const reversedIndex = useMemo(
    () => revisions.length - 1 - (revIndex ?? 0),
    [revIndex],
  );

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Content maxWidth="450px">
        <Dialog.Title>Manage versions</Dialog.Title>
        <VisuallyHidden>
          <Dialog.Description>
            Delete a version for this ticket
          </Dialog.Description>
        </VisuallyHidden>

        <Grid gap="4">
          <Card variant="surface">
            {revisions.map((r, i) => (
              <Flex
                key={r._id}
                align="center"
                justify="between"
                className="border-b-puntt-neutral-6 border-b px-4 py-2 last:border-b-0"
              >
                <Flex align="center" gap="4">
                  <figure className="size-6 overflow-hidden rounded-sm">
                    <img
                      src={assetForUser(r.thumbnail)}
                      className="object-cover"
                      alt={`Thumbnail for version ${r.title}`}
                    />
                  </figure>

                  <Text>V{revisions.length - i}</Text>
                </Flex>

                <Badge
                  variant="surface"
                  className={cx({ hidden: i !== reversedIndex })}
                >
                  Current
                </Badge>
                <IconButton
                  className={cx({
                    hidden: revisions.length === 1 || i === reversedIndex,
                  })}
                  variant="ghost"
                  color="gray"
                  onClick={() => onDeleteVersion(r._id, i)}
                  disabled={isPending}
                  loading={isPending && deletingId === r._id}
                >
                  <Trash />
                </IconButton>
              </Flex>
            ))}
          </Card>

          <Dialog.Close className="max-w-max place-self-end">
            <Button color="gray" variant="soft">
              Close
            </Button>
          </Dialog.Close>
        </Grid>
      </Dialog.Content>
    </Dialog.Root>
  );
}
