import { ButtonNew, Icon, Typography } from "@mg/dali/src";
import { type TicketStatus } from "@mg/schemas/src/commons";
import { useNavigate } from "@tanstack/react-router";
import {
  type Editor,
  Tldraw,
  type TLComponents,
  loadSnapshot,
} from "@tldraw/tldraw";
import cx from "classnames";
import { format } from "date-fns";
import { useState } from "react";

import { AvatarWithInitials } from "../../../components/AvatarWithInitials";
import { useAnalytics } from "../../../utils/analytics";
import { canSetTicketStatus } from "../../../utils/auth";
import { useAppSelector } from "../../../utils/hooks";
import { downloadAsset } from "../../../utils/http";
import {
  useEditProjectMutation,
  useGetDownloadableAsset,
} from "../../../utils/queries/projects";
import { assetUrls } from "../../../utils/tldraw/assets";
import { customShapeUtils } from "../../../utils/tldraw/shapeUtils";
import { customTools } from "../../../utils/tldraw/tools";
import { ticketsRoute } from "../route";
import { useTicket } from "../routes/ticket";

const components: TLComponents = {
  ContextMenu: null,
};

export function Delivery() {
  const posthog = useAnalytics("Delivery");
  const navigate = useNavigate();
  const ticket = useAppSelector((state) => state.ticket.value);
  const { headerHeight } = useTicket();
  const ticketMutation = useEditProjectMutation(ticket?._id as string);
  const downloadAssetMutation = useGetDownloadableAsset();
  const [editor, setEditor] = useState<Editor | null>(null);
  const [activeRevisionIndex, setActiveRevisionIndex] = useState(0);

  if (ticket == null) {
    return null;
  }

  const approvedBoards = ticket?.revisionBoards?.filter(
    (board) => board.approval,
  );

  const isComplete = ticket.status === "complete";

  // we shouldn't show the button if it's not complete or approved
  const hasPermission =
    canSetTicketStatus(ticket) &&
    (ticket.status === "complete" || ticket.status === "approved");

  function toggleCompletionStatus() {
    if (ticket == null) {
      return null;
    }

    const currentStatus = ticket.status;

    let newStatus = "complete";

    if (currentStatus === "approved") {
      newStatus = "complete";
    } else {
      newStatus = "approved";
    }
    const startTime = performance.now();
    ticketMutation
      .mutateAsync({
        _id: ticket._id as string,
        status: newStatus as TicketStatus,
      })
      .then((response) => {
        posthog.capture("ticket_status_changed", {
          startingStatus: currentStatus,
          status: response.status,
          mutationDuration: performance.now() - startTime,
        });
        if (response.status === "complete") {
          navigate({ to: ticketsRoute.to, search: {} });
        }
      });
  }

  if (!approvedBoards?.length) {
    return null;
  }

  return (
    <>
      <header className="flex items-center gap-4 text-base-black">
        {hasPermission && (
          <ButtonNew
            onClick={() => toggleCompletionStatus()}
            isLoading={ticketMutation.isPending}
            disabled={ticketMutation.isPending}
            size="sm"
            className="bg-egyptian-blue-600"
          >
            Mark As {isComplete ? "Incomplete" : "Complete"}
          </ButtonNew>
        )}
      </header>

      <div
        className="fixed inset-0"
        style={{
          top: headerHeight,
        }}
      >
        <Tldraw
          assetUrls={assetUrls}
          hideUi
          tools={customTools}
          components={components}
          acceptedImageMimeTypes={[
            "image/jpeg",
            "image/png",
            "image/gif",
            "image/webp",
            "image/svg+xml",
            "application/illustrator",
            "application/eps",
            "application/postscript",
            "application/msword",

            "application/pdf",
            "application/zip",
            "application/*",
            "image/*",
          ]}
          shapeUtils={customShapeUtils}
          onMount={(editor) => {
            setEditor(editor);
            const filteredStore = Object.entries(
              approvedBoards?.[activeRevisionIndex]?.shapes.store,
            ).reduce((acc, [key, value]) => {
              //@ts-expect-error TS2345: incorrect type from TLDraw
              if (value.type !== "comment") {
                //@ts-expect-error TS2345: incorrect type from TLDraw
                acc[key] = value;
              }
              return acc;
            }, {});
            const filteredShapes = {
              ...approvedBoards?.[activeRevisionIndex]?.shapes,
              store: filteredStore,
            };
            loadSnapshot(editor.store, filteredShapes);
            editor.updateInstanceState({ isReadonly: true });
            editor?.zoomToFit();
          }}
        />
        <aside className="fixed left-2 top-1/2 z-[200] grid max-h-[380px] -translate-y-1/2 gap-4 overflow-auto p-1">
          {approvedBoards?.map((revision, i) => (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <figure
              key={revision._id}
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
              role="button"
              tabIndex={-1}
              onClick={() => {
                setActiveRevisionIndex(i);
                const filteredStore = Object.entries(
                  revision.shapes.store,
                ).reduce((acc, [key, value]) => {
                  //@ts-expect-error TS2345: incorrect type from TLDraw
                  if (value.type !== "comment") {
                    //@ts-expect-error TS2345: incorrect type from TLDraw
                    acc[key] = value;
                  }
                  return acc;
                }, {});
                const filteredShapes = {
                  ...revision.shapes,
                  store: filteredStore,
                };
                if (editor == null) return;
                loadSnapshot(editor.store, filteredShapes);
                editor?.zoomToFit();
              }}
              className={cx(
                "grid justify-items-center gap-1 rounded-xl border bg-base-white px-1.5 py-1 shadow-lg",
                {
                  "border-egyptian-blue-400": i === activeRevisionIndex,
                  "border-transparent hover:border-egyptian-blue-200":
                    i !== activeRevisionIndex,
                },
              )}
            >
              <div
                className={cx(
                  "flex h-16 w-32 flex-col items-end justify-end rounded-xl border bg-carbon-50",
                )}
              >
                <AvatarWithInitials
                  //@ts-expect-error TS2345: incorrect type from BE
                  avatar={revision.createdBy?.avatar}
                  //@ts-expect-error TS2345: incorrect type from BE
                  name={revision.createdBy?.name}
                  size={6}
                  className="mb-1 mr-1.5"
                />
              </div>

              <figcaption className="flex w-32 items-center justify-between truncate">
                <Typography size="sm" className="truncate text-carbon-600">
                  {revision.name}
                </Typography>
                <Typography size="xs" className="text-carbon-300">
                  {format(revision.createdAt, "M/dd/yy")}
                </Typography>
              </figcaption>
              <ButtonNew
                onClick={(e) => {
                  e.stopPropagation();

                  downloadAssetMutation.mutateAsync(
                    {
                      ticketId: ticket._id as string,
                      boardId: revision._id as string,
                    },
                    {
                      onSuccess: (data) => {
                        downloadAsset(
                          data.url,
                          data.url.split("/").pop(),
                          "downloaded_asset",
                          {
                            boardId: revision._id,
                            duration_since_revision_creation:
                              Date.now() -
                              new Date(revision.createdAt).getTime(),
                          },
                        );
                      },
                    },
                  );
                }}
                isLoading={downloadAssetMutation.isPending}
                disabled={downloadAssetMutation.isPending}
                size="sm"
                className="bg-egyptian-blue-600 py-1.5"
              >
                <Icon.DownloadSimple />
                <Typography>Download Files</Typography>
              </ButtonNew>
            </figure>
          ))}
        </aside>
      </div>
    </>
  );
}
