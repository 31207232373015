import { type GetWorkedWithResponse } from "@mg/schemas/src/christo/myNetwork";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

type WorkedWithState = {
  profiles: GetWorkedWithResponse | null;
};

const initialState: WorkedWithState = {
  profiles: null,
};

export const workedWithSlice = createSlice({
  name: "workedWith",
  initialState,
  reducers: {
    setWorkedWith(state, action: PayloadAction<GetWorkedWithResponse>) {
      state.profiles = action.payload;
    },
  },
});

export const { setWorkedWith } = workedWithSlice.actions;
