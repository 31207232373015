import { ButtonNew, Card, Chip, Icon, Typography } from "@mg/dali/src";
import { useMutation } from "@tanstack/react-query";
// eslint-disable-next-line import/named
import { createRoute, useNavigate } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  type DropResult,
  type DroppableProvided,
  type DraggableProvided,
} from "react-beautiful-dnd";

import { myWorkEditRoute } from "./editRoute";

import { updateUserProfile } from "../../../../services/userProfile";
import { requiresAuth } from "../../../../utils/auth";
import { useAppSelector } from "../../../../utils/hooks";
import { assetForUser } from "../../../../utils/imageHandler";
import { useProfile } from "../../../../utils/queries/profile";
import { getEmbedURL } from "../../../../utils/validation";
import { authLayoutRoute } from "../../../auth-layout/route";
import { userProfileRoute } from "../../route";

export const myWorkListRoute = createRoute({
  getParentRoute: () => authLayoutRoute,
  path: "profile/my-work",
  beforeLoad() {
    requiresAuth();
  },
  component: function Component() {
    const navigate = useNavigate();
    const user = useAppSelector((state) => state.auth.value);
    const { data: profile } = useProfile(user?.userID as string);
    const profileMutation = useMutation({
      mutationFn: updateUserProfile,
    });

    const [projects, setProjects] = useState(profile?.highlights);

    useEffect(() => {
      setProjects(profile?.highlights);
    }, [profile?.highlights]);

    if (!profile) return null;

    const handleDrop = (droppedItem: DropResult) => {
      if (!droppedItem.destination) {
        return;
      }
      const highlights = [...(projects ?? [])];
      const [reorderedItem] = highlights.splice(droppedItem.source.index, 1);

      if (droppedItem.destination) {
        highlights.splice(droppedItem.destination.index, 0, reorderedItem);
      }
      setProjects(highlights);
    };

    return (
      <div className="flex h-screen w-full flex-col overflow-auto p-6 md:px-10">
        <div className="grid gap-6 pb-24 md:py-9">
          <div className="flex justify-between">
            <Typography size="2xl" weight="bold">
              My Work
            </Typography>
            <div className="flex gap-x-6">
              <ButtonNew
                theme="primary"
                variant="ghost"
                onClick={() => navigate({ to: userProfileRoute.to })}
              >
                Cancel
              </ButtonNew>
              <ButtonNew
                theme="primary"
                variant="filled"
                type="submit"
                onClick={() =>
                  profileMutation.mutate(
                    { highlights: projects },
                    {
                      onSuccess: () => {
                        navigate({ to: userProfileRoute.to });
                      },
                    },
                  )
                }
                isLoading={profileMutation.isPending}
                disabled={profileMutation.isPending}
              >
                Save
              </ButtonNew>
            </div>
          </div>

          <Typography size="lg">
            Projects are the best way to represent the scope of your creative
            work. Thoughtful descriptions and compelling imagery are
            foundational to making your profile as representative of you as
            possible.
          </Typography>
          <ButtonNew
            theme="primary"
            variant="outlined"
            onClick={() => navigate({ to: myWorkEditRoute.to })}
            className="max-w-fit"
          >
            <Icon.PlusCircle size={24} />
            Add Project
          </ButtonNew>
          <DragDropContext onDragEnd={handleDrop}>
            <Droppable droppableId="project-order">
              {(provided: DroppableProvided) => (
                <div
                  className="grid gap-7"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {projects?.map((project, i) => (
                    <Draggable
                      key={project.title + i}
                      draggableId={project.title + i}
                      index={i}
                    >
                      {(providedDrag: DraggableProvided) => (
                        <div
                          ref={providedDrag.innerRef}
                          {...providedDrag.dragHandleProps}
                          {...providedDrag.draggableProps}
                          className="flex gap-3"
                        >
                          <div className="w-full">
                            <Card
                              size="sm"
                              type="outlined"
                              className="group relative inline-grid min-w-full rounded-2xl hover:bg-carbon-50"
                              onClick={() => {
                                navigate({
                                  to: myWorkEditRoute.to,
                                  // @ts-expect-error TS2353: There are too many
                                  // search params on here that aren't being
                                  // tracked in a central location
                                  search: { projectId: project._id },
                                });
                              }}
                            >
                              <div className="absolute left-2 top-2">
                                <Icon.DotsSixVertical size={20} />{" "}
                              </div>
                              <div className="flex justify-between">
                                <Typography weight="bold" size="2xl">
                                  {project.title}
                                </Typography>
                                <ButtonNew
                                  variant="ghost"
                                  theme="primary"
                                  onClick={async (e) => {
                                    e.stopPropagation();

                                    const highlights = [...(projects ?? [])];
                                    highlights.splice(i, 1);
                                    setProjects(highlights);
                                  }}
                                >
                                  <Icon.XCircle size={24} />
                                </ButtonNew>
                              </div>
                              <div className="flex flex-col gap-6 md:flex-row">
                                <figure className="relative aspect-video overflow-hidden md:h-[223px] md:w-[400px]">
                                  {!project.assets[0] ? (
                                    <div className="flex h-[223px] w-[400px] bg-carbon-200" />
                                  ) : project.assets[0].type === "video" ? (
                                    <iframe
                                      width="400"
                                      height="223"
                                      src={getEmbedURL(
                                        project.assets[0].source,
                                      )}
                                      title={project.assets[0].source}
                                    />
                                  ) : (
                                    <img
                                      src={assetForUser(
                                        project.assets[0].source,
                                        600,
                                      )}
                                      alt="Uploaded content"
                                      className="size-full object-cover"
                                    />
                                  )}
                                  <figcaption className="absolute inset-0 flex items-center justify-center bg-base-black/75 opacity-0 transition-opacity group-hover:opacity-100">
                                    <Typography
                                      size="lg"
                                      className="text-base-white"
                                    >
                                      Click to edit project details
                                    </Typography>
                                  </figcaption>
                                </figure>
                                <div className="grid flex-1 gap-6">
                                  <div>
                                    <Typography size="xl" weight="medium">
                                      Deliverables and Skills
                                    </Typography>
                                    <div className="mt-2 flex flex-wrap gap-2">
                                      {[
                                        ...(project?.deliverables ?? []),
                                        ...(project?.skills ?? []),
                                      ].length > 0 ? (
                                        [
                                          ...(project?.deliverables ?? []),
                                          ...(project?.skills ?? []),
                                        ]?.map((s, i) => (
                                          <Chip
                                            key={`.${i}.${s}`}
                                            variant="primary-outlined"
                                            label={s}
                                          />
                                        ))
                                      ) : (
                                        <Chip
                                          variant="primary-outlined"
                                          label="N/A"
                                        />
                                      )}
                                    </div>
                                  </div>
                                  <div>
                                    <Typography size="xl" weight="medium">
                                      Industry
                                    </Typography>
                                    <div className="mt-2 flex flex-wrap gap-2">
                                      <Chip
                                        variant="primary-outlined"
                                        label={project?.industry ?? "N/A"}
                                      />
                                    </div>
                                  </div>
                                  <div>
                                    <Typography size="xl" weight="medium">
                                      Brand
                                    </Typography>
                                    <div className="mt-2 flex flex-wrap gap-2">
                                      <Chip
                                        variant="primary-outlined"
                                        label={
                                          project?.company?.length
                                            ? project.company
                                            : "N/A"
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Card>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    );
  },
  errorComponent() {
    return "Uh oh! That one isn't found";
  },
});
