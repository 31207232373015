import { cva, cx } from "class-variance-authority";
import React, { forwardRef, type HTMLAttributes, type Ref } from "react";
import { extendTailwindMerge } from "tailwind-merge";

import { Icon } from "../foundation";

const twMerge = extendTailwindMerge({ prefix: "dali-" });

export interface AvatarProps extends HTMLAttributes<HTMLElement> {
  /**
   * Optionally, the full image source including the protocol
   */
  image?: string;
  /**
   * Optionally, sets the img alt attribute value
   */
  alt?: string;
  /**
   * Optionally, sets the size of the Avatar component; defaults to 'sm'
   */
  size?: "sm" | "md" | "lg";
  /**
   * Optionally, sets the type of the Avatar and which default
   * to show if no image is provided; defaults to 'creative'
   */
  type?: "creative" | "customer";
}

const avatarStyles = cva(
  [
    "mg-avatar",
    "dali-aspect-square",
    "dali-bg-carbon-200",
    "dali-overflow-hidden",
    "[&>svg]:dali-h-full",
    "[&>svg]:dali-w-full",
  ],
  {
    variants: {
      image: {
        true: [],
        false: [],
      },
      size: {
        sm: ["dali-h-11"],
        md: ["dali-h-16"],
        lg: ["dali-h-32"],
      },
      type: {
        creative: ["dali-rounded-full"],
        customer: [],
      },
    },
    compoundVariants: [
      // no-image + size compound variants
      {
        size: "sm",
        image: false,
        className: "dali-p-2",
      },
      {
        size: "md",
        image: false,
        className: "dali-p-3",
      },
      {
        size: "lg",
        image: false,
        className: "dali-p-6",
      },

      // customer type + size compound variants
      {
        size: "sm",
        type: "customer",
        className: "dali-rounded-md",
      },
      {
        size: "md",
        type: "customer",
        className: "dali-rounded-lg",
      },
      {
        size: "lg",
        type: "customer",
        className: "dali-rounded-xl",
      },
    ],
  },
);

export const Avatar = forwardRef(
  (
    {
      image,
      alt = "User Avatar",
      size = "sm",
      type = "creative",
      ...rest
    }: AvatarProps,
    ref: Ref<HTMLElement>,
  ) => {
    const { className: hash, ...pass } = rest;
    const classes = cx(
      twMerge(avatarStyles({ type, size, image: image != null })),
      hash,
    );

    return (
      <figure className={classes} {...pass} ref={ref}>
        {image ? (
          <img
            src={image}
            className="dali-h-full dali-w-full dali-object-cover"
            alt={alt}
          />
        ) : (
          <>
            {type === "customer" && (
              <Icon.IdentificationBadge
                weight="regular"
                color="rgb(var(--base-black))"
              />
            )}
            {type === "creative" && (
              <Icon.UserCircle
                weight="regular"
                color="rgb(var(--base-black))"
              />
            )}
          </>
        )}
      </figure>
    );
  },
);

Avatar.displayName = "Avatar";
