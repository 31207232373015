import { Icon } from "@mg/dali/src";
import {
  EnterpriseProfileType,
  UserPermission,
} from "@mg/schemas/src/commons/user";
import { MagnifyingGlass } from "@phosphor-icons/react";
import {
  Button,
  Dialog,
  Flex,
  Heading,
  Select,
  Table,
  Text,
  TextField,
} from "@radix-ui/themes";
// eslint-disable-next-line import/named
import { createRoute, redirect } from "@tanstack/react-router";
import { useMemo, useRef, useState } from "react";

import { AvatarWithInitials } from "../../../components/AvatarWithInitials";
import { useUI } from "../../../contexts/ui";
import { errorAnalyticsPayload, useAnalytics } from "../../../utils/analytics";
import {
  requiresAuth,
  canManageUsers,
  canAccessPuntt,
  canAccessCreativeConnect,
  canAccessEditOwnProfile,
  canAccessLists,
} from "../../../utils/auth";
import { useArchiveUser, useInviteUser } from "../../../utils/queries/users";
import { store } from "../../../utils/store";
import { useGetVisibleUsers } from "../../../utils/tldraw/comments";
import { authLayoutRoute } from "../../auth-layout/route";
import { listsRoute } from "../../lists/route";
import { myNetworkRoute } from "../../network/route";
import { ticketsRoute } from "../../tickets/route";
import { aboutMeEditRoute } from "../../userProfile/routes/AboutMe/editRoute";

export const usersRoute = createRoute({
  getParentRoute: () => authLayoutRoute,
  path: "users",
  beforeLoad() {
    const canAccess = canManageUsers();

    if (!canAccess) {
      if (canAccessPuntt()) {
        throw redirect({
          to: ticketsRoute.to,
        } as any);
      }

      if (canAccessCreativeConnect()) {
        // @ts-expect-error TS2345: incorrect typing search params
        throw redirect({
          to: myNetworkRoute.to,
        });
      }

      if (canAccessEditOwnProfile()) {
        throw redirect({
          to: aboutMeEditRoute.to,
        });
      }

      if (canAccessLists()) {
        throw redirect({
          to: listsRoute.to,
        });
      }
    }

    requiresAuth();
  },
  component: Users,
});

function Users() {
  const posthog = useAnalytics("Users");
  const { notify } = useUI();

  const [filters, setFilters] = useState<{ name: string }>({ name: "" });

  const { value: currentUser } = store.getState().auth;
  // This is not how we normally like to check for access, but it needs to
  // match the backend, and at the time of writing the backend doesn't support
  // feature flags yet, so we didn't take on that scope as part of this feature.
  const canArchive = currentUser?.permissions?.includes(
    UserPermission.CONNECT_TEAM,
  );

  const [addUserName, setAddUserName] = useState("");
  const [addUserEmail, setAddUserEmail] = useState("");
  const [addUserRole, setAddUserRole] = useState(currentUser?.role ?? "");

  const {
    data: unsortedUsers,
    refetch,
    isPending,
    isRefetching,
  } = useGetVisibleUsers(false);
  const users = useMemo(() => {
    return unsortedUsers
      ?.filter(
        (user) =>
          !filters.name ||
          user.name?.toLowerCase().includes(filters.name.toLowerCase()) ||
          user.email?.toLowerCase().includes(filters.name.toLowerCase()),
      )
      .toSorted((a, b) => (a.name ?? "").localeCompare(b.name ?? ""));
  }, [unsortedUsers, filters.name]);

  const archiveUserMutation = useArchiveUser();
  const inviteUserMutation = useInviteUser();

  const isLoading =
    isPending ||
    isRefetching ||
    archiveUserMutation.isPending ||
    inviteUserMutation.isPending;

  const loader = (
    <Table.Row>
      <Table.Cell colSpan={4} className="text-center">
        Loading users&hellip;
      </Table.Cell>
    </Table.Row>
  );

  let allowedRoles: EnterpriseProfileType[] = [];
  const roles = [
    EnterpriseProfileType.ADMIN,
    EnterpriseProfileType.OPS,
    EnterpriseProfileType.CATALYST_CREATIVE,
    EnterpriseProfileType.CATALYST_MARKETING,
    EnterpriseProfileType.CATALYST_REQUESTER,
  ];
  if (currentUser?.role === EnterpriseProfileType.MEANINGFUL_GIGS) {
    roles.push(EnterpriseProfileType.MEANINGFUL_GIGS);
    allowedRoles = roles;
  } else if (currentUser?.role === EnterpriseProfileType.ADMIN) {
    allowedRoles = roles;
  }

  const closeNewUserDialogRef = useRef<HTMLButtonElement>(null);

  return (
    <Flex
      direction="column"
      wrap="nowrap"
      className="bg-puntt-neutral-gray-3 px-12 py-6"
      style={{
        minHeight: "calc(100vh - 57px)",
      }}
    >
      <Flex direction="row" wrap="nowrap" className="mb-4 gap-4">
        <Heading as="h1">Users</Heading>
        <Flex align="center" className="ml-auto gap-x-2">
          <TextField.Root
            placeholder="Search Users"
            size="1"
            defaultValue={filters.name}
            onInput={({ target }) =>
              setFilters({ name: (target as HTMLInputElement).value })
            }
          >
            <TextField.Slot>
              <MagnifyingGlass />
            </TextField.Slot>
          </TextField.Root>

          <Dialog.Root>
            <Dialog.Trigger>
              <Button variant="solid" size="1">
                <Icon.Plus />
                Add User
              </Button>
            </Dialog.Trigger>
            <Dialog.Content style={{ maxWidth: 350 }}>
              <Dialog.Title>New User</Dialog.Title>
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  const startTime = performance.now();

                  inviteUserMutation.mutate(
                    {
                      enterpriseId: currentUser?.enterpriseId as string,
                      name: addUserName,
                      email: addUserEmail,
                      role: addUserRole,
                    },
                    {
                      onSuccess: (data) => {
                        refetch();

                        posthog.capture("user_invited", {
                          userId: data?._id,
                          userEmail: addUserEmail,
                          userName: addUserName,
                          userRole: addUserRole,
                          durationSeconds:
                            (performance.now() - startTime) / 1000,
                        });

                        closeNewUserDialogRef.current?.click();

                        notify({
                          variant: "success",
                          title: `${addUserName} successfully invited`,
                          message: "User has been invited to the platform.",
                          leadingIcon: (
                            <Icon.CheckCircle color="rgb(var(--base-black))" />
                          ),
                        });

                        setAddUserName("");
                        setAddUserEmail("");
                        setAddUserRole(currentUser?.role || "");
                      },
                      onError: (error) => {
                        posthog.capture("user_invited_error", {
                          userEmail: addUserEmail,
                          userName: addUserName,
                          userRole: addUserRole,
                          durationSeconds:
                            (performance.now() - startTime) / 1000,
                          ...errorAnalyticsPayload(error),
                        });

                        notify({
                          variant: "error",
                          title: `Failed to invite ${addUserName}`,
                          message: error.message,
                        });

                        throw error;
                      },
                    },
                  );
                }}
              >
                <Flex direction="column" gap="3">
                  <label htmlFor="new-user-name">
                    <Text as="div" size="2" mb="1" weight="bold">
                      Name
                    </Text>
                    <TextField.Root
                      id="new-user-name"
                      placeholder="Enter name"
                      value={addUserName}
                      onChange={(e) => setAddUserName(e.target.value)}
                      required
                    />
                  </label>
                  <label htmlFor="new-user-email">
                    <Text as="div" size="2" mb="1" weight="bold">
                      Email
                    </Text>
                    <TextField.Root
                      id="new-user-email"
                      placeholder="Enter email"
                      value={addUserEmail}
                      onChange={(e) => setAddUserEmail(e.target.value)}
                      required
                    />
                  </label>
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label>
                    <Text as="div" size="2" mb="1" weight="bold">
                      Role
                    </Text>
                    <Select.Root
                      value={addUserRole}
                      onValueChange={(value) => setAddUserRole(value)}
                    >
                      <Select.Trigger className="w-full">
                        <Text as="span" size="1" className="capitalize">
                          {addUserRole?.replace(/-/g, " ") ?? ""}
                        </Text>
                      </Select.Trigger>
                      <Select.Content>
                        {(allowedRoles.length
                          ? allowedRoles
                          : [currentUser?.role]
                        ).map((role) => (
                          <Select.Item
                            key={role}
                            value={role as string}
                            className="capitalize data-[highlighted]:bg-base-black data-[highlighted]:text-base-white"
                          >
                            {role?.replace(/-/g, " ") ?? ""}
                          </Select.Item>
                        ))}
                      </Select.Content>
                    </Select.Root>
                  </label>
                </Flex>
                <Flex gap="3" mt="4" justify="end">
                  <Dialog.Close
                    data-testid="add-user-cancel"
                    onClick={(e) => e.stopPropagation()}
                    ref={closeNewUserDialogRef}
                  >
                    <Button variant="soft" color="gray" className="mr-auto">
                      Cancel
                    </Button>
                  </Dialog.Close>
                  <Button
                    type="submit"
                    data-testid="add-user-confirm"
                    disabled={inviteUserMutation.isPending}
                    loading={inviteUserMutation.isPending}
                  >
                    <Icon.PaperPlaneRight />
                    Send Invitation
                  </Button>
                </Flex>
              </form>
            </Dialog.Content>
          </Dialog.Root>
        </Flex>
      </Flex>
      <Table.Root>
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeaderCell>Name</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Email</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Role</Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>Actions</Table.ColumnHeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {isLoading && loader}
          {users?.map((user) => (
            <Table.Row
              key={user._id}
              className="bg-[#F9F9F9] hover:bg-carbon-50"
            >
              <Table.Cell className="align-middle">
                <AvatarWithInitials
                  avatar={user?.avatar}
                  name={user?.name}
                  size={8}
                  square
                />
                <Text weight="bold" className="pl-2">
                  {user.name}
                </Text>
              </Table.Cell>
              <Table.Cell className="align-middle">{user.email}</Table.Cell>
              <Table.Cell className="align-middle capitalize">
                {currentUser?.userID === user._id || !allowedRoles.length ? (
                  user.role.replace(/-/g, " ")
                ) : (
                  <Select.Root
                    value={user.role}
                    onValueChange={(newRole) => {
                      if (user.role !== newRole) {
                        posthog.capture("user_role_updated", {
                          userId: user._id,
                          userEmail: user.email,
                          oldRole: user.role,
                          userRole: newRole,
                        });
                        // Technically it should already be possible to update the current user's role,
                        // but we would need the enterpriseProfile which we don't have easy access to.
                        // We don't have an endpoint to update other users' roles yet.
                        notify({
                          variant: "default",
                          title: "Sorry",
                          message: "Updating roles is not yet supported.",
                        });
                      }
                    }}
                  >
                    <Select.Trigger className="w-full">
                      <Text as="span" size="1" className="capitalize">
                        {user.role.replace(/-/g, " ")}
                      </Text>
                    </Select.Trigger>
                    <Select.Content>
                      {allowedRoles.map((role) => (
                        <Select.Item
                          key={role}
                          value={role}
                          className="capitalize data-[highlighted]:bg-base-black data-[highlighted]:text-base-white"
                        >
                          {role.replace(/-/g, " ")}
                        </Select.Item>
                      ))}
                    </Select.Content>
                  </Select.Root>
                )}
              </Table.Cell>
              <Table.Cell className="align-middle">
                {canArchive && (
                  <Dialog.Root>
                    <Dialog.Trigger>
                      <Button
                        variant="outline"
                        className="m-1 hover:bg-puntt-accent-10 hover:text-base-white"
                        size="1"
                      >
                        Archive
                      </Button>
                    </Dialog.Trigger>
                    <Dialog.Content className="grid w-[90vw] max-w-lg gap-4">
                      <Dialog.Title>
                        Archive <em>{user.name}</em>
                      </Dialog.Title>
                      <Dialog.Description>
                        Are you sure you want to permanently archive this user?
                      </Dialog.Description>
                      <div className="mt-4 flex items-center justify-end gap-2">
                        <Dialog.Close
                          data-testid="archive-cancel"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Button variant="soft" color="gray">
                            Cancel
                          </Button>
                        </Dialog.Close>
                        <Dialog.Close
                          data-testid="archive-confirm"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Button
                            onClick={() => {
                              const startTime = performance.now();
                              archiveUserMutation.mutate(user._id, {
                                onSuccess: () => {
                                  refetch();

                                  posthog.capture("user_archived", {
                                    userId: user._id,
                                    userEmail: user.email,
                                    userName: user.name,
                                    userRole: user.role,
                                    durationSeconds:
                                      (performance.now() - startTime) / 1000,
                                  });

                                  notify({
                                    variant: "success",
                                    title: `${user.name} successfully archived`,
                                    message: "User has been archived.",
                                    leadingIcon: (
                                      <Icon.CheckCircle color="rgb(var(--base-black))" />
                                    ),
                                  });
                                },
                                onError: (error) => {
                                  posthog.capture("user_archived_error", {
                                    userId: user._id,
                                    userEmail: user.email,
                                    userName: user.name,
                                    userRole: user.role,
                                    durationSeconds:
                                      (performance.now() - startTime) / 1000,
                                    ...errorAnalyticsPayload(error),
                                  });

                                  notify({
                                    variant: "error",
                                    title: `Failed to archive ${user.name}`,
                                    message: error.message,
                                  });

                                  throw error;
                                },
                              });
                            }}
                          >
                            Archive
                          </Button>
                        </Dialog.Close>
                      </div>
                    </Dialog.Content>
                  </Dialog.Root>
                )}
              </Table.Cell>
            </Table.Row>
          )) ??
            (!isLoading && loader)}
        </Table.Body>
      </Table.Root>
    </Flex>
  );
}
