import { Dialog } from "@headlessui/react";
import { ButtonNew, Icon, Input, Typography } from "@mg/dali/src";
import { useMutation } from "@tanstack/react-query";
// eslint-disable-next-line import/named, @typescript-eslint/no-unused-vars
import { Link, Route, createRoute, useNavigate } from "@tanstack/react-router";
import cx from "classnames";
import { useEffect, useState } from "react";

import { projectImageUploadRoute } from "./create-project";
import { nameAvatarRoute } from "./name-avatar";
import { reviewPortfolioRoute } from "./review-portfolio";

import { ProgressBarLoader } from "../../../components/ProgressBarLoader";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ReactComponent as AiIcon } from "../../../icons/ai.svg";
import PortfolioLoadingGif from "../../../images/loading-portfolio.gif";
import { scrapeUserByUrl } from "../../../services/assistant";
import { updateUserProfile } from "../../../services/userProfile";
import { requiresAuth } from "../../../utils/auth";
import { LOADING_TEXT_LIST } from "../../../utils/constants";
import { useCurrentUserProfile } from "../../../utils/queries/userProfile";
import { BottomNavigation } from "../components/BottomNavigation";
import { onboardingRoute } from "../route";

export const importPortfolioRoute = createRoute({
  getParentRoute: () => onboardingRoute,
  path: "import-portfolio",
  beforeLoad() {
    requiresAuth();
  },
  component: function Component() {
    const [textIndex, setTextIndex] = useState(0);
    const [fade, setFade] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [website, setWebsite] = useState<string>("");
    const [emptyProjectsResponse, setEmptyProjectsResponse] =
      useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean | null>(null);
    const profile = useCurrentUserProfile();
    const mutation = useMutation({
      mutationFn: scrapeUserByUrl,
    });
    const profileMutation = useMutation({
      mutationFn: updateUserProfile,
    });
    const navigate = useNavigate();

    useEffect(() => {
      const interval = setInterval(() => {
        setFade(false);
      }, 3000);

      return () => clearInterval(interval);
    }, []);
    useEffect(() => {
      if (!fade) {
        const timeout = setTimeout(() => {
          setTextIndex((textIndex + 1) % LOADING_TEXT_LIST.length);
          setFade(true);
        }, 0);

        return () => clearTimeout(timeout);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fade]);

    const validateUrl = (url: string): boolean => {
      const regex = /\w+\.\w+/;
      return regex.test(url);
    };

    const handleBlur = (str?: string) => {
      if (!str) {
        str = website;
      }
      if (str.length > 2) {
        setIsValid(validateUrl(str));
      } else {
        setIsValid(false);
      }
    };

    return (
      <div className="flex h-dvh flex-col">
        <div className="grid size-full overflow-auto p-6 md:px-10 md:py-20">
          <div className="mx-auto mt-1 md:max-w-[692px]">
            <Typography
              weight="bold"
              className="text-center text-4xl md:text-6xl"
            >
              Sync Your Portfolio with AI
            </Typography>
            <div className="mt-6 grid gap-6">
              <Typography size="lg" className="text-center">
                Have a portfolio on a personal website or Behance? Great! Simply
                provide the link and our AI will smoothly integrate it into your
                profile. Fast. Effortless. And pretty darn cool!
              </Typography>
              <Input
                label="Portfolio"
                required
                onChange={({ target }) => {
                  handleBlur(target.value);
                  setWebsite(target.value);
                }}
                value={website}
                onBlur={() => handleBlur()}
                size="sm"
                placeholder="Please enter your personal website URL or Behance URL"
                invalid={
                  website?.length > 2 &&
                  typeof isValid === "boolean" &&
                  !isValid
                }
                helpText={
                  website?.length > 2 &&
                  typeof isValid === "boolean" &&
                  !isValid
                    ? "Please enter a valid URL"
                    : ""
                }
              />
              <ButtonNew
                size="xs"
                variant="ghost"
                className="mx-auto mt-3 max-w-fit"
                onClick={() => {
                  profileMutation.mutate({ highlights: [] });
                  navigate({ to: projectImageUploadRoute.to });
                }}
              >
                Skip and Setup My Profile Manually
              </ButtonNew>
            </div>
          </div>
          <Dialog
            as="div"
            open={isOpen}
            onClose={() => {
              setIsOpen(false);
              setEmptyProjectsResponse(false);
            }}
            className="fixed inset-0 z-10"
          >
            <div
              // eslint-disable-next-line tailwindcss/migration-from-tailwind-2
              className="fixed inset-0 bg-carbon-900 bg-opacity-80"
              aria-hidden="true"
            ></div>

            <div className="flex min-h-screen items-center justify-center">
              {/* Dialog panel with an opaque background */}
              <Dialog.Panel className="relative mx-auto max-w-xs rounded-2xl bg-base-white p-6 shadow-xl md:min-w-[720px] md:max-w-xl">
                {emptyProjectsResponse || mutation.isError ? (
                  <div className="p-10">
                    <Typography
                      weight="bold"
                      className="text-center text-4xl md:text-6xl"
                    >
                      Oops, AI&apos;s playing hard to sync!
                    </Typography>
                    <div className="mt-6 grid gap-4">
                      <Typography className="text-center text-xl md:text-3xl">
                        Time to go manual – your touch is needed.
                      </Typography>
                      <ButtonNew
                        variant="filled"
                        className="mx-auto mt-3 max-w-fit"
                        onClick={() => {
                          setIsOpen(false);
                          navigate({ to: projectImageUploadRoute.to });
                        }}
                      >
                        Add Project Image
                      </ButtonNew>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="p-10">
                      <Typography
                        weight="bold"
                        className="text-center text-4xl md:text-6xl"
                      >
                        Importing your portfolio
                      </Typography>
                      <div className="mt-6 grid gap-4">
                        <div className="mx-auto flex size-20 items-center justify-center rounded-full bg-base-black">
                          <img
                            src={PortfolioLoadingGif}
                            alt="Portfolio Loading"
                          />
                        </div>
                        <div className="relative flex h-20 justify-center">
                          {LOADING_TEXT_LIST.map((text, index) => (
                            <Typography
                              key={index}
                              className={cx(
                                "absolute text-center text-xl transition-opacity duration-200 md:text-3xl",
                                {
                                  hidden: !fade,
                                  "opacity-0": index !== textIndex,
                                  "opacity-100": index === textIndex,
                                },
                              )}
                            >
                              {text}
                            </Typography>
                          ))}
                        </div>
                      </div>
                    </div>
                    <ProgressBarLoader />
                  </>
                )}
              </Dialog.Panel>
            </div>
          </Dialog>
        </div>

        <BottomNavigation activeStep={2}>
          <div className="flex items-center justify-between p-6">
            <Link to={nameAvatarRoute.to}>
              <ButtonNew size="sm" variant="ghost">
                Back
              </ButtonNew>
            </Link>

            <nav className="flex gap-4">
              <ButtonNew
                size="sm"
                onClick={() => {
                  setIsOpen(true);
                  mutation.mutate(website, {
                    onSuccess: () => {
                      profile.refetch().then((response) => {
                        if (response.data?.highlights?.length === 0) {
                          setEmptyProjectsResponse(true);
                        } else {
                          setIsOpen(false);
                          navigate({ to: reviewPortfolioRoute.to });
                        }
                      });
                    },
                  });
                }}
                disabled={!isValid || website.length < 2}
              >
                <Icon.MagicWand />
                Start Sync
              </ButtonNew>
            </nav>
          </div>
        </BottomNavigation>
      </div>
    );
  },
});
