import { Icon } from "@mg/dali/src";
import {
  type BaseTicketComment,
  type GetTicketCommentsResponse,
} from "@mg/schemas/src/christo/catalyst";
import { TicketCommentDisposition } from "@mg/schemas/src/commons";
import {
  HTMLContainer,
  Rectangle2d,
  ShapeUtil,
  type RecordProps,
  type TLBaseShape,
  T,
  type TLShapeId,
} from "@tldraw/tldraw";
import { useEffect, useMemo } from "react";

import { AvatarWithInitials } from "../../../../components/AvatarWithInitials";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks";
import { assetForUser } from "../../../../utils/imageHandler";
import { setActiveCommentId } from "../../../../utils/slices/ticket";
import { store } from "../../../../utils/store";

export type TicketComment = GetTicketCommentsResponse[number];

type CommentShape = TLBaseShape<
  "comment",
  {
    w: number;
    h: number;
    commentId: unknown;
  }
>;

export class CommentAvatarShapeUtil extends ShapeUtil<CommentShape> {
  static override type = "comment-avatar" as const;

  static override props: RecordProps<CommentShape> = {
    w: T.number,
    h: T.number,
    commentId: T.unknown,
  };

  // [3]
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  override isAspectRatioLocked = (_shape: CommentShape) => false;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  override canResize = (_shape: CommentShape) => false;
  override hideRotateHandle = (_shape: CommentShape) => true;

  // [4]
  override canEdit = () => true;

  // [5]
  getDefaultProps(): CommentShape["props"] {
    return {
      w: 25,
      h: 25,
      commentId: null,
    };
  }

  private isVisible(shape: CommentShape, userId?: string) {
    const user = store.getState().auth.value;

    try {
      if (typeof shape.meta?.comment !== "string") {
        return true;
      }

      const comment = JSON.parse(shape.meta.comment) as TicketComment;

      if (
        user?.role != "ai" &&
        user?.role != "meaningful-gigs" &&
        comment.disposition == TicketCommentDisposition.DISMISSED
      ) {
        return false;
      }
      const createdById = String(
        typeof comment.createdBy === "object"
          ? comment.createdBy._id
          : comment.createdBy,
      );
      return !comment.isPending || createdById === String(userId);
    } catch {
      return true;
    }
  }

  // [6]
  getGeometry(shape: CommentShape) {
    const zoomLevel = this.editor.getZoomLevel();

    return new Rectangle2d({
      width: shape.props.w / zoomLevel,
      height: shape.props.h / zoomLevel,
      isFilled: true,
    });
  }

  component = (shape: CommentShape) => {
    const zoomLevel = this.editor.getZoomLevel();
    const user = store.getState().auth.value;

    const { highlightedCommentId } = useAppSelector((state) => state.ticket);
    const dispatch = useAppDispatch();

    const isSelected = this.editor.getSelectedShapeIds().includes(shape.id);

    useEffect(() => {
      const parsedComment = (shape.meta?.comment &&
        JSON.parse(shape.meta.comment as string)) as TicketComment;

      if (isSelected) {
        this.editor.bringToFront([shape.meta.linkedCommentId as TLShapeId]);
        this.editor.setSelectedShapes([
          shape.id,
          shape.meta.linkedCommentId as TLShapeId,
        ]);
        if (parsedComment?._id) {
          dispatch(setActiveCommentId(parsedComment?._id as string));
        }
      } else {
        dispatch(setActiveCommentId(null));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSelected]);

    const comment = useMemo(() => {
      if (!shape.meta?.comment) {
        return null;
      }
      const parsedComment = JSON.parse(
        shape.meta.comment as string,
      ) as BaseTicketComment;

      return parsedComment;
    }, [shape.meta?.comment]);

    if (!this.isVisible(shape, user?.userID)) {
      return (
        <div
          style={{
            width: 0,
            height: 0,
          }}
        />
      );
    }

    return (
      <HTMLContainer
        style={{
          transform: `scale(${1 / zoomLevel})`,
          position: "relative",
          zIndex: 8600,
        }}
      >
        <div className="comment-pin relative">
          <div
            className="comment-pin"
            style={{
              width: 25,
              backgroundColor: comment?.isRequired
                ? "rgb(var(--puntt-red-9))"
                : "var(--color-text-shadow)",
              borderRadius: "20px 20px 20px 0",
              boxShadow: `0 0 0 2px ${
                comment?.isRequired
                  ? "rgb(var(--puntt-red-9))"
                  : "var(--color-text-shadow)"
              }, 0 1px 3px 2px rgba(0, 0, 0, 0.12), 0 1px 2px 2px rgba(0, 0, 0, 0.24)`,
              transform: `${highlightedCommentId === comment?._id ? "scale(1.125)" : ""}`,
            }}
          >
            {comment?.isAI ? (
              <Icon.Robot
                className="rounded-full bg-amber-amber10 p-1 text-base-black"
                size={25}
              />
            ) : (
              <AvatarWithInitials
                avatar={assetForUser(
                  // @ts-expect-error TS2339: schemas are wrong
                  (comment ? comment.createdBy : user)?.avatar,
                )}
                // @ts-expect-error TS2339: schemas are wrong
                name={(comment ? comment.createdBy : user)?.name}
                size={6}
              />
            )}
          </div>
        </div>
      </HTMLContainer>
    );
  };

  // [8]
  indicator = () => {
    return null;
  };
}
