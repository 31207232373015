// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ButtonNew, Icon, Typography } from "@mg/dali/src";
import {
  Navigate,
  Outlet,
  // eslint-disable-next-line import/named
  createRoute,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  useNavigate,
  useRouter,
} from "@tanstack/react-router";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { z } from "zod";

import { EditProfileHeader } from "./components/EditHeader";
import { aboutMeEditRoute } from "./routes/AboutMe/editRoute";

import { requiresAuth } from "../../utils/auth";
import { useAppSelector } from "../../utils/hooks";
import { useProfile } from "../../utils/queries/profile";
import { authLayoutRoute } from "../auth-layout/route";

export const editProfileRoute = createRoute({
  getParentRoute: () => authLayoutRoute,
  path: "profile-edit",
  beforeLoad() {
    requiresAuth();
  },
  component: function Component() {
    const user = useAppSelector((state) => state.auth.value);
    const profile = useProfile(user?.userID as string);
    const creative = profile.data;
    const router = useRouter();

    if (profile.isLoading) {
      return <h2>Loading...</h2>;
    }

    if (creative == null) {
      return null;
    }

    if (!router.state.location.pathname.includes("about-me")) {
      return <Navigate to={aboutMeEditRoute.to} />;
    }
    return (
      <div className="mx-auto grid h-screen auto-rows-min gap-6 overflow-y-auto px-4 pb-4 md:px-10 md:py-9">
        <EditProfileHeader />
        <Outlet />
      </div>
    );
  },
});
