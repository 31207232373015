import { ButtonNew, Chip, Icon, Typography } from "@mg/dali/src";
import { useMutation } from "@tanstack/react-query";
// eslint-disable-next-line import/named, @typescript-eslint/no-unused-vars
import { Link, Route, createRoute } from "@tanstack/react-router";
import cx from "classnames";
import {
  type ChangeEventHandler,
  useState,
  type DragEventHandler,
} from "react";

import { importPortfolioRoute } from "./import-portfolio";
import { whatsNextRoute } from "./whats-next";

import { useUI } from "../../../contexts/ui";
import { aiParseImage } from "../../../services/profile";
import { presignedPost, uploadToS3 } from "../../../services/upload";
import { updateUserProfile } from "../../../services/userProfile";
import { requiresAuth } from "../../../utils/auth";
import { rootRoute } from "../../root/route";
import { BottomNavigation } from "../components/BottomNavigation";

const BYTES_PER_MB = 1_048_576;
const MAX_SIZE_MB = 4;
const MAX_FILE_SIZE = MAX_SIZE_MB * BYTES_PER_MB; // 4MB in bytes
const MIN_LONG_EDGE = 600; // px
const MIN_SHORT_EDGE = 250; // px

export const projectImageUploadRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "onboarding/create-project",
  beforeLoad() {
    requiresAuth();
  },
  component: function Component() {
    const { notify } = useUI();

    const presignedPostMutation = useMutation({
      mutationKey: ["presigned-post"],
      mutationFn: presignedPost,
    });
    const s3Mutation = useMutation({
      mutationKey: ["s3", "project"],
      mutationFn: uploadToS3,
    });
    const imageParserMutation = useMutation({
      mutationKey: ["ai-image-parser"],
      mutationFn: aiParseImage,
    });

    const profileMutation = useMutation({
      mutationFn: updateUserProfile,
    });

    const [previewImage, setPreviewImage] = useState<HTMLImageElement | null>(
      null,
    );

    async function generateImagePreview(file: File) {
      const preview = URL.createObjectURL(file);
      const image = await new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          resolve(img);
        };
        img.onerror = reject;
        img.src = preview;
      });

      return image as HTMLImageElement;
    }

    const handleChange: ChangeEventHandler<HTMLInputElement> = async ({
      target,
    }) => {
      profileMutation.mutate({ highlights: [] });
      const fileList = target.files;

      if (fileList == null || (fileList != null && fileList.length === 0)) {
        return;
      }

      const file = fileList[0];

      if (file.size > MAX_FILE_SIZE) {
        return notify({
          variant: "error",
          title: "Image too large",
          message: `Please choose an image smaller than ${MAX_SIZE_MB}MB`,
        });
      }

      const image = await generateImagePreview(file);

      if (
        image.naturalWidth + image.naturalHeight <
        MIN_LONG_EDGE + MIN_SHORT_EDGE
      ) {
        return notify({
          variant: "error",
          title: "Image too small",
          message: `Please choose an image larger than ${MIN_LONG_EDGE}px by ${MIN_SHORT_EDGE}px`,
        });
      }
      setPreviewImage(image);
      const { name } = file;
      const cleanName = name.replace(/[!'()*]/g, "");
      //@ts-expect-error TS2339: Property 'fields' does not exist on type 'void'
      const { fields, url } =
        await presignedPostMutation.mutateAsync(cleanName);

      const payload = new FormData();

      Object.keys(fields).forEach((key) => {
        payload.append(key, fields[key as keyof typeof fields]);
      });

      payload.set("file", file, cleanName);

      await s3Mutation.mutateAsync({ url, payload });
      await imageParserMutation.mutateAsync(fields.key);
    };

    const handleDrop: DragEventHandler<HTMLLabelElement> = async (event) => {
      event.preventDefault();
      if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
        const fileEvent = { target: { files: event.dataTransfer.files } };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await handleChange(fileEvent as any);
        event.dataTransfer.clearData();
      }
    };

    const handleDragOver: DragEventHandler<HTMLLabelElement> = (event) => {
      event.preventDefault();
    };

    if (
      imageParserMutation.isSuccess &&
      imageParserMutation.data != null &&
      previewImage != null
    ) {
      return (
        <div className="flex h-dvh flex-col">
          <div className="grid size-full overflow-auto p-6 text-center md:px-10 md:py-20">
            <article className="mx-auto flex flex-col gap-6 text-center md:max-w-[900px]">
              <Typography
                as="h1"
                className="text-4xl md:text-6xl"
                weight="bold"
              >
                Your First Project
              </Typography>

              <Typography size="xl" weight="normal">
                Based on your image, we&apos;ve extracted some basic
                information. You can edit this project and add more detail in
                your profile.
              </Typography>

              <article className="grid gap-4 rounded-2xl border-2 border-carbon-300 p-4 text-left">
                <header className="flex items-center justify-between">
                  <Typography size="2xl" weight="bold">
                    {imageParserMutation.data[0].title}
                  </Typography>

                  <button
                    className="z-20 p-1.5"
                    onClick={() => {
                      setPreviewImage(null);
                      imageParserMutation.reset();
                      profileMutation.mutate({ highlights: [] });
                    }}
                  >
                    <Icon.XCircle size={24} />
                  </button>
                </header>

                <section className="grid grid-cols-1 gap-6 md:grid-cols-2">
                  <figure className="relative h-max overflow-hidden rounded">
                    <img
                      src={previewImage.src}
                      alt={previewImage.src}
                      className="size-full object-cover"
                    />
                  </figure>

                  <div className="grid auto-rows-max gap-6">
                    <div className="grid gap-2">
                      <Typography size="xl" weight="medium">
                        Deliverables &amp; Skills
                      </Typography>

                      <div className="flex flex-wrap gap-2">
                        {imageParserMutation.data[0].deliverables?.map(
                          (d, i) => (
                            <Chip
                              key={`.${i}.${d}`}
                              label={d}
                              variant="primary-outlined"
                            />
                          ),
                        )}

                        {imageParserMutation.data[0].skills?.map((d, i) => (
                          <Chip
                            key={`.${i}.${d}`}
                            label={d}
                            variant="primary-outlined"
                          />
                        ))}
                      </div>
                    </div>

                    <div className="grid gap-2">
                      <Typography size="xl" weight="medium">
                        Industry
                      </Typography>

                      <div className="flex flex-wrap gap-2">
                        <Chip
                          label={imageParserMutation.data[0].industry ?? "N/A"}
                          variant="primary-outlined"
                        />
                      </div>
                    </div>

                    <div className="grid gap-2">
                      <Typography size="xl" weight="medium">
                        Brand
                      </Typography>

                      <div className="flex flex-wrap gap-2">
                        <Chip
                          label={imageParserMutation.data[0].company ?? "N/A"}
                          variant="primary-outlined"
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </article>
            </article>
          </div>

          <BottomNavigation activeStep={3}>
            <div className="flex items-center justify-between p-6">
              <Link to={importPortfolioRoute.to}>
                <ButtonNew size="sm" variant="ghost">
                  Back
                </ButtonNew>
              </Link>

              <nav className="flex gap-4">
                <Link to={whatsNextRoute.to}>
                  <ButtonNew size="sm">Finish</ButtonNew>
                </Link>
              </nav>
            </div>
          </BottomNavigation>
        </div>
      );
    }

    return (
      <div className="flex h-dvh flex-col">
        <div className="grid size-full overflow-auto p-6 text-center md:px-10 md:py-20">
          <article className="mx-auto flex flex-col gap-6 text-center md:max-w-[900px]">
            <Typography as="h1" className="text-4xl md:text-6xl" weight="bold">
              Add an image to your project
            </Typography>

            <Typography size="xl" weight="normal">
              You&apos;ll need an asset to get started. You can add more or make
              changes later.
            </Typography>

            {previewImage != null ? (
              <figure className="relative">
                <img src={previewImage.src} alt={previewImage.src} />

                <figcaption
                  className={cx(
                    "absolute inset-0 z-[1] grid place-content-center justify-items-center gap-6 bg-base-black/75 text-base-white opacity-0",
                    {
                      "opacity-100":
                        presignedPostMutation.isPending ||
                        s3Mutation.isPending ||
                        imageParserMutation.isPending,
                    },
                  )}
                >
                  <Typography size="lg" weight="bold">
                    Processing. This may take several seconds, but no more than
                    30...
                  </Typography>
                  <Icon.CircleNotch size={40} className="animate-spin" />
                </figcaption>
              </figure>
            ) : (
              <label
                htmlFor="project-asset"
                className="grid cursor-copy justify-items-center gap-4 rounded-2xl border-2 border-carbon-300 p-4 text-base-black"
                onDragOver={handleDragOver}
                onDrop={handleDrop}
              >
                <input
                  type="file"
                  accept="image/jpeg, image/png, image/webp, image/gif"
                  name="project-asset"
                  id="project-asset"
                  className="hidden"
                  onChange={handleChange}
                />
                <Icon.Images size={40} color="rgb(var(--base-black))" />
                <Typography size="lg" weight="bold">
                  Drag an image here or click to upload from your device.
                </Typography>
                <Typography as="span">
                  <Typography as="p">
                    Recommended Dimensions:{" "}
                    <Typography as="span" weight="bold">
                      950px by 534px
                    </Typography>
                  </Typography>

                  <Typography as="p">
                    Minimum Width: 950px. Maximum size:{" "}
                    <Typography as="span" weight="bold">
                      4MB
                    </Typography>
                  </Typography>

                  <Typography as="p">
                    Supported file types:{" "}
                    <Typography as="span" weight="bold">
                      .png, .jpg, .gif, .webp
                    </Typography>
                  </Typography>
                </Typography>
              </label>
            )}
          </article>
        </div>

        <BottomNavigation activeStep={3}>
          <div className="flex items-center justify-between p-6">
            <Link to={importPortfolioRoute.to}>
              <ButtonNew size="sm" variant="ghost">
                Back
              </ButtonNew>
            </Link>

            <nav className="flex gap-4">
              <ButtonNew size="sm" disabled>
                Finish
              </ButtonNew>
            </nav>
          </div>
        </BottomNavigation>
      </div>
    );
  },
});
