import { extendZodWithOpenApi } from "@asteasolutions/zod-to-openapi/dist/zod-extensions";
import { z } from "zod";

import { enterpriseSchema } from "./enterprise";
import { userSchema } from "./user";
extendZodWithOpenApi(z);

export const baseFolderSchema = z.object({
  _id: z.string().optional(),
  name: z.string(),
  enterprise: z.union([z.string(), enterpriseSchema]),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  deletedAt: z.coerce.date().optional(),
  createdBy: z.union([z.string(), userSchema.partial()]).optional(),
  participants: z.array(z.union([z.string(), userSchema.partial()])).optional(),
  allowList: z.array(z.union([z.string(), userSchema.partial()])).optional(),
});

export const folderSchema = baseFolderSchema.extend({
  parentFolder: z.union([z.string(), baseFolderSchema]).optional().nullable(),
});

export const baseFolderTreeSchema = z.object({
  _id: z.string().optional(),
  name: z.string(),
});

type FolderWithSubfolder = z.infer<typeof baseFolderTreeSchema> & {
  folders?: FolderWithSubfolder[];
};

export const folderTreeSchema: z.ZodType<FolderWithSubfolder> =
  baseFolderTreeSchema
    .extend({
      folders: z.lazy(() => folderTreeSchema.array()),
    })
    .openapi({
      type: "array",
      items: {
        type: "object",
      },
    });

export type FolderTree = z.infer<typeof folderTreeSchema>;

export type Folder = z.infer<typeof folderSchema>;

export const breadcrumbSchema = z.object({
  _id: z.string(),
  name: z.string(),
});

export type Breadcrumb = z.infer<typeof breadcrumbSchema>;
