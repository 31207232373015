import {
  AssetRecordType,
  type Editor,
  getHashForString,
  MediaHelpers,
  type StoreSnapshot,
  type TLRecord,
  type TLShapePartial,
  type TLImageShape,
  type TLShape,
  Box,
  type TLStore,
} from "@tldraw/tldraw";

import { loadPdf } from "./pdfs";

import { uploadImageAsset, uploadToS3 } from "../../services/upload";
import { assetForUser } from "../imageHandler";
import { setLoading } from "../slices/ticket";
import { store } from "../store";

type FileType = "file";
type FileInput = {
  type: FileType;
  file: File;
};

async function isGifAnimated(file: Blob): Promise<boolean> {
  const arrayBuffer = await file.arrayBuffer();
  const byteArray = new Uint8Array(arrayBuffer);
  let isAnimated = false;
  let i = 0;

  // GIF frame signature
  const FRAME_SIGNATURE = [0x21, 0xf9, 0x04];

  while (i < byteArray.length - 3) {
    if (
      byteArray[i] === FRAME_SIGNATURE[0] &&
      byteArray[i + 1] === FRAME_SIGNATURE[1] &&
      byteArray[i + 2] === FRAME_SIGNATURE[2]
    ) {
      isAnimated = true;
      break;
    }
    i++;
  }

  return isAnimated;
}

export function externalAssetHandler(editor: Editor) {
  return async function (fileInput: FileInput) {
    store.dispatch(setLoading(true));
    const { file } = fileInput;
    const payload = (await uploadImageAsset(file)) as FormData;
    const url = payload?.get("url") as string;
    const key = payload?.get("key") as string;
    payload?.delete("url");

    await uploadToS3({ url, payload });
    const originalUrl = `https://static.puntt.ai/${key}`;

    // Commit the upload to the canvas
    if (file.type.includes("image")) {
      const fileUrl = assetForUser(key) as string;
      const assetId = AssetRecordType.createId(
        getHashForString(fileUrl as string),
      );
      const size = await MediaHelpers.getImageSize(file);

      const asset = AssetRecordType.create({
        id: assetId,
        type: "image",
        typeName: "asset",
        props: {
          name: file.name,
          // TODO: downloading doesn't work yet and we need
          // to add that functionality in.
          src: originalUrl,
          ...size,
          mimeType: file.type,
          isAnimated: file.type === "image/gif" && (await isGifAnimated(file)),
        },
        meta: {
          url: `${url}/${key}`,
        },
      });

      store.dispatch(setLoading(false));

      return asset;
    }

    const shapeHeight = 250;
    const shapeWidth = 300;
    if (file.type.includes("pdf")) {
      await createAssetsFromPdf(file, editor);

      store.dispatch(setLoading(false));
    } else {
      editor.createShape({
        type: "external",
        meta: {
          label: file.name,
          w: shapeWidth,
          h: shapeHeight,
          href: originalUrl,
        },
        x: (window.innerWidth - shapeWidth) / 2,
        y: (window.innerHeight - shapeHeight) / 2,
      });

      store.dispatch(setLoading(false));

      return null;
    }
  };
}

export const filterCommentsFromStore = (data: StoreSnapshot<TLRecord>) => {
  const filteredStore = Object.entries(data.store)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(([key, value]) => (value as TLShape).type !== "comment")
    .reduce((acc: { [key: string]: TLRecord }, [key, value]) => {
      acc[key] = value as TLRecord;
      return acc;
    }, {});

  return {
    ...data,
    store: filteredStore,
  };
};

export function escapeRegex(s: string): string {
  return s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
}
export const createAssetsFromPdf = async (file: File, editor: Editor) => {
  const pdf = await loadPdf(file.name, await file.arrayBuffer());

  editor.createAssets(
    pdf.pages.map((page) => ({
      id: page.assetId,
      typeName: "asset",
      type: "image",
      meta: {},
      props: {
        w: page.bounds.w,
        h: page.bounds.h,
        mimeType: "image/png",
        src: page.src,
        name: "page",
        isAnimated: false,
      },
    })),
  );
  editor.createShapes(
    pdf.pages.map(
      (page): TLShapePartial<TLImageShape> => ({
        id: page.shapeId,
        type: "image",
        x: page.bounds.x,
        y: page.bounds.y,
        props: {
          assetId: page.assetId,
          w: page.bounds.w,
          h: page.bounds.h,
        },
        meta: {
          url: page.src,
        },
      }),
    ),
  );
  editor.groupShapes(pdf.pages.map((page) => page.shapeId));
};

export function updateCameraBounds(targetBounds: Box, editor: Editor) {
  const isMobile = editor.getViewportScreenBounds().width < 840;
  editor.setCameraOptions({
    constraints: {
      bounds: targetBounds,
      padding: { x: isMobile ? 16 : 164, y: 100 },
      origin: { x: 0.5, y: 0 },
      initialZoom: "fit-x-100",
      baseZoom: "default",
      behavior: "contain",
    },
  });

  editor.setCamera(editor.getCamera(), { reset: true });
}

// Function to calculate the union of bounds of an array of shapes
export function getShapesBounds(shapes: TLImageShape[]): Box {
  if (shapes.length === 0) {
    return new Box(0, 0, 0, 0);
  }

  const initialBounds = new Box(
    shapes[0].x,
    shapes[0].y,
    shapes[0].props.w,
    shapes[0].props.h,
  );

  const targetBounds = shapes.reduce((acc, shape) => {
    const shapeBounds = new Box(shape.x, shape.y, shape.props.w, shape.props.h);
    return acc.union(shapeBounds);
  }, initialBounds.clone());

  return targetBounds;
}

export function getFilteredImageShapes(
  shapes: TLStore | TLShape[],
): TLImageShape[] {
  if (Array.isArray(shapes)) {
    return shapes.filter(
      (shape) =>
        (shape as TLShape).typeName == "shape" &&
        (shape as TLShape).type == "image",
    ) as TLImageShape[];
  }
  return Object.values(shapes).filter(
    (shape) =>
      (shape as TLShape).typeName == "shape" &&
      (shape as TLShape).type == "image",
  );
}

export function filterAndSortShapes(shapes: TLImageShape[]): TLImageShape[] {
  // Filter to keep only one shape per unique meta.url and y + props.h combination
  const uniqueShapes = Object.values(
    shapes.reduce(
      (acc, shape) => {
        const key = `${shape.y}-${shape.props.h}`;
        acc[key] = shape;
        return acc;
      },
      {} as { [key: string]: TLImageShape },
    ),
  );

  // Sort by the y value from lowest to highest
  uniqueShapes.sort((a, b) => a.y - b.y);

  return uniqueShapes;
}
