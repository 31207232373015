import {
  GetTicketsResponse,
  ResponseFolder,
} from "@mg/schemas/src/christo/catalyst";

import { isNil } from "./fp/isNil";
import { store } from "./store";

type TicketStub = GetTicketsResponse[number];

export function fileToTicketStub(file: File): TicketStub {
  const enterpriseId = store.getState().auth.value?.enterpriseId;
  const nowIso = new Date().toISOString();

  if (isNil(enterpriseId)) {
    throw new Error("Enterprise ID is required to create a ticket stub");
  }

  return {
    _id: crypto.randomUUID(),
    title: file.name,
    isPending: true,
    createdAt: nowIso,
    updatedAt: nowIso,
    enterprise: enterpriseId,
    thumbnails: [],
    participants: [],
    totalRevisions: 1,
    // TODO: this is a naive setting
    totalFiles: 1,
  };
}

type FolderStub = Omit<ResponseFolder, "createdAt" | "updatedAt"> & {
  createdAt: string;
  updatedAt: string;
};

export function directoryNameToFolderStub(
  directoryName: string,
  parentId?: string,
): FolderStub {
  const enterpriseId = store.getState().auth.value?.enterpriseId;
  const now = new Date().toISOString();

  if (isNil(enterpriseId)) {
    throw new Error("Enterprise ID is required to create a folder stub");
  }

  return {
    _id: crypto.randomUUID(),
    name: directoryName,
    createdAt: now,
    updatedAt: now,
    enterprise: enterpriseId,
    parentFolder: parentId,
    thumbnails: [],
    participants: [],
    // TODO: this is a naive setting
    totalFiles: 1,
  };
}
