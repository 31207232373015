import { useMutation, useQuery } from "@tanstack/react-query";
import { useSearch } from "@tanstack/react-router";
import { useEffect } from "react";

import { useUI } from "../../contexts/ui";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { myNetworkRoute } from "../../routes/network/route";
import { getDesigner, updateTags, getTags } from "../../services/profile";
import { useAppDispatch } from "../hooks";
import { queryClient } from "../queryClient";
import { setProfile } from "../slices/profile";

export function useProfile(profileId?: string) {
  const dispatch = useAppDispatch();
  const search = useSearch({ strict: false });
  // @ts-expect-error TS2345: could spend a long time sorting this error out.
  const queries: (string | number)[] = Object.keys(search).flatMap((key) => {
    return Array.isArray(search[key as keyof typeof search])
      ? search[key as keyof typeof search]
      : ([] as string[]);
  });
  const query = useQuery({
    queryKey: ["profile", profileId, queries],
    queryFn: () => getDesigner(profileId as string, queries),
    enabled: profileId != null,
  });

  useEffect(() => {
    if (query.data != null) {
      dispatch(setProfile(query.data));
    }
  }, [query.data, dispatch]);

  return query;
}

export function useUpdateTags(profileId: string) {
  const { notify } = useUI();
  const mutation = useMutation({
    mutationKey: ["update-tags"],
    mutationFn: updateTags,
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ["profile", profileId] });
      notify({
        variant: "success",
        title: "Tags saved successfully",
      });
    },
  });

  return mutation;
}

export function useTags() {
  const query = useQuery({
    queryKey: ["tags"],
    queryFn: getTags,
  });

  return query;
}
