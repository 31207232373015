// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { modulate } from "@tldraw/tldraw";
import { useId } from "react";
import { type TLGridProps } from "tldraw";

const gridSteps = [
  { min: -1, mid: 0.15, step: 64 },
  { min: 0.05, mid: 0.375, step: 16 },
  { min: 0.15, mid: 1, step: 4 },
  { min: 0.7, mid: 2.5, step: 1 },
];

export function Checkerboard({ x, y, z, size }: TLGridProps) {
  const id = `grid_${useId().replace(/:/g, "_")}`;
  return (
    <svg className="tl-grid" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        {gridSteps.map(({ min, mid, step }, i) => {
          const s = step * size * z;
          const xo = x * z;
          const yo = y * z;
          const gxo = xo > 0 ? xo % s : s + (xo % s);
          const gyo = yo > 0 ? yo % s : s + (yo % s);
          const opacity = Math.pow(
            Math.min(1, Math.max(0, (z - min) / (mid - min))),
            4,
          );

          return (
            <pattern
              key={i}
              id={`${id}_${step}`}
              width={s}
              height={s}
              patternUnits="userSpaceOnUse"
              patternTransform={`translate(${gxo},${gyo})`}
            >
              <rect
                width={s / 2}
                height={s / 2}
                fill="#f0f0f0"
                opacity={opacity}
              />
              <rect
                x={s / 2}
                width={s / 2}
                height={s / 2}
                fill="#e0e0e0"
                opacity={opacity}
              />
              <rect
                y={s / 2}
                width={s / 2}
                height={s / 2}
                fill="#e0e0e0"
                opacity={opacity}
              />
              <rect
                x={s / 2}
                y={s / 2}
                width={s / 2}
                height={s / 2}
                fill="#f0f0f0"
                opacity={opacity}
              />
            </pattern>
          );
        })}
      </defs>
      {gridSteps.map(({ step }, i) => (
        <rect key={i} width="100%" height="100%" fill={`url(#${id}_${step})`} />
      ))}
    </svg>
  );
}
