import { Button, Dialog, Flex, TextField } from "@radix-ui/themes";
import { useState, useEffect, useMemo } from "react";

import { useAppSelector } from "../../../utils/hooks";

type RenameDialogProps = {
  _id?: string;
  onSave(ticketId: string, name: string): void;
  onCancel(): void;
  isPending: boolean;
};

export function RenameDialog(props: RenameDialogProps) {
  const { isPending, onCancel, onSave, _id } = props;
  const tickets = useAppSelector((state) => state.punttProjects.tickets);
  const folders = useAppSelector((state) => state.punttProjects.folders);

  const markedFolders = useMemo(
    () =>
      folders.map((f) => ({
        ...f,
        isFolder: true,
      })),
    [folders],
  );
  const mergedFoldersAndTickets = useMemo(
    () =>
      [...markedFolders, ...tickets].toSorted((a, b) => {
        const dateA = new Date(a.updatedAt as string);
        const dateB = new Date(b.updatedAt as string);

        // Sort in descending order (most recent first)
        return dateB.getTime() - dateA.getTime();
      }),
    [markedFolders, tickets],
  );

  const found = mergedFoldersAndTickets.find((t) => t._id === _id);
  // @ts-expect-error TS2339: Yeah, this type is really messed up.
  const [name, setName] = useState(found?.name ?? found?.title ?? "");
  useEffect(() => {
    // @ts-expect-error TS2339: Yeah, this type is really messed up.
    setName(found?.name ?? found?.title ?? "");
  }, [found?._id]);

  return (
    <Dialog.Root open={_id != null}>
      <Dialog.Content size="1" maxWidth="300px">
        <Dialog.Title size="2">
          Rename {found?.isFolder ? "folder" : "ticket"}?
        </Dialog.Title>
        <TextField.Root
          size="1"
          placeholder="Folder Name"
          name="folder-name"
          onChange={(e) => setName(e.target.value)}
          value={name}
        />

        <Flex gap="2" mt="3" justify="end">
          <Button
            size="1"
            variant="soft"
            color="gray"
            onClick={() => {
              onCancel();
              setName("");
            }}
          >
            Cancel
          </Button>
          <Button
            size="1"
            color="red"
            onClick={() => {
              onSave(_id as string, name);
              setName("");
            }}
            disabled={isPending}
            loading={isPending}
          >
            Save
          </Button>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
}
