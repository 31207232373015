import { ButtonNew, Input, Select, Typography } from "@mg/dali/src";
import { useMutation, useQuery } from "@tanstack/react-query";
// eslint-disable-next-line import/named, @typescript-eslint/no-unused-vars
import { Route, createRoute } from "@tanstack/react-router";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { type ChangeEventHandler, useState, useEffect } from "react";

import { getBrands } from "../../../services/concepts";
import { updateUserProfile } from "../../../services/userProfile";
import { requiresAuth } from "../../../utils/auth";
import { useCurrentUserProfile } from "../../../utils/queries/userProfile";
import { rootRoute } from "../../root/route";

export const projectDetailsRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "onboarding/project-details",
  beforeLoad() {
    requiresAuth();
  },
  component: function Component() {
    const [details, setDetails] = useState<{
      title: string;
      company: string | undefined;
    }>({ title: "", company: undefined });
    const profile = useCurrentUserProfile();
    const brands = useQuery({
      queryKey: ["brands"],
      queryFn: getBrands,
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const profileMutation = useMutation({
      mutationKey: ["user-profile"],
      mutationFn: updateUserProfile,
    });

    useEffect(() => {
      if (profile.data != null) {
        if (
          profile.data.highlights != null &&
          profile.data.highlights.length > 0
        ) {
          setDetails({
            title: profile.data.highlights[0].title,
            company: profile.data?.highlights[0].company,
          });
        }
      }
    }, [profile.data]);

    const formattedBrands =
      brands.data?.map((b) => ({ label: b, value: b })) ?? [];

    return (
      <div className="w-full bg-carbon-50 text-center text-base-black">
        <div className="mx-auto h-dvh max-w-screen-2xl bg-base-white">
          <article className="mx-auto grid max-w-[900px] gap-6 px-10 pb-9 pt-20">
            <Typography as="h1" size="6xl" weight="bold">
              Add some project details
            </Typography>

            <Typography as="p" size="xl" weight="normal">
              We need some information about your project so we can display its
              majesty
            </Typography>

            <Input
              size="sm"
              label="Project Name *"
              placeholder="What is your project called?"
              type="text"
              className="text-left"
              value={details.title ?? ""}
              onChange={({ target }) => {
                setDetails((prev) => {
                  const res = { ...prev };
                  res.title = target.value;

                  return res;
                });
              }}
              disabled={profile.isLoading}
              invalid={details.title === ""}
            />

            <Select<false>
              label="Project Company *"
              placeholder="What company did you make this for?"
              className="text-left"
              value={{
                label: details.company as string,
                value: details.company as string,
              }}
              allowCustom
              onChange={(value) => {
                setDetails((prev) => {
                  const res = { ...prev };
                  res.company = value?.value as string;

                  return res;
                });
              }}
              options={formattedBrands}
              disabled={profile.isLoading}
            />

            <ButtonNew className="mx-auto max-w-max">Save &amp; Next</ButtonNew>
          </article>
        </div>
      </div>
    );
  },
});
