import { ButtonNew, Icon, Typography } from "@mg/dali/src";
// eslint-disable-next-line import/named
import { createRoute, useNavigate } from "@tanstack/react-router";
import { z } from "zod";

import { AboutMe } from "./components/AboutMe";
import { InvitePipeline } from "./components/InvitePipeline";
import { ProfessionalDetails } from "./components/ProfessionalDetails";
import { Projects } from "./components/Projects";
import { myWorkEditRoute } from "./routes/MyWork/editRoute";
import { myWorkListRoute } from "./routes/MyWork/viewRoute";

import { CompleteYourProfileBanner } from "../../components/CompleteYourProfileBanner";
import { requiresAuth } from "../../utils/auth";
import { useAppSelector } from "../../utils/hooks";
import { searchParser } from "../../utils/parsers/search";
import { useProfile } from "../../utils/queries/profile";
import { authLayoutRoute } from "../auth-layout/route";

const profileSearchParser = searchParser.extend({
  showEndorsement: z.boolean().optional(),
});

export const userProfileRoute = createRoute({
  getParentRoute: () => authLayoutRoute,
  path: "profile",
  beforeLoad() {
    requiresAuth();
  },
  validateSearch(search: Record<string, unknown>) {
    return profileSearchParser.parse(search);
  },
  component: function Component() {
    const user = useAppSelector((state) => state.auth.value);
    const profile = useProfile(user?.userID as string);
    const navigate = useNavigate();
    const creative = profile.data;

    if (profile.isLoading) {
      return <h2>Loading...</h2>;
    }

    if (creative == null) {
      return null;
    }

    return (
      <div className="mx-auto grid h-screen auto-rows-min gap-y-6 overflow-y-auto px-4 pb-24 pt-4 md:px-10 md:py-9">
        <CompleteYourProfileBanner step={creative.profileStatus?.nextStep} />
        <div className="grid auto-rows-min grid-cols-1 gap-y-6 md:grid-cols-5 md:gap-6">
          <div className="col-span-2 grid auto-rows-min gap-6">
            <AboutMe {...creative} isUser />
            <ProfessionalDetails {...creative} />
          </div>
          <div className="col-span-3">
            <div className="mb-6 flex justify-between">
              <Typography as="h2" size="xl" weight="medium">
                Projects
              </Typography>
              <div className="flex gap-3">
                <ButtonNew
                  variant="ghost"
                  size="xs"
                  onClick={() =>
                    navigate({
                      to: myWorkEditRoute.to,
                      // @ts-expect-error TS2353: another case where we have
                      // untracked search params
                      search: { fromProject: true },
                    })
                  }
                  className="max-w-fit"
                >
                  <Icon.PlusCircle />
                  Add Project
                </ButtonNew>
                <ButtonNew
                  variant="ghost"
                  size="xs"
                  onClick={() => navigate({ to: myWorkListRoute.to })}
                >
                  <Icon.PencilSimpleLine />
                  <Typography weight="medium">Edit Projects</Typography>
                </ButtonNew>
              </div>
            </div>
            <Projects />
          </div>
        </div>

        <InvitePipeline />
      </div>
    );
  },
});
