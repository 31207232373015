// eslint-disable-next-line @typescript-eslint/no-unused-vars
import cx from "classnames";
import { useState, useEffect, type FC } from "react";

export const ProgressBarLoader: FC = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(interval);
          return 100;
        }
        return Math.min(oldProgress + 0.5, 100);
      });
    }, 300);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const getColor = (value: number) => {
    if (value < 25) return "bg-malachite-500";
    if (value < 50) return "bg-ochre-500";
    if (value < 75) return "bg-cadmium-500";
    return "bg-egyptian-blue-500";
  };

  return (
    <div className="w-full overflow-hidden rounded-lg bg-carbon-200">
      <div
        style={{ width: `${progress}%` }}
        className={`h-4 transition-all duration-1000 ease-in-out ${getColor(
          progress,
        )}`}
      />
    </div>
  );
};
