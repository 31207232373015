import { Button, Dialog, Flex, TextField } from "@radix-ui/themes";
import { useRouter } from "@tanstack/react-router";

import { useCreateFolderMutation } from "../../utils/queries/projects";
import { queryClient } from "../../utils/queryClient";

type CreateFolderDialogProps = {
  // TODO: abstract these attributes to a more global type
  open: boolean;
  onOpenChange(open: boolean): void;
  // attributes specific to this component
  folderId?: string;
};

export function CreateFolderDialog(props: CreateFolderDialogProps) {
  const { folderId, open, onOpenChange } = props;

  const router = useRouter();
  const createFolderMutation = useCreateFolderMutation();

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Content maxWidth="332px" size="1">
        <Dialog.Title size="2">New Folder</Dialog.Title>

        <form
          onSubmit={(e) => {
            e.preventDefault();

            const form = e.target;
            const formData = new FormData(form as HTMLFormElement);
            createFolderMutation.mutate(
              {
                folder: {
                  folderId,
                  name: formData.get("folder-name") as string,
                },
                tickets: [],
              },
              {
                onSuccess() {
                  queryClient.invalidateQueries({
                    queryKey: ["folders", "tree"],
                  });
                  router.invalidate();
                  onOpenChange(false);
                },
              },
            );
          }}
          className="grid gap-4"
        >
          <TextField.Root
            required
            size="1"
            variant="classic"
            placeholder="Folder Name"
            name="folder-name"
          />

          <Flex gap="4" align="center" justify="end">
            <Dialog.Close>
              <Button
                size="1"
                variant="soft"
                color="gray"
                disabled={createFolderMutation.isPending}
              >
                Cancel
              </Button>
            </Dialog.Close>

            <Button
              type="submit"
              size="1"
              disabled={createFolderMutation.isPending}
              loading={createFolderMutation.isPending}
            >
              Create Folder
            </Button>
          </Flex>
        </form>
      </Dialog.Content>
    </Dialog.Root>
  );
}
