import {
  // eslint-disable-next-line import/named
  createRoute,
} from "@tanstack/react-router";

import { authLayoutRoute } from "../../auth-layout/route";
import { TutorialRevision } from "../components/TutorialRevision";

export const tutorialTicketRoute = createRoute({
  getParentRoute: () => authLayoutRoute,
  path: "tutorial-ticket",
  component: TutorialRevision,
});
