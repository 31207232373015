import { Typography } from "@mg/dali/src";
import { type WorkosLoginResponse } from "@mg/schemas/src/prince/auth";
import { Box, Button, Flex, Text, Dialog } from "@radix-ui/themes";
import cx from "classnames";
import { useState } from "react";
import OtpInput from "react-otp-input";

import { useUI } from "../../contexts/ui";

interface MfaFormProps {
  mfaOptions: WorkosLoginResponse;
  loading: boolean;
  onVerify: (otp: string) => void;
  error?: boolean;
  errorMessage?: string;
  guestLogin?: boolean;
}

export function MfaForm({
  mfaOptions,
  onVerify,
  loading,
  error,
  errorMessage,
  guestLogin = false,
}: MfaFormProps) {
  const [otp, setOtp] = useState("");
  const [secretKeyModalOpen, setSecretKeyModalOpen] = useState(false);
  const { notify } = useUI();

  const onCopyLink = () => {
    navigator.clipboard.writeText(
      mfaOptions?.authenticationFactor?.totp?.secret as string,
    );
    notify({
      title: "Copied to Clipboard!",
      message: "",
    });
  };

  return (
    <Box mt="4" className="px-4">
      {mfaOptions?.authenticationFactor && (
        <Flex direction="column" gap="4">
          <Dialog.Root
            open={secretKeyModalOpen}
            onOpenChange={setSecretKeyModalOpen}
          >
            <Dialog.Content>
              <div className="mb-4 flex items-center justify-end gap-2">
                <Dialog.Close>
                  <Button color="gray" variant="soft" size="2">
                    x
                  </Button>
                </Dialog.Close>
              </div>
              <Typography>Your setup key:</Typography>
              <Flex align="center" justify="between">
                <Typography weight="bold">
                  {mfaOptions?.authenticationFactor?.totp?.secret}
                </Typography>
                <Button size="2" onClick={onCopyLink} variant="solid">
                  Copy
                </Button>
              </Flex>
            </Dialog.Content>
          </Dialog.Root>

          <Typography as="p" size={guestLogin ? "lg" : "xl"} weight="medium">
            Scan the QR code
          </Typography>
          <Typography size="base">
            Use an authenticator app like{" "}
            <a href="https://1password.com/" className="puntt-link">
              1Password
            </a>
            ,{" "}
            <a
              href="https://apps.apple.com/us/app/google-authenticator/id388497605"
              className="puntt-link"
            >
              Google Authenticator
            </a>
            ,{" "}
            <a href="https://authy.com/" className="puntt-link">
              Authy
            </a>
            , or{" "}
            <a
              href="https://www.microsoft.com/en-gb/security/mobile-authenticator-app"
              className="puntt-link"
            >
              Microsoft Authenticator
            </a>{" "}
            to scan the QR code below.
          </Typography>
          <Flex
            gap="4"
            align="center"
            justify={guestLogin ? "center" : "start"}
          >
            <img
              src={mfaOptions?.authenticationFactor?.totp?.qrCode}
              width="150"
              height="150"
              alt="QR code"
              className="mt-4 rounded-sm border-2 border-solid"
            />
            <Box>
              <Typography as="p" size="sm">
                Can‘t scan the code?
              </Typography>
              <Text
                color="blue"
                size="2"
                weight="medium"
                className="cursor-pointer"
                onClick={() => setSecretKeyModalOpen(true)}
              >
                View setup key
              </Text>
            </Box>
          </Flex>

          <hr className="mt-8" />
        </Flex>
      )}

      <Flex direction="column" gap="4" className="mt-4">
        <Typography as="p" weight="medium" size="sm">
          Enter Code From Your App
        </Typography>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          renderSeparator={<span></span>}
          renderInput={(props) => <input {...props} />}
          inputStyle={guestLogin ? "guest-otp-input-style" : "otp-input-style"}
          inputType="text"
        />
        <Text
          color="red"
          size="2"
          data-testid="mfa-error-message"
          className={cx({ hidden: !error })}
        >
          {errorMessage}
        </Text>

        <Button
          disabled={otp.length !== 6}
          loading={loading}
          data-testid="form-submit-cta"
          onClick={() => onVerify(otp)}
          size="2"
          className={cx("mt-4", "w-full", "mb-4")}
        >
          Verify
        </Button>
      </Flex>
    </Box>
  );
}
