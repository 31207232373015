import { Theme } from "@radix-ui/themes";
import { RouterProvider } from "@tanstack/react-router";
import { PostHogProvider } from "posthog-js/react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";

import { useDatadog } from "./config/datadog";
import { posthogClient } from "./config/posthog";
import { router } from "./config/router";
import { UIProvider } from "./contexts/ui";
import { QueryClientContext } from "./utils/queryClient";
import { store } from "./utils/store";

import "@mg/dali/tailwind.generated.css";
import "draft-js/dist/Draft.css";
import "@radix-ui/themes/styles.css";
import "./style.css";

function App() {
  useDatadog();

  return (
    <StrictMode>
      <PostHogProvider client={posthogClient ?? undefined}>
        <QueryClientContext>
          <Theme
            accentColor="blue"
            grayColor="sand"
            panelBackground="translucent"
            scaling="100%"
            radius="large"
            hasBackground={false}
          >
            <UIProvider>
              <ReduxProvider store={store}>
                <RouterProvider router={router} />
              </ReduxProvider>
            </UIProvider>
          </Theme>
        </QueryClientContext>
      </PostHogProvider>
    </StrictMode>
  );
}

const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);

root.render(<App />);
