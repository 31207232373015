import { ButtonNew, Card, Chip, Icon, Typography } from "@mg/dali/src";
import { type MyNetworkProfile } from "@mg/schemas/src/christo/myNetwork";
import * as Accordion from "@radix-ui/react-accordion";
import * as Tooltip from "@radix-ui/react-tooltip";
// eslint-disable-next-line import/named
import { Link, useNavigate } from "@tanstack/react-router";
import cx from "classnames";
import { useState } from "react";

import { AvatarWithInitials } from "../../../components/AvatarWithInitials";
import {
  SOCIAL_LINKS_MAP,
  SPECIALTY_ICON_MAP,
  domainRegex,
} from "../../../utils/constants";
import { useAppSelector } from "../../../utils/hooks";
import { ensureFullUrl } from "../../../utils/validation";
import { myNetworkRoute } from "../../network/route";
import { newMessageRoute } from "../../new-message/route";
import { aboutMeEditRoute } from "../../userProfile/routes/AboutMe/editRoute";

export function AboutMe(
  props: MyNetworkProfile & { onMessageClick?(): void; isUser?: boolean },
) {
  const user = useAppSelector((state) => state.auth.value);
  const [showFullDeliverables, setShowFullDeliverables] = useState({
    0: false,
    1: false,
  });
  const navigate = useNavigate();
  const isCreative =
    !user?.permissions?.includes("enterprise-team") &&
    !user?.permissions?.includes("enterprise-admin");

  const title = props.title || props.specialties?.[0];

  return (
    <Card
      size="sm"
      type="outlined"
      className="relative inline-grid w-full gap-6 rounded-2xl"
      uninteractive
    >
      <div className="flex items-start justify-between gap-4 truncate">
        <div className="flex w-full flex-1 items-center gap-2.5">
          <AvatarWithInitials
            size={16}
            name={props.name}
            avatar={props.avatar}
          />

          <section className="flex-1 truncate">
            <Typography
              as="h2"
              size="2xl"
              weight="medium"
              className="truncate text-base-black"
            >
              {props.name}
              <Typography
                size="sm"
                className={cx("inline text-carbon-600", {
                  hidden: user?.userID !== props._id,
                })}
              >
                &nbsp;&nbsp;(you)
              </Typography>
            </Typography>

            <div
              className={cx("flex items-center gap-2", {
                hidden: !title,
              })}
            >
              <Typography
                size="sm"
                className={cx("text-carbon-600", {
                  hidden: !title,
                })}
              >
                {title}
              </Typography>
            </div>
            <div
              className={cx("mt-2 flex max-w-max items-center gap-2", {
                hidden:
                  isCreative ||
                  //@ts-expect-error TS2367: BE returning wrong types
                  props.type === "pipeline" ||
                  props.trustScore == null,
              })}
            >
              <figure
                className={cx("rounded px-2 py-0.5", {
                  "bg-vermillion-600": (props.trustScore ?? 0) < 7,
                  "bg-ochre-400":
                    props.trustScore &&
                    props.trustScore >= 7 &&
                    props.trustScore <= 8,
                  "bg-malachite-600": (props.trustScore ?? 0) > 8,
                })}
              >
                <figcaption>
                  <Typography
                    size="sm"
                    weight="bold"
                    className="text-center text-base-white"
                  >
                    {props.trustScore}/10
                  </Typography>
                </figcaption>
              </figure>

              <Typography size="xs" weight="bold" className="text-carbon-400">
                Trust Score
              </Typography>
            </div>
          </section>
        </div>
      </div>
      {props.isUser ? (
        <ButtonNew
          size="sm"
          onClick={() => navigate({ to: aboutMeEditRoute.to })}
          className="flex-1 [&>span]:justify-center"
        >
          <Icon.PencilSimpleLine />
          Edit Your Profile
        </ButtonNew>
      ) : (
        <Link
          to={newMessageRoute.to}
          search={{ selections: [props._id] }}
          className={cx("flex-1", {
            hidden: user?.userID === props._id || user?.role === "guest",
          })}
        >
          <ButtonNew size="sm" className="w-full [&>span]:justify-center">
            <Icon.ChatCircleText />
            Message
          </ButtonNew>
        </Link>
      )}

      <div
        className={cx("grid gap-4", {
          //@ts-expect-error TS2367: BE returning wrong types
          hidden: props.type === "pipeline",
        })}
      >
        <article className="grid w-full gap-2">
          <Typography size="xl" weight="medium" className="text-carbon-900">
            What I Make
          </Typography>

          <Accordion.Root type="multiple">
            {props.whatIMake != null &&
              props.whatIMake.map((s, i) => (
                <Accordion.Item value={s.specialty} key={`.${i}.${s}`}>
                  <Accordion.Header className="flex">
                    <Icon.IconContext.Provider value={{ size: 24 }}>
                      <Accordion.Trigger className="group flex flex-1 items-center justify-between py-2">
                        <Typography
                          size="lg"
                          className="inline-flex items-center gap-4 text-left text-base-black"
                        >
                          {SPECIALTY_ICON_MAP[s.specialty]}
                          {s.specialty}
                        </Typography>
                        <Icon.CaretDown size={24} />
                      </Accordion.Trigger>
                    </Icon.IconContext.Provider>
                  </Accordion.Header>
                  <Accordion.Content className="flex-wrap gap-2 bg-carbon-50 p-2 data-[state=open]:flex">
                    {(showFullDeliverables[i as 0 | 1]
                      ? s.deliverables
                      : s.deliverables.slice(0, 2)
                    ).map((d) => (
                      // eslint-disable-next-line react/jsx-key
                      <Tooltip.Provider>
                        <Tooltip.Root delayDuration={150}>
                          <Tooltip.Trigger asChild>
                            <button
                              onClick={() =>
                                navigate({
                                  to: myNetworkRoute.to,
                                  //@ts-expect-error TS2322: Inheriting wrong search types
                                  search() {
                                    return { queries: [d] };
                                  },
                                })
                              }
                            >
                              <Chip
                                key={d}
                                label={d}
                                variant="primary-outlined"
                              />
                            </button>
                          </Tooltip.Trigger>
                          <Tooltip.Portal>
                            <Tooltip.Content
                              sideOffset={5}
                              className="w-fit max-w-full rounded bg-carbon-700 p-1.5 text-base-white"
                            >
                              <Typography size="sm">
                                Click to find others with this!
                              </Typography>
                              <Tooltip.Arrow className="fill-carbon-700" />
                            </Tooltip.Content>
                          </Tooltip.Portal>
                        </Tooltip.Root>
                      </Tooltip.Provider>
                    ))}

                    <button
                      onClick={() =>
                        setShowFullDeliverables((prev) => ({
                          ...prev,
                          [i]: !prev[i as 0 | 1],
                        }))
                      }
                    >
                      <Chip
                        className={cx({
                          hidden:
                            s.deliverables != null && s.deliverables.length < 3,
                        })}
                        label={`${s.deliverables.length - 2} ${
                          !showFullDeliverables[i as 0 | 1] ? "more" : "less"
                        }`}
                      />
                    </button>
                  </Accordion.Content>
                </Accordion.Item>
              ))}
          </Accordion.Root>
        </article>

        <article
          className={cx("grid flex-1 auto-rows-max gap-2", {
            hidden:
              props.brands == null ||
              (props.brands != null && props.brands.length === 0),
          })}
        >
          <Typography
            as="h3"
            size="xl"
            weight="medium"
            className="text-carbon-900"
          >
            Brands
          </Typography>

          <div className="flex flex-wrap gap-2">
            {Array.isArray(props.brands) &&
              props.brands.map((s, i) => (
                <Tooltip.Provider key={i}>
                  <Tooltip.Root delayDuration={150}>
                    <Tooltip.Trigger asChild>
                      <button
                        onClick={() =>
                          navigate({
                            to: myNetworkRoute.to,
                            //@ts-expect-error TS2322: Inheriting wrong search types
                            search() {
                              return { queries: [s] };
                            },
                          })
                        }
                      >
                        <Chip
                          key={`.${i}.${s}`}
                          variant="primary-outlined"
                          label={s}
                        />
                      </button>
                    </Tooltip.Trigger>
                    <Tooltip.Portal>
                      <Tooltip.Content
                        sideOffset={5}
                        className="w-fit max-w-full rounded bg-carbon-700 p-1.5 text-base-white"
                      >
                        <Typography size="sm">
                          Click to find others with this!
                        </Typography>
                        <Tooltip.Arrow className="fill-carbon-700" />
                      </Tooltip.Content>
                    </Tooltip.Portal>
                  </Tooltip.Root>
                </Tooltip.Provider>
              ))}
          </div>
        </article>
        <article className="grid w-full gap-2">
          <Typography size="xl" weight="medium" className="text-carbon-900">
            Bio
          </Typography>
          <Typography>
            {props.bio != null ? props.bio : "No bio available"}
          </Typography>
        </article>
        <div className="flex w-full justify-between">
          {props.yearsOfExperience != null && (
            <article className="grid flex-1 gap-2">
              <Typography size="xl" weight="medium" className="text-carbon-900">
                Experience
              </Typography>
              <Typography size="xl">
                {props.yearsOfExperience ?? 0} Year
                {props.yearsOfExperience === 1 ? "" : "s"}
              </Typography>
            </article>
          )}
          {([...(props.socialLinks ?? []), props.linkedIn, props.website] ?? [])
            .length > 0 && (
            <article className="grid flex-1 gap-2">
              <Typography size="xl" weight="medium" className="text-carbon-900">
                Links
              </Typography>
              <div className="flex h-max gap-2">
                {[...(props.socialLinks ?? []), props.website, props.linkedIn]
                  .filter((s) => s)
                  ?.map((link) => {
                    const matches = link?.match(domainRegex);

                    if (matches != null && matches.length >= 2) {
                      const domain = matches[1];

                      return (
                        <a
                          key={domain}
                          href={ensureFullUrl(link as string)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <ButtonNew className="h-10">
                            {/* @ts-expect-error TS7050: lift for this is large */}
                            {SOCIAL_LINKS_MAP[domain] ?? <Icon.LinkSimple />}
                          </ButtonNew>
                        </a>
                      );
                    } else if (link?.length) {
                      return (
                        <a
                          key={link}
                          href={ensureFullUrl(link)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <ButtonNew className="h-10">
                            <Icon.LinkSimple />
                          </ButtonNew>
                        </a>
                      );
                    }
                    return null;
                  })}
              </div>
            </article>
          )}
        </div>
      </div>
    </Card>
  );
}
