import { cva, cx } from "class-variance-authority";
import React, { forwardRef } from "react";
import { type HTMLAttributes, type ReactNode, type Ref } from "react";
import { extendTailwindMerge } from "tailwind-merge";

import { Icon, Typography } from "../foundation";
import { type TypographyProps } from "../foundation/typography/Typography";

const twMerge = extendTailwindMerge({ prefix: "dali-" });

type BaseChipProps = {
  /**
   * A mandatory label for the chip
   */
  label: string;
  /**
   * Optionally, adds a leading icon to the Chip. Leading icons are intended to
   * be informative-only and should not be interactive.
   */
  leadingIcon?: ReactNode;
  /**
   * Optionally, sets the variant of the Chip; defaults to 'primary'
   */
  variant?:
    | "primary"
    | "primary-outlined"
    | "secondary"
    | "secondary-outlined"
    | "egyptian-blue"
    | "malachite"
    | "ochre-light"
    | "ochre-dark"
    | "cadmium"
    | "vermillion"
    | "carbon-dark"
    | "carbon-light";
  /**
   * Optionally, disable the Chip entirely, including any interactive trailing
   * icons; defaults to false
   */
  disabled?: boolean;
  /**
   * Optionally, sets the size of the Chip; defaults to 'default'
   */
  size?: "sm" | "default";
  /**
   * Optionally, adds a trailing icon to the Chip. Trailing icons are intended
   * to be interactive and should always be wrapped in a <button> and have an
   * `onClick` handler. Trailing icons are never rendered on "sm" variants.
   */
  trailingIcon?: JSX.Element;
} & HTMLAttributes<HTMLDivElement>;

export type ChipProps = BaseChipProps;

const chipStyles = cva(
  [
    "mg-chip dali-inline-flex dali-items-center dali-p-px dali-rounded dali-border",
  ],
  {
    variants: {
      disabled: {
        true: [
          "!dali-bg-carbon-50",
          "!dali-border-carbon-50",
          "!dali-text-carbon-300",
          "dali-cursor-not-allowed",
          "[&>button]:dali-pointer-events-none",
        ],
        false: [],
      },
      isInteractive: {
        true: [
          "[&>button:focus]:dali-ring-2",
          "[&>button]:dali-rounded-sm",
          "[&>button]:dali-transition-all",
        ],
        false: [],
      },
      variant: {
        primary: [
          "dali-bg-base-black",
          "dali-border-base-black",
          "dali-text-base-white",
        ],
        "primary-outlined": ["dali-border-base-black", "dali-text-base-black"],
        secondary: [
          "dali-bg-vermillion-600",
          "dali-border-vermillion-600",
          "dali-text-base-white",
        ],
        "secondary-outlined": [
          "dali-border-vermillion-600",
          "dali-text-vermillion-600",
        ],
        "egyptian-blue": [
          "dali-bg-egyptian-blue-200",
          "dali-border-egyptian-blue-200",
          "dali-text-egyptian-blue-900",
        ],
        malachite: [
          "dali-bg-malachite-200",
          "dali-border-malachite-200",
          "dali-text-malachite-900",
        ],
        "ochre-light": [
          "dali-bg-ochre-200",
          "dali-border-ochre-200",
          "dali-text-ochre-900",
        ],
        "ochre-dark": [
          "dali-bg-ochre-400",
          "dali-border-ochre-400",
          "dali-text-base-black",
        ],
        cadmium: [
          "dali-bg-cadmium-200",
          "dali-border-cadmium-200",
          "dali-text-cadmium-900",
        ],
        vermillion: [
          "dali-bg-vermillion-50",
          "dali-border-vermillion-50",
          "dali-text-vermillion-900",
        ],
        "carbon-dark": [
          "dali-bg-carbon-700",
          "dali-border-carbon-700",
          "dali-text-base-white",
        ],
        "carbon-light": [
          "dali-bg-carbon-100",
          "dali-border-carbon-100",
          "dali-text-base-black",
        ],
      },
    },
    compoundVariants: [
      // interactive button styles
      {
        isInteractive: true,
        variant: "egyptian-blue",
        className: [
          "[&>button:hover]:dali-bg-egyptian-blue-300",
          "[&>button:focus]:dali-ring-base-black",
        ],
      },
      {
        isInteractive: true,
        variant: "malachite",
        className: [
          "[&>button:hover]:dali-bg-malachite-400",
          "[&>button:focus]:dali-ring-base-black",
        ],
      },
      {
        isInteractive: true,
        variant: "ochre-light",
        className: [
          "[&>button:hover]:dali-bg-ochre-300",
          "[&>button:focus]:dali-ring-base-black",
        ],
      },
      {
        isInteractive: true,
        variant: "ochre-dark",
        className: [
          "[&>button:hover]:dali-bg-ochre-500",
          "[&>button:focus]:dali-ring-base-black",
        ],
      },
      {
        isInteractive: true,
        variant: "cadmium",
        className: [
          "[&>button:hover]:dali-bg-cadmium-300",
          "[&>button:focus]:dali-ring-base-black",
        ],
      },
      {
        isInteractive: true,
        variant: "vermillion",
        className: [
          "[&>button:hover]:dali-bg-vermillion-200",
          "[&>button:focus]:dali-ring-base-black",
        ],
      },
      {
        isInteractive: true,
        variant: "primary",
        className: [
          "[&>button:hover]:dali-bg-carbon-800",
          "[&>button:focus]:dali-ring-base-white",
          "[&>button:focus]:!dali-ring-1",
        ],
      },
      {
        isInteractive: true,
        variant: "carbon-dark",
        className: [
          "[&>button:hover]:dali-bg-carbon-900",
          "[&>button:focus]:dali-ring-base-white",
          "[&>button:focus]:!dali-ring-1",
        ],
      },
      {
        isInteractive: true,
        variant: "primary-outlined",
        className: [
          "[&>button:hover]:dali-bg-carbon-200",
          "[&>button:focus]:dali-ring-base-black",
        ],
      },
      {
        isInteractive: true,
        variant: "carbon-light",
        className: [
          "[&>button:hover]:dali-bg-carbon-200",
          "[&>button:focus]:dali-ring-base-black",
        ],
      },
      {
        isInteractive: true,
        variant: "secondary",
        className: [
          "[&>button:hover]:dali-bg-vermillion-700",
          "[&>button:focus]:dali-ring-base-black",
        ],
      },
      {
        isInteractive: true,
        variant: "secondary-outlined",
        className: [
          "[&>button:hover]:dali-bg-vermillion-50",
          "[&>button:focus]:dali-ring-base-black",
        ],
      },
      // disabled outlined chips
      {
        disabled: true,
        variant: "secondary-outlined",
        className: "!dali-bg-transparent !dali-border-carbon-300",
      },
      {
        disabled: true,
        variant: "primary-outlined",
        className: "!dali-bg-transparent !dali-border-carbon-300",
      },
    ],
  },
);

export const Chip = forwardRef(
  (
    {
      className: hash,
      label,
      leadingIcon,
      trailingIcon,
      variant = "primary",
      size = "default",
      disabled,
      ...rest
    }: ChipProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const isInteractive =
      size === "default" &&
      trailingIcon != null &&
      trailingIcon.props.onClick != null;
    const classes = cx(
      twMerge(chipStyles({ disabled, variant, isInteractive })),
      hash,
    );

    const iconSizes: Record<NonNullable<ChipProps["size"]>, number> = {
      default: 20,
      sm: 16,
    };
    const labelSizes: Record<
      NonNullable<ChipProps["size"]>,
      TypographyProps["size"]
    > = {
      default: "sm",
      sm: "xs",
    };

    return (
      <Icon.IconContext.Provider
        value={{
          size: iconSizes[size],
        }}
      >
        <div className={classes} {...rest} ref={ref}>
          {leadingIcon}
          <Typography as="span" size={labelSizes[size]} className="dali-mx-1">
            {label}
          </Typography>
          {size === "default" && trailingIcon}
        </div>
      </Icon.IconContext.Provider>
    );
  },
);

Chip.displayName = "Chip";
