import { string, z } from "zod";

import { partialProfileSchema } from "./profile";

import {
  emailStatusSchema,
  enterpriseProfileTypeSchema,
  highlightSchema,
  userSchema,
} from "../commons";

// GAMIFICATION
export enum ProfileCompletionSteps {
  BASICS = "basics",
  EXPERTISE = "expertise",
  PROJECTS = "projects",
  REQUEST_VERIFICATION = "request-verification",
}

export const profileCompletionStepsSchema = z.nativeEnum(
  ProfileCompletionSteps,
);

export const basicProfileSchema = userSchema
  .pick({
    name: true,
    avatar: true,
    title: true,
    email: true,
    specialties: true,
    location: true,
    brands: true,
  })
  .extend({
    specialties: z.array(z.string()).min(1),
    brands: z.array(z.string()).min(1),
  })
  .required();

export const expertiseProfileSchema = userSchema
  .pick({
    skills: true,
    deliverables: true,
    tools: true,
    industries: true,
    bio: true,
  })
  .extend({
    skills: z.array(z.string()).min(1),
    deliverables: z.array(z.string()).min(1),
    tools: z.array(z.string()).min(1),
    industries: z.array(z.string()).min(1),
  })
  .required();

export const projectSchema = userSchema
  .pick({
    highlights: true,
  })
  .extend({
    highlights: z.array(highlightSchema).min(1),
  })
  .required();

export const profileStatusSchema = z.object({
  nextStep: profileCompletionStepsSchema.optional(),
  completedSteps: z.number(),
  totalSteps: z.number(),
  statusPerStep: z.array(
    z.object({
      step: profileCompletionStepsSchema,
      completed: z.boolean(),
    }),
  ),
});

export type ProfileStatus = z.infer<typeof profileStatusSchema>;

export const signalResponseContextSchema = z.object({
  sentence: z.string().optional(), // generated sentence to be displayed
});

// My Network
export const myNetworkBodySchema = z.object({
  specialties: z.array(z.string()).optional(),
  skills: z.array(z.string()).optional(),
  deliverables: z.array(z.string()).optional(),
  tools: z.array(z.string()).optional(),
  trustScore: z.coerce.number().optional(),
  timezone: z.array(z.string()).optional(),
  level: z.array(z.coerce.number()).optional(),
  brands: z.array(z.string()).optional(),
  tags: z.array(z.string()).optional(),
  names: z.array(z.string()).optional(),
  queries: z.array(z.string()).optional(),
  hasWorkedWith: z.boolean().optional(),
});

export const searchFilterSchema = z.object({
  label: z.string(),
  count: z.number(),
});

export type SearchFilter = z.infer<typeof searchFilterSchema>;

// My Network response
export const myNetworkFiltersSchema = z.object({
  specialties: z.array(z.string()).optional(),
  skills: z.array(z.string()).optional(),
  deliverables: z.array(z.string()).optional(),
  tools: z.array(z.string()).optional(),
  timezone: z.array(z.string()).optional(),
  industries: z.array(z.string()).optional(),
  brands: z.array(z.string()).optional(),
  tags: z.array(z.string()).optional(),
  names: z.array(z.string()).optional(),
});

export type MyNetworkFilters = z.infer<typeof myNetworkFiltersSchema>;

export const whatIMakeSchema = z.array(
  z.object({ specialty: z.string(), deliverables: z.array(z.string()) }),
);

export type WhatIMake = z.infer<typeof whatIMakeSchema>;

export type MyNetworkBody = z.infer<typeof myNetworkBodySchema>;

export const myNetworkRequestSchema = z.object({
  body: myNetworkBodySchema,
});

export type MyNetworkRequest = z.infer<typeof myNetworkRequestSchema>;

export const myNetworkSummaryProfileSchema = z.object({
  _id: string(),
  name: z.string(),
  type: enterpriseProfileTypeSchema,
  brands: z.array(z.string()).optional(),
  trustScore: z.coerce.number().min(0).max(10).optional(),
  specialties: z.array(z.string()).optional(),
  whatIMake: whatIMakeSchema.optional(),
  avatar: z.string().optional(),
  title: z.string().optional(),
  linkedIn: z.string().optional(),
  workedWith: z
    .array(
      z.object({
        _id: z.string(),
        name: z.string().optional(),
        avatar: z.string().optional(),
      }),
    )
    .optional(),
  highlightsSummaries: z
    .array(
      z.object({
        _id: z.string(),
        title: z.string().optional(),
        company: z.string().optional(),
        role: z.string().optional(),
        hero: z.string().optional(),
      }),
    )
    .optional(),
  assets: z.array(z.string()),
  hasWorkedWith: z.boolean(),
  hasWorkedWithEnterprise: z.boolean(),

  // Search stats
  score: z.number().optional(),
  matchedHighlights: z
    .array(
      z.object({
        field: z.string(),
        value: z.any(),
      }),
    )
    .optional(),

  // Meta recommendation
  onboarding: z.string().optional(),
  signalContext: signalResponseContextSchema.optional(),
  reason: z.string().optional(),
});

export const resultsRowSchema = z.object({
  total: z.coerce.number(),
  results: z.array(myNetworkSummaryProfileSchema),
});

export type ResultsRow = z.infer<typeof resultsRowSchema>;

export type MyNetworkSummaryProfile = z.infer<
  typeof myNetworkSummaryProfileSchema
>;

export const myNetworkResponseSchema = z.object({
  all: resultsRowSchema,
  recommendations: resultsRowSchema,
  filters: myNetworkFiltersSchema,
  profileStatus: profileStatusSchema.optional(),
  suggestions: myNetworkFiltersSchema.optional(),
});

export type MyNetworkResponse = z.infer<typeof myNetworkResponseSchema>;

// My Network Profile
export const myNetworkProfileSchema = z.object({
  // REQUIRED
  _id: z.string(),
  name: z.string(),
  email: z.string(),
  emailStatus: emailStatusSchema,
  type: enterpriseProfileTypeSchema,
  referredBy: z.object({
    _id: z.string(),
    name: z.string(),
    avatar: z.string().optional(),
  }),

  // OPTIONAL
  avatar: z.string().optional(),
  preferredName: z.string().optional(),
  title: z.string().optional(),
  location: z.string().optional(),
  skills: z.array(z.string()).optional(),
  specialties: z.array(z.string()).optional(),
  brands: z.array(z.string()).optional(),
  trustScore: z.coerce.number().min(1).max(10).optional(),
  tags: z.array(z.string()).optional(),
  whatsapp: z.string().optional(),
  industries: z.array(z.string()).optional(),
  deliverables: z.array(z.string()).optional(),
  softSkills: z.array(z.string()).optional(),
  tools: z.array(z.string()).optional(),
  role: z.string().optional(),
  linkedIn: z.string().optional(),
  yearsOfExperience: z.number().optional(),
  bio: z.string().optional(),
  highlights: z.array(highlightSchema).optional(),
  socialLinks: z.array(z.string()).optional(),
  website: z.string().optional(),
  note: z.string().optional(),
  workConnectionRequestsSent: z.array(z.string()).optional(),

  whatIMake: whatIMakeSchema.optional(),

  // Reason for why the creative is being recommended
  reason: z.string().optional(),

  // WORKED WITH SECTION
  workedWith: z
    .array(
      z.object({
        _id: z.string(),
        name: z.string(),
        avatar: z.string().optional(),
        rating: z.number().min(0).max(10).optional(),
        note: z.string().optional(),
      }),
    )
    .optional(),

  hasWorkedWith: z.boolean(),
  hasWorkedWithEnterprise: z.boolean(),

  // Highlights Summary Section
  highlightsSummaries: z
    .array(
      z.object({
        title: z.string().optional(),
        company: z.string().optional(),
        role: z.string().optional(),
        hero: z.string().optional(),
      }),
    )
    .optional(),

  // Profile Status
  profileStatus: profileStatusSchema.optional(),

  // Meta recommendation
  signalContext: signalResponseContextSchema.optional(),
});

export type MyNetworkProfile = z.infer<typeof myNetworkProfileSchema>;

export const getMyNetworkProfilesQuerySchema = z.object({
  queries: z.array(z.string()).optional(),
});

export type GetMyNetworkProfilesQuery = z.infer<
  typeof getMyNetworkProfilesQuerySchema
>;

// Invite to My Network
export const inviteToMyNetoworkBody = z.object({
  invites: z.array(
    z.object({
      email: z.string().trim().email(),
      name: z.string().optional(),
      type: enterpriseProfileTypeSchema.optional(),
    }),
  ),
  note: z.string().optional(),
});

export type InviteToMyNetworkBody = z.infer<typeof inviteToMyNetoworkBody>;

export const inviteToMyNetworkRequestSchema = z.object({
  body: inviteToMyNetoworkBody,
});

export type InviteToMyNetworkRequest = z.infer<
  typeof inviteToMyNetworkRequestSchema
>;

// Invite Creative to My Network
export const inviteCreativeToMyNetworkBody = z.object({
  invites: z.array(
    z.object({
      linkedinOrEmail: z.string(),
      hasWorkedWith: z.boolean().optional(),
    }),
  ),
});

export type InviteCreativeToMyNetworkBody = z.infer<
  typeof inviteCreativeToMyNetworkBody
>;

export const inviteCreativeToMyNetworkRequestSchema = z.object({
  body: inviteCreativeToMyNetworkBody,
});

export type InviteCreativeToMyNetworkRequest = z.infer<
  typeof inviteCreativeToMyNetworkRequestSchema
>;

export const completeOnboardingResponseSchema = z.object({
  token: z.string(),
});

export type CompleteOnboardingResponse = z.infer<
  typeof completeOnboardingResponseSchema
>;

export const submitTestimonialBodySchema = z.object({
  text: z.string(),
  rating: z.number().min(0).max(10),
});

export type SubmitTestimonialBody = z.infer<typeof submitTestimonialBodySchema>;

export const submitTestimonialParamsSchema = z.object({
  creativeId: z.string(),
});

export type SubmitTestimonialParams = z.infer<
  typeof submitTestimonialParamsSchema
>;

export const submitTestimonialRequestSchema = z.object({
  body: submitTestimonialBodySchema,
  params: submitTestimonialParamsSchema,
});

export type SubmitTestimonialRequest = z.infer<
  typeof submitTestimonialRequestSchema
>;

export const getPeopleQuerySchema = z.object({
  type: z.string().optional(),
});

export type GetPeopleQuery = z.infer<typeof getPeopleQuerySchema>;

export const getPeopleResponseSchema = z.array(
  z.object({
    _id: z.string(),
    name: z.string(),
    workedWith: z.boolean(),
    avatar: z.string().optional(),
    title: z.string().optional(),
  }),
);

export type GetPeopleResponse = z.infer<typeof getPeopleResponseSchema>;

export const addToPipelineBodySchema = z.object({
  name: z.string(),
  email: z.string().trim().email(),
  whatsapp: z.string().optional(),
  note: z.string().optional(),
  knownSkills: z.array(z.string()).optional(),
  isVendor: z.boolean().optional(),
});

export type AddToPipelineBody = z.infer<typeof addToPipelineBodySchema>;

export const addToPipelineRequestSchema = z.object({
  body: addToPipelineBodySchema,
});

export type AddToPipelineRequest = z.infer<typeof addToPipelineRequestSchema>;

export const addToPipelineResponseSchema = z.object({
  _id: z.string(),
});

export type AddToPipelineResponse = z.infer<typeof addToPipelineResponseSchema>;

export const updateTagsBodySchema = z.object({
  tags: z.array(z.string()),
});

export type UpdateTagsBody = z.infer<typeof updateTagsBodySchema>;

export const updateTagsParamsSchema = z.object({
  profileId: z.string(),
});

export type UpdateTagsParams = z.infer<typeof updateTagsParamsSchema>;

export const updateTagsRequestSchema = z.object({
  params: updateTagsParamsSchema,
  body: updateTagsBodySchema,
});

export type UpdateTagsRequest = z.infer<typeof updateTagsRequestSchema>;

export const updateTagsResponseSchema = z.object({
  tags: z.array(z.string()),
});

export type UpdateTagsResponse = z.infer<typeof updateTagsResponseSchema>;

export const getTagsResponseSchema = z.array(z.string());

export type GetTagsResponse = z.infer<typeof getTagsResponseSchema>;

export const getBrandsResponseSchema = z.array(z.string());

export type GetBrandsResponse = z.infer<typeof getBrandsResponseSchema>;

export const requestWorkConnectionBodySchema = z.object({
  profileIds: z.array(z.string()),
});

export type RequestWorkConnectionBody = z.infer<
  typeof requestWorkConnectionBodySchema
>;

export const requestWorkConnectionRequestSchema = z.object({
  body: requestWorkConnectionBodySchema,
});

export type RequestWorkConnectionRequest = z.infer<
  typeof requestWorkConnectionRequestSchema
>;

export const updateMyNetworkProfileBodySchema = partialProfileSchema.extend({
  creativeId: z.string().optional(),
});

export type UpdateMyNetoworkProfileBody = z.infer<
  typeof updateMyNetworkProfileBodySchema
>;

export const updateMyNetworkProfileRequestSchema = z.object({
  body: updateMyNetworkProfileBodySchema,
});

export type UpdateMyNetworkProfileRequest = z.infer<
  typeof updateMyNetworkProfileRequestSchema
>;

export const updateMyNetworkProfileResponseSchema = partialProfileSchema.extend(
  {
    token: z.string().optional(),
  },
);

export type UpdateMyNetworkProfileResponse = z.infer<
  typeof updateMyNetworkProfileResponseSchema
>;

export const getWorkedWithResponseSchema = z.array(
  myNetworkSummaryProfileSchema,
);

export type GetWorkedWithResponse = z.infer<typeof getWorkedWithResponseSchema>;

export const setWorkConnectionResponseSchema = z.object({
  hasWorkedWith: z.boolean(),
  count: z.number(),
});

export type SetWorkConnectionResponse = z.infer<
  typeof setWorkConnectionResponseSchema
>;

export const shareNetworkProfile = z.object({
  invites: z.array(z.object({ email: z.string().email(), name: z.string() })),
  message: z.string(),
});

export type ShareNetworkProfile = z.infer<typeof shareNetworkProfile>;

export const shareNetworkParamsSchema = z.object({
  profileId: z.string(),
});

export type ShareNetworkParams = z.infer<typeof shareNetworkParamsSchema>;

export const shareNetworkRequestSchema = z.object({
  params: shareNetworkParamsSchema,
  body: shareNetworkProfile,
});

export type ShareNetworkRequest = z.infer<typeof shareNetworkRequestSchema>;
