import { z } from "zod";

import {
  EnterpriseOnboardingStatus,
  uploadSchema,
  uploadTypeSchema,
} from "../commons";

// GET CONTACTS
export const getContactsQuerySchema = z.object({
  types: z.array(z.nativeEnum(EnterpriseOnboardingStatus)),
});

export type GetContactsQuery = z.infer<typeof getContactsQuerySchema>;

export const getContactsRequestSchema = z.object({
  query: getContactsQuerySchema,
});

export type GetContactsRequest = z.infer<typeof getContactsRequestSchema>;

export const getContactsResponseSchema = z.array(
  z.object({
    _id: z.string(),
    name: z.string(),
    email: z.string(),
    title: z.string().optional(),
    avatar: z.string().optional(),
    location: z.string().optional(),
    status: z.nativeEnum(EnterpriseOnboardingStatus),
  }),
);

export type GetContactsResponse = z.infer<typeof getContactsResponseSchema>;

// ARCHIVE CONTACT
export const archiveContactParamsSchema = z.object({
  contactId: z.string(),
});

export type ArchiveContactParams = z.infer<typeof archiveContactParamsSchema>;

export const archiveContactRequestSchema = z.object({
  params: archiveContactParamsSchema,
});

export type ArchiveContactRequest = z.infer<typeof archiveContactRequestSchema>;

export const archiveContactResponseSchema = z.boolean();

export type ArchiveContactResponse = z.infer<
  typeof archiveContactResponseSchema
>;

// UPLOAD CONTACTS
export const uploadContactsBody = z.object({
  link: z.string(),
  type: uploadTypeSchema,
});

export type UploadContactsBody = z.infer<typeof uploadContactsBody>;

export const uploadContactsRequestSchema = z.object({
  body: uploadContactsBody,
});

export type UploadContactsRequest = z.infer<typeof uploadContactsRequestSchema>;

// GET UPLOADS
export const getUploadsResponseSchema = z.array(uploadSchema);

export type GetUploadsResponse = z.infer<typeof getUploadsResponseSchema>;
