import { cva, cx } from "class-variance-authority";
import React, { type HTMLAttributes } from "react";

export interface TypographyProps
  extends HTMLAttributes<HTMLElement & HTMLHeadingElement & HTMLLIElement> {
  /**
   * Optionally, sets the font size based on Tailwind values; defaults to
   * "base". In order to overwrite the line-height, simply add a `leading-`
   * class and choose one of the available Tailwind attributes.
   */
  size?:
    | "xs"
    | "sm"
    | "base"
    | "lg"
    | "xl"
    | "2xl"
    | "3xl"
    | "4xl"
    | "5xl"
    | "6xl"
    | "7xl"
    | "8xl"
    | "9xl";
  /**
   * Optionally, sets the font weight; defaults to "normal"
   */
  weight?:
    | "thin"
    | "extralight"
    | "light"
    | "normal"
    | "medium"
    | "bold"
    | "extrabold"
    | "black";
  /**
   * Optionally, sets the tagname of the resulting element; defaults to <p />
   */
  as?: "p" | "span" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "li";
}

const typographyStyles = cva(["mg-typography", "dali-font-national2"], {
  variants: {
    size: {
      xs: "dali-text-xs",
      sm: "dali-text-sm",
      base: "dali-text-base",
      lg: "dali-text-lg",
      xl: "dali-text-xl",
      "2xl": "dali-text-2xl",
      "3xl": "dali-text-3xl",
      "4xl": "dali-text-4xl",
      "5xl": "dali-text-5xl",
      "6xl": "dali-text-6xl",
      "7xl": "dali-text-7xl",
      "8xl": "dali-text-8xl",
      "9xl": "dali-text-9xl",
    },
    weight: {
      thin: "dali-font-thin",
      extralight: "dali-font-extralight",
      light: "dali-font-light",
      normal: "dali-font-normal",
      medium: "dali-font-medium",
      bold: "dali-font-bold",
      extrabold: "dali-font-extrabold",
      black: "dali-font-black",
    },
  },
});

export default function Typography(props: TypographyProps) {
  const {
    size = "base",
    weight = "normal",
    as: Tag = "p",
    className: hash,
    ...rest
  } = props;

  const classes = cx(typographyStyles({ size, weight }), hash);

  return <Tag className={classes} {...rest} />;
}
