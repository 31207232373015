import { Icon, Typography } from "@mg/dali/src";
import {
  // eslint-disable-next-line import/named
  createRoute,
  useParams,
} from "@tanstack/react-router";
import { useRef } from "react";
import { z } from "zod";

import { AboutMe } from "./components/AboutMe";
import { ProfileHeader } from "./components/Header";
import { InvitePipeline } from "./components/InvitePipeline";
import { ProfessionalDetails } from "./components/ProfessionalDetails";
import { Projects } from "./components/Projects";
import { Summarizer } from "./components/Summarizer";

import { SendMessageDialog } from "../../components/SendMessageDialog";
import { requiresAuth } from "../../utils/auth";
import { searchParser } from "../../utils/parsers/search";
import { useProfile } from "../../utils/queries/profile";
import { authLayoutRoute } from "../auth-layout/route";

const profileSearchParser = searchParser.extend({
  showEndorsement: z.boolean().optional(),
});

export const profileRoute = createRoute({
  getParentRoute: () => authLayoutRoute,
  path: "profiles/$profileId",
  beforeLoad() {
    requiresAuth();
  },
  validateSearch(search: Record<string, unknown>) {
    return profileSearchParser.parse(search);
  },
  component: function Component() {
    const { profileId } = useParams({ strict: false });
    const profile = useProfile(profileId);
    const messageDialogRef = useRef<HTMLDialogElement>(null);

    if (profile.isLoading) {
      return (
        <Typography
          as="h1"
          size="2xl"
          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
        >
          Loading...
          <Icon.CircleNotch className="mx-auto animate-spin" />
        </Typography>
      );
    }

    if (profile.data == null) {
      return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { highlightsSummaries, ...freelancer } = profile.data;

    return (
      <div className="mx-auto grid h-dvh auto-rows-min gap-y-6 overflow-y-auto px-4 pb-24 md:px-10 md:py-9">
        <ProfileHeader />
        <div className="grid auto-rows-min grid-cols-1 gap-y-6 md:grid-cols-5 md:gap-6">
          <div className="col-span-2 grid auto-rows-min gap-6">
            {/* @ts-expect-error TS2741: we don't actually need that here */}
            <AboutMe
              {...freelancer}
              onMessageClick={() => {
                messageDialogRef.current?.showModal();
              }}
            />
            {/* @ts-expect-error TS2741: we don't actually need that here */}
            <ProfessionalDetails {...freelancer} />
          </div>
          <div className="col-span-3">
            <Summarizer />
            {/* @ts-expect-error TS2741: we don't actually need that here */}
            <Projects />
          </div>
        </div>

        <InvitePipeline />

        <SendMessageDialog
          ref={messageDialogRef}
          ids={[freelancer._id]}
          onClose={() => {
            if (messageDialogRef.current != null) {
              messageDialogRef.current.close();
            }
          }}
        />
      </div>
    );
  },
});
