import { Popover } from "@headlessui/react";
import { Icon, Typography } from "@mg/dali/src";
import {
  Outlet,
  Navigate,
  useNavigate,
  redirect,
  // eslint-disable-next-line import/named
  Link,
  // eslint-disable-next-line import/named
  createRoute,
  useParams,
} from "@tanstack/react-router";
import cx from "classnames";
import { useEffect, useRef, useState } from "react";

import { ShoutOut } from "../../components/ShoutOut";
import {
  canAccessCreativeConnect,
  canAccessEditOwnProfile,
  canAccessLists,
  canAccessPuntt,
  requiresAuth,
} from "../../utils/auth";
import { createDefaultNetworkRouteSearch } from "../../utils/constants";
import { useAppSelector } from "../../utils/hooks";
import { useLists } from "../../utils/queries/lists";
import { setSelections } from "../../utils/slices/lists";
import { store } from "../../utils/store";
import { authLayoutRoute } from "../auth-layout/route";
import { listRoute } from "../list/route";
import { myNetworkRoute } from "../network/route";
import { ticketsRoute } from "../tickets/route";
import { aboutMeEditRoute } from "../userProfile/routes/AboutMe/editRoute";

export const listsRoute = createRoute({
  getParentRoute: () => authLayoutRoute,
  path: "lists",
  beforeLoad() {
    requiresAuth();

    if (!canAccessLists()) {
      if (canAccessPuntt()) {
        throw redirect({
          to: ticketsRoute.to,
        });
      }

      if (canAccessCreativeConnect()) {
        throw redirect({
          to: myNetworkRoute.to,
          search: createDefaultNetworkRouteSearch(),
        });
      }

      if (canAccessEditOwnProfile()) {
        throw redirect({
          to: aboutMeEditRoute.to,
        });
      }
    }

    store.dispatch(setSelections([]));
  },
  component: function Component() {
    const { listId } = useParams({ strict: false });

    useLists();
    const navigate = useNavigate();
    const [query, setQuery] = useState("");
    const [sortType, setSortType] = useState<{
      type: string;
      direction: string;
    }>({ type: "date", direction: "desc" });
    const inputRef = useRef<HTMLInputElement>(null);
    const QUICK_FOCUS_KEY = "/";
    const QUICK_BLUR_KEY = "Escape";

    const { lists } = useAppSelector((state) => state.lists);
    const { headerHeight } = useAppSelector((state) => state.ui);

    const firstId = (lists ?? [])[0]?._id;

    useEffect(() => {
      function handleKeyDown(e: KeyboardEvent) {
        const isInputFocused = document.activeElement === inputRef.current;

        if (e.key === QUICK_FOCUS_KEY && !isInputFocused) {
          e.preventDefault();
          inputRef.current?.focus();
        } else if (e.key === QUICK_BLUR_KEY && isInputFocused) {
          inputRef.current?.blur();
          setQuery("");
        }
      }

      window.addEventListener("keydown", handleKeyDown);

      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }, []);

    // if we do not have `$listId` (meaning the param), but we have a valid list
    // ID to navigate to, we should do that.
    if (listId == null && firstId != null) {
      return <Navigate to={listRoute.to} params={{ listId: firstId }} />;
    }

    if (!lists) {
      return null;
    }

    if (lists.length === 0) {
      return (
        <div className="grid p-4 pt-16">
          <ShoutOut
            title="Create your first list by selecting “Save to List” on a Creative's Portfolio."
            ctaText="Go to Network"
            //@ts-expect-error TS2345: incorrect typing search params
            handleClick={() => navigate({ to: myNetworkRoute.to })}
          />
        </div>
      );
    }
    let sortedLists = lists;

    if (sortType.direction !== "desc" || sortType.type !== "date") {
      const tempLists = [...lists];
      if (sortType.type === "date") {
        sortedLists = tempLists.sort((a, b) => {
          return (
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          );
        });
      } else if (sortType.direction === "asc") {
        sortedLists = tempLists.sort((a, b) => {
          return a.title.localeCompare(b?.title);
        });
      } else {
        sortedLists = tempLists.sort((a, b) => {
          return b.title.localeCompare(a?.title);
        });
      }
    }

    const renderedLists =
      query.length > 0
        ? sortedLists.filter((l) =>
            l.title.toLowerCase().includes(query.toLowerCase()),
          )
        : sortedLists;

    return (
      <div className="flex gap-8 p-6">
        <aside
          className="sticky grid w-96 auto-rows-max gap-4 overflow-y-auto bg-transparent p-px"
          style={{
            maxHeight: `calc(100vh - ${headerHeight + 48}px)`,
            top: headerHeight + 24,
          }}
        >
          <article className="flex items-center gap-4 pb-2">
            <Typography as="h2" weight="medium" size="2xl" className="hidden">
              Lists
            </Typography>

            <div
              role="searchbox"
              className="group relative flex-1 overflow-hidden rounded-full bg-base-white focus-within:ring-1 focus-within:ring-base-black"
            >
              <Icon.MagnifyingGlass className="absolute left-4 top-1/2 -translate-y-1/2" />
              <input
                type="search"
                value={query}
                ref={inputRef}
                onInput={({ target }) =>
                  setQuery((target as HTMLInputElement).value)
                }
                className="w-full bg-transparent py-2 pl-10 pr-14 font-national2 font-normal leading-normal outline-transparent group-focus-within:pr-4"
              />

              <div className="pointer-events-none absolute right-4 top-1/2 -translate-y-1/2 rounded-lg bg-carbon-50 px-2 py-0.5 transition-opacity group-focus-within:!opacity-0 group-hover:opacity-50">
                <Typography size="sm">/</Typography>
              </div>
            </div>
            <Popover as="div" className="relative">
              {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
              {({ open }) => (
                <>
                  <Popover.Button className="mt-2 h-full">
                    <Icon.Funnel className="cursor-pointer" size={20} />
                  </Popover.Button>

                  <Popover.Panel className="absolute right-0 rounded-lg bg-base-white p-4">
                    <Popover.Button
                      onClick={() => {
                        setSortType({ type: "date", direction: "desc" });
                      }}
                      className={cx("flex gap-2 p-1 hover:bg-carbon-100", {
                        "border-vermillion-600 bg-cadmium-50":
                          sortType.type === "date" &&
                          sortType.direction === "desc",
                      })}
                    >
                      <Icon.SortAscending className="shrink-0" />

                      <Typography
                        weight="medium"
                        size="sm"
                        className="min-w-0 truncate"
                      >
                        Created Date (Newest First)
                      </Typography>
                    </Popover.Button>
                    <Popover.Button
                      onClick={() => {
                        setSortType({ type: "date", direction: "asc" });
                      }}
                      className={cx("flex gap-2 p-1 hover:bg-carbon-100", {
                        "border-vermillion-600 bg-cadmium-50":
                          sortType.type === "date" &&
                          sortType.direction === "asc",
                      })}
                    >
                      <Icon.SortDescending className="shrink-0" />

                      <Typography
                        weight="medium"
                        size="sm"
                        className="min-w-0 truncate"
                      >
                        Created Date (Oldest First)
                      </Typography>
                    </Popover.Button>

                    <Popover.Button
                      onClick={() => {
                        setSortType({ type: "title", direction: "asc" });
                      }}
                      className={cx(
                        "flex w-full gap-2 p-1 hover:bg-carbon-100",
                        {
                          "border-vermillion-600 bg-cadmium-50":
                            sortType.type === "title" &&
                            sortType.direction === "asc",
                        },
                      )}
                    >
                      <Icon.SortAscending className="shrink-0" />

                      <Typography
                        weight="medium"
                        size="sm"
                        className="min-w-0 truncate"
                      >
                        Title (A-Z)
                      </Typography>
                    </Popover.Button>
                    <Popover.Button
                      onClick={() => {
                        setSortType({ type: "title", direction: "desc" });
                      }}
                      className={cx(
                        "flex w-full gap-2 p-1 hover:bg-carbon-100",
                        {
                          "border-vermillion-600 bg-cadmium-50":
                            sortType.type === "title" &&
                            sortType.direction === "desc",
                        },
                      )}
                    >
                      <Icon.SortDescending className="shrink-0" />

                      <Typography
                        weight="medium"
                        size="sm"
                        className="min-w-0 truncate"
                      >
                        Title (Z-A)
                      </Typography>
                    </Popover.Button>
                  </Popover.Panel>
                </>
              )}
            </Popover>
          </article>

          <hr className="border-carbon-300" />

          {renderedLists.map((l) => (
            <ListCard key={l._id} title={l.title} _id={l._id} />
          ))}
        </aside>

        <Outlet />
      </div>
    );
  },
});

type ListCardProps = {
  _id: string;
  title: string;
};

function ListCard(props: ListCardProps) {
  return (
    <Link
      to={listRoute.to}
      params={{ listId: props._id }}
      className="truncate rounded-lg border border-carbon-50 bg-base-white p-2 transition-colors hover:border-vermillion-200"
      activeProps={{
        className: "bg-cadmium-50 border-vermillion-600",
      }}
    >
      <Typography
        as="h3"
        weight="normal"
        size="lg"
        className="truncate text-base-black"
      >
        {props.title}
      </Typography>
    </Link>
  );
}
