import { Typography } from "@mg/dali/src";
import { type GetUsersResponse } from "@mg/schemas/src/christo/catalyst";
import { type EnterpriseProfileType } from "@mg/schemas/src/commons";
import { PaperPlaneRight } from "@phosphor-icons/react";
import { Button, Flex } from "@radix-ui/themes";
import cx from "classnames";
import Mentions from "rc-mentions";
import { type MentionsRef } from "rc-mentions/lib/Mentions";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";

import { AuthTooltip } from "../../../../components/AuthTooltip";
import { AvatarWithInitials } from "../../../../components/AvatarWithInitials";
import { assetForUser } from "../../../../utils/imageHandler";
import { useGetVisibleUsers } from "../../../../utils/tldraw/comments";
import { escapeRegex } from "../../../../utils/tldraw/handlers";

const { Option } = Mentions;
import "./index.css"; // Keep as the last import to avoid ESLint errors

interface CommentMentionsProps {
  onChange: (value: string) => void;
  value: string;
  placeholder: string;
  className?: string;
  onSend: () => void;
  disabled?: boolean;
  loading?: boolean;
}

export type Selections = {
  _id?: string;
  role?: EnterpriseProfileType;
  name?: string | undefined;
  avatar?: string | undefined;
  email?: string | undefined;
  key?: string;
}[];

export const CommentMentions = forwardRef(
  (
    {
      onChange,
      value,
      placeholder,
      className,
      onSend,
      disabled,
      loading,
    }: CommentMentionsProps,
    ref,
  ) => {
    const mentionRef = useRef<MentionsRef>(null);
    const onChangeIntermediary = (value: string) => {
      const regex = /(?:^|\s)@puntt\b/i;

      if (regex.test(value)) {
        setSelections((prev) => {
          if (prev.find((s) => s.key === "@puntt")) {
            return prev;
          }
          return [
            ...prev,
            {
              _id: "@puntt",
              role: "admin",
              name: "Puntt",
              email: "puntt",
              key: "@puntt",
            } as GetUsersResponse[number],
          ];
        });
      }

      onChange(value);
    };
    const users = useGetVisibleUsers();
    const [selections, setSelections] = useState<Selections>([]);

    const usersWithPunt = [
      {
        _id: "@puntt",
        role: "admin",
        name: "Puntt",
        email: "puntt",
      } as GetUsersResponse[number],
      ...(users || []),
    ];
    const data = usersWithPunt
      ?.map((u) => ({
        id: u.email,
        imgSrc: u.avatar,
        altText: u.name,
        userId: u._id,
        email: u.email,
        display: u.name,
      }))
      ?.sort((a, b) => {
        return (a.id as string).localeCompare(b.id as string);
      });

    // Expose the getSelections method to the parent component
    useImperativeHandle(ref, () => ({
      getSelections: () => {
        const mapped = selections.map((s) => {
          return usersWithPunt?.find((u) => u._id === s.key);
        });

        setSelections([]);
        return mapped.filter((selection) => {
          const mentionRegex = new RegExp(
            `(^|\\W)@${escapeRegex(selection?.email as string)}\\b`,
            "gi",
          );
          return mentionRegex.test(value);
        });
      },
      closeMentions: () => {
        if (mentionRef.current) {
          mentionRef.current?.blur();
        }
      },
    }));

    return (
      <div className={className}>
        <Flex
          className={cx("items-center bg-base-white", className)}
          style={{ boxShadow: "inset 0 0 0 1px var(--gray-a7)" }}
          data-auth-trigger="comment-mentions"
        >
          <AuthTooltip classNames="w-full flex">
            <Mentions
              ref={mentionRef}
              onChange={onChangeIntermediary}
              autoSize={{ minRows: 1, maxRows: 5 }}
              value={value}
              onSelect={(option) => {
                setSelections((prev) => [...prev, option]);
              }}
              className="w-full border-y-0 border-l-0 border-r border-puntt-neutral-gray-5 p-1 hover:border-y-0 focus:border-y-0 focus:border-l-0 focus:border-r"
              placement="bottom"
              direction="ltr"
              onClick={(e) => e.stopPropagation()}
              placeholder={placeholder}
              onPressEnter={(event) => {
                if (!event.shiftKey) {
                  event.preventDefault();
                  if (disabled) return;
                  onSend();
                }
              }}
              filterOption={(input, option) => {
                //@ts-expect-error TS2339: custom options arent defined
                const { email, name } = option;
                const lowerCaseInput = input.toLowerCase();
                return (
                  email.toLowerCase().includes(lowerCaseInput) ||
                  name.toLowerCase().includes(lowerCaseInput)
                );
              }}
            >
              {data?.map((obj) => (
                <Option
                  value={obj.id}
                  // @ts-expect-error TS2322: does id exist here?
                  id={obj.id}
                  key={obj.userId}
                  email={obj.email}
                  name={obj.display}
                >
                  <div className="punt-soft-half cursor-pointer">
                    <div className="flex gap-x-2">
                      <AvatarWithInitials
                        avatar={assetForUser(obj.imgSrc)}
                        name={obj.id ?? ""}
                        size={6}
                      />
                      <div>
                        <Typography weight="medium" size="lg">
                          {obj.display}
                        </Typography>
                        <Typography className="text-puntt-cool-gray-dark-8">
                          {obj.email}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </Option>
              ))}
            </Mentions>
          </AuthTooltip>
        </Flex>
        <Button
          size="1"
          onClick={onSend}
          disabled={disabled || !value.trim()}
          loading={loading}
          className="float-right mt-2 min-h-6 cursor-pointer px-2 disabled:cursor-not-allowed"
        >
          <PaperPlaneRight className="mr-1" /> Send
        </Button>
      </div>
    );
  },
);

CommentMentions.displayName = "CommentMentions";
