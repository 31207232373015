import { z } from "zod";

import { noteSchema } from "../commons";

export const noteResponseSchema = noteSchema.extend({
  createdByName: z.string(),
});

export type NoteResponse = z.infer<typeof noteResponseSchema>;

export const createNoteSchema = noteSchema
  .pick({
    content: true,
    title: true,
  })
  .extend({
    creativeId: z.string().optional(),
    creativeListId: z.string().optional(),
  });

export type CreateNoteBody = z.infer<typeof createNoteSchema>;

export const createNoteRequestSchema = z.object({
  body: createNoteSchema,
});

export type CreateNoteRequest = z.infer<typeof createNoteRequestSchema>;

export const createNoteResponseSchema = noteResponseSchema;

export type CreateNoteResponse = z.infer<typeof createNoteResponseSchema>;

export type UpdateNoteBody = z.infer<typeof createNoteSchema>;

export const updateNoteParamsSchema = z.object({
  noteId: z.string(),
});

export type UpdateNoteParams = z.infer<typeof updateNoteParamsSchema>;

export const updateNoteRequestSchema = z.object({
  body: createNoteSchema,
  params: updateNoteParamsSchema,
});

export type UpdateNoteRequest = z.infer<typeof updateNoteRequestSchema>;

export const updateNoteResponseSchema = noteResponseSchema;

export type UpdateNoteResponse = z.infer<typeof updateNoteResponseSchema>;

export const deleteNoteParamsSchema = z.object({
  noteId: z.string(),
});

export type DeleteNoteParams = z.infer<typeof deleteNoteParamsSchema>;

export const deleteNoteRequestSchema = z.object({
  params: deleteNoteParamsSchema,
});

export type DeleteNoteRequest = z.infer<typeof deleteNoteRequestSchema>;

export const getNotesQuerychema = z.object({
  creativeId: z.string().optional(),
  creativeListId: z.string().optional(),
});

export type GetNotesQuery = z.infer<typeof getNotesQuerychema>;

export const getNotesRequestSchema = z.object({
  query: getNotesQuerychema,
});

export type GetNotesRequest = z.infer<typeof getNotesRequestSchema>;

export const getNotesResponseSchema = z.array(noteResponseSchema);

export type GetNotesResponse = z.infer<typeof getNotesResponseSchema>;
