import { Popover } from "@headlessui/react";
import { ButtonNew, Icon, Menu, Textarea, Typography } from "@mg/dali/src";
import {
  type CreateNoteBody,
  type UpdateNoteBody,
} from "@mg/schemas/src/christo/notes";
import { type Note as NoteType } from "@mg/schemas/src/commons";
import cx from "classnames";
import { createContext, useContext, useState } from "react";

import { Markdown } from "./Markdown";

import { isUserCreative } from "../utils/auth";
import {
  useCreateNote,
  useDeleteNote,
  useEditNote,
  useNotes,
} from "../utils/queries/notes";

type NotetakerContextValues = {
  notes: ReturnType<typeof useNotes>;
  deleteNoteMutation: ReturnType<typeof useDeleteNote>;
  editNoteMutation: ReturnType<typeof useEditNote>;
};

const NotetakerContext = createContext({} as NotetakerContextValues);
const useNotetaker = () => useContext(NotetakerContext);

interface CreativeNotes {
  creativeId: string;
  creativeListId?: never;
}

interface ListNotes {
  listId: string;
  creativeListId?: never;
}

export type NotetakerProps = CreativeNotes | ListNotes;

export function Notetaker(props: NotetakerProps) {
  const notes = useNotes(props);
  const createNoteMutation = useCreateNote();
  const deleteNoteMutation = useDeleteNote();
  const editNoteMutation = useEditNote();
  const isCreative = isUserCreative();

  const [isAdding, setIsAdding] = useState(false);
  // not being used for anything other than showing the character count.
  const [note, setNote] = useState("");

  if (isCreative) {
    return null;
  }

  return (
    <NotetakerContext.Provider
      value={{ notes, editNoteMutation, deleteNoteMutation }}
    >
      <Popover className="relative">
        {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
        {({ open }) => (
          <>
            <Popover.Button
              className="flex items-center gap-2"
              data-testid="note-taker-cta"
            >
              {notes.isFetching ? (
                <Icon.CircleNotch className="animate-spin" />
              ) : (
                <Icon.ChatText />
              )}
              <Typography weight="medium">
                Notes{" "}
                {notes.data && notes.data.length > 0
                  ? "(" + notes.data.length + ")"
                  : ""}
              </Typography>
            </Popover.Button>

            <Popover.Panel
              className={cx(
                "absolute left-0 z-10 max-h-96 w-80 overflow-auto rounded border border-carbon-50 bg-base-white shadow-lg",
              )}
            >
              <div
                className={cx("flex justify-end px-6 py-4", {
                  hidden: isAdding,
                })}
              >
                <ButtonNew
                  variant="outlined"
                  size="sm"
                  onClick={() => setIsAdding(true)}
                >
                  <Icon.PlusCircle />
                  New Note
                </ButtonNew>
              </div>
              <form
                className={cx("grid gap-4 p-6", {
                  hidden: !isAdding,
                })}
                onSubmit={(e) => {
                  e.preventDefault();

                  const formData = new FormData(e.target as HTMLFormElement);
                  createNoteMutation.mutate(
                    Object.fromEntries(formData.entries()) as CreateNoteBody,
                    {
                      onSuccess() {
                        setNote("");
                        notes.refetch();
                        setIsAdding(false);
                      },
                    },
                  );
                  (e.target as HTMLFormElement).reset();
                }}
              >
                <input
                  type="hidden"
                  name={Object.keys(props)[0]}
                  defaultValue={Object.values(props)[0]}
                />
                <input type="hidden" name="title" defaultValue="" />
                <Textarea
                  name="content"
                  id="note"
                  required
                  value={note}
                  onChange={({ target }) => setNote(target.value)}
                />

                <div className="flex gap-4">
                  <ButtonNew
                    type="submit"
                    disabled={createNoteMutation.isPending}
                    isLoading={createNoteMutation.isPending}
                  >
                    Save Note
                  </ButtonNew>
                  <ButtonNew
                    variant="outlined"
                    onClick={() => setIsAdding(false)}
                    disabled={createNoteMutation.isPending}
                  >
                    Cancel
                  </ButtonNew>
                </div>
              </form>

              <NotesList />
            </Popover.Panel>
          </>
        )}
      </Popover>
    </NotetakerContext.Provider>
  );
}

function NotesList() {
  const { notes } = useNotetaker();

  if (notes.isLoading) {
    return (
      <Typography className="border-t border-t-base-black p-6">
        Loading notes...
      </Typography>
    );
  }

  if (notes.data?.length === 0 || notes.data == null) {
    return (
      <Typography className="border-t border-t-base-black p-6">
        No notes.
      </Typography>
    );
  }

  return notes.data.map((note) => <Note key={note._id} note={note} />);
}

type NoteProps = {
  note: NoteType;
};

function Note({ note }: NoteProps) {
  const { notes, deleteNoteMutation, editNoteMutation } = useNotetaker();
  const [isEditing, setIsEditing] = useState(false);
  // not being used for anything other than showing the character count.
  const [value, setValue] = useState(note.content);

  const formatDate = (date?: Date) =>
    date == null ? "No date" : Intl.DateTimeFormat("en-US").format(date);

  return (
    <article
      className={cx(
        "grid gap-4 break-words border-t border-t-base-black p-6 [word-break:break-word]",
        {
          "opacity-50": deleteNoteMutation.isPending,
        },
      )}
    >
      <header className="flex items-center justify-between">
        <Typography as="h3" size="xs" weight="bold" className="text-carbon-600">
          {formatDate(note.createdAt)} &bull; {note.createdByName}
        </Typography>

        <Menu
          className={cx({ "pointer-events-none opacity-50": isEditing })}
          label=""
          labelIcon={<Icon.DotsThreeVertical />}
          items={[
            {
              label: "Edit",
              onClick() {
                setIsEditing(true);
              },
            },
            {
              label: "Delete",
              onClick() {
                deleteNoteMutation.mutate(note._id as string, {
                  onSuccess() {
                    notes.refetch();
                  },
                });
              },
            },
          ]}
        />
      </header>

      {isEditing ? (
        <form
          className="grid gap-4"
          onSubmit={(e) => {
            e.preventDefault();

            const formData = new FormData(e.target as HTMLFormElement);
            editNoteMutation
              .mutateAsync(
                Object.fromEntries(formData.entries()) as UpdateNoteBody & {
                  noteId: string;
                },
              )
              .then(() => {
                notes.refetch();
                setIsEditing(false);
                (e.target as HTMLFormElement).reset();
              });
          }}
        >
          <input type="hidden" defaultValue={note.title} name="title" />
          <input type="hidden" defaultValue={note._id} name="noteId" />

          <Textarea
            defaultValue={note.content}
            name="content"
            value={value}
            onChange={({ target }) => setValue(target.value)}
          />

          <div className="flex gap-4">
            <ButtonNew
              type="submit"
              disabled={editNoteMutation.isPending}
              isLoading={editNoteMutation.isPending}
            >
              Save Note
            </ButtonNew>
            <ButtonNew
              variant="outlined"
              onClick={() => setIsEditing(false)}
              disabled={editNoteMutation.isPending}
            >
              Cancel
            </ButtonNew>
          </div>
        </form>
      ) : (
        <Markdown>{note.content}</Markdown>
      )}
    </article>
  );
}
