// eslint-disable-next-line import/named, @typescript-eslint/no-unused-vars
import { Route, createRoute } from "@tanstack/react-router";

import { ForgotPasswordView } from "./view";

import { unauthLayoutRoute } from "../unauth-layout/route";

export const forgotPasswordRoute = createRoute({
  getParentRoute: () => unauthLayoutRoute,
  path: "forgot-password",
  component: ForgotPasswordView,
});
