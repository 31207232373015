import {
  type GetNetworkLists,
  type GetByIdResponse,
} from "@mg/schemas/src/christo/creativeList";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

type ListsState = {
  lists: GetNetworkLists | null;
  activeList: GetByIdResponse | null;
  selections: string[] | undefined;
  showSelections: boolean;
};

const initialState: ListsState = {
  lists: null,
  activeList: null,
  selections: undefined,
  showSelections: false,
};

export const listsSlice = createSlice({
  name: "lists",
  initialState,
  reducers: {
    setLists(state, action: PayloadAction<GetNetworkLists>) {
      state.lists = action.payload;
    },
    setActiveList(state, action: PayloadAction<GetByIdResponse>) {
      state.activeList = action.payload;
    },
    setSelections(state, action: PayloadAction<string[]>) {
      state.selections = Array.from(new Set(action.payload ?? []));
    },
    setShowSelections(state, action: PayloadAction<boolean>) {
      state.showSelections = action.payload;
    },
  },
});

export const { setLists, setActiveList, setSelections, setShowSelections } =
  listsSlice.actions;
