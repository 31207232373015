// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Popover } from "@headlessui/react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ButtonNew, Icon, Input, Typography } from "@mg/dali/src";
import { type Ticket } from "@mg/schemas/src/commons";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as Dialog from "@radix-ui/react-dialog";
import { type Editor, Tldraw } from "@tldraw/tldraw";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import cx from "classnames";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useEffect, useRef, useState } from "react";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { CommentsSidebar } from "./CommentsSidebar";
import { NoPermissionsView } from "./NoPermissionsView";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useYjsStore } from "./useYjsStore";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { uploadImageAsset, uploadToS3 } from "../../../services/upload";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useAppSelector } from "../../../utils/hooks";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useGetTicketComments } from "../../../utils/queries/projects";
import { assetUrls } from "../../../utils/tldraw/assets";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { externalAssetHandler } from "../../../utils/tldraw/handlers";
import { customShapeUtils } from "../../../utils/tldraw/shapeUtils";
import { customTools } from "../../../utils/tldraw/tools";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useTicket } from "../routes/ticket";

type ConceptDesignProps = {
  /**
   * The step's index within the tab manager
   */
  index: number;
  /**
   * Handles submitting the current tab and moving onto the
   * next tab
   */
  onNext(): void;
  /**
   * The name of the next step, to be used within the "next"
   * CTA
   */
  nextStepName: string;
  /**
   * The ticket itself
   */
  ticket: Ticket;
};

export function ConceptDesignBoard(props: ConceptDesignProps) {
  const { nextStepName, onNext, ticket } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [editor, setEditor] = useState<Editor | null>(null);
  // const ticketComments = useGetTicketComments({
  //   ticketId: ticket._id as string,
  //   query: "approval",
  // });

  // this is stub for user permissions work
  const hasPermission = true;
  return (
    <>
      <header className="flex items-center gap-4 text-base-black">
        {hasPermission && ticket.workflowStep === 2 && (
          <ButtonNew
            size="sm"
            className="bg-egyptian-blue-600"
            onClick={onNext}
          >
            {nextStepName}
          </ButtonNew>
        )}
      </header>

      <div className="fixed inset-0 top-[211px]">
        {hasPermission ? (
          <Tldraw
            assetUrls={assetUrls}
            // store={store}
            // persistenceKey={
            //   ticket.revisionBoards?.[activeRevisionIndex]?._id ?? "empty-board"
            // }
            hideUi
            tools={customTools}
            acceptedImageMimeTypes={[]}
            shapeUtils={customShapeUtils}
            onMount={(editor) => {
              setEditor(editor);
            }}
          ></Tldraw>
        ) : (
          <NoPermissionsView />
        )}
      </div>
    </>
  );
}
