import { ButtonNew, Icon, Typography } from "@mg/dali/src";
import cx from "classnames";
import {
  Editor,
  type EditorState,
  RichUtils,
  KeyBindingUtil,
  getDefaultKeyBinding,
} from "draft-js";
import { Map } from "immutable";
import {
  type Dispatch,
  type SetStateAction,
  type ReactNode,
  type KeyboardEvent,
} from "react";

import { RichEditorAssistant } from "./RichEditorAssistant";

const blockRenderMap = Map({
  "header-one": {
    element: "h1",
    wrapper: <Typography size="4xl" as="span" />,
  },
  "header-two": {
    element: "h2",
    wrapper: <Typography size="3xl" as="span" />,
  },
  "header-three": {
    element: "h3",
    wrapper: <Typography size="2xl" as="span" />,
  },
  "header-four": {
    element: "h4",
    wrapper: <Typography size="xl" as="span" />,
  },
  "header-five": {
    element: "h5",
    wrapper: <Typography size="lg" as="span" />,
  },
  "header-six": {
    element: "h6",
    wrapper: <Typography size="base" as="span" />,
  },
  "code-block": {
    element: "pre",
    wrapper: <pre className="rounded bg-carbon-50 p-1 text-sm" />,
  },
  "unordered-list-item": {
    element: "li",
    wrapper: <ul className="list-disc" />,
  },
  "ordered-list-item": {
    element: "li",
    wrapper: <ol className="list-decimal" />,
  },
  unstyled: {
    element: "span",
    wrapper: <Typography size="base" as="span" />,
  },
});

type RichEditorProps = {
  editorState: EditorState;
  label?: string;
  onChange: Dispatch<SetStateAction<EditorState>> | ((e: EditorState) => void);
  placeholder?: string;
  className?: string;
  actions?: ReactNode[];
  startBrief?: boolean;
  hideAssistant?: boolean;
};

function keyBindingFn(event: KeyboardEvent<HTMLElement>) {
  const { isCtrlKeyCommand } = KeyBindingUtil;

  if (event.code === "Space" && isCtrlKeyCommand(event)) {
    return "cc-space";
  }
  return getDefaultKeyBinding(event);
}

export function RichEditor(props: RichEditorProps) {
  const {
    editorState,
    label,
    onChange,
    placeholder,
    className: hash,
    startBrief,
    hideAssistant,
  } = props;

  // get the current block and inline styles
  const contentState = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const currentBlock = contentState.getBlockForKey(selection.getStartKey());
  const currentInlineStyle = editorState.getCurrentInlineStyle();
  const editorContainer = cx(
    "overflow-auto h-max max-h-96 [&>div]:after:clear-both [&>div]:after:table [&_.public-DraftEditorPlaceholder-inner]:font-national2",
    hash,
  );

  function handleKeyCommand(command: string) {
    if (command === "cc-space") {
      // Perform a request to save your contents, set
      // a new `editorState`, etc.
      return "handled";
    }

    return "not-handled";
  }

  return (
    <div className="grid w-full gap-2.5">
      <Typography
        as="p"
        className={cx("text-base-black", {
          hidden: label == null,
        })}
      >
        {label}
      </Typography>
      <div className="rounded-lg border-2 border-carbon-300">
        <header className="flex flex-wrap items-center border-b border-b-carbon-300">
          {!hideAssistant && (
            <RichEditorAssistant
              onChange={onChange}
              editorState={editorState}
              startBrief={startBrief}
            />
          )}

          {/* H1 Button toggle */}
          <ButtonNew
            size="sm"
            variant="ghost"
            tabIndex={-1}
            active={currentBlock.getType() === "header-one"}
            onClick={() => {
              onChange(RichUtils.toggleBlockType(editorState, "header-one"));
            }}
          >
            <Icon.TextHOne weight="bold" />
          </ButtonNew>

          {/* H2 Button toggle */}
          <ButtonNew
            size="sm"
            variant="ghost"
            tabIndex={-1}
            active={currentBlock.getType() === "header-two"}
            onClick={() => {
              onChange(RichUtils.toggleBlockType(editorState, "header-two"));
            }}
          >
            <Icon.TextHTwo weight="bold" />
          </ButtonNew>

          {/* H3 Button toggle */}
          <ButtonNew
            size="sm"
            variant="ghost"
            tabIndex={-1}
            active={currentBlock.getType() === "header-three"}
            onClick={() => {
              onChange(RichUtils.toggleBlockType(editorState, "header-three"));
            }}
          >
            <Icon.TextHThree weight="bold" />
          </ButtonNew>

          {/* Bold Button toggle */}
          <ButtonNew
            size="sm"
            variant="ghost"
            tabIndex={-1}
            active={currentInlineStyle.includes("BOLD")}
            onClick={() => {
              onChange(RichUtils.toggleInlineStyle(editorState, "BOLD"));
            }}
          >
            <Icon.TextB weight="bold" />
          </ButtonNew>

          {/* Italic Button toggle */}
          <ButtonNew
            size="sm"
            variant="ghost"
            tabIndex={-1}
            active={currentInlineStyle.includes("ITALIC")}
            onClick={() => {
              onChange(RichUtils.toggleInlineStyle(editorState, "ITALIC"));
            }}
          >
            <Icon.TextItalic weight="bold" />
          </ButtonNew>

          {/* Underline Button toggle */}
          <ButtonNew
            size="sm"
            variant="ghost"
            tabIndex={-1}
            active={currentInlineStyle.includes("UNDERLINE")}
            onClick={() => {
              onChange(RichUtils.toggleInlineStyle(editorState, "UNDERLINE"));
            }}
          >
            <Icon.TextUnderline weight="bold" />
          </ButtonNew>

          {/* Bulleted List Button toggle */}
          <ButtonNew
            size="sm"
            variant="ghost"
            tabIndex={-1}
            active={currentBlock.getType() === "unordered-list-item"}
            onClick={() => {
              onChange(
                RichUtils.toggleBlockType(editorState, "unordered-list-item"),
              );
            }}
          >
            <Icon.ListBullets weight="bold" />
          </ButtonNew>

          {/* Numbered List Button toggle */}
          <ButtonNew
            size="sm"
            variant="ghost"
            tabIndex={-1}
            active={currentBlock.getType() === "ordered-list-item"}
            onClick={() => {
              onChange(
                RichUtils.toggleBlockType(editorState, "ordered-list-item"),
              );
            }}
          >
            <Icon.ListNumbers weight="bold" />
          </ButtonNew>

          {/* Code Button toggle */}
          <ButtonNew
            size="sm"
            variant="ghost"
            tabIndex={-1}
            active={currentBlock.getType() === "code-block"}
            onClick={() => {
              onChange(RichUtils.toggleBlockType(editorState, "code-block"));
            }}
          >
            <Icon.Code weight="bold" />
          </ButtonNew>
        </header>
        <div className="relative flex flex-col break-words p-4 [word-break:break-word]">
          <div className={editorContainer}>
            <Editor
              editorState={editorState}
              onChange={onChange}
              blockRenderMap={blockRenderMap}
              placeholder={placeholder}
              handleKeyCommand={handleKeyCommand}
              keyBindingFn={keyBindingFn}
            />
          </div>
          {props.actions && <div className="ml-auto">{props.actions}</div>}
        </div>
      </div>
    </div>
  );
}
