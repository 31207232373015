import { z } from "zod";

import { assetSchema } from "./asset";

export const testimonialSchema = z.object({
  avatar: z.string().optional(),
  name: z.string(),
  title: z.string(),
  csat: z.number(),
  designerId: z.string().optional(),
  text: z.string().optional(),
});

export type Testimonial = z.infer<typeof testimonialSchema>;

export const enterpriseUserPartialSchema = z.object({
  _id: z.string().optional(),
  name: z.string().optional(),
  email: z.string().optional(),
  avatar: z.string().optional(),
  role: z.string().optional(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
  profile: z.record(z.unknown()).optional(),
});

export type EnterpriseUserPartial = z.infer<typeof enterpriseUserPartialSchema>;

export const enterpriseSkillRatingSchema = z.object({
  label: z.string(),
  experience: z.number().int().optional(),
  category: z.string().optional(),
});

export type EnterpriseSkillRating = z.infer<typeof enterpriseSkillRatingSchema>;

export enum EnterpriseProfileTagType {
  NoRatedOptions = "no-rated-options",
  RatedOptions = "rated-options",
  TextInput = "text",
}

export const enterpriseRatingMapSchema = z.object({
  label: z.string(),
  value: z.coerce.number().optional(),
});

export type EnterpriseRatingMap = z.infer<typeof enterpriseRatingMapSchema>;

export const enterpriseCommentsAssistantSchema = z.object({
  assistantId: z.string(),
  instructions: z.string(),
  files: z.array(assetSchema).optional(),
});

export type EnterpriseCommentsAssistant = z.infer<
  typeof enterpriseCommentsAssistantSchema
>;

export const enterpriseBriefGuidelines = z.object({
  description: z.string().optional(),
  files: z.array(assetSchema).optional(),
  assistantId: z.string().optional(), // OpenAI Assistant ID
  summary: z.string().optional(),
  checklist: z.string().optional(),
});

export type EnterpriseBriefGuidelines = z.infer<
  typeof enterpriseBriefGuidelines
>;

export const catalystTeamSettingsSchema = z.object({
  briefGuidelines: enterpriseBriefGuidelines.optional(),
  commentsAssistant: enterpriseCommentsAssistantSchema.optional(),
  objectives: z.string().optional(),
});

export type CatalystTeamSettings = z.infer<typeof catalystTeamSettingsSchema>;

export enum AIProvider {
  OpenAI = "openai",
  OpenAIReasoning = "openai-reasoning",
  Google = "google",
  Anthropic = "anthropic",
}

export const enterpriseProfileSettingsSchema =
  catalystTeamSettingsSchema.extend({
    skills: z.array(enterpriseSkillRatingSchema).optional(),
    industries: z.array(enterpriseSkillRatingSchema).optional(),
    tools: z.array(enterpriseSkillRatingSchema).optional(),
    softSkills: z.array(enterpriseSkillRatingSchema).optional(),
    growthAreas: z.array(z.string()).optional(),
    ratings: z.array(enterpriseRatingMapSchema).optional(),

    // Catalyst settings
    aiProviders: z.nativeEnum(AIProvider).array().optional(),
    delayedMailTime: z.coerce.number().optional(),
    extraFonts: z.array(z.string()).optional(),
  });

export type EnterpriseProfileSettings = z.infer<
  typeof enterpriseProfileSettingsSchema
>;

export const enterpriseTeamSchema = z.object({
  _id: z.string().optional(),
  users: z.array(z.union([z.string(), enterpriseUserPartialSchema])),
  profileSettings: catalystTeamSettingsSchema.optional(),
});

export const enterpriseSchema = z.object({
  _id: z.string().optional(),
  name: z.string(),
  slug: z.string().optional(),
  logo: z.string().optional(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
  primaryColor: z.string().optional(),
  hero: z.string().optional(),
  profile: z.record(z.unknown()).optional(),
  totalActiveGigs: z.number().optional(),
  totalInactiveGigs: z.number().optional(),
  industries: z.array(z.string()).optional(),
  testimonials: z.array(testimonialSchema).optional(),
  profileSettings: enterpriseProfileSettingsSchema.optional(),
  description: z.string().optional(),
  teams: z.array(enterpriseTeamSchema).optional(),
});

export type Enterprise = z.infer<typeof enterpriseSchema>;
