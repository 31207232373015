// eslint-disable-next-line import/named
import { Outlet, createRoute } from "@tanstack/react-router";
import { createContext, useContext, useState } from "react";

import { MessagesSidebar } from "./components/MessagesSidebar";

import { requiresAuth } from "../../utils/auth";
import { useAppSelector } from "../../utils/hooks";
import { useMessages } from "../../utils/queries/messages";
import { authLayoutRoute } from "../auth-layout/route";

export const messagesRoute = createRoute({
  getParentRoute: () => authLayoutRoute,
  path: "messages",
  beforeLoad() {
    requiresAuth();
  },
  component: function Component() {
    const [mobileDrawerVisible, setMobileDrawerVisible] = useState(false);
    const { headerHeight } = useAppSelector((state) => state.ui);
    useMessages();

    return (
      <MessagesContext.Provider
        value={{ mobileDrawerVisible, setMobileDrawerVisible }}
      >
        <div
          className="flex"
          style={{ height: `calc(100dvh - ${headerHeight}px)` }}
        >
          <MessagesSidebar />
          <Outlet />
        </div>
      </MessagesContext.Provider>
    );
  },
});

interface MessagesContextValues {
  mobileDrawerVisible: boolean;
  setMobileDrawerVisible: (next: boolean) => void;
}

const MessagesContext = createContext({} as MessagesContextValues);
export const useMessagesContext = () => useContext(MessagesContext);
