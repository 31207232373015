import { cva, cx } from "class-variance-authority";
import React, { forwardRef, isValidElement } from "react";
import {
  type InputHTMLAttributes,
  type ReactNode,
  type Ref,
  type TextareaHTMLAttributes,
} from "react";
import { extendTailwindMerge } from "tailwind-merge";

import { Icon } from "../foundation";
import { useId } from "../foundation/base";
import Typography, {
  type TypographyProps,
} from "../foundation/typography/Typography";

const twMerge = extendTailwindMerge({ prefix: "dali-" });

export interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "size"> {
  /**
   * Optionally, makes the TextField fill the parent's width
   */
  fullWidth?: boolean;
  /**
   * Optionally, sets helper text beneath the input. You can set a persistent
   * help message by simply passing a `ReactNode` as a value, or you can get
   * more granular control and display the helpText only when the field is
   * invalid by using an object.
   */
  helpText?: ReactNode | TextFieldHelperTextProps;
  /**
   * Optionally, shows an adornment to the left of the input. An adornment is
   * usually a piece of text (such as '$'), or an icon
   */
  startAdornment?: ReactNode;
  /**
   * Optionally, forces the field into an invalid state, and thus invalidates
   * the form
   */
  invalid?: boolean;
  /**
   * Optionally, display a floating label for the input
   */
  label?: string;
  /**
   * Optionally, render an adornment to the right of the input. An adornment is
   * usually a piece of text (such as 'kg'), or an icon
   */
  endAdornment?: ReactNode;
  /**
   * Optionally, sets the size variant of the input. This prop overrides
   * the default `size` behavior of the `<input>` element, which is effectively
   * the same as using CSS `width` on the elment. Width should always be
   * specified through classnames
   */
  size?: "lg" | "md" | "sm";
  /**
   * Optionally, render a yellow border and default icon to represent a warning
   * state that the user has provided potentially errant information to the
   * input.
   */
  warning?: boolean;
  /**
   * Optionally, render a green border and default icon to represent that the
   * user's input is valid and the goal is to explicitly provide that
   * experience. This is different than if `invalid={false}`
   */
  success?: boolean;
  /**
   * All other prop documentation can be found at the link below:
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attributes
   */
}

const inputContainerStyles = cva(
  [
    "mg-input",
    "dali-font-national2",
    "dali-font-text-regular",
    "dali-rounded-lg",
  ],
  {
    variants: {
      fullWidth: {
        true: ["dali-w-full"],
        false: [],
      },
      disabled: {
        true: [
          "dali-cursor-not-allowed",
          "[&>label]:dali-cursor-not-allowed",
          "dali-text-carbon-300",
        ],
        false: ["dali-cursor-text"],
      },
    },
  },
);

const inputStyles = cva(
  [
    "dali-font-[inherit]",
    "dali-border-2",
    "dali-rounded-lg",
    "dali-transition-all",
    "dali-flex",
    "dali-gap-4",
    "dali-items-center",
    "dali-cursor-inherit",
  ],
  {
    variants: {
      size: {
        sm: ["dali-px-4", "dali-py-2"],
        md: ["dali-px-4", "dali-py-3"],
        lg: ["dali-px-6", "dali-py-4"],
        textarea: ["dali-p-1"],
      },
      invalid: {
        true: ["dali-border-cadmium-600"],
        false: [
          "dali-border-carbon-300",
          "focus-within:dali-border-base-black",
        ],
      },
      warning: {
        true: ["dali-border-ochre-400"],
        false: [],
      },
      success: {
        true: ["dali-border-malachite-600"],
        false: [],
      },
      disabled: {
        true: [
          "dali-border-carbon-300",
          "dali-cursor-inherit",
          "dali-border-carbon-300",
        ],
      },
    },
    compoundVariants: [],
  },
);

const nativeInputStyles = cva(
  [
    "dali-text-base-black",
    "dali-outline-none",
    "dali-border-0",
    "dali-flex-1",
    "dali-w-full",
    "placeholder:dali-text-carbon-600",
    "disabled:dali-cursor-not-allowed",
    "disabled:placeholder:dali-text-carbon-300",
    "disabled:dali-text-carbon-300",
    "disabled:dali-bg-transparent",
  ],
  {
    variants: {
      size: {
        sm: ["dali-text-base"],
        md: ["dali-text-lg"],
        lg: ["dali-text-xl"],
        textarea: ["dali-p-3"],
      },
    },
  },
);

const helpTextStyles = cva(["mg-text-field__helper-line", "dali-mt-1"], {
  variants: {
    disabled: {
      true: ["dali-text-carbon-300"],
    },
    invalid: {
      true: ["dali-text-cadmium-600"],
      false: ["dali-text-base-black"],
    },
    textarea: {
      true: ["dali-flex", "dali-justify-between", "dali-gap-2"],
    },
  },
});

export const Input = forwardRef(
  (
    {
      fullWidth = false,
      helpText,
      invalid = false,
      warning = false,
      success = false,
      label,
      startAdornment,
      endAdornment,
      type = "text",
      value,
      size = "md",
      disabled = false,
      ...rest
    }: InputProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    const { style, className: hash, ...pass } = rest;
    const id = useId("input", { label, id: rest.id });
    const labelId = `${id}-label`;

    const labelSize: Record<
      NonNullable<InputProps["size"]>,
      NonNullable<TypographyProps["size"]>
    > = {
      sm: "base",
      md: "lg",
      lg: "lg",
    };
    const iconSize: Record<NonNullable<InputProps["size"]>, number> = {
      sm: 16,
      md: 20,
      lg: 24,
    };
    const iconColor =
      (invalid && "rgb(var(--cadmium-600))") ||
      (warning && "rgb(var(--ochre-400))") ||
      (success && "rgb(var(--malachite-600))") ||
      (disabled && "rgb(var(--carbon-300))") ||
      "rgb(var(--base-black))";

    const inputContainerClasses = cx(
      inputContainerStyles({ fullWidth, disabled }),
      hash,
    );
    const inputClasses = twMerge(
      inputStyles({ size, invalid, warning, success, disabled }),
    );

    const renderHelpText = () => {
      if (helpText == null) {
        return null;
      }

      const shouldSpread =
        typeof helpText === "object" && !isValidElement(helpText);

      return (
        <div className={helpTextStyles({ disabled, invalid })}>
          {helpText && shouldSpread ? (
            (helpText as TextFieldHelperTextProps).children
          ) : (
            <Typography size="sm">{helpText}</Typography>
          )}
        </div>
      );
    };

    return (
      <Icon.IconContext.Provider
        value={{ size: iconSize[size], color: iconColor }}
      >
        <div className={inputContainerClasses}>
          <label style={style} aria-labelledby={labelId} htmlFor={id}>
            {label != null && (
              <Typography
                size={labelSize[size]}
                className="dali-mb-2 dali-text-base-black"
              >
                {label}
              </Typography>
            )}
            <div role="presentation" className={inputClasses}>
              {startAdornment}
              <input
                ref={ref}
                type={type}
                value={value}
                id={id}
                className={nativeInputStyles({ size })}
                disabled={disabled}
                {...pass}
              />
              {!invalid && !warning && !success && endAdornment}
              {invalid && endAdornment && (
                <div className="dali-flex dali-gap-1">
                  <Icon.IconContext.Provider
                    value={{ color: "defaultColor", size: iconSize[size] }}
                  >
                    {endAdornment}
                  </Icon.IconContext.Provider>
                  <Icon.Prohibit />
                </div>
              )}
              {warning && endAdornment && <Icon.Warning />}
              {success && endAdornment && <Icon.CheckCircle />}
            </div>
          </label>
          {renderHelpText()}
        </div>
      </Icon.IconContext.Provider>
    );
  },
);

export interface TextFieldHelperTextProps {
  persistent?: boolean;
  validationMsg?: boolean;
  children: ReactNode;
}

export interface TextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  /**
   * Optionally, renders a character count. Must be used with `maxLength`
   */
  characterCount?: boolean;
  /**
   * Optionally, makes the TextField fill the parent's width
   */
  fullWidth?: boolean;
  /**
   * Optionally, sets helper text beneath the textarea. You can set a persistent
   * help message by simply passing a `ReactNode` as a value, or you can get
   * more granular control and display the helpText only when the field is
   * invalid by using an object.
   */
  helpText?: ReactNode | TextFieldHelperTextProps;
  /**
   * Optionally, forces the field into an invalid state, and thus invalidates
   * the form
   */
  invalid?: boolean;
  /**
   * Optionally, display a floating label for the textarea
   */
  label?: string;
  /**
   * Optionally, render a yellow border and default icon to represent a warning
   * state that the user has provided potentially errant information to the
   * input.
   */
  warning?: boolean;
  /**
   * Optionally, render a green border and default icon to represent that the
   * user's input is valid and the goal is to explicitly provide that
   * experience. This is different than if `invalid={false}`
   */
  success?: boolean;
}

export const Textarea = forwardRef(
  (
    {
      characterCount,
      fullWidth = false,
      helpText,
      invalid = false,
      warning = false,
      success = false,
      label,
      value,
      disabled,
      ...rest
    }: TextareaProps,
    ref: Ref<HTMLTextAreaElement>,
  ) => {
    const { style, className: hash, ...pass } = rest;
    const id = useId("textfield", { label, id: rest.id });
    const labelId = `${id}-label`;

    const inputContainerClasses = cx(
      inputContainerStyles({ fullWidth, disabled }),
      hash,
    );
    const inputClasses = twMerge(
      inputStyles({ size: "textarea", invalid, warning, success, disabled }),
    );

    const renderHelpText = (renderedCharacterCounter: JSX.Element | null) => {
      const shouldRender = !!helpText || (characterCount && pass.maxLength);

      if (!shouldRender) {
        return null;
      }

      const shouldSpread =
        typeof helpText === "object" && !isValidElement(helpText);

      return (
        <div className={helpTextStyles({ disabled, invalid, textarea: true })}>
          {helpText && shouldSpread ? (
            (helpText as TextFieldHelperTextProps).children
          ) : (
            <Typography size="sm">{helpText}</Typography>
          )}
          {renderedCharacterCounter}
        </div>
      );
    };

    const renderedCharacterCounter =
      characterCount && pass.maxLength ? (
        <Typography size="sm">
          {((value as string) ?? "").length}
          &nbsp;/&nbsp;
          {pass.maxLength}
        </Typography>
      ) : null;

    return (
      <div className={inputContainerClasses}>
        <label style={style} aria-labelledby={labelId} htmlFor={id}>
          {label != null && (
            <Typography size="base" className="dali-mb-2 dali-text-base-black">
              {label}
            </Typography>
          )}

          <div
            role="presentation"
            className={cx(inputClasses, "dali-relative")}
          >
            <textarea
              className={nativeInputStyles({ size: "textarea" })}
              ref={ref}
              value={value}
              id={id}
              disabled={disabled}
              {...pass}
            />
            {invalid && (
              <Icon.Prohibit
                color="rgb(var(--cadmium-600))"
                className="dali-absolute dali-top-4 dali-right-4"
              />
            )}
          </div>
        </label>
        {renderHelpText(renderedCharacterCounter)}
      </div>
    );
  },
);

Textarea.displayName = "Textarea";
Input.displayName = "Input";
