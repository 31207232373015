import { z } from "zod";

import { contractSchema } from "./contract";
import { customerSchema } from "./customer";
import { reportSchema } from "./report";
import { userSchema } from "./user";

export enum GigStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
  INTERVIEWING = "interviewing",
  REJECTED = "rejected",
  PENDING = "pending",
}

export const gigStatusSchema = z.nativeEnum(GigStatus);

export const clientSchema = z.object({
  id: z.union([userSchema, z.string()]),
  role: z.string(),
});

export type Client = z.infer<typeof clientSchema>;

export const gigSchema = z.object({
  _id: z.string().optional(),
  client: z.union([userSchema.partial(), z.string()]),
  salesRep: z.union([userSchema.partial(), z.string()]).optional(),
  talent: z.union([userSchema.partial(), z.string()]),
  customer: z.union([customerSchema, z.string()]).optional(),
  status: gigStatusSchema,
  createdAt: z.coerce.date().optional(),
  clientRate: z.number().optional(),
  talentRate: z.number().optional(),
  weeklyHours: z.number().optional(),
  budget: z.number().optional(),
  reports: z.array(z.union([reportSchema, z.string()])).optional(),
  clients: z.array(clientSchema).optional(),
  startedAt: z.coerce.date().optional(),
  endDates: z.array(z.coerce.date()).optional(),
  contractHistory: z.array(contractSchema).optional(),
  interviewsAt: z.array(z.coerce.date()).optional(),
  expectedToStartAt: z.coerce.date().optional(),
});

export type Gig = z.infer<typeof gigSchema>;
