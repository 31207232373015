import { Typography } from "@mg/dali/src";

export const TicketLoader: React.FC = () => (
  <div className="fixed inset-0 z-[201] flex items-center justify-center">
    <div className="flex items-center justify-center gap-x-3 rounded bg-base-white px-6 py-4">
      <div className="size-12 animate-spin rounded-full border-8 border-solid border-egyptian-blue-400 border-t-transparent"></div>
      <Typography size="2xl">Loading...</Typography>
    </div>
  </div>
);
