import { ButtonNew, Icon } from "@mg/dali/src";
import { useMutation } from "@tanstack/react-query";
import { useNavigate, useParams } from "@tanstack/react-router";
import cx from "classnames";
import { useFeatureFlagVariantKey } from "posthog-js/react";
import { useState } from "react";

import { AddListDialog } from "../../../components/AddListDialog";
import { Notetaker } from "../../../components/Notetaker";
import { impersonateUser } from "../../../services/impersonation";
import { isUserCreative } from "../../../utils/auth";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import { derive } from "../../../utils/slices/auth";
import { userProfileRoute } from "../../userProfile/route";
import { profileRoute } from "../route";

export function ProfileHeader() {
  const masqueradeAccess = useFeatureFlagVariantKey("masquerading");
  const { profileId } = useParams({ from: profileRoute.id }) as {
    profileId: string;
  };
  const navigate = useNavigate();
  const creative = useAppSelector((state) => state.profile.value);
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const impersonateMutation = useMutation({
    mutationKey: ["impersonate", profileId],
    mutationFn: () => impersonateUser(profileId),
    onSuccess(token) {
      localStorage.setItem("token", token);
      sendTokenToServiceWorker();
      dispatch(derive());

      navigate({ to: userProfileRoute.to });
    },
  });

  if (creative == null) {
    return null;
  }

  const isCreative = isUserCreative();
  const canImpersonate = masqueradeAccess === "enabled";

  return (
    <>
      <div className="sticky top-0 z-[1] ml-0 flex items-center justify-between bg-base-white py-1 md:static md:-ml-8 md:-mr-4">
        <ButtonNew variant="ghost" onClick={() => history.back()}>
          <Icon.ArrowLeft />
          Back
        </ButtonNew>
        <div className="flex items-center gap-2">
          <ButtonNew
            variant="ghost"
            className={cx({ hidden: !canImpersonate })}
            onClick={() => impersonateMutation.mutate()}
          >
            Impersonate
          </ButtonNew>
          <ButtonNew
            className={cx({ hidden: isCreative })}
            variant="ghost"
            onClick={() => setIsOpen(true)}
          >
            <Icon.ListPlus />
            Add to List
          </ButtonNew>
          <Notetaker creativeId={profileId} />
        </div>
        <AddListDialog
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          // @ts-expect-error TS2322: this really should be an object, but the
          // unraveling is too time-consuming for this milestone release.
          // JLC - 2024/10/28
          creatives={[creative._id]}
        />
      </div>
    </>
  );
}
