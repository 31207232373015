import { Typography } from "@mg/dali/src";
import cx from "classnames";
import { type FC, type PropsWithChildren } from "react";

type BottomNavigationProps = {
  activeStep: number; // 0-indexed
};

export const BottomNavigation: FC<PropsWithChildren<BottomNavigationProps>> = ({
  children,
  activeStep,
}: PropsWithChildren<BottomNavigationProps>) => {
  const STEPS = [
    ["1.", "Your Role"],
    ["2.", "Name & Avatar"],
    ["3.", "Portfolio Sync"],
    ["4.", "Project Approval"],
  ];

  return (
    <footer className="w-full bg-base-white">
      <div className="flex gap-px">
        {STEPS.map((s, i) => (
          <div
            key={i}
            className={cx("flex flex-1 px-2 py-1.5", {
              "bg-base-black": activeStep >= i,
              "bg-carbon-300": activeStep < i,
            })}
          >
            <Typography weight="bold" key={i} className={cx("text-base-white")}>
              {s[0]}
            </Typography>
            <Typography
              weight="bold"
              className="ml-1 hidden text-base-white md:flex"
            >
              {s[1]}
            </Typography>{" "}
          </div>
        ))}
      </div>

      {children}
    </footer>
  );
};
