import {
  getContactsResponseSchema,
  getUploadsResponseSchema,
} from "@mg/schemas/src/christo/contacts";
import { type EnterpriseOnboardingStatus } from "@mg/schemas/src/commons";

import { API_URL } from "../config/env";
import { smartFetch } from "../utils/http";

/**
 * Returns a list of all imported contacts from LinkedIn and other data sources.
 */
export async function getContacts(types: EnterpriseOnboardingStatus[]) {
  const json = await smartFetch(
    `${API_URL}/private/contacts?types=${types.join("&types=")}`,
    {
      method: "GET",
    },
  );

  const parsedData = getContactsResponseSchema.parse(json);
  return parsedData;
}

/**
 * Given a contact ID (userID), archives the profile and prevents it from
 * showing in the contact list.
 */
export async function archiveContact(contactId: string) {
  await smartFetch(`${API_URL}/private/contacts/${contactId}/archive`, {
    method: "POST",
  });

  return undefined;
}

/**
 * Sends a request to upload contacts from a given link.
 */
export async function uploadContacts(link: string) {
  await smartFetch(`${API_URL}/private/contacts/upload`, {
    method: "POST",
    body: JSON.stringify({ link }),
  });

  return undefined;
}

/**
 * Lists all the uploads and their status
 */
export async function getUploads() {
  const json = await smartFetch(`${API_URL}/private/contacts/upload`, {
    method: "GET",
  });

  const parsedData = getUploadsResponseSchema.parse(json);
  return parsedData;
}
