import { useMutation, useQuery } from "@tanstack/react-query";

import { type NotetakerProps } from "../../components/Notetaker";
import {
  createNote,
  deleteNote,
  getNotes,
  updateNote,
} from "../../services/notes";

export function useNotes(props: NotetakerProps) {
  const query = useQuery({
    queryKey: ["notes", JSON.stringify(props)],
    queryFn: () => getNotes(props),
  });

  return query;
}

export function useCreateNote() {
  return useMutation({
    mutationKey: ["create-note"],
    mutationFn: createNote,
  });
}

export function useDeleteNote() {
  return useMutation({
    mutationKey: ["delete-note"],
    mutationFn: deleteNote,
  });
}

export function useEditNote() {
  return useMutation({
    mutationKey: ["edit-note"],
    mutationFn: updateNote,
  });
}
