import { z } from "zod";

import { whatIMakeSchema } from "./myNetwork";

import { assetSchema, levelSchema } from "../commons";

const creativeSchema = z.object({
  _id: z.string(),
  searchTerms: z.array(z.string()).optional(),
  alignedSkills: z.array(z.string()).optional(),
  hero: assetSchema.optional(),
  testimonial: z
    .object({
      company: z.string().optional(),
      avatar: z.string().optional(),
      name: z.string(),
      title: z.string(),
      csat: z.number(),
      text: z.string().optional(),
    })
    .optional(),
});

export type CreativeRequest = z.infer<typeof creativeSchema>;

export const createBodySchema = z.object({
  title: z.string().trim().min(1),
  creative: creativeSchema.optional(),
  creatives: z.array(creativeSchema).optional(),
});

export type CreateBody = z.infer<typeof createBodySchema>;

export const createRequestSchema = z.object({
  body: createBodySchema,
});

export type CreateRequest = z.infer<typeof createRequestSchema>;

export const createResponseSchema = z.object({
  _id: z.string(),
  title: z.string(),
  creatives: z.array(z.string()),
});

export type CreateResponse = z.infer<typeof createResponseSchema>;

export const archiveParamsSchema = z.object({
  listId: z.string().nonempty(),
});

export const archiveRequestSchema = z.object({
  params: archiveParamsSchema,
});

export type ArchiveRequest = z.infer<typeof archiveRequestSchema>;

export const removeParamsSchema = z.object({
  listId: z.string().nonempty(),
  creativeId: z.string().nonempty(),
});

export type RemoveParams = z.infer<typeof removeParamsSchema>;

export const removeRequestSchema = z.object({
  params: removeParamsSchema,
});

export type RemoveRequest = z.infer<typeof removeRequestSchema>;

export const shareParamsSchema = z.object({
  listId: z.string().nonempty(),
});

export const shareBodySchema = z.object({
  email: z.string().nonempty(),
  message: z.string().optional(),
});

export type ShareBody = z.infer<typeof shareBodySchema>;

export const shareRequestSchema = z.object({
  params: shareParamsSchema,
  body: shareBodySchema,
});

export type ShareRequest = z.infer<typeof shareRequestSchema>;

export const addCreativeParamsSchema = z.object({
  listId: z.string().nonempty(),
  creativeId: z.string().nonempty(),
});

export type AddCreativeParams = z.infer<typeof addCreativeParamsSchema>;

export const addCreativeBodySchema = z.object({
  searchTerms: z.array(z.string()).optional(),
  alignedSkills: z.array(z.string()).optional(),
  hero: assetSchema.optional(),
  testimonial: z.object({}).optional(),
});

export type AddCreativeBody = z.infer<typeof addCreativeBodySchema>;

export const addCreativeRequestSchema = z.object({
  params: addCreativeParamsSchema,
  body: addCreativeBodySchema,
});

export type AddCreativeRequest = z.infer<typeof addCreativeRequestSchema>;

export const addCreativeResponseSchema = z.object({
  _id: z.string(),
  title: z.string(),
  creatives: z.array(z.string()),
});

export type AddCreativeResponse = z.infer<typeof addCreativeResponseSchema>;

export const addCreativesParamsSchema = z.object({
  listId: z.string().nonempty(),
});

export type AddCreativesParams = z.infer<typeof addCreativesParamsSchema>;

export const addCreativesBodySchema = z.array(z.string());

export type AddCreativesBody = z.infer<typeof addCreativesBodySchema>;

export const addCreativesRequestSchema = z.object({
  params: addCreativesParamsSchema,
  body: addCreativesBodySchema,
});

export type AddCreativesRequest = z.infer<typeof addCreativesRequestSchema>;

export const getSharedLinkQuerySchema = z.object({
  e: z.string().nonempty(),
  cl: z.string().nonempty(),
});

export const getSharedLinkRequestSchema = z.object({
  query: getSharedLinkQuerySchema,
});

export type GetSharedLinkRequest = z.infer<typeof getSharedLinkRequestSchema>;

export const getByIdParamsSchema = z.object({
  listId: z.string().nonempty(),
});

export const getByIdRequestSchema = z.object({
  params: getByIdParamsSchema,
});

export type GetByIdRequest = z.infer<typeof getByIdRequestSchema>;

export const getByIdResponseSchema = z.object({
  _id: z.string(),
  title: z.string(),
  createdAt: z.string().datetime(),
  creatives: z.array(
    z.object({
      _id: z.string(),
      name: z.string(),
      avatar: z.string(),
      type: z.string().optional(),
      note: z.string().optional(),
      title: z.string().optional(),
      hourlyRate: z.number(),
      skills: z.array(z.string()),
      website: z.string(),
      isPortfolioActive: z.boolean(),
      isAvailable: z.boolean(),
      specialties: z.array(z.string()),
      brands: z.array(z.string()).optional(),
      hero: assetSchema,
      interviewsAt: z.array(z.coerce.date()).optional(),
      gigId: z.string().optional(),
      isCompleted: z.boolean().optional(),
      searchTerms: z.array(z.string()).optional(),
      alignedSkills: z.array(z.string()).optional(),
      level: levelSchema.optional(),
      trustScore: z.coerce.number().optional(),
      hasWorkedWith: z.boolean(),
      hasWorkedWithEnterprise: z.boolean(),
      workedWith: z.array(
        z.object({
          _id: z.string(),
          name: z.string().optional(),
          avatar: z.string().optional(),
        }),
      ),
      testimonial: z
        .object({
          avatar: z.string().optional(),
          name: z.string(),
          title: z.string(),
          csat: z.number(),
          text: z.string().optional(),
        })
        .optional(),
      whatIMake: whatIMakeSchema.optional(),
      assets: z.array(z.string()),
      highlightsSummaries: z
        .array(
          z.object({
            _id: z.string(),
            title: z.string().optional(),
            company: z.string().optional(),
            role: z.string().optional(),
            hero: z.string().optional(),
          }),
        )
        .optional(),
    }),
  ),
});

export type GetByIdResponse = z.infer<typeof getByIdResponseSchema>;

export const getSummaryResponseSchema = z.object({
  myLists: z.array(
    z.object({
      _id: z.string(),
      title: z.string(),
      creatives: z.array(z.string()),
    }),
  ),
  shared: z.array(
    z.object({
      _id: z.string(),
      title: z.string(),
      creatives: z.array(z.string()),
      isNew: z.boolean(),
    }),
  ),
});

export type GetSummaryResponse = z.infer<typeof getSummaryResponseSchema>;

export const getNetworkListsResponseSchema = z.array(
  z.object({
    _id: z.string(),
    title: z.string(),
    creatives: z.array(
      z.object({
        _id: z.string(),
        name: z.string(),
        avatar: z.string().optional(),
      }),
    ),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
  }),
);

export type GetNetworkLists = z.infer<typeof getNetworkListsResponseSchema>;

export const updateParamsSchema = z.object({
  listId: z.string().nonempty(),
});

export type UpdateParams = z.infer<typeof updateParamsSchema>;

export const updateBodySchema = z.object({
  title: z.string().nonempty(),
});

export type UpdateBody = z.infer<typeof updateBodySchema>;

export const updateRequestSchema = z.object({
  params: updateParamsSchema,
  body: updateBodySchema,
});

export type UpdateRequest = z.infer<typeof updateRequestSchema>;

export const updateResponseSchema = getByIdResponseSchema;

export type UpdateResponse = z.infer<typeof updateResponseSchema>;

export const duplicateParamsSchema = z.object({
  listId: z.string().nonempty(),
});

export type DuplicateParams = z.infer<typeof duplicateParamsSchema>;

export const duplicateBodySchema = z.object({
  title: z.string().optional(),
});

export type DuplicateBody = z.infer<typeof duplicateBodySchema>;

export const duplicateRequestSchema = z.object({
  params: duplicateParamsSchema,
  body: duplicateBodySchema,
});

export type DuplicateRequest = z.infer<typeof duplicateRequestSchema>;

export const duplicateResponseSchema = z.object({
  _id: z.string(),
  title: z.string(),
  creatives: z.array(z.string()),
});

export type DuplicateResponse = z.infer<typeof duplicateResponseSchema>;

export const shareWithOutsideOfNetworkBody = z.object({
  invites: z.array(z.object({ email: z.string().email(), name: z.string() })),
  message: z.string(),
  subject: z.string().optional(),
});

export type ShareWithOutsideOfNetworkBody = z.infer<
  typeof shareWithOutsideOfNetworkBody
>;

export const shareWithOutsideOfNetworkParamsSchema = z.object({
  listId: z.string(),
});

export type ShareWithOutsideOfNetworkParams = z.infer<
  typeof shareWithOutsideOfNetworkParamsSchema
>;

export const shareWithOutsideOfNetworkRequestSchema = z.object({
  body: shareWithOutsideOfNetworkBody,
  params: shareWithOutsideOfNetworkParamsSchema,
});

export type ShareWithOutsideOfNetworkRequest = z.infer<
  typeof shareWithOutsideOfNetworkRequestSchema
>;
