import { Text, Flex, Progress } from "@radix-ui/themes";
import React, { useState, useEffect } from "react";

interface PendingProgressProps {
  isPending: boolean;
  pendingText?: string;
}

const PendingProgress: React.FC<PendingProgressProps> = ({
  isPending,
  pendingText,
}) => {
  const [progressValue, setProgressValue] = useState(0);

  useEffect(() => {
    if (isPending) {
      const timer = setInterval(() => {
        setProgressValue((prev) => {
          if (prev === 99) {
            return prev;
          }
          if (prev >= 90) {
            return prev + 0.5;
          }
          if (prev >= 80) {
            return prev + 1;
          }
          return prev + 2;
        });
      }, 500);
      return () => clearInterval(timer);
    }
  }, [isPending]);

  return (
    <>
      {isPending && (
        <div className="pointer-events-none fixed inset-0 z-[201] flex items-center justify-center">
          <Flex direction="column" gap="2" width="400px">
            <Text size="7" className="text-wrap text-center">
              {pendingText ??
                "We're uploading your file and performing some close up magic, hang tight and don't blink!"}
            </Text>
            <Progress size="3" value={progressValue} />
          </Flex>
        </div>
      )}
    </>
  );
};

export default PendingProgress;
