import {
  CaretDown,
  GridFour,
  ListBullets,
  MagnifyingGlass,
} from "@phosphor-icons/react";
import {
  Button,
  DropdownMenu,
  Flex,
  SegmentedControl,
  TextField,
} from "@radix-ui/themes";
import { useEffect, useState } from "react";

import { HighlightModal } from "../../../components/HighlightModal";
import { useAppSelector } from "../../../utils/hooks";

export function TutorialHeader() {
  const { tutorialStep } = useAppSelector((state) => state.onboarding);
  const [open, setOpen] = useState(true);
  useEffect(() => {
    if (tutorialStep != 1) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [tutorialStep]);
  return (
    <header className="flex items-center justify-between">
      <Flex align="center" gap="4">
        {/* <Heading as="h1" size="5" weight="bold">
        <Link asChild color="gray">
          <NavLink
            to={ticketsRoute.to}
            search={({ folderId: _folderId, ...prev }) => prev}
          >
            Projects
          </NavLink>
        </Link>
      </Heading> */}

        {/* <Breadcrumbs /> */}
      </Flex>

      <section className="flex items-center gap-2">
        <TextField.Root placeholder="Search Ticket Name" size="1" disabled>
          <TextField.Slot>
            <MagnifyingGlass />
          </TextField.Slot>
        </TextField.Root>

        {/* <Combobox
        multiple
        value={participants ?? []}
        as="div"
        className="relative rounded-md focus-within:ring-2 focus-within:ring-puntt-blue-8 focus-within:ring-offset-[-1px]"
        disabled={isPending}
        onChange={(newParticipants) => {
          onSearch();
          navigate({
            search(prev) {
              return {
                ...prev,
                participants: newParticipants,
              };
            },
            replace: true,
          });
        }}
      >
        <Combobox.Button className="flex items-center gap-1 overflow-hidden rounded-md border border-puntt-neutral-gray-7 bg-base-white px-0.5 py-[3px]">
          <UserCircle className="text-puntt-neutral-gray-9" />
          {Array.isArray(participants) && participants.length > 0 && (
            <Badge size="1" variant="surface">
              {participants.length} selected{" "}
            </Badge>
          )}
          <Combobox.Input
            onChange={({ target }) => setParticipantsQuery(target.value)}
            className="border-none text-xs outline-transparent"
            placeholder="Search Participants"
          />
          <CaretDown />
        </Combobox.Button>
        <Combobox.Options className="absolute top-full z-10 mt-0.5 grid max-h-72 w-full gap-1 overflow-auto rounded-md border border-puntt-neutral-gray-7 bg-base-white p-1 shadow-lg">
          {filteredParticipants.map((p) => (
            <Combobox.Option
              key={p._id}
              value={p._id}
              disabled={isPending}
              className={({ active, selected, disabled }) =>
                cx("cursor-pointer rounded-lg px-2 py-0.5", {
                  "opacity-50": disabled,
                  "bg-puntt-blue-5": active && selected && !disabled,
                  "bg-puntt-blue-9 text-base-white": selected || active,
                })
              }
            >
              <Text size="1">{p.name}</Text>
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </Combobox> */}

        <SegmentedControl.Root
          defaultValue="grid"
          size="1"
          onValueChange={(value: string) => {
            localStorage.setItem("layout", value);
          }}
        >
          <SegmentedControl.Item value="list">
            <ListBullets />
          </SegmentedControl.Item>
          <SegmentedControl.Item value="grid">
            <GridFour />
          </SegmentedControl.Item>
        </SegmentedControl.Root>
        <HighlightModal order={1} show tooltipPosition={{ top: 200, right: 0 }}>
          <DropdownMenu.Root open={open}>
            <DropdownMenu.Trigger>
              <Button size="1">
                New <CaretDown />
              </Button>
            </DropdownMenu.Trigger>

            <DropdownMenu.Content>
              <DropdownMenu.Item className="pointer-events-none">
                File Upload
              </DropdownMenu.Item>
              <DropdownMenu.Item className="pointer-events-none">
                Folder Upload
              </DropdownMenu.Item>
              <DropdownMenu.Item className="pointer-events-none">
                New Folder
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        </HighlightModal>
      </section>
    </header>
  );
}
