import React, { useState, useEffect, useReducer, useCallback } from "react";
import { type ChangeEvent, type ReactNode } from "react";

import { SimpleDialog, type SimpleDialogProps } from "./Dialog";

import { Typography } from "../foundation";
import { ArrayEmitter, randomId } from "../foundation/base";
import { Input, type InputProps } from "../textfield";

export interface DialogQueueProps extends SimpleDialogProps {
  /**
   * An array of event-emitting Dialogs
   */
  dialogs: ArrayEmitter;
}

export function DialogQueue({
  dialogs,
  ...defaultDialogProps
}: DialogQueueProps) {
  // https://reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
  const [, forceUpdate] = useReducer((value) => value + 1, 0);
  const [closingDialogs, setClosingDialogs] = useState<{
    [key: string]: SimpleDialogProps;
  }>({});

  const removeDialog = useCallback(
    (evt, dialog) => {
      setClosingDialogs({
        ...closingDialogs,
        [dialog.id]: true,
      });

      dialog.resolve(evt);

      // remove the dialog from our array
      dialogs.remove(dialog);

      // remove the dialog from the closing state
      const closingDialogsCopy = { ...closingDialogs };
      delete closingDialogsCopy[dialog.id];

      setClosingDialogs(closingDialogsCopy);
    },
    [closingDialogs, dialogs],
  );

  useEffect(() => {
    dialogs.on("change", forceUpdate);

    return () => {
      dialogs.off("change", forceUpdate);
    };
  }, [dialogs]);

  // A simple way to show only one Dialog at a time. We
  // loop through each until we find a Dialog that's not
  // closing. When one is closing, we flip this flag and
  // render all the other ones in a closed state. This
  // ensures we get the proper animations for closing
  // dialogs.
  let foundOpen = false;

  return (
    // This must be wrapped in a Fragment until React type
    // definitions allow Element[] as a return value, which
    // is valid React JSX syntax.
    <>
      {dialogs.array.map((dialog: SimpleDialogProps & PromiseConstructor) => {
        const { id, resolve: _resolve, reject: _reject, ...rest } = dialog;

        const rendered = (
          <SimpleDialog
            {...defaultDialogProps}
            {...rest}
            key={id}
            open={!closingDialogs[id as string] && !foundOpen}
            onClose={async (evt) => {
              if (dialog.onClose) {
                await dialog.onClose(evt);
              }
              removeDialog(evt, dialog);
            }}
          />
        );

        if (!closingDialogs[id as string]) {
          foundOpen = true;
        }

        return rendered;
      })}
    </>
  );
}

export interface DialogPromise extends SimpleDialogProps, PromiseConstructor {}

/**
 * Base dialog factory that handles the promise chain with
 * consistent behavior
 *
 * @param {Function} factory a factory callback
 * @param {ArrayEmitter} queue the queue to add the factory to
 */
function dialogFactory<T extends (dialog: DialogPromise) => DialogPromise>(
  factory: T,
  queue: ArrayEmitter,
) {
  return (dialog: SimpleDialogProps) =>
    new Promise((resolve, reject) => {
      const d = factory({
        id: randomId(
          (Math.random() + Math.random() + 1).toString(36).substring(2),
        ),
        ...dialog,
        // @ts-expect-error TS2322: TODO - resolve this type
        resolve,
        // @ts-expect-error TS2322: TODO - resolve this type
        reject,
      });

      queue.push(d);
    });
}

export interface PromptBodyProps {
  body: ReactNode;
  inputProps: InputProps;
  apiRef(value: () => string): void;
}
/**
 * Handler to open a Prompt-style dialog.
 * Note: we have to jump through a few hoops to get the
 *   value back out.
 */
function PromptBody({ body, inputProps, apiRef }: PromptBodyProps) {
  const [value, setValue] = useState<string>("");

  useEffect(() => {
    apiRef(() => value);
  }, [apiRef, value]);

  return (
    <div>
      {!!body && <div style={{ marginBottom: 16 }}>{body}</div>}
      <Input
        {...inputProps}
        value={value}
        fullWidth
        onChange={(evt: ChangeEvent<HTMLInputElement>) => {
          setValue(evt.currentTarget.value);
        }}
        data-testid="name-field"
      />
    </div>
  );
}

function promptFactory(dialog: DialogPromise) {
  let getValue: (() => string) | undefined = () => "";

  const body = (
    <>
      <PromptBody
        body={dialog.body}
        inputProps={
          (dialog as DialogPromise & PromptBodyProps)
            .inputProps as PromptBodyProps["inputProps"]
        }
        apiRef={(_getValue) => {
          getValue = _getValue;
        }}
      />
    </>
  );

  return {
    title: "Prompt",
    ...dialog,
    body,
    resolve: (evt: CustomEvent<{ action: string }>) => {
      const returnValue = getValue && getValue();
      getValue = undefined;
      return dialog.resolve(
        evt.detail.action === "accept" ? returnValue : null,
      );
    },
  };
}

/**
 * Handler to open an Alert-style dialog
 */
function alertFactory(dialog: DialogPromise) {
  return {
    title: "Alert",
    body: <Typography size="sm">You have been alerted!</Typography>,
    acceptLabel: "OK",
    cancelLabel: null,
    ...dialog,
    resolve: (evt: CustomEvent<{ action: string }>) =>
      dialog.resolve(evt.detail.action),
  };
}

/**
 * Handler to open a Confirm-style dialog
 */
function confirmFactory(dialog: DialogPromise) {
  return {
    title: "Confirm",
    body: <Typography size="sm">Are you sure you want to do that?</Typography>,
    acceptLabel: "OK",
    cancelLabel: "Cancel",
    ...dialog,
    resolve: (evt: CustomEvent<{ action: string }>) =>
      dialog.resolve(evt.detail.action === "accept"),
  };
}

/**
 * Finally, create the Dialog queue
 */
export const createDialogQueue = () => {
  const dialogs = new ArrayEmitter();

  return {
    dialogs,
    // @ts-expect-error TS2322: TODO - resolve this type
    alert: dialogFactory(alertFactory, dialogs),
    // @ts-expect-error TS2322: TODO - resolve this type
    confirm: dialogFactory(confirmFactory, dialogs),
    // @ts-expect-error TS2322: TODO - resolve this type
    prompt: dialogFactory(promptFactory, dialogs),
  };
};
