const {
  VITE_API_URL = "http://localhost:3000/dev/v1",
  VITE_SCRAPE_URL = "https://uyr6ap3xlnpq4lpjyo32vsfrri0xgadz.lambda-url.us-east-1.on.aws/",
  VITE_AI_URL,
  VITE_INTERCOM_ACCESS_TOKEN,
  VITE_AUTH_URL,
  VITE_SEARCH_URL,
  VITE_DD_CLIENT_TOKEN,
  VITE_DD_HOST,
  VITE_DD_APP_ID,
  VITE_DD_VERSION,
  VITE_CALENDLY_URL,
  VITE_CHAT_URL,
  VITE_CHAT_WS_URL,
  VITE_UPLOAD_API_URL,
  VITE_YJS_HOST,
  VITE_POSTHOG_KEY,
  VITE_POSTHOG_HOST,
  VITE_COOKIE_NAME,
  VITE_BASEPATH = "/",
} = import.meta.env;

export const API_URL = VITE_API_URL;
export const INTERCOM_ACCESS_TOKEN = VITE_INTERCOM_ACCESS_TOKEN;
export const AUTH_URL = VITE_AUTH_URL;
export const SEARCH_URL = VITE_SEARCH_URL;
export const DD_CLIENT_TOKEN = VITE_DD_CLIENT_TOKEN;
export const DD_HOST = VITE_DD_HOST;
export const DD_APP_ID = VITE_DD_APP_ID;
export const DD_VERSION = VITE_DD_VERSION;
export const CALENDLY_URL = VITE_CALENDLY_URL;
export const CHAT_URL = VITE_CHAT_URL;
export const CHAT_WS_URL = VITE_CHAT_WS_URL;
export const UPLOAD_API_URL = VITE_UPLOAD_API_URL;
export const SCRAPE_URL = VITE_SCRAPE_URL;
export const AI_URL = VITE_AI_URL;
export const YJS_HOST = VITE_YJS_HOST;
export const POSTHOG_KEY = VITE_POSTHOG_KEY;
export const POSTHOG_HOST = VITE_POSTHOG_HOST;
export const COOKIE_NAME = VITE_COOKIE_NAME;
export const BASEPATH = VITE_BASEPATH;
