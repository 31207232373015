import { useSearch } from "@tanstack/react-router";

import { useAppSelector } from "../hooks";

export const useReversedIndex = () => {
  const { revIndex: activeRevisionIndex = 0 } = useSearch({
    strict: false,
  });
  const { revisions } = useAppSelector((state) => state.ticket);
  return revisions.length - 1 - activeRevisionIndex;
};
