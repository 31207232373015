import { ButtonNew, Checkbox, Icon, Textarea, Typography } from "@mg/dali/src";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { type EnterpriseProfileType, User } from "@mg/schemas/src/commons";
import { useNavigate } from "@tanstack/react-router";
import cx from "classnames";
import { useEffect, useState } from "react";

import { AvatarWithInitials } from "../../../components/AvatarWithInitials";
import { useAnalytics } from "../../../utils/analytics";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import { assetForUser } from "../../../utils/imageHandler";
import {
  useEditProjectMutation,
  useFetchRecommendedCreatives,
  useSendToDesignMutation,
} from "../../../utils/queries/projects";
import { setTicket } from "../../../utils/slices/ticket";
import { useTicket } from "../routes/ticket";

interface SuggestedUsers {
  _id: string;
  role: EnterpriseProfileType;
  name?: string | undefined;
  avatar?: string | undefined;
  email?: string | undefined;
}

export const SendToDesignSidebar = () => {
  const posthog = useAnalytics("SendToDesignSidebar");
  const navigate = useNavigate();
  const [selections, setSelections] = useState<Set<string>>(new Set());
  const [note, setNote] = useState<string>("");
  const ticket = useAppSelector((state) => state.ticket.value);
  const ticketMutation = useEditProjectMutation(ticket?._id as string);
  const user = useAppSelector((state) => state.auth.value);
  const sendToDesignMutation = useSendToDesignMutation();
  const { toggleDrawer, tab } = useTicket();
  const dispatch = useAppDispatch();
  const designers = useFetchRecommendedCreatives(ticket?._id as string);

  let suggested: SuggestedUsers[] = [],
    more: SuggestedUsers[] = [],
    network: SuggestedUsers[] = [];

  if (designers.data) {
    suggested = designers.data.suggested;
    more = designers.data.more;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    network = designers.data.network;
  }

  useEffect(() => {
    if (ticket) {
      // @ts-expect-error TS2339: BE returns a different type
      setSelections(new Set(ticket.creatives?.map((c) => c._id) ?? []));
    }
  }, [ticket]);

  const handleToggle = (id: string) => {
    const newSelections = new Set(selections);
    if (newSelections.has(id)) {
      newSelections.delete(id);
    } else {
      newSelections.add(id);
    }
    setSelections(newSelections);
  };

  const handleSubmit = () => {
    sendToDesignMutation.mutateAsync(
      {
        ticketId: ticket?._id as string,
        creatives: Array.from(selections),
        note,
      },
      {
        onSuccess: () => {
          posthog.capture("assigned_designers", {
            designers: Array.from(selections),
          });
          ticketMutation
            .mutateAsync({
              _id: ticket?._id as string,
              workflowStep: (tab ?? 0) + 1,
            })
            .then((response) => {
              dispatch(setTicket(response));

              navigate({
                replace: true,
                search: {
                  tab: (tab ?? 0) + 1,
                },
              });
            });
        },
      },
    );
  };

  return (
    <aside
      className={cx(
        "fixed right-0 top-0 z-[200] h-screen w-80 bg-base-white shadow-lg transition-transform",
      )}
    >
      <header className="flex items-center justify-between border-b border-carbon-300 p-4 text-base-black">
        <Typography size="base" weight="medium">
          Assign Creatives
        </Typography>
        <button className="p-1" onClick={() => toggleDrawer("design")}>
          <Icon.X />
        </button>
      </header>
      <div className="flex h-full flex-col pb-14">
        <div className="flex h-full flex-col justify-start overflow-y-scroll">
          <div className="flex items-center justify-between border-y border-carbon-300 bg-carbon-50 px-4 py-2">
            <Typography weight="medium">
              {user?.company}&apos;s Creative Team
            </Typography>
          </div>
          <div className="flex items-center gap-1 px-4 py-2">
            <Typography size="sm" className="text-carbon-600">
              Suggested Creatives
            </Typography>
          </div>

          {suggested.map((result) => (
            <div
              role="button"
              tabIndex={0}
              key={result._id}
              className="flex cursor-pointer items-center gap-1 px-4 py-2"
              onClick={() => handleToggle(result._id)}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  handleToggle(result._id);
                }
              }}
            >
              <Checkbox
                key={"checkbox" + result._id}
                id={"checkbox" + result._id}
                size="sm"
                value={String(result._id)}
                checked={selections.has(result._id)}
                onClickLabel={(e) => e.preventDefault()}
                label=""
                className="rounded-sm border-carbon-200"
              />
              <AvatarWithInitials
                avatar={assetForUser(result.avatar)}
                name={result.name ?? ""}
                size={10}
                square
              />
              <Typography>{result.name}</Typography>
            </div>
          ))}
          <div className="flex items-center gap-1 px-4 py-2">
            <Typography size="sm" className="text-carbon-600">
              More Creatives
            </Typography>
          </div>

          {more.map((result) => (
            <div
              role="button"
              tabIndex={0}
              key={result._id}
              className="flex cursor-pointer items-center gap-1 px-4 py-2"
              onClick={() => handleToggle(result._id)}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  handleToggle(result._id);
                }
              }}
            >
              <Checkbox
                key={"checkbox" + result._id}
                id={"checkbox" + result._id}
                size="sm"
                value={String(result._id)}
                checked={selections.has(result._id)}
                onClickLabel={(e) => e.preventDefault()}
                label=""
                className="rounded-sm border-carbon-200"
              />
              <AvatarWithInitials
                avatar={assetForUser(result.avatar)}
                name={result.name ?? ""}
                size={10}
                square
              />
              <Typography>{result.name}</Typography>
            </div>
          ))}
        </div>

        <div className="grid gap-y-2 px-4 py-2">
          <Textarea
            label="Add a Note"
            className="py-1"
            onChange={(e) => setNote(e.target.value)}
          />
          <ButtonNew
            className="w-fit bg-egyptian-blue-600 text-sm"
            onClick={handleSubmit}
            isLoading={
              sendToDesignMutation.isPending || ticketMutation.isPending
            }
            disabled={
              sendToDesignMutation.isPending || ticketMutation.isPending
            }
          >
            Assign Now
          </ButtonNew>
        </div>
      </div>
    </aside>
  );
};
