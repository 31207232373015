// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ButtonNew, Card, Typography } from "@mg/dali/src";
import { type MyNetworkProfile } from "@mg/schemas/src/christo/myNetwork";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import cx from "classnames";
import { useState } from "react";

import { Recommendations } from "./Recommendations";

import { LabeledMappedChips } from "../../../components/LabeledMappedChips";
import { useAppSelector } from "../../../utils/hooks";
import { useUpdateTags } from "../../../utils/queries/profile";

export function ProfessionalDetails(
  props: MyNetworkProfile & {
    matchedTerms: Record<
      string,
      { value: string; context: string; term: string }[]
    >;
  },
) {
  const tagsMutation = useUpdateTags(props._id);
  const user = useAppSelector((state) => state.auth.value);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showWorkedWith, setShowWorkedWith] = useState(false);
  //@ts-expect-error TS2367: incorrect types coming from backend
  if (props.type === "pipeline") {
    return null;
  }

  const isCreative =
    !user?.permissions?.includes("enterprise-team") &&
    !user?.permissions?.includes("enterprise-admin");

  function handleSaveTags(tags: (string | number)[]) {
    return tagsMutation.mutateAsync({
      portfolioId: props._id,
      tags: tags as string[],
    });
  }

  return (
    <Card
      size="sm"
      type="outlined"
      className="relative inline-grid w-full gap-6 rounded-2xl"
      uninteractive
      data-testid="portfolio-tools-tags"
    >
      <div className="grid gap-y-4">
        <LabeledMappedChips
          title="Tools"
          values={props.tools}
          hideWhenEmpty
          searchChips
          matchedTerms={props.matchedTerms?.tools}
        />

        {!isCreative && (
          <LabeledMappedChips
            subheader={`These tags describe ${props.name} outside of their work. Add attributes like education, additional skills, soft skills, hobbies, talents, etc`}
            title="Tags"
            values={props.tags}
            editable
            onSave={handleSaveTags}
            searchChips
            matchedTerms={props.matchedTerms?.tags}
          />
        )}

        {!isCreative && <Recommendations />}
      </div>
    </Card>
  );
}
