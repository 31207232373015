import { type PartialProfile } from "@mg/schemas/src/christo/profile";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

import { getUserProfile } from "../../services/userProfile";
import { useAppDispatch } from "../hooks";
import { setUserProfile } from "../slices/userProfile";

export function useCurrentUserProfile(): typeof query & {
  data: PartialProfile | undefined;
} {
  const dispatch = useAppDispatch();

  const query = useQuery({
    queryKey: ["user-profile"],
    queryFn: getUserProfile,
  });

  useEffect(() => {
    if (query.data != null) {
      dispatch(setUserProfile(query.data));
    }
  }, [query.data, dispatch]);

  return query;
}
