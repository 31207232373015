import { z } from "zod";

import { API_URL } from "../config/env";
import { smartFetch } from "../utils/http";

export async function impersonateUser(userId: string) {
  const json = await smartFetch(
    `${API_URL}/private/my-network/impersonate/${userId}`,
    {
      method: "POST",
    },
  );

  const parsedData = z.string().parse(json);

  return parsedData;
}
