import { z } from "zod";

import { creativeListSchema } from ".";

import { enterpriseSchema } from "./enterprise";
import { userSchema } from "./user";

export const noteSchema = z.object({
  _id: z.string().optional(),
  creative: z.union([userSchema, z.string()]).optional(),
  creativeList: z.union([creativeListSchema, z.string()]).optional(),
  enterprise: z.union([enterpriseSchema, z.string()]).optional(),
  createdBy: z.union([userSchema, z.string()]),
  createdByName: z.string().optional(),
  content: z.string(),
  title: z.string().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  updatedBy: z.union([userSchema, z.string()]).optional(),
});

export type Note = z.infer<typeof noteSchema>;
