import { z } from "zod";

import { ticketSchema } from "./ticket";

const textractDataSchema = z.object({
  layout: z.any(),
  extra_layout_info: z.any(),
  raw: z.any(),
});

export type TextractData = z.infer<typeof textractDataSchema>;

const aiCacheSchema = z.object({
  key: z.string(), // original image key, could be tile piece image
  parentKey: z.string().optional(), // original file, could be PDF or larger image that is now tiled
  parentAssetId: z.string().optional(),
  convertedKey: z.string().optional(), // AI resized image
  textract: textractDataSchema.optional(),
  ticket: z.union([z.string(), ticketSchema.partial()]),
  page: z.coerce.number().optional(),
  x: z.coerce.number().optional(), // original position X
  y: z.coerce.number().optional(), // original position Y
  timestamp: z.coerce.number().optional(), // Video timestamp integer position
  dimensions: z.array(z.coerce.number()).optional(), // resized AI image dimensions
  parentDimensions: z.array(z.coerce.number()).optional(), // parent original image dimensions
  originalDimensions: z.array(z.coerce.number()).optional(), // image used that could be tiled, without resize
  boardId: z.string().optional(),
});

export type AICache = z.infer<typeof aiCacheSchema>;
