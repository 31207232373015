import { UploadSimple } from "@phosphor-icons/react";
import { Text } from "@radix-ui/themes";
import React from "react";

interface DragAndDropFileUploadProps {
  isDragging: boolean;
}

export const DragAndDropFileUpload: React.FC<DragAndDropFileUploadProps> = ({
  isDragging,
}) => {
  if (!isDragging) {
    return null;
  }
  return (
    <div className="fixed inset-x-0 bottom-0 z-50 flex h-1/3 flex-col justify-end bg-gradient-to-t from-violet-violet7 to-transparent text-puntt-accent-10 transition-all">
      <div className="m-6 flex flex-col items-center justify-end gap-6 rounded-xl border-2 border-dashed border-violet-violet8 p-6">
        <UploadSimple size={36} />
        <Text size="6">Drop Files or Folders to Upload</Text>
      </div>
    </div>
  );
};
