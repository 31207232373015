import { Eye, EyeClosed } from "@phosphor-icons/react";
import { Button, Grid, Heading, Text, TextField } from "@radix-ui/themes";
/* eslint-disable-next-line import/named */
import { Link } from "@tanstack/react-router";
import cx from "classnames";
import { useReducer, type FormEventHandler } from "react";

import { forgotPasswordRoute } from "../forgot-password/route";

interface LoginFormProps {
  formRef: React.RefObject<HTMLFormElement>;
  isValid: boolean;
  onSubmit: FormEventHandler<HTMLFormElement>;
  checkValidity: () => void;
  loading: boolean;
  disabled: boolean;
  error: boolean;
}

export function LoginForm({
  formRef,
  isValid,
  onSubmit,
  checkValidity,
  loading,
  disabled,
  error,
}: LoginFormProps) {
  const [isPasswordVisible, togglePasswordVisibility] = useReducer(
    (x) => !x,
    false,
  );

  return (
    <>
      <Heading
        as="h1"
        weight="medium"
        className="hidden sm:block"
        data-testid="login-description"
      >
        Log in to your account.
      </Heading>

      <form onSubmit={onSubmit} className="grid gap-4" ref={formRef}>
        <Grid gap="2">
          <Text weight="medium" size="2" data-testid="email-field-label">
            Work Email
          </Text>
          <TextField.Root
            required
            variant="classic"
            name="email"
            id="email-input"
            placeholder="name@work-email.com"
            type="email"
            autoComplete="true"
            disabled={disabled}
            onInput={checkValidity}
            autoFocus
            className="[&>input]:w-full"
            data-testid="email-field"
          />
          <Text
            color="red"
            size="2"
            data-testid="email-error-message"
            className={cx({ hidden: !error })}
          >
            Invalid or unrecognized email address
          </Text>
        </Grid>

        <Grid gap="2">
          <Text weight="medium" size="2" data-testid="password-field-label">
            Password
          </Text>
          <TextField.Root
            required
            variant="classic"
            onInput={checkValidity}
            name="password"
            id="password-input"
            placeholder="Enter a password"
            type={isPasswordVisible ? "text" : "password"}
            autoComplete="true"
            disabled={disabled}
            data-testid="password-field"
          >
            <TextField.Slot side="right">
              {isPasswordVisible ? (
                <EyeClosed onClick={togglePasswordVisibility} />
              ) : (
                <Eye onClick={togglePasswordVisibility} />
              )}
            </TextField.Slot>
          </TextField.Root>
          <Text
            color="red"
            size="2"
            data-testid="password-error-message"
            className={cx({ hidden: !error })}
          >
            Invalid email or password combination
          </Text>
        </Grid>

        <Link to={forgotPasswordRoute.to} data-testid="forgot-password-link">
          <Text color="blue" size="2" weight="medium">
            Forgot password
          </Text>
        </Link>

        <Button
          type="submit"
          disabled={!isValid || disabled}
          loading={loading}
          data-testid="form-submit-cta"
        >
          Log In
        </Button>
      </form>
    </>
  );
}
