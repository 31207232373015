import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

type OnboardingState = {
  showConfetti: boolean;
  showTutorial: boolean;
  tutorialStep: number;
  loadingAI: boolean;
};

const initialState: OnboardingState = {
  showConfetti: false,
  showTutorial: false,
  tutorialStep: 0,
  loadingAI: false,
};

export const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    setShowConfetti(state, action: PayloadAction<boolean>) {
      state.showConfetti = action.payload;
    },
    setShowTutorial(state, action: PayloadAction<boolean>) {
      state.showTutorial = action.payload;
    },
    setTutorialStep(state, action: PayloadAction<number>) {
      state.tutorialStep = action.payload;
    },
    setLoadingAI(state, action: PayloadAction<boolean>) {
      state.loadingAI = action.payload;
    },
  },
});

export const {
  setShowConfetti,
  setShowTutorial,
  setTutorialStep,
  setLoadingAI,
} = onboardingSlice.actions;
