import { X, XCircle } from "@phosphor-icons/react";
import { PaperPlaneRight } from "@phosphor-icons/react/dist/ssr";
import {
  Badge,
  Button,
  Checkbox,
  Flex,
  IconButton,
  Text,
  TextArea,
} from "@radix-ui/themes";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import cx from "classnames";
import React, { type ReactNode, useEffect, useRef } from "react";
import { useState } from "react";

import { AvatarWithInitials } from "./AvatarWithInitials";

import { ticketsRoute } from "../routes/tickets/route";
import { tutorialTicketRoute } from "../routes/tutorial/routes/tutorialTicketRoute";
import { completeOnboarding } from "../services/userProfile";
import { useAnalytics } from "../utils/analytics";
import { useAppDispatch, useAppSelector } from "../utils/hooks";
import { assetForUser } from "../utils/imageHandler";
import { login } from "../utils/slices/auth";
import {
  setLoadingAI,
  setShowTutorial,
  setTutorialStep,
} from "../utils/slices/onboarding";

type HighlightModalProps = {
  children: ReactNode;
  tooltipPosition?: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
  order?: number;
  show?: boolean;
};

export const HighlightModal: React.FC<HighlightModalProps> = ({
  children,
  tooltipPosition = {},
  order,
  show,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const onboardingMutation = useMutation({
    mutationKey: ["complete-onboarding-client"],
    mutationFn: completeOnboarding,
  });
  const { tutorialStep, showTutorial, loadingAI } = useAppSelector(
    (state) => state.onboarding,
  );
  const user = useAppSelector((state) => state.auth.value);
  const posthog = useAnalytics();
  const isVisible = (showTutorial || show) && tutorialStep === order;
  const [finishLoading, setFinishLoading] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const setStep = (step: number) => {
    posthog.capture("tutorial_step_changed", {
      step,
    });
    dispatch(setTutorialStep(step));
  };
  useEffect(() => {
    const rootElement = document.getElementById("root");
    if (!isVisible) {
      document.body.style.overflow = "";
      if (rootElement) {
        rootElement.style.overflow = "";
      }
    } else {
      document.body.style.overflow = "hidden";
      if (rootElement) {
        rootElement.style.overflow = "hidden";
      }
    }
  }, [tutorialStep, showTutorial]);

  useEffect(() => {
    if (showTutorial) {
      document.addEventListener("keydown", trapFocus);
    } else {
      document.removeEventListener("keydown", trapFocus);
    }

    return () => {
      document.removeEventListener("keydown", trapFocus);
    };
  }, [showTutorial]);

  const trapFocus = (event: KeyboardEvent) => {
    if (event.key === "Tab" && contentRef.current) {
      const focusableElements =
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
      const focusableContent =
        contentRef.current.querySelectorAll(focusableElements);
      const firstElement = focusableContent[0] as HTMLElement;
      const lastElement = focusableContent[
        focusableContent.length - 1
      ] as HTMLElement;

      if (!contentRef.current.contains(document.activeElement)) {
        firstElement.focus();
        event.preventDefault();
      } else if (event.shiftKey) {
        if (document.activeElement === firstElement) {
          lastElement.focus();
          event.preventDefault();
        }
      } else {
        if (document.activeElement === lastElement) {
          firstElement.focus();
          event.preventDefault();
        }
      }
    }
  };

  if (!isVisible) return <>{children}</>;
  const tooltipContent = {
    0: (
      <div className="w-[380px] rounded-lg bg-puntt-gold-9 px-4 py-5 shadow-lg">
        <IconButton
          size="1"
          variant="ghost"
          className="absolute right-2 top-2"
          onClick={() => {
            onboardingMutation.mutateAsync().then((data) => {
              localStorage.setItem("token", data.token);
              sendTokenToServiceWorker();
              dispatch(login({ token: data.token }));
              navigate({ to: ticketsRoute.to } as any);
              dispatch(setShowTutorial(false));
              localStorage.removeItem("onboarding_flow");
            });
          }}
        >
          <XCircle className="text-base-black" />
        </IconButton>

        <div className="mb-1 grid gap-y-3">
          <Text size="8">Welcome to Puntt.</Text>
          <Text size="3">
            Let&apos;s walk through a few key features to help you speed up your
            creative workflow.
          </Text>
          <div className="grid gap-y-2">
            <Badge size="1" color="gold" className="max-w-fit">
              Step 1
            </Badge>
            <Text size="7">Upload your assets</Text>
          </div>
          <div className="grid gap-y-2">
            <Badge size="1" color="gold" className="max-w-fit">
              Step 2
            </Badge>
            <Text size="7">Get AI Feedback</Text>
          </div>
          <div className="grid gap-y-2">
            <Badge size="1" color="gold" className="max-w-fit">
              Step 3
            </Badge>
            <Text size="7">Collaborate</Text>
          </div>
          <Button
            onClick={() => setStep(1)}
            size="2"
            variant="solid"
            className={cx("max-w-fit bg-base-black")}
          >
            Get Started
          </Button>
        </div>
      </div>
    ),
    1: (
      <TooltipContent
        step={1}
        title="Upload Your Files"
        description="Add and organize images, documents, and videos to projects."
        buttonText="Next: AI Feedback"
        buttonAction={() => setStep(2)}
        onClose={() => {
          dispatch(setShowTutorial(false));
        }}
      />
    ),
    2: (
      <TooltipContent
        step={2}
        title="AI Feedback"
        description="Puntt AI gives feedback on brand accuracy and design quality."
        buttonText="Open the Demo Image"
        buttonAction={() => {
          navigate({
            to: tutorialTicketRoute.to,
          });
          setStep(3);
        }}
        onClose={() => {
          dispatch(setShowTutorial(false));
        }}
      />
    ),
    3: (
      <TooltipContent
        step={3}
        title="AI Feedback"
        description="Puntt AI gives feedback on brand accuracy and design quality."
        buttonText="Start AI Review"
        buttonAction={() => {
          dispatch(setLoadingAI(true));
          setTimeout(() => {
            setStep(4);
            dispatch(setLoadingAI(false));
          }, 3000);
        }}
        onClose={() => {
          dispatch(setShowTutorial(false));
        }}
        isLoading={loadingAI}
      />
    ),
    4: (
      <TooltipContent
        step={4}
        title="Review"
        description="See recommended changes from Puntt AI based on brand guidelines and best practices."
        buttonText="Next: Collaborate"
        buttonAction={() => setStep(5)}
        onClose={() => {
          dispatch(setShowTutorial(false));
        }}
      />
    ),
    5: (
      <>
        <TooltipContent
          step={5}
          title="Collaborate with AI"
          description="Want more feedback: @Puntt AI for suggestions or more details."
          buttonText="Next: Versions & Sharing"
          buttonAction={() => setStep(6)}
          onClose={() => {
            dispatch(setShowTutorial(false));
          }}
        />

        <div className="pointer-events-none relative mt-10 w-[300px] bg-base-white">
          <div
            // eslint-disable-next-line tailwindcss/enforces-negative-arbitrary-values
            className="absolute -top-[30px] right-0"
            style={{
              width: 25,
              backgroundColor: "rgb(var(--puntt-red-9))",
              borderRadius: "20px 20px 20px 0",
              boxShadow: `0 0 0 2px rgb(var(--puntt-red-9)), 0 1px 3px 2px rgba(0, 0, 0, 0.12), 0 1px 2px 2px rgba(0, 0, 0, 0.24)`,
            }}
          >
            <AvatarWithInitials
              avatar={assetForUser(user?.avatar)}
              name={user?.name}
              size={6}
            />
          </div>
          <Flex className="h-6 items-center justify-end border-b border-puntt-neutral-gray-6 pr-[3px]">
            <IconButton
              size="1"
              variant="ghost"
              onClick={() => {}}
              className="cursor-pointer"
            >
              <X size={16} color="rgb(var(--base-black))" />
            </IconButton>
          </Flex>
          <div className="p-4">
            <TextArea
              className="px-2 py-4"
              placeholder="Reply to comment…"
              value="@Puntt Give me some ideas on how to improve this layout."
            />
            <Flex gap="2" className="mt-4 items-center justify-between">
              <Flex gap="2" className="items-center">
                <Checkbox defaultChecked={false} onCheckedChange={() => {}} />
                Required Change
              </Flex>
              <Button>
                <PaperPlaneRight /> Send
              </Button>
            </Flex>
          </div>
        </div>
      </>
    ),
    6: (
      <TooltipContent
        step={6}
        title="Collaborate with Team"
        description="Address comments, upload new version, and share with a colleague."
        buttonText="Finish & Go to Projects"
        buttonAction={() => {
          setFinishLoading(true);
          onboardingMutation.mutateAsync().then((data) => {
            localStorage.setItem("token", data.token);
            sendTokenToServiceWorker();
            dispatch(login({ token: data.token }));
            navigate({ to: ticketsRoute.to } as any);
            dispatch(setShowTutorial(false));
            localStorage.removeItem("onboarding_flow");
          });
        }}
        onClose={() => {
          dispatch(setShowTutorial(false));
        }}
        isLoading={finishLoading}
      />
    ),
    8: <div />,
  };
  const { top, right, bottom, left } = tooltipPosition;

  return (
    <>
      <div className="bg-opacity-60/60 fixed inset-0 z-[201] overflow-hidden bg-puntt-neutral-gray-10/75"></div>
      <div style={{ position: "relative", zIndex: 301 }}>
        <div>
          <div style={{ pointerEvents: "none" }} ref={ref}>
            {children}
          </div>
        </div>

        {tooltipContent && (
          <div
            style={{
              position: "absolute",
              zIndex: 52,
              top: top,
              right: right,
              bottom: bottom,
              left: left,
              pointerEvents: "auto",
            }}
            ref={contentRef}
          >
            {tooltipContent[tutorialStep as keyof typeof tooltipContent]}
          </div>
        )}
      </div>
    </>
  );
};

type TooltipContentProps = {
  step: number;
  title: string;
  description: string;
  buttonText: string;
  isLoading?: boolean;
  buttonAction: () => void;
  onClose: () => void;
};
const TooltipContent = ({
  step,
  title,
  description,
  buttonText,
  buttonAction,
  isLoading = false,
}: TooltipContentProps) => {
  const navigate = useNavigate();
  const onboardingMutation = useMutation({
    mutationKey: ["complete-onboarding-client"],
    mutationFn: completeOnboarding,
  });
  const dispatch = useAppDispatch();
  return (
    <div className="w-[300px] rounded-lg bg-puntt-gold-9 px-4 py-5 shadow-lg">
      <IconButton
        size="1"
        variant="ghost"
        className="absolute right-2 top-2"
        onClick={() => {
          onboardingMutation.mutateAsync().then((data) => {
            localStorage.setItem("token", data.token);
            sendTokenToServiceWorker();
            dispatch(login({ token: data.token }));
            navigate({ to: ticketsRoute.to } as any);
            dispatch(setShowTutorial(false));
            localStorage.removeItem("onboarding_flow");
          });
        }}
      >
        <XCircle className="text-base-black" />
      </IconButton>
      <Badge size="1" color="gold">
        Step {step} of 6
      </Badge>

      <div className="mb-1 gap-x-4">
        <Text size="6">{title}</Text>
      </div>
      <div className="grid gap-y-3">
        <Text size="3">{description}</Text>
        <Button
          onClick={buttonAction}
          size="2"
          variant="solid"
          className={cx("max-w-fit bg-base-black", {
            "bg-puntt-cool-gray-5": isLoading,
          })}
          loading={isLoading}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
};
