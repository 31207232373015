import { createRouter } from "@tanstack/react-router";

import { BASEPATH } from "./env";

import { appRoutes, rootRoute } from "../routes";
import { NotFoundComponent } from "../routes/root/notFoundComponent";
import { queryClient } from "../utils/queryClient";

const routeTree = rootRoute.addChildren(appRoutes);

export const router = createRouter({
  routeTree,
  basepath: BASEPATH,
  context: {
    queryClient,
  },
  defaultNotFoundComponent: NotFoundComponent,
});

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}
