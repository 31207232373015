import {
  getWorkedWithResponseSchema,
  setWorkConnectionResponseSchema,
} from "@mg/schemas/src/christo/myNetwork";

import { API_URL } from "../config/env";
import { smartFetch } from "../utils/http";

export async function getWorkedWith() {
  const json = await smartFetch(`${API_URL}/private/my-network/worked-with`, {
    method: "GET",
  });

  const parsedData = getWorkedWithResponseSchema.parse(json);
  return parsedData;
}

export async function setWorkedWith(userId: string) {
  const json = await smartFetch(
    `${API_URL}/private/my-network/connect/${userId}`,
    {
      method: "PUT",
    },
  );

  const parsedData = setWorkConnectionResponseSchema.parse(json);
  return parsedData;
}
