import { getFormattedResponseSchema } from "@mg/schemas/src/christo/concepts";
import { getBrandsResponseSchema } from "@mg/schemas/src/christo/myNetwork";

import { API_URL } from "../config/env";
import { smartFetch } from "../utils/http";

export async function getConceptsByType(type: string) {
  const json = await smartFetch(
    `${API_URL}/private/concepts/formatted?t=${type}`,
    {
      method: "GET",
    },
  );

  const parsedData = getFormattedResponseSchema.parse(json);
  return parsedData;
}

export async function getConceptsByParent(type: string) {
  const json = await smartFetch(
    `${API_URL}/private/concepts/children?p=${type}&t=deliverable`,
    {
      method: "GET",
    },
  );

  const parsedData = getFormattedResponseSchema.parse(json);
  return parsedData;
}

export async function getBrands() {
  const json = await smartFetch(`${API_URL}/private/my-network/brands`, {
    method: "GET",
  });

  const parsedData = getBrandsResponseSchema.parse(json);
  return parsedData;
}
