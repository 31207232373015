// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ButtonNew, Card, Typography } from "@mg/dali/src";
// eslint-disable-next-line import/named
import { createRoute, useNavigate } from "@tanstack/react-router";
import React from "react";

import { brandGuidelineRoute } from "./routes/brandGuidelineRoute";

import { authLayoutRoute } from "../auth-layout/route";

export const settingsRoute = createRoute({
  getParentRoute: () => authLayoutRoute,
  path: "settings",

  component: Settings,
});

function Settings() {
  const navigate = useNavigate({ from: settingsRoute.to });
  return (
    <article className="grid gap-6 px-10 py-6">
      <header className="flex items-center justify-between">
        <Typography as="h1" size="2xl" weight="bold">
          Settings
        </Typography>
      </header>

      <Card onClick={() => navigate({ to: brandGuidelineRoute.to })}>
        <Typography>Brand Guidelines and Assets</Typography>
      </Card>
    </article>
  );
}
