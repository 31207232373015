// 🚨 NOTE: These types are duplicated in the carrie/ and figma-plugin/ repos. Make sure to
// keep them in sync! Both files are named `figma-plugin-types.ts`.

// Message types sent between Figma plugin and Carrie
export enum MessageType {
  GET_FIGMA_SELECTION = "GET_FIGMA_SELECTION",
  SEND_FIGMA_SELECTION = "SEND_FIGMA_SELECTION",
  DEBUG_MESSAGE = "DEBUG_MESSAGE",
  UPLOAD_SUCCESS = "UPLOAD_SUCCESS",
  RESIZE = "RESIZE",
  FRAME_LOADED = "FRAME_LOADED",
}

export type FigmaCoordinates = [number, number];

export type FigmaImageData = {
  data: Uint8Array;
  mimeType: string;
  location: FigmaCoordinates;
};

export type SenderType = "figma" | "web";

export type FigmaMessage = {
  type: MessageType;
  images?: FigmaImageData[];
  message?: string;
  sender: SenderType;
};
