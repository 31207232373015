import { z } from "zod";

import {
  briefFileSchema,
  ticketSchema,
  workflowSchema,
  ticketCommentSchema,
} from "../commons";

export enum AIType {
  ASK_PUNTT = "ap",
  CREATIVE_SUMMARY = "cs",
  TICKET_COMMENTS = "tc",
  TICKET_BRIEF = "tb",
  TICKET_PROMPT = "tp",
  TICKET_VALIDATE_BRIEF = "tvb",
}

export const aiRequestParamsSchema = z.object({
  // creative summary
  creativeId: z.string().optional(),
  queries: z.string().optional(),

  // ticket overall
  ticketId: z.string().optional(),

  // common
  type: z.nativeEnum(AIType).default(AIType.CREATIVE_SUMMARY),
});

export type AIRequestParams = z.infer<typeof aiRequestParamsSchema>;

export const generateBriefBodySchema = z.object({
  ticketId: z.string(),
  prompt: z.string().optional(),
  workflow: workflowSchema.optional(),
});

export type GenerateBriefBody = z.infer<typeof generateBriefBodySchema>;

export const generateBriefResponseSchema = z.object({
  brief: z.string(),
  briefFiles: z.array(briefFileSchema),
  debugSimilar: z.any().optional(),
  debugTimes: z.any().optional(),
});

export type GenerateBriefResponse = z.infer<typeof generateBriefResponseSchema>;

export const generatePromptRequestSchema = z.object({
  title: z.string(),
});

export type GeneratePromptRequest = z.infer<typeof generatePromptRequestSchema>;

export const generatePromptResponseSchema = z.object({
  prompt: z.string(),
  workflow: z.string(),
});

export type GeneratePromptResponse = z.infer<
  typeof generatePromptResponseSchema
>;

export const validateBriefBodySchema = ticketSchema;

export type ValidateBriefBody = z.infer<typeof validateBriefBodySchema>;

export const validateBriefParamsSchema = z.object({
  ticketId: z.string(),
});

export type ValidateBriefParams = z.infer<typeof validateBriefParamsSchema>;

export const validateBriefRequest = z.object({
  body: validateBriefBodySchema,
  params: validateBriefParamsSchema,
});

export type ValidateBriefRequest = z.infer<typeof validateBriefRequest>;

export const validateBriefResponseSchema = z.object({
  valid: z.boolean(),
  alertMessage: z.string().optional(),
  alertLevel: z.enum(["error", "warning"]).optional(),
});

export type ValidateBriefResponse = z.infer<typeof validateBriefResponseSchema>;

export const generateCommentsRequestSchema = z.object({
  ticketId: z.string(),
  boardId: z.string(),
});

export type GenerateCommentsRequest = z.infer<
  typeof generateCommentsRequestSchema
>;

export const generateCommentsResponseSchema = z.object({
  totalComments: z.coerce.number(),
  allComments: z.any(),
  reviewId: z.string().optional(),
});

export type GenerateCommentsResponse = z.infer<
  typeof generateCommentsResponseSchema
>;

export const askPunttRequestSchema = z.object({
  ticketId: z.string(),
  boardId: z.string(),
  commentId: z.string().optional(),
  x: z.coerce.number(),
  y: z.coerce.number(),
  description: z.string(),
  shapeIds: z.array(z.string()).default([]),
});

export const askPunttResponseSchema = ticketCommentSchema;

export type AskPunttRequest = z.infer<typeof askPunttRequestSchema>;
export type AskPunttResponse = z.infer<typeof askPunttResponseSchema>;
