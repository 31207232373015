import { type GetProfileResponse } from "@mg/schemas/src/christo/profile";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

type ProfileState = {
  value: GetProfileResponse | null;
};

const initialState: ProfileState = {
  value: null,
};

export const userProfileSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {
    setUserProfile(state, action: PayloadAction<GetProfileResponse>) {
      state.value = action.payload;
    },
  },
});

export const { setUserProfile } = userProfileSlice.actions;
