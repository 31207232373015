import { ButtonNew, Checkbox, Icon, Textarea, Typography } from "@mg/dali/src";
import { type TicketStatus } from "@mg/schemas/src/commons";
import cx from "classnames";
import { type ChangeEventHandler, useEffect, useState } from "react";

import { AvatarWithInitials } from "../../../components/AvatarWithInitials";
import { useAnalytics } from "../../../utils/analytics";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import { assetForUser } from "../../../utils/imageHandler";
import {
  useAddReviewersMutation,
  useEditProjectMutation,
  useFetchRecommendedReviewers,
} from "../../../utils/queries/projects";
import { setTicket } from "../../../utils/slices/ticket";
import { useTicket } from "../routes/ticket";

export const RequestApprovalSidebar = () => {
  const posthog = useAnalytics("RequestApprovalSidebar");
  const [selections, setSelections] = useState<Set<string>>(new Set());
  const [note, setNote] = useState<string>("");
  const ticket = useAppSelector((state) => state.ticket.value);
  const ticketMutation = useEditProjectMutation(ticket?._id as string);
  const addReviewersMutation = useAddReviewersMutation();
  const users = useFetchRecommendedReviewers(ticket?._id as string);
  const { toggleDrawer, drawerOpen } = useTicket();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (ticket) {
      // @ts-expect-error TS2339: BE returns a different type
      setSelections(new Set(ticket.reviewers?.map((c) => c._id) ?? []));
    }
  }, [ticket]);
  const handleChange: ChangeEventHandler<HTMLInputElement> = (evt) => {
    const { currentTarget } = evt;
    const value = currentTarget.value as string | number;

    if (value == null || currentTarget.nodeName !== "INPUT") {
      return;
    }

    if (selections.has(value as string)) {
      selections.delete(value as string);
    } else {
      selections.add(value as string);
    }

    const result = Array.from(selections);

    setSelections(new Set(result));
  };
  const handleSubmit = () => {
    const startTime = performance.now();
    addReviewersMutation.mutateAsync(
      {
        ticketId: ticket?._id as string,
        payload: {
          reviewers: Array.from(selections),
          note,
        },
      },
      {
        onSuccess: () => {
          posthog.capture("reviewers_requested", {
            mutationDuration: performance.now() - startTime,
            reviewers: Array.from(selections),
          });
          toggleDrawer("approval");
          ticketMutation
            .mutateAsync({
              _id: ticket?._id as string,
              status: "needs_review" as TicketStatus,
            })
            .then((response) => {
              dispatch(setTicket(response));
              // TODO: this is a hack so that I can move on with my life
              window.location = location;
            });
        },
      },
    );
  };
  const reviewers = [
    ...(ticket?.reviewers ?? []),
    ...(users.data ?? []).filter(
      // @ts-expect-error TS2339: BE returns a different type
      (u) => !ticket?.reviewers?.find((rev) => rev._id === u._id),
    ),
  ];
  return (
    <aside
      className={cx(
        "fixed right-0 top-0 z-[200] h-screen w-80 bg-base-white shadow-lg transition-transform",
        {
          "translate-x-80": drawerOpen !== "approval",
        },
      )}
    >
      <header className="flex items-center justify-between border-b border-carbon-300 p-4 text-base-black">
        <Typography size="base" weight="medium">
          Request Approval
        </Typography>
        <button className="p-1" onClick={() => toggleDrawer("approval")}>
          <Icon.X />
        </button>
      </header>
      <div className="flex h-full flex-col pb-14">
        <div className="flex h-full flex-col justify-start overflow-y-scroll">
          {reviewers?.map((result) => (
            // @ts-expect-error TS2339: BE returns a different type
            <div key={result._id} className="flex items-center gap-1 px-4 py-2">
              <Checkbox
                // @ts-expect-error TS2339: BE returns a different type
                key={"checkbox" + result._id}
                // @ts-expect-error TS2339: BE returns a different type
                id={"checkbox" + result._id}
                size="sm"
                // @ts-expect-error TS2339: BE returns a different type
                value={String(result._id)}
                // @ts-expect-error TS2339: BE returns a different type
                checked={selections.has(result._id)}
                // @ts-expect-error TS2322: TODO need to fix the types
                onChange={handleChange}
                label=""
                className="rounded-sm border-carbon-200"
              />
              <AvatarWithInitials
                // @ts-expect-error TS2339: BE returns a different type
                avatar={assetForUser(result.avatar)}
                // @ts-expect-error TS2339: BE returns a different type
                name={result.name ?? ""}
                size={10}
                square
              />
              {/* @ts-expect-error TS2339: BE returns a different type */}
              <Typography>{result.name}</Typography>
            </div>
          ))}
        </div>
        <div className="grid gap-y-2 px-4 py-2">
          <Textarea
            label="Add a Note"
            className="py-1"
            onChange={(e) => setNote(e.target.value)}
          />
          <ButtonNew
            className="w-fit bg-egyptian-blue-600 text-sm"
            onClick={handleSubmit}
          >
            Request Selected Reviewers
          </ButtonNew>
        </div>
      </div>
    </aside>
  );
};
