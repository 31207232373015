import { Popover, Transition } from "@headlessui/react";
import { ButtonNew, Icon, Textarea, Typography } from "@mg/dali/src";
import { type AssistantWriteContext } from "@mg/schemas/src/christo/assistant";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import { useMutation } from "@tanstack/react-query";
import cx from "classnames";
import { ContentState, EditorState, convertFromHTML } from "draft-js";
// @ts-expect-error TS7016: Could not find a declaration file for module 'draft-js-export-markdown'.
import { stateToMarkdown } from "draft-js-export-markdown";
import { useEffect, useRef, useState } from "react";

import { Markdown } from "./Markdown";

import { assistWrite } from "../services/assistant";

type RichEditorAssistantProps = {
  editorState: EditorState;
  onChange: (e: EditorState) => void;
  showBrief?: boolean;
  startBrief?: boolean;
};

type AssistantState = "idle" | "askInput" | "thinking" | "confirm" | "error";

export function RichEditorAssistant({
  onChange,
  editorState,
}: RichEditorAssistantProps) {
  const scrollViewportRef = useRef<HTMLDivElement>(null);
  const popoverPanelRef = useRef<HTMLDivElement>(null);
  const controller = useRef(new AbortController());
  const [initialPrompt, setInitialPrompt] = useState("");
  const [tellAiAssist, setTellAiAssist] = useState("");
  const [lastRequest, setLastRequest] = useState<AssistantWriteContext | null>(
    null,
  );
  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState<AssistantState>("idle");

  const aiMutation = useMutation({
    mutationKey: ["ai-writer"],
    mutationFn: (vars: AssistantWriteContext) =>
      assistWrite(vars, controller.current.signal),
    onMutate() {
      setState("thinking");
    },
    onError(e) {
      if (e.name === "AbortError") {
        return setState("idle");
      }
      return setState("error");
    },
    onSuccess() {
      setState("askInput");
    },
  });

  useEffect(() => {
    if (!isOpen) {
      // reset the abort controller
      controller.current = new AbortController();

      // clear any popover inputs.
      setInitialPrompt("");
      setTellAiAssist("");
      setLastRequest(null);

      // set the editor back to idle
      setState("idle");

      // reset the mutation
      aiMutation.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (state === "askInput") {
      if (!scrollViewportRef.current || !popoverPanelRef.current) return;
      const rect = popoverPanelRef.current.getBoundingClientRect();
      const isPopoverOffScreen = rect.y < 0;

      if (isPopoverOffScreen) {
        scrollViewportRef.current.style.height = `${
          scrollViewportRef.current.clientHeight + rect.y - 16
        }px`;
      }
    }
  }, [state]);

  function handleCancel() {
    controller.current.abort("Action canceled by user");

    // AbortController is consumed once aborted and must be recreated otherwise
    // subsequent calls that make use of the underlying signal will error
    // immediately.
    controller.current = new AbortController();
  }

  function getHtmlState(text: string) {
    const blocksFromHtml = convertFromHTML(text);
    const state = ContentState.createFromBlockArray(
      blocksFromHtml.contentBlocks,
      blocksFromHtml.entityMap,
    );

    return state;
  }
  function handleAccept() {
    if (aiMutation.data == null || !aiMutation.data?.content.length) {
      return;
    }

    const state = getHtmlState(aiMutation.data.content);

    onChange(EditorState.push(editorState, state, "insert-characters"));
    setIsOpen(false);
  }

  function renderPanelState() {
    switch (state) {
      case "idle":
        return (
          <>
            <Textarea
              rows={6}
              placeholder="Tell the AI Assistant to...
e.g.
&bull; Write me a work brief about ...
&bull; Write a formal message to get sign-off from ...
&bull; Ask a creative if they will be available for ..."
              value={initialPrompt}
              onChange={({ target }) => setInitialPrompt(target.value)}
              autoFocus
            />
            <ButtonNew
              size="xs"
              theme="tertiary"
              className="max-w-max justify-self-end"
              disabled={initialPrompt.length === 0}
              onClick={() => {
                const request = {
                  action: "custom-writing",
                  content: initialPrompt,
                };
                setLastRequest(request as AssistantWriteContext);
                aiMutation.mutate(request as AssistantWriteContext);
              }}
            >
              Generate
            </ButtonNew>
          </>
        );
      case "thinking":
        return (
          <div className="flex items-center justify-between rounded-lg border-2 border-carbon-300 p-4">
            <Typography>Assistant is writing...</Typography>
            <ButtonNew variant="ghost" size="xs" onClick={() => handleCancel()}>
              Stop
            </ButtonNew>
          </div>
        );
      case "askInput":
        return (
          <>
            <ScrollArea.Root
              type="auto"
              className="max-h-80 w-full overflow-hidden"
              ref={scrollViewportRef}
            >
              <ScrollArea.Viewport className="max-h-80 w-full">
                <Markdown>
                  {stateToMarkdown(
                    getHtmlState(aiMutation.data?.content ?? ""),
                  )}
                </Markdown>
              </ScrollArea.Viewport>

              <ScrollArea.Scrollbar
                className="flex touch-none select-none bg-carbon-50 p-0.5 transition-colors duration-[160ms] ease-out hover:bg-carbon-100 data-[orientation=horizontal]:h-2.5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col"
                orientation="vertical"
              >
                <ScrollArea.Thumb className="relative flex-1 rounded-[10px] bg-carbon-500 before:absolute before:left-1/2 before:top-1/2 before:size-full before:min-h-[44px] before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-['']" />
              </ScrollArea.Scrollbar>
              <ScrollArea.Scrollbar
                className="hover:bg-blackA5 flex touch-none select-none bg-carbon-50 p-0.5 transition-colors duration-[160ms] ease-out data-[orientation=horizontal]:h-2.5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col"
                orientation="horizontal"
              >
                <ScrollArea.Thumb className="relative flex-1 rounded-[10px] bg-carbon-500 before:absolute before:left-1/2 before:top-1/2 before:size-full before:min-h-[44px] before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-['']" />
              </ScrollArea.Scrollbar>
              <ScrollArea.Corner className="bg-carbon-100" />
            </ScrollArea.Root>
            <div className="flex items-center gap-2">
              <ButtonNew size="xs" onClick={() => handleAccept()}>
                Insert
              </ButtonNew>
              <ButtonNew
                size="xs"
                variant="ghost"
                title="Regenerate response"
                onClick={() =>
                  aiMutation.mutate({
                    ...lastRequest,
                    regenerate: true,
                  } as AssistantWriteContext)
                }
              >
                <Icon.ArrowCounterClockwise weight="bold" />
              </ButtonNew>
            </div>

            <div className="grid gap-2 rounded-lg border-2 border-carbon-300 p-4">
              <input
                placeholder="Tell AI Assist..."
                className="outline-none"
                value={tellAiAssist}
                onChange={({ target }) => setTellAiAssist(target.value)}
              />
              <div className="flex items-center gap-2">
                <ButtonNew
                  size="xs"
                  theme="tertiary"
                  variant="ghost"
                  onClick={() => {
                    const request: AssistantWriteContext = {
                      //@ts-expect-error TS2322: Type 'string' is not assignable to type 'AssistantWriteAction'.
                      action: "format-job-description",
                      content: aiMutation.data?.content,
                    };

                    setLastRequest(request);
                    aiMutation.mutate(request);
                    setTellAiAssist("");
                  }}
                >
                  Write a work brief
                </ButtonNew>

                <ButtonNew
                  size="xs"
                  theme="tertiary"
                  variant="ghost"
                  onClick={() => {
                    const request: AssistantWriteContext = {
                      //@ts-expect-error TS2322: Type 'string' is not assignable to type 'AssistantWriteAction'.
                      action: "summarize",
                      content: aiMutation.data?.content,
                    };

                    setLastRequest(request);
                    aiMutation.mutate(request);
                    setTellAiAssist("");
                  }}
                >
                  Make Shorter
                </ButtonNew>

                <ButtonNew
                  size="xs"
                  theme="tertiary"
                  variant="ghost"
                  onClick={() => {
                    const request: AssistantWriteContext = {
                      //@ts-expect-error TS2322: Type 'string' is not assignable to type 'AssistantWriteAction'.
                      action: "continue-writing",
                      content: aiMutation.data?.content,
                    };

                    setLastRequest(request);
                    aiMutation.mutate(request);
                    setTellAiAssist("");
                  }}
                >
                  Make Longer
                </ButtonNew>
              </div>

              <ButtonNew
                size="xs"
                theme="tertiary"
                className="max-w-max justify-self-end"
                disabled={tellAiAssist.length === 0}
                onClick={() => {
                  const request: AssistantWriteContext = {
                    // @ts-expect-error TS2322: Type 'string' is not assignable to type 'AssistantWriteAction'.
                    action: "custom-writing",
                    content: aiMutation.data?.content,
                    contextText: tellAiAssist,
                  };

                  setLastRequest(request);
                  aiMutation.mutate(request);
                  setTellAiAssist("");
                }}
              >
                Generate
              </ButtonNew>
            </div>
          </>
        );
    }
  }

  return (
    <Popover
      // @ts-expect-error TS2322: Incorrectly typed props.
      open={isOpen}
      className="relative flex items-center justify-end gap-2"
    >
      <Popover.Button
        className="group relative ml-2 h-max overflow-hidden outline-none"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <div className="absolute inset-0 z-0 animate-spin-gradient rounded-lg bg-[linear-gradient(var(--angle),rgb(var(--vermillion-600)),rgb(var(--ochre-400)))]" />
        <span
          className={cx([
            "isolate flex items-center gap-2 rounded-md px-1",
            "text-base-white transition-colors group-hover:bg-base-white/20",
            "before:absolute before:-inset-1/2 before:translate-x-0 before:skew-x-[-45deg] before:animate-shine before:bg-[linear-gradient(to_right,rgb(255_255_255_/_0.2),rgb(255_255_255_/_0.8),rgb(255_255_255_/_0.2))]",
          ])}
        >
          <Icon.MagicWand />
          AI
        </span>
      </Popover.Button>

      <Transition
        show={isOpen}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
        className="absolute bottom-[calc(100%_+_1rem)] left-0 z-20 w-[calc(100vw_-_2rem)] rounded-lg border-2 border-carbon-300 bg-base-white p-2 shadow-lg md:w-[476px]"
      >
        <Popover.Panel ref={popoverPanelRef} static className="grid gap-2">
          <header className="flex items-center justify-between">
            <Typography className="flex items-center gap-4">
              <Icon.MagicWand />
              AI Assistant
            </Typography>
            <button
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <Icon.XCircle color="rgb(var(--base-black))" />
            </button>
          </header>
          {renderPanelState()}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
