import {
  type MyNetworkResponse,
  type GetPeopleResponse,
} from "@mg/schemas/src/christo/myNetwork";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

type NetworkState = {
  value: MyNetworkResponse | null;
  messagesableList: GetPeopleResponse | null;
  selections: string[] | undefined;
  showSelections: boolean;
};

const initialState: NetworkState = {
  value: null,
  messagesableList: null,
  selections: undefined,
  showSelections: false,
};

export const networkSlice = createSlice({
  name: "network",
  initialState,
  reducers: {
    setNetwork(state, action: PayloadAction<MyNetworkResponse>) {
      state.value = action.payload;
    },
    setMessageableList(state, action: PayloadAction<GetPeopleResponse>) {
      state.messagesableList = action.payload;
    },
    setSelections(state, action: PayloadAction<string[]>) {
      state.selections = Array.from(new Set(action.payload ?? []));
    },
    setShowNetworkSelections(state, action: PayloadAction<boolean>) {
      state.showSelections = action.payload;
    },
  },
});

export const {
  setNetwork,
  setMessageableList,
  setSelections,
  setShowNetworkSelections,
} = networkSlice.actions;
