import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

type UIState = {
  headerHeight: number;
  searchHeight: number;
  showResolvedComments: boolean;
  showDismissedComments: boolean;
  authenticationDialogOpen: boolean;
  drawerOpen: boolean;
};

const initialState: UIState = {
  headerHeight: 0,
  searchHeight: 0,
  showResolvedComments: false,
  showDismissedComments: false,
  authenticationDialogOpen: false,
  drawerOpen: false,
};

export const UISlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setHeaderHeight(state, action) {
      state.headerHeight = action.payload;
    },
    setSearchHeight(state, action) {
      state.searchHeight = action.payload;
    },
    setAuthenticationDialogOpen(state, action: PayloadAction<boolean>) {
      state.authenticationDialogOpen = action.payload;
    },
    toggleShowResolvedComments(state) {
      state.showResolvedComments = !state.showResolvedComments;
    },
    toggleShowDismissedComments(state) {
      state.showDismissedComments = !state.showDismissedComments;
    },
    setDrawerOpen(state, action: PayloadAction<boolean>) {
      state.drawerOpen = action.payload;
    },
  },
});

export const {
  setHeaderHeight,
  setSearchHeight,
  setAuthenticationDialogOpen,
  toggleShowResolvedComments,
  toggleShowDismissedComments,
  setDrawerOpen,
} = UISlice.actions;
