// eslint-disable-next-line import/named
import { createRoute } from "@tanstack/react-router";

import { ResetPasswordView } from "./view";

import { rootRoute } from "../root/route";

export const resetPasswordRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "reset-password",
  component: ResetPasswordView,
});
