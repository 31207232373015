// eslint-disable-next-line import/named, @typescript-eslint/no-unused-vars
import { ButtonNew, Icon, Input, Typography } from "@mg/dali/src";
import {
  type CreateGuestAccountResponse,
  type VerifyMfaResponse,
  type WorkosLoginResponse,
} from "@mg/schemas/src/prince/auth";
import {
  Button,
  Dialog,
  Flex,
  Grid,
  Text,
  VisuallyHidden,
} from "@radix-ui/themes";
import { useMutation } from "@tanstack/react-query";
// eslint-disable-next-line import/named
import { Link } from "@tanstack/react-router";
import cx from "classnames";
import { useEffect, useReducer, useRef, useState, type FormEvent } from "react";
import zxcvbn from "zxcvbn";

import { ReactComponent as Logo } from "../images/Logo-blue.svg";
import { forgotPasswordRoute } from "../routes/forgot-password/route";
import { MfaForm } from "../routes/login/MfaForm";
import {
  createAccount,
  verifyMfa,
  verifyUserExists,
  workOsLogin,
} from "../services/auth";
import { errorAnalyticsPayload, useAnalytics } from "../utils/analytics";
import { useAppDispatch, useAppSelector } from "../utils/hooks";
import { login } from "../utils/slices/auth";
import { setAuthenticationDialogOpen } from "../utils/slices/ui";

function useVerifyUserMutation() {
  const mutation = useMutation({
    mutationKey: ["verify-user"],
    mutationFn: verifyUserExists,
  });

  return mutation;
}

function useLoginMutation() {
  const mutation = useMutation({
    mutationKey: ["login"],
    mutationFn: workOsLogin,
  });

  return mutation;
}

function useSignupMutation() {
  const mutation = useMutation({
    mutationKey: ["guest-account"],
    mutationFn: createAccount,
  });

  return mutation;
}

function useMfaMutation() {
  const verifyMfaMutation = useMutation({
    mutationKey: ["totp"],
    mutationFn: verifyMfa,
  });

  return verifyMfaMutation;
}

enum AuthStep {
  EMAIL_VERIFICATION = 1,
  LOGIN = 2,
  SIGNUP = 3,
  MFA = 4,
}

export function AuthenticationDialog() {
  const { authenticationDialogOpen } = useAppSelector((state) => state.ui);
  const dispatch = useAppDispatch();
  const posthog = useAnalytics("AuthenticationDialog");
  const user = useAppSelector((state) => state.auth.value);

  const loginMutation = useLoginMutation();
  const signupMutation = useSignupMutation();
  const verifyUserMutation = useVerifyUserMutation();
  const mfaMutation = useMfaMutation();

  const [errorMessage, setErrorMessage] = useState("");
  const [currentStep, setCurrentStep] = useState(AuthStep.EMAIL_VERIFICATION);
  const [email, setEmail] = useState("");
  const [mfaOptions, setMfaOptions] = useState<WorkosLoginResponse>({});
  const [passwordStrength, setPasswordStrength] = useState<{
    suggestions: string[];
    score: number;
    password: string;
  }>({
    suggestions: [],
    score: 0,
    password: "",
  });
  const [isPasswordVisibilityToggled, togglePasswordVisibility] = useReducer(
    (x) => !x,
    false,
  );

  const formRef = useRef<HTMLFormElement>(null);

  const handleVerifyUser = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const startTime = performance.now();
    const formData = new FormData(event.currentTarget);
    const email = formData.get("email") as string;

    verifyUserMutation.mutate(
      {
        email,
      },
      {
        onSuccess: (data) => {
          if (data?.userExists) {
            posthog.capture("authentication_dialog", {
              requestDurationSeconds: (performance.now() - startTime) / 1000,
              step: "Login",
              referredBy: user?.referredBy,
              email,
            });
            setCurrentStep(AuthStep.LOGIN);
          } else {
            posthog.capture("authentication_dialog", {
              requestDurationSeconds: (performance.now() - startTime) / 1000,
              step: "Signup",
              referredBy: user?.referredBy,
              email,
            });
            setCurrentStep(AuthStep.SIGNUP);
          }
          setEmail(email);
          posthog.capture("authentication_dialog_user_verification", {
            requestDurationSeconds: (performance.now() - startTime) / 1000,
            email,
            referredBy: user?.referredBy,
          });
        },
        onError: (error) => {
          setErrorMessage(error.message);
          posthog.capture("authentication_dialog_user_verification_err", {
            requestDurationSeconds: (performance.now() - startTime) / 1000,
            ...errorAnalyticsPayload(error),
            referredBy: user?.referredBy,
            email,
          });
        },
      },
    );
  };

  const handleSuccess = (data: WorkosLoginResponse, startTime: number) => {
    if (data.token) {
      posthog.capture("authentication_dialog_login", {
        requestDurationSeconds: (performance.now() - startTime) / 1000,
        email,
        referredBy: user?.referredBy,
      });

      dispatch(login({ token: data.token }));
      window.location.reload();
    } else {
      setMfaOptions(data);
      setCurrentStep(AuthStep.MFA);
      posthog.capture("authentication_dialog", {
        requestDurationSeconds: (performance.now() - startTime) / 1000,
        step: "MFA",
        referredBy: user?.referredBy,
        email,
      });
    }
  };

  const handleLogin = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const startTime = performance.now();
    const formData = new FormData(event.target as HTMLFormElement);

    loginMutation.mutate(
      {
        email,
        password: formData.get("password") as string,
      },
      {
        onSuccess: (data) => {
          handleSuccess(data as WorkosLoginResponse, startTime);
        },
        async onError(error) {
          setErrorMessage(error.message);
          posthog.capture("authentication_dialog_login_error", {
            durationSeconds: (performance.now() - startTime) / 1000,
            ...errorAnalyticsPayload(error),
            email,
            referredBy: user?.referredBy,
          });
        },
      },
    );
  };

  const handleSignUp = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const startTime = performance.now();
    const formData = new FormData(event.target as HTMLFormElement);

    signupMutation.mutate(
      {
        email,
        name: formData.get("name") as string,
        password: formData.get("password") as string,
      },
      {
        onSuccess: (data) => {
          handleSuccess(data as CreateGuestAccountResponse, startTime);
        },
        async onError(error) {
          setErrorMessage(error.message);
          posthog.capture("authentication_dialog_signup_error", {
            durationSeconds: (performance.now() - startTime) / 1000,
            ...errorAnalyticsPayload(error),
            email,
            referredBy: user?.referredBy,
          });
        },
      },
    );
  };

  const renderForm = () => {
    switch (currentStep) {
      case AuthStep.EMAIL_VERIFICATION:
        return (
          <form onSubmit={handleVerifyUser} className="mt-4 grid gap-4">
            <Grid gap="2">
              <Input
                type="email"
                name="email"
                placeholder="Your email address"
                label="Email"
                required
                size="sm"
              />
              <Text
                color="red"
                size="2"
                data-testid="email-error-message"
                className={cx({ hidden: !verifyUserMutation.isError })}
              >
                Request failed with error: {errorMessage}. Please try again.
              </Text>
            </Grid>
            <Flex>
              <Button
                type="submit"
                loading={verifyUserMutation.isPending}
                disabled={verifyUserMutation.isPending}
                className="mt-4 w-full"
              >
                Continue
              </Button>
            </Flex>
          </form>
        );
      case AuthStep.LOGIN:
        return (
          <form onSubmit={handleLogin} className="mt-4 grid gap-4">
            <Grid gap="2">
              <Input
                type="password"
                name="password"
                required
                minLength={8}
                placeholder="••••••••"
                label="Password"
                size="sm"
              />
              <Text
                color="red"
                size="2"
                data-testid="password-error-message"
                className={cx({ hidden: !loginMutation.isError })}
              >
                {errorMessage}
              </Text>
            </Grid>
            <Link to={forgotPasswordRoute.to}>
              <Text color="blue" weight="medium">
                Forgot Password
              </Text>
            </Link>
            <Button
              className="w-full"
              disabled={loginMutation.isPending}
              loading={loginMutation.isPending}
            >
              Log In
            </Button>
          </form>
        );
      case AuthStep.SIGNUP:
        return (
          <form
            onSubmit={handleSignUp}
            className="mt-4 grid gap-4"
            ref={formRef}
          >
            <Input
              type="text"
              name="name"
              placeholder="First Last"
              required
              label="Name"
              size="sm"
            />

            <Input
              label="Password"
              required
              endAdornment={
                isPasswordVisibilityToggled ? (
                  <Icon.EyeClosed onClick={togglePasswordVisibility} />
                ) : (
                  <Icon.Eye onClick={togglePasswordVisibility} />
                )
              }
              name="password"
              id="password"
              placeholder="••••••••"
              type={isPasswordVisibilityToggled ? "text" : "password"}
              size="sm"
              minLength={8}
              disabled={signupMutation.isPending}
              invalid={signupMutation.isError}
              onChange={(e) => {
                const evaluation = zxcvbn(e.target.value);
                setPasswordStrength({
                  suggestions: evaluation.feedback.suggestions,
                  score: evaluation.score,
                  password: e.target.value,
                });
              }}
            />

            <Text
              color={passwordStrength?.score < 3 ? "yellow" : "blue"}
              size="2"
              data-testid="password-strength-warning"
              className={cx({ hidden: !passwordStrength?.password })}
            >
              Password Strength:{" "}
              {passwordStrength.score < 3 ? "Weak" : "Strong"}
            </Text>

            <div className="flex flex-col">
              {passwordStrength?.password &&
                passwordStrength?.suggestions?.map((suggestion, id) => (
                  <Text size="1" color="yellow" key={id}>
                    {suggestion}
                  </Text>
                ))}
            </div>

            <Text
              color="red"
              size="2"
              data-testid="password-error-message"
              className={cx({ hidden: !signupMutation.isError })}
            >
              {errorMessage}
            </Text>

            <Button
              type="submit"
              disabled={signupMutation.isPending || passwordStrength.score < 3}
              loading={signupMutation.isPending}
            >
              Create Account
            </Button>
          </form>
        );
      case AuthStep.MFA:
        return (
          <MfaForm
            mfaOptions={mfaOptions}
            loading={mfaMutation.isPending}
            onVerify={(otp) => {
              const startTime = performance.now();
              mfaMutation.mutate(
                {
                  authenticationChallengeId: mfaOptions?.authenticationChallenge
                    ?.id as string,
                  pendingAuthenticationToken:
                    mfaOptions?.pendingAuthenticationToken as string,
                  code: otp,
                },
                {
                  onSuccess: (data) => {
                    dispatch(
                      login({
                        token: (data as VerifyMfaResponse).token as string,
                      }),
                    );
                    posthog.capture("authentication_dialog_mfa_verification", {
                      requestDurationSeconds:
                        (performance.now() - startTime) / 1000,
                      email,
                      referredBy: user?.referredBy,
                    });
                    window.location.reload();
                  },
                  onError: (error) => {
                    setErrorMessage(error.message);
                    posthog.capture(
                      "authentication_dialog_mfa_verification_error",
                      {
                        requestDurationSeconds:
                          (performance.now() - startTime) / 1000,
                        email,
                        ...errorAnalyticsPayload(error),
                        referredBy: user?.referredBy,
                      },
                    );
                  },
                },
              );
            }}
            error={mfaMutation.isError}
            errorMessage={errorMessage}
            guestLogin
          />
        );
      default:
        return null;
    }
  };

  const handleModalClose = () => {
    setEmail("");
    setCurrentStep(AuthStep.EMAIL_VERIFICATION);
    setErrorMessage("");
    setPasswordStrength({
      password: "",
      score: 0,
      suggestions: [],
    });
    setMfaOptions({});
    loginMutation.reset();
    signupMutation.reset();
    mfaMutation.reset();
    verifyUserMutation.reset();
    if (isPasswordVisibilityToggled) togglePasswordVisibility();
  };

  useEffect(() => {
    if (!authenticationDialogOpen) {
      handleModalClose();
    }
  }, [authenticationDialogOpen]);

  return (
    <Dialog.Root
      open={authenticationDialogOpen}
      onOpenChange={(open) => dispatch(setAuthenticationDialogOpen(open))}
    >
      <Dialog.Content className="w-[400px]" onFocus={(e) => e.preventDefault()}>
        <VisuallyHidden>
          <Dialog.Title>Log in or sign up</Dialog.Title>
        </VisuallyHidden>

        <VisuallyHidden>
          <Dialog.Description>
            To use this application, please log in or create a new account.
          </Dialog.Description>
        </VisuallyHidden>

        {currentStep !== AuthStep.EMAIL_VERIFICATION && (
          <Flex className="mb-4">
            <ButtonNew
              variant="ghost"
              onClick={() => {
                if (currentStep === AuthStep.MFA) {
                  setMfaOptions({});
                  setCurrentStep(AuthStep.LOGIN);
                } else if (
                  currentStep == AuthStep.LOGIN ||
                  currentStep == AuthStep.SIGNUP
                ) {
                  setCurrentStep(AuthStep.EMAIL_VERIFICATION);
                }
              }}
            >
              <Icon.ArrowLeft />
              Back
            </ButtonNew>
          </Flex>
        )}
        <Flex align="center" direction="column" gap="4">
          <Logo />
          <Typography as="h3" weight="bold" size="2xl">
            {currentStep === AuthStep.EMAIL_VERIFICATION ||
            currentStep == AuthStep.LOGIN
              ? "Sign in to Puntt"
              : currentStep == AuthStep.SIGNUP
                ? "Sign up for Puntt"
                : "Set up an authenticator app"}
          </Typography>
          {currentStep !== AuthStep.MFA && (
            <Typography as="p" className="text-center">
              {currentStep === AuthStep.EMAIL_VERIFICATION
                ? "Log in or create an account to get access to all available features."
                : currentStep === AuthStep.LOGIN
                  ? "Log in to get access to all available features."
                  : "Create an account to get access to all available features."}
            </Typography>
          )}
        </Flex>

        {renderForm()}
      </Dialog.Content>
    </Dialog.Root>
  );
}

export function useAuthenticationOverlay() {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.value);
  const posthog = useAnalytics("useAuthenticationOverlay");

  useEffect(() => {
    const el = document.getElementById("root");

    if (el == null) return;

    const handleClick = (event: MouseEvent) => {
      if (!user) return;

      const target = event.target as HTMLElement | null;

      if (target == null) {
        return;
      }

      const triggerElem = target.closest("[data-auth-trigger]");

      if (triggerElem && user.referredBy != null) {
        event.preventDefault();
        event.stopImmediatePropagation();
        dispatch(setAuthenticationDialogOpen(true));
        posthog.capture("authentication_dialog_open", {
          referredBy: user?.referredBy,
          trigger: triggerElem.getAttribute("data-auth-trigger"),
        });
      }
    };

    el.addEventListener("click", handleClick, { capture: true });

    return () => {
      el.removeEventListener("click", handleClick, { capture: true });
    };
  }, [dispatch, posthog, user]);

  return null;
}
