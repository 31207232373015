export function getEmbedURL(url: string) {
  const match = url.match(youtubeOrVimeoRegex);

  if (!match) {
    console.log("Invalid URL: The URL should be a YouTube or Vimeo URL.");
    return "";
  }

  // Check for the platform and video ID
  const platform = match[1] ? match[1] : "youtube";
  const videoId = match[2] ? match[2] : match[3] ? match[3] : "";

  if (!videoId) {
    console.log("Invalid URL: No video ID detected.");
    return "";
  }

  if (platform === "youtube") {
    return `https://www.youtube.com/embed/${videoId}`;
  } else if (platform === "vimeo") {
    return `https://player.vimeo.com/video/${videoId}`;
  } else {
    console.log("Invalid URL: The URL should be a YouTube or Vimeo URL.");
    return "";
  }
}

export function getDomain(url: string) {
  const regex = /^(?:https?:\/\/)?(?:www\.)?([^.]+)\.[a-z]{2,}.*$/i;
  const matches = url.match(regex);

  if (matches && matches.length >= 2) {
    return matches[1].toLowerCase();
  }

  return null;
}

export const ensureFullUrl = (url: string) => {
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    url = "https://" + url;
  }
  return url;
};

export const youtubeOrVimeoRegex = new RegExp(
  /^(?:https?:\/\/)?(?:www\.|player\.|youtu\.be\/)?(vimeo|youtube)\.com\/(?:embed\/|video\/|(?:watch\?v=)?|)(.+)(?:$|\?)|^https?:\/\/youtu\.be\/([a-zA-Z0-9_-]+)(?:$|\?)/,
);

export const filterDuplicateOptions = (
  items?: { label: string; value: string }[],
): { label: string; value: string }[] => {
  if (!items) return [];
  const uniqueItems = new Map<string, { label: string; value: string }>();

  items.forEach((item) => {
    const key = `${item.label}-${item.value}`;
    if (!uniqueItems.has(key)) {
      uniqueItems.set(key, item);
    }
  });

  return Array.from(uniqueItems.values());
};

/**
 * Returns whether the keyboard event is in an input field.
 *
 * Useful for when you want to respond to keyboard events but you don't want to
 * accidentally get in the way of form field input.
 *
 * @param event
 *   The event object for the keyboard event.
 */
export function isKeyboardEventInInputField(event: KeyboardEvent) {
  if (!(event.target instanceof HTMLElement)) return false;
  const target = event.target;

  // <input> types that accept keyboard input.
  const editableInputTypes = [
    "text",
    "search",
    "tel",
    "url",
    "email",
    "password",
    "number",
    "range",
    "date",
    "month",
    "week",
    "time",
    "datetime",
    "datetime-local",
    "color",
  ];

  return (
    target.nodeName.toLowerCase() === "textarea" ||
    target.nodeName.toLowerCase() === "select" ||
    target.getAttribute("contenteditable") ||
    (target instanceof HTMLInputElement &&
      editableInputTypes.includes(target.type.toLowerCase()) &&
      !target.getAttribute("data-allow-event-interception"))
  );
}
