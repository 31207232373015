export enum AssistantWriteActions {
  CUSTOM_WRITING = "custom-writing",
  CONTINUE_WRITING = "continue-writing",
  SUMMARIZE = "summarize",
  IMPROVE_WRITE = "improve-write",
  FIX_SPELLING = "fix-spelling",
  CHANGE_TONE_FORMAL = "change-tone-formal",
  CHANGE_TONE_FRIENDLY = "change-tone-friendly",
  FORMAT_JOB_DESCRIPTION = "format-job-description",
}
