import { ButtonNew, Icon, Typography } from "@mg/dali/src";
import { ProfileCompletionSteps } from "@mg/schemas/src/christo/myNetwork";
// eslint-disable-next-line import/named
import { Link } from "@tanstack/react-router";
import { useState } from "react";

import { aboutMeEditRoute } from "../routes/userProfile/routes/AboutMe/editRoute";
import { myExpertiseEditRoute } from "../routes/userProfile/routes/MyExpertise/editRoute";
import { myWorkEditRoute } from "../routes/userProfile/routes/MyWork/editRoute";

interface CompleteYourProfileBannerProps {
  step?: ProfileCompletionSteps;
  verificationDialogRef?: React.RefObject<HTMLDialogElement>;
}

export function CompleteYourProfileBanner(
  props: CompleteYourProfileBannerProps,
) {
  const [open, setOpen] = useState(true);

  const DATA_PER_STEP = {
    [ProfileCompletionSteps.BASICS]: {
      title: "Complete your profile to get noticed",
      description:
        "Completed profiles get 36x more views on average, and a snazzy blue check mark to boot. Let everyone knows what makes you special!",
      action: (
        <Link to={aboutMeEditRoute.to}>
          <ButtonNew className="w-max" variant="filled" theme="secondary">
            Complete the Basics
          </ButtonNew>
        </Link>
      ),
    },
    [ProfileCompletionSteps.EXPERTISE]: {
      title: "Tell everyone about what you are the best at",
      description:
        "You have amazing experience, so tell everyone all the juicy details! The more you add, the more you show up to people in the network looking for creatives!",
      action: (
        <Link to={myExpertiseEditRoute.to}>
          <ButtonNew className="w-max" variant="filled" theme="secondary">
            Add Your Expertise
          </ButtonNew>
        </Link>
      ),
    },
    [ProfileCompletionSteps.PROJECTS]: {
      title: "Add more projects to really stand out",
      description:
        "Add more projects, and your projects will becomes visible in the network search page, really bringing attention to what great work you bring to the table! Profiles projects show up first, so make sure you add them!",
      action: (
        <Link to={myWorkEditRoute.to}>
          <ButtonNew className="w-max" variant="filled" theme="secondary">
            Add More Projects
          </ButtonNew>
        </Link>
      ),
    },
    [ProfileCompletionSteps.REQUEST_VERIFICATION]: {
      title: "Connect with everyone you have directly worked with",
      description:
        "Your personal network is your rock! Request to be a verified connection with everyone you have worked with and you will unlock something special to really make you stand out! Remember, there is strength in numbers!",
      action: (
        <ButtonNew
          className="w-max"
          variant="filled"
          theme="secondary"
          onClick={() => props.verificationDialogRef?.current?.showModal()}
        >
          Request Verification
        </ButtonNew>
      ),
    },
  };

  if (props.step == null) return null;

  const { title, description, action } = DATA_PER_STEP[props.step];

  if (open) {
    return (
      <article className="grid grid-cols-[auto_1fr_auto] gap-2 rounded-lg bg-base-black p-4 shadow-xl">
        <Icon.IconContext.Provider
          value={{ color: "rgb(var(--base-white))", size: 24 }}
        >
          <Icon.Lightning />

          <Typography weight="bold" className="text-base-white">
            {title}
          </Typography>

          <button onClick={() => setOpen(false)}>
            <Icon.XCircle />
          </button>

          <div />

          <div className="grid gap-y-2.5">
            <Typography className="text-base-white">{description}</Typography>
            {action}
          </div>
        </Icon.IconContext.Provider>
      </article>
    );
  }
}
