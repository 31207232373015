import { Combobox, Transition } from "@headlessui/react";
import { ButtonNew, Icon, type Option, Typography } from "@mg/dali/src";
import * as Tooltip from "@radix-ui/react-tooltip";
// eslint-disable-next-line import/named
import { createRoute, useNavigate, useSearch } from "@tanstack/react-router";
import cx from "classnames";
import { EditorState } from "draft-js";
// @ts-expect-error TS7016: Could not find a declaration file for module 'draft-js-export-markdown'.
import { stateToMarkdown } from "draft-js-export-markdown";
import { Fragment, useEffect, useMemo, useState } from "react";
import { z } from "zod";

import { AvatarWithInitials } from "../../components/AvatarWithInitials";
import { RichEditor } from "../../components/RichEditor";
import { useUI } from "../../contexts/ui";
import { requiresAuth } from "../../utils/auth";
import { useMessages, useSendMessage } from "../../utils/queries/messages";
import { useMessageablePeople } from "../../utils/queries/network";
import { messagesRoute } from "../messages/route";
import { threadRoute } from "../thread/route";

export const newMessageRoute = createRoute({
  getParentRoute: () => messagesRoute,
  path: "new",
  validateSearch(search: Record<string, unknown>) {
    const parser = z.object({
      selections: z.array(z.string()).optional(),
    });

    return parser.parse(search);
  },
  beforeLoad() {
    requiresAuth();
  },
  component: function Component() {
    const { notify } = useUI();
    const navigate = useNavigate();
    const { selections } = useSearch({ from: "/authenticated/messages/new" });
    const [message, setMessage] = useState(() => EditorState.createEmpty());
    const [showBcc, setShowBcc] = useState(false);

    const sendMessageMutation = useSendMessage();
    const messageablePeople = useMessageablePeople();
    const [recipients, setRecipients] = useState<
      (Option & { avatar?: string })[]
    >([]);
    const [bccRecipients, setBccRecipients] = useState<
      (Option & { avatar?: string })[]
    >([]);
    const [bccQuery, setBccQuery] = useState("");

    const [query, setQuery] = useState("");
    const [mobileDrawerVisible, setMobileDrawerVisible] = useState(false);
    const options = useMemo(() => {
      if (messageablePeople.data == null) {
        return [];
      }

      return messageablePeople.data.map((p) => ({
        value: p._id,
        label: p.name,
        avatar: p.avatar,
      }));
    }, [messageablePeople.data]);

    const messages = useMessages();

    const sendMessage = () => {
      const contentState = message.getCurrentContent();
      const payload = {
        content: stateToMarkdown(contentState),
        recipientIds: recipients.map((r) => r.value) as string[],
        bcc: bccRecipients.map((r) => r.value) as string[],
      };

      sendMessageMutation.mutate(
        { payload },
        {
          async onSuccess(data) {
            if (payload.recipientIds.length === 1) {
              navigate({
                to: threadRoute.to,
                // @ts-expect-error TS2345: data is of type unknown.
                params: { threadId: data._id },
              });
            }

            if (payload.recipientIds.length > 1) {
              if (payload.bcc.length > 0) {
                notify({
                  title: "Messages sent successfully",
                  message: "Messages are sent individually",
                });
              } else {
                notify({
                  title: "Message sent successfully",
                });
              }

              navigate({
                to: threadRoute.to,
                // @ts-expect-error TS2345: data is of type unknown.
                params: { threadId: data._id },
              });
            }
            messages.refetch();
          },
        },
      );
    };

    useEffect(() => {
      if (messageablePeople.data != null) {
        setRecipients(
          selections
            ?.map((s) => {
              const found = messageablePeople.data.find((m) => m._id === s);

              if (found != null) {
                return {
                  label: found.name,
                  value: found._id,
                  avatar: found.avatar,
                };
              }
            })
            .filter(Boolean) ?? [],
        );
      }
    }, [messageablePeople.data, selections]);

    const filteredOptions =
      query.length > 0
        ? options.filter(
            (o) =>
              o.label.toLocaleLowerCase().includes(query.toLocaleLowerCase()) &&
              recipients.find((r) => r.value === o.value) == null,
          )
        : options;
    const bccFilteredOptions =
      bccQuery.length > 0
        ? options.filter((o) =>
            o.label.toLocaleLowerCase().includes(bccQuery.toLocaleLowerCase()),
          )
        : options;
    return (
      <div
        className={cx(
          "absolute inset-0 flex w-full flex-1 flex-col justify-between bg-base-white pb-24 shadow-[0_-4px_6px_-1px_rgba(0,0,0,0.2)] transition-transform md:static md:pb-0",
          {
            "translate-x-3/4 md:translate-x-0": mobileDrawerVisible,
          },
        )}
      >
        <div className="grid gap-4">
          <ButtonNew
            variant="ghost"
            size="xs"
            onClick={() => setMobileDrawerVisible((prev) => !prev)}
            className="max-w-max md:hidden"
          >
            <Icon.ArrowLeft /> Back
          </ButtonNew>
          <div>
            <Combobox
              as="div"
              value={recipients}
              onChange={(values) => {
                setRecipients(values);
                setQuery("");
              }}
              multiple
              nullable
              className="relative flex items-start justify-between gap-2.5 border-b border-carbon-50 px-4 py-2 transition-all"
            >
              <Typography size="2xl" className="ml-2.5">
                To:
              </Typography>
              <div className="flex flex-1 flex-wrap gap-2 pr-2">
                {recipients.map((r, i) => (
                  <div
                    key={r.value}
                    className="flex items-center gap-1 rounded-full bg-carbon-50 pr-2"
                  >
                    <AvatarWithInitials
                      name={r.label}
                      size={8}
                      avatar={r.avatar}
                    />

                    <Typography size="sm">{r.label}</Typography>

                    <button
                      onClick={() => {
                        const res = [...recipients];
                        res.splice(i, 1);

                        setRecipients(res);
                      }}
                    >
                      <Icon.XCircle />
                    </button>
                  </div>
                ))}
                <Combobox.Input
                  value={query}
                  onChange={({ target }) => setQuery(target.value)}
                  placeholder={
                    recipients.length > 0
                      ? undefined
                      : "Enter one or more names"
                  }
                  className="font-text-regular flex-1 bg-transparent font-national2 text-2xl outline-transparent placeholder:text-carbon-600"
                />
              </div>

              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery("")}
              >
                <Combobox.Options
                  className={cx([
                    "border-2 border-carbon-300 bg-base-white",
                    "absolute left-0 top-full",
                    "max-h-60 w-full rounded-lg py-0.5",
                    "z-10 overflow-auto",
                  ])}
                >
                  {filteredOptions.map((o) => (
                    <Combobox.Option
                      key={o.value}
                      value={o}
                      className={({ active, selected }) =>
                        cx("flex cursor-pointer items-center gap-2 px-4 py-1", {
                          "bg-base-black text-base-white": active,
                          hidden: selected,
                        })
                      }
                    >
                      <AvatarWithInitials
                        name={o.label}
                        size={8}
                        avatar={o.avatar}
                      />
                      <Typography size="base">{o.label}</Typography>
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              </Transition>
            </Combobox>
            {!showBcc ? (
              <button onClick={() => setShowBcc(true)} className="mx-4 my-2">
                <Typography size="2xl" className="underline">
                  Add Bcc
                </Typography>
              </button>
            ) : (
              <>
                <Combobox
                  as="div"
                  value={bccRecipients}
                  onChange={(values) => {
                    setBccRecipients(values);
                    setBccQuery("");
                  }}
                  multiple
                  nullable
                  className="relative flex items-start justify-between gap-2.5 border-b border-carbon-50 px-4 py-2 transition-all"
                >
                  <Combobox.Label>
                    <Typography size="2xl">Bcc:</Typography>
                  </Combobox.Label>
                  <div className="flex flex-1 flex-wrap gap-2 pr-2">
                    {bccRecipients.map((r, i) => (
                      <div
                        key={r.value + "bcc"}
                        className="flex items-center gap-1 rounded-full bg-carbon-50 pr-2"
                      >
                        <AvatarWithInitials
                          name={r.label}
                          size={8}
                          avatar={r.avatar}
                        />

                        <Typography size="sm">{r.label}</Typography>

                        <button
                          onClick={() => {
                            const res = [...bccRecipients];
                            res.splice(i, 1);

                            setBccRecipients(res);
                          }}
                        >
                          <Icon.XCircle />
                        </button>
                      </div>
                    ))}
                    <Combobox.Input
                      value={bccQuery}
                      onChange={({ target }) => setBccQuery(target.value)}
                      placeholder={
                        bccRecipients.length > 0
                          ? undefined
                          : "Enter one or more names"
                      }
                      className="font-text-regular flex-1 bg-transparent font-national2 text-2xl outline-transparent placeholder:text-carbon-600"
                    />
                  </div>
                  <button
                    onClick={() => {
                      setBccQuery("");
                      setBccRecipients([]);
                      setShowBcc(false);
                    }}
                  >
                    <Icon.X />
                  </button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => setBccQuery("")}
                  >
                    <Combobox.Options
                      className={cx([
                        "border-2 border-carbon-300 bg-base-white",
                        "absolute left-0 top-full",
                        "max-h-60 w-full rounded-lg py-0.5",
                        "z-10 overflow-auto",
                      ])}
                    >
                      {bccFilteredOptions.map((o) => (
                        <Combobox.Option
                          key={o.value}
                          value={o}
                          className={({ active, selected }) =>
                            cx(
                              "flex cursor-pointer items-center gap-2 px-4 py-1",
                              {
                                "bg-base-black text-base-white": active,
                                hidden: selected,
                              },
                            )
                          }
                        >
                          <AvatarWithInitials
                            name={o.label}
                            size={8}
                            avatar={o.avatar}
                          />
                          <Typography size="base">{o.label}</Typography>
                        </Combobox.Option>
                      ))}
                    </Combobox.Options>
                  </Transition>
                </Combobox>

                <div className="px-4 py-2">
                  <Typography size="sm" className="italic">
                    BCC&apos;d recipients will receive a separate copy of this
                    thread
                  </Typography>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="flex w-full items-end px-4 pb-4">
          <RichEditor
            editorState={message}
            onChange={setMessage}
            placeholder="Send a message"
            className="!md:h-96 mr-4 !h-52"
            actions={[
              <Tooltip.Provider key="tt-provider">
                <Tooltip.Root>
                  <Tooltip.Trigger asChild>
                    <div>
                      <ButtonNew
                        size="sm"
                        variant="outlined"
                        key="send-message"
                        onClick={sendMessage}
                        disabled={
                          !message.getCurrentContent().hasText() ||
                          sendMessageMutation.isPending ||
                          !recipients.length
                        }
                        isLoading={sendMessageMutation.isPending}
                      >
                        <Icon.PaperPlaneRight />
                      </ButtonNew>
                    </div>
                  </Tooltip.Trigger>
                  <Tooltip.Portal>
                    <Tooltip.Content
                      className="TooltipContent"
                      collisionPadding={10}
                    >
                      <div className="bg-base-white">
                        {recipients.length === 0
                          ? "Please add a recipient"
                          : !message.getCurrentContent().hasText()
                            ? "Enter Text to Send"
                            : "Send Message"}
                      </div>

                      <Tooltip.Arrow className="TooltipArrow" />
                    </Tooltip.Content>
                  </Tooltip.Portal>
                </Tooltip.Root>
              </Tooltip.Provider>,
            ]}
          />
        </div>
      </div>
    );
  },
});
