import { DotsThreeVertical } from "@phosphor-icons/react";
import { DropdownMenu, IconButton } from "@radix-ui/themes";
import cx from "classnames";

type TicketActionsDropdownProps = {
  className?: string;
  onDeleteTicket(): void;
  onRename?: () => void;
  onShare(): void;
  onDownload(): void;
};

export function TicketActionsDropdown(props: TicketActionsDropdownProps) {
  const { className, onDeleteTicket, onRename, onShare, onDownload } = props;

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger className={className}>
        <IconButton size="1" variant="ghost" color="gray">
          <DotsThreeVertical weight="bold" />
        </IconButton>
      </DropdownMenu.Trigger>

      <DropdownMenu.Content>
        <DropdownMenu.Item
          onClick={(e) => {
            e.stopPropagation();
            onShare();
          }}
          className="cursor-pointer"
        >
          Share
        </DropdownMenu.Item>
        <DropdownMenu.Item
          onClick={(e) => {
            e.stopPropagation();
            onRename && onRename();
          }}
          className={cx("cursor-pointer", { hidden: !onRename })}
        >
          Rename
        </DropdownMenu.Item>
        <DropdownMenu.Item
          onClick={(e) => {
            e.stopPropagation();
            onDownload();
          }}
          className="cursor-pointer"
        >
          Download
        </DropdownMenu.Item>
        <DropdownMenu.Item
          onClick={(e) => {
            e.stopPropagation();
            onDeleteTicket();
          }}
          className="cursor-pointer"
        >
          Delete
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
}
