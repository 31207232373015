import { datadogRum } from "@datadog/browser-rum";
import { type ShowcaseToken } from "@mg/schemas/src/prince/auth";
import { jwtDecode } from "jwt-decode";
import { useEffect } from "react";

import { DD_CLIENT_TOKEN, DD_HOST, DD_APP_ID, DD_VERSION } from "./env";

export function useDatadog() {
  useEffect(() => {
    if (DD_CLIENT_TOKEN && DD_APP_ID) {
      datadogRum.init({
        applicationId: DD_APP_ID,
        clientToken: DD_CLIENT_TOKEN,
        site: "datadoghq.com",
        service: "CARRIE",
        env: DD_HOST,
        version: DD_VERSION,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "mask-user-input",
        enableExperimentalFeatures: ["clickmap"],
        allowedTracingUrls: [
          (url) =>
            !!url.match(
              /^(https?:\/\/)?([a-zA-Z0-9-]+\.)*puntt\.ai(\/[^\s?]*)(\?[^\s]*)?$/,
            ),
        ],
      });

      datadogRum.startSessionReplayRecording();

      const localToken = localStorage.getItem("token");

      if (localToken) {
        const decodedToken = jwtDecode<ShowcaseToken>(localToken);
        datadogRum.setUser({
          id: decodedToken.userID,
          ...decodedToken,
        });
      }
    }
  }, []);

  return null;
}
