import { ButtonNew, Typography } from "@mg/dali/src";
import * as Dialog from "@radix-ui/react-dialog";

import { useUI } from "../../../contexts/ui";
import { useAppSelector } from "../../../utils/hooks";
import {
  useEditProjectMutation,
  useSendToDesignMutation,
} from "../../../utils/queries/projects";
import { useTicket } from "../routes/ticket";

type DesignDialogProps = {
  open: boolean;
  onOpenChange(open: boolean): void;
};

export const SendToDesignDialog = (props: DesignDialogProps) => {
  const sendToDesignMutation = useSendToDesignMutation();
  const ticket = useAppSelector((state) => state.ticket.value);
  const ticketMutation = useEditProjectMutation(ticket?._id as string);
  const { refreshTicket } = useTicket();
  const { notify } = useUI();

  async function handleSubmit() {
    sendToDesignMutation.mutateAsync(
      {
        ticketId: ticket?._id as string,
        creatives: [],
      },
      {
        onSuccess: () => {
          props.onOpenChange(false);
          refreshTicket();

          notify({
            autoDismiss: false,
            title: "Notification sent!",
            message:
              "An email has been sent to the ticket admin to assign creatives. Once assigned, the ticket status will be updated.",
          });
        },
      },
    );
  }

  return (
    <Dialog.Root {...props}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-10 bg-base-black/50 data-[state=open]:animate-fade-in" />
        <Dialog.Content className="fixed left-1/2 top-1/2 z-[12] grid w-[90vw] max-w-2xl -translate-x-1/2 -translate-y-1/2 gap-4 rounded-lg bg-base-white p-6 text-base-black shadow-2xl focus:outline-none data-[state=open]:animate-dialog-content-show">
          <Dialog.Title>
            <Typography size="2xl" weight="medium">
              Assign Creatives
            </Typography>
          </Dialog.Title>
          <Dialog.Description>
            <Typography weight="normal">
              The creatives assigned to this project will be sent the Creative
              Brief.
            </Typography>
          </Dialog.Description>
          <div className="flex justify-end gap-3">
            <Dialog.Close asChild>
              <ButtonNew className="bg-carbon-50 text-base-black">
                Cancel
              </ButtonNew>
            </Dialog.Close>
            <ButtonNew
              type="submit"
              className="bg-egyptian-blue-600"
              onClick={handleSubmit}
              isLoading={
                sendToDesignMutation.isPending ?? ticketMutation.isPending
              }
            >
              Confirm
            </ButtonNew>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

SendToDesignDialog.displayName = "SendToDesignDialog";
