import {
  getChatsResponseSchema,
  getMessagesResponseSchema,
  type SendMessageBody,
} from "@mg/schemas/src/christo/messages";

import { API_URL } from "../config/env";
import { smartFetch } from "../utils/http";

export async function getMessages() {
  const json = await smartFetch(`${API_URL}/private/messages`, {
    method: "GET",
  });

  const parsedData = getChatsResponseSchema.parse(json);
  return parsedData;
}

export async function getThreads(id: string) {
  const json = await smartFetch(`${API_URL}/private/messages/${id}`, {
    method: "GET",
  });

  const parsedData = getMessagesResponseSchema.parse(json);
  return parsedData;
}

export async function sendMessage({ payload }: { payload: SendMessageBody }) {
  return await smartFetch(`${API_URL}/private/messages`, {
    method: "PUT",
    body: JSON.stringify(payload),
  });
}
