import { ButtonNew, Textarea, Typography } from "@mg/dali/src";
import { type Asset } from "@mg/schemas/src/commons/asset";
import { FilePlus, Spinner, Trash } from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
// eslint-disable-next-line import/named
import { createRoute, redirect } from "@tanstack/react-router";
import { type ChangeEvent, useEffect, useRef, useState } from "react";

import { posthogClient } from "../../../config/posthog";
import { useUI } from "../../../contexts/ui";
import { getGuidelines } from "../../../services/brand-guidelines";
import { uploadImageAsset, uploadToS3 } from "../../../services/upload";
import { useAnalytics } from "../../../utils/analytics";
import { requiresAuth } from "../../../utils/auth";
import { downloadAsset } from "../../../utils/http";
import { useUpdateGuidelinesMutation } from "../../../utils/queries/projects";
import { authLayoutRoute } from "../../auth-layout/route";
import { ticketsRoute } from "../../tickets/route";

export const brandGuidelineRoute = createRoute({
  getParentRoute: () => authLayoutRoute,
  path: "brand-guidelines",
  beforeLoad() {
    const canAccess = posthogClient.getFeatureFlag("manage-brand-guidelines");

    if (!canAccess) {
      throw redirect({
        to: ticketsRoute.to,
      });
    }

    requiresAuth();
  },
  component: BrandGuidelines,
});

function BrandGuidelines() {
  const { notify } = useUI();
  const query = useQuery({
    queryKey: ["brand-guidelines"],
    queryFn: getGuidelines,
  });
  const mutation = useUpdateGuidelinesMutation();
  const posthog = useAnalytics();

  const [brandGuidelines, setBrandGuidelines] = useState("");
  const [files, setFiles] = useState<Asset[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (query.data) {
      setBrandGuidelines(query.data.description as string);
      setFiles(query.data.files as []);
    }
  }, [query.data]);

  function handleInput({ target }: ChangeEvent<HTMLTextAreaElement>) {
    setBrandGuidelines(target.value);
  }
  const onSave = () => {
    mutation.mutate(
      { description: brandGuidelines, files },
      {
        onSuccess() {
          posthog.capture("brand-guidelines-updated", {
            description: brandGuidelines,
            files: files,
          });
          notify({
            title: "Brand Guidelines Updated",
            message: "",
            variant: "success",
          });
        },
      },
    );
  };

  return (
    <article className="grid gap-6 px-10 py-6">
      <header>
        <Typography as="h1" size="3xl" weight="bold" className="mb-2">
          Brand Guidelines
        </Typography>
        <Typography>
          Brand Guidelines are used by the AI Reviewer as well as to inform
          generating Creative Briefs and Mood Boards.
        </Typography>
      </header>

      <div className="grid w-[30%] gap-y-6">
        <ul>
          {files?.map((file: { source: string; originalSource?: string }) => {
            const fileName = (file.originalSource ?? file.source)
              ?.split("/")
              .pop();
            return (
              <li key={file.source} className="group inline-flex items-center">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    downloadAsset(file.source, fileName);
                  }}
                  className="text-egyptian-blue-600 transition-colors hover:text-egyptian-blue-700 active:text-egyptian-blue-800"
                >
                  {fileName}
                </a>
                <Trash
                  className="ml-2 cursor-pointer text-carbon-500 opacity-0 transition-colors hover:text-egyptian-blue-700 active:text-egyptian-blue-800 group-hover:opacity-100"
                  size={20}
                  onClick={() => {
                    setFiles((prev) =>
                      prev.filter((f) => f.source !== file.source),
                    );
                  }}
                />
              </li>
            );
          })}
        </ul>
        <div>
          <Typography className="mb-2 text-base-black" weight="medium">
            Select Files to Upload
          </Typography>
          <input
            type="file"
            className="hidden"
            ref={fileInputRef}
            onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
              const files = event.target.files;
              if (files && files.length > 0) {
                setIsUploading(true);
                const startTime = performance.now();
                const payload = (await uploadImageAsset(files[0])) as FormData;
                const url = payload?.get("url") as string;
                const key = payload?.get("key") as string;
                payload.delete("url");
                await uploadToS3({ url, payload });

                setFiles((prev) => [
                  ...(prev ?? []),
                  {
                    source: "https://static.puntt.ai/" + key,
                    originalSource: files[0].name,
                    type: files[0].type.includes("image") ? "image" : "file",
                  },
                ]);

                posthog.capture("brand-guidelines-file-uploaded", {
                  durationMs: performance.now() - startTime,
                  key,
                  fileName: files[0].name,
                  fileType: files[0].type,
                  fileSizeMB: (files[0].size / 1024 / 1024).toFixed(2),
                });
                setIsUploading(false);
              }
            }}
          />
          <button
            className="flex w-full items-center justify-between rounded border border-carbon-100 bg-base-white p-1"
            onClick={() => {
              fileInputRef.current?.click();
            }}
          >
            <Typography>
              {fileInputRef.current?.files?.[0]?.name ?? "Select File"}
            </Typography>
            <div className="rounded bg-egyptian-blue-600 p-1 transition-colors hover:bg-egyptian-blue-700 active:bg-egyptian-blue-800">
              {isUploading ? (
                <Spinner className="text-base-white" size={20} />
              ) : (
                <FilePlus className="text-base-white" size={20} />
              )}
            </div>
          </button>
        </div>
        <div>
          <Typography className="mb-2 text-base-black" weight="medium">
            Or Type Guidelines
          </Typography>
          <Textarea
            fullWidth
            placeholder="Add your brand guidelines here"
            className="[&_textarea]:min-h-24 [&_textarea]:resize-none [&_textarea]:py-0.5 [&_textarea]:pr-[52px]"
            rows={1}
            onInput={handleInput}
            value={brandGuidelines}
          />
        </div>

        <ButtonNew
          onClick={() => {
            onSave();
          }}
          className="w-fit bg-egyptian-blue-600 text-base-white transition-colors hover:bg-egyptian-blue-700 active:bg-egyptian-blue-800"
          disabled={isUploading}
        >
          Save
        </ButtonNew>
      </div>
    </article>
  );
}
