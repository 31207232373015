// IMPORTANT:
// Only import this file once, and only in `src/index.tsx` because we do not
// want to instantiate multiple query clients within our application.

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { type FC, type PropsWithChildren } from "react";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
    mutations: {
      onError(e) {
        console.error(e);
      },
    },
  },
});

export const QueryClientContext: FC<PropsWithChildren> = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
