import { ButtonNew, Icon, Input, Select, Typography } from "@mg/dali/src";
import { type EnterpriseProfileType } from "@mg/schemas/src/commons";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  useNavigate,
  // eslint-disable-next-line import/named
  createRoute,
  useSearch,
  redirect,
} from "@tanstack/react-router";
import cx from "classnames";
import { EditorState } from "draft-js";
// @ts-expect-error TS7016: No corresponding type decl.
import { stateToMarkdown } from "draft-js-export-markdown";
// @ts-expect-error TS7016: No corresponding type decl.
import { stateFromMarkdown } from "draft-js-import-markdown";
import {
  type ChangeEvent,
  type FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { AvatarWithInitials } from "../../../../components/AvatarWithInitials";
import { RichEditor } from "../../../../components/RichEditor";
import { useUI } from "../../../../contexts/ui";
import { getBrands, getConceptsByParent } from "../../../../services/concepts";
import { updateAvatar } from "../../../../services/upload";
import {
  getUserProfile,
  updateUserProfile,
} from "../../../../services/userProfile";
import {
  canAccessCreativeConnect,
  canAccessEditOwnProfile,
  canAccessLists,
  canAccessPuntt,
  requiresAuth,
} from "../../../../utils/auth";
import {
  createDefaultNetworkRouteSearch,
  CREATIVE_ROLES_OPTIONS,
} from "../../../../utils/constants";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks";
import { useConcepts } from "../../../../utils/queries/concepts";
import { useProfile } from "../../../../utils/queries/profile";
import { setUserProfile } from "../../../../utils/slices/userProfile";
import { filterDuplicateOptions } from "../../../../utils/validation";
import { authLayoutRoute } from "../../../auth-layout/route";
import { listsRoute } from "../../../lists/route";
import { myNetworkRoute } from "../../../network/route";
import { ticketsRoute } from "../../../tickets/route";
import { userProfileRoute } from "../../route";

export const aboutMeEditRoute = createRoute({
  getParentRoute: () => authLayoutRoute,
  beforeLoad() {
    if (!canAccessEditOwnProfile()) {
      if (canAccessPuntt()) {
        throw redirect({
          to: ticketsRoute.to,
        });
      }

      if (canAccessLists()) {
        throw redirect({
          to: listsRoute.to,
        });
      }

      if (canAccessCreativeConnect()) {
        throw redirect({
          to: myNetworkRoute.to,
          search: createDefaultNetworkRouteSearch(),
        });
      }
    }

    requiresAuth();
  },
  path: "profile/edit-about-me",
  component: function Component() {
    const { notify } = useUI();
    // @ts-expect-error TS2339: this route could benefit from a validateSearch
    const { creativeId } = useSearch({ strict: false });
    const navigate = useNavigate();
    const user = useAppSelector((state) => state.auth.value);

    const isAdmin = user?.permissions?.includes("enterprise-admin");
    const editingId = creativeId ?? user?.userID;

    const { data: profile } = useProfile(editingId as string);
    const displayName =
      profile?.type === "agency" ? profile?.name : profile?.name?.split(" ")[0];
    const { data: toolOptions } = useConcepts("tool", "tools");
    const { data: industryOptions } = useConcepts("industry", "industries");
    const { data: specialtiesOptions } = useConcepts(
      "parent-skill",
      "specialties",
    );
    const { data: skillsOptions } = useConcepts("skill", "skills");
    const brandsQuery = useQuery({
      queryKey: ["brands"],
      queryFn: getBrands,
    });

    const brandsConcepts = brandsQuery.data?.map((brand: string) => ({
      label: brand,
      value: brand,
    }));

    const [role, setRole] = useState<string>(profile?.role ?? "");

    const [avatarPreview, setAvatarPreview] = useState<string | undefined>(
      undefined,
    );
    const [avatarUploading, setAvatarUploading] = useState(false);
    const avatarInputRef = useRef<HTMLInputElement>(null);
    const [avatarUrl, setAvatarUrl] = useState<string | undefined>(undefined);
    const [bio, setBio] = useState(() => EditorState.createEmpty());
    const [bioCharCount, setBioCharCount] = useState<string>("2000");
    const [tools, setTools] = useState<string[]>(profile?.tools ?? []);
    const [skills, setSkills] = useState<string[]>(profile?.skills ?? []);
    const [industries, setIndustries] = useState<string[]>(
      profile?.industries ?? [],
    );

    const [specialties, setSpecialties] = useState<string[]>(
      profile?.specialties ?? [],
    );
    const [deliverables1, setDeliverables1] = useState<string[]>([]);
    const [deliverables2, setDeliverables2] = useState<string[]>([]);
    const [deliverables3, setDeliverables3] = useState<string[]>([]);
    const [brands, setBrands] = useState<string[]>(profile?.brands ?? []);
    const [, setIsError] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const profileMutation = useMutation({
      mutationFn: updateUserProfile,
    });
    const refetchProfile = useMutation({
      mutationFn: getUserProfile,
    });

    const handleAvatarUpload = useCallback(
      (e: FormEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;

        if (target.files == null || !target.files.length) {
          return;
        }

        return updateAvatar(target.files[0])
          .catch((err) => {
            notify({
              title: "Oops! Something went wrong",
              message: err.data?.message,
              autoDismiss: false,
            });
          })
          .then((response) => {
            if (response) {
              setAvatarUrl(response.fields.key);
            }
          });
      },
      [notify],
    );

    const { data: specialtyOneDeliverablesOptions } = useQuery({
      queryKey: ["specialty-children-1", specialties[0]],
      queryFn: () =>
        getConceptsByParent(
          encodeURIComponent(specialties[0].replace(",", "#")),
        ),
      enabled: specialties[0] != null,
    });
    const { data: specialtyTwoDeliverablesOptions } = useQuery({
      queryKey: ["specialty-children-2", specialties[1]],
      queryFn: () =>
        getConceptsByParent(
          encodeURIComponent(specialties[1].replace(",", "#")),
        ),
      enabled: specialties[1] != null,
    });
    const { data: specialtyThreeDeliverablesOptions } = useQuery({
      queryKey: ["specialty-children-3", specialties[2]],
      queryFn: () =>
        getConceptsByParent(
          encodeURIComponent(specialties[2].replace(",", "#")),
        ),
      enabled: specialties[2] != null,
    });

    useEffect(() => {
      if (profile?.bio?.length) {
        setBio(() =>
          EditorState.createWithContent(stateFromMarkdown(profile.bio)),
        );
      }
      if (profile?.role) {
        setRole(profile.role);
      }
      if (profile?.avatar) {
        setAvatarPreview(profile.avatar);
        setAvatarUrl(profile.avatar);
      }
      if (profile?.brands?.length) {
        setBrands(profile.brands);
      }
      if (profile?.tools?.length) {
        setTools(profile.tools);
      }
      if (profile?.industries?.length) {
        setIndustries(profile.industries);
      }
      if (profile?.specialties?.length) {
        setSpecialties(profile.specialties);
      }
      if (profile?.skills?.length) {
        setSkills(profile.skills);
      }
    }, [profile]);

    useEffect(
      () => {
        if (!profile?.specialties?.length) return;

        if (specialtyOneDeliverablesOptions?.length && !deliverables1.length) {
          setDeliverables1(
            specialtyOneDeliverablesOptions
              .filter((s) => profile.deliverables?.includes(s.label))
              .map((spec) => spec.label),
          );
        }
        if (
          profile.specialties[1] &&
          specialtyTwoDeliverablesOptions?.length &&
          !deliverables2.length
        ) {
          setDeliverables2(
            specialtyTwoDeliverablesOptions
              .filter((s) => profile.deliverables?.includes(s.label))
              .map((spec) => spec.label),
          );
        }
        if (
          profile.specialties[2] &&
          specialtyThreeDeliverablesOptions?.length &&
          !deliverables3.length
        ) {
          setDeliverables3(
            specialtyThreeDeliverablesOptions
              .filter((s) => profile.deliverables?.includes(s.label))
              .map((spec) => spec.label),
          );
        }
        if (!specialties[0]) {
          setDeliverables1([]);
        }
        if (!specialties[1]) {
          setDeliverables2([]);
        }
        if (!specialties[2]) {
          setDeliverables3([]);
        }
      },
      // we dont want this to update with deliverables or specialties, so disabling the warning
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        profile,
        specialtyOneDeliverablesOptions,
        specialtyTwoDeliverablesOptions,
        specialtyThreeDeliverablesOptions,
      ],
    );
    const formIsValid = useCallback(
      (field?: string) => {
        const invCheck = {
          specialties: specialties.length,
          deliverables1: deliverables1.length,
        };
        if (field) return !!invCheck[field as keyof typeof invCheck];

        return true;
      },
      [specialties, deliverables1],
    );

    function handleSubmit(e: FormEvent) {
      e.preventDefault();
      if (!formIsValid()) return setIsError(true);
      else setIsError(false);

      const form = e.target;
      const formData = new FormData(form as HTMLFormElement);
      const yearValue = formData.get("yearsExperience") as string;
      const formValues = {
        bio: stateToMarkdown(bio.getCurrentContent()),
        role: role as EnterpriseProfileType,
        title: formData.get("title") as string,
        email: formData.get("email") as string,
        avatar: avatarUrl,
        name: formData.get("name") as string,
        preferredName: formData.get("preferredName") as string,
        yearsExperience: yearValue !== "" ? +yearValue : undefined,
        linkedIn: formData.get("linkedIn") as string,
        whatsapp: formData.get("whatsapp") as string,
        location: formData.get("location") as string,
        socialLinks: [formData.get("socialLink1") as string],
        website: formData.get("website") as string,
        deliverables: [...deliverables1, ...deliverables2, ...deliverables3],
        tools,
        industries,
        brands,
        specialties,
        skills,
        creativeId,
      };

      profileMutation.mutate(formValues, {
        onSuccess() {
          if (isAdmin) {
            //@ts-expect-error TS2345: Inherited search types incorrect
            return navigate({ to: myNetworkRoute.to });
          }
          refetchProfile.mutate(undefined, {
            onSuccess(data) {
              navigate({ to: userProfileRoute.to });
              setDeliverables1([]);
              setDeliverables2([]);
              setDeliverables3([]);
              setSpecialties([]);
              setTools([]);
              setIndustries([]);
              if (!isAdmin) {
                dispatch(setUserProfile(data));
              }
              notify({
                variant: "success",
                title: `Saved!`,
                message: `You have successfully saved your profile!`,
                leadingIcon: (
                  <Icon.CheckCircle color="rgb(var(--base-black))" />
                ),
              });
            },
          });
        },
        onError() {
          notify({
            variant: "error",
            title: `Oops!`,
            message: `Something went wrong!`,
            leadingIcon: <Icon.XCircle color="rgb(var(--base-black))" />,
          });
        },
      });
    }

    const handleSetBio = useCallback((e: EditorState) => {
      const charCount = e.getCurrentContent().getPlainText("").length;
      setBioCharCount(
        charCount >= 2000 ? "0" : (2000 - charCount).toLocaleString(),
      );
      if (charCount < 2000) {
        setBio(e);
      }
    }, []);

    if (!profile) return null;

    return (
      <form
        className="flex w-full flex-col gap-6 overflow-auto p-4 pb-24 md:px-10 md:py-9"
        onSubmit={handleSubmit}
      >
        <div className="flex justify-between">
          <Typography size="2xl" weight="bold">
            About {isAdmin ? profile.name : "Me"}
          </Typography>
          <div className="flex gap-x-6">
            <ButtonNew
              theme="primary"
              variant="ghost"
              onClick={() => {
                if (isAdmin) {
                  //@ts-expect-error TS2345: Inherited search types incorrect
                  return navigate({ to: myNetworkRoute.to });
                }

                return navigate({ to: userProfileRoute.to });
              }}
            >
              Back
            </ButtonNew>
            <ButtonNew
              theme="primary"
              variant="filled"
              disabled={profileMutation.isPending}
              type="submit"
            >
              Save
            </ButtonNew>
          </div>
        </div>
        <Typography size="lg">
          This core information serves as the backbone of{" "}
          {isAdmin ? `${displayName}'s` : "your"} profile. Keep any changes to
          your contact information and availability as up to date as possible.
        </Typography>
        <div className="align-center flex gap-x-2 pt-3">
          <input
            name="avatar"
            type="file"
            accept=".jpg, .jpeg, .png"
            disabled={avatarUploading || profileMutation.isPending}
            onInput={(e: ChangeEvent<HTMLInputElement>) => {
              const { target } = e;

              if (
                target.value === "" ||
                target.files == null ||
                target.files.length === 0
              ) {
                return;
              }

              const tempImagePreview = window.URL.createObjectURL(
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                e.target!.files![0],
              );
              setAvatarUploading(true);
              setAvatarPreview(tempImagePreview);
              const img = new Image();
              img.src = tempImagePreview;

              img.onload = () => {
                if (img.width > 4000 || img.height > 4000) {
                  return notify({
                    title: "Image Upload Unsuccessful",
                    message:
                      "Both the width and height of your image must be less than 4000px",
                  });
                }
              };

              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              handleAvatarUpload(e)!.finally(() => {
                setAvatarUploading(false);
              });
            }}
            ref={avatarInputRef}
            className="absolute left-0 top-0 size-px opacity-0"
          />
          <button
            onClick={(e) => {
              e.preventDefault();
              avatarInputRef.current?.click();
            }}
          >
            <AvatarWithInitials
              size={16}
              name={profile.name}
              avatar={avatarPreview}
              isLoading={avatarUploading}
            />
          </button>

          <ButtonNew
            size="xs"
            theme="primary"
            variant="ghost"
            onClick={() => avatarInputRef.current?.click()}
          >
            {" "}
            <Icon.UploadSimple />
            Upload Headshot *
          </ButtonNew>
        </div>
        <div className="grid grid-cols-1 gap-y-6 md:grid-cols-3">
          <div className="col-span-3 grid grid-cols-1 gap-6 md:grid-cols-2">
            <Input
              name="name"
              label="Full Name *"
              placeholder="First Last"
              size="sm"
              defaultValue={profile.name}
              required
              key={`${profile.name}-profile-name`}
            />
            <Input
              name="preferredName"
              label="Nickname"
              placeholder="Nickname / Preferred Name"
              size="sm"
              defaultValue={profile.preferredName}
              key={`${profile.preferredName}-profile-preferredName`}
            />
          </div>
          <div className="col-span-3 grid grid-cols-1 gap-y-6 md:grid-cols-3 md:gap-x-6">
            <Select<false>
              onChange={({ value }) => setRole(value as string)}
              options={CREATIVE_ROLES_OPTIONS}
              value={CREATIVE_ROLES_OPTIONS.find((o) => o.value === role)}
              name="role"
              label={`${
                isAdmin ? `${displayName}'s` : "Your"
              } Role @ ${user?.company}`}
              disabled
            />
            <Input
              name="title"
              label="Title"
              placeholder="Designer"
              size="sm"
              defaultValue={profile.title}
              key={`${profile.title}-profile-title`}
            />
            <Input
              name="yearsExperience"
              label="Years of Experience"
              placeholder="5"
              size="sm"
              defaultValue={profile.yearsOfExperience}
              key={`${profile.yearsOfExperience}-profile-yearsOfExperience`}
              type="number"
            />
          </div>
          <div className="col-span-3 grid grid-cols-1 gap-y-6 md:grid-cols-3 md:gap-x-6">
            <Input
              name="website"
              label="Portfolio"
              placeholder="portfolio.com"
              size="sm"
              defaultValue={profile.website}
              key={profile.website}
            />
            <Input
              name="linkedIn"
              label="LinkedIn"
              placeholder="https://linkedin.com/"
              size="sm"
              defaultValue={profile.linkedIn}
              key={profile.linkedIn ?? "profile-linkedin"}
            />
            <Input
              name="socialLink1"
              label="Additional Social"
              placeholder="social.com/profile"
              size="sm"
              defaultValue={profile.socialLinks ? profile.socialLinks[0] : ""}
              key={
                profile.socialLinks
                  ? profile.socialLinks[0]
                  : "profile-socialLinks"
              }
            />
          </div>
          <div className="col-span-3 grid grid-cols-1 gap-y-6 md:grid-cols-3 md:gap-x-6">
            <Input
              name="email"
              label="Email"
              placeholder="email@email.com"
              size="sm"
              defaultValue={profile.email}
            />
            <Input
              name="whatsapp"
              label="Contact Number"
              placeholder="+1 234 567 8901"
              size="sm"
              defaultValue={profile.whatsapp}
              key={profile.whatsapp ?? "profile-whatsapp"}
            />
            <Input
              name="location"
              label="Location"
              placeholder="City, Country"
              size="sm"
              defaultValue={profile.location}
              key={`${profile.location}-profile-location`}
            />
          </div>
          <div className="col-span-3">
            <div>
              <RichEditor
                label="Bio"
                editorState={bio}
                onChange={handleSetBio}
                placeholder={`What's interesting about ${
                  isAdmin ? profile.name : "you"
                }?`}
                className="h-96"
              />
              <div className="flex items-start justify-between">
                <Typography>{bioCharCount} characters remaining</Typography>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-y-6 border-y border-base-black py-6 md:grid-cols-3 md:gap-x-6">
          <div className="col-span-3">
            <Typography>
              You can select up to three (3) Specialties. Each Specialty has a
              number of Deliverables that will help refine{" "}
              {isAdmin ? `${displayName}'s` : "your"} profile and represent{" "}
              {isAdmin ? "their" : "your"} abilities.
            </Typography>
          </div>
          <div className="col-span-3 md:col-span-1">
            <Select<false>
              label="Specialty 1 *"
              placeholder="Search or Select"
              disabled={!specialtiesOptions || profileMutation.isPending}
              options={specialtiesOptions ?? []}
              name="specialty1"
              value={
                specialties[0]
                  ? { value: specialties[0], label: specialties[0] }
                  : undefined
              }
              onChange={({ value }) => {
                setSpecialties(() => {
                  const newSpecialties = [...specialties];
                  if (value !== specialties[0]) {
                    setDeliverables1([]);
                  }
                  newSpecialties[0] = value as string;
                  return newSpecialties;
                });
              }}
              key={`spec1-${specialties[0]}`}
              sortOptions
            />
          </div>
          <div className="col-span-3 flex items-start md:col-span-2">
            <Select<true>
              label="Deliverables"
              placeholder="Search or Select"
              disabled={!specialties[0] || profileMutation.isPending}
              options={
                specialtyOneDeliverablesOptions?.map((spec) => ({
                  label: spec.label,
                  value: spec.label,
                })) ?? []
              }
              multiple
              sortOptions
              name="deliverables1"
              value={deliverables1.map((s) => ({ value: s, label: s }))}
              onChange={(values) =>
                setDeliverables1(values.map((v) => v.value as string))
              }
              className="w-full"
              key={`deli1-${deliverables1.join(",")}`}
            />
            <button
              onClick={(e) => {
                e.preventDefault();
                setSpecialties(() => {
                  const newSpecialties = [...specialties];

                  newSpecialties.splice(0, 1);
                  return newSpecialties;
                });
                setDeliverables1(deliverables2);
                setDeliverables2(deliverables3);
              }}
              disabled={!specialties[0]}
            >
              <Icon.XCircle
                size={24}
                className={cx({ "text-carbon-300": !specialties[0] })}
              />
            </button>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-y-6 border-b border-base-black pb-6 md:grid-cols-3 md:gap-x-6">
          <div className="col-span-1">
            <Select<false>
              label="Specialty 2"
              placeholder="Search or Select"
              disabled={
                !specialties[0] ||
                !specialtiesOptions ||
                profileMutation.isPending
              }
              sortOptions
              options={
                specialtiesOptions?.filter((spec) => {
                  if (
                    (specialties[0] && spec.value === specialties[0]) ||
                    (specialties[2] && spec.value === specialties[2])
                  ) {
                    return false;
                  }
                  return true;
                }) ?? []
              }
              name="specialty2"
              value={
                specialties[1]
                  ? { value: specialties[1], label: specialties[1] }
                  : undefined
              }
              onChange={({ value }) =>
                setSpecialties(() => {
                  const newSpecialties = [...specialties];
                  if (value !== specialties[1]) {
                    setDeliverables2([]);
                  }
                  newSpecialties[1] = value as string;
                  return newSpecialties;
                })
              }
              key={`spec2-${specialties[1]}`}
            />
          </div>
          <div className="col-span-2 flex items-start">
            <Select<true>
              label="Deliverables"
              placeholder="Search or Select"
              disabled={!specialties[1] || profileMutation.isPending}
              options={
                specialtyTwoDeliverablesOptions?.map((spec) => ({
                  label: spec.label,
                  value: spec.label,
                })) ?? []
              }
              sortOptions
              multiple
              name="deliverables2"
              value={deliverables2.map((s) => ({ value: s, label: s }))}
              onChange={(values) =>
                setDeliverables2(values.map((v) => v.value as string))
              }
              key={`deli2-${deliverables2.join(",")}`}
              className="w-full"
            />
            <button
              onClick={(e) => {
                e.preventDefault();
                setSpecialties(() => {
                  const newSpecialties = [...specialties];
                  newSpecialties.splice(1, 1);
                  return newSpecialties;
                });
                setDeliverables2(deliverables3);
              }}
              disabled={!specialties[1]}
            >
              <Icon.XCircle
                size={24}
                className={cx({ "text-carbon-300": !specialties[1] })}
              />
            </button>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-y-6 border-b border-base-black pb-6 md:grid-cols-3 md:gap-x-6">
          <div className="col-span-1">
            <Select<false>
              label="Specialty 3"
              placeholder="Search or Select"
              disabled={
                !specialties[0] ||
                !specialties[1] ||
                !specialtiesOptions ||
                profileMutation.isPending
              }
              sortOptions
              options={
                specialtiesOptions?.filter((spec) => {
                  if (
                    (specialties[0] && spec.value === specialties[0]) ||
                    (specialties[1] && spec.value === specialties[1])
                  ) {
                    return false;
                  }
                  return true;
                }) ?? []
              }
              name="specialty3"
              value={
                specialties[2]
                  ? { value: specialties[2], label: specialties[2] }
                  : undefined
              }
              onChange={({ value }) =>
                setSpecialties(() => {
                  const newSpecialties = [...specialties];
                  if (value !== specialties[2]) {
                    setDeliverables3([]);
                  }
                  newSpecialties[2] = value as string;
                  return newSpecialties;
                })
              }
              key={`spec3-${specialties[2]}`}
            />
          </div>
          <div className="col-span-2 flex items-start">
            <Select<true>
              label="Deliverables"
              placeholder="Search or Select"
              disabled={!specialties[2] || profileMutation.isPending}
              options={
                specialtyThreeDeliverablesOptions?.map((spec) => ({
                  label: spec.label,
                  value: spec.label,
                })) ?? []
              }
              multiple
              sortOptions
              name="deliverables3"
              value={deliverables3.map((s) => ({ value: s, label: s }))}
              onChange={(values) =>
                setDeliverables3(values.map((v) => v.value as string))
              }
              className="w-full"
              key={`deli3-${deliverables3.join(",")}`}
            />
            <button
              onClick={(e) => {
                e.preventDefault();
                setDeliverables3([]);
                setSpecialties(() => {
                  const newSpecialties = [...specialties];
                  newSpecialties.splice(2, 1);

                  return newSpecialties;
                });
              }}
              disabled={!specialties[2]}
            >
              <Icon.XCircle
                size={24}
                className={cx({ "text-carbon-300": !specialties[2] })}
              />
            </button>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-y-6 md:gap-x-6">
          <Select<true>
            label="Skills"
            placeholder="Search or Select"
            disabled={!skillsOptions || profileMutation.isPending}
            options={filterDuplicateOptions(skillsOptions) ?? []}
            multiple
            sortOptions
            name="skills"
            value={skills.map((s) => ({ value: s, label: s }))}
            onChange={(values) =>
              setSkills(values.map((v) => v.value as string))
            }
            allowCustom
          />
          {/* had to make this a flex flex-col because grid was causing the options to be rendered at bottom of the screen */}
          <Select<true>
            label="Tools"
            placeholder="Search or Select"
            disabled={!toolOptions || profileMutation.isPending}
            options={filterDuplicateOptions(toolOptions) ?? []}
            multiple
            sortOptions
            name="tools"
            value={tools.map((s) => ({ value: s, label: s }))}
            onChange={(values) =>
              setTools(values.map((v) => v.value as string))
            }
            allowCustom
          />
          <Select<true>
            label="Industries"
            placeholder="Search or Select"
            disabled={!industryOptions || profileMutation.isPending}
            options={industryOptions ?? []}
            multiple
            name="industries"
            value={industries.map((s) => ({ value: s, label: s }))}
            onChange={(values) =>
              setIndustries(values.map((v) => v.value as string))
            }
            allowCustom
            sortOptions
          />
          <Select<true>
            label="Brands"
            placeholder="Search or Select"
            disabled={!brandsConcepts || profileMutation.isPending}
            options={brandsConcepts ?? []}
            multiple
            name="brands"
            value={brands.map((s) => ({ value: s, label: s }))}
            onChange={(values) =>
              setBrands(values.map((v) => v.value as string))
            }
            allowCustom
            sortOptions
          />
        </div>
      </form>
    );
  },
  errorComponent() {
    return "Uh oh! That one isn't found";
  },
});
