import { type GetFormattedResponse } from "@mg/schemas/src/christo/concepts";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

export type ConceptsState = {
  skills: GetFormattedResponse | null;
  softSkills: GetFormattedResponse | null;
  specialties: GetFormattedResponse | null;
  tools: GetFormattedResponse | null;
  industries: GetFormattedResponse | null;
  deliverables: GetFormattedResponse | null;
};

type SetConceptPayload = {
  target: keyof ConceptsState;
  value: GetFormattedResponse;
};

const initialState: ConceptsState = {
  skills: null,
  softSkills: null,
  specialties: null,
  tools: null,
  industries: null,
  deliverables: null,
};

export const conceptsSlice = createSlice({
  name: "concepts",
  initialState,
  reducers: {
    setConcept(state, action: PayloadAction<SetConceptPayload>) {
      const { target, value } = action.payload;
      state[target] = value;
    },
  },
});

export const { setConcept } = conceptsSlice.actions;
