import { cva } from "class-variance-authority";
import React, { useRef, useEffect, useCallback, useState } from "react";
import { type HTMLAttributes, type ReactElement, type ReactNode } from "react";
import { extendTailwindMerge } from "tailwind-merge";

import { Icon, Typography } from "..";

export interface BannerProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * Optional callback for when the banner has opened
   */
  onOpen?(evt: CustomEvent<{ action: "open" }>): void;
  /**
   * Optional callback for when the banner has closed
   */
  onClose?(evt: CustomEvent<{ action: "close" }>): void;
  /**
   * Whether the banner is visible or not
   */
  open: boolean;
  /**
   * Sets the variant color of the banner
   */
  variant?: "success" | "error" | "default";
  /**
   * An optional message to display in the banner
   */
  message?: ReactNode;
  /**
   * An optional icon to display with the banner text
   */
  icon?: ReactNode;
}

const twMerge = extendTailwindMerge({ prefix: "dali-" });

export function Banner({
  onOpen = () => {
    /* noop */
  },
  onClose = () => {
    /* noop */
  },
  open,
  variant = "default",
  message,
  icon,
  ...rest
}: BannerProps): ReactElement {
  const contentRef = useRef(null);

  const { children, className: hash, ...pass } = rest;
  const variantColorMap = {
    default: "base-black",
    error: "cadmium-900",
    success: "malachite-900",
  };
  const defaultLeadingIconMap = {
    default: <Icon.LightbulbFilament />,
    error: <Icon.Prohibit />,
    success: <Icon.CheckCircle />,
  };

  const chosenLeadingIcon =
    icon === undefined ? defaultLeadingIconMap[variant] : icon;

  const [offsetHeight, setOffsetHeight] = useState(0);

  const baseClasses = cva(
    [
      "mg2-banner__container dali-py-0 dali-px-4 dali-box-border dali-overflow-hidden dali-items-start dali-bg-carbon-100",
      "dali-shrink-0 dali-h-0 dali-border-0 dali-w-full dali-transition-all dali-duration-300 dali-ease-out",
    ],
    {
      variants: {
        variant: {
          success: "dali-bg-malachite-200 dali-text-malachite-900",
          error: "dali-bg-cadmium-200 dali-text-cadmium-900",
          default: "dali-bg-carbon-100 dali-text-base-black",
        },
        open: {
          true: `dali-py-2 dali-sticky dali-top-0 dali-flex dali-h-[calc(${offsetHeight}px_+_16px)]`,
          false: "",
        },
      },
    },
  );
  const containerClasses = twMerge(baseClasses({ variant, open }), hash);
  const contentClasses = cva(
    [
      "dali-flex dali-w-full dali-transition-transform dali-duration-300 dali-ease-out-expo",
    ],
    {
      variants: {
        open: {
          true: "dali-translate-y-0",
          false: "",
        },
      },
    },
  );

  const handleClose = useCallback(() => {
    if (!open) {
      return;
    }

    onClose(new CustomEvent("onClose", { detail: { action: "close" } }));
  }, [open, onClose]);

  useEffect(() => {
    const handleOpen = () => {
      setOffsetHeight(
        (contentRef.current as unknown as HTMLElement)?.offsetHeight,
      );
      onOpen(new CustomEvent("onOpen", { detail: { action: "open" } }));
    };

    if (open) {
      handleOpen();
    } else {
      handleClose();
    }

    if (open) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [open, onOpen, handleClose]);

  return (
    <Icon.IconContext.Provider
      value={{
        size: 24,
        color: `rgb(var(--${variantColorMap[variant]}))`,
      }}
    >
      <div
        className={containerClasses}
        role="banner"
        style={
          {
            "--offset-height": `${offsetHeight}px`,
          } as React.CSSProperties
        }
        {...pass}
      >
        <div
          className={contentClasses({ open })}
          ref={contentRef}
          role="status"
          aria-live="assertive"
        >
          <div className="dali-flex dali-flex-1 dali-mr-2">
            <div className="dali-mr-2" role="img">
              {icon !== false && chosenLeadingIcon}
            </div>
            <div className="dali-flex dali-flex-1 dali-text-center">
              <Typography>{message}</Typography>
              {children}
            </div>
          </div>
          <Icon.XCircle
            onClick={handleClose}
            size={24}
            className="mg-banner__dismiss-icon"
          />
        </div>
      </div>
    </Icon.IconContext.Provider>
  );
}
