import { Icon, Logo, Typography } from "@mg/dali/src";
// eslint-disable-next-line import/named, @typescript-eslint/no-unused-vars
import { Route, createRoute } from "@tanstack/react-router";
import { useEffect, useState } from "react";

import { CsvUploadCard } from "../../components/CsvUploadCard";
import { InviteCreativeCard } from "../../components/InviteCreativeCard";
import { InviteDialog } from "../../components/InviteDialog";
import { useUI } from "../../contexts/ui";
import { requiresAuth } from "../../utils/auth";
import { useNetworkSearch } from "../../utils/hooks/useNetworkSearch";
import { authLayoutRoute } from "../auth-layout/route";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { NetworkSection, networkSearchParser } from "../network/route";

export const discoverRoute = createRoute({
  getParentRoute: () => authLayoutRoute,
  path: "discover",
  beforeLoad() {
    requiresAuth();
  },
  validateSearch(search: Record<string, unknown>) {
    return networkSearchParser.parse(search);
  },
  component: function Component() {
    const { rootRef } = useUI();
    const { networkMutation } = useNetworkSearch();

    const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [cardsPerRow, setCardsPerRow] = useState(0);

    useEffect(() => {
      const calculateCardsPerRow = () => {
        if (rootRef.current) {
          const containerWidth = rootRef.current.offsetWidth - 72;
          const cardWidth = 367;
          const cards = Math.floor((containerWidth + 24) / (cardWidth + 24));

          setCardsPerRow(cards);
        }
      };

      calculateCardsPerRow();

      window.addEventListener("resize", calculateCardsPerRow);

      return () => {
        window.removeEventListener("resize", calculateCardsPerRow);
      };
    }, [rootRef]);

    function renderContents() {
      if (networkMutation.data != null) {
        if (networkMutation.data.recommendations.total === 0) {
          return (
            <div className="grid gap-6">
              <Typography size="3xl" weight="bold">
                No Results
              </Typography>
              <Typography>
                There aren&apos;t any great matches for your current search.{" "}
              </Typography>

              <section className="hidden grid-cols-2 gap-8">
                <InviteCreativeCard />
                <CsvUploadCard />
              </section>
            </div>
          );
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { results } = networkMutation.data.recommendations;

        // return (
        //   <NetworkSection
        //     title="People You May Know"
        //     actions={[]}
        //     creatives={results}
        //     data-testid="people-you-may-know"
        //     cardsPerRow={cardsPerRow}
        //     renderCount={false}
        //     isDiscover
        //   />
        // );

        return null;
      }

      if (networkMutation.error != null) {
        return (
          <figure className="fixed left-1/2 top-1/2 grid -translate-x-1/2 -translate-y-1/2 justify-center text-vermillion-800">
            <Icon.SmileyXEyes size={64} weight="fill" className="mx-auto" />
            <Typography size="2xl" weight="medium">
              Error loading your network. Please try again later.
            </Typography>
          </figure>
        );
      }

      return (
        <figure className="fixed left-1/2 top-1/2 grid -translate-x-1/2 -translate-y-1/2 justify-center">
          <Logo variant="black" type="logo" className="mx-auto" />
          <Typography>Loading your network</Typography>
        </figure>
      );
    }

    return (
      <section
        ref={rootRef}
        className="group grid gap-6 px-5 pb-4 pt-0 md:px-9 md:py-4"
      >
        <InviteDialog
          open={inviteDialogOpen}
          onOpenChange={(o) => {
            setInviteDialogOpen(o);
          }}
        />

        {renderContents()}
      </section>
    );
  },
});
