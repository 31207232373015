/**
 * Simple debounce function, used to delay successive API calls, etc.
 */

export function debounce<T extends unknown[]>(
  fn: (...args: T) => void,
  timeout = 300,
) {
  let timer: number;

  return (...args: T) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
    }, timeout);
  };
}
