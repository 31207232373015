import {
  type GetMessagesResponse,
  type GetChatsResponse,
} from "@mg/schemas/src/christo/messages";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

type ListsState = {
  messages: GetChatsResponse | null;
  activeThread: GetMessagesResponse | null;
};

const initialState: ListsState = {
  messages: null,
  activeThread: null,
};

export const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    setMessages(state, action: PayloadAction<GetChatsResponse>) {
      state.messages = action.payload;
    },
    setActiveThread(state, action: PayloadAction<GetMessagesResponse>) {
      state.activeThread = action.payload;
    },
  },
});

export const { setMessages, setActiveThread } = messagesSlice.actions;
