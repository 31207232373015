import { z } from "zod";

import { queryStringSchema } from "../common";
import { conceptSchema } from "../commons/concepts";

export const getQuerySchema = z.object({
  t: queryStringSchema,
});

export const getRequestSchema = z.object({
  query: getQuerySchema,
});

export type GetRequest = z.infer<typeof getRequestSchema>;

export const getResponseSchema = z.array(conceptSchema);

export type GetResponse = z.infer<typeof getResponseSchema>;

export const childrenQuerySchema = z.object({
  p: queryStringSchema,
  t: z.string().optional(),
});

export const childrenRequestSchema = z.object({
  query: childrenQuerySchema,
});

export type ChildrenRequest = z.infer<typeof childrenRequestSchema>;

export const getChildrenResponseSchema = z.array(conceptSchema);

export type GetChildrenResponse = z.infer<typeof getResponseSchema>;

export const getFormattedQuerySchema = z.object({
  t: queryStringSchema,
});

export const getFormattedRequestSchema = z.object({
  query: getQuerySchema,
});

export type GetFormattedRequest = z.infer<typeof getFormattedRequestSchema>;

export const formattedConceptSchema = z.object({
  label: z.string(),
  value: z.coerce.string(),
});

export type FormattedConcept = z.infer<typeof formattedConceptSchema>;

export const getFormattedResponseSchema = z.array(formattedConceptSchema);

export type GetFormattedResponse = z.infer<typeof getFormattedResponseSchema>;
