import { cva, cx } from "class-variance-authority";
import React, { forwardRef } from "react";
import { type HTMLAttributes, type Ref } from "react";
import { extendTailwindMerge } from "tailwind-merge";

const twMerge = extendTailwindMerge({ prefix: "dali-" });
export interface CardProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * Optionally sets the type of Card; defaults to "elevated"
   */
  type?: "elevated" | "outlined" | "ghost";
  /**
   * Optionally, sets the padding of the Card; defaults to "sm"
   */
  size?: "sm" | "lg";
  /**
   * Optionally, disables the Card click events
   */
  disabled?: boolean;
  /**
   * Optionally, make the card uninteractable
   */
  uninteractive?: boolean;
}

export const Card = forwardRef(
  (
    {
      className: hash,
      children,
      disabled,
      type = "elevated",
      size = "sm",
      uninteractive = false,
      ...rest
    }: CardProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const baseStyles = [
      "dali-border-transparent",
      "active:dali-border-gray-200",
      "dali-rounded-lg",
      "dali-transition-all",
      "dali-bg-base-white",
      "dali-text-left",
    ];

    const disabledStyles = [
      "dali-relative",
      "dali-overflow-hidden",
      "dali-pointer-events-none",
      "after:dali-absolute",
      "after:dali-block",
      "after:dali-bg-base-white/50",
      "after:dali-inset-0",
      { children: "dali-opacity-50" },
    ];

    const elevatedStyles = [
      "dali-border",
      "dali-shadow-lg",
      "hover:dali-shadow-xl",
      "active:dali-shadow-md",
    ];

    const baseOutlinedStyles = [
      "dali-shadow-none",
      "dali-border",
      "dali-border-carbon-300",
      "hover:dali-border-gray-400",
      "active:dali-border-gray-600",
    ];

    const baseGhostStyles = ["dali-shadow-none", "dali-border-transparent"];

    const cardStyles = cva(baseStyles, {
      variants: {
        type: {
          outlined: baseOutlinedStyles,
          elevated: elevatedStyles,
          ghost: baseGhostStyles,
        },
        size: {
          sm: ["dali-p-6"],
          lg: ["dali-p-9"],
        },
        disabled: {
          true: disabledStyles,
          false: "",
        },
        uninteractive: {
          true: "dali-cursor-default",
          false: "dali-cursor-pointer",
        },
      },
      compoundVariants: [
        // primary compound variants
        {
          disabled: true,
          type: "elevated",
          className: "dali-shadow-none",
        },
        {
          type: "outlined",
          disabled: true,
          className: ["dali-border-carbon-300/50"],
        },
      ],
    });

    const classes = twMerge(
      cx("mg-card", cardStyles({ size, type, disabled, uninteractive }), hash),
    );

    return (
      <div role="button" tabIndex={-1} className={classes} {...rest} ref={ref}>
        {children}
      </div>
    );
  },
);

Card.displayName = "Card";
