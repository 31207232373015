import { z } from "zod";

import { assetSchema } from "./asset";

export const markdownSchema = z.string();

export type Markdown = z.infer<typeof markdownSchema>;

export const chatUserSchema = z.object({
  creativeId: z.string(),
  avatar: z.string(),
  hero: assetSchema.optional(),
  name: z.string(),
  bio: markdownSchema.optional(),
});

export type ChatUser = z.infer<typeof chatUserSchema>;

export enum ChatRole {
  SYSTEM = "system",
  USER = "user",
  ASSISTANT = "assistant",
  FUNCTION = "function",
  DEBUG = "debug",
  MG_PROTOCOL = "mg_protocol",
  CONTEXT = "context",
}

export enum ChatProtocolType {
  SUMMARY = "summary",
  RECOMMENDATION = "recommendation",
  PARSE = "parse",
  ERROR = "error",
}

export const chatProtocolTypeSchema = z.nativeEnum(ChatProtocolType);

export const roleSchema = z.nativeEnum(ChatRole);

export const chatMessageSchema = z.object({
  createdAt: z.coerce.date().optional(),
  role: roleSchema,
  content: markdownSchema.optional(),
  name: z.string().optional(),
  creatives: z.array(chatUserSchema).optional(),
  protocolType: chatProtocolTypeSchema.optional(),
  chatId: z.string().optional(),
  topic: z.string().optional(),
  streamMode: z.boolean().optional(),
});

export type ChatMessage = z.infer<typeof chatMessageSchema>;

export const chatSchema = z.object({
  _id: z.string().optional(),
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  archivedAt: z.coerce.date().optional(),
  createdBy: z.string(),
  topic: z.string().optional(),
  messages: z.array(chatMessageSchema),
  summary: z.string().optional(),
  matchedCreatives: z.array(z.string()).optional(),
  // deprecated
  keywords: z.array(z.string()).optional(),
  // new fields
  skills: z.array(z.string()).optional(),
  specialties: z.array(z.string()).optional(),
  industries: z.array(z.string()).optional(),
  tools: z.array(z.string()).optional(),
});

export type Chat = z.infer<typeof chatSchema>;
