import { useState, useCallback } from "react";

import { useAnalytics, errorAnalyticsPayload } from "../../../utils/analytics";
import { delay } from "../../../utils/delay";
import { uploadImagesToS3 } from "../../../utils/figma-plugin";
import { type FigmaImageData } from "../../../utils/figma-plugin-types";
import { useRevisionMutation } from "../../../utils/queries/projects";

interface RevisionImageData {
  url: string;
  location: [number, number];
}

/**
 * Upload Progress is displayed as a progress bar in the UI. We have the following steps:
 * 1. FigmaExport: Exporting the images from Figma
 * 2. S3Upload: Uploading the images to S3
 * 3. RevisionCreate: Creating a new revision in Puntt via API
 */
const PROGRESS_LEVELS = {
  FigmaExport: 10,
  S3Upload: 80,
  RevisionCreate: 100,
};

export const useUploadRevision = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const createRevisionMutation = useRevisionMutation();
  const posthog = useAnalytics();
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  /**
   * Helper function used to create a new revision
   */
  const createRevision = useCallback(
    async (data: RevisionImageData[], ticketId: string) => {
      if (!ticketId) {
        throw new Error("Ticket ID is required");
      }
      const reviewFiles = data.map((item) => ({
        source: item.url,
        type: "image",
        x: item.location[0],
        y: item.location[1],
      }));
      console.log("🚀 Creating revision with data", reviewFiles);
      return createRevisionMutation.mutateAsync({
        ticketId,
        payload: {
          reviewFiles,
        },
      });
    },
    [createRevisionMutation],
  );

  /**
   * Uploads the images to S3 and creates a new revision
   */
  const upload = useCallback(
    async (
      images: FigmaImageData[],
      ticketId: string | undefined,
    ): Promise<boolean> => {
      if (!ticketId) {
        setLoading(false);
        setError("Ticket URL is required");
        return false;
      }
      if (!images || images.length === 0) {
        setLoading(false);
        setError("Please select a design to upload to Puntt");
        return false;
      }

      setUploadProgress(PROGRESS_LEVELS.FigmaExport);
      setLoading(true);
      setError(null);
      setSuccess(false);

      const startTime = performance.now();

      try {
        const countImages = images.length;
        const progressForImages =
          PROGRESS_LEVELS.S3Upload - PROGRESS_LEVELS.FigmaExport;
        const progressPerImage = progressForImages / countImages;
        let countFinished = 0;

        const onProgress = () => {
          // called when each S3 upload is finished
          countFinished++;
          setUploadProgress(
            PROGRESS_LEVELS.FigmaExport +
              Math.round(countFinished * progressPerImage),
          );
        };

        // Upload each image to S3
        const screenshotURLs = await uploadImagesToS3(images, onProgress);
        setUploadProgress(PROGRESS_LEVELS.S3Upload);

        const imageCoordinates = images.map((image) => image.location);

        // merge the images and coordinates into an array of objects
        const imagesData = screenshotURLs.map((url, index) => {
          return { url, location: imageCoordinates[index] };
        });

        // create a new revision
        await createRevision(imagesData, ticketId);

        setUploadProgress(PROGRESS_LEVELS.RevisionCreate);
        await delay(300);

        const analyticsPayload = {
          duration: performance.now() - startTime,
          images: images.length,
          ticketId,
        };
        posthog.capture("figma_plugin_upload_success", analyticsPayload);
        setSuccess(true);
        return true;
      } catch (e) {
        const analyticsPayload = {
          ...errorAnalyticsPayload(e as Error),
          duration: performance.now() - startTime,
          images: images?.length,
          ticketId,
        };
        posthog.capture("figma_plugin_upload_error", analyticsPayload);
        setError((e as Error).message ?? "An error occurred");
        setSuccess(false);
      } finally {
        setLoading(false);
      }
      return false;
    },
    [createRevision, posthog],
  );

  /**
   * Resets the state of the hook
   */
  const reset = () => {
    setLoading(false);
    setError(null);
    setSuccess(false);
    setUploadProgress(0);
  };

  return { loading, error, success, upload, reset, uploadProgress };
};
