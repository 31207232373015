import { type EnterpriseOnboardingStatus } from "@mg/schemas/src/commons";
import { useQuery } from "@tanstack/react-query";

import { getContacts } from "../../services/contacts";

export function useContacts(types: EnterpriseOnboardingStatus[]) {
  const query = useQuery({
    queryKey: ["contacts", types.join(", ")],
    queryFn: () => getContacts(types),
    enabled: types != null,
  });

  return query;
}
