import { ButtonNew, Card, Icon, Typography } from "@mg/dali/src";
import {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  MyNetworkBody,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  MyNetworkResponse,
  type MyNetworkProfile,
} from "@mg/schemas/src/christo/myNetwork";
import { EmailStatus } from "@mg/schemas/src/commons";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { useMutation } from "@tanstack/react-query";
// eslint-disable-next-line import/named
import { Link, useSearch } from "@tanstack/react-router";
import cx from "classnames";
import { useState } from "react";

import { AvatarWithInitials } from "../../../components/AvatarWithInitials";
import { LabeledMappedChips } from "../../../components/LabeledMappedChips";
import { TooltipAvatar } from "../../../components/TooltipAvatar";
import { setWorkedWith } from "../../../services/workedWith";
import {
  SOCIAL_LINKS_MAP,
  SPECIALTY_ICON_MAP,
  domainRegex,
} from "../../../utils/constants";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import { type MatchedTerms, highlightWords } from "../../../utils/search";
import { setNetwork } from "../../../utils/slices/network";
import { ensureFullUrl } from "../../../utils/validation";
import { newMessageRoute } from "../../new-message/route";
import { aboutMeEditRoute } from "../../userProfile/routes/AboutMe/editRoute";

export function AboutMe(
  props: MyNetworkProfile & {
    onMessageClick?(): void;
    isUser?: boolean;
    matchedTerms: MatchedTerms;
  },
) {
  const search = useSearch({ strict: false });
  const network = useAppSelector((state) => state.network.value);

  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.auth.value);

  const workedWithMutation = useMutation({
    mutationKey: ["worked-with", props._id],
    mutationFn: setWorkedWith,
    onSuccess: (data) => {
      if (!network) return true;
      const newNetwork = {
        ...network,
        all: {
          ...network.all,
          results: [...network.all.results],
        },
        recommendations: {
          ...network.recommendations,
          results: [...network.recommendations.results],
        },
      };
      const allIndex = newNetwork.all.results.findIndex(
        (c) => c._id === props._id,
      );
      const resultsIndex = newNetwork.recommendations.results.findIndex(
        (c) => c._id === props._id,
      );
      if (allIndex !== -1) {
        const workedWith = data.hasWorkedWith
          ? [
              ...(newNetwork.all.results[allIndex].workedWith ?? []),
              {
                _id: user?.userID as string,
                name: user?.name,
                avatar: user?.avatar,
              },
            ]
          : newNetwork.all.results[allIndex].workedWith?.filter(
              (c) => c._id !== user?.userID,
            );

        newNetwork.all.results[allIndex] = {
          ...newNetwork.all.results[allIndex],
          hasWorkedWith: data.hasWorkedWith,
          workedWith,
        };
      } else if (resultsIndex !== -1) {
        const workedWith = data.hasWorkedWith
          ? [
              ...(newNetwork.recommendations.results[allIndex].workedWith ??
                []),
              {
                _id: user?.userID as string,
                name: user?.name,
                avatar: user?.avatar,
              },
            ]
          : newNetwork.recommendations.results[allIndex].workedWith?.filter(
              (c) => c._id !== user?.userID,
            );

        newNetwork.recommendations.results[resultsIndex] = {
          ...newNetwork.recommendations.results[resultsIndex],
          hasWorkedWith: data.hasWorkedWith,
          workedWith,
        };
      }
      dispatch(setNetwork(newNetwork));
    },
  });

  const [open, setOpen] = useState(false);

  const isAdmin = user?.permissions?.includes("enterprise-admin") ?? false;

  const displayName =
    props.type === "agency" ? props.name : props.name.split(" ")[0];
  const title = props.title || props.specialties?.[0];
  const isWorkedWithChecked = workedWithMutation.data
    ? workedWithMutation.data?.hasWorkedWith
    : props.hasWorkedWith;
  const count = workedWithMutation.data?.count ?? props.workedWith?.length ?? 0;
  const workedWith = props.workedWith ?? [];
  const bio = props.bio != null ? props.bio : "No bio available";

  function renderWorkedWith() {
    if (props.hasWorkedWith) {
      return (
        <>
          <Typography
            size="base"
            weight="medium"
            data-testid="worked-with-header"
          >
            Worked with you{" "}
            {count - 1 === 0 ? null : `and ${count - 1} colleagues`}
          </Typography>

          <section
            className="flex flex-wrap gap-2"
            data-testid="worked-with-avatar"
          >
            {props.workedWith?.map((person) => (
              <TooltipAvatar
                onClick={(e) => {
                  e?.stopPropagation();
                  if (person._id === user?.userID) {
                    workedWithMutation.mutate(props._id);
                  }
                }}
                key={person._id}
                name={
                  person._id === user?.userID
                    ? 'Remove "worked with" relationship'
                    : person.name
                }
                _id={person._id}
                avatar={person.avatar}
              />
            ))}
          </section>
        </>
      );
    }

    if (props.hasWorkedWithEnterprise) {
      return (
        <>
          <Typography
            size="base"
            weight="medium"
            data-testid="worked-with-header"
          >
            Worked with {count} colleague{count !== 1 ? "s" : null}
          </Typography>

          <div className="grid gap-4">
            <section className="flex gap-2" data-testid="worked-with-avatars">
              {workedWith.slice(0, 2).map((w, i) => (
                <TooltipAvatar key={i} {...w} />
              ))}
              {workedWith[2] != null ? (
                <TooltipAvatar
                  {...workedWith[2]}
                  overlayCount={
                    workedWith.length === 3 ? 0 : workedWith.length - 2
                  }
                  content={workedWith.slice(2).map((c) => c.name)}
                />
              ) : null}
            </section>

            <button
              onClick={(e) => {
                e.stopPropagation();
                workedWithMutation.mutate(props._id);
              }}
              className={cx(
                "flex items-center justify-center gap-2 truncate rounded-full bg-carbon-50 px-3 py-1.5 text-base-black shadow-md transition-colors hover:bg-carbon-100 active:bg-carbon-200",
                {
                  "opacity-50": workedWithMutation.isPending,
                  "bg-malachite-300 hover:bg-malachite-400":
                    isWorkedWithChecked,
                },
              )}
              data-testid="worked-with-cta"
            >
              <Icon.Handshake size={28} />
              <Typography
                weight="medium"
                className="truncate"
                data-testid="worked-with-header"
              >
                I worked with {displayName}
              </Typography>
            </button>
          </div>
        </>
      );
    }

    return (
      <button
        onClick={(e) => {
          e.stopPropagation();
          workedWithMutation.mutate(props._id);
        }}
        className={cx(
          "flex items-center justify-center gap-2 truncate rounded-full bg-carbon-50 px-3 py-1.5 text-base-black shadow-md transition-colors hover:bg-carbon-100 active:bg-carbon-200",
          {
            "opacity-50": workedWithMutation.isPending,
            "bg-malachite-300 hover:bg-malachite-400": isWorkedWithChecked,
          },
        )}
        data-testid="worked-with-cta"
      >
        <Icon.Handshake size={28} />
        <Typography weight="medium" data-testid="worked-with-header">
          I worked with {displayName}
        </Typography>
      </button>
    );
  }

  return (
    <Card
      size="sm"
      type="outlined"
      className="relative inline-grid w-full gap-6 rounded-2xl"
      uninteractive
      data-testid="portfolio-personal-info"
    >
      <div className="flex items-start justify-between gap-4 truncate">
        <div className="flex w-full flex-1 gap-4">
          <AvatarWithInitials
            size={16}
            name={props.name}
            avatar={props.avatar}
            hasWorkedWith={props.hasWorkedWith}
          />

          <section className="flex flex-1 flex-col gap-1 truncate">
            <Typography
              as="h2"
              size="2xl"
              weight="medium"
              className={cx("truncate text-base-black", {
                "bg-malachite-100": props.matchedTerms["name"],
              })}
              data-testid="person-name"
            >
              {props.name}
              <Typography
                size="sm"
                className={cx("inline text-carbon-600", {
                  hidden: user?.userID !== props._id,
                })}
              >
                &nbsp;&nbsp;(you)
              </Typography>
            </Typography>

            <div
              className={cx("flex items-center gap-2", {
                hidden: !title,
              })}
              data-testid="person-role"
            >
              <Typography
                className={cx("text-carbon-600", {
                  hidden: !title,
                  "bg-malachite-100": props.matchedTerms["title"],
                })}
              >
                {title}
              </Typography>
            </div>
          </section>
        </div>
      </div>

      <Link
        to={newMessageRoute.to}
        search={{ selections: [props._id] }}
        className={cx("flex-1", {
          hidden: user?.userID === props._id || user?.role === "guest",
        })}
        disabled={props.emailStatus === EmailStatus.INVALID}
        data-testid="person-message-cta"
      >
        <AlertDialog.Root open={open} onOpenChange={setOpen}>
          <AlertDialog.Trigger asChild>
            <ButtonNew
              size="sm"
              className="w-full rounded-full [&>span]:justify-center"
              onClick={(e) => {
                if (props.emailStatus === EmailStatus.INVALID) {
                  e.preventDefault();
                  e.stopPropagation();
                  setOpen(true);
                }
              }}
            >
              <Icon.ChatCircleText />
              Message
            </ButtonNew>
          </AlertDialog.Trigger>
          <AlertDialog.Portal>
            <AlertDialog.Overlay className="fixed inset-0 z-40 bg-base-black/50 data-[state=open]:animate-fade-in" />
            <AlertDialog.Content className="fixed left-1/2 top-1/2 z-[41] grid max-h-[85vh] w-[90vw] max-w-lg -translate-x-1/2 -translate-y-1/2 gap-4 rounded-lg bg-base-white p-6 text-base-black shadow-2xl focus:outline-none data-[state=open]:animate-dialog-content-show">
              <AlertDialog.Title className="">
                <Typography weight="medium" className="text-xl md:text-2xl">
                  Oops!
                </Typography>
              </AlertDialog.Title>
              <AlertDialog.Description asChild>
                <Typography as="span" size="base" weight="normal" className="">
                  We do not have a valid email address for this creative, so it
                  is not possible to send them messages at the moment.{" "}
                  {isAdmin
                    ? "You can edit their profile to add an email."
                    : null}
                </Typography>
              </AlertDialog.Description>
              <div className="flex items-center gap-4">
                <AlertDialog.Cancel asChild>
                  <ButtonNew variant="outlined">Cancel</ButtonNew>
                </AlertDialog.Cancel>

                <Link
                  to={aboutMeEditRoute.to}
                  // @ts-expect-error TS2339: incorrect type inheritance
                  search={{ creativeId: search.port }}
                  disabled={!isAdmin}
                  className={cx({ hidden: !isAdmin })}
                >
                  <AlertDialog.Action asChild>
                    <ButtonNew>Edit profile</ButtonNew>
                  </AlertDialog.Action>
                </Link>
              </div>
            </AlertDialog.Content>
          </AlertDialog.Portal>
        </AlertDialog.Root>
      </Link>

      <Link
        to={aboutMeEditRoute.to}
        // @ts-expect-error TS2339: incorrect type inheritance
        search={{ creativeId: search.port }}
        disabled={!isAdmin}
        className={cx({ hidden: !isAdmin })}
      >
        <ButtonNew
          theme="tertiary"
          size="sm"
          className="w-full rounded-full [&>span]:justify-center"
        >
          Edit {displayName}&apos;s profile
        </ButtonNew>
      </Link>

      <div
        className={cx("grid gap-4", {
          // @ts-expect-error TS2339: incorrect type from BE
          hidden: props.type === "pipeline",
        })}
      >
        <article className="grid w-full gap-2">{renderWorkedWith()}</article>
        <article
          className={cx("grid flex-1 auto-rows-max gap-2", {
            hidden:
              props.whatIMake == null ||
              (props.whatIMake != null && props.whatIMake.length === 0),
          })}
        >
          <Typography
            as="h3"
            size="xl"
            weight="medium"
            className="text-carbon-900"
          >
            Top Skills
          </Typography>
          {props.whatIMake != null &&
            props.whatIMake.map((s, i) => (
              <div key={`.${i}.${s}`}>
                <div className="flex">
                  <Icon.IconContext.Provider value={{ size: 24 }}>
                    <div
                      className={cx(
                        "group flex flex-1 items-center justify-between",
                        {
                          "bg-malachite-100": props.matchedTerms[
                            "specialties"
                          ]?.find((spec) => spec.value === s.specialty),
                        },
                      )}
                    >
                      <Typography
                        size="lg"
                        className="inline-flex items-center gap-4 text-left text-base-black"
                      >
                        {SPECIALTY_ICON_MAP[s.specialty]}
                        {s.specialty}
                      </Typography>
                    </div>
                  </Icon.IconContext.Provider>
                </div>
                <LabeledMappedChips
                  title=""
                  values={s.deliverables}
                  hideWhenEmpty
                  searchChips
                  minShowValue={2}
                  // @ts-expect-error TS2322: incorrect type inheritance
                  matchedTerms={props.matchedTerms["deliverables"]}
                />
              </div>
            ))}
        </article>
        {props.matchedTerms?.skills?.length > 0 && (
          <LabeledMappedChips
            title="More Skills"
            values={props.skills}
            hideWhenEmpty
            searchChips
            minShowValue={2}
            // @ts-expect-error TS2322: incorrect type inheritance
            matchedTerms={props.matchedTerms["skills"]}
          />
        )}
        <LabeledMappedChips
          title="Brands"
          values={props.brands}
          hideWhenEmpty
          searchChips
          minShowValue={2}
          // @ts-expect-error TS2322: incorrect type inheritance
          matchedTerms={props.matchedTerms["brands"]}
        />
        {props.matchedTerms?.industries?.length > 0 && (
          <LabeledMappedChips
            title="Industries"
            values={props.industries}
            hideWhenEmpty
            searchChips
            minShowValue={2}
            // @ts-expect-error TS2322: incorrect type inheritance
            matchedTerms={props.matchedTerms.industries}
          />
        )}
        <article className="grid w-full gap-2" data-testid="person-bio">
          <Typography size="xl" weight="medium" className="text-carbon-900">
            Bio
          </Typography>
          <Typography>
            {props.matchedTerms["bio"]?.length
              ? highlightWords(
                  bio,
                  props.matchedTerms["bio"].map((t) => t.term as string),
                ).map((term, i) =>
                  term.highlight ? (
                    <span
                      key={`${i}.${term.text}`}
                      className="bg-malachite-100"
                    >
                      {term.text}
                    </span>
                  ) : (
                    term.text
                  ),
                )
              : bio}
          </Typography>
        </article>
        <div className="flex w-full justify-between">
          {props.yearsOfExperience != null && props.yearsOfExperience > 0 && (
            <article
              className="grid flex-1 gap-2"
              data-testid="person-experience"
            >
              <Typography size="xl" weight="medium" className="text-carbon-900">
                Experience
              </Typography>
              <Typography size="xl">
                {props.yearsOfExperience} Year
                {props.yearsOfExperience === 1 ? "" : "s"}
              </Typography>
            </article>
          )}
          {([...(props.socialLinks ?? []), props.linkedIn, props.website] ?? [])
            .length > 0 && (
            <article className="grid flex-1 gap-2" data-testid="person-links">
              <Typography size="xl" weight="medium" className="text-carbon-900">
                Links
              </Typography>
              <div className="flex h-max gap-2">
                {[...(props.socialLinks ?? []), props.website, props.linkedIn]
                  .filter((s) => s)
                  ?.map((link) => {
                    const matches = link?.match(domainRegex);

                    if (matches != null && matches.length >= 2) {
                      const domain = matches[1];

                      return (
                        <a
                          key={domain}
                          href={ensureFullUrl(link as string)}
                          target="_blank"
                          rel="noreferrer"
                          data-testid={`link-${domain}`}
                        >
                          <ButtonNew className="h-10">
                            {SOCIAL_LINKS_MAP[
                              domain as keyof typeof SOCIAL_LINKS_MAP
                            ] ?? <Icon.LinkSimple />}
                          </ButtonNew>
                        </a>
                      );
                    } else if (link?.length) {
                      return (
                        <a
                          key={link}
                          href={ensureFullUrl(link)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <ButtonNew className="h-10">
                            <Icon.LinkSimple />
                          </ButtonNew>
                        </a>
                      );
                    }
                    return null;
                  })}
              </div>
            </article>
          )}
        </div>
      </div>
    </Card>
  );
}
