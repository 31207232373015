import { ButtonNew, Card, Icon, Typography } from "@mg/dali/src";
import { type Asset, type Highlight } from "@mg/schemas/src/commons";
import { useNavigate } from "@tanstack/react-router";
import cx from "classnames";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Fragment, useEffect, useReducer, useRef } from "react";

import { Markdown } from "../../../components/Markdown";
import { useAppSelector } from "../../../utils/hooks";
import { assetForUser } from "../../../utils/imageHandler";
import { projectRoute } from "../../profile/project-route";

export function ProjectsSummary() {
  const creative = useAppSelector((state) => state.profile.value);

  if (creative == null) {
    return null;
  }

  if (
    creative.highlights == null ||
    creative.highlights?.length === 0 ||
    // @ts-expect-error TS2367: Incorrect BE response.
    creative.type === "pipeline"
  ) {
    return null;
  }

  return (
    <>
      <Typography weight="medium" className="text-carbon-400">
        Projects
      </Typography>

      <div className="flex h-52 w-full gap-6 overflow-x-auto whitespace-nowrap">
        {creative.highlightsSummaries?.map((summary, i) => (
          <div
            className="grid place-content-start gap-y-1"
            tabIndex={-1}
            key={i}
            data-testid={`project-snippet-${i}`}
          >
            <figure className="relative aspect-video h-40 w-72 cursor-pointer overflow-hidden rounded">
              <img
                src={assetForUser(summary.hero, 280)}
                alt={`Designer ${i + 1}`}
                style={{ width: "100%" }}
                data-testid={`project-${summary.title}-${i}-hero`}
                loading="lazy"
              />
            </figure>
            <Typography
              as="p"
              size="base"
              weight="medium"
              className="text-base-black"
              data-testid={`project-snippet-${i}-title`}
            >
              {summary.title}
            </Typography>
            <Typography
              as="p"
              size="xs"
              weight="normal"
              className="text-carbon-700"
              data-testid={`project-snippet-${i}-client`}
            >
              {summary.role}
            </Typography>
          </div>
        ))}
      </div>
    </>
  );
}

export function Projects() {
  const creative = useAppSelector((state) => state.profile.value);

  if (creative == null) {
    return null;
  }

  if (
    creative.highlights == null ||
    creative.highlights?.length === 0 ||
    // @ts-expect-error TS2367: Incorrect BE response.
    creative.type === "pipeline"
  ) {
    return null;
  }

  return (
    <div className="grid gap-6">
      {creative.highlights?.map((p) => <Project key={p._id} {...p} />)}
    </div>
  );
}

function Project(props: Highlight) {
  const creative = useAppSelector((state) => state.profile.value);
  const [expanded, toggleExpansion] = useReducer((x) => !x, false);
  const [showButton, setShowButton] = useReducer((x) => !x, false);
  const textRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (textRef.current) {
      const style = window.getComputedStyle(textRef.current);
      const lineHeight = parseFloat(style.lineHeight);
      const lines = textRef.current.scrollHeight / lineHeight;
      if (lines > 4) {
        setShowButton();
      }
    }
  }, [props.description]);

  function getEmbedURL(url: string) {
    let embedUrl = "";

    // Use URL constructor to parse the url
    const parsedUrl = new URL(url);

    if (parsedUrl.hostname.includes("youtube")) {
      // Check if the URL is already an embed URL
      if (parsedUrl.pathname.includes("embed")) {
        embedUrl = url;
      } else {
        const videoId = parsedUrl.searchParams.get("v");
        embedUrl = `https://www.youtube.com/embed/${videoId}`;
      }
    } else if (parsedUrl.hostname.includes("vimeo")) {
      // Check if the URL is already an embed URL
      if (parsedUrl.pathname.includes("video")) {
        embedUrl = url;
      } else {
        const videoId = parsedUrl.pathname.split("/")[1];
        embedUrl = `https://player.vimeo.com/video/${videoId}`;
      }
    } else {
      console.error("Invalid URL: The URL should be a YouTube or Vimeo URL.");
    }

    return embedUrl;
  }

  function renderAsset(assets?: Partial<Asset>[]) {
    if (assets == null || assets.length === 0) {
      return null;
    }
    const a = assets[0];

    if (a.type === "image") {
      return (
        <>
          <link rel="preload" as="image" href={assetForUser(a.source, 1280)} />
          <img
            src={assetForUser(a.source, 1280)}
            alt={`Designer ${a.source}`}
            style={{ width: "100%" }}
          />
        </>
      );
    } else if (a.type === "video") {
      return (
        <>
          <div className="relative w-full pt-[56.25%]">
            <iframe
              src={getEmbedURL(a.source ?? "")}
              width="640"
              height="360"
              className="absolute left-0 top-0 size-full"
              title={`Designer video ${a.source}`}
            />
          </div>
        </>
      );
    }
  }

  return (
    <Card
      size="sm"
      type="outlined"
      className="relative grid w-full cursor-pointer gap-8 rounded-2xl"
      onClick={() =>
        navigate({
          to: projectRoute.to,
          params: {
            projectId: props._id as string,
            profileId: creative?._id as string,
          },
        })
      }
    >
      <div>
        <Typography size="2xl" weight="bold" className="mb-4">
          {props.title}
        </Typography>
        {renderAsset(props.assets)}
      </div>

      <div className="grid gap-8 md:grid-cols-2">
        <div className="grid auto-rows-max gap-1">
          <Typography weight="medium" className="text-carbon-400">
            What I Did
          </Typography>

          <div
            className={cx({ "line-clamp-4": !expanded })}
            data-testid="bio-description-body"
            ref={textRef}
          >
            <Markdown>
              {props.description ??
                "There's no description for this project just yet..."}
            </Markdown>
          </div>
          <ButtonNew
            variant="ghost"
            theme="primary"
            className={cx("max-w-max", { hidden: !showButton })}
            size="xs"
            onClick={(e) => {
              e.stopPropagation();
              toggleExpansion();
            }}
            data-testid={
              expanded
                ? "what-i-did-less-details-btn"
                : "what-i-did-more-details-btn"
            }
          >
            {expanded ? <Icon.MinusCircle /> : <Icon.PlusCircle />}
            Read {expanded ? "Less" : "More"}
          </ButtonNew>
        </div>
        <div className="grid auto-rows-min gap-4">
          {props.company && (
            <section className="grid gap-1">
              <Typography weight="medium" className="text-carbon-400">
                Company
              </Typography>
              <Typography size="xl" weight="medium" className="text-base-black">
                {props.company}
              </Typography>
            </section>
          )}

          {props.role && (
            <section className="grid gap-1">
              <Typography weight="medium" className="text-carbon-400">
                My Role
              </Typography>
              <Typography size="xl" weight="medium" className="text-base-black">
                {props.role}
              </Typography>
            </section>
          )}
        </div>
      </div>
    </Card>
  );
}
