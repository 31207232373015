import { Icon, Input, Typography } from "@mg/dali/src";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import isEmail from "validator/lib/isEmail";
import isURL from "validator/lib/isURL";

import { useUI } from "../contexts/ui";
import { sendInvitations } from "../services/network";

export function InviteCreativeCard() {
  const { notify } = useUI();
  const inviteMutation = useMutation({
    mutationKey: ["invite"],
    mutationFn: sendInvitations,
    onSuccess() {
      notify({
        variant: "success",
        title: "Sent 1 invitation",
        message:
          "This could take 24-48 hours before they appear here in your network.",
      });
    },
  });

  const [value, setValue] = useState("");

  function isValid() {
    return isEmail(value) || isURL(value);
  }

  function handleSubmit() {
    inviteMutation
      .mutateAsync({ invites: [{ linkedinOrEmail: value }] })
      .then(() => {
        setValue("");
      });
  }

  return (
    <article className="grid gap-4 rounded-2xl bg-base-white p-4 shadow-lg">
      <Icon.UserCirclePlus size={40} className="mx-auto" />

      <Typography size="lg" weight="bold" className="text-center">
        Invite a creative
      </Typography>

      <Input
        size="sm"
        label="Email, website, or LinkedIn URL*"
        value={value}
        onInput={({ target }) => setValue((target as HTMLInputElement).value)}
      />

      <button
        onClick={() => handleSubmit()}
        disabled={inviteMutation.isPending || !isValid()}
        className="flex items-center justify-center gap-2 rounded-full bg-vermillion-700 px-3 py-2 text-base-white shadow-md transition-all hover:bg-vermillion-600 active:bg-vermillion-800 disabled:opacity-30"
      >
        <Icon.PlusCircle />
        <Typography as="span" weight="medium">
          Invite Creative
        </Typography>
      </button>
    </article>
  );
}
