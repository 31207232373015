import cx from "classnames";
import React, { forwardRef } from "react";
import { type HTMLAttributes, type Ref } from "react";
import { twMerge } from "tailwind-merge";

export type SidebarProps = HTMLAttributes<HTMLDivElement>;

export const Sidebar = forwardRef(
  (props: SidebarProps, ref: Ref<HTMLDivElement>) => {
    const { className: hash, ...rest } = props;

    const baseStyles = [
      "dali-w-[250px]",
      "dali-h-screen",
      "dali-p-6",
      "dali-relative",
      "dali-flex",
      "dali-flex-col",
      "dali-gap-16",
      "dali-overflow-hidden",
    ];
    const classes = twMerge(cx("mg-sidebar", baseStyles, hash));

    return <aside ref={ref} className={classes} {...rest} />;
  },
);

Sidebar.displayName = "Sidebar";
