import { cva, cx } from "class-variance-authority";
import React, {
  type ReactNode,
  useCallback,
  useEffect,
  useState,
  type HTMLAttributes,
} from "react";
import { extendTailwindMerge } from "tailwind-merge";

import { Typography } from "../foundation";
import Icon from "../foundation/icons";

const twMerge = extendTailwindMerge({ prefix: "dali-" });

export interface SnackbarProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * Whether the snackbar should dismiss after a timeout
   */
  autoDismiss?: boolean;
  /**
   * Callback for when the Snackbar has opened
   */
  onOpen?(evt: CustomEvent<{ action: "open" }>): void;
  /**
   * Callback for when the Snackbar has closed
   */
  onClose?(evt: CustomEvent<{ action: "close" }>): void;
  /**
   * Whether the Snackbar is open or not
   */
  open?: boolean;
  /**
   * Sets the title of the Snackbar
   */
  title?: string;
  /**
   * Sets the duration the Snackbar should remain visible for
   */
  timeout?: number;
  /**
   * Optionally, Sets the label of the Snackbar
   */
  message?: string;
  /**
   * Icon preceding title
   */
  leadingIcon?: ReactNode | false;
  /**
   * Icon preceding title
   */
  actionButtons?: ReactNode;
  /**
   * variants of the Snackbar
   */
  variant?: "success" | "error" | "default";
}

const snackbarStyles = cva(
  [
    "dali-group dali-flex dali-p-4 dali-rounded-lg dali-shadow-xl dali-fixed",
    "dali-w-[320px] md:dali-w-[640px] dali-top-8",
    "dali-right-0 dali-z-20",
    "dali-transition-transform",
  ],
  {
    variants: {
      open: {
        true: ["-dali-translate-x-8"],
        false: ["dali-translate-x-[672px]"],
      },
      variant: {
        success: ["dali-bg-malachite-200"],
        error: ["dali-bg-cadmium-200"],
        default: ["dali-bg-carbon-50"],
      },
    },
    compoundVariants: [{}],
  },
);

// This needs to be defined outside of the component rather than inline as a
// default parameter for onOpen and onClose in order to avoid unnecessary
// re-renders due to the default parameter being a new reference on every
// render.
const noop = () => {
  /* noop */
};

export function Snackbar({
  autoDismiss = true,
  onOpen = noop,
  onClose = noop,
  open = false,
  title = "",
  timeout = 3000,
  message = "",
  leadingIcon,
  actionButtons,
  variant = "default",
  ...rest
}: SnackbarProps) {
  const { className: hash, ...pass } = rest;
  const [isOpen, setIsOpen] = useState(false);

  const SNACKBAR_ANIMATE_DURATION = 300;

  const defaultLeadingIconMap = {
    default: (
      <Icon.LightbulbFilament size={24} color="rgb(var(--base-black))" />
    ),
    error: <Icon.Prohibit size={24} color="rgb(var(--cadmium-900))" />,
    success: <Icon.CheckCircle size={24} color="rgb(var(--base-black))" />,
  };

  const chosenLeadingIcon =
    leadingIcon === undefined ? defaultLeadingIconMap[variant] : leadingIcon;

  const handleOpen = useCallback(() => {
    setIsOpen(true);

    if (onOpen) {
      onOpen(new CustomEvent("onOpen", { detail: { action: "open" } }));
    }
  }, [onOpen]);

  const handleClose = useCallback(() => {
    if (!open) {
      return;
    }

    setIsOpen(false);
    if (onClose) {
      onClose(new CustomEvent("onClose", { detail: { action: "close" } }));
    }
  }, [onClose, open]);

  useEffect(() => {
    if (open) {
      handleOpen();
    } else {
      setIsOpen(false);
      if (onClose) {
        onClose(new CustomEvent("onClose", { detail: { action: "close" } }));
      }
    }
  }, [handleOpen, open, onClose]);

  useEffect(() => {
    let autoDismissTimer: NodeJS.Timeout;
    if (autoDismiss && open) {
      autoDismissTimer = setTimeout(
        handleClose,
        timeout + SNACKBAR_ANIMATE_DURATION,
      );
    }
    return () => clearTimeout(autoDismissTimer);
  }, [handleClose, timeout, autoDismiss, open]);

  return (
    <aside
      className={cx(snackbarStyles({ open: isOpen, variant }), hash)}
      aria-live="assertive"
      aria-atomic
      aria-hidden
      data-testid="snackbar"
      {...pass}
    >
      <div className="dali-flex-1 dali-flex dali-gap-2">
        <div className="dali-mb-3 md:dali-mb-0 md:dali-inline-block">
          {chosenLeadingIcon !== false && chosenLeadingIcon}
        </div>

        <div className="dali-grid dali-flex-1 dali-gap-2">
          <Typography
            className={twMerge(
              cx("dali-text-base-black", {
                "dali-text-cadmium-900": variant === "error",
              }),
            )}
            weight="bold"
            data-testid="title"
          >
            {title}
          </Typography>
          <Typography className="dali-text-base-black" data-testid="body">
            {message}
          </Typography>

          {actionButtons && (
            <div className="dali-mt-3 dali-flex dali-gap-4 [&>.mg-button]:dali-p-0">
              {actionButtons}
            </div>
          )}
        </div>
      </div>

      <Icon.XCircle
        className={cx(
          "dali-cursor-pointer dali-ml-4 group-hover:dali-opacity-100 dali-transition-opacity",
          {
            "dali-opacity-0": autoDismiss,
          },
        )}
        onClick={handleClose}
        color="rgb(var(--base-black))"
        size={24}
        data-testid="x-btn"
      />
    </aside>
  );
}
