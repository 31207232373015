import { ButtonNew, Icon, Typography } from "@mg/dali/src";
import { useState } from "react";

import { useAppSelector } from "../../../utils/hooks";
import { useSendInvite } from "../../../utils/queries/network";

export function InvitePipeline() {
  const creative = useAppSelector((state) => state.profile.value);
  const mutation = useSendInvite();
  const [open, setOpen] = useState(true);

  if (creative == null) {
    return null;
  }
  // @ts-expect-error TS2367: Incorrect BE response.
  if (creative.type !== "pipeline") {
    return null;
  }

  if (open) {
    return (
      <article className="grid grid-cols-[auto_1fr_auto] gap-2 rounded-lg bg-carbon-50 p-4 shadow-xl">
        <Icon.Lightbulb size={24} />

        <Typography weight="bold">
          This person has not yet been invited to your network.
        </Typography>

        <button onClick={() => setOpen(false)}>
          <Icon.XCircle size={24} />
        </button>

        <div />

        <ButtonNew
          className="w-max"
          onClick={() => {
            // @ts-expect-error TS2367: Incorrect BE Types.
            mutation.mutate([{ email: creative.email }]);
          }}
          disabled={mutation.isPending}
          isLoading={mutation.isPending}
        >
          <Icon.EnvelopeSimple />
          {mutation.isSuccess ? "Invite sent" : "Send Invite"}
        </ButtonNew>
      </article>
    );
  }
}
