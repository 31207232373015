import { Input } from "@mg/dali/src";
import { GetFolderTicketsPaginatedResponse } from "@mg/schemas/src/christo/catalyst";
import { Button, Dialog } from "@radix-ui/themes";
import { useNavigate, useRouter, useSearch } from "@tanstack/react-router";
import cx from "classnames";
import { usePostHog } from "posthog-js/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { DeleteFolderDialog } from "./components/DeleteFolderDialog";
import { DeleteTicketDialog } from "./components/DeleteTicketDialog";
import {
  ShareDialog,
  type ShareDialogProps,
} from "./components/dialogs/ShareDialog";
import { DragAndDropFileUpload } from "./components/DragAndDropUpload";
import { EmptyTicketsView } from "./components/EmptyTicketsView";
import { InfiniteScrollTrigger } from "./components/InfiniteScrollTrigger";
import { TicketGrid } from "./components/TicketGrid";
import { TicketsHeader } from "./components/TicketsHeader";
import { TicketTable } from "./components/TicketTable";
import { RenameDialog } from "./components/UpdateNameDialog";
import { ticketsRoute } from "./route";
import { ticketRoute } from "./routes/ticket";

import { useUI } from "../../contexts/ui";
import { uploadImageAsset, uploadToS3 } from "../../services/upload";
import { errorAnalyticsPayload } from "../../utils/analytics";
import { isPunttGuest } from "../../utils/auth";
import { batch } from "../../utils/batch";
import { DISALLOWED_FILES } from "../../utils/constants";
import { directoryNameToFolderStub, fileToTicketStub } from "../../utils/files";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import {
  useDownloadFolderLink,
  useShareFolder,
  useShareFolderLink,
} from "../../utils/queries/folders";
import {
  useCombineTickets,
  useCreateFolderMutation,
  useDeleteFolderMutation,
  useDeleteTicketMutation,
  useDownloadTicketLink,
  useProjectMutation,
  useEditProjectMutation,
  useRevisionMutation,
  useShareTicket,
  useShareTicketLink,
  useUpdateFolderMutation,
} from "../../utils/queries/projects";
import { queryClient } from "../../utils/queryClient";
import {
  addTemporaryFolders,
  addTemporaryTickets,
  replaceTemporaryFolder,
  replaceTemporaryTicket,
} from "../../utils/slices/punttProjects";

export function Tickets() {
  const dispatch = useAppDispatch();

  const tickets = useAppSelector((state) => state.punttProjects.tickets);
  const folders = useAppSelector((state) => state.punttProjects.folders);
  const more = useAppSelector((state) => state.punttProjects.more);

  const markedFolders = useMemo(
    () =>
      folders.map((f) => ({
        ...f,
        isFolder: true,
      })),
    [folders],
  );
  const mergedFoldersAndTickets = useMemo(
    () =>
      [...markedFolders, ...tickets].toSorted((a, b) => {
        const dateA = new Date(a.updatedAt as string);
        const dateB = new Date(b.updatedAt as string);

        // Sort in descending order (most recent first)
        return dateB.getTime() - dateA.getTime();
      }),
    [markedFolders, tickets],
  );

  const router = useRouter();
  const navigate = useNavigate({ from: ticketsRoute.to });
  const { folderId, view } = useSearch({ from: ticketsRoute.id });
  const posthog = usePostHog();
  const { notify } = useUI();

  const mutation = useDeleteTicketMutation();
  const ticketMutation = useProjectMutation();
  const revisionMutation = useRevisionMutation();
  const createFolderMutation = useCreateFolderMutation();
  const deleteFolderMutation = useDeleteFolderMutation();
  const updateFolderMutation = useUpdateFolderMutation();
  const folderShareLinkMutation = useShareFolderLink();
  const shareFolderMutation = useShareFolder();
  const ticketShareLinkMutation = useShareTicketLink();
  const shareTicketMutation = useShareTicket();
  const folderDownloadMutation = useDownloadFolderLink();
  const ticketDownloadMutation = useDownloadTicketLink();
  const combineTicketsMutation = useCombineTickets();
  const editProjectMutation = useEditProjectMutation();

  const [deletingTicketId, setDeletingTicketId] = useState<string>();
  const [deletingFolderId, setDeletingFolderId] = useState<string>();
  const [shareItem, setShareItem] = useState<ShareDialogProps["item"]>();
  const [shareLink, setShareLink] = useState<string>();
  const [renamingId, setRenamingId] = useState<string>();
  const [isPending, setIsPending] = useState(false);
  const filesUploadRef = useRef<HTMLInputElement>(null);
  const foldersUploadRef = useRef<HTMLInputElement>(null);

  const [isDragging, setIsDragging] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const { drawerOpen } = useAppSelector((state) => state.ui);

  const handleDragLeave = (event: DragEvent) => {
    // If event.relatedTarget is null, it means the drag has left the window
    if (!event.relatedTarget) {
      setIsDragging(false);
      posthog.capture("drag_leave", {
        message: "Drag left the window",
        draggedItems: event.dataTransfer?.items?.length ?? 0,
      });
    }
  };

  const handleUploadFiles = useCallback(
    async (
      files: FileList | File[] | null,
      ticketId?: string,
      revisionName?: string,
      isFolder?: boolean,
    ) => {
      const startTime = performance.now();
      if (files == null || !files.length) {
        console.warn("Attempted to upload files, but none were provided.");
        return;
      }

      const fileList = Array.from(files);

      files = fileList.filter((file) => !DISALLOWED_FILES.includes(file.name));

      const initialFile = fileList[0];
      const ticketStubs = fileList.map(fileToTicketStub);

      if (!ticketId) {
        dispatch(addTemporaryTickets(ticketStubs));
      }

      const payloadsStartTime = performance.now();
      const payloads = (await Promise.all(
        fileList.map((file) => uploadImageAsset(file)),
      )) as FormData[];
      const payloadsDuration = performance.now() - payloadsStartTime;

      if (files.length !== payloads.length) {
        throw new Error("Error getting presigned URL");
      }

      const assetsStartTime = performance.now();
      const assets = await Promise.all(
        payloads.map(async (payload, i) => {
          const url = payload.get("url") as string;
          const key = payload.get("key") as string;
          payload.delete("url");

          await uploadToS3({ url, payload });

          return {
            source: `https://static.puntt.ai/${key}`,
            // @ts-expect-error TS1355: we know the type
            type: (files[i].type.includes("image")
              ? "image"
              : files[i].type.includes("video")
                ? "video"
                : "file") as const,
          };
        }),
      );
      const assetsDuration = performance.now() - assetsStartTime;

      /////
      // CREATE TICKET
      /////
      const createTicketStartTime = performance.now();
      if (ticketId == null || (isFolder && ticketId)) {
        await Promise.all(
          fileList.map(async (f, i) => {
            const payload: { title: string; folder?: string } = {
              title: f.name,
            };
            if (isFolder && ticketId) {
              payload["folder"] = ticketId;
            } else if (folderId != null) {
              payload["folder"] = folderId;
            }

            // Create the ticket
            const ticket = await ticketMutation.mutateAsync(payload);

            dispatch(
              replaceTemporaryTicket({
                temporaryId: ticketStubs[i]._id,
                ticket,
              }),
            );

            // Create the ticket's first revision
            await revisionMutation.mutateAsync({
              ticketId: ticket._id,
              payload: {
                name: "Version 1",
                documentImportType: f.name
                  .slice(f.name.lastIndexOf(".") + 1)
                  .toUpperCase(),
                reviewFiles: [assets[i]],
              },
            });
          }),
        );
      } else {
        // Just add a new revision since we're adding to an existing ticket
        await revisionMutation.mutateAsync({
          ticketId: ticketId,
          payload: {
            name: revisionName ?? "Version 1",
            documentImportType: initialFile.name
              .slice(initialFile.name.lastIndexOf(".") + 1)
              .toLocaleUpperCase(),
            reviewFiles: assets,
          },
        });
      }

      /////
      // CREATE REVISION
      /////
      const createTicketDuration = performance.now() - createTicketStartTime;

      if (filesUploadRef.current) filesUploadRef.current.files = null;

      router.invalidate();

      const totalDuration = performance.now() - startTime;
      posthog.capture("add_file_as_ticket", {
        file_count: files.length,
        ticket_id: ticketId,
        is_folder: isFolder,
        revision_name: revisionName ?? "Version 1",
        file_names: fileList.map((file) => file.name),
        unique_file_types: Array.from(
          new Set(fileList.map((file) => file.type)),
        ),
        first_file_type: files[0]?.type,
        total_duration: totalDuration,
        payloads_duration: payloadsDuration,
        assets_duration: assetsDuration,
        create_ticket_duration: createTicketDuration,
      });
    },
    [router, folderId],
  );

  const handleUploadFolder = useCallback(
    async ({
      directoryName,
      files,
    }: {
      directoryName: string;
      files: FileList | File[];
    }) => {
      const startTime = performance.now();
      const folderStub = directoryNameToFolderStub(directoryName, folderId);

      dispatch(addTemporaryFolders([folderStub]));

      const payloadStartTime = performance.now();
      const payloads = (await Promise.all(
        Array.from(files)
          .filter((f) => !f.name.startsWith("."))
          .map((file) => uploadImageAsset(file)),
      )) as FormData[];
      const payloadsDuration = performance.now() - payloadStartTime;

      const assetsStartTime = performance.now();
      const assets = await Promise.all(
        payloads.map(async (payload, i) => {
          const url = payload?.get("url") as string;
          const key = payload?.get("key") as string;
          payload.delete("url");

          await uploadToS3({ url, payload });

          return {
            source: `https://static.puntt.ai/${key}`,
            // @ts-expect-error TS1355: we know the type
            type: (files[i].type.includes("image")
              ? "image"
              : files[i].type.includes("video")
                ? "video"
                : "file") as const,
          };
        }),
      );
      const assetsDuration = performance.now() - assetsStartTime;

      const createFolderStartTime = performance.now();
      const createdFolder = await createFolderMutation.mutateAsync({
        folder: {
          folderId,
          name: directoryName,
        },
        tickets: [],
      });

      dispatch(
        replaceTemporaryFolder({
          temporaryId: folderStub._id,
          // @ts-expect-error TS2322: Date objects will be automatically
          // converted to strings
          folder: createdFolder.folder,
        }),
      );

      await Promise.all(
        Array.from(files)
          .filter((f) => !f.name.startsWith("."))
          .map(async (f, i) => {
            const ticket = await ticketMutation.mutateAsync({
              title: f.name,
              folder: createdFolder.folder._id,
            });
            const ticketId = ticket._id;

            await revisionMutation.mutateAsync({
              ticketId: ticketId,
              payload: {
                name: "Version 1",
                documentImportType: f.name
                  .slice(f.name.lastIndexOf(".") + 1)
                  .toUpperCase(),
                reviewFiles: [assets[i]],
              },
            });
          }),
      );
      const createFolderDuration = performance.now() - createFolderStartTime;

      if (filesUploadRef.current) filesUploadRef.current.files = null;

      router.invalidate();

      const totalDuration = performance.now() - startTime;
      posthog.capture("folder_upload_attempt", {
        directory_name: directoryName,
        file_count: files.length,
        file_names: Array.from(files).map((file) => file.name),
        unique_file_types: Array.from(
          new Set(Array.from(files).map((file) => file.type)),
        ),
        first_file_type: files[0]?.type,
        total_duration: totalDuration,
        payloads_duration: payloadsDuration,
        assets_duration: assetsDuration,
        create_folder_and_tickets_duration: createFolderDuration,
      });
    },
    [router, folderId],
  );
  const getOrCreateDirectory = (
    droppedData: { directoryName: string | null; files: File[] }[],
    directoryName: string | null,
  ) => {
    let dir = droppedData.find((d) => d.directoryName === directoryName);
    if (!dir) {
      dir = { directoryName, files: [] };
      droppedData.push(dir);
    }
    return dir;
  };

  // Handle reading entries from folders or files
  const readEntry = async (
    entry: any,
    droppedData: { directoryName: string | null; files: File[] }[],
    parentDirName: string | null = null,
  ) => {
    return new Promise<void>((resolve) => {
      if (entry.isFile) {
        entry.file((file: File) => {
          // Skip hidden files
          if (!file.name.startsWith(".")) {
            const dir = getOrCreateDirectory(droppedData, parentDirName);
            dir.files.push(file);
          }
          resolve(); // Resolve once the file is processed
        });
      } else if (entry.isDirectory) {
        const reader = entry.createReader();
        const directoryName = entry.name;

        // Read directory contents until there are none left. Chrome reads entries in batches of up to 100 https://issues.chromium.org/issues/41110876
        let reads = 0;
        (function readEntries() {
          reads++;
          reader.readEntries(async (entries: FileSystemEntry[]) => {
            if (entries.length === 0) {
              if (reads === 1) {
                // Handle empty directories by creating an empty directory entry
                getOrCreateDirectory(droppedData, directoryName);
              } else {
                resolve();
              }
            }

            // Go one level deep, process files, but ignore nested directories
            for (const entry of entries) {
              if (entry.isFile) {
                await readEntry(entry, droppedData, directoryName);
              }
            }
            readEntries();
          });
        })();
      }
    });
  };

  useEffect(() => {
    const handleDragOver = (event: DragEvent) => {
      event.preventDefault();

      if (event.dataTransfer?.items?.length) {
        setIsDragging(true);
      }
    };

    const handleDrop = async (event: DragEvent) => {
      event.preventDefault();

      setIsDragging(false);

      posthog.capture("file_drop", {
        message: "File dropped into the window",
        dragged_items: event.dataTransfer?.items?.length ?? 0,
      });

      const items = event.dataTransfer?.items;
      const droppedData: { directoryName: string | null; files: File[] }[] = [];

      if (items) {
        const promises = [];
        for (const item of Array.from(items)) {
          const entry = item.webkitGetAsEntry();
          if (entry) {
            promises.push(readEntry(entry, droppedData));
          }
        }
        await Promise.all(promises);
      }
      for (const dir of droppedData) {
        if (dir.directoryName != null) {
          handleUploadFolder({
            directoryName: dir.directoryName,
            files: dir.files,
          });
        } else {
          handleUploadFiles(dir.files);
        }
      }
    };

    // Attach drag events globally
    window.addEventListener("dragover", handleDragOver);
    window.addEventListener("dragleave", handleDragLeave);
    window.addEventListener("drop", handleDrop);

    return () => {
      // Cleanup event listeners when the component is unmounted
      window.removeEventListener("dragover", handleDragOver);
      window.removeEventListener("dragleave", handleDragLeave);
      window.removeEventListener("drop", handleDrop);
    };
  }, [handleUploadFiles]);

  function handleOpenTicket(ticketId: string) {
    navigate({
      to: ticketRoute.to,
      params: { ticketId },
      search: { tab: 0 },
    });
  }

  function handleOpenFolder(folderId: string) {
    router.invalidate();
    navigate({
      search(prev) {
        return {
          ...prev,
          folderId,
        };
      },
    });
  }

  function handleShareTicket(ticketId: string, emails: string[]) {
    const startTime = performance.now();
    shareTicketMutation.mutate(
      { ticketId, emails },
      {
        onSuccess() {
          const ticket = tickets.find(
            (t) => t._id === ticketId,
          ) as GetFolderTicketsPaginatedResponse["tickets"][number];
          posthog.capture("emailed_share_link", {
            ticket_id: ticketId,
            emails,
            num_shares: emails.length,
            created_hours_ago: ticket?.createdAt
              ? (Date.now() - new Date(ticket.createdAt).getTime()) /
                (1000 * 60 * 60)
              : undefined,
            num_revisions: ticket.totalRevisions,
            duration: performance.now() - startTime,
          });

          setShareLink(undefined);
          setShareItem(undefined);
          notify({
            title: "Ticket shared",
            message: `Email has been sent to ${emails.length} recipient(s)`,
          });
        },
      },
    );
  }

  function handleShareFolder(folderId: string, emails: string[]) {
    const startTime = performance.now();
    shareFolderMutation.mutate(
      { folderId, emails },
      {
        onSuccess() {
          const folder = folders.find((f) => f._id === folderId);

          posthog.capture("emailed_folder_share_link", {
            folder_id: folderId,
            folder_name: folder?.name,
            emails,
            num_shares: emails.length,
            duration: performance.now() - startTime,
          });

          setShareLink(undefined);
          setShareItem(undefined);
          notify({
            title: "Folder shared",
            message: `Email has been sent to ${emails.length} recipient(s)`,
          });
        },
      },
    );
  }

  function handleDownloadFolder(folderId: string) {
    const startTime = performance.now();
    folderDownloadMutation.mutate(folderId, {
      onSuccess(data) {
        const folder = folders.find((f) => f._id === folderId);

        posthog.capture("download_folder", {
          folder_id: folderId,
          folder_name: folder?.name,
          data_length: data?.url.length,
          request_duration: performance.now() - startTime,
        });
        if (data) {
          window.open(data.url, "_blank");
        }
      },
    });
  }

  function handleDownloadTicket(ticketId: string) {
    const startTime = performance.now();
    ticketDownloadMutation.mutate(ticketId, {
      onSuccess(data) {
        const ticket = tickets.find((t) => t._id === ticketId);

        posthog.capture("download_ticket", {
          ticket_id: ticketId,
          request_duration: performance.now() - startTime,
          created_hours_ago: ticket?.createdAt
            ? (Date.now() - new Date(ticket.createdAt).getTime()) /
              (1000 * 60 * 60)
            : undefined,
          num_revisions: ticket?.totalRevisions,
          data_length: data?.url.length,
        });
        if (data) {
          window.open(data.url, "_blank");
        }
      },
    });
  }

  async function handleDropCards(draggedIds: string[], dropTargetId: string) {
    const dropTarget = mergedFoldersAndTickets.find(
      (t) => t._id === dropTargetId,
    );

    // Move selected tickets/folders to target folder
    if (dropTarget?.isFolder) {
      if (isPunttGuest()) {
        notify({
          title: "Changing file location requires logging in",
          message: "Please log in to move items into a folder.",
        });
        return;
      }

      const startTime = performance.now();
      try {
        await batch(
          draggedIds,
          1,
          async ([dragTargetId]) => {
            const dragTarget = mergedFoldersAndTickets.find(
              (t) => t._id === dragTargetId,
            );
            if (dragTarget?.isFolder) {
              await updateFolderMutation.mutateAsync({
                _id: dragTargetId,
                parentFolder: dropTargetId,
              });
            } else {
              await editProjectMutation.mutateAsync({
                _id: dragTargetId,
                folder: dropTargetId,
              });
            }
          },
          5,
        );
        router.invalidate();
        posthog.capture("tickets_dragged_to_folder", {
          folder_id: dropTargetId,
          num_dropped: draggedIds.length,
          dropped_ids: draggedIds,
          duration_ms: performance.now() - startTime,
        });
      } catch (error) {
        notify({
          title: "Error dragging to folder",
          message: error instanceof Error ? error.message : String(error),
        });
        posthog.capture("tickets_dragged_to_folder_error", {
          folder_id: dropTargetId,
          num_dropped: draggedIds.length,
          dropped_ids: draggedIds,
          duration_ms: performance.now() - startTime,
          ...errorAnalyticsPayload(error),
        });
      }
    }
    // If selectedIds contains exactly one ticket and dropTargetId is also a ticket, combine the selected ticket with the target ticket
    else if (draggedIds.length === 1) {
      const dragTargetId = draggedIds[0];
      const dragTarget = mergedFoldersAndTickets.find(
        (t) => t._id === dragTargetId,
      );
      if (!dragTarget?.isFolder) {
        if (isPunttGuest()) {
          notify({
            title: "Adding a new version requires logging in",
            message: "Please log in to add a new version to a ticket.",
          });
          return;
        }

        const startTime = performance.now();
        await combineTicketsMutation.mutateAsync(
          {
            payload: {
              receivingTicketId: dropTargetId,
              addingTicketId: dragTargetId,
            },
          },
          {
            onSuccess() {
              router.invalidate();
              posthog.capture("combined_tickets", {
                drop_target_id: dropTargetId,
                drag_target_id: dragTargetId,
                duration_ms: performance.now() - startTime,
              });
            },
            onError(error) {
              notify({
                title: "Error adding version to ticket",
                message: error.message,
              });
              posthog.capture("combined_tickets_error", {
                drop_target_id: dropTargetId,
                drag_target_id: dragTargetId,
                duration_ms: performance.now() - startTime,
                ...errorAnalyticsPayload(error),
              });
            },
          },
        );
      }
    }
  }

  function renderTickets() {
    if (mergedFoldersAndTickets.length === 0) {
      return (
        <EmptyTicketsView
          onFilesUpload={() => filesUploadRef.current?.click()}
        />
      );
    }

    switch (view) {
      case "list":
        return (
          <TicketTable
            onDeleteTicket={setDeletingTicketId}
            onTicketClick={handleOpenTicket}
            onFolderClick={handleOpenFolder}
            onDeleteFolder={setDeletingFolderId}
            onRenameFolder={setRenamingId}
            onShareTicket={(ticketId: string) => {
              setShareLink(undefined);
              setShareItem({ id: ticketId, type: "ticket" });
              ticketShareLinkMutation.mutate(ticketId, {
                onSuccess(data) {
                  if (data == null) {
                    throw new Error("An unknown error occurred");
                  }

                  setShareLink(data.url);
                },
              });
            }}
            onShareFolder={(folderId: string) => {
              setShareLink(undefined);
              setShareItem({ id: folderId, type: "folder" });
              folderShareLinkMutation.mutate(folderId, {
                onSuccess(data) {
                  if (data == null) {
                    throw new Error("An unknown error occurred");
                  }

                  setShareLink(data.url);
                },
              });
            }}
            onDownloadFolder={handleDownloadFolder}
            onDownloadTicket={handleDownloadTicket}
          />
        );
      case "grid":
        return (
          <TicketGrid
            onDeleteTicket={setDeletingTicketId}
            onTicketCardClick={handleOpenTicket}
            onFolderCardClick={handleOpenFolder}
            revisionMutation={revisionMutation}
            onDeleteFolder={setDeletingFolderId}
            onRenameFolder={setRenamingId}
            onShareTicket={(ticketId: string) => {
              setShareLink(undefined);
              setShareItem({ id: ticketId, type: "ticket" });
              ticketShareLinkMutation.mutate(ticketId, {
                onSuccess(data) {
                  if (data == null) {
                    throw new Error("An unknown error occurred");
                  }

                  setShareLink(data.url);
                },
              });
            }}
            onShareFolder={(folderId: string) => {
              setShareLink(undefined);
              setShareItem({ id: folderId, type: "folder" });
              folderShareLinkMutation.mutate(folderId, {
                onSuccess(data) {
                  if (data == null) {
                    throw new Error("An unknown error occurred");
                  }

                  setShareLink(data.url);
                },
              });
            }}
            onDownloadFolder={handleDownloadFolder}
            onDownloadTicket={handleDownloadTicket}
            onDropCards={handleDropCards}
          />
        );
      default:
        throw new Error("Unsupported view type");
    }
  }

  return (
    <>
      <Dialog.Root open={isDialogOpen} onOpenChange={setDialogOpen}>
        <Dialog.Content maxWidth="450px">
          <Dialog.Title>New Folder</Dialog.Title>

          <form
            onSubmit={(e) => {
              e.preventDefault();

              const form = e.target;
              const formData = new FormData(form as HTMLFormElement);
              const folderName = formData.get("folder-name") as string;

              const stubFolder = directoryNameToFolderStub(
                folderName,
                folderId,
              );

              dispatch(addTemporaryFolders([stubFolder]));

              createFolderMutation.mutate(
                {
                  folder: {
                    folderId,
                    name: folderName,
                  },
                  tickets: [],
                },
                {
                  onSuccess({ folder }) {
                    dispatch(
                      replaceTemporaryFolder({
                        temporaryId: stubFolder._id,
                        folder,
                      }),
                    );

                    queryClient.invalidateQueries({
                      queryKey: ["folders", "tree"],
                    });
                    router.invalidate();

                    posthog.capture("folder_created", {
                      folder_id: folderId,
                      folder_name: folderName,
                    });
                  },
                },
              );
              setDialogOpen(false);
            }}
            className="grid gap-4"
          >
            <Input size="sm" placeholder="Folder Name" name="folder-name" />

            <div className="flex items-center justify-end gap-4">
              <Dialog.Close>
                <Button variant="soft">Cancel</Button>
              </Dialog.Close>
              <Button type="submit">Create Folder</Button>
            </div>
          </form>
        </Dialog.Content>
      </Dialog.Root>
      <ShareDialog
        item={shareItem}
        shareLink={shareLink}
        shareLinkPending={
          folderShareLinkMutation.isPending || ticketShareLinkMutation.isPending
        }
        isPending={
          shareFolderMutation.isPending || shareTicketMutation.isPending
        }
        onCancel={() => setShareItem(undefined)}
        onShare={(emails: string[]) => {
          if (shareItem == null) {
            throw new Error("No item to share");
          }

          switch (shareItem.type) {
            case "folder":
              return handleShareFolder(shareItem.id, emails);
            case "ticket":
              return handleShareTicket(shareItem.id, emails);
            default:
              throw new Error("Unknown share item type");
          }
        }}
      />
      <RenameDialog
        isPending={updateFolderMutation.isPending}
        _id={renamingId}
        onCancel={() => setRenamingId(undefined)}
        onSave={(_id, folderName) => {
          updateFolderMutation.mutate(
            { _id, name: folderName },
            {
              onSuccess() {
                setRenamingId(undefined);
                posthog.capture("folder_renamed", {
                  folder_id: _id,
                  folder_name: folderName,
                });
                return router.invalidate();
              },
            },
          );
        }}
      />
      <DeleteTicketDialog
        isPending={mutation.isPending}
        ticketId={deletingTicketId}
        onCancel={() => setDeletingTicketId(undefined)}
        onDelete={() => {
          if (deletingTicketId == null) {
            throw new Error(
              "The ticket we want to delete cannot have a null or undefined ID",
            );
          }

          mutation.mutate(
            { ticketId: deletingTicketId },
            {
              onSuccess() {
                setDeletingTicketId(undefined);
                posthog.capture("ticket_deleted", {
                  ticket_id: deletingTicketId,
                });
                return router.invalidate();
              },
              onError() {
                window.alert("Unable to delete ticket. Please try again.");
              },
            },
          );
        }}
      />
      <DeleteFolderDialog
        isPending={deleteFolderMutation.isPending}
        folderId={deletingFolderId}
        onCancel={() => setDeletingFolderId(undefined)}
        onDelete={() => {
          if (deletingFolderId == null) {
            throw new Error(
              "The folder we want to delete cannot have a null or undefined ID",
            );
          }

          deleteFolderMutation.mutate(deletingFolderId, {
            onSuccess() {
              if (folderId === deletingFolderId) {
                navigate({
                  search: ({ folderId: _folderId, ...rest }) => rest,
                });
              }
              setDeletingFolderId(undefined);
              posthog.capture("folder_deleted", {
                folder_id: deletingFolderId,
              });
              return router.invalidate();
            },
            onError() {
              window.alert("Unable to delete folder. Please try again.");
            },
          });
        }}
      />
      <article
        className={cx(
          "grid min-h-[calc(100vh-57px)] content-start gap-6 py-6",
          {
            "ml-80": drawerOpen,
          },
        )}
      >
        {/* This is used in both the no tickets view and the "File Upload" option in the "New" dropdown */}
        <input
          type="file"
          ref={filesUploadRef}
          className="hidden"
          multiple
          onChange={({ target }) => handleUploadFiles(target.files)}
        />
        <input
          type="file"
          ref={foldersUploadRef}
          className="hidden"
          // @ts-expect-error TS(2322): We have this type
          webkitdirectory="true"
          // eslint-disable-next-line react/no-unknown-property
          mozdirectory="true"
          // eslint-disable-next-line react/no-unknown-property
          directory="true"
          onChange={(event) => {
            const files = event.target.files;
            if (!files) return;
            const directoryName = files[0].webkitRelativePath
              .split("/")
              .slice(0, -1)
              .join("/");

            handleUploadFolder({ directoryName, files });
          }}
        />
        <TicketsHeader
          isPending={isPending}
          onSearch={() => setIsPending(true)}
          onFilesUpload={() => filesUploadRef.current?.click()}
          onFolderUpload={() => foldersUploadRef.current?.click()}
          onNewFolder={() => setDialogOpen(true)}
          onDeleteFolder={setDeletingFolderId}
          onRenameFolder={setRenamingId}
          onShareFolder={(folderId: string) => {
            setShareLink(undefined);
            setShareItem({ id: folderId, type: "folder" });
            folderShareLinkMutation.mutate(folderId, {
              onSuccess(data) {
                if (data == null) {
                  throw new Error("An unknown error occurred");
                }

                setShareLink(data.url);
              },
            });
          }}
          onDownloadFolder={handleDownloadFolder}
        />

        {renderTickets()}
        <InfiniteScrollTrigger
          lastItemDate={
            mergedFoldersAndTickets[mergedFoldersAndTickets.length - 1]
              ?.updatedAt
          }
          hasMore={more}
        />
        <DragAndDropFileUpload isDragging={isDragging} />
      </article>
    </>
  );
}
