import { Dialog } from "@headlessui/react";
import { ButtonNew, Input, Select, Typography } from "@mg/dali/src";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

import { useUI } from "../contexts/ui";
import { addCreativesToList, createList, getLists } from "../services/lists";
import { type CreativeSelection, useAppSelector } from "../utils/hooks";
import { setLists } from "../utils/slices/lists";
import { store } from "../utils/store";

export function AddListDialog({
  onClose,
  isOpen,
  creatives,
}: {
  onClose: () => void;
  isOpen: boolean;
  creatives?: CreativeSelection[];
}) {
  const { lists } = useAppSelector((state) => state.lists);
  const [creativeListId, setCreativeListId] = useState<string | null>(null);
  const { notify } = useUI();
  const queryClient = useQueryClient();

  const createListMutation = useMutation({
    mutationFn: createList,
  });

  const addCreativesToListMutation = useMutation({
    mutationFn: addCreativesToList,
  });

  const handleCreateList = async () => {
    return createListMutation.mutateAsync(
      {
        title: creativeListId as string,
        creatives: creatives?.map((c) => ({
          _id: c,
        })) as unknown as CreativeSelection[],
      },
      {
        async onSuccess() {
          notify({
            title: "Creative List Saved!",
            message: "",
          });
          queryClient.invalidateQueries({
            queryKey: ["lists"],
            refetchType: "none",
          });

          const lists = await queryClient.fetchQuery({
            queryKey: ["lists"],
            queryFn: getLists,
          });
          store.dispatch(setLists(lists));
          closeDialog();
        },
      },
    );
  };
  const handleAddCreativesToList = () => {
    return addCreativesToListMutation.mutateAsync(
      {
        listId: creativeListId as string,
        payload: creatives as unknown as string[],
      },
      {
        async onSuccess(data) {
          if (
            (creatives && creatives?.length > 1) ||
            !(creatives?.[0] as CreativeSelection)?.name
          ) {
            notify({
              title: `Your creatives have been added to your ${data.title} list!`,
              message: "",
            });
          } else {
            notify({
              title: `${(creatives?.[0] as CreativeSelection).name} has been added to your ${data.title} list!`,
              message: "",
            });
          }
          queryClient.invalidateQueries({
            queryKey: ["lists"],
            refetchType: "none",
          });

          const lists = await queryClient.fetchQuery({
            queryKey: ["lists"],
            queryFn: getLists,
          });

          store.dispatch(setLists(lists));
          closeDialog();
        },
      },
    );
  };

  const handleAddToListFlow = async () => {
    if (!lists?.find((l) => l._id === creativeListId)) {
      return handleCreateList();
    }
    return handleAddCreativesToList();
  };

  const closeDialog = () => {
    setCreativeListId(null);
    onClose();
  };

  return (
    <Dialog
      as="div"
      open={isOpen}
      onClose={closeDialog}
      className="fixed inset-0 z-10"
    >
      <div
        // eslint-disable-next-line tailwindcss/migration-from-tailwind-2
        className="fixed inset-0 bg-carbon-900 bg-opacity-80"
        aria-hidden="true"
      ></div>

      <div className="flex min-h-screen items-center justify-center">
        {/* Dialog panel with an opaque background */}
        <Dialog.Panel className="relative mx-auto grid max-w-xl gap-9 rounded-2xl bg-base-white p-6 shadow-xl md:min-w-[720px]">
          <Typography weight="bold" size="2xl">
            Add to List
          </Typography>

          {!lists?.length ? (
            <Input
              label="New List Name"
              required
              onChange={({ target }) => {
                setCreativeListId(target.value);
              }}
              value={creativeListId ?? ""}
              size="sm"
              placeholder="What would you like to name your list?"
            />
          ) : (
            <Select<false>
              label="Select/Create a List"
              placeholder="Select a list"
              className="text-left"
              options={(lists ?? []).map((l) => ({
                value: l._id,
                label: l.title,
              }))}
              onChange={(values) => {
                setCreativeListId(values.value as string);
              }}
              allowCustom
            />
          )}
          <div className="flex gap-4">
            <ButtonNew
              variant="filled"
              className="max-w-fit"
              onClick={handleAddToListFlow}
              disabled={creativeListId == null}
              isLoading={
                createListMutation.isPending ||
                addCreativesToListMutation.isPending
              }
            >
              {!(lists ?? []).length
                ? "Create New List"
                : creativeListId !== null &&
                    !lists?.find((l) => l._id === creativeListId)
                  ? "Create and Add to List"
                  : "Add to List"}
            </ButtonNew>
            <ButtonNew
              variant="outlined"
              className="max-w-fit"
              onClick={closeDialog}
            >
              Cancel
            </ButtonNew>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
