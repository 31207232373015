import cx from "classnames";
import React, { forwardRef } from "react";
import { type HTMLAttributes, type ReactNode, type Ref } from "react";
import { twMerge } from "tailwind-merge";

import { ReactComponent as Loader } from "./assets/loader.svg";

type ButtonType = JSX.IntrinsicElements["button"]["type"];

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  /**
   * Optionally, the button's role type; defaults to "button"
   */
  type?: ButtonType;
  /**
   * Optionally, sets the theme of the button; defaults to "primary"
   */
  theme: "primary" | "secondary";
  /**
   * Optionally, whether to disable the button
   */
  disabled?: boolean;
  /**
   * Optionally, set the size of the button; defaults to "md"
   */
  size?: "xs" | "sm" | "md" | "lg";
  /**
   * Optionally, set the variant of the button; defaults to "filled"
   */
  variant?: "filled" | "outlined" | "text";
  /**
   * The label and any icons
   */
  children: ReactNode;
  /**
   * Optionally, put the button into a loading state. For use when
   * the button click calls an API and we want to wait for the
   * response. Should be used in addition to the `disabled` prop
   */
  isLoading?: boolean;
}

export const Button = forwardRef(
  (
    {
      children,
      type = "button",
      theme = "primary",
      disabled = false,
      size = "md",
      variant = "filled",
      isLoading = false,
      ...rest
    }: ButtonProps,
    ref: Ref<HTMLButtonElement>,
  ) => {
    const baseStyles = [
      "dali-inline-flex",
      "dali-items-center",
      "dali-justify-center",
      "dali-gap-2",
      "dali-rounded",
      "dali-border-2",
      "focus:dali-ring-2",
      "dali-ring-offset-2",
      "[&>svg]:dali-h-4",
      "[&>svg]:dali-w-4",
      "[&_path]:dali-stroke-current",
      "dali-transition-colors",
    ];
    const sizes: Record<NonNullable<ButtonProps["size"]>, string[]> = {
      lg: ["dali-py-3.5", "dali-px-6", "dali-font-text-medium", "dali-text-lg"],
      md: [
        "dali-py-2.5",
        "dali-px-3.5",
        "dali-font-text-medium",
        "dali-text-base",
      ],
      sm: ["dali-py-1.5", "dali-px-3", "dali-font-text-medium", "dali-text-sm"],
      xs: [
        "dali-gap-1",
        "dali-py-1",
        "dali-px-1.5",
        "dali-text-[10px]",
        "dali-leading-[12px]",
        "dali-font-text-medium",
      ],
    };
    const variants: Record<
      NonNullable<ButtonProps["theme"]>,
      Record<NonNullable<ButtonProps["variant"]>, string[]>
    > = {
      primary: {
        filled: [
          "dali-text-primary-900",
          "dali-bg-primary-200",
          "dali-border-primary-200",
          "focus:dali-ring-primary-300",
          "hover:dali-bg-primary-400",
          "hover:dali-border-primary-400",
          "active:dali-bg-primary-300",
          "active:dali-border-primary-300",
          "disabled:dali-text-gray-400",
          "disabled:dali-bg-primary-100",
          "disabled:dali-border-primary-100",
        ],
        outlined: [
          "dali-border-primary-900",
          "dali-text-primary-900",
          "focus:dali-ring-primary-900",
          "focus:dali-border-transparent",
          "hover:dali-border-primary-800",
          "hover:dali-text-primary-800",
          "active:dali-border-transparent",
          "disabled:dali-text-primary-300",
          "disabled:dali-border-primary-300",
        ],
        text: [
          "dali-border-transparent",
          "dali-text-primary-900",
          "hover:dali-text-primary-800",
          "active:dali-text-primary-900",
          "disabled:dali-text-primary-300",
        ],
      },
      secondary: {
        filled: [
          "dali-text-base-white",
          "dali-bg-gray-900",
          "dali-border-gray-900",
          "hover:dali-bg-gray-700",
          "hover:dali-border-gray-700",
          "active:dali-bg-gray-900",
          "active:dali-border-gray-900",
          "focus:dali-ring-gray-900",
          "disabled:dali-bg-gray-300",
          "disabled:dali-border-gray-300",
        ],
        outlined: [
          "dali-text-gray-900",
          "dali-border-gray-900",
          "hover:dali-text-gray-700",
          "hover:dali-border-gray-700",
          "active:dali-text-gray-900",
          "active:dali-border-transparent",
          "focus:dali-ring-gray-900",
          "focus:dali-border-transparent",
          "disabled:dali-text-gray-300",
          "disabled:dali-border-gray-300",
        ],
        text: [
          "dali-text-gray-900",
          "dali-border-transparent",
          "hover:dali-text-gray-700",
          "active:dali-text-gray-900",
          "disabled:dali-text-gray-300",
        ],
      },
    };

    const { className: hash, ...pass } = rest;
    const classes = twMerge(
      cx("mg-button", baseStyles, sizes[size], variants[theme][variant], hash),
    );
    const buttonChildrenClasses = twMerge(
      cx(
        "dali-inline-flex",
        "dali-items-center",
        "dali-justify-center",
        "dali-gap-2",
        {
          "dali-opacity-0": isLoading,
        },
      ),
    );

    return (
      <button
        ref={ref}
        className={classes}
        disabled={disabled}
        // eslint-disable-next-line react/button-has-type
        type={type}
        {...pass}
      >
        <Loader
          className={cx("dali-absolute", { "dali-opacity-0": !isLoading })}
        />
        <div className={buttonChildrenClasses}>{children}</div>
      </button>
    );
  },
);

Button.displayName = "Button";
