import { EnterpriseProfileType } from "@mg/schemas/src/commons";
import { type Editor, createShapeId } from "@tldraw/tldraw";

import { useAppSelector } from "../hooks";
import { useGetUsers } from "../queries/users";

export function useGetVisibleUsers(
  dataOnly: false,
): ReturnType<typeof useGetUsers>;
export function useGetVisibleUsers(
  dataOnly?: true,
): ReturnType<typeof useGetUsers>["data"];
export function useGetVisibleUsers(dataOnly = true) {
  const user = useAppSelector((state) => state.auth.value);

  const roles = [
    EnterpriseProfileType.ADMIN,
    EnterpriseProfileType.OPS,
    EnterpriseProfileType.CATALYST_CREATIVE,
    EnterpriseProfileType.CATALYST_MARKETING,
    EnterpriseProfileType.CATALYST_REQUESTER,
  ];
  if (user?.role === EnterpriseProfileType.MEANINGFUL_GIGS) {
    roles.push(EnterpriseProfileType.MEANINGFUL_GIGS);
  }

  const mutation = useGetUsers(roles);
  return dataOnly ? mutation?.data : mutation;
}

export const createAndSelectCommentShape = ({
  x,
  y,
  editor,
  originalCoords,
  zoom,
}: {
  x: number;
  y: number;
  editor: Editor;
  originalCoords?: { x?: number; y?: number };
  zoom: number;
}) => {
  const avatarShapeId = createShapeId();
  const commentShapeId = createShapeId();

  editor.createShapes([
    {
      id: avatarShapeId,
      type: "comment-avatar",
      x: originalCoords?.x ?? x,
      y: (originalCoords?.y ?? y) - 25 / zoom,
      props: {
        h: 25,
        w: 25,
      },
      meta: {
        linkedCommentId: commentShapeId,
      },
    },
    {
      id: commentShapeId,
      type: "comment",
      x,
      y: y,
      meta: {
        originalCoords:
          originalCoords?.x != null || originalCoords?.y != null
            ? JSON.stringify(originalCoords)
            : false,
        linkedAvatarId: avatarShapeId,
      },
      props: {
        h: 25,
        w: 300,
      },
    },
  ]);
  editor.setSelectedShapes([avatarShapeId, commentShapeId]);
};
