import { ButtonNew, Icon, Typography } from "@mg/dali/src";
import { useNavigate, useRouter } from "@tanstack/react-router";

import { aboutMeEditRoute } from "../routes/AboutMe/editRoute";
import { myExpertiseEditRoute } from "../routes/MyExpertise/editRoute";
import { myWorkListRoute } from "../routes/MyWork/viewRoute";

export function EditProfileHeader() {
  const navigate = useNavigate();
  const router = useRouter();
  return (
    <div className="flex items-center justify-between border-b border-base-black pb-6">
      <Typography weight="bold" size="2xl">
        Profile
      </Typography>
      <div className="flex items-center gap-2">
        <ButtonNew
          variant="ghost"
          onClick={() => navigate({ to: aboutMeEditRoute.to })}
          active={router.state.location.pathname.includes("about-me")}
        >
          <Icon.User />
          About Me
        </ButtonNew>
        <ButtonNew
          variant="ghost"
          active={router.state.location.pathname.includes("my-expertise")}
          onClick={() => navigate({ to: myExpertiseEditRoute.to })}
        >
          <Icon.Star />
          Expertise
        </ButtonNew>
        <ButtonNew
          variant="ghost"
          onClick={() => navigate({ to: myWorkListRoute.to })}
        >
          <Icon.Briefcase />
          Projects
          <Icon.ArrowUpRight />
        </ButtonNew>
      </div>
    </div>
  );
}
