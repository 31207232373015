import {
  completeOnboardingResponseSchema,
  updateMyNetworkProfileResponseSchema,
} from "@mg/schemas/src/christo/myNetwork";
import {
  type PartialProfile,
  getProfileResponseSchema,
} from "@mg/schemas/src/christo/profile";

import { API_URL } from "../config/env";
import { smartFetch } from "../utils/http";
import { login } from "../utils/slices/auth";
import { store } from "../utils/store";

export async function updateUserProfile(payload: PartialProfile) {
  const json = await smartFetch(`${API_URL}/private/my-network/profile`, {
    method: "PUT",
    body: JSON.stringify(payload),
  });

  const parsedData = updateMyNetworkProfileResponseSchema.parse(json);

  if (payload.creativeId == null) {
    store.dispatch(login({ token: parsedData.token as string }));
  }
  return parsedData;
}

export async function getUserProfile() {
  const json = await smartFetch(`${API_URL}/private/profile`, {
    method: "GET",
  });

  const parsedData = getProfileResponseSchema.parse(json);

  return parsedData;
}

export async function completeOnboarding() {
  const json = await smartFetch(`${API_URL}/private/my-network/onboard`, {
    method: "POST",
  });

  const parsedData = completeOnboardingResponseSchema.parse(json);

  return parsedData;
}
