import {
  type UpdateBriefGuidelinesBody,
  getBriefGuidelinesResponseSchema,
  updateBriefGuidelinesResponseSchema,
} from "@mg/schemas/src/christo/catalyst";

import { API_URL } from "../config/env";
import { smartFetch } from "../utils/http";

export async function getGuidelines() {
  const json = await smartFetch(
    `${API_URL}/private/catalyst/brief-guidelines`,
    {
      method: "GET",
    },
  );

  const parsedData = getBriefGuidelinesResponseSchema.parse(json);
  return parsedData;
}

export async function updateGuidelines(payload: UpdateBriefGuidelinesBody) {
  const json = await smartFetch(
    `${API_URL}/private/catalyst/brief-guidelines`,
    {
      method: "PUT",
      body: JSON.stringify({ ...payload }),
    },
  );

  const parsedData = updateBriefGuidelinesResponseSchema.parse(json);

  return parsedData;
}
