import { z } from "zod";

import {
  enterpriseProfileTypeSchema,
  highlightSchema,
  notificationSchema,
  referenceSchema,
  specialtyHeroesSchema,
  userSchema,
} from "../commons";

export enum SectionStatus {
  EMPTY = "empty",
  DRAFT = "draft",
  COMPLETED = "completed",
}

export const sectionStatusSchema = z.nativeEnum(SectionStatus);

// REQUIRED FIELDS
const profileRequiredFields = userSchema
  .pick({
    _id: true,
    avatar: true,
    name: true,
    email: true,
    whatsapp: true,
    timezone: true,
    workingHours: true,
    availability: true,
    location: true,
    bio: true,
    yearsExperience: true,
    specialtyHeroes: true,
    tools: true,
    industries: true,
    highlights: true,
  })
  .extend({
    highlights: z.array(highlightSchema).min(2),
    tools: z.array(z.string()).min(1),
    industries: z.array(z.string()).min(1),
    specialtyHeroes: z.array(specialtyHeroesSchema).min(1),
    bio: z.string().min(50),
  })
  .required();

// OPTIONAL FIELDS
const profileOptionalFields = userSchema
  .pick({
    preferredName: true,
    pronoun: true,
    topClients: true,
    linkedIn: true,
    deliverables: true,
    socialLinks: true,
    references: true,
    status: true,
    languages: true,
    softSkills: true,
    employmentHistory: true,
    volunteerExperience: true,
    education: true,
    certifications: true,
    professionalAffiliations: true,
    skills: true,
    specialties: true,
    type: true,
    brands: true,
    title: true,
    website: true,
  })
  .partial()
  .extend({
    experienceAreas: z.array(z.string()).optional(),
    role: enterpriseProfileTypeSchema.optional(),
  });

// PROFILE SCHEMA
export const profileSchema = profileRequiredFields.merge(profileOptionalFields);

export type Profile = z.infer<typeof profileSchema>;

// PARTIAL PROFILE
export const partialProfileSchema = profileSchema.partial().extend({
  highlights: z.array(highlightSchema).optional(),
  tools: z.array(z.string()).optional(),
  industries: z.array(z.string()).optional(),
  specialtyHeroes: z.array(specialtyHeroesSchema).optional(),
  bio: z.string().optional(),
  references: z.array(referenceSchema.partial()).optional(),
  brands: z.array(z.string()).optional(),
  notifications: z.array(notificationSchema).optional(),
  creativeId: z.string().optional(),
});

export type PartialProfile = z.infer<typeof partialProfileSchema>;

// SECTION STATUSES
export const profileStatusSchema = z.object({
  generalInfo: sectionStatusSchema,
  professionalDetails: sectionStatusSchema,
  testimonials: sectionStatusSchema,
  specialtiesAndSkills: sectionStatusSchema,
  tools: sectionStatusSchema,
  industries: sectionStatusSchema,
  softSkills: sectionStatusSchema,
  projects: sectionStatusSchema,
  enterpriseData: sectionStatusSchema,
  whatIMake: sectionStatusSchema,
});

export type ProfileStatus = z.infer<typeof profileStatusSchema>;

//  STEP 1
export const generalInfoSchema = profileSchema.pick({
  avatar: true,
  name: true,
  preferredName: true,
  pronoun: true,
  email: true,
  whatsapp: true,
  timezone: true,
  workingHours: true,
  availability: true,
  location: true,
  languages: true,
});

export type GeneralInfo = z.infer<typeof generalInfoSchema>;

//  STEP 2
export const professionalDetailsSchema = profileSchema.pick({
  bio: true,
  yearsExperience: true,
  topClients: true,
  linkedIn: true,
  socialLinks: true,
  employmentHistory: true,
  volunteerExperience: true,
  education: true,
  certifications: true,
  professionalAffiliations: true,
});

export type ProfessionalDetails = z.infer<typeof professionalDetailsSchema>;

// STEP 3
export const testimonialsSchema = profileSchema.pick({
  references: true,
});

export type Testimonials = z.infer<typeof testimonialsSchema>;

// STEP 4
export const specialtiesAndSkillsSchema = profileSchema.pick({
  specialtyHeroes: true,
});

export type SpecialtiesAndSkills = z.infer<typeof specialtiesAndSkillsSchema>;

// STEP 5
export const toolsSchema = profileSchema.pick({
  tools: true,
});

export type Tools = z.infer<typeof toolsSchema>;

// STEP 6
export const industriesSchema = profileSchema
  .pick({
    industries: true,
  })
  .required();

export type Industries = z.infer<typeof industriesSchema>;

// STEP 7
export const softSkillsSchema = profileSchema.pick({
  softSkills: true,
});

export type SoftSkills = z.infer<typeof softSkillsSchema>;

// STEP 8
export const projectsSchema = profileSchema.pick({
  highlights: true,
});

export type Projects = z.infer<typeof projectsSchema>;

// STEP 9
export const enterpriseDataSchema = profileSchema.pick({
  experienceAreas: true,
});

export type EnterpriseData = z.infer<typeof enterpriseDataSchema>;

// STEP 10
export const whatIMakeSchema = profileSchema.pick({
  deliverables: true,
});

export type WhatIMake = z.infer<typeof whatIMakeSchema>;

// REQUESTS

// PUT /v1/private/profile
export const updateProfileBodySchema = partialProfileSchema;

export type UpdateProfileBody = z.infer<typeof updateProfileBodySchema>;

export const updateProfileRequestSchema = z.object({
  body: updateProfileBodySchema,
});

export type UpdateProfileRequest = z.infer<typeof updateProfileRequestSchema>;

export const updateProfileResponseSchema = partialProfileSchema;

export type UpdateProfileResponse = z.infer<typeof updateProfileResponseSchema>;

// GET /v1/private/profile
export const getProfileResponseSchema = partialProfileSchema.extend({
  profileStatus: profileStatusSchema,
});

export type GetProfileResponse = z.infer<typeof getProfileResponseSchema>;
