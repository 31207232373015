import {
  type DesignReviewConfig,
  type Ticket,
  TicketStatus,
  TicketWorkflowStep,
  type WorkflowUserTypes,
} from "../commons";

export function canApproveTicket(
  ticket: Ticket,
  userId: string,
  userRole: WorkflowUserTypes,
) {
  const config = ticket.workflow?.steps.find(
    (step) => step.type === TicketWorkflowStep.DESIGN_REVIEW,
  )?.config as DesignReviewConfig | null | undefined;

  if (config == null) {
    return true;
  }

  if (
    ticket.status === TicketStatus.APPROVED ||
    ticket.status === TicketStatus.COMPLETE
  ) {
    return false;
  }

  const approvals =
    ticket.revisionBoards?.filter((board) => board.approval) ?? [];

  const isUserIdAlwaysAllowed =
    config.requiresApproval?.canAlwaysApprove?.userIds?.includes(userId) ??
    false;

  const isUserRoleAlwaysAllowed =
    config.requiresApproval?.canAlwaysApprove?.roles?.includes(userRole) ??
    false;

  const meetsApprovalCountRequirement =
    config.requiresApproval?.requiredReviewersForApproval == null ||
    approvals.length >= config.requiresApproval.requiredReviewersForApproval;

  return (
    isUserIdAlwaysAllowed ||
    isUserRoleAlwaysAllowed ||
    meetsApprovalCountRequirement
  );
}
