import { Icon, Logo, Typography } from "@mg/dali/src";
import { type MyNetworkSummaryProfile } from "@mg/schemas/src/christo/myNetwork";
// eslint-disable-next-line import/named, @typescript-eslint/no-unused-vars
import { Route, createRoute } from "@tanstack/react-router";
import { useState } from "react";

import { InviteDialog } from "../../components/InviteDialog";
import { NetworkSummaryCard } from "../../components/NetworkSummaryCard";
import { requiresAuth } from "../../utils/auth";
import { useNetworkSearch } from "../../utils/hooks/useNetworkSearch";
import { authLayoutRoute } from "../auth-layout/route";

export const workedWithRoute = createRoute({
  getParentRoute: () => authLayoutRoute,
  path: "worked-with",
  beforeLoad() {
    requiresAuth();
  },
  component: function Component() {
    const { networkMutation } = useNetworkSearch();
    const [inviteDialogOpen, setInviteDialogOpen] = useState(false);

    const sectionActions = (
      <button
        onClick={() => setInviteDialogOpen(true)}
        className="hidden items-center gap-2 rounded-full bg-vermillion-700 px-3 py-2.5 text-base-white shadow-lg group-first-of-type:flex"
        data-testid="section-add-cta"
      >
        <Icon.PlusCircle />
        <Typography weight="medium">Add Creative</Typography>
      </button>
    );

    function renderContents() {
      if (networkMutation.data != null) {
        const { results } = networkMutation.data.all;
        const workedWith = results.filter(
          (creative) => !!creative.hasWorkedWith,
        );

        if (workedWith.length === 0) {
          return (
            <div>
              <Typography size="3xl" weight="bold" className="mb-6">
                No Results
              </Typography>
              <Typography>
                There aren&apos;t any great matches for your current search. You
                can{" "}
                <button
                  onClick={() => setInviteDialogOpen(true)}
                  className="text-vermillion-600 underline"
                >
                  import creatives from your network
                </button>{" "}
                to add more options.
              </Typography>
            </div>
          );
        }

        return (
          <NetworkSection
            title="Worked with Me"
            creatives={workedWith}
            actions={sectionActions}
            data-testid="worked-with-me"
          />
        );
      }

      if (networkMutation.error != null) {
        return (
          <figure className="fixed left-1/2 top-1/2 grid -translate-x-1/2 -translate-y-1/2 justify-center text-vermillion-800">
            <Icon.SmileyXEyes size={64} weight="fill" className="mx-auto" />
            <Typography size="2xl" weight="medium">
              Error loading your network. Please try again later.
            </Typography>
          </figure>
        );
      }

      return (
        <figure className="fixed left-1/2 top-1/2 grid -translate-x-1/2 -translate-y-1/2 justify-center">
          <Logo variant="black" type="logo" className="mx-auto" />
          <Typography>Loading your network</Typography>
        </figure>
      );
    }

    return (
      <section className="group grid gap-6 px-5 pb-4 pt-0 md:px-9 md:py-4">
        <InviteDialog
          open={inviteDialogOpen}
          onOpenChange={(o) => {
            setInviteDialogOpen(o);
          }}
        />

        {renderContents()}
      </section>
    );
  },
});

type NetworkSectionProps = {
  title: string;
  actions: React.ReactNode;
  creatives: MyNetworkSummaryProfile[];
  "data-testid": string;
};

function NetworkSection({
  actions,
  creatives,
  title,
  ...rest
}: NetworkSectionProps) {
  const count = creatives.length ?? 0;

  if (count === 0) {
    return null;
  }

  return (
    <>
      <header
        className="flex items-center justify-between"
        data-testid={`${rest["data-testid"]}-header`}
      >
        <Typography as="h1" size="4xl" weight="bold">
          {title}{" "}
          <Typography as="span" className="text-carbon-400">
            ({count})
          </Typography>
        </Typography>

        {actions}
      </header>

      <article
        className="grid grid-cols-[repeat(auto-fill,minmax(384px,1fr))] gap-8"
        data-testid={`${rest["data-testid"]}-cards`}
      >
        {creatives.map((creative) => {
          return (
            <NetworkSummaryCard
              key={creative._id}
              presentation="grid"
              {...creative}
            />
          );
        })}
      </article>
    </>
  );
}
