import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

import { getMessages, getThreads, sendMessage } from "../../services/messages";
import { canAccessMessages } from "../auth";
import { useAppDispatch } from "../hooks";
import { setActiveThread, setMessages } from "../slices/messages";

export const useMessages = () => {
  const dispatch = useAppDispatch();
  const query = useQuery({
    queryKey: ["messages"],
    queryFn: getMessages,
    enabled: canAccessMessages(),
  });

  useEffect(() => {
    if (query.data != null) {
      dispatch(setMessages(query.data));
    }
  }, [query.data, dispatch]);

  return query;
};

export const useThread = (id: string) => {
  const dispatch = useAppDispatch();
  const messages = useMessages();
  const query = useQuery({
    queryKey: ["thread", id],
    queryFn: () => getThreads(id),
  });

  useEffect(() => {
    if (query.data != null) {
      dispatch(setActiveThread(query.data));
      messages.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.data, dispatch]);

  return query;
};

export const useSendMessage = () => {
  return useMutation({
    mutationFn: sendMessage,
  });
};
