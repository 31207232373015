import { Icon, Typography } from "@mg/dali/src";
import { useMutation } from "@tanstack/react-query";
import { type ChangeEventHandler, useRef } from "react";

import { presignedPost, uploadToS3 } from "../services/upload";

export function CsvUploadCard() {
  const presignedPostMutation = useMutation({
    mutationKey: ["presigned-post"],
    mutationFn: presignedPost,
  });

  const s3Mutation = useMutation({
    mutationKey: ["s3", "project"],
    mutationFn: uploadToS3,
  });

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange: ChangeEventHandler<HTMLInputElement> = async ({
    target,
  }) => {
    const fileList = target.files;

    if (fileList == null || (fileList != null && fileList.length === 0)) {
      return;
    }

    const file = fileList[0];

    const { name } = file;
    const cleanName = name.replace(/[!'()*]/g, "");

    const response = await presignedPostMutation.mutateAsync(cleanName);
    if (response == null) return;
    const { url, fields } = response;
    const payload = new FormData();

    Object.keys(fields).forEach((key) => {
      payload.append(key, fields[key as keyof typeof fields]);
    });

    payload.set("file", file, cleanName);

    await s3Mutation.mutateAsync({ url, payload });
  };

  return (
    <article className="flex flex-col justify-between gap-4 rounded-2xl bg-base-white p-4 shadow-lg">
      <Icon.FileArrowUp size={40} className="mx-auto" />

      <Typography size="lg" weight="bold" className="text-center">
        Import creatives
      </Typography>

      <Typography className="text-center">
        You can add multiple contacts at once by uploading a .csv exported from
        your LinkedIn.
      </Typography>

      <input
        type="file"
        ref={inputRef}
        className="hidden"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        onChange={handleChange}
      />

      <button
        onClick={() => inputRef.current?.click()}
        disabled={presignedPostMutation.isPending || s3Mutation.isPending}
        className="flex h-max items-center justify-center gap-2 rounded-full bg-carbon-100 px-3 py-2 text-base-black shadow-md transition-all hover:bg-carbon-50 active:bg-carbon-200 disabled:opacity-30"
      >
        <Icon.UploadSimple />
        <Typography as="span" weight="medium">
          Upload File
        </Typography>
      </button>
    </article>
  );
}
