import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

import { getList, getLists } from "../../services/lists";
import { useAppDispatch } from "../hooks";
import { setActiveList, setLists } from "../slices/lists";

export function useLists() {
  const dispatch = useAppDispatch();
  const query = useQuery({
    queryKey: ["lists"],
    queryFn: getLists,
  });

  useEffect(() => {
    if (query.data != null) {
      dispatch(setLists(query.data));
    }
  }, [query.data, dispatch]);

  return query;
}

export function useList(listId: string) {
  const dispatch = useAppDispatch();

  const query = useQuery({
    queryKey: ["lists", listId],
    queryFn: () => getList(listId),
  });

  useEffect(() => {
    if (query.data != null) {
      dispatch(setActiveList(query.data));
    }
  }, [query.data, dispatch]);

  return query;
}
