import { ButtonNew, Card, Icon, Typography } from "@mg/dali/src";
import { type GetContactsResponse } from "@mg/schemas/src/christo/contacts";
import { EnterpriseOnboardingStatus } from "@mg/schemas/src/commons";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import cx from "classnames";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { useEffect, useRef, useState } from "react";

import { AvatarWithInitials } from "../../../components/AvatarWithInitials";
import { RichEditor } from "../../../components/RichEditor";
import { useAppSelector } from "../../../utils/hooks";

type ContactCardProps = GetContactsResponse[number] & {
  invitingEmail: string;
  archivingId: string;
  archivedIds: string[];
  invitedEmails: string[];
  inviteMutation: unknown;
  archiveMutation: unknown;
};

export function ContactCard(props: ContactCardProps) {
  const user = useAppSelector((state) => state.auth.value);
  const isBeingInvited = props.invitingEmail === props.email;

  const isBeingArchived = props.archivingId === props._id;
  const isInvited =
    props.invitedEmails.includes(props.email) ||
    props.status === EnterpriseOnboardingStatus.INVITED;
  const isArchived = props.archivedIds.includes(props._id);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );
  const actionButtonRef = useRef<HTMLButtonElement>(null);

  // Focus the action button when the dialog is open
  useEffect(() => {
    if (actionButtonRef.current) {
      actionButtonRef.current.focus();
    }
  }, []);

  return (
    <Card
      type="outlined"
      className={cx(
        "flex items-center justify-between gap-6 truncate px-4 py-3 md:px-6",
        {
          hidden: isArchived,
        },
      )}
      uninteractive
    >
      <div className="flex items-center gap-2.5 truncate">
        <AvatarWithInitials
          className="h-10 md:!h-16"
          name={props.name}
          avatar={props.avatar}
        />

        <div className="flex-1 truncate">
          <Typography weight="medium" className="truncate text-xl md:text-2xl">
            {props.name}
          </Typography>
          <Typography
            size="sm"
            weight="normal"
            className="truncate text-carbon-600"
          >
            {props.title}
          </Typography>
          <Typography
            size="sm"
            weight="normal"
            className="truncate text-carbon-600"
          >
            {props.location}
          </Typography>
        </div>
      </div>

      <div className="flex gap-4 md:gap-6">
        {isInvited ? (
          <div className="flex items-center gap-2 rounded-lg border-2 border-base-black px-3 py-2">
            <Icon.Checks />
            <Typography>Invited!</Typography>
          </div>
        ) : (
          <AlertDialog.Root>
            <AlertDialog.Trigger asChild>
              <ButtonNew isLoading={isBeingInvited} disabled={isBeingInvited}>
                <Icon.UserPlus />
                Invite
              </ButtonNew>
            </AlertDialog.Trigger>
            <AlertDialog.Portal>
              <AlertDialog.Overlay className="fixed inset-0 z-[1] bg-base-black/50 data-[state=open]:animate-fade-in" />
              <AlertDialog.Content className="fixed left-1/2 top-1/2 z-[2] grid max-h-[85vh] w-[90vw] max-w-lg -translate-x-1/2 -translate-y-1/2 gap-4 rounded-lg bg-base-white p-6 text-base-black shadow-2xl focus:outline-none data-[state=open]:animate-dialog-content-show">
                <AlertDialog.Title className="">
                  <Typography weight="medium" className="text-xl md:text-2xl">
                    Invite {props.name}
                  </Typography>
                </AlertDialog.Title>
                <AlertDialog.Description asChild>
                  <Typography
                    as="span"
                    size="base"
                    weight="normal"
                    className=""
                  >
                    This will send an email invite to {props.name} to join the{" "}
                    {user?.enterpriseName} network. You can add a custom message
                    below.
                  </Typography>
                </AlertDialog.Description>
                <RichEditor
                  editorState={editorState}
                  onChange={setEditorState}
                  placeholder="Write a custom invitation note"
                  className="min-h-[80px]"
                  hideAssistant
                />

                <div className="flex items-center gap-4">
                  <AlertDialog.Action asChild>
                    <ButtonNew
                      variant="filled"
                      className="border-2 border-base-black"
                      onClick={() => {
                        const contentState = editorState.getCurrentContent();
                        const note = stateToHTML(contentState);
                        // @ts-expect-error TS18046: is of type unknown.
                        props.inviteMutation.mutate({
                          invites: [{ email: props.email }],
                          note,
                        });
                        setEditorState(EditorState.createEmpty());
                      }}
                      ref={actionButtonRef}
                    >
                      Send invitation
                    </ButtonNew>
                  </AlertDialog.Action>

                  <AlertDialog.Cancel asChild>
                    <ButtonNew
                      variant="outlined"
                      onClick={() => setEditorState(EditorState.createEmpty())}
                    >
                      Cancel
                    </ButtonNew>
                  </AlertDialog.Cancel>
                </div>
              </AlertDialog.Content>
            </AlertDialog.Portal>
          </AlertDialog.Root>
        )}

        <ButtonNew
          variant="ghost"
          // @ts-expect-error TS18046: is of type unknown.
          onClick={() => props.archiveMutation.mutate(props._id)}
          className={cx({ hidden: isInvited })}
          isLoading={isBeingArchived}
          disabled={isBeingArchived}
        >
          <Icon.X />
        </ButtonNew>
      </div>
    </Card>
  );
}
