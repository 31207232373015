// eslint-disable-next-line import/named
import { createRoute } from "@tanstack/react-router";

import { LoginView } from "./LoginView";

import { unauthLayoutRoute } from "../unauth-layout/route";

export const loginRoute = createRoute({
  getParentRoute: () => unauthLayoutRoute,
  path: "login",
  component: LoginView,
});
