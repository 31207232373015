// eslint-disable-next-line import/named
import { Outlet, createRoute, redirect } from "@tanstack/react-router";

import { store } from "../../utils/store";
import { rootRoute } from "../root/route";
import { ticketsRoute } from "../tickets/route";

export const unauthLayoutRoute = createRoute({
  getParentRoute: () => rootRoute,
  id: "unauthenticated",
  beforeLoad() {
    const { value: user } = store.getState().auth;

    if (user?.email) {
      redirect({
        to: ticketsRoute.to,
      });
    }
  },
  component: function Component() {
    return <Outlet />;
  },
});
