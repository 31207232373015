import { ButtonNew, Icon, Typography } from "@mg/dali/src";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";

import { useUI } from "../../../contexts/ui";
import { deleteList } from "../../../services/lists";
import { useAppSelector } from "../../../utils/hooks";
import { queryClient } from "../../../utils/queryClient";
import { listRoute } from "../../list/route";

type DeleteListDialogProps = {
  open: boolean;
  onOpenChange(open: boolean): void;
  title: string;
};

export const DeleteListDialog = (props: DeleteListDialogProps) => {
  const { title, ...rest } = props;

  const navigate = useNavigate();
  const activeList = useAppSelector((state) => state.lists.activeList);
  const { notify } = useUI();

  const deleteListMutation = useMutation({
    mutationFn: deleteList,
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["lists"],
      });
      notify({
        variant: "success",
        title: `Deleted ${activeList?.title}`,
        message: `You have successfully deleted ${activeList?.title}.`,
        leadingIcon: <Icon.CheckCircle color="rgb(var(--base-black))" />,
      });

      navigate({
        to: listRoute.to,
        params: {
          listId: "",
        },
      });
    },
  });

  return (
    <AlertDialog.Root {...rest}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="fixed inset-0 z-[2] bg-base-black/50 data-[state=open]:animate-fade-in" />
        <AlertDialog.Content className="fixed left-1/2 top-1/2 z-[2] w-[90vw] max-w-lg -translate-x-1/2 -translate-y-1/2 rounded-lg bg-base-white p-6 text-base-black shadow-2xl focus:outline-none data-[state=open]:animate-dialog-content-show">
          <form
            onSubmit={(e) => {
              e.preventDefault();

              deleteListMutation
                .mutateAsync(activeList?._id as string)
                .then(() => {
                  props.onOpenChange(false);
                });
            }}
            className="grid gap-4"
          >
            <AlertDialog.Title asChild>
              <Typography size="2xl" weight="medium">
                Confirm deletion
              </Typography>
            </AlertDialog.Title>
            <AlertDialog.Description asChild>
              <Typography size="lg">
                Are you sure you want to delete &ldquo;{title}&rdquo;?
              </Typography>
            </AlertDialog.Description>

            <div className="flex gap-4">
              <ButtonNew theme="tertiary" type="submit">
                Yes, Delete
              </ButtonNew>

              <AlertDialog.Cancel asChild>
                <ButtonNew variant="outlined">Cancel</ButtonNew>
              </AlertDialog.Cancel>
            </div>
          </form>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
};
