import { createSlice } from "@reduxjs/toolkit";

type AiChatState = {
  openCount: number | null;
};

const initialState: AiChatState = {
  openCount: null,
};

export const aiChatSlice = createSlice({
  name: "ai-chat",
  initialState,
  reducers: {
    setAiChatOpen(state) {
      state.openCount = state.openCount ? state.openCount + 1 : 1;
    },
  },
});

export const { setAiChatOpen } = aiChatSlice.actions;
