import { extendZodWithOpenApi } from "@asteasolutions/zod-to-openapi/dist/zod-extensions";
import { z } from "zod";

import { assetSchema } from "./asset";
import { conceptValueSchema } from "./concepts";
import { userSchema } from "./user";
extendZodWithOpenApi(z);

export const creativeListTestimonialSchema = z.object({
  avatar: z.string().optional(),
  name: z.string(),
  title: z.string(),
  csat: z.number(),
  text: z.string().optional(),
});

export type CreativeListTestimonial = z.infer<
  typeof creativeListTestimonialSchema
>;

export const creativeListCreativeSchema = z.object({
  searchTerms: z.array(conceptValueSchema).optional(),
  hero: assetSchema,
  alignedSkills: z.array(conceptValueSchema).optional(),
  creative: z.union([userSchema, z.string()]),
  testimonial: creativeListTestimonialSchema.optional(),
});

export type CreativeListCreative = z.infer<typeof creativeListCreativeSchema>;

export enum CreativeListType {
  CUSTOM = "custom",
  MAGICMATCH = "magic-match",
}

export const creativeListTypeSchema = z.nativeEnum(CreativeListType);

export const creativeListSchema = z.object({
  _id: z.string().optional(), // would this ever be optional?
  title: z.string(),
  createdBy: z.union([z.string(), userSchema]),
  creatives: z.array(creativeListCreativeSchema),
  archivedCreatives: z.array(creativeListCreativeSchema),
  createdAt: z.coerce.date().optional(),
  archivedAt: z.coerce.date().optional(),
  type: creativeListTypeSchema,
  skills: z.array(conceptValueSchema).optional(),
  specialties: z.array(conceptValueSchema).optional(),
  updatedAt: z.coerce.date().optional(),
  sharedWith: z.array(z.union([z.string(), userSchema])).optional(),
});

export type CreativeList = z.infer<typeof creativeListSchema>;
