import { Logo } from "@mg/dali/src";
import { ErrorComponent, useRouter } from "@tanstack/react-router";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import { traceId } from "../../utils/http";
import { logout } from "../../utils/slices/auth";
import { logoutUrl } from "../auth-layout/route";

export const CustomErrorComponent: typeof ErrorComponent = ({
  error,
}: {
  error: Error;
}) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const posthog = usePostHog();
  const user = useAppSelector((state) => state.auth.value);

  useEffect(() => {
    posthog?.capture("root_error", {
      error_message:
        error instanceof Error ? error.stack ?? error.message : String(error),
      trace_id: traceId,
    });
  }, []); // Runs only once on mount

  const handleClearAndRedirect = () => {
    dispatch(logout());
    posthog?.reset();

    // Full redirect to login page
    location.assign(logoutUrl(router.basepath));
  };

  let errorMessage = String(error);
  if (error instanceof Error) {
    const errorStack = (error.stack ?? error.message).split("\n");
    if (errorStack.length > 3) {
      errorMessage = errorStack.slice(0, 3).join("\n") + "\n\t...";
    }
  }

  return (
    <main className="relative">
      <div className="sticky top-0 z-[3]">
        <header className="sticky top-0 z-[1] flex items-center justify-center border-b border-b-carbon-300 bg-base-white p-3 shadow-3">
          <Logo variant="puntt" className="h-8" data-testid="logo" />
        </header>
      </div>
      <div className="p-5">
        <h1 className="p-5 text-center text-3xl">Something went wrong!</h1>
        <pre className="mx-auto max-w-3xl whitespace-pre-wrap text-base">
          {errorMessage}
        </pre>
        <p className="mx-auto max-w-3xl pt-6 text-base">
          {user?.email ? (
            <span>
              You are logged into{" "}
              <em>{user?.company ?? "an unknown enterprise"}</em> as{" "}
              <em>
                {user?.name ?? "an unknown user"} &lt;
                {user?.email ?? "with an unknown email address"}&gt;
              </em>{" "}
              with the{" "}
              <em className="capitalize">
                {user?.role
                  ?.toLowerCase()
                  .replace("-", " ")
                  .replace("ops", "normal") ?? "guest"}
              </em>{" "}
              role.
            </span>
          ) : (
            "You are currently not logged in."
          )}
        </p>
        <p className="mx-auto max-w-3xl pt-4 text-base">
          <span>
            <strong>Error ID:</strong> {traceId}
          </span>
        </p>
        <div className="flex justify-center">
          <span className="mr-2 mt-5">
            Please <strong>try again</strong> or
          </span>
          <button
            onClick={handleClearAndRedirect}
            className="rt-reset rt-BaseButton rt-r-size-1 rt-variant-solid rt-Button mt-5 cursor-pointer rounded border-none px-5 py-2.5 text-[14px] transition-colors"
          >
            Log in {user?.email && "again"}
          </button>
        </div>
      </div>
    </main>
  );
};
