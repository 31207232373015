import { Icon, Typography } from "@mg/dali/src";
import * as Avatar from "@radix-ui/react-avatar";
import cx from "classnames";
import { forwardRef, memo, useCallback } from "react";

import { assetForUser } from "../utils/imageHandler";

const colors = [
  "carbon",
  "vermillion",
  "cadmium",
  "egyptian-blue",
  "malachite",
  "ochre",
] as const;

function hashNameToIndex(str: string, max: number) {
  let hash = 0;
  for (let i = 0; i < Math.min(str.length, 100); i++) {
    hash += str.charCodeAt(i);
  }
  return Math.abs(hash) % max;
}

function getRandomColor(userName: string) {
  const col = colors[hashNameToIndex(userName, colors.length)];
  const colMap = {
    carbon: "bg-carbon-100 text-carbon-900",
    vermillion: "bg-vermillion-100 text-vermillion-900",
    cadmium: "bg-cadmium-100 text-cadmium-900",
    "egyptian-blue": "bg-egyptian-blue-100 text-egyptian-blue-900",
    malachite: "bg-malachite-100 text-malachite-900",
    ochre: "bg-ochre-100 text-ochre-900",
  };
  return colMap[col];
}

interface AvatarWithInitialsProps
  extends React.ComponentPropsWithoutRef<typeof Avatar.Root> {
  size?: number;
  square?: boolean;
  avatar?: string;
  name?: string;
  className?: string;
  hasWorkedWith?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
}

export const AvatarWithInitials = memo(
  forwardRef<HTMLElement, AvatarWithInitialsProps>(
    (
      {
        size = 11,
        square,
        avatar,
        name,
        className,
        hasWorkedWith = false,
        isLoading,
        onClick,
        ...props
      },
      ref,
    ) => {
      const realName = name ?? "";
      const firstInitial = realName[0];
      const lastInitial =
        realName.lastIndexOf(" ") !== -1 &&
        realName[realName.lastIndexOf(" ") + 1];

      const containerClasses = cx(
        "inline-flex aspect-square select-none align-middle items-center justify-center relative",
        getRandomColor(realName),
        { "border-2 border-ochre-400": hasWorkedWith },
        { rounded: square },
        { "rounded-full": !square },
        className,
      );

      const getTextSize = useCallback(() => {
        // size is in 4 times px same as tailwind
        if (size > 31) {
          return "4xl";
        }
        if (size > 23) {
          return "3xl";
        }
        if (size > 15) {
          return "2xl";
        }
        if (size > 10) {
          return "lg";
        }
        if (size > 5) {
          return "sm";
        }
        return "xs";
      }, [size]);

      const avatarComponent = (
        <Avatar.Root
          className={containerClasses}
          style={{ height: size * 4 }}
          data-testid="person-avatar"
          ref={ref}
          {...props}
        >
          {isLoading ? (
            <div className="size-8 animate-spin rounded-full border-4 border-malachite-600 border-t-transparent" />
          ) : (
            <>
              <Avatar.Image
                className={`size-full rounded-[inherit] object-cover`}
                src={assetForUser(avatar)}
                alt={realName}
                title={realName}
                loading="lazy"
              />
              <Avatar.Fallback title={realName}>
                <Typography
                  size={getTextSize()}
                  className="text-center uppercase"
                  weight="medium"
                >
                  {firstInitial}
                  {lastInitial}
                </Typography>
              </Avatar.Fallback>

              <span
                className={cx(
                  "absolute -bottom-0.5 -right-0.5 rounded-full bg-ochre-400 p-1 text-base-black",
                  {
                    hidden: !hasWorkedWith,
                  },
                )}
              >
                <Icon.Handshake />
              </span>
            </>
          )}
        </Avatar.Root>
      );

      if (onClick) {
        return <button onClick={onClick}>{avatarComponent}</button>;
      }

      return avatarComponent;
    },
  ),
);

AvatarWithInitials.displayName = "AvatarWithInitials";
