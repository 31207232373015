import {
  type AddToPipelineBody,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  type InviteToMyNetworkBody,
  type MyNetworkBody,
  type RequestWorkConnectionBody,
  myNetworkResponseSchema,
  getPeopleResponseSchema,
  type SubmitTestimonialBody,
  addToPipelineResponseSchema,
  type InviteCreativeToMyNetworkBody,
} from "@mg/schemas/src/christo/myNetwork";

import { API_URL } from "../config/env";
import { smartFetch } from "../utils/http";

//
// NETWORK
//

/**
 * Returns all users in a network for a given user. If there are no users in the
 * network, returns an empty array.
 */
export async function getNetwork(payload: MyNetworkBody) {
  const json = await smartFetch(`${API_URL}/private/my-network`, {
    method: "POST",
    body: JSON.stringify(payload),
  });

  const parsedData = myNetworkResponseSchema.parse(json);
  return parsedData;
}

//
// INVITATIONS
//

/**
 * Given one or more sets of `{ email, type }`, submits a pending invitation
 * for each email, and returns nothing.
 */
export async function sendInvitations(payload: InviteCreativeToMyNetworkBody) {
  await smartFetch(`${API_URL}/private/my-network/invite/creative`, {
    method: "POST",
    body: JSON.stringify(payload),
  });

  return undefined;
}

/**
 * Given a pipeline contact payload, adds a member to the pipeline without
 * sending an invitation email.
 */
export async function addPipelineContact(payload: AddToPipelineBody) {
  const json = await smartFetch(`${API_URL}/private/my-network/pipeline`, {
    method: "POST",
    body: JSON.stringify(payload),
  });

  const parsedData = addToPipelineResponseSchema.parse(json);
  return parsedData;
}

/**
 * Given a creativeId, sends an invitation to an existing creative.
 */
export async function sendRecommendedInvite(recommendationId: string) {
  await smartFetch(
    `${API_URL}/private/my-network/recommendation/${recommendationId}/accept`,
    {
      method: "POST",
    },
  );

  return undefined;
}

//
// People list
//

/**
 * Gets a list of people that can be used for sending messages
 */
export async function getMessageableList() {
  const json = await smartFetch(`${API_URL}/private/my-network/people`, {
    method: "GET",
  });

  const parsedData = getPeopleResponseSchema.parse(json);
  return parsedData;
}

/**
 * Gets a list of people for admins to show possible connections
 */
export async function getPendingConnectionsList() {
  const json = await smartFetch(
    `${API_URL}/private/my-network/people?type=creatives`,
    {
      method: "GET",
    },
  );

  const parsedData = getPeopleResponseSchema.parse(json);
  return parsedData;
}

/**
 * Sends requests to other to verify acept the connection
 */
export async function sendVerificationRequest(
  payload: RequestWorkConnectionBody,
) {
  await smartFetch(`${API_URL}/private/my-network/connection`, {
    method: "POST",
    body: JSON.stringify(payload),
  });

  return undefined;
}

/**
 * Submits a testimonials once the user accepts the connection
 */
export async function submitTestimonial(
  payload: SubmitTestimonialBody & { creativeId: string },
) {
  const { creativeId, ...rest } = payload;

  await smartFetch(`${API_URL}/private/my-network/review/${creativeId}`, {
    method: "POST",
    body: JSON.stringify(rest),
  });

  return undefined;
}

/**
 * Verifies the connection
 */
export async function sendVerifiedConnection(connectionId: string) {
  await smartFetch(`${API_URL}/private/my-network/connect/${connectionId}`, {
    method: "PUT",
  });

  return undefined;
}
