import {
  type GetParticipantsResponse,
  type GetTicketsResponse,
  type ResponseFolder,
  type GetBreadcrumbsResponse,
  type GetFolderTicketsPaginatedResponse,
} from "@mg/schemas/src/christo/catalyst";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

type Folder = Omit<ResponseFolder, "createdAt" | "updatedAt"> & {
  createdAt: string;
  updatedAt: string;
};

type PunttProjectsState = {
  breadcrumbs: GetBreadcrumbsResponse;
  folders: Folder[];
  isLoadingMore: boolean;
  more: boolean;
  participants: GetParticipantsResponse;
  tickets: GetTicketsResponse;
};

const initialState: PunttProjectsState = {
  breadcrumbs: [],
  tickets: [],
  folders: [],
  isLoadingMore: false,
  more: false,
  participants: [],
};

export const punttProjectsSlice = createSlice({
  name: "puntt_projects",
  initialState,
  reducers: {
    /**
     * Bulk adds state to avoid multiple calls to dispatch
     */
    mergePunttProjectState(
      state,
      action: PayloadAction<Partial<PunttProjectsState>>,
    ) {
      Object.assign(state, action.payload);
    },
    /**
     * Main store for all tickets and folders for the given depth. In order to
     * paginate, use this data as the `initialData` for the paginated query, and
     * then splat the result to the end of the array.
     */
    setProjects(
      state,
      action: PayloadAction<GetFolderTicketsPaginatedResponse>,
    ) {
      state.tickets = action.payload.tickets;
      // @ts-expect-error TS2322: Date objects will be automatically converted
      // to strings
      state.folders = action.payload.folders ?? [];
      state.more = action.payload.more ?? false;
    },
    /**
     * Determines whether the visual loader at the bottom of the
     * tickets/folders page is visible.
     */
    setIsLoadingMore(state, action: PayloadAction<boolean>) {
      state.isLoadingMore = action.payload;
    },
    /**
     * Sets the current breadcrubs for the given depth.
     */
    setBreadcrumbs(state, action: PayloadAction<GetBreadcrumbsResponse>) {
      state.breadcrumbs = action.payload;
    },
    /**
     * Keeps a cached record of the participants query. This is a redundant
     * cache since participants are queried with `ensureQueryData`, which avoids
     * refetching when the data has been previously fetched.
     */
    setParticipants(
      state,
      action: PayloadAction<GetParticipantsResponse | undefined>,
    ) {
      state.participants = action.payload ?? [];
    },
    /**
     * Adds temporary tickets with UUIDs before API confirmation
     *
     * Note: Direct mutations to the state are valid here because Redux Toolkit's
     * createSlice uses Immer under the hood, which automatically converts these
     * "mutations" into immutable updates. This means we don't need to explicitly
     * create new arrays with spread operators.
     *
     * New tickets are added to the beginning of the array to show them first.
     */
    addTemporaryTickets(
      state,
      action: PayloadAction<GetTicketsResponse[number][]>,
    ) {
      state.tickets.unshift(...action.payload);
    },
    /**
     * Adds temporary folders with a UUID before API confirmation
     *
     * Note: Direct mutations to the state (like state.folders.push()) are valid here
     * because Redux Toolkit's createSlice uses Immer under the hood, which automatically
     * converts these "mutations" into immutable updates. This means we don't need to
     * explicitly create new arrays with spread operators.
     */
    addTemporaryFolders(state, action: PayloadAction<Folder[]>) {
      state.folders.unshift(...action.payload);
    },
    /**
     * Replaces a ticket with a new one
     */
    replaceTemporaryTicket(
      state,
      action: PayloadAction<{
        temporaryId: string;
        ticket: GetTicketsResponse[number];
      }>,
    ) {
      const ticketIndex = state.tickets.findIndex(
        (t) => t._id === action.payload.temporaryId,
      );
      if (ticketIndex !== -1) {
        state.tickets[ticketIndex] = action.payload.ticket;
      }
    },
    /**
     * Replaces a folder with a new one
     */
    replaceTemporaryFolder(
      state,
      action: PayloadAction<{ temporaryId: string; folder: Folder }>,
    ) {
      const folderIndex = state.folders.findIndex(
        (f) => f._id === action.payload.temporaryId,
      );
      if (folderIndex !== -1) {
        state.folders[folderIndex] = action.payload.folder;
      }
    },
  },
});

export const {
  addTemporaryFolders,
  addTemporaryTickets,
  mergePunttProjectState,
  replaceTemporaryFolder,
  replaceTemporaryTicket,
  setBreadcrumbs,
  setIsLoadingMore,
  setParticipants,
  setProjects,
} = punttProjectsSlice.actions;
