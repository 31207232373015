import { DotsThree } from "@phosphor-icons/react";
import { DropdownMenu, Flex, Heading, IconButton } from "@radix-ui/themes";
import { type Editor } from "@tldraw/tldraw";
import cx from "classnames";

import { Comments } from "./Comments";

import { canShowDismissedComments } from "../../../../../utils/auth";
import { useAppDispatch, useAppSelector } from "../../../../../utils/hooks";
import {
  toggleShowDismissedComments,
  toggleShowResolvedComments,
} from "../../../../../utils/slices/ui";
import { useReversedIndex } from "../../../../../utils/tldraw/revisions";
import { useTicket } from "../../ticket";

type CommentDrawerProps = {
  editor?: Editor | null;
  setActiveRevisionIndex(index: number): Promise<void>;
  videoRef: React.RefObject<{
    handleCommentClicked: (_seekTime: number) => void;
    duration: number;
  }>;
  isLoading: boolean;
};

export function CommentDrawer(props: CommentDrawerProps) {
  const dispatch = useAppDispatch();
  const { value: ticket, revisions } = useAppSelector((state) => state.ticket);
  const { showResolvedComments, showDismissedComments } = useAppSelector(
    (state) => state.ui,
  );
  const { tab } = useTicket();
  const activeRevisionIndex = useReversedIndex();

  if (ticket == null) {
    return null;
  }

  const currentBoard = ticket.workflow.steps[tab];
  let boardId: string;

  if (currentBoard.type === "review") {
    boardId = revisions[activeRevisionIndex]?._id;
  } else {
    boardId = ticket.briefBoard?._id;
  }

  if (boardId == null) {
    return null;
  }

  return (
    <aside
      data-testid="comments-drawer"
      className={cx(
        "fixed right-0 top-[57px] z-[1] h-[calc(100vh_-_57px)] w-80 bg-base-white shadow-lg transition-all",
        {
          "translate-x-full": !open,
        },
      )}
    >
      <header
        data-testid="comments-drawer-header"
        className="flex items-center justify-between border-b border-b-puntt-neutral-gray-8 p-4"
      >
        <Heading size="3" weight="medium">
          Comments
        </Heading>

        <Flex align="center" gap="2">
          <DropdownMenu.Root>
            <DropdownMenu.Trigger>
              <IconButton size="1" variant="ghost" color="gray">
                <DotsThree />
              </IconButton>
            </DropdownMenu.Trigger>

            <DropdownMenu.Content>
              <DropdownMenu.Item
                onClick={() => dispatch(toggleShowResolvedComments())}
              >
                {showResolvedComments ? "Hide" : "Show"} resolved comments
              </DropdownMenu.Item>
              <DropdownMenu.Item
                onClick={() => dispatch(toggleShowDismissedComments())}
                disabled={!canShowDismissedComments()}
                className={cx({ hidden: !canShowDismissedComments() })}
              >
                {showDismissedComments ? "Hide" : "Show"} dismissed comments
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        </Flex>
      </header>

      <Comments {...props} />
    </aside>
  );
}
