import { Icon, Typography, ButtonNew } from "@mg/dali/src";
import { type MyNetworkProfile } from "@mg/schemas/src/christo/myNetwork";
import * as Dialog from "@radix-ui/react-dialog";
import { useNavigate, useSearch } from "@tanstack/react-router";
import cx from "classnames";
import { useState, type FC } from "react";

import { AddToListMenu } from "./AddToListMenu";
import { Notetaker } from "./Notetaker";
import { ShareDialog, ShareDialogType } from "./ShareDialog";

import { AboutMe } from "../routes/profile/components/AboutMe";
import { ProfessionalDetails } from "../routes/profile/components/ProfessionalDetails";
import { Project } from "../routes/profile/components/Project";
import { Projects } from "../routes/profile/components/Projects";
import { Summarizer } from "../routes/profile/components/Summarizer";
import { InvitePipeline } from "../routes/userProfile/components/InvitePipeline";
import { useAppSelector } from "../utils/hooks";
import { useProfile } from "../utils/queries/profile";
import { findProjectMatches, searchProfileForTerms } from "../utils/search";

export const PortfolioDrawer: FC = () => {
  // @ts-expect-error TS2339: Incorrectly inheriting types from search.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, port, proj, ...search } = useSearch({
    strict: false,
  });
  const navigate = useNavigate();
  const [shareProfileDialogOpen, setShareProfileDialogOpen] = useState(false);
  const profile = useProfile(port);
  const { searchHeight } = useAppSelector((state) => state.ui);
  function renderContent() {
    if (profile.data) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { highlightsSummaries, ...freelancer } = profile.data;
      // @ts-expect-error TS2339: Incorrectly inheriting types from search.
      const matchedTerms = searchProfileForTerms(profile.data, search);

      if (port && !proj) {
        return (
          <div className="mx-auto grid auto-rows-min gap-y-6 overflow-y-auto px-4 pb-24 md:p-6">
            <div className="grid auto-rows-min grid-cols-1 gap-y-6 md:grid-cols-5 md:gap-6">
              <div className="col-span-2 grid auto-rows-max gap-6">
                <AboutMe {...freelancer} matchedTerms={matchedTerms} />
                <ProfessionalDetails
                  {...freelancer}
                  // @ts-expect-error TS2339: Incorrectly inheriting types from search.
                  matchedTerms={matchedTerms}
                />
              </div>
              <div className="col-span-3 grid auto-rows-max gap-y-6">
                <Summarizer />
                <Typography size="2xl" weight="medium">
                  Portfolio
                </Typography>
                <Projects matchedTerms={matchedTerms} />
              </div>
            </div>

            <InvitePipeline />
          </div>
        );
      }

      if (port && proj) {
        const project = profile.data.highlights?.find((p) => p._id === proj);
        if (!project) return null;
        const idx = profile.data.highlights?.indexOf(project);
        const projectMatchedTerms = findProjectMatches(matchedTerms, idx ?? -1);
        if (project == null) {
          return null;
        }

        return (
          <Project
            project={{ ...project, matchedTerms: projectMatchedTerms }}
          />
        );
      }
    }

    if (profile.error) {
      <div>
        <Typography className="text-vermillion-700">
          An unexpected error occurred:
        </Typography>

        <pre>{JSON.stringify(profile.error, null, 2)}</pre>
      </div>;
    }

    return <Typography>Loading...</Typography>;
  }

  return (
    <>
      <Dialog.Root
        open={port}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            navigate({
              search: (prev) => {
                const params = { ...prev };
                // @ts-expect-error TS2339: Incorrectly inheriting types from search.
                delete params["proj"];
                // @ts-expect-error TS2339: Incorrectly inheriting types from search.
                delete params["port"];

                return params;
              },
            });
          }
        }}
      >
        <Dialog.Portal>
          <Dialog.Overlay
            style={{ top: searchHeight }}
            className="fixed inset-0 z-10 bg-base-black/50 data-[state=open]:animate-fade-in"
          />
          <Dialog.Content
            style={{ top: searchHeight }}
            className="fixed bottom-0 right-0 z-10 w-4/5 overflow-auto rounded-tl-lg bg-carbon-50 p-6 shadow data-[state=closed]:animate-drawer-slide-out data-[state=open]:animate-drawer-slide-in"
          >
            <div
              className={cx("flex items-center gap-x-2", { hidden: proj })}
              data-testid="portfolio-drawer-header"
            >
              <Dialog.Close
                className={cx("rounded-full border bg-base-white p-2", {
                  hidden: proj,
                })}
                data-testid="drawer-close-cta"
              >
                <Icon.X size={24} />
              </Dialog.Close>
              <AddToListMenu creative={profile.data as MyNetworkProfile} />
              <ButtonNew
                variant="ghost"
                onClick={() => setShareProfileDialogOpen(true)}
              >
                <Icon.Share />
                Share Profile
              </ButtonNew>
              <Notetaker creativeId={port} />
            </div>

            {renderContent()}
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
      <ShareDialog
        open={shareProfileDialogOpen}
        onOpenChange={setShareProfileDialogOpen}
        type={ShareDialogType.Profile}
      />
    </>
  );
};
