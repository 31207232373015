// eslint-disable-next-line import/named
import { createRoute } from "@tanstack/react-router";

import { rootRoute } from "../root/route";

export const errorRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "error",
  component: function Component() {
    throw new Error("This is a test error");
  },
});
