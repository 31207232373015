import { ButtonNew, Card, Icon, Typography } from "@mg/dali/src";
import { type Highlight, type Asset } from "@mg/schemas/src/commons";
// eslint-disable-next-line import/named
import { Link, useNavigate, useSearch } from "@tanstack/react-router";
import cx from "classnames";
import { Fragment, useEffect, useReducer, useRef } from "react";

import { LabeledMappedChips } from "../../../components/LabeledMappedChips";
import { Markdown } from "../../../components/Markdown";
import { useAppSelector } from "../../../utils/hooks";
import { assetForUser } from "../../../utils/imageHandler";
import { type MatchedTerms, highlightWords } from "../../../utils/search";
import { myWorkEditRoute } from "../../userProfile/routes/MyWork/editRoute";

export const Project = ({
  project,
}: {
  project: Highlight & { matchedTerms?: MatchedTerms };
}) => {
  const navigate = useNavigate();
  const search = useSearch({ strict: false });
  const user = useAppSelector((state) => state.auth.value);

  const isAdmin = user?.permissions?.includes("enterprise-admin");

  const [expanded, toggleExpansion] = useReducer((x) => !x, false);
  const [showButton, setShowButton] = useReducer((x) => !x, false);
  const textRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (textRef.current) {
      const style = window.getComputedStyle(textRef.current);
      const lineHeight = parseFloat(style.lineHeight);
      const lines = textRef.current.scrollHeight / lineHeight;

      if (lines > 5) {
        setShowButton();
      }
    }
    if (project.matchedTerms?.description && !expanded) {
      toggleExpansion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project.description]);

  if (project == null) {
    return null;
  }

  let description =
    project.description ??
    "There's no description for this project just yet...";

  if (project.matchedTerms?.description) {
    description = highlightWords(
      description,
      project.matchedTerms?.description.map((t) => t.term as string),
    )
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map((term, i) => {
        return term.highlight
          ? `<span className='bg-malachite-100'>${term.text}</span>`
          : term.text;
      })
      .join("");
  }

  function getEmbedURL(url: string) {
    let embedUrl = "";

    // Use URL constructor to parse the url
    const parsedUrl = new URL(url);

    if (parsedUrl.hostname.includes("youtube")) {
      // Check if the URL is already an embed URL
      if (parsedUrl.pathname.includes("embed")) {
        embedUrl = url;
      } else {
        const videoId = parsedUrl.searchParams.get("v");
        embedUrl = `https://www.youtube.com/embed/${videoId}`;
      }
    } else if (parsedUrl.hostname.includes("vimeo")) {
      // Check if the URL is already an embed URL
      if (parsedUrl.pathname.includes("video")) {
        embedUrl = url;
      } else {
        const videoId = parsedUrl.pathname.split("/")[1];
        embedUrl = `https://player.vimeo.com/video/${videoId}`;
      }
    } else {
      console.error("Invalid URL: The URL should be a YouTube or Vimeo URL.");
    }

    return embedUrl;
  }

  function renderAssets(assets?: Partial<Asset>[]) {
    return assets?.map((a, i) => {
      if (a.type === "image") {
        return (
          <Fragment key={i}>
            <link
              rel="preload"
              as="image"
              href={assetForUser(a.source, 1280)}
            />
            <img
              src={assetForUser(a.source, 1280)}
              alt={`Designer ${i + 1}`}
              style={{ width: "100%" }}
              data-testid="project-hero"
            />
          </Fragment>
        );
      } else if (a.type === "video") {
        return (
          <Fragment key={i}>
            <div className="relative w-full pt-[56.25%]">
              <iframe
                src={getEmbedURL(a.source ?? "")}
                width="640"
                height="360"
                className="absolute left-0 top-0 size-full"
                title={`Designer video ${i + 1}`}
                data-testid="project-hero"
              />
            </div>
          </Fragment>
        );
      }

      return null;
    });
  }

  return (
    <Card className="mx-auto grid auto-rows-min gap-6 overflow-y-auto px-4 pb-24 md:p-6">
      <section className="grid gap-8">
        <div>
          <section className="flex items-center justify-between">
            <button
              className="mb-2 rounded-full bg-carbon-50 p-3"
              onClick={() => {
                navigate({
                  search: (prev) => {
                    const params = { ...prev };
                    // @ts-expect-error TS2322: incorrect type inheritance
                    delete params["proj"];

                    return params;
                  },
                });
              }}
            >
              <Icon.ArrowLeft size={24} />
            </button>

            <Link
              to={myWorkEditRoute.to}
              search={{
                // @ts-expect-error TS2322: incorrect type inheritance
                profileId: search.port,
                // @ts-expect-error TS2322: incorrect type inheritance
                projectId: search.proj,
              }}
              disabled={!isAdmin}
              className={cx({ hidden: !isAdmin })}
            >
              <ButtonNew size="sm" theme="tertiary" className="rounded-full">
                Edit Project
              </ButtonNew>
            </Link>
          </section>
          <Typography
            size="3xl"
            weight="bold"
            data-testid="project-title"
            className={cx({
              "bg-malachite-100": project.matchedTerms?.title,
            })}
          >
            {project.title}
          </Typography>
        </div>

        <div className="grid gap-8 md:grid-cols-1">
          <div className="grid auto-rows-max gap-2">
            <Typography
              weight="medium"
              className="text-carbon-400"
              data-testid="project-what-i-did"
            >
              What I Did
            </Typography>

            <div
              className={cx({ "line-clamp-5": !expanded })}
              data-testid="bio-description-body"
              ref={textRef}
            >
              <Markdown>{description}</Markdown>
            </div>
            <ButtonNew
              variant="ghost"
              theme="primary"
              className={cx("max-w-max", { hidden: !showButton })}
              size="xs"
              onClick={toggleExpansion}
              data-testid={
                expanded
                  ? "what-i-did-less-details-btn"
                  : "what-i-did-more-details-btn"
              }
            >
              {expanded ? <Icon.MinusCircle /> : <Icon.PlusCircle />}
              {expanded ? "Hide" : "More"} Details
            </ButtonNew>
            <div className="mt-4 flex justify-between gap-9">
              <LabeledMappedChips
                title="Deliverables & Skills"
                values={[
                  ...(project.deliverables ?? []),
                  ...(project.skills ?? []),
                ]}
                // @ts-expect-error TS2322: incorrect type inheritance
                matchedTerms={[
                  ...(project.matchedTerms?.skills ?? []),
                  ...(project.matchedTerms?.deliverables ?? []),
                ]}
                hideWhenEmpty
              />

              <LabeledMappedChips
                title="Tags"
                values={project.tags ?? []}
                hideWhenEmpty
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between">
          {project.company && (
            <section className="grid gap-2">
              <Typography weight="medium" className="text-carbon-400">
                Company
              </Typography>
              <Typography
                size="xl"
                weight="medium"
                className={cx("text-base-black", {
                  "bg-malachite-100": project.matchedTerms?.company,
                })}
              >
                {project.company}
              </Typography>
            </section>
          )}

          {project.role != null && (
            <section className="grid gap-2">
              <Typography weight="medium" className="text-carbon-400">
                My Role
              </Typography>
              <Typography
                size="xl"
                weight="medium"
                className={cx("text-base-black", {
                  "bg-malachite-100": project.matchedTerms?.role,
                })}
              >
                {project.role}
              </Typography>
            </section>
          )}

          {project.industry != null && (
            <section
              className={cx("grid gap-2", {
                hidden: !project.industry,
              })}
            >
              <Typography weight="medium" className="text-carbon-400">
                Industry
              </Typography>
              <Typography
                size="xl"
                weight="medium"
                className={cx("text-base-black", {
                  "bg-malachite-100": project.matchedTerms?.industry,
                })}
              >
                {project.industry}
              </Typography>
            </section>
          )}

          {project.year != null && (
            <section className="grid gap-2">
              <Typography weight="medium" className="text-carbon-400">
                Year
              </Typography>
              <Typography size="xl" weight="medium" className="text-base-black">
                {project.year}
              </Typography>
            </section>
          )}
        </div>
      </section>

      {renderAssets(project.assets)}
    </Card>
  );
};
