import React, { useState, useEffect, useCallback, useReducer } from "react";

import { Banner } from "./Banner";
import { type BannerProps } from "./Banner";

import { ArrayEmitter } from "../foundation/base";

export interface BannerQueueProps extends Partial<BannerProps> {
  messages: ArrayEmitter;
}

export function BannerQueue({
  messages,
  ...defaultBannerProps
}: BannerQueueProps) {
  const currentMessage = messages.array[0];
  const BANNER_ANIMATE_DURATION = 300;

  const [, forceUpdate] = useReducer((value) => value + 1, 0);
  const [message, setMessage] = useState(messages.array[0]);

  const removeMessage = useCallback(
    (m) => {
      if (m) {
        messages.remove(m);
      }
    },
    [messages],
  );

  useEffect(() => {
    let timerId: number;

    const doChange = () => {
      if (messages.array[0] !== message) {
        forceUpdate();

        timerId = window.setTimeout(
          () => setMessage(messages.array[0]),
          BANNER_ANIMATE_DURATION,
        );
      }
    };

    messages.on("change", doChange);

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }

      messages.off("change", doChange);
    };
  }, [messages, message]);

  // TODO: Remove after fixing the emitter types
  // @ts-expect-error TS2700: Cant spread a nonobject, will be fixed after fixing emitter types
  const { message: body = "", onClose, ...messageBannerProps } = message || {};

  // We can consider an `open` state if we have a message
  // in the queue and the current message is the message in
  // our state.
  const open = message && message === currentMessage;

  return (
    <Banner
      {...defaultBannerProps}
      {...messageBannerProps}
      open={open}
      message={body}
      onClose={(evt) => {
        if (onClose) {
          // TODO: Remove after fixing the emitter types
          // @ts-expect-error TS2349: Cant call a never, will be fixed after fixing emitter types
          onClose(evt);
        }

        removeMessage(message);
      }}
    />
  );
}

export const createBannerQueue = () => {
  const messages = new ArrayEmitter();

  return {
    messages,
    clearAll: () => messages.empty(),
    bannerNotify: (message: Partial<BannerProps>) => {
      messages.push(message);

      return {
        close: () => {
          messages.remove(message);
        },
      };
    },
  };
};
