// eslint-disable-next-line import/named
import { createRoute } from "@tanstack/react-router";

import { AddPasswordView } from "./view";

import { requiresAuth } from "../../utils/auth";
import { rootRoute } from "../root/route";

export const addPasswordRoute = createRoute({
  getParentRoute: () => rootRoute,
  beforeLoad() {
    requiresAuth();
  },
  path: "onboarding",
  component: AddPasswordView,
});
