import { z } from "zod";

import { AssistantWriteActions } from "./assistant";
import { userSchema } from "./user";

export const messageSchema = z.object({
  sender: z.union([userSchema, z.string()]),
  content: z.string(),
  createdAt: z.string().datetime().optional(),
  updatedAt: z.string().datetime().optional(),
  assistantActions: z.array(z.nativeEnum(AssistantWriteActions)).optional(),
});

export type Message = z.infer<typeof messageSchema>;

export const userChatSchema = z.object({
  _id: z.string(),
  users: z.array(z.union([userSchema, z.string()])),
  messages: z.array(messageSchema),
  lastMessage: messageSchema,
  createdAt: z.coerce.date().optional(),
  updatedAt: z.coerce.date().optional(),
  unreadMessagesByUser: z.record(z.number()),
  isArchivedByUser: z.record(z.boolean()),
});

export type UserChat = z.infer<typeof userChatSchema>;
