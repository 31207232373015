import { Popover } from "@headlessui/react";
import { ButtonNew, Icon } from "@mg/dali/src";
import { type MyNetworkProfile } from "@mg/schemas/src/christo/myNetwork";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRef, useState } from "react";

import { useUI } from "../contexts/ui";
import { addCreativesToList, createList, getLists } from "../services/lists";
import { useAppSelector } from "../utils/hooks";
import { setLists } from "../utils/slices/lists";
import { store } from "../utils/store";

export function AddToListMenu({ creative }: { creative: MyNetworkProfile }) {
  const { lists } = useAppSelector((state) => state.lists);
  const { notify } = useUI();
  const queryClient = useQueryClient();
  const [query, setQuery] = useState("");
  const [addToNewList, setAddToNewList] = useState(false);
  const [newListName, setNewListName] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const createListMutation = useMutation({
    mutationFn: createList,
  });

  const addCreativesToListMutation = useMutation({
    mutationFn: addCreativesToList,
  });

  const handleCreateList = async () => {
    return createListMutation.mutateAsync(
      {
        title: newListName,
        creatives: [{ _id: creative._id }],
      },
      {
        async onSuccess() {
          notify({
            title: "Creative List Saved!",
            message: "",
          });
          queryClient.invalidateQueries({
            queryKey: ["lists"],
            refetchType: "none",
          });

          const lists = await queryClient.fetchQuery({
            queryKey: ["lists"],
            queryFn: getLists,
          });
          store.dispatch(setLists(lists));
        },
      },
    );
  };
  const handleAddCreativesToList = (listId: string) => {
    return addCreativesToListMutation.mutateAsync(
      {
        listId,
        payload: [creative._id],
      },
      {
        async onSuccess(data) {
          notify({
            title: `${creative.name} has been added to your ${data.title} list!`,
            message: "",
          });

          queryClient.invalidateQueries({
            queryKey: ["lists"],
            refetchType: "none",
          });

          const lists = await queryClient.fetchQuery({
            queryKey: ["lists"],
            queryFn: getLists,
          });

          store.dispatch(setLists(lists));
        },
      },
    );
  };

  const closeDialog = () => {
    setNewListName("");
    setAddToNewList(false);
    buttonRef.current?.click();
  };

  const renderedLists =
    query.length > 0
      ? lists?.filter((l) =>
          l.title.toLowerCase().includes(query.toLowerCase()),
        )
      : lists;

  return (
    <Popover as="div" className="relative inline-block text-left">
      <Popover.Button ref={buttonRef} data-testid="add-to-list-cta">
        <ButtonNew variant="ghost">
          <Icon.ListPlus />
          Add to List
        </ButtonNew>
      </Popover.Button>
      <Popover.Panel className="absolute left-0 z-50 box-border w-96 min-w-96 rounded-xl bg-[#F9F9F9] p-4">
        <div className="grid max-h-80 w-full gap-y-2 overflow-auto py-1">
          <div className="grid gap-y-2 border-b border-carbon-200 pb-2">
            <div
              role="searchbox"
              className="group relative flex-1 overflow-hidden rounded-full bg-[#F0F0F0] focus-within:ring-base-black"
            >
              <Icon.MagnifyingGlass className="absolute left-4 top-1/2 -translate-y-1/2" />
              <input
                type="search"
                value={query}
                ref={inputRef}
                onInput={({ target }) => {
                  setQuery((target as HTMLInputElement).value);
                }}
                className="w-full bg-transparent py-2 pl-10 pr-4 font-national2 font-normal leading-normal outline-transparent group-focus-within:pr-4"
              />
            </div>
            {addToNewList ? (
              <div className="flex items-center gap-x-1">
                <div className="group relative flex-1 overflow-hidden rounded-full bg-[#F0F0F0] focus-within:ring-base-black">
                  <Icon.Plus className="absolute left-4 top-1/2 -translate-y-1/2" />
                  <input
                    type="text"
                    value={newListName}
                    placeholder="New List Name"
                    onInput={({ target }) =>
                      setNewListName((target as HTMLInputElement).value)
                    }
                    className="w-full bg-transparent py-2 pl-10 pr-4 font-national2 font-normal leading-normal outline-transparent"
                  />
                </div>
                <div
                  className="flex h-fit cursor-pointer items-center justify-center rounded-full bg-malachite-50 p-1"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    handleCreateList();
                    closeDialog();
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter" || event.key === " ") {
                      handleCreateList();
                      closeDialog();
                    }
                  }}
                >
                  <Icon.Check size={18} />
                </div>
                <div
                  className="flex h-fit cursor-pointer items-center justify-center rounded-full bg-carbon-50 p-1"
                  role="button"
                  onClick={() => {
                    setAddToNewList(false);
                    setNewListName("");
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter" || event.key === " ") {
                      setAddToNewList(false);
                      setNewListName("");
                    }
                  }}
                  tabIndex={0}
                >
                  <Icon.X size={18} />
                </div>
              </div>
            ) : (
              <div
                role="button"
                tabIndex={0}
                className="flex cursor-pointer items-center gap-2 p-2"
                onClick={() => {
                  setAddToNewList(true);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter" || event.key === " ") {
                    setAddToNewList(true);
                  }
                }}
              >
                <Icon.Plus /> Add to new list
              </div>
            )}
          </div>
          {renderedLists?.map((listItem) => (
            <Popover.Button
              key={listItem._id}
              as="div"
              className="w-full overflow-hidden"
            >
              <ButtonNew
                variant="ghost"
                className="w-full truncate"
                onClick={() => handleAddCreativesToList(listItem._id)}
              >
                <span className="block overflow-hidden text-ellipsis">
                  {listItem.title}
                </span>
              </ButtonNew>
            </Popover.Button>
          ))}
        </div>
      </Popover.Panel>
    </Popover>
  );
}
