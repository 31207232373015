import { z } from "zod";

import { userSchema } from "./user";

export enum EventTypes {
  COMPLETE_ONBOARD = "complete-onboard",
  AI_ASSISTANT = "ai-assistant",
  AI_WRITE_ASSISTANT = "ai-write-assistant",
  SEARCH_NETWORK = "search-network",
  IMPORT_PROFILE = "import-profile",
  VIEW_NETWORK_PORTFOLIO = "view-network-portfolio",
  SHARE_LIST = "share-list",
  SHARE_PROFILE = "share-profile",
  AI_TICKET_COMMENTS = "ai-ticket-comments",
  AI_BRIEF = "ai-brief",
}

export const eventSchema = z.object({
  _id: z.string().optional(),
  user: z.union([userSchema, z.string()]).optional(),
  event: z.string(),
  timestamp: z.date().optional(),
  details: z.record(z.any()),
});

export type Event = z.infer<typeof eventSchema>;
