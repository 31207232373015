import { z } from "zod";

import { enterpriseSchema } from "./enterprise";

export const customerSchema = z.object({
  _id: z.string().optional(),
  name: z.string(),
  logo: z.string().optional(),
  enterprise: z.union([enterpriseSchema, z.string()]).optional(),
  createdAt: z.coerce.date().optional(),
});

export type Customer = z.infer<typeof customerSchema>;
