import { logout } from "./slices/auth";
import { store } from "./store";
import { generateUUID } from "./uuid";

import { posthogClient } from "../config/posthog";
import { loginRoute } from "../routes/login/route";

export const getAuthToken = () => {
  const localToken = localStorage.getItem("token");

  if (localToken != null) {
    return `Bearer ${localToken}`;
  }

  return null;
};

// Datadog trace ID, unique per document load
export const traceId = generateUUID();

export async function smartFetch(
  url: string,
  options?: RequestInit,
  askAuth?: boolean,
) {
  const requiresAuth = url.includes("private") || url.includes("upload");
  const opts = options ?? {};
  const defaultHeaders: Record<string, string> = {
    "Content-Type": "application/json",
    "x-datadog-trace-id": traceId,
    "x-datadog-parent-id": "0", // 0 indicates this is the root span
    ...(opts.headers as Record<string, string>),
  };
  opts.mode = "cors";
  opts.credentials = "include";

  if (requiresAuth || askAuth) {
    const localToken = getAuthToken();

    if (localToken == null) {
      throw new Error(
        "Attempt to access private route without an auth token is forbidden.",
      );
    }

    defaultHeaders.Authorization = localToken;
  }

  opts.headers = defaultHeaders;

  const response = await fetch(url, opts);

  if (!response.ok) {
    // Token expired
    if (response.status === 401) {
      const responseData = (await response.clone().json()) as { error: string };
      if (responseData.error === "Invalid Token") {
        store.dispatch(logout());
        posthogClient.reset();
        window.location.assign(loginRoute.to);
      }
    }

    throw response;
  }

  if (response.status === 204) {
    return null;
  }

  return response.json();
}

export async function downloadAsset(
  url: string,
  fileName = "download",
  analyticsEventName = "downloaded_asset",
  analyticsEventProperties: Record<string, boolean | number | string> = {},
) {
  const startTime = Date.now();
  // Fetch the file instead of just downloading the file directly so that the
  // appropriate headers are set for AssetShield.
  const response = await fetch(url);
  const fileType = response.headers.get("Content-Type");

  if (!response.ok) {
    posthogClient.capture(analyticsEventName + "_error", {
      assetUrl: url,
      assetName: fileName,
      fileType,
      durationSeconds: (Date.now() - startTime) / 1000,
      statusCode: response.status,
      statusText: response.statusText,
      responseText: await response.text(),
      ...analyticsEventProperties,
    });
    throw new Error("Failed to download asset");
  }

  const blob = await response.blob();
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.style.display = "none";
  link.download = fileName;
  link.href = blobUrl;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  posthogClient.capture(analyticsEventName, {
    assetUrl: url,
    assetName: fileName,
    fileType,
    fileSizeMB: blob.size / 1024 / 1024,
    durationSeconds: (Date.now() - startTime) / 1000,
    ...analyticsEventProperties,
  });

  // Ensure the download starts before we release the memory
  setTimeout(() => URL.revokeObjectURL(url), 500);
}

export const handleApiError = async (e: unknown) => {
  if (e instanceof Response) {
    const error = e as Response;
    const errorData = (await error.clone().json()) as {
      message?: string;
    };
    const errorMessage = errorData.message || "An unknown error occurred";

    throw new Error(errorMessage);
  } else {
    console.error("Error is not a Response:", e);
    throw e;
  }
};
