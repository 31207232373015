import { ButtonNew, Typography, Input, Icon } from "@mg/dali/src";
import { type ShareWithOutsideOfNetworkBody } from "@mg/schemas/src/christo/creativeList";
import * as Dialog from "@radix-ui/react-dialog";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import { useMutation } from "@tanstack/react-query";
import cx from "classnames";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { useState } from "react";

import { RichEditor } from "./RichEditor";

import { useUI } from "../contexts/ui";
import { shareList } from "../services/lists";
import { shareProfile } from "../services/profile";
import { useAppSelector } from "../utils/hooks";

export enum ShareDialogType {
  List = "list",
  Profile = "profile",
}

type ShareDialogProps = {
  open: boolean;
  onOpenChange(open: boolean): void;
  type: ShareDialogType;
};

export const ShareDialog = (props: ShareDialogProps) => {
  const { activeList } = useAppSelector((state) => state.lists);
  const { value: profile } = useAppSelector((state) => state.profile);
  const listId = activeList?._id ?? "";
  const profileId = profile?._id ?? "";

  const { notify } = useUI();

  const [invites, setInvites] = useState<
    ShareWithOutsideOfNetworkBody["invites"]
  >([{ name: "", email: "" }]);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );

  const [subject, setSubject] = useState<string>();

  const isList = props.type === ShareDialogType.List;

  const shareListMutation = useMutation({
    mutationFn: isList ? shareList : shareProfile,
    onSuccess() {
      notify({
        title: `${
          isList ? "Your list" : profile?.name + "'s profile"
        } has been shared!`,
        message: "",
      });

      props.onOpenChange(false);
      setEditorState(() => EditorState.createEmpty());
      setInvites(() => [{ name: "", email: "" }]);
    },
    onError(err) {
      console.error(err);
    },
  });

  return (
    <Dialog.Root {...props}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-20 bg-base-black/50 data-[state=open]:animate-fade-in" />
        <Dialog.Content className="fixed left-1/2 top-1/2 z-20 grid w-[90vw] max-w-lg -translate-x-1/2 -translate-y-1/2 gap-4 rounded-lg bg-base-white p-6 text-base-black shadow-2xl focus:outline-none data-[state=open]:animate-dialog-content-show">
          <Dialog.Title asChild>
            <Typography size="2xl" weight="medium">
              Share {isList ? "List" : `${profile?.name}'s Profile`}
            </Typography>
          </Dialog.Title>
          <form
            onSubmit={(e) => {
              e.preventDefault();

              const contentState = editorState.getCurrentContent();
              const message = stateToHTML(contentState);
              const payload = {
                message,
                invites,
                subject,
              };

              shareListMutation.mutate({
                objectId: isList ? listId : profileId,
                payload,
              });
            }}
            className="grid gap-6"
          >
            <Dialog.Description asChild>
              <Typography size="lg" weight="normal" className="text-base-black">
                Enter the email and the name of the person you will like to send
                this {isList ? "list" : "profile"} to.
              </Typography>
            </Dialog.Description>
            <ScrollArea.Root type="auto" className="overflow-hidden">
              <ScrollArea.Viewport className="max-h-48 [&>div>div:last-child]:mb-0 [&>div>div]:mb-6">
                {invites.map((invite, idx) => (
                  <div
                    className="flex gap-2 pr-4"
                    key={`.${idx}.${invites.length}`}
                  >
                    <Input
                      size="sm"
                      type="email"
                      required
                      placeholder="coworker@work-email.com"
                      className="flex-1"
                      onChange={({ target }) => {
                        setInvites((prev) => {
                          const res = [...prev];
                          res[idx].email = target.value;

                          return res;
                        });
                      }}
                      value={invite.email}
                    />

                    <Input
                      size="sm"
                      type="name"
                      required
                      placeholder="Coworker Name"
                      className="flex-1"
                      onChange={({ target }) => {
                        setInvites((prev) => {
                          const res = [...prev];
                          res[idx].name = target.value;

                          return res;
                        });
                      }}
                      value={invite.name}
                    />

                    <ButtonNew
                      variant="ghost"
                      size="xs"
                      className={cx("text-cadmium-700", {
                        hidden: invites.length === 1,
                      })}
                      onClick={() => {
                        setInvites((prev) => {
                          const res = [...prev];
                          res.splice(idx, 1);

                          return res;
                        });
                      }}
                    >
                      <Icon.Trash />
                    </ButtonNew>
                  </div>
                ))}
              </ScrollArea.Viewport>

              <ScrollArea.Scrollbar
                className="flex touch-none select-none bg-carbon-50 p-0.5 transition-colors duration-[160ms] ease-out hover:bg-carbon-100 data-[orientation=horizontal]:h-2.5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col"
                orientation="vertical"
              >
                <ScrollArea.Thumb className="relative flex-1 rounded-[10px] bg-carbon-500 before:absolute before:left-1/2 before:top-1/2 before:size-full before:min-h-[44px] before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-['']" />
              </ScrollArea.Scrollbar>
              <ScrollArea.Scrollbar
                className="hover:bg-blackA5 flex touch-none select-none bg-carbon-50 p-0.5 transition-colors duration-[160ms] ease-out data-[orientation=horizontal]:h-2.5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col"
                orientation="horizontal"
              >
                <ScrollArea.Thumb className="relative flex-1 rounded-[10px] bg-carbon-500 before:absolute before:left-1/2 before:top-1/2 before:size-full before:min-h-[44px] before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-['']" />
              </ScrollArea.Scrollbar>
              <ScrollArea.Corner className="bg-carbon-100" />
            </ScrollArea.Root>

            <ButtonNew
              variant="ghost"
              size="xs"
              className="max-w-max"
              onClick={() =>
                setInvites((prev) => {
                  const res = [...prev];
                  res.push({ email: "", name: "" });

                  return res;
                })
              }
            >
              <Icon.PlusCircle />
              Add Another
            </ButtonNew>

            <div className="grid gap-3.5">
              <Input
                size="sm"
                type="name"
                placeholder="Hey! Take a look at this!"
                label="Subject (optional)"
                className="flex-1"
                onChange={({ target }) => {
                  setSubject(target.value);
                }}
                value={subject}
              />
            </div>

            <div className="grid gap-3.5">
              <RichEditor
                label="Message (optional)"
                editorState={editorState}
                onChange={setEditorState}
                placeholder="Please take a look at these contacts. I think they might be a good fit for a project you’re leading."
              />
            </div>

            <div className="flex gap-4">
              <ButtonNew
                type="submit"
                theme="primary"
                disabled={shareListMutation.isPending}
                isLoading={shareListMutation.isPending}
              >
                Share {isList ? "List" : "Profile"}
              </ButtonNew>

              <Dialog.Close asChild>
                {/* @ts-expect-error TS2322: Incorrect proptypes. */}
                <ButtonNew theme="primary" variant="outlined" action="cancel">
                  Cancel
                </ButtonNew>
              </Dialog.Close>
            </div>
          </form>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
