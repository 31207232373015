import { Avatar, ButtonNew, Icon, Input, Typography } from "@mg/dali/src";
import { useMutation } from "@tanstack/react-query";
// eslint-disable-next-line import/named
import { Link, useNavigate } from "@tanstack/react-router";
import {
  type ChangeEvent,
  type FormEvent,
  useCallback,
  useRef,
  useState,
} from "react";

import { BottomNavigation } from "./BottomNavigation";

import { AvatarWithInitials } from "../../../components/AvatarWithInitials";
import { useUI } from "../../../contexts/ui";
import { updateAvatar } from "../../../services/upload";
import { updateUserProfile } from "../../../services/userProfile";
import { useAppSelector } from "../../../utils/hooks";
import { assetForUser } from "../../../utils/imageHandler";
import { useCurrentUserProfile } from "../../../utils/queries/userProfile";
import { defineRoleRoute } from "../routes/define-role";
import { importPortfolioRoute } from "../routes/import-portfolio";

export function WhatDoWeCallYou() {
  const user = useAppSelector((state) => state.auth.value);
  const { data: profile } = useCurrentUserProfile();
  const navigate = useNavigate();
  const { notify } = useUI();
  const [avatarPreview, setAvatarPreview] = useState<string | undefined>(
    undefined,
  );
  const [avatarUploading, setAvatarUploading] = useState(false);
  const avatarInputRef = useRef<HTMLInputElement>(null);
  const [name, setFullName] = useState<string>("");
  const [avatarUrl, setAvatarUrl] = useState<string>("");
  const profileMutation = useMutation({
    mutationFn: updateUserProfile,
  });

  const handleAvatarUpload = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement;

      if (target.files == null || !target.files.length) {
        return;
      }

      return updateAvatar(target.files[0])
        .catch((err) => {
          notify({
            title: "Oops! Something went wrong",
            message: err.data?.message,
            autoDismiss: false,
          });
        })
        .then((response) => {
          if (response) {
            setAvatarUrl(response.fields.key);
          }
        });
    },
    [notify],
  );
  const isTeam = profile?.role !== "freelancer";
  if (!profile) return null;

  return (
    <div className="flex h-dvh flex-col">
      <div className="grid size-full overflow-auto p-6 text-center md:px-10 md:py-20">
        <div className="mx-auto md:max-w-[692px]">
          <Typography
            weight="bold"
            className="text-center text-4xl md:text-6xl"
          >
            {!isTeam
              ? "Add a Name and then a Face to the Name"
              : "Tell Us About Your Team"}
          </Typography>
          <div className="mt-6 grid gap-4">
            <Typography size="xl" className="text-center">
              {isTeam
                ? "What's your company’s name? This basic info is the first step to building your presence and getting noticed by enterprises."
                : `Give us your name and upload a profile picture to personalize your ${user?.company} network presence. A great photo can make your profile more relatable and approachable. A good name is just something you're born with (or pick if you're lucky)`}
            </Typography>
            <div className="grid justify-items-center gap-2 hover:cursor-pointer">
              <input
                name="avatar"
                type="file"
                accept=".jpg, .jpeg, .png"
                disabled={avatarUploading}
                onInput={(e: ChangeEvent<HTMLInputElement>) => {
                  const { target } = e;

                  if (
                    target.value === "" ||
                    target.files == null ||
                    target.files.length === 0
                  ) {
                    return;
                  }

                  const tempImagePreview = window.URL.createObjectURL(
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    e.target!.files![0],
                  );
                  setAvatarUploading(true);
                  setAvatarPreview(tempImagePreview);
                  const img = new Image();
                  img.src = tempImagePreview;

                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  handleAvatarUpload(e)!.finally(() => {
                    setAvatarUploading(false);
                  });
                }}
                ref={avatarInputRef}
                className="absolute left-0 top-0 size-px opacity-0"
              />

              {name.length &&
              !avatarUploading &&
              !avatarPreview &&
              !avatarUrl ? (
                <AvatarWithInitials
                  size={32}
                  name={name}
                  onClick={() => avatarInputRef.current?.click()}
                />
              ) : avatarUploading ? (
                <div className="flex size-32 flex-col items-center justify-center rounded-full bg-carbon-300">
                  <Icon.CircleNotch className="animate-spin" size={60} />
                </div>
              ) : (
                <Avatar
                  size="lg"
                  image={avatarPreview || assetForUser(avatarUrl, 150)}
                  alt={`users's headshot`}
                  onClick={() => avatarInputRef.current?.click()}
                />
              )}

              <ButtonNew
                theme="primary"
                variant="ghost"
                onClick={() => avatarInputRef.current?.click()}
              >
                {" "}
                <Icon.UploadSimple />
                Upload {isTeam ? "Image" : "Headshot"}
              </ButtonNew>
            </div>
            <Input
              size="sm"
              label={isTeam ? "Company Name *" : "Full Name *"}
              required
              value={name}
              className="text-left"
              onChange={({ target }) => {
                setFullName(target.value);
              }}
              placeholder="Enter Your Name"
            />
          </div>
        </div>
      </div>
      <BottomNavigation activeStep={1}>
        <div className="flex items-center justify-between p-6">
          <Link to={defineRoleRoute.to}>
            <ButtonNew size="sm" variant="ghost">
              Back
            </ButtonNew>
          </Link>

          <nav className="flex gap-4">
            <ButtonNew
              size="sm"
              isLoading={profileMutation.isPending}
              disabled={!name?.length || avatarUploading}
              onClick={() => {
                profileMutation.mutate(
                  {
                    avatar: avatarUrl,
                    name,
                  },
                  {
                    onSuccess: () => {
                      navigate({ to: importPortfolioRoute.to });
                    },
                  },
                );
              }}
            >
              Next: Sync Your Portfolio
            </ButtonNew>
          </nav>
        </div>
      </BottomNavigation>
    </div>
  );
}
