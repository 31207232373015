import { Icon, Typography } from "@mg/dali/src";
import { useMutation } from "@tanstack/react-query";
import { type ChangeEventHandler } from "react";

import { presignedPost, uploadToS3 } from "../../../services/upload";

export function SpreadSheetImporter() {
  const presignedPostMutation = useMutation({
    mutationKey: ["presigned-post"],
    mutationFn: presignedPost,
  });

  const s3Mutation = useMutation({
    mutationKey: ["s3", "project"],
    mutationFn: uploadToS3,
  });

  const handleChange: ChangeEventHandler<HTMLInputElement> = async ({
    target,
  }) => {
    const fileList = target.files;

    if (fileList == null || (fileList != null && fileList.length === 0)) {
      return;
    }

    const file = fileList[0];

    const { name } = file;
    const cleanName = name.replace(/[!'()*]/g, "");

    // @ts-expect-error TS299: Response is types as undefined
    const { fields, url } = await presignedPostMutation.mutateAsync(cleanName);

    const payload = new FormData();

    Object.keys(fields).forEach((key) => {
      payload.append(key, fields[key as keyof typeof fields]);
    });

    payload.set("file", file, cleanName);

    await s3Mutation.mutateAsync({ url, payload });
  };

  if (s3Mutation.isSuccess) {
    return (
      <article className="mx-auto grid gap-6 px-10 pb-8 pt-20 text-center">
        <Typography weight="bold">
          Thank you! Your contacts are being processed. We will notify you when
          they are ready.
        </Typography>
      </article>
    );
  }

  if (s3Mutation.isPending) {
    return (
      <article className="mx-auto grid gap-6 px-10 pb-8 pt-20 text-center">
        <Typography weight="bold">Uploading...</Typography>
      </article>
    );
  }

  return (
    <article className="mx-auto grid gap-6 px-10 pb-8 pt-20 text-center">
      <label
        htmlFor="project-asset"
        className="grid cursor-copy justify-items-center gap-4 rounded-2xl border-2 border-carbon-300 p-4 text-base-black"
      >
        <input
          type="file"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          name="project-asset"
          id="project-asset"
          className="hidden"
          onChange={handleChange}
        />
        <Icon.FileArrowUp size={40} color="rgb(var(--base-black))" />
        <Typography size="lg" weight="bold">
          Drag a file here or click to upload from your device.
        </Typography>
      </label>
    </article>
  );
}
