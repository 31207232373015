import { TicketWorkflowStep } from "@mg/schemas/src/commons";
import { ArrowLeft } from "@phosphor-icons/react";
import {
  Avatar,
  Button,
  DropdownMenu,
  Grid,
  Progress,
  Select,
  Text,
  TextField,
  Tooltip,
} from "@radix-ui/themes";
import { useQueryClient } from "@tanstack/react-query";
// eslint-disable-next-line import/named
import { createRoute, Navigate } from "@tanstack/react-router";
import { useReducer, useEffect } from "react";

import { getProject } from "../../../services/projects";
import { errorAnalyticsPayload, useAnalytics } from "../../../utils/analytics";
import { ticketUrlForFigmaChip } from "../../../utils/figma-plugin";
import {
  type FigmaMessage,
  MessageType,
} from "../../../utils/figma-plugin-types";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import { assetForUser } from "../../../utils/imageHandler";
import {
  useProjectMutation,
  useProjects,
  useWorkflows,
} from "../../../utils/queries/projects";
import { logout } from "../../../utils/slices/auth";
import { authLayoutRoute } from "../../auth-layout/route";
import { loginRoute } from "../../login/route";
import { useUploadRevision } from "../components/useUploadRevision";

type ActionType =
  // user has just chosen a ticket from the dropdown
  | "select_ticket"
  // user has just selected or deselected a frame in Figma (may cause view
  // switching)
  | "selection_change"
  // user has opted to create a ticket and we need to swap the view
  | "create_ticket"
  // user has given the new ticket a name or updated the current name
  | "name_ticket"
  // user has initated the upload and optionally ticket creation process
  | "ready"
  // there was an error in the process
  | "error"
  // the process has completed
  | "complete"
  // user has reset the flow
  | "reset";

type Status =
  | "select_ticket"
  | "create_ticket"
  | "ready"
  | "complete"
  | "error";

type FigmaFlowState = {
  status: Status;
  selection: boolean;
  newTicketName: string;
  ticketId?: string;
  errorMessage?: string | null;
};

type FigmaFlowAction = {
  type: ActionType;
  payload: Partial<FigmaFlowState>;
};

const initialReducerState: FigmaFlowState = {
  errorMessage: null,
  newTicketName: "",
  selection: false,
  status: "select_ticket",
  ticketId: undefined,
};

function flowStateReducer(
  state: FigmaFlowState,
  action: FigmaFlowAction,
): FigmaFlowState {
  const { type, payload } = action;

  switch (type) {
    case "ready":
      return {
        ...state,
        status: "ready",
      };
    case "select_ticket":
      return {
        ...state,
        ticketId: payload.ticketId,
      };
    case "selection_change":
      return {
        ...state,
        selection: payload.selection!,
      };
    case "create_ticket":
      return {
        ...state,
        status: "create_ticket",
      };
    case "name_ticket":
      return {
        ...state,
        newTicketName: payload.newTicketName!,
      };
    case "error":
      return {
        ...state,
        errorMessage: payload.errorMessage,
      };
    case "complete":
      return {
        ...state,
        status: "complete",
      };
    case "reset":
      return { ...initialReducerState, selection: state.selection };
    default:
      throw new Error("Unknown reducer action type");
  }
}

export const figmaPluginRoute = createRoute({
  getParentRoute: () => authLayoutRoute,
  path: "tickets/figma-plugin",
  component: function Component() {
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.auth.value);
    const queryClient = useQueryClient();
    const tickets = useProjects();
    const workflows = useWorkflows();
    const ticketMutation = useProjectMutation();
    const { upload, reset } = useUploadRevision();
    const posthog = useAnalytics();

    const [state, dispatchState] = useReducer(
      flowStateReducer,
      initialReducerState,
    );

    // Add a new useEffect to handle ticket fetching errors
    useEffect(() => {
      if (tickets.isError) {
        console.error("Error fetching tickets:", tickets.error);
        posthog.capture(
          "figma_plugin_tickets_loading_error",
          errorAnalyticsPayload(tickets.error),
        );
        dispatch(logout());
      }
    }, [tickets.isError, tickets.error, dispatch]);

    useEffect(() => {
      async function handleFigmaFrameMessage(event: MessageEvent) {
        const data: FigmaMessage = event.data;

        // Sender = figma; type = GET_FIGMA_SELECTION
        // This is different than if the web were to make the same request.
        if (data.type === MessageType.GET_FIGMA_SELECTION) {
          if (data.message === "SELECTION") {
            dispatchState({
              type: "selection_change",
              payload: { selection: true },
            });
          } else if (data.message === "NONE") {
            dispatchState({
              type: "selection_change",
              payload: { selection: false },
            });
          }
        }

        // We requested the selected frames as images and Figma responded as
        // follows
        if (data.type === MessageType.SEND_FIGMA_SELECTION) {
          const { images } = data;

          if (!images) {
            console.error("There are no images");
            return;
          }

          const startUpload = performance.now();
          const success = await upload(images, state.ticketId);
          const uploadDuration = performance.now() - startUpload;

          if (success) {
            const startFetch = performance.now();
            const ticket = await queryClient.fetchQuery({
              queryKey: ["tickets", state.ticketId],
              queryFn: () => getProject(state.ticketId!),
              staleTime: 0, // Re-fetch ticket since we just modified it
            });
            const fetchDuration = performance.now() - startFetch;

            const revIndex = Math.max(0, ticket.revisionBoards.length - 1);
            const tab = ticket.workflow.steps.findLastIndex(
              (step) =>
                step.type === TicketWorkflowStep.DESIGN_REVIEW &&
                step.startedAt,
            );

            posthog.capture("figma_plugin_upload_success", {
              upload_duration_ms: uploadDuration,
              fetch_duration_ms: fetchDuration,
              ticket_id: state.ticketId,
              revision_index: revIndex,
              tab_index: tab,
            });

            window.parent.postMessage(
              {
                type: MessageType.UPLOAD_SUCCESS,
                sender: "web",
                message: ticketUrlForFigmaChip(
                  `${window.location.origin}/tickets/${ticket._id}`,
                  revIndex,
                  tab,
                ), // Send URL to Figma to display in chip
              },
              "*",
            );

            dispatchState({ type: "complete", payload: {} });
          }
        }
      }

      const resizeObserver = new ResizeObserver((entries) => {
        const newSize = String(Math.max(entries[0].target.clientHeight, 200));
        const message: FigmaMessage = {
          type: MessageType.RESIZE,
          sender: "web",
          message: newSize,
        };

        window.parent.postMessage(message, "*");
      });

      window.addEventListener("message", handleFigmaFrameMessage);
      const observedEl = document.body.querySelector("main");

      if (observedEl) {
        resizeObserver.observe(observedEl);
      }

      return () => {
        window.removeEventListener("message", handleFigmaFrameMessage);
        if (observedEl) {
          resizeObserver.unobserve(observedEl);
        }
      };
    }, [queryClient, state, upload, workflows]);

    function requestFigmaSelection() {
      window.parent.postMessage(
        {
          type: MessageType.GET_FIGMA_SELECTION,
          sender: "web",
        } as FigmaMessage,
        "*",
      );
    }

    function renderView() {
      if (state.status === "error") {
        return (
          <Grid gap="2">
            <Text>{state.errorMessage}</Text>

            <Button
              size="1"
              variant="soft"
              color="gray"
              onClick={() => dispatchState({ type: "reset", payload: {} })}
            >
              Reset
            </Button>
          </Grid>
        );
      }

      if (state.status === "create_ticket") {
        return (
          <Grid gap="2" className="dark">
            <Button
              size="1"
              variant="soft"
              color="gray"
              className="max-w-max"
              onClick={() => dispatchState({ type: "reset", payload: {} })}
            >
              <ArrowLeft />
              Back
            </Button>

            <Text size="3" className="text-base-white">
              New ticket name
            </Text>

            <TextField.Root
              placeholder="Ticket 123"
              size="2"
              value={state.newTicketName}
              onChange={({ target }) =>
                dispatchState({
                  type: "name_ticket",
                  payload: { newTicketName: target.value },
                })
              }
            />

            <Tooltip
              content="Please select one or more frames first"
              side="bottom"
            >
              <Button
                variant="solid"
                size="2"
                disabled={
                  !state.selection ||
                  !state.newTicketName.trim().length ||
                  workflows.isPending
                }
                onClick={() => {
                  dispatchState({ type: "ready", payload: {} });

                  const designReviewOnlyWorkflow = workflows.data!.find(
                    (workflow) =>
                      workflow.steps.length === 1 &&
                      workflow.steps[0].type === "review",
                  );

                  if (!designReviewOnlyWorkflow) {
                    posthog.capture("figma_plugin_no_usable_workflow", {
                      ticket_name: state.newTicketName,
                      num_workflows: workflows.data?.length,
                    });
                    return dispatchState({
                      type: "error",
                      payload: {
                        errorMessage:
                          "No usable workflow to create the ticket. Please contact Meaningful Gigs.",
                      },
                    });
                  }

                  const startTime = performance.now();
                  ticketMutation
                    .mutateAsync({
                      title: state.newTicketName,
                    })
                    .then((response) => {
                      // reload the tickets for the dropdown
                      tickets.refetch();

                      posthog.capture("figma_plugin_create_ticket", {
                        ticket_name: state.newTicketName,
                        duration_ms: performance.now() - startTime,
                      });

                      dispatchState({
                        type: "select_ticket",
                        payload: { ticketId: response._id },
                      });

                      requestFigmaSelection();
                    });
                }}
              >
                Create new ticket &amp; Upload version
              </Button>
            </Tooltip>
          </Grid>
        );
      }

      if (state.status === "select_ticket") {
        return (
          <Grid gap="2" className="dark">
            <Text size="3" className="text-base-white">
              Select ticket
            </Text>

            <Select.Root
              size="2"
              value={state.ticketId}
              onValueChange={(value) => {
                window.parent.postMessage(
                  {
                    type: MessageType.FRAME_LOADED,
                    sender: "web",
                    message: "true",
                  },
                  "*",
                );
                dispatchState({
                  type: "select_ticket",
                  payload: { ticketId: value },
                });
                posthog.capture("figma_plugin_select_ticket", {
                  ticket_id: value,
                });
              }}
            >
              <Select.Trigger
                variant="surface"
                placeholder={
                  tickets.isPending
                    ? "Loading tickets..."
                    : "Search or select ticket"
                }
              />
              <Select.Content className="dark">
                {tickets.data?.tickets.map((ticket) => (
                  <Select.Item key={ticket._id} value={ticket._id}>
                    {ticket.title}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select.Root>

            <Tooltip
              content="Please select one or more frames first"
              side="bottom"
            >
              <Button
                variant="solid"
                size="2"
                disabled={!state.selection || !state.ticketId}
                onClick={() => {
                  dispatchState({ type: "ready", payload: {} });
                  requestFigmaSelection();
                }}
              >
                Upload new version
              </Button>
            </Tooltip>

            <Text size="3" className="text-base-white" align="center" m="2">
              OR
            </Text>

            <Button
              onClick={() =>
                dispatchState({ type: "create_ticket", payload: {} })
              }
            >
              Create a new ticket
            </Button>
          </Grid>
        );
      }

      if (state.status === "ready") {
        return (
          <Grid gap="2" className="dark">
            <Text size="3" className="text-base-white">
              Uploading...
            </Text>

            <Progress size="3" duration="30s" />
          </Grid>
        );
      }

      if (state.status === "complete") {
        return (
          <Grid gap="2" className="dark">
            <Text size="3" className="text-base-white">
              Revision uploaded successfully!
            </Text>

            <Button
              onClick={() => {
                reset();
                dispatchState({ type: "reset", payload: {} });
              }}
            >
              Start over
            </Button>
          </Grid>
        );
      }
    }

    if (!user) {
      return (
        <Navigate to={`${loginRoute.to}?redirect=${figmaPluginRoute.to}`} />
      );
    }

    return (
      <main className="h-max min-h-[200px] bg-grayDark-gray3 p-4">
        <header className="flex items-center justify-between">
          {/* This span intentionally left blank */}
          <span />

          <DropdownMenu.Root>
            <DropdownMenu.Trigger>
              <Avatar src={assetForUser(user?.avatar)} fallback="A" size="1" />
            </DropdownMenu.Trigger>

            <DropdownMenu.Content>
              <DropdownMenu.Item onClick={() => dispatch(logout())}>
                Logout
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        </header>

        {renderView()}
      </main>
    );
  },
});
