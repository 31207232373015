import { type MyNetworkProfile } from "@mg/schemas/src/christo/myNetwork";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

type ProfileState = {
  value: MyNetworkProfile | null;
};

const initialState: ProfileState = {
  value: null,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfile(state, action: PayloadAction<MyNetworkProfile>) {
      state.value = action.payload;
    },
  },
});

export const { setProfile } = profileSlice.actions;
