import { type EnterpriseProfileType } from "@mg/schemas/src/commons";
import { useMutation, useQuery } from "@tanstack/react-query";

import {
  archiveUser,
  getUsers,
  inviteUser,
  updateCurrentUser,
} from "../../services/users";

export function useGetUsers(roles?: EnterpriseProfileType[]) {
  const query = useQuery({
    queryKey: ["retrieve-users", ...(roles ?? [])],
    queryFn: () => getUsers(roles),
  });

  return query;
}

export function useInviteUser() {
  const mutation = useMutation({
    mutationKey: ["invite-users"],
    mutationFn: inviteUser,
  });

  return mutation;
}

export function useUpdateCurrentUser() {
  const mutation = useMutation({
    mutationKey: ["update-current-user"],
    mutationFn: updateCurrentUser,
  });

  return mutation;
}

export function useArchiveUser() {
  const mutation = useMutation({
    mutationKey: ["archive-user"],
    mutationFn: archiveUser,
  });

  return mutation;
}
