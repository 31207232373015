import { z } from "zod";

import { catalystTeamSettingsSchema, enterpriseSchema } from "./enterprise";

export const teamUserPartialSchema = z.object({
  _id: z.string().optional(),
  name: z.string().optional(),
  email: z.string().optional(),
  avatar: z.string().optional(),
  role: z.string().optional(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
  profile: z.record(z.unknown()).optional(),
});

export type TeamUserPartial = z.infer<typeof teamUserPartialSchema>;

export const teamSchema = z.object({
  _id: z.string().optional(),
  enterprise: z.union([z.string(), enterpriseSchema]),
  users: z.array(z.union([z.string(), teamUserPartialSchema])),
  profileSettings: catalystTeamSettingsSchema.optional(),
  archivedAt: z.coerce.date().optional(),
});

export type Team = z.infer<typeof teamSchema>;
