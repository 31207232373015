// eslint-disable-next-line import/named, @typescript-eslint/no-unused-vars
import { Route, createRoute } from "@tanstack/react-router";

import { Step1Explanation } from "../components/Step1Explanation";
import { onboardingRoute } from "../route";

export const introExplanationRoute = createRoute({
  getParentRoute: () => onboardingRoute,
  path: "intro",
  component: Step1Explanation,
});
