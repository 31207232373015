export const MG_ASSET_CDN_URL = "https://assets.meaningfulgigs.com";
const MG_ASSET_ALT_URL = "https://static.puntt.ai";

export function assetForUser(path?: string, size = 100, hsize = 0) {
  return resolvePath(path, size, hsize);
}

export function getEnterpriseLogo(path?: string) {
  if (!path) {
    return `${MG_ASSET_CDN_URL}/misc/company.png`;
  }

  if (path.includes("http")) {
    return path;
  }

  return `${MG_ASSET_CDN_URL}/${path}`;
}

export function resolvePath(path?: string | null, size = 100, hsize = 0) {
  if (!path) {
    return undefined;
  }

  if (path.includes("http")) {
    return path;
  }

  if (path.includes("users")) {
    if (path.toLowerCase().endsWith(".gif")) {
      return `${MG_ASSET_ALT_URL}/${path}`;
    }
  }

  return `${MG_ASSET_CDN_URL}/fit-in/${size}x${hsize || size}/${path}`;
}

export function resizeImage(file: File): Promise<File> {
  return new Promise((resolve) => {
    const img = new Image();
    const reader = new FileReader();

    reader.onload = (e) => {
      img.src = e.target?.result as string;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const max_width = 1200;
        const max_height = 1200;

        let width = img.width;
        let height = img.height;
        if (width > max_width || height > max_height) {
          if (width > height) {
            if (width > max_width) {
              height *= max_width / width;
              width = max_width;
            }
          } else {
            if (height > max_height) {
              width *= max_height / height;
              height = max_height;
            }
          }
        }

        canvas.width = width;
        canvas.height = height;

        ctx?.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(new File([blob], file.name, { type: file.type }));
            } else {
              resolve(file);
            }
          },
          "image/jpeg",
          0.9,
        );
      };
    };

    reader.readAsDataURL(file);
  });
}
