import * as RadixAvatar from "@radix-ui/react-avatar";
import { cva, cx } from "class-variance-authority";
import React, {
  forwardRef,
  type HTMLAttributes,
  type ReactNode,
  type Ref,
  useReducer,
  useRef,
  useState,
  useEffect,
} from "react";
import { extendTailwindMerge } from "tailwind-merge";

import { Sidebar, type SidebarProps } from "./Sidebar";

import { Avatar, type AvatarProps } from "../avatar";
import { ButtonNew, type ButtonNewProps } from "../button";
import { Icon, Logo, type LogoProps, Typography } from "../foundation";

const twMerge = extendTailwindMerge({ prefix: "dali-" });

export interface AppSidebarProps extends SidebarProps {
  /**
   * Sets the background color of the sidebar depending on the context which it
   * is used.
   */
  type: "internal" | "external";
  /**
   * Optionally, configure a click handler for the logo at the top of the
   * component.
   */
  onLogoClick?: () => void;
  /**
   * By default, the AppSidebar includes an expandable menu at the bottom of the
   * bar, and includes a logout button. However, that logout functionality must
   * be provided by the implementing codebase.
   */
  onLogout(): void;
  /**
   * By default, the mobile AppSidebar includes an open/close menu to the left
   * of the logo. However, the open state must be defined by the implementing
   * codebase.
   */
  onMobileMenuClick(): void;
  mobileOpen?: boolean;
  /**
   * Configure the display of the profile information at the bottom of the bar.
   */
  profile?: {
    /**
     * Set the avatar of the expandable menu.
     */
    avatar: AvatarProps;
    /**
     * Set the title of the expandable menu.
     */
    title: string;
    /**
     * Set the subtitle of the expandable menu (usually designer level, internal
     * team, or customer enterprise).
     */
    subtitle: string;
    /**
     * Optionally, expand the menu by default; defaults to `false`.
     */
    defaultExpanded?: boolean;
    /**
     * Optionally, override top level type for styling purposes`.
     */
    type?: "internal" | "external";
  } | null;
  /**
   * Optionally, provide additional actions in the expandable menu at the bottom
   * of the bar.
   */
  expandedMenuActions?: {
    /**
     * Set the icon of the menu item
     */
    icon: ReactNode;
    /**
     * Set the label of the menu item
     */
    label: string;
    /**
     * Set the click handler of the menu item
     */
    onClick(): void;
    /**
     * Optionally make the menu item display as active
     */
    active?: boolean;
    /**
     * Optionally set the action button theme
     */
    theme?: "primary" | "secondary" | "tertiary";
    /**
     * Optionally set the action button variant
     */
    variant?: "filled" | "outlined" | "ghost";
    /**
     * Optionally set the action button variant
     */
    size?: "xs" | "sm" | "md" | "lg";
  }[];
  /**
   * Optionally, set the sidebar to an expanded or collapsed state; defaults to
   * `false`
   */
  expanded?: boolean;
  /**
   * Optionally, provide an enterprise logo to display in the sidebar.
   */
  enterpriseLogo?: string;
}

const appSidebarStyles = cva(
  ["mg-app-sidebar", "dali-w-screen sm:dali-w-[250px] sm:dali-h-screen"],
  {
    variants: {
      type: {
        internal: ["dali-bg-egyptian-blue-100"],
        external: ["dali-bg-base-black"],
      },
      open: {
        true: ["dali-h-screen", "dali-sticky", "dali-top-0"],
        false: ["dali-h-[85px]", "dali-overflow-y-hidden"],
      },
    },
  },
);

const logoVariant: Record<AppSidebarProps["type"], LogoProps["variant"]> = {
  internal: "black",
  external: "white",
};

export const AppSidebar = forwardRef(
  (props: AppSidebarProps, ref: Ref<HTMLDivElement>) => {
    const {
      type,
      onLogoClick = () => null,
      onLogout,
      profile,
      expandedMenuActions = [],
      // expanded = false,
      mobileOpen,
      onMobileMenuClick,
      className: hash,
      children,
      enterpriseLogo,
      ...rest
    } = props;
    const [loadingStatus, setLoadingStatus] = useState("idle");
    const logoClasses = cx("dali-w-[100px] sm:dali-w-[150px] dali-h-auto", {
      "dali-cursor-pointer": onLogoClick,
    });
    const poweredByClasses = cx("dali-w-[66px] sm:dali-w-[100px] dali-h-auto", {
      "dali-cursor-pointer": onLogoClick,
    });

    return (
      <Sidebar
        ref={ref}
        className={cx(
          twMerge(appSidebarStyles({ type, open: mobileOpen })),
          hash,
        )}
        data-testid="sidebar"
        {...rest}
      >
        <div className="dali-flex dali-gap-3 dali-items-center">
          <button
            className="dali-bg-carbon-900 dali-p-1 dali-text-base-white dali-rounded sm:dali-hidden"
            onClick={onMobileMenuClick}
          >
            {mobileOpen ? <Icon.X size={24} /> : <Icon.ListBullets size={24} />}
          </button>
          <RadixAvatar.Root>
            <RadixAvatar.Image
              onLoadingStatusChange={setLoadingStatus}
              src={enterpriseLogo}
              className={logoClasses}
              alt="Enterprise Logo"
            ></RadixAvatar.Image>
            <div className={cx({ hidden: loadingStatus === "error" })}>
              <Typography className="dali-text-base-white dali-mt-2" size="xs">
                powered by
              </Typography>
              <Logo
                variant={logoVariant[type]}
                className={poweredByClasses}
                onClick={onLogoClick}
                data-testid="logo"
              />
            </div>

            <RadixAvatar.Fallback>
              <Logo
                variant={logoVariant[type]}
                className={logoClasses}
                onClick={onLogoClick}
                data-testid="logo"
              />
            </RadixAvatar.Fallback>
          </RadixAvatar.Root>
        </div>

        {children}

        <ProfileMenu
          type={type}
          profile={profile}
          actions={expandedMenuActions}
          onLogout={onLogout}
          className={cx("sm:dali-block", { "dali-hidden": !mobileOpen })}
        />
      </Sidebar>
    );
  },
);

AppSidebar.displayName = "AppSidebar";

interface ProfileMenuProps extends HTMLAttributes<HTMLDivElement> {
  type: AppSidebarProps["type"];
  profile: AppSidebarProps["profile"];
  actions: AppSidebarProps["expandedMenuActions"];
  onLogout: AppSidebarProps["onLogout"];
}

function ProfileMenu(props: ProfileMenuProps) {
  const { type, profile, actions = [], className } = props;
  const titleTextColor: Record<ProfileMenuProps["type"], string> = {
    internal: "dali-text-base-black",
    external: "dali-text-base-white",
  };
  const subtitleTextColor: Record<ProfileMenuProps["type"], string> = {
    internal: "dali-text-carbon-600",
    external: "dali-text-carbon-300",
  };
  const overflowMenuBg: Record<ProfileMenuProps["type"], string> = {
    internal: "dali-bg-egyptian-blue-100",
    external: "dali-bg-base-black",
  };
  const actionButtonType: Record<
    ProfileMenuProps["type"],
    ButtonNewProps["theme"]
  > = {
    internal: "primary",
    external: "secondary",
  };

  const [profileExpanded, toggleProfileExpanded] = useReducer(
    (expanded) => !expanded,
    props.profile?.defaultExpanded ?? false,
  );
  const expandedMenuRef = useRef<HTMLDivElement>(null);
  const [expandedMenuHeight, setExpandedMenuHeight] = useState(0);

  useEffect(() => {
    if (expandedMenuRef.current) {
      setExpandedMenuHeight(expandedMenuRef.current.scrollHeight + 16);
    }
  }, [profileExpanded]);

  if (props.profile == null) {
    return null;
  }

  return (
    <section
      className={cx(
        "dali-absolute dali-bottom-6 dali-right-6 dali-left-6",
        titleTextColor[type],
        className,
      )}
      id="profile-menu"
    >
      <div
        ref={expandedMenuRef}
        style={{
          height: `${profileExpanded ? expandedMenuHeight : 0}px`,
        }}
        className={twMerge(
          cx(
            "dali-overflow-y-hidden dali-flex dali-flex-col dali-gap-2 dali-transition-all",
            overflowMenuBg[type],
            {
              "dali-pb-4 dali-pt-0.5 dali-px-0.5": profileExpanded,
              "dali-pb-0": !profileExpanded,
            },
          ),
        )}
        data-testid="profile-expanded"
      >
        <>
          {actions.map((action, i) => (
            <SubnavLink
              theme={action.theme ?? actionButtonType[type]}
              key={i}
              onClick={action.onClick}
              active={action.active}
              variant={action.variant ?? "ghost"}
              size={action.size ?? "xs"}
            >
              {action.icon}
              {action.label}
            </SubnavLink>
          ))}
          <SubnavLink
            onClick={props.onLogout}
            theme={actionButtonType[profile?.type ?? type]}
            data-testid="logout-btn"
          >
            <Icon.SignOut />
            <span>Log Out</span>
          </SubnavLink>
        </>
      </div>
      {/* Profile avatar/name section */}
      <button
        type="button"
        aria-expanded={profileExpanded}
        aria-controls="profile-menu"
        onClick={() => toggleProfileExpanded()}
        className="dali-flex dali-w-full dali-items-center dali-gap-4 dali-text-left"
      >
        {!profile?.avatar.image && profile?.title.length ? (
          <div className="dali-h-11 dali-w-11 dali-rounded-full dali-flex dali-justify-center dali-items-center dali-bg-carbon-200">
            <Typography
              size="lg"
              weight="bold"
              className="dali-text-carbon-900"
            >
              {profile.title[0]}
              {profile.title.lastIndexOf(" ") !== -1 &&
                profile.title[profile.title.lastIndexOf(" ") + 1].toUpperCase()}
            </Typography>
          </div>
        ) : (
          <Avatar {...props.profile.avatar} data-testid="user-avatar" />
        )}
        <div className="dali-flex-1 dali-truncate">
          <Typography
            weight="medium"
            className="dali-truncate"
            data-testid="user-name"
          >
            {profile.title}
          </Typography>
          <Typography
            size="xs"
            weight="medium"
            className={cx("dali-truncate", subtitleTextColor[type])}
            data-testid="enterprise"
          >
            {profile.subtitle}
          </Typography>
        </div>

        <Icon.CaretUp
          color="currentColor"
          weight="bold"
          size={18}
          className={cx("dali-transition-transform", {
            "dali-rotate-180": profileExpanded,
          })}
        />
      </button>
    </section>
  );
}

function SubnavLink(props: ButtonNewProps) {
  return <ButtonNew {...props} />;
}
