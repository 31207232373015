import { Disclosure } from "@headlessui/react";
import { type GetTicketCommentsResponse } from "@mg/schemas/src/christo/catalyst";
import { CaretLeft, MagicWand } from "@phosphor-icons/react";
import { Badge, Button, Flex, Heading, Text } from "@radix-ui/themes";
import { type Editor } from "@tldraw/tldraw";
import cx from "classnames";

import { HighlightModal } from "../../../components/HighlightModal";
import leapfrogLoader from "../../../images/leapfrogLoader.gif";
import { useAppSelector } from "../../../utils/hooks";
import { Comment } from "../../tickets/routes/components/comment-drawer/Comment";

type CommentDrawerProps = {
  editor?: Editor | null;
  comments: GetTicketCommentsResponse;
};

const collapsibleTriggerClasses =
  "z-20 top-0 sticky p-4 border-b border-b-puntt-neutral-gray-9 bg-base-white w-full flex items-center justify-between";

export function TutorialCommentDrawer(props: CommentDrawerProps) {
  const { tutorialStep, loadingAI } = useAppSelector(
    (state) => state.onboarding,
  );

  const { comments } = props;
  const requiredComments = comments.filter((comment) => comment.isRequired);
  const suggestedComments = comments.filter((comment) => !comment.isRequired);

  return (
    <HighlightModal tooltipPosition={{ top: 30, right: 350 }} order={4} show>
      <aside
        data-testid="comments-drawer"
        className={cx(
          "fixed right-0 top-[57px] z-[200] h-[calc(100vh_-_57px)] w-80 bg-base-white shadow-lg transition-all",
          {
            "translate-x-full": !open,
          },
        )}
      >
        <header className="flex items-center justify-between border-b border-b-puntt-neutral-gray-8 p-4">
          <Heading size="3" weight="medium">
            Comments
          </Heading>
        </header>
        {tutorialStep === 3 && (
          <HighlightModal
            tooltipPosition={{ top: 0, right: 350 }}
            order={3}
            show
          >
            <Flex className="mt-2 w-full justify-center px-2">
              <Button size="4" className="w-full">
                {loadingAI ? (
                  <img
                    alt="leap frog loader"
                    src={leapfrogLoader}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                ) : (
                  <>
                    <MagicWand />
                    Start AI Review
                  </>
                )}
              </Button>
            </Flex>
          </HighlightModal>
        )}
        <section className="relative">
          <Disclosure defaultOpen as="div" className="relative">
            {({ open }) => (
              <>
                <Disclosure.Button className={collapsibleTriggerClasses}>
                  <Flex gap="2" align="center">
                    <Text size="3" weight="medium">
                      Required Changes
                    </Text>

                    <Badge size="1" variant="solid" color="red">
                      {requiredComments.length}
                    </Badge>
                  </Flex>

                  <CaretLeft
                    className={cx("transition-transform", {
                      "-rotate-90": open,
                    })}
                  />
                </Disclosure.Button>
                <Disclosure.Panel>
                  <section className="relative">
                    {requiredComments.map((comment) => (
                      <Comment
                        mentions={comment.mentions as string[]}
                        users={[]}
                        editor={null}
                        rule={comment.rule}
                        key={comment._id}
                        author={
                          comment.createdBy as Exclude<
                            typeof comment.createdBy,
                            string
                          >
                        }
                        createdAt={comment.createdAt}
                        isAI={comment.isAI}
                        message={comment.description}
                        messageId={comment._id}
                        meta={comment}
                        replies={comment.messages}
                        onClick={() => {}}
                        updatedAt={comment.updatedAt}
                        onSuccess={(_data) => {}}
                        isActive={false}
                        videoStart={comment.videoStart}
                        commentId={comment._id}
                      />
                    ))}
                  </section>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>

          <Disclosure defaultOpen as="div" className="relative">
            {({ open }) => (
              <>
                <Disclosure.Button className={collapsibleTriggerClasses}>
                  <Flex gap="2" align="center">
                    <Text size="3" weight="medium">
                      Suggested Improvements
                    </Text>

                    <Badge size="1" variant="solid" color="red">
                      {suggestedComments.length}
                    </Badge>
                  </Flex>

                  <CaretLeft
                    className={cx("transition-transform", {
                      "-rotate-90": open,
                    })}
                  />
                </Disclosure.Button>
                <Disclosure.Panel>
                  <section className="relative">
                    {suggestedComments.map((comment) => (
                      <Comment
                        mentions={comment.mentions as string[]}
                        users={[]}
                        editor={null}
                        rule={comment.rule}
                        key={comment._id}
                        author={
                          comment.createdBy as Exclude<
                            typeof comment.createdBy,
                            string
                          >
                        }
                        createdAt={comment.createdAt}
                        isAI={comment.isAI}
                        message={comment.description}
                        messageId={comment._id}
                        meta={comment}
                        replies={comment.messages}
                        onClick={() => {}}
                        updatedAt={comment.updatedAt}
                        onSuccess={(_data) => {}}
                        isActive={false}
                        videoStart={comment.videoStart}
                        commentId={comment._id}
                      />
                    ))}
                  </section>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </section>
      </aside>
    </HighlightModal>
  );
}
