import { z } from "zod";

import { messageSchema, userChatSchema } from "../commons";

export const sendBodySchema = z.object({
  designerId: z.string(),
  subject: z.string(),
  message: z.string(),
});

export const sendRequestSchema = z.object({
  body: sendBodySchema,
});

export type SendRequest = z.infer<typeof sendRequestSchema>;

export const interviewConfirmationBodySchema = z.object({
  designerId: z.string().nonempty(),
  interviewTime: z.string().nonempty(),
});

export type InterviewConfirmationBody = z.infer<
  typeof interviewConfirmationBodySchema
>;

export const interviewConfirmationRequestSchema = z.object({
  body: interviewConfirmationBodySchema,
});

export type InterviewConfirmationRequest = z.infer<
  typeof interviewConfirmationRequestSchema
>;

export const interviewConfirmationResponseSchema = z.object({
  designerName: z.string(),
  clientEmail: z.string(),
  companyName: z.string(),
  interviewTime: z.string(),
});

export type InterviewConfirmationResponse = z.infer<
  typeof interviewConfirmationResponseSchema
>;

export const sendMessageBodySchema = z.object({
  content: z.string(),
  threadId: z.string().optional(),
  recipientIds: z.array(z.string()),
  bcc: z.array(z.string()),
});

export type SendMessageBody = z.infer<typeof sendMessageBodySchema>;

export const sendMessageRequestSchema = z.object({
  body: sendMessageBodySchema,
});

export type SendMessageRequest = z.infer<typeof sendMessageRequestSchema>;

export const sendMessageResponseSchema = userChatSchema;

export type SendMessageResponse = z.infer<typeof sendMessageResponseSchema>;

export const getMessagesParamsSchema = z.object({
  threadId: z.string(),
});

export type GetMessagesParams = z.infer<typeof getMessagesParamsSchema>;

export const getMessagesRequestSchema = z.object({
  params: getMessagesParamsSchema,
});

export type GetMessagesRequest = z.infer<typeof getMessagesRequestSchema>;

export const getMessagesResponseSchema = z.object({
  messages: z.array(
    z.object({
      sender: z.object({
        _id: z.string(),
        name: z.string(),
        avatar: z.string().optional(),
      }),
      content: z.string(),
      createdAt: z.string().datetime(),
    }),
  ),
  recipients: z.array(
    z.object({
      _id: z.string(),
      name: z.string(),
      avatar: z.string().optional(),
    }),
  ),
});

export type GetMessagesResponse = z.infer<typeof getMessagesResponseSchema>;

export const getChatsResponseSchema = z.array(
  z.object({
    _id: z.string(),
    recipients: z.array(
      z.object({
        _id: z.string(),
        name: z.string(),
        avatar: z.string().optional(),
      }),
    ),
    lastMessage: messageSchema,
    unreadMessages: z.number(),
  }),
);

export type GetChatsResponse = z.infer<typeof getChatsResponseSchema>;
