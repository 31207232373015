import { cva, cx } from "class-variance-authority";
import React, {
  forwardRef,
  type InputHTMLAttributes,
  type ReactNode,
} from "react";
import { extendTailwindMerge } from "tailwind-merge";

import { Typography } from "../foundation";
import { useId } from "../foundation/base";

/**
 * TODO: Remove this once we have a better way to extend tailwind merge.
 * Ideally, we just declare this once in a base util or something.
 */
const twMerge = extendTailwindMerge({ prefix: "dali-" });

export interface RadioProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "size"> {
  /**
   * Force the radio's checked state
   */
  checked?: boolean;
  /**
   * Optionally, disable the radio
   */
  disabled?: boolean;
  /**
   * Optionally, a unique ID for the radio element used by the form
   */
  id?: string;
  /**
   * The value for the radio
   */
  value: string | number;
  /**
   * The size variant of the radio
   */
  size?: "sm" | "lg";
  /**
   * A mandatory label for the radio
   */
  label: ReactNode;
}

const radioStyles = cva(
  [
    // control styles
    "dali-relative dali-border-2 dali-aspect-square dali-rounded-full dali-transition-all",
    "group-focus-within:dali-ring-2 group-focus-within:dali-ring-offset-2 group-focus-within:dali-ring-base-black",
    // control inner
    "before:dali-absolute before:dali-inset-0 before:dali-aspect-square before:dali-rounded-full",
    "before:dali-left-0.5 before:dali-top-0.5 before:dali-transition-all",
  ],
  {
    variants: {
      size: {
        sm: ["dali-h-4 before:dali-h-2"],
        lg: ["dali-h-6 before:dali-h-4"],
      },
      checked: {
        true: ["dali-border-malachite-700 before:dali-bg-malachite-700"],
        false: ["dali-border-base-black"],
      },
      disabled: {
        true: [],
        false: [],
      },
    },
    compoundVariants: [
      {
        disabled: true,
        checked: true,
        className: "dali-border-carbon-300 before:dali-bg-carbon-300",
      },
      {
        disabled: true,
        checked: false,
        className: "dali-border-carbon-300",
      },
    ],
  },
);

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  ({ checked, disabled, id, value, size = "lg", label, ...rest }, ref) => {
    const { className: hash, ...pass } = rest;

    const idToUse = useId("radio", rest);
    const labelId = `${idToUse}-label`;

    return (
      <div
        className={cx(
          "dali-flex dali-items-center dali-gap-2 dali-group",
          {
            "dali-cursor-pointer": !disabled,
            "dali-cursor-not-allowed": disabled,
          },
          hash,
        )}
      >
        <div className={twMerge(radioStyles({ size, checked, disabled }))}>
          <input
            id={id || idToUse}
            type="radio"
            className={cx(
              "mg-radio__native-control",
              "dali-absolute dali-inset-0 dali-appearance-none",
              {
                "dali-cursor-pointer": !disabled,
                "dali-cursor-not-allowed": disabled,
              },
            )}
            value={value}
            disabled={disabled}
            checked={checked}
            ref={ref}
            {...pass}
          />
        </div>
        <label
          id={labelId}
          htmlFor={idToUse}
          className={cx({
            "dali-cursor-pointer": !disabled,
            "dali-cursor-not-allowed": disabled,
          })}
        >
          <Typography
            className={cx("dali-transition-colors", {
              "dali-text-carbon-300": disabled,
              "dali-text-base-black": !disabled,
            })}
            size={size === "lg" ? "lg" : "base"}
          >
            {label}
          </Typography>
        </label>
      </div>
    );
  },
);

Radio.displayName = "Radio";
