import { Icon, Logo, type Option, Typography, ButtonNew } from "@mg/dali/src";
import { type MyNetworkSummaryProfile } from "@mg/schemas/src/christo/myNetwork";
// eslint-disable-next-line import/named
import { createRoute, redirect } from "@tanstack/react-router";
import cx from "classnames";
import { useEffect, useReducer, useState } from "react";
import { z } from "zod";

import { CsvUploadCard } from "../../components/CsvUploadCard";
import { HighlightModal } from "../../components/HighlightModal";
import { InviteCreativeCard } from "../../components/InviteCreativeCard";
import { InviteDialog } from "../../components/InviteDialog";
import { NetworkSummaryCard } from "../../components/NetworkSummaryCard";
import { useUI } from "../../contexts/ui";
import {
  canAccessCreativeConnect,
  canAccessEditOwnProfile,
  canAccessLists,
  canAccessPuntt,
  requiresAuth,
} from "../../utils/auth";
import { useAppSelector } from "../../utils/hooks";
import { useNetworkSearch } from "../../utils/hooks/useNetworkSearch";
import { searchParser } from "../../utils/parsers/search";
import { setSelections } from "../../utils/slices/network";
import { store } from "../../utils/store";
import { authLayoutRoute } from "../auth-layout/route";
import { listsRoute } from "../lists/route";
import { ticketsRoute } from "../tickets/route";
import { aboutMeEditRoute } from "../userProfile/routes/AboutMe/editRoute";

export const networkSearchParser = searchParser.extend({
  specialties: z.array(z.string()).optional().default([]),
  skills: z.array(z.string()).optional().default([]),
  deliverables: z.array(z.string()).optional().default([]),
  tools: z.array(z.string()).optional().default([]),
  tags: z.array(z.string()).optional().default([]),
  timezone: z.array(z.string()).optional().default([]),
  level: z.array(z.number()).optional().default([]),
  brands: z.array(z.string()).optional().default([]),
  names: z.array(z.string()).optional().default([]),
  queries: z.array(z.string()).optional().default([]),
  industries: z.array(z.string()).optional().default([]),
});

export type NetworkSearch = z.infer<typeof networkSearchParser>;

export function toConceptObject(value: string) {
  return {
    label: value,
    value,
  };
}

export function toValue(value: Option) {
  return String(value.value);
}

export const myNetworkRoute = createRoute({
  getParentRoute: () => authLayoutRoute,
  path: "network",
  beforeLoad() {
    requiresAuth();

    if (!canAccessCreativeConnect()) {
      if (canAccessPuntt()) {
        throw redirect({
          to: ticketsRoute.to,
        });
      }

      if (canAccessEditOwnProfile()) {
        throw redirect({
          to: aboutMeEditRoute.to,
        });
      }

      if (canAccessLists()) {
        throw redirect({
          to: listsRoute.to,
        });
      }
    }

    store.dispatch(setSelections([]));
  },
  validateSearch(search: Record<string, unknown>) {
    return networkSearchParser.parse(search);
  },
  component: function Component() {
    const user = useAppSelector((state) => state.auth.value);
    const networkData = useAppSelector((state) => state.network.value);
    const { networkMutation } = useNetworkSearch();
    const { rootRef } = useUI();
    const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
    const [cardsPerRow, setCardsPerRow] = useState(0);

    useEffect(() => {
      const calculateCardsPerRow = () => {
        if (rootRef?.current) {
          const containerWidth = rootRef.current.offsetWidth - 72;
          const cardWidth = 275;
          const cards = Math.floor((containerWidth + 24) / (cardWidth + 24));

          setCardsPerRow(cards);
        }
      };

      calculateCardsPerRow();

      window.addEventListener("resize", calculateCardsPerRow);

      return () => {
        window.removeEventListener("resize", calculateCardsPerRow);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sectionActions = (
      <button
        onClick={() => setInviteDialogOpen(true)}
        className="hidden items-center gap-2 rounded-full bg-vermillion-700 px-3 py-2.5 text-base-white shadow-lg group-first-of-type:flex"
        data-testid="section-add-cta"
      >
        <Icon.PlusCircle />
        <Typography weight="medium">Add Creative</Typography>
      </button>
    );

    function renderContents() {
      if (networkData != null) {
        if (networkData.all.total === 0) {
          return (
            <div className="grid gap-6">
              <Typography size="3xl" weight="bold">
                No Results
              </Typography>
              <Typography>
                There aren&apos;t any great matches for your current search.{" "}
              </Typography>

              <section className="hidden grid-cols-2 gap-8">
                <InviteCreativeCard />
                <CsvUploadCard />
              </section>
            </div>
          );
        }

        const enterpriseName =
          user?.enterpriseName ?? user?.company ?? "Enterprise";
        const { all, recommendations } = networkData;
        const { results } = all;
        const workedWith = results.filter(
          (creative) => !!creative.hasWorkedWith,
        );
        const workedWithEnterprise = results.filter(
          (creative) =>
            !!creative.hasWorkedWithEnterprise && !creative.hasWorkedWith,
        );
        const moreInNetwork = results.filter(
          (creative) =>
            !creative.hasWorkedWith && !creative.hasWorkedWithEnterprise,
        );
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const recommended = recommendations.results;

        return (
          <>
            <NetworkSection
              title="Worked with Me"
              creatives={workedWith}
              actions={sectionActions}
              data-testid="worked-with-me"
              cardsPerRow={cardsPerRow}
            />
            <NetworkSection
              title={`Worked with ${enterpriseName}`}
              creatives={workedWithEnterprise}
              actions={sectionActions}
              data-testid="worked-with-enterprise"
              cardsPerRow={cardsPerRow}
            />
            <NetworkSection
              title={`More in ${enterpriseName}'s Network`}
              creatives={moreInNetwork}
              actions={sectionActions}
              data-testid="more-in-network"
              cardsPerRow={cardsPerRow}
            />
            {/*
            <NetworkSection
              title="People You May Know"
              creatives={recommended}
              actions={sectionActions}
              data-testid="people-you-may-know"
              tutorial={tutorial("moreInNetwork")}
              cardsPerRow={cardsPerRow}
              renderCount={false}
            />
            */}
          </>
        );
      }

      if (networkMutation.error != null) {
        return (
          <figure className="fixed left-1/2 top-1/2 grid -translate-x-1/2 -translate-y-1/2 justify-center text-vermillion-800">
            <Icon.SmileyXEyes size={64} weight="fill" className="mx-auto" />
            <Typography size="2xl" weight="medium">
              Error loading your network. Please try again later.
            </Typography>
          </figure>
        );
      }

      return (
        <figure className="fixed left-1/2 top-1/2 grid -translate-x-1/2 -translate-y-1/2 justify-center">
          <Logo variant="black" type="logo" className="mx-auto" />
          <Typography>Loading your network</Typography>
        </figure>
      );
    }

    return (
      <section
        ref={rootRef}
        className="group grid gap-6 px-5 pb-4 pt-0 md:px-9 md:py-4"
      >
        <InviteDialog
          open={inviteDialogOpen}
          onOpenChange={(o) => {
            setInviteDialogOpen(o);
          }}
        />

        {renderContents()}
      </section>
    );
  },
});

type NetworkSectionProps = {
  title: string;
  actions: React.ReactNode;
  creatives: MyNetworkSummaryProfile[];
  tutorial?: { cta: boolean; card: boolean };
  cardsPerRow: number;
  showFull?: boolean;
  renderCount?: boolean;
  isDiscover?: boolean;
  "data-testid": string;
};

export function NetworkSection({
  actions,
  creatives,
  title,
  tutorial,
  cardsPerRow,
  showFull = false,
  renderCount = true,
  isDiscover = false,
  ...rest
}: NetworkSectionProps) {
  const count = creatives.length ?? 0;
  const [showAll, toggleShowAll] = useReducer((x) => !x, showFull);
  const [clickedCount, setClickedCount] = useState(1);

  if (count === 0) {
    return null;
  }

  return (
    <>
      <header
        className="flex items-center justify-between"
        data-testid={`${rest["data-testid"]}-header`}
      >
        <Typography as="h1" size="4xl" weight="bold">
          {title}{" "}
          <Typography
            as="span"
            className={cx("text-carbon-400", {
              hidden: !renderCount,
            })}
          >
            ({count})
          </Typography>
        </Typography>
        {title !== "People You May Know" && (
          <HighlightModal
            tooltipPosition={{ top: 60, right: 0 }}
            order={tutorial?.cta ? 3 : 7}
          >
            {actions}
          </HighlightModal>
        )}
      </header>

      <article
        className="grid grid-cols-[repeat(auto-fill,minmax(275px,1fr))] gap-6"
        data-testid={`${rest["data-testid"]}-cards`}
      >
        {(showAll
          ? creatives
          : creatives.slice(
              0,
              cardsPerRow * (isDiscover ? clickedCount * 2 : 1),
            )
        ).map((creative, i) => {
          if (tutorial?.card && i === 0)
            return (
              <HighlightModal
                tooltipPosition={{ bottom: 50, right: -500 }}
                order={2}
                key={creative._id}
              >
                <NetworkSummaryCard
                  key={creative._id}
                  presentation="grid"
                  {...creative}
                />
              </HighlightModal>
            );
          return (
            <NetworkSummaryCard
              key={creative._id}
              presentation="grid"
              {...creative}
            />
          );
        })}
      </article>
      {isDiscover && count >= clickedCount * 2 * cardsPerRow && (
        <ButtonNew
          variant="ghost"
          className="mx-auto"
          onClick={() => setClickedCount((curr) => curr + 1)}
          data-testid={
            showAll ? "network-section-show-less" : "network-section-show-more"
          }
        >
          Discover More
        </ButtonNew>
      )}
      {count > cardsPerRow && !showFull && (
        <ButtonNew
          variant="ghost"
          className={cx("mx-auto", {
            hidden: isDiscover || title === "People You May Know",
          })}
          onClick={() => toggleShowAll()}
          data-testid={
            showAll ? "network-section-show-less" : "network-section-show-more"
          }
        >
          Show {!showAll ? "More" : "Less"}
        </ButtonNew>
      )}
    </>
  );
}
