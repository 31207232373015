import {
  type BaseTicketComment,
  type CreateTicketCommentRequest,
  type GetTicketCommentsResponse,
} from "@mg/schemas/src/christo/catalyst";
import {
  EnterpriseProfileType,
  TicketCommentBoard,
  TicketCommentDisposition,
} from "@mg/schemas/src/commons";
import { X } from "@phosphor-icons/react";
import { Checkbox, Flex, IconButton, Select, Text } from "@radix-ui/themes";
import {
  HTMLContainer,
  Rectangle2d,
  ShapeUtil,
  type RecordProps,
  type TLBaseShape,
  T,
  stopEventPropagation,
  useIsEditing,
  type TLShapeId,
} from "@tldraw/tldraw";
import cx from "classnames";
import { useEffect, useRef, useState, useMemo, useCallback } from "react";

import { useUI } from "../../../../contexts/ui";
import { useAnalytics } from "../../../../utils/analytics";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks";
import {
  useCreateAskPunttComment,
  useNewCommentMutation,
} from "../../../../utils/queries/projects";
import { getAiRuleMap } from "../../../../utils/selections";
import {
  setActiveCommentId,
  setComments,
  setVisibleCommentShapes,
} from "../../../../utils/slices/ticket";
import { store } from "../../../../utils/store";
import { useGetVisibleUsers } from "../../../../utils/tldraw/comments";
import { useReversedIndex } from "../../../../utils/tldraw/revisions";
import { Comment } from "../../routes/components/comment-drawer/Comment";
import { useTicket } from "../../routes/ticket";
import {
  CommentMentions,
  type Selections,
} from "../CommentMentions/CommentMentions";

type TicketComment = GetTicketCommentsResponse[number];

type CommentShape = TLBaseShape<
  "comment",
  {
    w: number;
    h: number;
    commentId: unknown;
    isLocked?: boolean;
  }
>;

export class CommentShapeUtil extends ShapeUtil<CommentShape> {
  static override type = "comment" as const;

  static override props: RecordProps<CommentShape> = {
    w: T.number,
    h: T.number,
    commentId: T.unknown,
    isLocked: T.boolean.optional(),
  };

  // [3]
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  override isAspectRatioLocked = (_shape: CommentShape) => false;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  override canResize = (_shape: CommentShape) => false;
  override hideRotateHandle = (_shape: CommentShape) => true;
  override canBind = () => true;

  // [4]
  override canEdit = () => true;

  // [5]
  getDefaultProps(): CommentShape["props"] {
    return {
      w: 0,
      h: 0,
      commentId: null,
      isLocked: false,
    };
  }

  private isVisible(shape: CommentShape, userId?: string) {
    const user = store.getState().auth.value;

    try {
      if (typeof shape.meta?.comment !== "string") {
        return true;
      }

      const comment = JSON.parse(shape.meta.comment) as TicketComment;

      if (
        user?.role != "ai" &&
        user?.role != "meaningful-gigs" &&
        comment.disposition == TicketCommentDisposition.DISMISSED
      ) {
        return false;
      }
      const createdById = String(
        typeof comment.createdBy === "object"
          ? comment.createdBy._id
          : comment.createdBy,
      );
      return !comment.isPending || createdById === String(userId);
    } catch {
      return true;
    }
  }

  // [6]
  getGeometry(shape: CommentShape) {
    const zoomLevel = this.editor.getZoomLevel();

    return new Rectangle2d({
      width: shape.props.w / zoomLevel,
      height: shape.props.h / zoomLevel,
      isFilled: true,
    });
  }

  component = (shape: CommentShape) => {
    const zoomLevel = this.editor.getZoomLevel();
    const user = store.getState().auth.value;
    const [required, setRequired] = useState<boolean | "indeterminate">(true);
    const [rule, setRule] = useState("no_rule");
    const mentionsRef = useRef<{
      getSelections: () => Selections;
      closeMentions: () => void;
    }>();
    const containerRef = useRef<HTMLDivElement>(null);
    const handleGetSelections = () => {
      if (mentionsRef.current) {
        return mentionsRef.current.getSelections();
      }
    };

    const posthog = useAnalytics("CommentTool");
    const {
      value: ticket,
      comments,
      visibleCommentShapes,
    } = useAppSelector((state) => state.ticket);
    const dispatch = useAppDispatch();
    const activeRevisionIndex = useReversedIndex();
    const selectedShapeIds = this.editor.getSelectedShapeIds();
    const isSelected =
      selectedShapeIds.includes(shape.id) ||
      selectedShapeIds.includes(shape.meta?.linkedAvatarId as string);
    const { tab = 0 } = useTicket();
    const users = useGetVisibleUsers();

    const aiRuleMap = getAiRuleMap();

    const [isLoading, setIsLoading] = useState(false);

    const [message, setMessage] = useState<string>("");
    const mutation = useNewCommentMutation();
    const askPunttMutation = useCreateAskPunttComment();

    const { notify } = useUI();

    const getBoardInfo = useCallback(() => {
      if (!isSelected) {
        return { boardType: null, boardId: "", userIsReviewing: false };
      }

      const currentBoard = ticket?.workflow.steps[tab];
      let boardType: TicketCommentBoard;
      let boardId: string;
      let userIsReviewing = false;

      if (currentBoard?.type === "review") {
        boardType = TicketCommentBoard.REVISION;
        boardId = ticket?.revisionBoards?.[activeRevisionIndex]?._id as string;
        if (comments.length > 0) {
          userIsReviewing = comments.some(
            (c) => c.isPending && c.createdBy === user?.userID,
          );
        }
      } else {
        boardType = TicketCommentBoard.BRIEF;
        boardId = ticket?.briefBoard?._id as string;
      }

      return { boardType, boardId, userIsReviewing };
    }, [ticket, tab, activeRevisionIndex, comments, user, isSelected]);

    useEffect(() => {
      const parsedComment = (shape.meta?.comment &&
        JSON.parse(shape.meta.comment as string)) as TicketComment;

      const newSize = isSelected
        ? {
            w: 300,
            h: 150,
          }
        : { w: 0, h: 0 };
      // shape wont change inside of render, so must define the changes here to user it with parsed origin point
      const shapeWithSize = { ...shape, props: newSize };
      if (shape.props.w !== newSize.w) {
        this.editor.updateShape(shapeWithSize);
      }
      if (isSelected) {
        this.editor.bringToFront([
          shape.id,
          shape.meta.linkedAvatarId as TLShapeId,
        ]);
        if (parsedComment?._id) {
          dispatch(setActiveCommentId(parsedComment?._id as string));
        }
      } else {
        if (!parsedComment) {
          this.editor.deleteShapes([
            shape.id,
            shape.meta.linkedAvatarId as TLShapeId,
          ]);
        } else {
          dispatch(setActiveCommentId(null));
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSelected]);

    useEffect(() => {
      if (mentionsRef.current) {
        mentionsRef.current?.closeMentions();
      }
    }, [zoomLevel]);

    useEffect(() => {
      if (!isSelected) {
        const parentShape = this.editor.getShape(
          shape.meta.linkedAvatarId as TLShapeId,
        );
        if (!parentShape) {
          return;
        }

        this.editor.updateShape({
          ...shape,
          props: {
            ...shape.props,
            w: 0,
            h: 0,
          },
          x: parentShape.x,
          y: parentShape.y + 25 / zoomLevel,
        });
        return;
      }
      const observer = new ResizeObserver(() => {
        if (containerRef.current) {
          const { height: shapeHeight } =
            containerRef.current.getBoundingClientRect();
          const parentShape = this.editor.getShape(
            shape.meta.linkedAvatarId as TLShapeId,
          );
          if (!parentShape) {
            return;
          }
          let x = shape.x;
          let y = shape.y;
          const viewport = this.editor.getViewportScreenBounds();

          // 600 because the comment tool is 300px wide and we double it to take the sidebar into account
          if (x == parentShape.x && parentShape.x + 600 > viewport.width) {
            x = parentShape.x - 300 / zoomLevel;
          }
          const { originScreenPoint } = this.editor.inputs;

          if (originScreenPoint.y > viewport.height - viewport.y) {
            // we have to account for the zoom level which effects the size of the comment box
            y = parentShape.y - shapeHeight / zoomLevel;
          }

          this.editor.updateShape({
            ...shape,
            x,
            y,
          });
        }
      });

      if (containerRef.current) {
        observer.observe(containerRef.current);
      }

      // Clean up the observer when the component unmounts
      return () => {
        if (containerRef.current) {
          observer.unobserve(containerRef.current);
        }
      };
    }, [isSelected, shape, zoomLevel]);

    this.editor.addListener("event", (e) => {
      if (e.type === "wheel" && mentionsRef.current) {
        mentionsRef.current?.closeMentions();
      }
    });

    const comment = useMemo(() => {
      if (!shape.meta?.comment) {
        return null;
      }
      const parsedComment = JSON.parse(
        shape.meta.comment as string,
      ) as BaseTicketComment;

      return parsedComment;
    }, [shape.meta?.comment]);

    const ticketId = ticket?._id;

    if (
      shape.props.w == 0 ||
      shape.props.h == 0 ||
      !isSelected ||
      !this.isVisible(shape, user?.userID)
    ) {
      return (
        <HTMLContainer
          id={shape.id}
          style={{
            width: 0,
            height: 0,
          }}
        />
      );
    }

    const handleChange = (event: string) => {
      setMessage(event);
    };

    return (
      <HTMLContainer
        id={shape.id}
        key={shape.id}
        style={{
          transform: `scale(${1 / zoomLevel})`,
          width: shape.props.w,
          position: "relative",
          zIndex: 8600,
        }}
        className="comment-container"
      >
        <div
          role="button"
          tabIndex={0}
          className="cursor-default"
          style={{
            border: "1px solid rgba(0, 0, 0, 0.1)",
            boxShadow:
              "0px 1px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.04)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            pointerEvents: "all",
            backgroundColor: "white",
            position: "relative",
            marginLeft: -2, // accounts for the border
          }}
          onKeyDown={stopEventPropagation}
          ref={containerRef}
        >
          <Flex className="h-6 items-center justify-end border-b border-puntt-neutral-gray-6 pr-[3px]">
            <IconButton
              size="1"
              variant="ghost"
              onClick={() => {
                this.editor.selectNone();
                dispatch(setActiveCommentId(null));
              }}
              className="cursor-pointer"
              onPointerDown={stopEventPropagation}
            >
              <X size={16} color="rgb(var(--base-black))" />
            </IconButton>
          </Flex>
          {!!comment && (
            <div
              className="h-full overflow-auto border-b py-4"
              onPointerDown={stopEventPropagation}
            >
              <Comment
                mentions={comment.mentions as string[]}
                key={comment._id}
                author={
                  comment.createdBy as Exclude<typeof comment.createdBy, string>
                }
                createdAt={comment.createdAt}
                isAI={comment.isAI}
                message={comment.description}
                messageId={comment._id}
                commentId={comment._id}
                meta={comment}
                replies={comment.messages}
                updatedAt={comment.updatedAt}
                rule={comment.rule}
                onSuccess={(data) => {
                  if (data != null) {
                    if (
                      user?.role != "ai" &&
                      user?.role != "meaningful-gigs" &&
                      (data.disposition == TicketCommentDisposition.DISMISSED ||
                        data.disposition == TicketCommentDisposition.RESOLVED)
                    ) {
                      this.editor.deleteShapes([shape.id]);
                    }
                    const replaceCommentIndex = comments.findIndex(
                      (comment) => comment._id === data._id,
                    );
                    const commentsCopy = [...comments];
                    commentsCopy.splice(replaceCommentIndex, 1, data);

                    dispatch(setComments(commentsCopy));
                  }
                  this.editor.bringToFront([shape.id]);
                  posthog.capture("added_new_comment", {
                    isAI: data.isAI,
                    comment: data,
                  });
                  this.editor.updateShape({
                    ...shape,
                    meta: {
                      ...shape.meta,
                      comment: JSON.stringify(data),
                    },
                    props: {
                      ...shape.props,
                      commentId: data._id,
                    },
                  });
                }}
                editor={this.editor}
                users={users}
              />
            </div>
          )}

          {!comment && (
            <div
              className="flex flex-col gap-4 p-4"
              onPointerDown={stopEventPropagation}
            >
              <CommentMentions
                value={message}
                ref={mentionsRef}
                onChange={handleChange}
                placeholder="Add a comment"
                disabled={
                  mutation.isPending || !message.trim().length || isLoading
                }
                loading={mutation.isPending || isLoading}
                onSend={async () => {
                  if (isLoading) return;
                  setIsLoading(true);

                  const { boardType, boardId, userIsReviewing } =
                    getBoardInfo();
                  const selections = handleGetSelections();
                  const parsedOriginPoint =
                    shape.meta?.originalCoords &&
                    (JSON.parse(shape.meta.originalCoords as string) as {
                      x?: number;
                      y?: number;
                    });

                  const payload: CreateTicketCommentRequest["body"] = {
                    description: message,
                    x: parsedOriginPoint ? parsedOriginPoint.x : shape.x,
                    y: parsedOriginPoint
                      ? (parsedOriginPoint?.y ?? 0) - 26 / zoomLevel
                      : shape.y - 26 / zoomLevel, // minus 26px is needed for the offset since we're creating the comment pin shape differently
                    boardId,
                    boardType: boardType as TicketCommentBoard,
                    isPending: userIsReviewing,
                    isRequired: required as boolean,
                    rule: rule === "no_rule" ? undefined : rule,
                  };
                  const sisterShape = this.editor.getShape(
                    shape.meta.linkedAvatarId as TLShapeId,
                  );
                  const onSuccess = (data: BaseTicketComment) => {
                    this.editor.bringToFront([shape.id]);

                    this.editor.updateShapes([
                      {
                        ...shape,
                        meta: {
                          ...shape.meta,
                          comment: JSON.stringify(data),
                        },
                        props: {
                          ...shape.props,
                          commentId: data._id,
                        },
                      },
                      // @ts-expect-error TS2322: tlshape type isn't expecting meta here
                      {
                        ...sisterShape,
                        meta: {
                          ...sisterShape?.meta,
                          comment: JSON.stringify(data),
                        },
                      },
                    ]);
                    const commentWithShapeId = {
                      ...data,
                      shapeId: shape.id,
                    };
                    dispatch(
                      setVisibleCommentShapes([
                        ...visibleCommentShapes,
                        commentWithShapeId,
                      ]),
                    );

                    dispatch(setComments([...comments, data]));
                  };

                  if (selections?.find((s) => s._id === "@puntt")) {
                    const tempCommentData = {
                      ...payload,
                      _id: (Math.random() * 10000).toString(),
                      createdBy: {
                        _id: user?.userID,
                        name: user?.name ?? "Anonymous",
                        avatar: user?.avatar,
                      },
                      createdAt: new Date().toISOString(),
                      updatedAt: new Date().toISOString(),
                      isDeleted: false,
                      isReview: false,
                      votes: [],
                      isAI: false,
                      mentions: [],
                      messages: [
                        {
                          createdBy: {
                            _id: "63bc994226c323d41d67a11f",
                            name: "AI Reviewer",
                            avatar:
                              "users/615f5203e1cb445ef2486b74/3-color-logo-white-bg-square.png",
                          },
                          aiPersona: {
                            name: "",
                            avatar: "",
                          },
                          description: "",
                          createdAt: new Date().toISOString(),
                          updatedAt: new Date().toISOString(),
                          isDeleted: false,
                          isPending: false,
                          isReview: false,
                          votes: [],
                          isAI: true,
                          reviewId: "67082ad6ecce06a419fe876d",
                          mentions: [],
                          messages: [],
                          x: 20,
                          y: 20,
                          isRequired: false,
                          boardType: "revisionBoard",
                          boardId: "6706ca0b6a3ca36a292610a5",
                          disposition: "default",
                          _id: (Math.random() * 100000).toString(),
                          aiPending: true,
                        },
                      ],
                      isRequired: required as boolean,
                      disposition: "default" as TicketCommentDisposition,
                    };
                    onSuccess(tempCommentData);
                    setIsLoading(false);
                    setMessage("");
                    // this is where we will have the call to get the ai response and then we will update as below
                    return askPunttMutation
                      .mutateAsync({
                        ticketId: ticketId as string,
                        description: message,
                        x: payload.x as number,
                        y: payload.y as number,
                        boardId,
                        shapeIds: [],
                      })
                      .then((res) => {
                        // @ts-expect-error TS2345: _id can never be undefined
                        onSuccess(res);
                      })
                      .catch(() => {
                        dispatch(setComments(comments));
                        const deletingShapes = [shape.id];

                        if (sisterShape != null) {
                          deletingShapes.push(sisterShape.id);
                        }

                        this.editor.deleteShapes(deletingShapes);
                        notify({
                          message: "An error occurred while asking Puntt AI",
                          timeout: 8000,
                          variant: "error",
                        });
                      })
                      .finally(() => {
                        setMessage("");
                        setIsLoading(false);
                      });
                  }

                  if (selections?.length) {
                    payload.mentions = selections.map((s) => s._id as string);
                  }
                  // @ts-expect-error TS2339: comment is not actually type
                  // `never`
                  if (comment != null && comment._id) {
                    // @ts-expect-error TS2339: comment is not actually type
                    // `never`
                    payload.commentId = comment._id;
                  }

                  setMessage("");

                  mutation.mutate(
                    {
                      ticketId: ticketId as string,
                      payload,
                    },
                    {
                      onSuccess: (data) => {
                        posthog.capture("added_new_comment", {
                          isAI: data.isAI,
                          comment: data,
                        });

                        onSuccess(data);
                      },

                      onSettled() {
                        setIsLoading(false);
                      },
                    },
                  );
                }}
              />

              <Text
                as="label"
                className={cx("text-base-black", {
                  hidden:
                    user?.role === EnterpriseProfileType.CATALYST_REQUESTER ||
                    user?.role === EnterpriseProfileType.CATALYST_CREATIVE,
                })}
              >
                <Flex gap="2" className="items-center">
                  <Checkbox
                    defaultChecked={
                      user?.role !== EnterpriseProfileType.CATALYST_REQUESTER &&
                      user?.role !== EnterpriseProfileType.CATALYST_CREATIVE
                    }
                    onCheckedChange={setRequired}
                  />
                  Required Change
                </Flex>
              </Text>
              <Select.Root size="3" value={rule} onValueChange={setRule}>
                <Select.Trigger
                  className={cx("w-full", {
                    hidden:
                      (user?.role !== EnterpriseProfileType.MEANINGFUL_GIGS &&
                        user?.role !== EnterpriseProfileType.CATALYST_AI) ||
                      !required,
                  })}
                  variant="surface"
                  color="gray"
                />
                <Select.Content>
                  <Select.Item value="no_rule">No Rule</Select.Item>
                  {Object.keys(aiRuleMap).map((key) => (
                    <Select.Item key={key} value={key}>
                      {aiRuleMap[key]}
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select.Root>
            </div>
          )}
        </div>
      </HTMLContainer>
    );
  };

  // [8]
  indicator = (shape: CommentShape) => {
    const isEditing = useIsEditing(shape.id);
    const zoomLevel = this.editor.getZoomLevel();
    const isSelected = this.editor.getSelectedShapeIds().includes(shape.id);

    if (isSelected) return null;
    return (
      <rect
        stroke={
          isEditing ? "rgb(var(--puntt-red-5))" : "rgb(var(--puntt-blue-7))"
        }
        width={shape.props.w / zoomLevel}
        height={shape.props.h / zoomLevel}
      />
    );
  };
}
