// eslint-disable-next-line import/named, @typescript-eslint/no-unused-vars
import { Route, Outlet, createRoute } from "@tanstack/react-router";

import { requiresAuth } from "../../utils/auth";
import { rootRoute } from "../root/route";

export const onboardingRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "onboarding/steps",
  beforeLoad() {
    requiresAuth();
  },
  component: function Component() {
    return <Outlet />;
  },
});
