import { Badge, Grid, Heading } from "@radix-ui/themes";
import { type ReactNode, type FC, type PropsWithChildren } from "react";

import LoginMockup from "../../images/login-mockup.png";
import { ReactComponent as Logo } from "../../images/Logo.svg";

// this layout component makes use of children instead of `<Outlet />` because
// it is not mounted to a Route, and instead is used like a React component.
export const LoginLayout: FC<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { customCaption?: ReactNode } & PropsWithChildren<any>
> = ({ customCaption, children }) => {
  return (
    <section className="box-content grid h-screen sm:grid-cols-[384px,1fr]">
      <article
        className={`p-6 sm:p-12 ${children ? "grid auto-rows-max gap-12 xs:gap-5" : ""}`}
      >
        <Logo className="hidden xs:block" />
        <hr className="hidden border-base-black sm:block" />
        {children}
      </article>
      <figure className="hidden max-w-96 place-content-center gap-5 overflow-hidden sm:grid md:mx-24">
        {customCaption ?? (
          <>
            <Heading as="h2" size="8" weight="regular">
              AI-powered reviews for faster, better creative output.
            </Heading>

            <Grid gap="2">
              <Badge
                size="1"
                color="amber"
                variant="solid"
                className="max-w-max"
              >
                Step 1
              </Badge>

              <Heading size="7" weight="regular">
                Upload your assets
              </Heading>
            </Grid>

            <Grid gap="2">
              <Badge
                size="1"
                color="amber"
                variant="solid"
                className="max-w-max"
              >
                Step 2
              </Badge>

              <Heading size="7" weight="regular">
                Get AI feedback
              </Heading>
            </Grid>

            <Grid gap="2">
              <Badge
                size="1"
                color="amber"
                variant="solid"
                className="max-w-max"
              >
                Step 3
              </Badge>

              <Heading size="7" weight="regular">
                Collaborate
              </Heading>
            </Grid>
          </>
        )}
      </figure>
      <img
        alt=""
        src={LoginMockup}
        className="fixed right-10 top-1/2 hidden max-w-md -translate-y-1/2 lg:block"
      />
    </section>
  );
};
