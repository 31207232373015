import { z } from "zod";

import { assetSchema } from "./asset";
import { contractTypeSchema, billingMethodSchema } from "./contract";

export const reportSchema = z.object({
  _id: z.string().optional(),
  createdAt: z.date().optional(),
  title: z.string(),
  project: z.string().optional(),
  description: z.string(),
  reportYear: z.number(),
  reportWeek: z.number(),
  images: z.array(assetSchema).optional(),
  contractType: contractTypeSchema.optional(),
  billingMethod: billingMethodSchema,
  bookingsAmount: z.number().optional(),
  payoutAmount: z.number().optional(),
  clientRate: z.number().optional(),
  talentRate: z.number().optional(),
  hoursSpent: z.number().optional(),
});

export type Report = z.infer<typeof reportSchema>;
