import { z } from "zod";

export const queryStringSchema = z.preprocess((obj) => {
  if (Array.isArray(obj)) {
    return obj;
  } else if (typeof obj === "string") {
    return obj.split(",");
  } else {
    return [];
  }
}, z.coerce.string().array());

const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
  if (issue.code === z.ZodIssueCode.too_small) {
    if (issue.type === "string") {
      let ref = (issue.path[issue.path.length - 1] as string) || "this";
      // make first letter uppercase
      ref = ref.charAt(0).toUpperCase() + ref.slice(1);
      return {
        message: `${ref} must contain ${
          issue.exact ? "exactly" : issue.inclusive ? `at least` : `over`
        } ${issue.minimum} character(s)`,
      };
    }
  }
  if (issue.code === z.ZodIssueCode.invalid_type) {
    if (issue.received === z.ZodParsedType.undefined) {
      return { message: "This field is required." };
    }

    if (issue.expected === "number") {
      return { message: "Invalid number" };
    }
  }

  return { message: ctx.defaultError };
};

z.setErrorMap(customErrorMap);
