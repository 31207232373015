import { ButtonNew, Card, Typography } from "@mg/dali/src";
import { type MyNetworkProfile } from "@mg/schemas/src/christo/myNetwork";
import cx from "classnames";
import { useState } from "react";

import { AvatarWithInitials } from "../../../components/AvatarWithInitials";
import { LabeledMappedChips } from "../../../components/LabeledMappedChips";
import { useAppSelector } from "../../../utils/hooks";
import { useUpdateTags } from "../../../utils/queries/profile";
import { Recommendations } from "../../profile/components/Recommendations";

export function ProfessionalDetails(props: MyNetworkProfile) {
  const tagsMutation = useUpdateTags(props._id);
  const user = useAppSelector((state) => state.auth.value);
  const [showWorkedWith, setShowWorkedWith] = useState(false);
  // @ts-expect-error TS2367: Incorrect BE response.
  if (props.type === "pipeline") {
    return null;
  }

  const isCreative =
    !user?.permissions?.includes("enterprise-team") &&
    !user?.permissions?.includes("enterprise-admin");

  function handleSaveTags(tags: string[]) {
    return tagsMutation.mutateAsync({ portfolioId: props._id, tags });
  }

  return (
    <Card
      size="sm"
      type="outlined"
      className="relative inline-grid w-full gap-6 rounded-2xl"
      uninteractive
    >
      <article className="grid w-full gap-6">
        <div className="flex items-start justify-between">
          <Typography size="xl" weight="medium" className="text-carbon-900">
            People {props.name.split(" ")?.[0]} has worked with at{" "}
            {user?.company}
          </Typography>
          {props.workedWith && props.workedWith?.length > 4 ? (
            <ButtonNew
              className="min-w-fit"
              variant="ghost"
              onClick={() => setShowWorkedWith(!showWorkedWith)}
            >
              {showWorkedWith
                ? "View Less"
                : `View All (${props.workedWith.length})`}
            </ButtonNew>
          ) : null}
        </div>
        {!props.workedWith?.length ? (
          <Typography>There are no team connections to show yet.</Typography>
        ) : (
          (showWorkedWith
            ? props.workedWith
            : props.workedWith?.slice(0, 4)
          )?.map((s) => (
            <section
              className="grid auto-rows-min grid-cols-[auto_1fr] gap-2.5"
              key={s._id}
            >
              <AvatarWithInitials name={s.name} avatar={s.avatar} />

              <Typography
                weight="medium"
                className="self-center text-base-black"
              >
                {s.name}
              </Typography>

              <div
                className={cx(
                  "h-max rounded px-0.5 text-center text-base-white",
                  {
                    "opacity-0": isCreative,
                    "bg-vermillion-600": s.rating ?? 0 < 7,
                    "bg-ochre-400": s.rating === 7 || s.rating === 8,
                    "bg-malachite-600": s.rating ?? 0 > 8,
                  },
                )}
              >
                <Typography size="sm" weight="bold">
                  {!isCreative ? s.rating : null}
                </Typography>
              </div>

              <Typography
                size="sm"
                weight="normal"
                className={cx({ hidden: isCreative })}
              >
                {isCreative ? null : s.note}
              </Typography>
            </section>
          ))
        )}
      </article>
      <div className="h-px w-full bg-carbon-300" />
      <div className="grid gap-y-4">
        <LabeledMappedChips
          title="Tools"
          values={props.tools}
          hideWhenEmpty
          searchChips
        />

        {!isCreative && (
          <LabeledMappedChips
            subheader={`These tags describe ${props.name} outside of their work. Add attributes like education, additional skills, soft skills, hobbies, talents, etc`}
            title="Custom Tags"
            values={props.tags}
            editable
            // @ts-expect-error TS2367: Incorrect Dali type.
            onSave={handleSaveTags}
            searchChips
          />
        )}

        {!isCreative && <Recommendations />}
      </div>
    </Card>
  );
}
