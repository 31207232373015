import { z } from "zod";

import { highlightSchema } from "../commons";

export const scrapeRequestSchema = z.object({
  body: z.object({
    url: z.string(),
  }),
});

export type ScrapeRequest = z.infer<typeof scrapeRequestSchema>;

export const scrapeProjectSchema = z.object({
  title: z.string(),
  url: z.string().optional(),
  s3: z.string().optional(),
});

export type ScrapeProject = z.infer<typeof scrapeProjectSchema>;

export const scrapeResponseSchema = z.object({
  skills: z.array(z.string()),
  industries: z.array(z.string()),
  deliverables: z.array(z.string()),
  brands: z.array(z.string()),
  projects: z.array(scrapeProjectSchema),
  bio: z.string().optional(),
  highlights: z.array(highlightSchema),
});

export type ScrapeResponse = z.infer<typeof scrapeResponseSchema>;
