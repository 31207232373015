import {
  type AddCreativeBody,
  type AddCreativeParams,
  addCreativeResponseSchema,
  type CreateBody,
  createResponseSchema,
  duplicateResponseSchema,
  getByIdResponseSchema,
  type RemoveParams,
  type UpdateBody,
  updateResponseSchema,
  type AddCreativesBody,
  type ShareWithOutsideOfNetworkBody,
  getNetworkListsResponseSchema,
} from "@mg/schemas/src/christo/creativeList";

import { API_URL } from "../config/env";
import { type CreativeSelection } from "../utils/hooks";
import { smartFetch } from "../utils/http";

//
// LISTS
//

/**
 * Returns all creative lists for the currently authenticated user. If there are
 * no lists, returns empty arrays for both `myLists` and `shared`.
 *
 * Note: this endpoint does not contain information about creatives. In order to
 * get detailed creative information, call `getList($listId)`
 */
export async function getLists() {
  const json = await smartFetch(`${API_URL}/private/creative-lists/network`, {
    method: "GET",
  });

  const parsedData = getNetworkListsResponseSchema.parse(json);
  return parsedData;
}

//
// LIST $id CRUD endpoints
//

/**
 * Returns a creative list given an ID. If no creative list is found, throws a
 * 404 error.
 */
export async function getList(listId: string) {
  const json = await smartFetch(`${API_URL}/private/creative-lists/${listId}`, {
    method: "GET",
  });

  const parsedData = getByIdResponseSchema.parse(json);
  return parsedData;
}

/**
 * Creates a list and returns only the newly created list.
 *
 * Note: this endpoint does not contain information about creatives. In order to
 * get detailed creative information, call `getList($listId)`
 */
export async function createList(
  payload: Omit<CreateBody, "creatives"> & {
    creatives?: CreativeSelection[] | undefined;
  },
) {
  const json = await smartFetch(`${API_URL}/private/creative-lists`, {
    method: "POST",
    body: JSON.stringify(payload),
  });

  const parsedData = createResponseSchema.parse(json);
  return parsedData;
}

/**
 * Updates a creative list given an ID and a payload, and returns only the
  type CreateResponse,
 * updated list. If no creative list is found, throws a 404 error.
 */
export async function updateList({
  listId,
  payload,
}: {
  listId: string;
  payload: UpdateBody;
}) {
  const json = await smartFetch(`${API_URL}/private/creative-lists/${listId}`, {
    method: "PUT",
    body: JSON.stringify(payload),
  });

  const parsedData = updateResponseSchema.parse(json);
  return parsedData;
}

/**
 * Archives a list given an ID, and returns nothing. If no creative list is
 * found, throws a 404 error.
 */
export async function deleteList(listId: string) {
  await smartFetch(`${API_URL}/private/creative-lists/${listId}`, {
    method: "DELETE",
  });

  return undefined;
}

//
// LIST $id MODIFIERS
//

/**
 * Shares a list to one or more emails, separated by a string. Returns nothing.
 */
export async function shareList({
  objectId,
  payload,
}: {
  objectId: string;
  payload: ShareWithOutsideOfNetworkBody;
}) {
  await smartFetch(
    `${API_URL}/private/creative-lists/network/${objectId}/share`,
    {
      method: "POST",
      body: JSON.stringify(payload),
    },
  );

  return undefined;
}

/**
 * Duplicates a list, optionally providing a new title to distinguish the
 * duplicated list from the original.
 *
 * Note: this endpoint does not contain information about creatives. In order to
 * get detailed creative information, call `getList($listId)`
 */
export async function duplicateList({
  listId,
  title,
}: {
  listId: string;
  title?: string;
}) {
  const json = await smartFetch(
    `${API_URL}/private/creative-lists/${listId}/duplicate`,
    {
      method: "POST",
      body: JSON.stringify({ title }),
    },
  );

  const parsedData = duplicateResponseSchema.parse(json);
  return parsedData;
}

/**
 * Adds a creative to a list, and returns the list.
 *
 * Note: this endpoint does not contain information about creatives. In order to
 * get detailed creative information, call `getList($listId)`
 */
export async function addCreativeToList(
  payload: AddCreativeParams & AddCreativeBody,
) {
  const { listId, creativeId, ...rest } = payload;
  const json = await smartFetch(
    `${API_URL}/private/creative-lists/${listId}/creative/${creativeId}`,
    {
      method: "PUT",
      body: JSON.stringify(rest),
    },
  );

  const parsedData = addCreativeResponseSchema.parse(json);
  return parsedData;
}

/**
 * Adds multiple creatives to a list, and returns the list.
 *
 * Note: this endpoint does not contain information about creatives. In order to
 * get detailed creative information, call `getList($listId)`
 */
export async function addCreativesToList({
  listId,
  payload,
}: {
  listId: string;
  payload: AddCreativesBody;
}) {
  const json = await smartFetch(
    `${API_URL}/private/creative-lists/${listId}/creatives`,
    {
      method: "PUT",
      body: JSON.stringify(payload),
    },
  );

  const parsedData = addCreativeResponseSchema.parse(json);
  return parsedData;
}

/**
 * Removes a creative from a list, and returns nothing.
 *
 * Note: this endpoint does not contain information about creatives. In order to
 * get detailed creative information, call `getList($listId)`
 */
export async function removeCreativeToList(params: RemoveParams) {
  const { listId, creativeId } = params;

  await smartFetch(
    `${API_URL}/private/creative-lists/${listId}/creative/${creativeId}`,
    {
      method: "DELETE",
    },
  );

  return undefined;
}
