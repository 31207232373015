import { z } from "zod";

export enum ConceptTypes {
  INDUSTRY = "industry",
  SKILL = "skill",
  TOOL = "tool",
  PARENT_SKILL = "parent-skill",
  SOFT_SKILL = "soft-skill",
  SPECIALTY = "specialty", // DEPRECATED
  DELIVERABLE = "deliverable", // DEPRECATED
  TITLE = "title", // DEPRECATED
}

export const conceptTypesSchema = z.nativeEnum(ConceptTypes);

export const conceptValueSchema = z.coerce.string();

export type ConceptValue = z.infer<typeof conceptValueSchema>;

// https://github.com/colinhacks/zod#recursive-types
const baseConceptSchema = z.object({
  _id: z.string().optional(),
  type: conceptTypesSchema,
  label: conceptValueSchema,
});

export const conceptSchema = z.object({
  _id: z.string().optional(),
  type: z.union([conceptTypesSchema, z.string()]),
  label: conceptValueSchema,
  synonyms: z.array(z.string()).optional(),
  parent: z.union([baseConceptSchema, z.string()]).optional(),
  subcategory: z.string().optional(),
  code: z.string().optional(),
  version: z.coerce.number().optional(),
});

export type Concept = z.infer<typeof conceptSchema>;

export const conceptRatingSchema = z.object({
  label: conceptValueSchema,
  experience: z.coerce.number().min(1).max(5).int(),
});

export type ConceptRating = z.infer<typeof conceptRatingSchema>;

export enum ConceptRatingExperience {
  Beginner = 1,
  Intermediate = 2,
  Advanced = 3,
}
