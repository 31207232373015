import { cva, cx } from "class-variance-authority";
import React, { forwardRef, type ButtonHTMLAttributes, type Ref } from "react";
import { extendTailwindMerge } from "tailwind-merge";

import { Icon } from "../foundation";

const twMerge = extendTailwindMerge({ prefix: "dali-" });

type OmittedProps = "size";
export type ButtonNewProps = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  OmittedProps
> & {
  /**
   * Optionally, sets the theme of the button; defaults to "primary"
   */
  theme?: "primary" | "secondary" | "tertiary";
  /**
   * Optionally, sets the size of the button; defaults to "sm"
   */
  size?: "xs" | "sm" | "md" | "lg";
  /**
   * Optionally, replaces the text button contents (including icons) with a
   * loading spinner. Should be used in conjunction with `disabled`; defaults to
   * false
   */
  isLoading?: boolean;
  /**
   * Optionally, sets the button variant for various appearances; defaults to
   * "filled"
   */
  variant?: "filled" | "outlined" | "ghost";
  /**
   * Optionally, render the button as active. This property exists because we
   * use the Button component as NavLinks, and we want to differentiate which
   * Link is active. This is separate from the :active state from the mousedown
   * event; defaults to false.
   */
  active?: boolean;
};

const buttonStyles = cva(
  [
    "dali-relative",
    "dali-rounded-md",
    "dali-transition-all",
    "dali-outline-0",
    "focus:dali-ring-2",
    "focus:dali-ring-offset-2",
    "disabled:dali-opacity-50",
    "disabled:dali-pointer-events-none",
    // opt to use classes instead of the typography component as this simplifies
    // the logic.
    "dali-font-national2",
    "dali-font-medium",
  ],
  {
    variants: {
      theme: {
        primary: ["dali-bg-base-black", "focus:dali-ring-[#000]"],
        secondary: [
          "dali-bg-base-white",
          "focus:dali-ring-base-white",
          "focus:dali-ring-offset-base-black",
        ],
        tertiary: ["dali-bg-vermillion-700", "focus:dali-ring-vermillion-700"],
      },
      size: {
        xs: ["dali-px-1", "dali-text-base"],
        sm: ["dali-px-3", "dali-py-2", "dali-text-base"],
        md: ["dali-px-[18px]", "dali-py-3", "dali-text-lg"], // TODO: replace arbitrary with a valid TW value
        lg: ["dali-px-[22px]", "dali-py-4", "dali-text-xl"], // TODO: replace arbitrary with a valid TW value
      },
      variant: {
        // leave these empty because they are used in a generic
        filled: [],
        outlined: [],
        ghost: [],
      },
      active: {
        true: [],
        false: [],
      },
    },
    compoundVariants: [
      // primary compound variants
      {
        theme: "primary",
        variant: "filled",
        className: [
          "dali-text-base-white",
          "active:dali-text-vermillion-500",
          "hover:dali-shadow-lg",
          "hover:dali-bg-vermillion-700",
        ],
      },
      {
        theme: "primary",
        variant: "outlined",
        className: [
          "dali-text-base-black",
          "dali-bg-transparent",
          "active:dali-text-vermillion-600",
          "active:dali-border-vermillion-600",
          "dali-border-2",
          "dali-border-base-black",
          "hover:dali-shadow-lg",
        ],
      },
      {
        theme: "primary",
        variant: "ghost",
        className: [
          "dali-text-base-black",
          "dali-bg-transparent",
          "active:dali-text-vermillion-600",
          "hover:dali-drop-shadow-lg",
        ],
      },
      // secondary compound variants
      {
        theme: "secondary",
        variant: "filled",
        className: [
          "dali-text-base-black",
          "active:dali-text-base-white",
          "active:dali-bg-vermillion-600",
          "hover:dali-shadow-lg",
        ],
      },
      {
        theme: "secondary",
        variant: "outlined",
        className: [
          "dali-text-base-white",
          "dali-bg-transparent",
          "active:dali-text-vermillion-600",
          "active:dali-border-vermillion-600",
          "dali-border-2",
          "dali-border-base-white",
          "hover:dali-shadow-lg",
        ],
      },
      {
        theme: "secondary",
        variant: "ghost",
        className: [
          "dali-text-base-white",
          "dali-bg-transparent",
          "active:dali-text-vermillion-600",
          "hover:dali-drop-shadow-lg",
        ],
      },
      // tertiary compound variants
      {
        theme: "tertiary",
        variant: "filled",
        className: [
          "dali-text-base-white",
          "active:dali-bg-vermillion-800",
          "hover:dali-shadow-lg",
        ],
      },
      {
        theme: "tertiary",
        variant: "outlined",
        className: [
          "dali-text-vermillion-700",
          "dali-bg-transparent",
          "active:dali-text-vermillion-800",
          "active:dali-border-vermillion-800",
          "dali-border-2",
          "dali-border-vermillion-700",
          "hover:dali-shadow-lg",
        ],
      },
      {
        theme: "tertiary",
        variant: "ghost",
        className: [
          "dali-text-vermillion-700",
          "dali-bg-transparent",
          "active:dali-text-vermillion-800",
          "hover:dali-drop-shadow-lg",
          "focus:dali-ring-vermillion-600",
        ],
      },
      // active compound variants
      {
        variant: "filled",
        active: true,
        className: ["dali-bg-vermillion-600"],
      },
      {
        variant: "outlined",
        active: true,
        className: ["dali-border-vermillion-600", "dali-text-vermillion-600"],
      },
      {
        variant: "ghost",
        active: true,
        className: ["dali-text-vermillion-600"],
      },
      {
        variant: "filled",
        theme: "secondary",
        active: true,
        className: ["dali-text-base-white"],
      },
      {
        variant: "filled",
        theme: "tertiary",
        active: true,
        className: ["dali-bg-vermillion-800"],
      },
      {
        variant: "outlined",
        theme: "tertiary",
        active: true,
        className: ["dali-border-vermillion-800", "dali-text-vermillion-800"],
      },
      {
        variant: "ghost",
        theme: "tertiary",
        active: true,
        className: ["dali-text-vermillion-800"],
      },
    ],
  },
);

export const ButtonNew = forwardRef(
  (
    {
      theme = "primary",
      size = "sm",
      isLoading = false,
      variant = "filled",
      type = "button",
      className: hash,
      active = false,
      children,
      ...rest
    }: ButtonNewProps,
    ref: Ref<HTMLButtonElement>,
  ) => (
    <button
      ref={ref}
      className={cx(
        twMerge(buttonStyles({ theme, size, variant, active })),
        hash,
      )}
      type={type}
      {...rest}
    >
      <span
        className={cx(
          "dali-relative dali-flex dali-gap-2 dali-items-center dali-transition-opacity",
          {
            "dali-opacity-0": isLoading,
          },
        )}
      >
        {children}
      </span>
      <span
        className={twMerge(
          cx(
            "dali-z-0 dali-absolute dali-inset-0 dali-flex dali-items-center dali-justify-center dali-transition-opacity dali-opacity-0",
            {
              "dali-opacity-100 [&_*]:opacity-0": isLoading,
            },
          ),
        )}
      >
        <Icon.CircleNotch
          weight="bold"
          className={cx({ "dali-animate-spin": isLoading })}
        />
      </span>
    </button>
  ),
);

ButtonNew.displayName = "ButtonNew";
