import { type ZodSchema, ZodError } from "zod";

/**
 * Given a Zod parser and an API response, attempt to run the parser on the
 * response. If the result of parsing throws an error, catches the error and
 * prints it to the console.
 */
export function parseResponse<T>(parser: ZodSchema<T>, obj: unknown) {
  try {
    const parsedData = parser.parse(obj);
    return parsedData;
  } catch (e) {
    if (e instanceof ZodError) {
      console.groupCollapsed("ZodError");
      const formatted = e.format();

      Object.keys(formatted).map((key) => {
        if (key === "_errors" && Array.isArray(key) && !key.length) {
          return;
        }

        console.groupCollapsed(key);

        // @ts-expect-error TS7053: we aren't using this type in a meaningful
        // way
        console.error(JSON.stringify(formatted[key], null, 4));

        console.groupEnd();
      });

      console.groupEnd();

      throw e;
    }
  }
}
