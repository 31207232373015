import { ButtonNew, Checkbox, Icon, Input, Typography } from "@mg/dali/src";
import {
  type InviteCreativeToMyNetworkBody,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  type InviteToMyNetworkBody,
} from "@mg/schemas/src/christo/myNetwork";
import * as Dialog from "@radix-ui/react-dialog";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import { useMutation } from "@tanstack/react-query";
import cx from "classnames";
import { type FormEvent, useState, useEffect } from "react";
import isEmail from "validator/lib/isEmail";
import isURL from "validator/lib/isURL";

import { useUI } from "../contexts/ui";
import { sendInvitations } from "../services/network";
import { useAppSelector } from "../utils/hooks";

type InviteDialogProps = {
  open: boolean;
  onOpenChange(open: boolean): void;
};

export const InviteDialog = (props: InviteDialogProps) => {
  const { notify } = useUI();
  const user = useAppSelector((state) => state.auth.value);

  const [invites, setInvites] = useState<
    InviteCreativeToMyNetworkBody["invites"]
  >([{ linkedinOrEmail: "", hasWorkedWith: false }]);

  const isValid = invites.every(
    ({ linkedinOrEmail }) => isEmail(linkedinOrEmail) || isURL(linkedinOrEmail),
  );

  const inviteMutation = useMutation({
    mutationKey: ["invite"],
    mutationFn: sendInvitations,
    onSuccess() {
      notify({
        variant: "success",
        title: `Added ${invites.length} creative${
          invites.length === 1 ? "" : "s"
        }`,
        message:
          "This could take 24-48 hours before they appear here in your network.",
      });
    },
  });

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    // because we are managing with state, we don't need to access the form
    // data.
    inviteMutation.mutateAsync({ invites }).then(() => {
      setInvites([{ linkedinOrEmail: "" }]);
      props.onOpenChange(false);
    });
  }

  useEffect(() => {
    setInvites([{ linkedinOrEmail: "" }]);
  }, [props.open]);

  return (
    <Dialog.Root {...props}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-10 bg-base-black/50 data-[state=open]:animate-fade-in" />
        <Dialog.Content className="fixed left-1/2 top-1/2 z-[12] grid w-[90vw] max-w-2xl -translate-x-1/2 -translate-y-1/2 gap-4 rounded-lg bg-base-white p-6 text-base-black shadow-2xl focus:outline-none data-[state=open]:animate-dialog-content-show">
          <Dialog.Title>
            <Typography size="2xl" weight="medium">
              Add a Creative to {user?.company}&apos;s Network
            </Typography>
          </Dialog.Title>
          <Dialog.Description>
            <Typography size="lg" weight="normal">
              Add a creative by entering their email, website, or LinkedIn URL
              below.
              <br />
              <Typography as="span" className="italic">
                CreativeConnect will take about a day to set up the
                creative&apos;s profile using AI to fill in their skills and
                projects. The creative will <strong>NOT</strong> be notified.
              </Typography>
            </Typography>
          </Dialog.Description>
          <form onSubmit={handleSubmit} className="grid gap-6">
            <ScrollArea.Root type="auto" className="overflow-hidden">
              <ScrollArea.Viewport className="max-h-48">
                {invites.map((invite, idx) => (
                  // eslint-disable-next-line jsx-a11y/label-has-associated-control
                  <label key={`label-${idx}`}>
                    <Typography weight="medium" className="ml-4">
                      Creative&apos;s email
                    </Typography>
                    <div
                      className={cx("flex items-center gap-2 pr-3", {
                        "mb-4": idx !== invites.length - 1,
                      })}
                      key={`.${idx}.${invites.length}`}
                    >
                      <Input
                        size="sm"
                        required
                        className="flex-1"
                        onChange={({ target }) => {
                          setInvites((prev) => {
                            const res = [...prev];
                            res[idx].linkedinOrEmail = target.value;

                            return res;
                          });
                        }}
                        value={invite.linkedinOrEmail}
                      />
                      <Checkbox
                        label={
                          <Typography weight="medium">
                            I worked with this person
                          </Typography>
                        }
                        size="sm"
                        checked={invite.hasWorkedWith}
                        onChange={(e) => {
                          const target =
                            e.target as unknown as HTMLInputElement;
                          setInvites((prev) => {
                            const res = [...prev];
                            res[idx].hasWorkedWith = target.checked;

                            return res;
                          });
                        }}
                        className="ml-4"
                      />
                      <ButtonNew
                        variant="ghost"
                        size="xs"
                        className={cx("text-cadmium-700", {
                          hidden: invites.length === 1,
                        })}
                        onClick={() => {
                          setInvites((prev) => {
                            const res = [...prev];
                            res.splice(idx, 1);

                            return res;
                          });
                        }}
                      >
                        <Icon.Trash />
                      </ButtonNew>
                    </div>
                  </label>
                ))}
              </ScrollArea.Viewport>

              <ScrollArea.Scrollbar
                className="flex touch-none select-none bg-carbon-50 p-0.5 transition-colors duration-[160ms] ease-out hover:bg-carbon-100 data-[orientation=horizontal]:h-2.5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col"
                orientation="vertical"
              >
                <ScrollArea.Thumb className="relative flex-1 rounded-[10px] bg-carbon-500 before:absolute before:left-1/2 before:top-1/2 before:size-full before:min-h-[44px] before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-['']" />
              </ScrollArea.Scrollbar>
              <ScrollArea.Scrollbar
                className="hover:bg-blackA5 flex touch-none select-none bg-carbon-50 p-0.5 transition-colors duration-[160ms] ease-out data-[orientation=horizontal]:h-2.5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col"
                orientation="horizontal"
              >
                <ScrollArea.Thumb className="relative flex-1 rounded-[10px] bg-carbon-500 before:absolute before:left-1/2 before:top-1/2 before:size-full before:min-h-[44px] before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-['']" />
              </ScrollArea.Scrollbar>
              <ScrollArea.Corner className="bg-carbon-100" />
            </ScrollArea.Root>

            <ButtonNew
              variant="ghost"
              size="xs"
              className="max-w-max"
              onClick={() =>
                setInvites((prev) => {
                  const res = [...prev];
                  res.push({ linkedinOrEmail: "", hasWorkedWith: false });

                  return res;
                })
              }
            >
              <Icon.PlusCircle />
              Add Another
            </ButtonNew>

            <div className="flex gap-4">
              <ButtonNew
                type="submit"
                disabled={inviteMutation.isPending || !isValid}
                isLoading={inviteMutation.isPending}
              >
                Add to {user?.company}&apos;s Network
              </ButtonNew>

              <Dialog.Close asChild>
                <ButtonNew variant="outlined">Cancel</ButtonNew>
              </Dialog.Close>
            </div>
          </form>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

InviteDialog.displayName = "InviteDialog";
