import { DotsThreeOutline } from "@phosphor-icons/react";
import { Button, DropdownMenu } from "@radix-ui/themes";

import { isPunttGuest } from "../../../../utils/auth";

type VersionManagerProps = {
  onManageVersions(): void;
};

export function TicketManager(props: VersionManagerProps) {
  const { onManageVersions } = props;

  if (isPunttGuest()) {
    return null;
  }

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <Button variant="ghost" color="gray">
          <DotsThreeOutline />
        </Button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Content>
        <DropdownMenu.Item onClick={onManageVersions}>
          Manage versions
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
}
