import {
  type CreateNoteBody,
  createNoteResponseSchema,
  type GetNotesQuery,
  getNotesResponseSchema,
  type UpdateNoteBody,
  updateNoteResponseSchema,
} from "@mg/schemas/src/christo/notes";

import { API_URL } from "../config/env";
import { smartFetch } from "../utils/http";

//
// NOTES
//

/**
 * Given a $creativeId or a $creativeListId, returns all notes of the
 * associative ID.
 *
 * Note: do ont supply both a $creativeListId and a $creativeId at the same
 * time.
 */
export async function getNotes(query: GetNotesQuery) {
  const search = new URLSearchParams(query).toString();

  const json = await smartFetch(`${API_URL}/private/notes?${search}`, {
    method: "GET",
  });

  const parsedData = getNotesResponseSchema.parse(json);
  return parsedData;
}

//
// NOTES $id CRUD endpoints
//

/**
 * Creates a note and returns the newly created note.
 */
export async function createNote(payload: CreateNoteBody) {
  const json = await smartFetch(`${API_URL}/private/notes`, {
    method: "POST",
    body: JSON.stringify(payload),
  });

  const parsedData = createNoteResponseSchema.parse(json);
  return parsedData;
}

/**
 * Updates a note and returns only the updated note
 *
 * Note: in order to effectively rehydrate notes of a given ID, it's best to
 * call `getNotes()` again after updating a note.
 */
export async function updateNote(payload: UpdateNoteBody & { noteId: string }) {
  const { noteId, ...rest } = payload;

  const json = await smartFetch(`${API_URL}/private/notes/${noteId}`, {
    method: "PUT",
    body: JSON.stringify(rest),
  });

  const parsedData = updateNoteResponseSchema.parse(json);
  return parsedData;
}

/**
 * Given a $noteId, deletes a note and returns nothing.
 *
 * Note: in order to effectively rehydrate notes of a given ID, it's best to
 * call `getNotes()` again after deleting a note.
 */
export async function deleteNote(noteId: string) {
  await smartFetch(`${API_URL}/private/notes/${noteId}`, {
    method: "DELETE",
  });

  return undefined;
}
