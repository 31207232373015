import { Card, Chip, Icon, Typography } from "@mg/dali/src";
import { useNavigate, useSearch } from "@tanstack/react-router";
import cx from "classnames";
import { useEffect, useRef, useState } from "react";

import { Markdown } from "../../../components/Markdown";
import { type NetworkSearch } from "../../../components/SearchAndFilterBar";
import { summarizeAi } from "../../../services/assistant";
import { useAppSelector } from "../../../utils/hooks";

export function Summarizer() {
  const creative = useAppSelector((state) => state.profile.value);
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, ...search } = useSearch({ strict: false });

  const [summary, setSummary] = useState("");
  const buffer = useRef("");

  const queries = Object.keys(search).flatMap((key) => {
    //@ts-expect-error TS7053: flatmap incorrectly typed after flattening
    return Array.isArray(search[key]) ? search[key] : [];
  });

  function handleChangeFilter(
    key: keyof NetworkSearch,
    value: string | string[],
  ) {
    navigate({
      replace: true,
      search(prev) {
        if (Array.isArray(value) && value.length > 0) {
          //@ts-expect-error TS2339: type inheritance not working
          delete prev.query;
          //@ts-expect-error TS2339: type inheritance not working
          prev[key] = value;
        } else {
          if ((Array.isArray(value) && value.length === 0) || value == null) {
            delete prev[key];
          } else {
            //@ts-expect-error TS2339: type inheritance not working
            prev[key] = value;
          }
        }
        //@ts-expect-error TS2339: type inheritance not working
        delete prev.port;
        return prev;
      },
    });
  }

  useEffect(() => {
    // @ts-expect-error TS2367: Backend incorrect types
    if (!creative || creative.type === "pipeline") {
      return;
    }

    setSummary("");

    buffer.current = "";

    const appendTextFn = (text: string) => {
      buffer.current += text;
      setSummary(buffer.current);
    };

    // @ts-expect-error TS2345: this is a string[]
    const controller = summarizeAi(queries, creative._id, appendTextFn);
    return () => {
      try {
        controller.abort();
      } catch (e) {
        console.log(e);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creative?._id]);
  // @ts-expect-error TS2367: Backend incorrect types
  if (!creative || creative.type === "pipeline") {
    return null;
  }

  return (
    <Card
      size="sm"
      type="outlined"
      className="grid w-full gap-4 rounded-2xl"
      uninteractive
      data-testid="ai-summarizer"
    >
      <Typography size="2xl" weight="medium">
        AI profile summary
        {queries.length === 0 ? "" : " based on your search terms"}
      </Typography>
      <div
        className={cx("flex flex-wrap items-center gap-2", {
          hidden:
            Object.values(search)
              .flatMap((e) => e)
              .filter((e) => !!e).length === 0,
        })}
      >
        {Object.keys(search).flatMap((key) => {
          //@ts-expect-error TS2339: type inheritance not working
          return Array.isArray(search[key])
            ? //@ts-expect-error TS2339: type inheritance not working
              search[key].map((term: string | number) => (
                <Chip
                  key={`.${key}.${term}`}
                  label={
                    key === "level"
                      ? "$".repeat(term as unknown as number)
                      : (term as string)
                  }
                  variant="carbon-light"
                  className="capitalize"
                  trailingIcon={
                    <button
                      onClick={() => {
                        handleChangeFilter(
                          key as keyof NetworkSearch,
                          //@ts-expect-error TS2339: type inheritance not working
                          search[key].filter(
                            (val: string | number) => val !== term,
                          ),
                        );
                      }}
                    >
                      <Icon.XCircle />
                    </button>
                  }
                />
              ))
            : null;
        })}
      </div>
      <Markdown data-testid="summarizer-text">{summary}</Markdown>
    </Card>
  );
}
