import {
  type UpdateTagsBody,
  myNetworkProfileSchema,
  updateTagsResponseSchema,
  getTagsResponseSchema,
  type ShareNetworkProfile,
} from "@mg/schemas/src/christo/myNetwork";
import { createProjectsWithAIResponseSchema } from "@mg/schemas/src/christo/projects";

import { API_URL } from "../config/env";
import { smartFetch } from "../utils/http";

export async function getDesigner(id: string, queries?: (string | number)[]) {
  const params = queries?.length
    ? "?" +
      new URLSearchParams({
        queries: JSON.stringify(queries),
      })
    : "";
  const json = await smartFetch(
    `${API_URL}/private/my-network/profiles/${id}${params}`,
    {
      method: "GET",
    },
  );

  const parsedData = myNetworkProfileSchema.parse(json);

  return parsedData;
}

export async function updateTags(
  payload: UpdateTagsBody & { portfolioId: string },
) {
  const { portfolioId, tags } = payload;

  const json = await smartFetch(
    `${API_URL}/private/my-network/profiles/${portfolioId}/tags`,
    {
      method: "PUT",
      body: JSON.stringify({ tags }),
    },
  );

  const parsedData = updateTagsResponseSchema.parse(json);

  return parsedData;
}

export function getTags() {
  const json = smartFetch(`${API_URL}/private/my-network/tags`, {
    method: "GET",
  });

  const parsedData = getTagsResponseSchema.parse(json);

  return parsedData;
}

export async function aiParseImage(key: string) {
  // dev/users/{userId}/{filename}
  const json = await smartFetch(`${API_URL}/private/projects/ai`, {
    method: "POST",
    body: JSON.stringify([
      {
        assets: [
          {
            type: "image",
            source: key,
          },
        ],
      },
    ]),
  });

  const parsedData = createProjectsWithAIResponseSchema.parse(json);
  return parsedData;
}

/**
 * Shares a profile to one or more emails, separated by a string. Returns nothing.
 */
export async function shareProfile({
  objectId,
  payload,
}: {
  objectId: string;
  payload: ShareNetworkProfile;
}) {
  await smartFetch(`${API_URL}/private/my-network/profiles/${objectId}/share`, {
    method: "POST",
    body: JSON.stringify(payload),
  });

  return undefined;
}
