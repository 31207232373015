import { type HTMLAttributes, type ReactNode, useState } from "react";

import { randomId } from "./random-id";

/**
 * Implements a unique id for a DOM element using the
 * #randomId() and a prefix.
 *
 * @param {string} prefix
 * @param {object} props
 */
export function useId<
  T extends HTMLAttributes<HTMLElement> & { label?: ReactNode },
>(prefix: string, props: T) {
  let idToUse;

  if (props.id) {
    idToUse = props.id;
  } else if (props.label) {
    idToUse = `${prefix}-${props.label}`;
  } else {
    idToUse = randomId(prefix);
  }

  const [id] = useState(idToUse); // converts this function to a React hook.

  return id;
}
