import {
  createEnterpriseResponseSchema,
  getEnterprisesResponseSchema,
} from "@mg/schemas/src/christo/catalyst";

import { API_URL } from "../config/env";
import { smartFetch } from "../utils/http";

export async function createEnterprise(name: string) {
  const requestUrl = `${API_URL}/private/catalyst/internal/enterprises`;
  const json = await smartFetch(requestUrl, {
    method: "POST",
    body: JSON.stringify({ name }),
  });

  return createEnterpriseResponseSchema.parse(json);
}

export async function getEnterprises() {
  const requestUrl = `${API_URL}/private/catalyst/enterprises`;
  const json = await smartFetch(requestUrl);

  return getEnterprisesResponseSchema.parse(json);
}
