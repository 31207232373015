import { Button, Dialog, Flex, Grid, Text, TextField } from "@radix-ui/themes";
import { useState } from "react";
import { isEmail } from "validator";

import { useUI } from "../../../../contexts/ui";

/**
 * An action-less Dialog used to share tickets. Action-less means you must
 * provide the button that toggles an `open` state and passes it into this
 * component. This is to allow various styles of buttons to open the same
 * dialog. This is different from the ShareTicketDialog.tsx that exists on
 * the ticket/$ticketId/view page, but otherwise has identical functionality.
 *
 * The way you determine whether you're sharing a ticket or a folder is with
 * `item.type`. This is uninformative to the dialog itself, but it used in the
 * upper scope to determine how sharing is handled.
 */

export type ShareDialogProps = {
  item?: { type: "folder" | "ticket"; id: string };
  shareLink?: string;
  shareLinkPending: boolean;
  isPending: boolean;
  onCancel(): void;
  onShare(emails: string[]): void;
};

export function ShareDialog(props: ShareDialogProps) {
  const { isPending, item, shareLink, shareLinkPending, onCancel, onShare } =
    props;

  const { notify } = useUI();

  const [emails, setEmails] = useState("");

  function handleCopyLink() {
    navigator.clipboard.writeText(shareLink as string).then(() => {
      notify({
        title: "Share link copied to clipboard",
      });
    });
  }

  return (
    <Dialog.Root open={item != null}>
      <Dialog.Content maxWidth="450px">
        <Dialog.Title>Share {item?.type}</Dialog.Title>
        <Dialog.Description>
          Copy or email a link to this {item?.type}
        </Dialog.Description>

        <Grid gap="3" mt="4">
          <TextField.Root value={shareLink} readOnly>
            <TextField.Slot side="right">
              <Button
                variant="soft"
                size="1"
                disabled={shareLink == null || shareLinkPending}
                loading={shareLinkPending}
                onClick={handleCopyLink}
              >
                Copy
              </Button>
            </TextField.Slot>
          </TextField.Root>

          <Grid gap="2">
            <Text size="2">Email(s)</Text>
            <TextField.Root
              value={emails}
              onChange={({ target }) => setEmails(target.value)}
            />
            <Text size="1">Separate multiple emails with a comma</Text>
          </Grid>
        </Grid>

        <Flex gap="3" mt="4" justify="end">
          <Button size="1" onClick={onCancel} variant="soft" color="gray">
            Cancel
          </Button>
          <Button
            size="1"
            variant="soft"
            disabled={
              emails.split(",").some((email) => !isEmail(email.trim())) ||
              isPending
            }
            loading={isPending}
            onClick={() =>
              onShare(emails.split(",").map((email) => email.trim()))
            }
          >
            Share
          </Button>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
}
