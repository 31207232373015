import { ButtonNew, Input, Icon, Typography } from "@mg/dali/src";
import * as Dialog from "@radix-ui/react-dialog";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "@tanstack/react-router";

import { useUI } from "../../../contexts/ui";
import { updateList } from "../../../services/lists";
import { useLists } from "../../../utils/queries/lists";
import { listRoute } from "../../list/route";

type RenameListDialogProps = {
  open: boolean;
  onOpenChange(open: boolean): void;
  title: string;
};

export const EditListName = (props: RenameListDialogProps) => {
  const { listId } = useParams({ from: listRoute.id }) as { listId: string };
  const { notify } = useUI();
  const lists = useLists();
  const { title, ...rest } = props;

  const editListMutation = useMutation({
    mutationFn: updateList,
    async onSuccess() {
      notify({
        variant: "success",
        title: `Updated List!`,
        leadingIcon: <Icon.CheckCircle color="rgb(var(--base-black))" />,
      });
      lists.refetch().then(() => {
        props.onOpenChange(false);
      });
    },
  });

  return (
    <Dialog.Root {...rest}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-[2] bg-base-black/50 data-[state=open]:animate-fade-in" />
        <Dialog.Content className="fixed left-1/2 top-1/2 z-[2] grid w-[90vw] max-w-lg -translate-x-1/2 -translate-y-1/2 gap-4 rounded-lg bg-base-white p-6 text-base-black shadow-2xl focus:outline-none data-[state=open]:animate-dialog-content-show">
          <Dialog.Title asChild>
            <Typography size="2xl" weight="medium">
              Edit List Name
            </Typography>
          </Dialog.Title>
          <form
            onSubmit={(e) => {
              e.preventDefault();

              const formData = new FormData(e.target as HTMLFormElement);
              const title = formData.get("title") as string;
              editListMutation.mutateAsync({
                listId,
                payload: { title },
              });
            }}
          >
            <Input
              name="title"
              label="List Name"
              defaultValue={title}
              size="sm"
              fullWidth
              data-testid="name-field"
              key={title}
            />
            <div className="mt-6 flex gap-4">
              <ButtonNew
                theme="primary"
                variant="filled"
                disabled={editListMutation.isPending}
                type="submit"
                isLoading={editListMutation.isPending}
              >
                Save
              </ButtonNew>
              <Dialog.Close asChild>
                {/* @ts-expect-error TS2322: incorrect type inheritance */}
                <ButtonNew theme="primary" variant="outlined" action="cancel">
                  Cancel
                </ButtonNew>
              </Dialog.Close>
            </div>
          </form>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
