import { cva, cx } from "class-variance-authority";
import React, { forwardRef } from "react";
import { type HTMLAttributes, type ReactNode, type Ref } from "react";
import { extendTailwindMerge } from "tailwind-merge";

const twMerge = extendTailwindMerge({ prefix: "dali-" });

export interface MediaCardProps extends HTMLAttributes<HTMLElement> {
  /**
   * A visual title for the MediaCard
   */
  title: string;
  /**
   * Optionally, sets an image media source URL
   */
  media?: HTMLImageElement["src"];
  /**
   * An array of actions for the MediaCard; at least one is required
   */
  actions: ReactNode;
  /**
   * Optionally, sets the MediaCard size; defaults to "sm"
   * Note: "sm" should be used only for 3-column layouts and
   * "lg" should be used only for 2-column layouts
   */
  size?: "sm" | "lg";
}

const mediaCardStyles = cva(
  [
    "mg-media-card",
    "dali-overflow-hidden",
    "dali-relative",
    "dali-rounded-2xl",
    "dali-border",
    "dali-border-gray-300",
  ],
  {
    variants: {
      size: {
        // using hard widths and heights here per Max request
        sm: ["dali-w-[280px]", "dali-h-[200px]", "[&>figcaption]:dali-p-3"],
        lg: ["dali-w-[425px]", "dali-h-[300px]", "[&>figcaption]:dali-p-6"],
      },
    },
  },
);

export const MediaCard = forwardRef(
  (
    { className: hash, size = "sm", ...rest }: MediaCardProps,
    ref: Ref<HTMLElement>,
  ) => {
    const classes = cx(twMerge(mediaCardStyles({ size })), hash);

    return (
      <figure ref={ref} className={classes} {...rest}>
        <img
          src={rest.media ?? ""}
          alt={rest.title}
          className="dali-h-full dali-w-full dali-object-cover"
        />

        <figcaption className="dali-absolute dali-inset-0 dali-flex dali-flex-col dali-justify-between dali-bg-gray-900 dali-bg-opacity-75 dali-opacity-0 dali-transition-opacity hover:dali-opacity-100">
          <div className="dali-ml-auto dali-flex dali-gap-2">
            {rest.actions}
          </div>

          <p className="dali-font-text-bold dali-text-lg dali-text-base-white">
            {rest.title}
          </p>
        </figcaption>
      </figure>
    );
  },
);

export interface MediaCardActionProps
  extends HTMLAttributes<HTMLButtonElement> {
  size?: "sm" | "lg";
}

const mediaCardActionStyles = cva(
  [
    "mg-media-card__action",
    "dali-bg-base-white",
    "dali-rounded-full",
    "dali-flex",
    "dali-items-center",
    "dali-justify-center",
  ],
  {
    variants: {
      size: {
        sm: ["dali-h-6", "dali-w-6"],
        lg: ["dali-h-8", "dali-w-8"],
      },
    },
  },
);

export const MediaCardAction = forwardRef(
  (props: MediaCardActionProps, ref: Ref<HTMLButtonElement>) => {
    const { className: hash, size = "sm", ...rest } = props;
    const classes = cx(twMerge(mediaCardActionStyles({ size })), hash);

    return <button type="button" className={classes} ref={ref} {...rest} />;
  },
);

MediaCard.displayName = "MediaCard";

MediaCardAction.displayName = "MediaCardAction";
