import { Grid, Spinner, Text } from "@radix-ui/themes";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

import { isNil } from "../../../utils/fp";
import { useAppDispatch } from "../../../utils/hooks";
import { useFoldersAndTicketsMutation } from "../../../utils/queries/projects";
import { mergePunttProjectState } from "../../../utils/slices/punttProjects";
import { ticketsRoute } from "../route";

interface InfiniteScrollTriggerProps {
  lastItemDate?: string;
  hasMore: boolean;
}

export function InfiniteScrollTrigger({
  lastItemDate,
  hasMore,
}: InfiniteScrollTriggerProps) {
  console.debug(lastItemDate);
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const { folderId, pageSize, ticketTitle, participants } =
    ticketsRoute.useSearch();
  const dispatch = useAppDispatch();
  const foldersAndTicketsMutation = useFoldersAndTicketsMutation(folderId);

  useEffect(() => {
    if (inView && hasMore && !isNil(lastItemDate)) {
      dispatch(mergePunttProjectState({ isLoadingMore: true }));
      foldersAndTicketsMutation.mutate({
        folderId,
        pageSize,
        ticketTitle,
        participants,
        searchAfter: lastItemDate,
      });
    }
  }, [inView, hasMore, lastItemDate]);

  if (!hasMore) return null;

  return (
    <Grid ref={ref} py="4" gap="3" maxWidth="max-content" mx="auto">
      <Spinner mx="auto" />

      <Text>Loading More</Text>
    </Grid>
  );
}
