import { ButtonNew, Typography } from "@mg/dali/src";
import {
  type GetTicketCommentsResponse,
  type GetTicketResponse,
  type UpdateTicketBody,
} from "@mg/schemas/src/christo/catalyst";
import * as TabsPrimitive from "@radix-ui/react-tabs";
import { Flex, Text } from "@radix-ui/themes";
import { type QueryObserverResult } from "@tanstack/react-query";
/* eslint-disable-next-line import/named */
import { createRoute, Link, useNavigate } from "@tanstack/react-router";
import cx from "classnames";
import { useFeatureFlagPayload, usePostHog } from "posthog-js/react";
import {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
} from "react";
import "@tldraw/tldraw/tldraw.css";
import { z } from "zod";

import { VersionManagerDialog } from "./components/dialogs/VersionManagerDialog";
import { TicketManager } from "./components/TicketManager";

import { useAnalytics } from "../../../utils/analytics";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import {
  useDeleteRevisionMutation,
  useEditProjectMutation,
  useGetTicketComments,
  useProject,
} from "../../../utils/queries/projects";
import { queryClient } from "../../../utils/queryClient";
import {
  setComments,
  setRevisions,
  setTicket,
} from "../../../utils/slices/ticket";
import { store } from "../../../utils/store";
import { injectCustomCursorStyles } from "../../../utils/tldraw/assets";
import { authLayoutRoute } from "../../auth-layout/route";
import { loginRoute } from "../../login/route";
import { ConceptDesignBoard } from "../components/ConceptDesign";
import { Delivery } from "../components/Delivery";
import { RequestApprovalSidebar } from "../components/RequestApprovalSidebar";
import { Revision } from "../components/Revision";
import { SendToDesignDialog } from "../components/SendToDesignDialog";
import { SendToDesignSidebar } from "../components/SendToDesignSidebar";
import { TicketLoader } from "../components/TicketLoader";
import { ticketsRoute } from "../route";

const Tabs = TabsPrimitive.Root;
const TabList = TabsPrimitive.List;
const Tab = TabsPrimitive.Trigger;
const TabPanel = TabsPrimitive.Content;

interface TicketContextValues {
  toggleDrawer: (type: string) => void;
  drawerOpen: string | undefined;
  tab: number;
  hasInitiatedAiReview: boolean;
  setHasInitiatedAiReview(value: boolean): void;
  setTab: (tab: number) => void;
  isReviewable: boolean;
  refreshTicket: () => Promise<QueryObserverResult<GetTicketResponse, Error>>;
  refreshComments: () => Promise<
    QueryObserverResult<GetTicketCommentsResponse, Error>
  >;
  headerHeight: number;
}

export const TicketContext = createContext({} as TicketContextValues);

export const useTicket = () => useContext(TicketContext);

const ticketSearchParser = z.object({
  revIndex: z.coerce.number().optional(),
  tab: z.coerce.number().default(0),
  drawer: z.string().optional(),
});

export const ticketRoute = createRoute({
  getParentRoute: () => authLayoutRoute,
  validateSearch(search: Record<string, unknown>) {
    return ticketSearchParser.parse(search);
  },
  path: "tickets/$ticketId/view",
  component: Ticket,
  onLeave({ params }) {
    const { ticketId } = params;

    store.dispatch(setTicket(null));
    store.dispatch(setRevisions([]));
    store.dispatch(setComments([]));
    queryClient.removeQueries({
      queryKey: ["ticket-comments", ticketId, "all"],
    });
  },
});

function Ticket() {
  const { ticketId } = ticketRoute.useParams();
  const search = ticketRoute.useSearch();
  const navigate = useNavigate();
  const headerRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const ticket = useProject(ticketId);
  const globalTicket = useAppSelector((state) => state.ticket.value);
  const revisions = useAppSelector((state) => state.ticket.revisions);
  const isTicketLoading = useAppSelector((state) => state.ticket.isLoading);
  const comments = useGetTicketComments({
    ticketId,
    query: "all",
  });
  const ticketMutation = useEditProjectMutation(ticketId);
  const deleteRevisionMutation = useDeleteRevisionMutation(ticketId);
  const moodBoardPayload = useFeatureFlagPayload("mood-board-access");
  const conceptDesignPayload = useFeatureFlagPayload("concept-design-access");

  const { tab, drawer: drawerOpen } = search;

  const skipTitleBlurHandlerRef = useRef(false);
  const [ticketTitle, setTicketTitle] = useState("");
  const [designModalOpen, setDesignModalOpen] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [versionManagerDialogOpen, setVersionManagerDialogOpen] =
    useState(false);
  const [hasInitiatedAiReview, setHasInitiatedAiReview] = useState(false);

  const analytics = useAnalytics(`root_ticket_${ticketId}`);
  const posthog = usePostHog();

  useEffect(() => {
    // this is needed to inject the custom cursor styles
    // safari isn't interpolating css colors with %23
    injectCustomCursorStyles();
  }, []);

  useEffect(() => {
    const context = {
      ticketId,
      tab,
      ticketTitle: globalTicket?.title,
      ticketStatus: globalTicket?.status,
      drawerOpen,
    };
    posthog.register(context);

    return () => {
      Object.keys(context).forEach((key) => {
        posthog.unregister(key);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketId, globalTicket, tab, drawerOpen]);

  function setTab(tab: number) {
    navigate({
      from: ticketRoute.to,
      replace: true,
      search: (prev) => ({
        ...prev,
        drawer: prev.drawer == "comments" ? undefined : prev.drawer,
        tab,
      }),
    });
  }

  const toggleDrawer = (type: string) => {
    if (type === drawerOpen) {
      return navigate({
        replace: true,
        search: (prev) => ({
          ...prev,
          drawer: undefined,
        }),
      });
    }

    navigate({
      replace: true,
      search: (prev) => ({
        ...prev,
        drawer: type,
      }),
    });
  };

  function handleUpdateTicket(ticket: UpdateTicketBody) {
    // Skip the API request if no changes are being made
    if (
      globalTicket &&
      (Object.keys(ticket) as (keyof UpdateTicketBody)[]).every(
        (key) => ticket[key] === globalTicket[key],
      )
    ) {
      return;
    }

    const startTime = performance.now();
    return ticketMutation.mutateAsync(ticket).then((response) => {
      dispatch(setTicket(response));

      const endTime = performance.now();
      analytics.capture("ticket_updated", {
        ticketTitle: ticket.title,
        mutationDuration: endTime - startTime,
        durationSincePageLoad: endTime - (analytics.startTimeRef?.current ?? 0),
      });
    });
  }

  function handleSubmitTab(nextTab: number) {
    ticketMutation
      .mutateAsync({
        _id: ticketId,
        workflowStep:
          nextTab > (globalTicket?.workflowStep ?? 0)
            ? nextTab
            : globalTicket?.workflowStep,
      })
      .then((response) => {
        dispatch(setTicket(response));
        setTab(nextTab);
      });
  }

  useEffect(() => {
    if (tab === null) {
      navigate({ search: { tab: 0 }, replace: true });
    }
  }, [navigate, tab]);

  useEffect(() => {
    if (globalTicket != null) {
      setTicketTitle(globalTicket.title);
    }
  }, [globalTicket]);
  useLayoutEffect(() => {
    if (!headerRef.current) return;
    // add one for shadow offset
    setHeaderHeight(headerRef.current.getBoundingClientRect().bottom + 1 ?? 0);
    // I don't know why, but it doesn't work without this.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerRef.current]);

  const inputRef = useRef<HTMLInputElement>(null);
  const hiddenDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (hiddenDivRef.current && inputRef.current) {
      const width = hiddenDivRef.current.offsetWidth + 10;
      inputRef.current.style.width = `${width}px`;
    }
  }, [ticketTitle]);

  const [unauthorizedError, setUnauthorizedError] = useState<string | null>(
    null,
  );

  useEffect(() => {
    if (
      ticket.isError &&
      ticket.error instanceof Response &&
      ticket.error.status === 401
    ) {
      ticket.error
        .clone()
        .json()
        .then((responseData) => {
          if (
            typeof responseData === "object" &&
            responseData !== null &&
            "error" in responseData &&
            responseData.error === "Unauthorized Guest Access"
          ) {
            setUnauthorizedError("Unauthorized Guest Access");
          } else {
            setUnauthorizedError(null);
          }
        })
        .catch(() => {
          setUnauthorizedError(null);
        });
    } else {
      setUnauthorizedError(null);
    }
  }, [ticket.isError, ticket.error]);

  if (unauthorizedError === "Unauthorized Guest Access") {
    return (
      <Flex
        direction="column"
        justify="center"
        align="center"
        className="h-screen text-center"
      >
        <strong>
          The access provided by the link you followed does not match the
          current ticket URL.
        </strong>
        <span>Perhaps the link was accidentally changed.</span>
        <span>
          Please{" "}
          <Link
            to={loginRoute.to}
            className="text-egyptian-blue-600 hover:text-egyptian-blue-800 active:text-egyptian-blue-900"
            data-auth-trigger="unauthorized-guest-access"
          >
            log in
          </Link>{" "}
          to continue.
        </span>
      </Flex>
    );
  }

  if (ticket.isError) {
    // 404 means the ticket ID is not found at all. 500 means the ticket ID is invalid or not found in this enterprise.
    if (
      "status" in ticket.error &&
      (ticket.error.status === 404 || ticket.error.status === 500)
    ) {
      return (
        <Flex
          direction="column"
          justify="center"
          align="center"
          className="h-screen text-center"
        >
          <strong>This ticket was not found today!</strong>
          <span>Perhaps it was deleted.</span>
          <span>
            Please return to the{" "}
            <Link
              to={ticketsRoute.to}
              className="text-egyptian-blue-600 hover:text-egyptian-blue-800 active:text-egyptian-blue-900"
            >
              tickets page
            </Link>
          </span>
          <span>to get your task completed.</span>
        </Flex>
      );
    }
  }

  if (globalTicket == null || ticket.isPending) {
    return (
      <Text className="fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
        Loading...
      </Text>
    );
  }

  const renderTabContents = (name: string, type: string, index: number) => {
    const handleNext = () => handleSubmitTab(index + 1);
    const nextStepName = globalTicket.workflow.steps[index + 1]?.name;

    switch (type) {
      case "revision":
        return <Revision />;
      case "review":
        return <Revision />;
      case "concept_design":
        return (
          <ConceptDesignBoard
            index={index}
            onNext={handleNext}
            // @ts-expect-error TS2322: Zod will parse as Date, but we want
            // string always on the frontend.
            ticket={globalTicket}
            nextStepName={nextStepName}
          />
        );
      case "delivery":
        return <Delivery />;

      default:
        return (
          <div className="flex items-center gap-4">
            <Typography>
              Content for {name}, {type}
            </Typography>

            <ButtonNew onClick={handleNext}>Next</ButtonNew>
          </div>
        );
    }
  };

  return (
    <TicketContext.Provider
      value={{
        toggleDrawer,
        drawerOpen,
        tab,
        isReviewable: true,
        setTab,
        refreshTicket: () => ticket.refetch(),
        refreshComments: () => comments.refetch(),
        headerHeight,
        hasInitiatedAiReview,
        setHasInitiatedAiReview,
      }}
    >
      <VersionManagerDialog
        open={versionManagerDialogOpen}
        onOpenChange={setVersionManagerDialogOpen}
        onDeleteVersion={(revisionId, index) =>
          deleteRevisionMutation.mutate(revisionId, {
            onSuccess() {
              comments.refetch();
              navigate({
                search(prev) {
                  return {
                    ...prev,
                    revIndex:
                      prev.revIndex <= index
                        ? prev.revIndex - 1
                        : prev.revIndex,
                  };
                },
              });
            },
          })
        }
        revisions={
          revisions.map((board, i) => ({
            _id: board._id,
            title: board.name ?? `Version ${i + 1}`,
            thumbnail: board.thumbnails[0] ?? board.screenshotUrl,
          })) ?? []
        }
        isPending={deleteRevisionMutation.isPending}
        deletingId={deleteRevisionMutation.variables}
      />

      {drawerOpen == "design" && <SendToDesignSidebar />}
      {drawerOpen == "approval" && <RequestApprovalSidebar />}
      <SendToDesignDialog
        open={designModalOpen}
        onOpenChange={setDesignModalOpen}
      />
      {isTicketLoading && <TicketLoader />}
      <Tabs
        ref={headerRef}
        defaultValue={(tab ?? 0).toString()}
        className="grid max-w-[calc(100%-20rem)] gap-y-2 truncate bg-base-white px-7 py-4 shadow"
        value={(tab ?? 0).toString()}
      >
        <Flex gap="4" align="center" className="min-w-0">
          <div className="relative inline-block min-w-0">
            <input
              data-auth-trigger="title-input"
              ref={inputRef}
              type="text"
              value={ticketTitle}
              onInput={({ target }) =>
                setTicketTitle((target as HTMLInputElement).value)
              }
              onKeyDown={({ key, target }) => {
                // Hit Enter to save
                if (key === "Enter") {
                  // Use the current value of the field. ticketTitle might not be updated yet
                  const newTitle = (target as HTMLInputElement).value;
                  if (newTitle.trim().length > 0) {
                    handleUpdateTicket({
                      _id: globalTicket._id,
                      title: newTitle,
                    });
                  } else {
                    // Hitting Enter while the field is empty resets it to the last saved value
                    setTicketTitle(globalTicket.title);
                  }
                  skipTitleBlurHandlerRef.current = true;
                  inputRef.current?.blur();
                  // Hit Escape to reset the field to the last saved value
                } else if (key === "Escape") {
                  setTicketTitle(globalTicket.title);
                  skipTitleBlurHandlerRef.current = true;
                  inputRef.current?.blur();
                }
              }}
              onBlur={({ target }) => {
                // Hitting Escape causes the field to blur, but we don't want it to save
                if (skipTitleBlurHandlerRef.current) {
                  skipTitleBlurHandlerRef.current = false;
                  return;
                }

                // Use the current value of the field. ticketTitle might not be updated yet
                const newTitle = target.value;
                if (newTitle.trim().length > 0) {
                  handleUpdateTicket({
                    _id: globalTicket._id,
                    title: newTitle,
                  });
                  // Leaving the field while it's empty resets to the last saved value
                } else {
                  setTicketTitle(globalTicket.title);
                }
              }}
              placeholder="New Ticket"
              className="lastpass-disable-search max-w-full p-0.5 font-national2 text-2xl text-base-black transition-all hover:bg-puntt-neutral-gray-3"
              style={{ width: "auto", boxSizing: "content-box" }}
              data-lpignore="true"
              autoComplete="off"
            />
            <div
              ref={hiddenDivRef}
              style={{
                position: "absolute",
                visibility: "hidden",
                whiteSpace: "pre",
                padding: "0.5rem",
                fontFamily: "inherit",
                fontWeight: "inherit",
              }}
              className="text-2xl"
            >
              {ticketTitle || "New Ticket"}
            </div>
          </div>

          <TicketManager
            onManageVersions={() => setVersionManagerDialogOpen(true)}
          />
        </Flex>

        {globalTicket.workflow.steps.length > 1 && (
          <div className="flex">
            <div className="flex-0 inline-flex shrink-0">
              <TabList aria-label="ticket tabs" className="flex">
                {globalTicket.workflow.steps.map(({ name, type }, i) => {
                  const moodBoardAccessDenied =
                    type === "mood_board" &&
                    moodBoardPayload != null &&
                    //@ts-expect-error TS2339: BE returning wrong type
                    moodBoardPayload.no_access;
                  const conceptDesignAccessDenied =
                    type === "concept_design" &&
                    conceptDesignPayload != null &&
                    //@ts-expect-error TS2339: BE returning wrong type
                    conceptDesignPayload.no_access;

                  return (
                    <Tab
                      value={i.toString()}
                      key={`tab.${name}.${type}`}
                      onClick={() => {
                        analytics.capture("manually_switched_tab", {
                          durationSincePageLoad:
                            performance.now() -
                            (analytics.startTimeRef?.current ?? 0),
                        });
                        setTab(i);
                      }}
                      className={cx(
                        "flex items-center justify-center border-b border-b-carbon-50 px-4 py-1 outline-transparent",
                        "text-base-black disabled:text-carbon-200 data-[state=active]:border-b-2 data-[state=active]:border-egyptian-blue-500",
                        {
                          hidden:
                            i !== tab &&
                            (moodBoardAccessDenied ||
                              conceptDesignAccessDenied),
                        },
                      )}
                      disabled={
                        i > globalTicket.workflowStep ||
                        moodBoardAccessDenied ||
                        conceptDesignAccessDenied
                      }
                    >
                      <Typography
                        size="base"
                        weight={i === tab ? "medium" : "normal"}
                      >
                        {name}
                      </Typography>
                    </Tab>
                  );
                })}
              </TabList>
            </div>
          </div>
        )}

        {globalTicket.workflow.steps.map(({ name, type }, i) => (
          <TabPanel value={i.toString()} key={`tab-panel.${name}.${type}`}>
            {renderTabContents(name, type, i)}
          </TabPanel>
        ))}
      </Tabs>
    </TicketContext.Provider>
  );
}
