import { type Editor } from "@tldraw/tldraw";
import { useEffect, useRef } from "react";

import { IGNORED_CANVAS_UPDATE_KEYS } from "../../../../utils/constants";

const AUTOSAVE_TIMEOUT = 1000; // timer in millis

/**
 * A debounce timer for auto-saving the TLDraw editor
 * contents.
 *
 * Given a TLDraw editor object, detects changes made to
 * the canvas and saves the changes to the database. The
 * ticket that is saved is automatically read in from the
 * Ticket slice. The ticket in the slice is not updated
 * post auto-save. This is to prevent any unnecessary
 * re-renders, and does not impact remote sync changes via
 * Yjs.
 *
 * TODO: clean this up, it's a tad verbose and unreadable
 */
export function useEditorAutoSave(
  editor: Editor | null | undefined,
  callback: () => void,
  enabled = true,
) {
  const timerRef = useRef<number>();

  useEffect(() => {
    if (editor == null || !enabled) {
      return;
    }

    editor.store.listen((entry) => {
      const allUpdateKeys = Object.keys(entry.changes.updated);

      // savable keys are the difference of `allUpdateKeys` and
      // `ignoreUpdateKeys`.
      const savableKeys = allUpdateKeys.filter(
        (key) => !IGNORED_CANVAS_UPDATE_KEYS.includes(key),
      );

      const hasNonCommentChanges = savableKeys.some(
        (key) =>
          entry.changes.updated[key as keyof typeof entry.changes.updated][0]
            .type !== "comment" &&
          entry.changes.updated[key as keyof typeof entry.changes.updated][0]
            .type !== "comment-avatar",
      );
      const hasSavableChanges = savableKeys.length > 0 && hasNonCommentChanges;
      const hasSavableAdditions = Object.values(entry.changes.added)?.some(
        // @ts-expect-error TS2339: some more TLDraw type shenanigans
        (s) => s.type !== "comment" && s.type !== "comment-avatar",
      );

      const hasRemovedChanges = Object.values(entry.changes.removed)?.some(
        // @ts-expect-error TS2339: some more TLDraw type shenanigans
        (s) => s.type !== "comment" && s.type !== "comment-avatar",
      );

      if (
        enabled &&
        (hasSavableAdditions || hasRemovedChanges || hasSavableChanges)
      ) {
        if (timerRef.current != null) {
          clearTimeout(timerRef.current);
        }

        timerRef.current = setTimeout(() => {
          console.debug("Auto-saving now!");

          callback();
        }, AUTOSAVE_TIMEOUT);
      }
    });

    return () => {
      clearTimeout(timerRef.current);
      timerRef.current = undefined;
    };
  }, [callback, editor, enabled]);

  return null;
}
