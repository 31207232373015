import {
  ButtonNew,
  DialogButton,
  Icon,
  Input,
  Select,
  Typography,
} from "@mg/dali/src";
import { type Asset, type Highlight } from "@mg/schemas/src/commons";
import { useMutation } from "@tanstack/react-query";
// eslint-disable-next-line import/named
import { createRoute, useNavigate, useSearch } from "@tanstack/react-router";
import { EditorState } from "draft-js";
//@ts-expect-error TS7016: Could not find a declaration file for module 'draft-js-export-markdown'.
import { stateToMarkdown } from "draft-js-export-markdown";
//@ts-expect-error TS7016: Could not find a declaration file for module 'draft-js-import-markdown'.
import { stateFromMarkdown } from "draft-js-import-markdown";
import { useReducer, useState, type FormEvent, useEffect } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  type DropResult,
  type DroppableProvided,
  type DraggableProvided,
} from "react-beautiful-dnd";

import { myWorkListRoute } from "./viewRoute";

import { ImageUpload } from "../../../../components/ImageUploader";
import { RichEditor } from "../../../../components/RichEditor";
import { useUI } from "../../../../contexts/ui";
import { updateAvatar } from "../../../../services/upload";
import { updateUserProfile } from "../../../../services/userProfile";
import { requiresAuth } from "../../../../utils/auth";
import { useAppSelector } from "../../../../utils/hooks";
import { assetForUser } from "../../../../utils/imageHandler";
import { useConcepts } from "../../../../utils/queries/concepts";
import { useProfile } from "../../../../utils/queries/profile";
import {
  filterDuplicateOptions,
  getEmbedURL,
  youtubeOrVimeoRegex,
} from "../../../../utils/validation";
import { authLayoutRoute } from "../../../auth-layout/route";
import { myNetworkRoute } from "../../../network/route";
import { userProfileRoute } from "../../route";

export const myWorkEditRoute = createRoute({
  getParentRoute: () => authLayoutRoute,
  path: "profile/my-work/edit",
  beforeLoad() {
    requiresAuth();
  },
  component: function Component() {
    const { alert } = useUI();
    const navigate = useNavigate();
    const user = useAppSelector((state) => state.auth.value);
    //@ts-expect-error TS2345: Inherited search types incorrect
    const { profileId, projectId, fromProject } = useSearch({
      from: "/authenticated/profile/my-work/edit",
    });

    const isAdmin = user?.permissions?.includes("enterprise-admin");
    const editingId = profileId ?? user?.userID;

    const { data: profile, refetch } = useProfile(editingId as string);
    const [imageRerenders, forceImageUpdate] = useReducer((x) => x + 1, 0);
    const [isLoading, setIsLoading] = useState<number | null>(null);

    const { data: skillsOptions } = useConcepts("skill", "skills");
    const { data: deliverableOptions } = useConcepts(
      "deliverable",
      "deliverables",
    );
    const { data: industryOptions } = useConcepts("industry", "industries");

    const profileMutation = useMutation({
      mutationFn: updateUserProfile,
    });
    const imageUploadMutation = useMutation({
      mutationFn: updateAvatar,
    });

    const [description, setDescription] = useState(() =>
      EditorState.createEmpty(),
    );
    const [project, setProject] = useState<Highlight | null>();
    const [skills, setSkills] = useState<string[]>([]);
    const [deliverables, setDeliverables] = useState<string[]>([]);
    const [industry, setIndustry] = useState<string | null>();
    const [assets, setAssets] = useState<Asset[]>([]);

    useEffect(() => {
      if (!projectId) return;
      const proj = profile?.highlights?.find((h) => h._id === projectId);
      if (!proj) return;
      setProject(proj);
      setDescription(() =>
        EditorState.createWithContent(
          stateFromMarkdown(proj?.description ?? ""),
        ),
      );
      setAssets(proj?.assets ?? []);
      setSkills(proj?.skills ?? []);
      setDeliverables(proj?.deliverables ?? []);
      setIndustry(proj?.industry ?? null);
    }, [projectId, profile?.highlights]);

    if (!profile) return null;
    function handleSubmit(e: FormEvent) {
      e.preventDefault();
      const formData = new FormData(e.target as HTMLFormElement);

      const yearValue = formData.get("year") as string;

      const newProject = {
        assets,
        description: stateToMarkdown(description.getCurrentContent()),
        title: formData.get("title") as string,
        company: formData.get("company") as string,
        year: yearValue !== "" ? yearValue : undefined,
        role: formData.get("role") as string,
        team: formData.get("team") as string,
        skills,
        deliverables,
        industry,
      };

      let highlightsBody = {
        highlights: [...(profile?.highlights ?? []), newProject],
      };

      if (projectId && profile?.highlights) {
        highlightsBody = {
          highlights: profile.highlights.map((h) => {
            if (h._id === projectId) {
              return newProject;
            }
            return h;
          }),
        };
      }
      profileMutation.mutate(
        // @ts-expect-error TS2322: Type 'string | number' is not assignable to type 'string'.
        { ...highlightsBody, creativeId: profileId },
        {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          onSuccess(data) {
            if (isAdmin) {
              return navigate({
                to: myNetworkRoute.to,
                //@ts-expect-error TS2345: Inherited search types incorrect
                search: { port: profileId },
              });
            }

            if (fromProject) {
              navigate({ to: userProfileRoute.to });
            } else {
              navigate({ to: myWorkListRoute.to });
            }
            setProject(null);
            setSkills([]);
            setDeliverables([]);
            setIndustry(null);
            refetch();
          },
        },
      );
    }

    return (
      <div className="flex w-full flex-col overflow-auto md:px-10">
        <form
          className="flex w-full flex-col gap-6 p-6 pb-24 md:py-9"
          onSubmit={handleSubmit}
        >
          <div className="flex justify-between">
            <Typography size="2xl" weight="bold">
              {!projectId ? "New" : "Edit"} Project
            </Typography>
            <div className="flex gap-x-6">
              <ButtonNew
                theme="primary"
                variant="ghost"
                onClick={() => {
                  setProject(null);
                  setSkills([]);
                  setDeliverables([]);
                  setIndustry(null);
                  history.back();
                }}
              >
                Cancel
              </ButtonNew>
              <ButtonNew
                theme="primary"
                variant="filled"
                type="submit"
                disabled={assets.length < 1 || assets[0].source == null}
                isLoading={profileMutation.isPending}
              >
                Save
              </ButtonNew>
            </div>
          </div>

          <div className="grid gap-6 border-b border-b-base-black pb-6">
            <Input
              name={"title"}
              size="sm"
              label="Project Title *"
              required
              defaultValue={project?.title}
              key={project?.title ?? "project-title"}
            />
            <RichEditor
              label="Description"
              editorState={description}
              onChange={setDescription}
              placeholder="What's interesting about you as an individual?"
            />

            <div className="grid grid-cols-2 gap-6">
              <div className="grid auto-rows-min gap-6">
                <Input
                  name="company"
                  size="sm"
                  label="Company"
                  defaultValue={project?.company}
                  key={project?.company ?? "project-company"}
                />
                <Input
                  name="year"
                  size="sm"
                  label="Year"
                  type="number"
                  defaultValue={project?.year}
                  key={`project-year-${project?.year}`}
                />
              </div>
              <div className="grid auto-rows-min gap-6">
                <Input
                  name="role"
                  size="sm"
                  label="Role"
                  defaultValue={project?.role}
                  key={`project-role-${project?.role}`}
                />
                <Input
                  name="team"
                  size="sm"
                  label="Business Unit/Team"
                  defaultValue={project?.team}
                  key={`project-team-${project?.team}`}
                />
              </div>
              <div className="col-span-2 grid gap-6">
                <Select<false>
                  label="Industry"
                  placeholder="Search or Select"
                  disabled={!industryOptions || profileMutation.isPending}
                  options={industryOptions ?? []}
                  name="industry"
                  value={industryOptions?.find((o) => o.value === industry)}
                  onChange={(values) =>
                    setIndustry(values?.value as string | null)
                  }
                  sortOptions
                />
                <Select<true>
                  key={`skills-${skills.join("-")}`}
                  label="Skills"
                  placeholder="Search or Select"
                  disabled={!skillsOptions || profileMutation.isPending}
                  options={filterDuplicateOptions(skillsOptions) ?? []}
                  multiple
                  name="skills"
                  value={skills.map((s) => ({ value: s, label: s }))}
                  onChange={(values) =>
                    setSkills(values.map((v) => v.value as string))
                  }
                  allowCustom
                  sortOptions
                />
                <Select<true>
                  label="Deliverables"
                  placeholder="Search or Select"
                  disabled={!deliverableOptions || profileMutation.isPending}
                  options={filterDuplicateOptions(deliverableOptions) ?? []}
                  multiple
                  name="deliverables"
                  value={deliverables.map((s) => ({ value: s, label: s }))}
                  onChange={(values) =>
                    setDeliverables(values.map((v) => v.value as string))
                  }
                  sortOptions
                />
              </div>
            </div>
            <Typography weight="normal">Project Assets</Typography>

            <>
              <div className="flex gap-6">
                <ButtonNew
                  variant="outlined"
                  theme="primary"
                  onClick={() => {
                    // @ts-expect-error TS2345: this is totally valid
                    setAssets((prev) => {
                      return [
                        {
                          type: "image",
                          source: null,
                          description: "",
                        },
                        ...(prev ?? []),
                      ];
                    });

                    forceImageUpdate();
                  }}
                  disabled={imageUploadMutation.isPending}
                >
                  <Icon.PlusCircle />
                  Add Image
                </ButtonNew>
                <ButtonNew
                  variant="outlined"
                  theme="primary"
                  onClick={() => {
                    // @ts-expect-error TS2345: this is totally valid
                    setAssets((prev) => {
                      return [{ type: "video" }, ...(prev ?? [])];
                    });

                    forceImageUpdate();
                  }}
                  disabled={imageUploadMutation.isPending}
                >
                  <Icon.PlusCircle />
                  Add Video
                </ButtonNew>
              </div>
              <DragDropContext
                onDragEnd={(droppedItem: DropResult) => {
                  if (!droppedItem.destination) {
                    return;
                  }
                  const tempAssets = [...(assets ?? [])];
                  const [reorderedItem] = tempAssets.splice(
                    droppedItem.source.index,
                    1,
                  );

                  if (droppedItem.destination) {
                    tempAssets.splice(
                      droppedItem.destination.index,
                      0,
                      reorderedItem,
                    );
                  }
                  setAssets(tempAssets);
                }}
              >
                <Droppable droppableId={`image-asset-upload-list`}>
                  {(provided: DroppableProvided) => (
                    <div
                      className="grid gap-7"
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {assets?.map((asset, j) => {
                        return (
                          <Draggable
                            key={j + imageRerenders.toString()}
                            draggableId={j + imageRerenders.toString()}
                            index={j}
                          >
                            {(providedDrag: DraggableProvided) => (
                              <div
                                ref={providedDrag.innerRef}
                                {...providedDrag.dragHandleProps}
                                {...providedDrag.draggableProps}
                                className="relative flex flex-col gap-3 md:flex-row"
                              >
                                <ButtonNew
                                  className="absolute right-0 top-0 md:hidden"
                                  variant="ghost"
                                  theme="primary"
                                  onClick={() => {
                                    setAssets((prev) =>
                                      prev?.filter((_, k) => k !== j),
                                    );
                                  }}
                                >
                                  <Icon.XCircle size={24} />
                                </ButtonNew>
                                <div>
                                  <Icon.DotsSixVertical size={32} />
                                </div>
                                <div className="flex flex-col gap-6 md:ml-10 md:mt-6 md:flex-row">
                                  <>
                                    <div>
                                      {asset.type === "video" ? (
                                        asset.source ? (
                                          <iframe
                                            className="h-[168px] w-[300px] md:h-[336px] md:w-[600px]"
                                            src={getEmbedURL(asset.source)}
                                            title={asset.source}
                                          />
                                        ) : (
                                          <div
                                            className="flex h-[168px] w-[300px] cursor-pointer flex-col items-center justify-center gap-y-4 rounded bg-carbon-200 md:h-[336px] md:w-[600px]"
                                            role="button"
                                            tabIndex={0}
                                            onKeyDown={() => {
                                              alert({
                                                title: "Add Your Video",
                                                acceptLabel: "",
                                                body: (
                                                  <form
                                                    onSubmit={(
                                                      e: FormEvent,
                                                    ) => {
                                                      e.preventDefault();

                                                      const form = e.target;
                                                      const formData =
                                                        new FormData(
                                                          form as HTMLFormElement,
                                                        );
                                                      const videoUrl =
                                                        formData.get(
                                                          "videoUrl",
                                                        );
                                                      if (!videoUrl) return;

                                                      setAssets((prev) => {
                                                        const tempAssets = [
                                                          ...prev,
                                                        ];
                                                        tempAssets[j].source =
                                                          getEmbedURL(
                                                            videoUrl as string,
                                                          );
                                                        tempAssets[j].type =
                                                          "video";
                                                        return tempAssets;
                                                      });
                                                      forceImageUpdate();
                                                    }}
                                                  >
                                                    <Input
                                                      label="Video URL"
                                                      name="videoUrl"
                                                      fullWidth
                                                      required
                                                      size="sm"
                                                      placeholder="YouTube or Vimeo"
                                                    />
                                                    <div className="mt-6 flex gap-x-6">
                                                      <DialogButton
                                                        action="submit"
                                                        theme="primary"
                                                        type="submit"
                                                        size="sm"
                                                      >
                                                        Add Video
                                                      </DialogButton>
                                                      <DialogButton
                                                        action="submit"
                                                        theme="primary"
                                                        variant="outlined"
                                                        size="sm"
                                                      >
                                                        Cancel
                                                      </DialogButton>
                                                    </div>
                                                  </form>
                                                ),
                                              });
                                            }}
                                            onClick={() => {
                                              const videoUrlError = false;
                                              alert({
                                                title: "Add Your Video",
                                                acceptLabel: "",
                                                body: (
                                                  <form
                                                    onSubmit={(
                                                      e: FormEvent,
                                                    ) => {
                                                      e.preventDefault();

                                                      const form = e.target;
                                                      const formData =
                                                        new FormData(
                                                          form as HTMLFormElement,
                                                        );
                                                      const videoUrl =
                                                        formData.get(
                                                          "videoUrl",
                                                        );

                                                      if (!videoUrl) return;
                                                      if (
                                                        !youtubeOrVimeoRegex.test(
                                                          videoUrl as string,
                                                        )
                                                      ) {
                                                        return alert({
                                                          title:
                                                            "Please enter a valid YouTube or Vimeo URL",
                                                          body: (
                                                            <Typography>
                                                              Looks like the url
                                                              you entered
                                                              didn&apos;t jibe
                                                              with us. Please
                                                              fix the url and
                                                              try again.
                                                            </Typography>
                                                          ),
                                                        });
                                                      }

                                                      setAssets((prev) => {
                                                        const tempAssets = [
                                                          ...prev,
                                                        ];
                                                        tempAssets[j].source =
                                                          getEmbedURL(
                                                            videoUrl as string,
                                                          );
                                                        tempAssets[j].type =
                                                          "video";
                                                        return tempAssets;
                                                      });
                                                      forceImageUpdate();
                                                    }}
                                                  >
                                                    <Input
                                                      label="Video URL"
                                                      name="videoUrl"
                                                      fullWidth
                                                      required
                                                      size="sm"
                                                      placeholder="YouTube or Vimeo"
                                                      invalid={videoUrlError}
                                                      helpText={
                                                        videoUrlError
                                                          ? "Please enter a valid YouTube or Vimeo URL"
                                                          : ""
                                                      }
                                                    />
                                                    <div className="mt-6 flex gap-x-6">
                                                      <DialogButton
                                                        action="submit"
                                                        theme="primary"
                                                        type="submit"
                                                        size="sm"
                                                      >
                                                        Add Video
                                                      </DialogButton>
                                                      <DialogButton
                                                        action="submit"
                                                        theme="primary"
                                                        variant="outlined"
                                                        size="sm"
                                                      >
                                                        Cancel
                                                      </DialogButton>
                                                    </div>
                                                  </form>
                                                ),
                                              });
                                            }}
                                          >
                                            <Icon.PlusCircle
                                              className="mt-8 text-base-black"
                                              size={32}
                                            />
                                            <ButtonNew
                                              variant="ghost"
                                              theme="primary"
                                            >
                                              <Icon.UploadSimple /> Video URL
                                            </ButtonNew>
                                          </div>
                                        )
                                      ) : (
                                        <ImageUpload
                                          value={assetForUser(
                                            asset.source,
                                            700,
                                          )}
                                          isLoading={isLoading === j}
                                          onUpload={(file) => {
                                            setIsLoading(j);
                                            imageUploadMutation.mutate(
                                              file as File,
                                              {
                                                onSuccess: (res) => {
                                                  setAssets((prev) => {
                                                    return prev.map(
                                                      (asset, idx) => {
                                                        if (idx !== j)
                                                          return asset;
                                                        return {
                                                          ...asset,
                                                          source: res?.fields
                                                            .key as string,
                                                        };
                                                      },
                                                    );
                                                  });
                                                  setIsLoading(null);
                                                },
                                              },
                                            );
                                          }}
                                        />
                                      )}
                                    </div>
                                  </>
                                </div>
                                <div className="hidden md:block">
                                  <button
                                    onClick={() => {
                                      setAssets((prev) =>
                                        prev?.filter((_, k) => k !== j),
                                      );
                                    }}
                                    className="pb-4 pr-4"
                                  >
                                    <Icon.XCircle size={24} />
                                  </button>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </>
          </div>
        </form>
      </div>
    );
  },
  errorComponent() {
    return "Uh oh! That one isn't found";
  },
});
