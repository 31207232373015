import { getWorkflowsResponseSchema } from "@mg/schemas/src/christo/catalyst";

import { API_URL } from "../config/env";
import { smartFetch } from "../utils/http";

export async function getWorkflows() {
  const json = await smartFetch(`${API_URL}/private/catalyst/workflows`, {
    method: "GET",
  });

  const parsedData = getWorkflowsResponseSchema.parse(json);
  return parsedData;
}
