import cx from "classnames";
import React, { type HTMLAttributes } from "react";
import { extendTailwindMerge } from "tailwind-merge";

import { ReactComponent as MGLogo8 } from "./assets/logo-black-bg-fill.svg";
import { ReactComponent as MGLogo6 } from "./assets/logo-black-bg.svg";
import { ReactComponent as MGLogo3 } from "./assets/logo-black-fill.svg";
import { ReactComponent as MGLogoBlack } from "./assets/logo-black.svg";
import { ReactComponent as MGLogo7 } from "./assets/logo-white-bg-fill.svg";
import { ReactComponent as MGLogo5 } from "./assets/logo-white-bg.svg";
import { ReactComponent as MGLogo4 } from "./assets/logo-white-fill.svg";
import { ReactComponent as MGLogoWhite } from "./assets/logo-white.svg";
import { ReactComponent as PunttLogoBlue } from "./assets/puntt-logo.svg";
import { ReactComponent as PunttWordmarkBlue } from "./assets/puntt-wordmark.svg";
import { ReactComponent as MGWordmark3 } from "./assets/wordmark-black-fill.svg";
import { ReactComponent as MGWordmarkBlack } from "./assets/wordmark-black.svg";
import { ReactComponent as MGWordmark4 } from "./assets/wordmark-white-fill.svg";
import { ReactComponent as MGWordmarkWhite } from "./assets/wordmark-white.svg";

const twMerge = extendTailwindMerge({ prefix: "dali-" });

export interface LogoProps extends HTMLAttributes<SVGSVGElement> {
  /**
   * Optionally, the color variant of the Logo to use
   */
  variant?: "black-filled" | "white-filled" | "black" | "white" | "puntt";
  /**
   * Optionally, specify a type.
   */
  type?: "wordmark" | "logo" | "background";
}

export default function Logo({
  variant = "white-filled",
  type = "wordmark",
  ...rest
}: LogoProps) {
  const { className: hash, ...pass } = rest;

  const logoSize =
    type === "wordmark"
      ? "dali-h-[60px] dali-aspect-ratio"
      : type === "background"
        ? "dali-h-20 dali-aspect-square"
        : "dali-h-[60px] dali-aspect-square";
  const localClasses = cx(`mg-logo`, logoSize, {
    "mg-logo--icon": type !== "wordmark",
  });

  const classes = twMerge(localClasses, hash);
  const variants = {
    "black-filled": {
      wordmark: <MGWordmark3 className={classes} {...pass} />,
      logo: <MGLogo3 className={classes} {...pass} />,
      background: <MGLogo7 className={classes} {...pass} />,
    },
    "white-filled": {
      wordmark: <MGWordmark4 className={classes} {...pass} />,
      logo: <MGLogo4 className={classes} {...pass} />,
      background: <MGLogo8 className={classes} {...pass} />,
    },
    black: {
      wordmark: <MGWordmarkBlack className={classes} {...pass} />,
      logo: <MGLogoBlack className={classes} {...pass} />,
      background: <MGLogo5 className={classes} {...pass} />,
    },
    white: {
      wordmark: <MGWordmarkWhite className={classes} {...pass} />,
      logo: <MGLogoWhite className={classes} {...pass} />,
      background: <MGLogo6 className={classes} {...pass} />,
    },
    puntt: {
      wordmark: <PunttWordmarkBlue className={classes} {...pass} />,
      logo: <PunttLogoBlue className={classes} {...pass} />,
      background: <PunttLogoBlue className={classes} {...pass} />,
    },
  };

  return variants[variant as keyof typeof variants][type];
}
