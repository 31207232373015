import { ButtonNew, Icon, Input, Typography } from "@mg/dali/src";
import { type CreatePasswordBody } from "@mg/schemas/src/prince/auth";
import { Text } from "@radix-ui/themes";
import { useMutation } from "@tanstack/react-query";
// eslint-disable-next-line import/named
import { Link, useNavigate, useSearch } from "@tanstack/react-router";
import cx from "classnames";
import {
  type FormEventHandler,
  useReducer,
  useState,
  useRef,
  useCallback,
} from "react";
import zxcvbn from "zxcvbn";

import { resetPasswordRoute } from "./route";

import { LoginLayout } from "../../components/layouts/login";
import {
  resetWorkosPassword,
  type UpdatePasswordPayload,
} from "../../services/auth";
import { loginRoute } from "../login/route";

function useResetPasswordMutation() {
  return useMutation({
    mutationKey: ["reset-password"],
    mutationFn: resetWorkosPassword,
  });
}

export function ResetPasswordView() {
  // we won't automatically digest the token to apply the user.
  const { token } = useSearch({ from: resetPasswordRoute.to }) as {
    token: string;
  };
  const navigate = useNavigate();
  const mutation = useResetPasswordMutation();

  const formRef = useRef<HTMLFormElement>(null);
  const [isPasswordVisibilityToggled, togglePassworVisibility] = useReducer(
    (x) => !x,
    false,
  );
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [passwordStrength, setPasswordStrength] = useState<{
    suggestions: string[];
    score: number;
    password: string;
  }>({
    score: 0,
    suggestions: [],
    password: "",
  });

  const checkValidity = useCallback(() => {
    if (formRef.current == null) {
      return;
    }

    const elements = formRef.current.elements;
    // @ts-expect-error TS7015: this is totally valid
    const password = elements["password"] as HTMLInputElement;
    // @ts-expect-error TS7015: this is totally valid
    const confirmPassword = elements["confirm_password"] as HTMLInputElement;

    if (password.value !== confirmPassword.value) {
      return setPasswordsMatch(false);
    }

    return setPasswordsMatch(true);
  }, []);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form as HTMLFormElement);
    formData.set("password", formData.get("password") as string);
    // remove the confirm_password since we've already verified they're the
    // same.
    formData.delete("confirm_password");

    // convert the FormData to a POJO
    const data = Object.fromEntries(formData.entries()) as CreatePasswordBody &
      UpdatePasswordPayload;

    mutation
      .mutateAsync(data)
      .then((_response) => {
        navigate({ to: loginRoute.to });
      })
      .catch((error) => {
        let errorMessage;
        if (error instanceof Error) {
          errorMessage = error.message;
        } else {
          errorMessage = `An Unknown Error ${error} occurred`;
        }
        setErrorMessage(errorMessage);
      });
  };

  return (
    <LoginLayout>
      <Typography
        as="h1"
        weight="medium"
        size="2xl"
        className="text-base-black"
      >
        Reset your password.
      </Typography>
      <form onSubmit={handleSubmit} ref={formRef} className="grid gap-4">
        <input type="hidden" name="token" value={token} />

        <Input
          label="New Password"
          required
          endAdornment={
            isPasswordVisibilityToggled ? (
              <Icon.EyeClosed onClick={togglePassworVisibility} />
            ) : (
              <Icon.Eye onClick={togglePassworVisibility} />
            )
          }
          onInput={checkValidity}
          name="password"
          id="password"
          placeholder="••••••••"
          type={isPasswordVisibilityToggled ? "text" : "password"}
          size="sm"
          minLength={8}
          disabled={mutation.isPending}
          invalid={mutation.isError}
          onChange={(e) => {
            const evaluation = zxcvbn(e.target.value);
            setPasswordStrength({
              suggestions: [
                ...evaluation.feedback.suggestions,
                evaluation.feedback.warning,
              ],
              score: evaluation.score,
              password: e.target.value,
            });
          }}
        />

        <Text
          color={passwordStrength?.score < 3 ? "yellow" : "blue"}
          size="2"
          data-testid="password-strength-warning"
          className={cx({ hidden: !passwordStrength?.password })}
        >
          Password Strength: {passwordStrength.score < 3 ? "Weak" : "Strong"}
        </Text>

        <div className="flex flex-col">
          {passwordStrength?.password &&
            passwordStrength?.suggestions?.map((suggestion, id) => (
              <Text size="1" color="yellow" key={id}>
                {suggestion}
              </Text>
            ))}
        </div>

        <Input
          label="Verify New Password"
          required
          endAdornment={
            isPasswordVisibilityToggled ? (
              <Icon.EyeClosed onClick={togglePassworVisibility} />
            ) : (
              <Icon.Eye onClick={togglePassworVisibility} />
            )
          }
          onInput={checkValidity}
          name="confirm_password"
          id="confirm_password"
          placeholder="••••••••"
          type={isPasswordVisibilityToggled ? "text" : "password"}
          size="sm"
          minLength={8}
          disabled={mutation.isPending}
          invalid={mutation.isError || !passwordsMatch}
          helpText={!passwordsMatch ? "Passwords do not match" : null}
        />

        <Text
          color="red"
          size="2"
          data-testid="password-error-message"
          className={cx({ hidden: !mutation.isError })}
        >
          {mutation.error?.message ?? errorMessage}
        </Text>

        <ButtonNew
          type="submit"
          disabled={
            mutation.isPending ||
            (!!passwordStrength?.password && passwordStrength.score < 3)
          }
          isLoading={mutation.isPending}
          className="[&>span]:justify-center"
        >
          Reset Password
        </ButtonNew>
      </form>

      <Link to={loginRoute.to}>
        <ButtonNew variant="ghost" size="xs">
          <Typography as="span" weight="bold">
            Log In
          </Typography>{" "}
          to an existing account
        </ButtonNew>
      </Link>
    </LoginLayout>
  );
}
