import { ButtonNew, Input, Icon, Typography } from "@mg/dali/src";
import * as Dialog from "@radix-ui/react-dialog";
import { useMutation } from "@tanstack/react-query";
import { useNavigate, useParams } from "@tanstack/react-router";

import { useUI } from "../../../contexts/ui";
import { duplicateList } from "../../../services/lists";
import { useLists } from "../../../utils/queries/lists";
import { listRoute } from "../../list/route";

type DuplicateListDialogProps = {
  open: boolean;
  onOpenChange(open: boolean): void;
  title: string;
};

export const DuplicateListDialog = (props: DuplicateListDialogProps) => {
  const { listId } = useParams({ from: listRoute.id }) as { listId: string };
  const { notify } = useUI();
  const navigate = useNavigate();
  const lists = useLists();
  const { title, ...rest } = props;

  const duplicateListMutation = useMutation({
    mutationFn: duplicateList,
    async onSuccess(data) {
      notify({
        variant: "success",
        title: `Duplicated ${data.title}`,
        message: `You have successfully duplicated ${props.title} as ${data.title}.`,
        leadingIcon: <Icon.CheckCircle color="rgb(var(--base-black))" />,
      });

      lists.refetch().then(() => {
        props.onOpenChange(false);
        navigate({ to: listRoute.to, params: { listId: data._id } });
      });
    },
  });

  return (
    <Dialog.Root {...rest}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-[2] bg-base-black/50 data-[state=open]:animate-fade-in" />
        <Dialog.Content className="fixed left-1/2 top-1/2 z-[2] grid w-[90vw] max-w-lg -translate-x-1/2 -translate-y-1/2 gap-4 rounded-lg bg-base-white p-6 text-base-black shadow-2xl focus:outline-none data-[state=open]:animate-dialog-content-show">
          <Dialog.Title asChild>
            <Typography size="2xl" weight="medium">
              Duplicate List
            </Typography>
          </Dialog.Title>

          <form
            onSubmit={(e) => {
              e.preventDefault();

              const formData = new FormData(e.target as HTMLFormElement);
              const title = formData.get("title") as string;
              duplicateListMutation.mutateAsync({
                listId,
                title,
              });
            }}
          >
            <Input
              name="title"
              label="List Name"
              defaultValue={`Copy of ${title}`}
              size="sm"
              fullWidth
              data-testid="name-field"
              key={title}
            />
            <div className="mt-6 flex gap-4">
              <ButtonNew
                theme="primary"
                variant="filled"
                disabled={duplicateListMutation.isPending}
                type="submit"
                isLoading={duplicateListMutation.isPending}
              >
                Save Duplicate
              </ButtonNew>
              <Dialog.Close asChild>
                <ButtonNew theme="primary" variant="outlined">
                  Cancel
                </ButtonNew>
              </Dialog.Close>
            </div>
          </form>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
