import { Typography, ButtonNew } from "@mg/dali/src";
import * as Dialog from "@radix-ui/react-dialog";

import { SpreadSheetImporter } from "../SpreadSheetImporter";

type CustomCSVImportDialogProps = {
  open: boolean;
  onOpenChange(open: boolean): void;
};
export const CustomCSVImportDialog = (props: CustomCSVImportDialogProps) => {
  return (
    <Dialog.Root {...props}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-[5] bg-base-black/50 data-[state=open]:animate-fade-in" />
        <Dialog.Content className="fixed left-1/2 top-1/2 z-[6] grid w-[90vw] max-w-lg -translate-x-1/2 -translate-y-1/2 gap-6 rounded-lg bg-base-white p-6 text-base-black shadow-2xl focus:outline-none data-[state=open]:animate-dialog-content-show">
          <Dialog.Title asChild>
            <Typography size="2xl" weight="medium">
              Custom Spreadsheet Import
            </Typography>
          </Dialog.Title>

          <Typography>
            We don&apos;t require any format. You can just submit a list
            containing Name or Email and we&apos;ll take care of the rest.
          </Typography>

          <SpreadSheetImporter />

          <div className="flex gap-4">
            <ButtonNew>Import</ButtonNew>

            <Dialog.Close asChild>
              <ButtonNew variant="outlined">Cancel</ButtonNew>
            </Dialog.Close>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

CustomCSVImportDialog.displayName = "CustomCSVImportDialog";
