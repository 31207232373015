// eslint-disable-next-line import/named
import { createRoute, redirect } from "@tanstack/react-router";

import {
  canAccessCreativeConnect,
  canAccessEditOwnProfile,
  canAccessLists,
  canAccessPuntt,
} from "../../utils/auth";
import { createDefaultNetworkRouteSearch } from "../../utils/constants";
import { store } from "../../utils/store";
import { listsRoute } from "../lists/route";
import { loginRoute } from "../login/route";
import { myNetworkRoute } from "../network/route";
import { rootRoute } from "../root/route";
import { ticketsRoute } from "../tickets/route";
import { aboutMeEditRoute } from "../userProfile/routes/AboutMe/editRoute";

export const indexRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/",
  beforeLoad({ search }) {
    const user = store.getState().auth.value;

    if (user != null) {
      if (search.redirect) {
        throw redirect({ to: search.redirect });
      }

      if (canAccessPuntt()) {
        throw redirect({ to: ticketsRoute.to });
      }

      if (canAccessCreativeConnect()) {
        throw redirect({
          to: myNetworkRoute.to,
          search: createDefaultNetworkRouteSearch(),
        });
      }

      if (canAccessLists()) {
        throw redirect({ to: listsRoute.to });
      }

      if (canAccessEditOwnProfile()) {
        throw redirect({ to: aboutMeEditRoute.to });
      }
    }

    throw redirect({ to: loginRoute.to });
  },
});
