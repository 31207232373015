import { Em, Grid, Heading, Link, Text } from "@radix-ui/themes";
// eslint-disable-next-line import/named
import { createRoute, Link as NavLink } from "@tanstack/react-router";

import { indexRoute } from "../index/route";
import { rootRoute } from "../root/route";

export const privacyPolicyRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "privacy-policy",
  component: function Component() {
    return (
      <Grid gap="8" p="9" mx="auto" className="max-w-prose">
        <Heading size="8" align="center">
          Privacy Policy
        </Heading>

        <Text size="3">
          <Em>Last updated: August 28, 2024</Em>
        </Text>

        <Grid gap="4">
          <Text size="2">
            This privacy policy applies to the{" "}
            <Link asChild>
              <NavLink to={indexRoute.to}>app.puntt.ai</NavLink>
            </Link>{" "}
            website, all of its publicly accessibly sub-pages, and any plugins
            or extensions authored by Meaningful Gigs that connect to{" "}
            <Link asChild>
              <NavLink to={indexRoute.to}>app.puntt.ai</NavLink>
            </Link>{" "}
            (collectively, the &ldquo;Website&rdquo;). Services delivered
            through non-public webpages and other means may be covered under a
            separate policy or agreement (for example, specified in customer
            contracts). If you have questions about this, contact{" "}
            <Link href="mailto:security@meaningfulgigs.com">
              security@meaningfulgigs.com
            </Link>
            .
          </Text>

          <Text size="2">
            Persons under age 18 should not use this Website. By using the
            Website, you represent that you have the legal capacity to enter
            into a binding agreement and agree to be bound by this Privacy
            Policy.
          </Text>

          <Heading size="3">Anonymous Information</Heading>

          <Text size="2">
            We collect anonymous data from every visitor of the Website in order
            to operate our services&mdash;for example, to monitor traffic,
            improve Website navigation, and fix bugs. The anonymous data we
            collect is not personally identifying; rather, it is information
            like web requests, the data sent in response to such requests, the
            Internet Protocol address, and the browser type.
          </Text>

          <Heading size="3">Personal Information</Heading>

          <Text size="2">
            In order to take advantage of certain features of the Website, you
            may choose to provide us with personal information, such as your
            name, email address, a password used to log into the Website, and
            other contact information. Your decision to provide such data will
            always be voluntary.
          </Text>

          <Text size="2">
            We only use your personal information in the following ways:
            <ul className="list-disc pl-4">
              <li>to provide you with services</li>
              <li>
                to communicate with you about the services, the Website, or your
                account
              </li>
              <li>to provide you with information you request from us</li>
              <li>to enforce our rights arising from contracts</li>
            </ul>
          </Text>

          <Text size="2">
            <Link href="https://puntt.ai">Puntt.ai</Link> is operated from the
            United States. Regardless of location, by using the Website you
            agree to this processing of any personal information that you
            provide to us according to this Privacy Policy.
          </Text>

          <Text size="2">
            We do not share your personal information you have provided to us
            without your consent, unless doing so is appropriate to carry out
            your request, such as:
            <ul className="list-disc pl-4">
              <li>
                sharing your email address with third party email management
                software in order to contact you about changes to your account,
              </li>
              <li>
                sharing your support request with customer relationship
                management software that helps us effectively manage such
                requests,
              </li>
              <li>or other similar uses;</li>
              <li>
                we believe it&apos;s needed to detect, prevent, or address
                fraud, security, or technical issues;
              </li>
              <li>
                we believe it&apos;s needed to otherwise protect our property,
                our legal rights, or the property or legal rights of others;
              </li>
              <li>
                we sell our company or all or substantially all of our assets,
                enter into a merger, or if we out of business or enter
                bankruptcy, in which case we may transfer your personal
                information to a third party and the acquirer may continue to
                use your personal information as set forth in this Privacy
                Policy; or we believe it&apos;s required by law.
              </li>
            </ul>
          </Text>

          <Text size="2">
            If we are legally compelled to share your personal information, we
            will use reasonable efforts to give you prompt notice, unless we
            determine in good faith that either (a) we are not permitted to
            provide it under applicable law, or (b) that doing so would result
            in an imminent risk of death, serious physical injury, or
            significant property loss or damage to Instrumental or a third
            party.
          </Text>

          <Text size="2">
            If you have shared contact information with us, we may contact you
            by email or other means. For example, we may communicate with you
            about your use of the Website or send you promotional emails
            relating to Puntt.ai or Meaningful Gigs. We may also use technology
            to alert us when you open an email from us. For promotional and
            newsletter emails, you can modify your email notification
            preferences by clicking the appropriate link included in the footer
            of email notifications.
          </Text>

          <Text size="2">
            Our website is not intended for children under 13 years old. We will
            not knowingly collect, use, provide or process any personal
            information of children under age 13.
          </Text>

          <Heading size="3">Other Collected Information</Heading>

          <Text size="2">
            In the course of using the Website, you may upload business
            information such as images, PDFs, or other files in order to use
            them with the Website&apos;s services. For example, you may use our
            Chrome extension to upload screenshots of a web page in order to run
            an AI Design Review on the page. These files are stored securely on
            our servers and processed only in order to provide the requested
            services. Uploaded files are generally governed by an enterprise
            contract; typically your organization is the owner of these files,
            and you grant Meaningful Gigs a worldwide, non-exclusive right to
            use the files as part of providing the Website&apos;s services, and
            warrant that you have the right to do so.
          </Text>

          <Heading size="3">Access to Data by Agents</Heading>

          <Text size="2">
            We employ and contract with people and other entities that perform
            certain tasks on our behalf and who are under our control (our
            &ldquo;Agents&rdquo;). We may need to share personal information
            with our Agents in order to provide products or services to you.
            Unless we tell you differently, our Agents do not have any right to
            use personal information or other information we share with them
            beyond what is necessary to assist us. By submitting your personal
            information to us, you consent to our sharing of that personal
            information with our Agents.
          </Text>

          <Text size="2">
            We may also share personal information:
            <ul className="list-disc pl-4">
              <li>
                with a company we merge with, acquire, or that buys us, or in
                the event of change in structure of our company of any form,
              </li>
              <li>
                to comply with our legal obligations or to enforce our rights,
              </li>
              <li>
                with service providers that enable us to better provide the
                Website&apos;s services, or
              </li>
              <li>with your consent.</li>
            </ul>
          </Text>

          <Heading size="3">Data Security</Heading>

          <Text size="2">
            We have implemented physical, electronic and administrative measures
            designed to secure your Personal Information and Other Collected
            Information from accidental loss and from unauthorized access, use,
            alteration, and disclosure. We use encryption technology for
            information sent and received by us. Despite these measures, data
            transmitted through the Internet or stored electronically cannot be
            made absolutely secure.
          </Text>

          <Text size="2">
            The safety and security of your information also depends on you.
            Where you have chosen a password for the use of our Website, you are
            responsible for keeping this password confidential. We ask you not
            to share your password with anyone.
          </Text>

          <Heading size="3">Updating or Deleting Personal Information</Heading>

          <Text size="2">
            You may request us to update or delete your personal information by
            sending an email to:{" "}
            <Link href="mailto:security@meaningfulgigs.com">
              security@meaningfulgigs.com
            </Link>
            . When we delete your personal information, it will be deleted from
            our active databases but may remain in our back-up files.
          </Text>

          <Heading size="3">Links to Third Party Sites</Heading>

          <Text size="2">
            For your convenience, we may provide links to third-party websites.
            Our Privacy Policy does not apply to sites owned by third parties.
          </Text>

          <Heading size="3">Changes to the Privacy Policy</Heading>

          <Text size="2">
            We may amend this Privacy Policy from time to time. Use of
            information we collect now is subject to the Privacy Policy in
            effect at the time such information is used. If we make major
            changes in the way we collect or use information, we will notify you
            by posting an announcement on the Website or sending you an email.
            You are bound by any changes to the Privacy Policy when you use the
            Website after such changes have been first posted.
          </Text>
        </Grid>
      </Grid>
    );
  },
});
