import { Icon } from "@mg/dali/src";
import cx from "classnames";
import { type ReactNode } from "react";

export const ENTERPRISE_PROFILE_TYPES = [
  "upwork",
  "agency",
  "freelancer",
] as const;

export const ENTERPRISE_PROFILE_OPTIONS = ENTERPRISE_PROFILE_TYPES.map(
  (type) => ({
    value: type,
    label: `${type[0].toUpperCase()}${type.slice(1)}` as Capitalize<
      typeof type
    >,
  }),
);

export const availabilityOptions = [
  {
    label: "Full-time (40 hours/week)",
    value: "available-fulltime",
  },
  {
    label: "Part-time (20 hours/week)",
    value: "available-parttime",
  },
  {
    label: "Booked with Meaningful Gigs",
    value: "unavailable-booked",
  },
  {
    label: "Not Active with Meaningful Gigs",
    value: "unavailable-inactive",
  },
];

export const pronounOptions = [
  {
    label: "He/Him",
    value: "He/Him",
  },
  {
    label: "She/Her",
    value: "She/Her",
  },
  {
    label: "They/Them",
    value: "They/Them",
  },
  {
    label: "Ze/Zir",
    value: "Ze/Zir",
  },
  {
    label: "Other",
    value: "Other",
  },
  {
    label: "Prefer to Not Say",
    value: "Prefer to Not Say",
  },
];

export const COMPANY_SIZES = [
  { label: "1-9", value: "1-9" },
  { label: "10-199", value: "10-199" },
  { label: "200-999", value: "200-999" },
  { label: "1,000-4,999", value: "1,000-4,999" },
  { label: "5,000-9,999", value: "5,000-9,999" },
  { label: "10,000+", value: "10,000+" },
];

export const languageOptions = [
  { label: "English", value: "English" },
  { label: "Mandarin Chinese", value: "Mandarin Chinese" },
  { label: "Hindi", value: "Hindi" },
  { label: "Spanish", value: "Spanish" },
  { label: "French", value: "French" },
  { label: "Standard Arabic", value: "Standard Arabic" },
  { label: "Bengali", value: "Bengali" },
  { label: "Russian", value: "Russian" },
  { label: "Portuguese", value: "Portuguese" },
  { label: "Urdu", value: "Urdu" },
  { label: "Indonesian", value: "Indonesian" },
  { label: "German", value: "German" },
  { label: "Japanese", value: "Japanese" },
  { label: "Swahili", value: "Swahili" },
  { label: "Marathi", value: "Marathi" },
  { label: "Telugu", value: "Telugu" },
  { label: "Punjabi", value: "Punjabi" },
  { label: "Wu (Shanghainese)", value: "Wu (Shanghainese)" },
  { label: "Tamil", value: "Tamil" },
  { label: "Turkish", value: "Turkish" },
  { label: "Korean", value: "Korean" },
  { label: "Vietnamese", value: "Vietnamese" },
  { label: "Hausa", value: "Hausa" },
  { label: "Burmese", value: "Burmese" },
  { label: "Thai", value: "Thai" },
  { label: "Yoruba", value: "Yoruba" },
  { label: "Oromo", value: "Oromo" },
  { label: "Igbo", value: "Igbo" },
  { label: "Zulu", value: "Zulu" },
  { label: "Somali", value: "Somali" },
  { label: "Amharic", value: "Amharic" },
  { label: "Malagasy", value: "Malagasy" },
  { label: "Kinyarwanda", value: "Kinyarwanda" },
];

export const domainRegex = new RegExp(
  /^(?:https?:\/\/)?(?:www\.)?([^.]+)\.[a-z]{2,}\/.*$/i,
);

export const SOCIAL_LINKS_MAP = {
  instagram: <Icon.InstagramLogo size={16} />,
  linkedin: <Icon.LinkedinLogo size={16} />,
  pinterest: <Icon.PinterestLogo size={16} />,
  youtube: <Icon.YoutubeLogo size={16} />,
  facebook: <Icon.FacebookLogo size={16} />,
  behance: <Icon.BehanceLogo size={16} />,
  dribbble: <Icon.DribbbleLogo size={16} />,
};

export const SPECIALTY_ICON_MAP: Record<string, ReactNode> = {
  "UX / UI Product Design": <Icon.Layout />,
  "Brand & Marketing Design": <Icon.Palette />,
  "Illustration, Graphic & Visual Storytelling": <Icon.PenNib />,
  "Motion, Video & Animation": <Icon.FilmSlate />,
  Copywriting: <Icon.Notepad />,
  "Industrial Design": <Icon.HighlighterCircle />,
  "Creative & Design Operations": <Icon.Lightning />,
};

export const CREATIVE_ROLES_OPTIONS = [
  { value: "freelancer", label: "Freelancer/Contractor" },
  { value: "agency", label: "Studio/Agency" },
  { value: "employee", label: "Employee" },
];

export const LOADING_TEXT_LIST = [
  "Making the logo bigger.",
  "0011010010010… Err, I mean, scanning your profile",
  "Actively steaming the hams",
  "Boo! Did we scare you?",
  "How can you keep someone in suspense?",
  "The pixels are aligning perfectly",
  "Resolving creative conflicts. Our pixels are in therapy.",
  "This loading screen is our magnum opus.",
  "Debating the profound meaning of 'Loading.' It's a deep subject.",
  "Deciding between 'ecru' and 'eggshell.' Color palettes are hard.",
  "Adjusting the keming",
  "Making impatience a design feature.",
  "Turning caffeine into creativity",
  "Lorem Ipsum, language of the gods",
  "Making it pop",
  "We love it! But we have a few thoughts.",
];

export const baseToggleItemClasses = cx(
  "text-base-black flex items-center justify-center bg-white h-8 w-max min-w-8 px-2 transition-colors",
  "hover:bg-vermillion-50/50",
  "data-[state=on]:bg-vermillion-600 data-[state=on]:text-base-white",
);
export const toggleItemClasses = cx(
  baseToggleItemClasses,
  "first:rounded-l-lg last:rounded-r-lg",
);

// continue to add to this array any TLDraw keys that you
// don't want saved to the database.
export const IGNORED_CANVAS_UPDATE_KEYS = [
  "document:document",
  "pointer:pointer",
  "instance:instance",
  "instance_page_state:page:page", // this is your current presence cursor position
  "camera:page:page",
  "page:page",
];

export function getNameInitials(name?: string) {
  if (name == null) {
    return "N/A";
  }

  const realName = name ?? "";
  const firstInitial = realName[0];
  const lastInitial =
    realName.lastIndexOf(" ") !== -1 && realName[realName.lastIndexOf(" ") + 1];

  if (lastInitial) {
    return `${firstInitial}${lastInitial}`;
  }

  return firstInitial;
}

// TODO: this shouldn't be necessary as the myNetworkRoute searchParser
// can just add these in for us if they're required...
export function createDefaultNetworkRouteSearch() {
  return {
    tags: [],
    level: [],
    names: [],
    tools: [],
    brands: [],
    skills: [],
    queries: [],
    timezone: [],
    industries: [],
    specialties: [],
    deliverables: [],
  };
}

export function deepEqual<T>(a: T, b: T): boolean {
  if (a === b) return true;

  if (typeof a !== typeof b) return false;

  // Handle null and undefined cases
  if (a === null || b === null || a === undefined || b === undefined)
    return a === b;

  // Handle Date comparison
  if (a instanceof Date && b instanceof Date)
    return a.getTime() === b.getTime();

  // Handle Array comparison
  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i++) {
      if (!deepEqual(a[i], b[i])) return false;
    }
    return true;
  }

  // Handle Object comparison
  if (typeof a === "object" && typeof b === "object") {
    const aKeys = Object.keys(a as object);
    const bKeys = Object.keys(b as object);

    if (aKeys.length !== bKeys.length) return false;

    for (const key of aKeys) {
      if (!(key in b) || !deepEqual((a as any)[key], (b as any)[key])) {
        return false;
      }
    }

    return true;
  }

  // Fallback for non-object types (number, string, boolean, symbol)
  return a === b;
}

export const DISALLOWED_FILES = [
  "Thumbs.db", // Windows thumbnail cache
  "desktop.ini", // Windows folder customization
  ".DS_Store", // macOS system file
  ".Spotlight-V100", // macOS search indexing
  ".VolumeIcon.icns", // macOS volume icon
  "._.", // macOS extended attributes
  ".Trashes", // macOS trash data
  ".Trash", // Linux trash data
  ".thumbnails", // Linux thumbnails
  ".cache", // Linux cache
  "recently-used.xbel", // Linux recently used files
];
