import { datadogRum } from "@datadog/browser-rum";
import {
  type LoginResponse,
  type ShowcaseToken,
} from "@mg/schemas/src/prince/auth";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";

import { COOKIE_NAME } from "../../config/env";
import { queryClient } from "../queryClient";

type AuthState = {
  value: ShowcaseToken | null;
};

const initialState: AuthState = {
  value: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    /**
     * Given a token, decodes the token and sets the user object.
     * Note: This should be invoked immediately after hitting an endpoint that
     * returns a token. This action does not check for token expiry before
     * setting the user. To check token expiry, use `derive` instead.
     */
    login(state, action: PayloadAction<LoginResponse>) {
      const decoded = jwtDecode<ShowcaseToken>(action.payload.token, {});

      // will error at this point if invalid token.

      localStorage.setItem("token", action.payload.token);
      sendTokenToServiceWorker();

      state.value = decoded;
      datadogRum.setUser({
        id: decoded.userID,
        ...decoded,
      });
    },
    /**
     * Removes the token from localStorage and sets the state to `null`.
     */
    logout(state, action: PayloadAction<string | undefined>) {
      localStorage.removeItem("token");
      sendTokenToServiceWorker();
      state.value = null;
      // Also remove WorkOS cookie
      document.cookie = `${COOKIE_NAME}=; max-age=0; path=/; domain=.puntt.ai; Secure; SameSite=None`;

      // reset query client
      queryClient.invalidateQueries();
      queryClient.clear();

      // reset Datadog user
      datadogRum.setUser({});

      if (action.payload != null) {
        localStorage.setItem("redirect", action.payload);
      }
    },
    // this action sucks and should be improved. It's a 1:1 of Hendrix's auth
    // provider logic, but doesn't properly handle the case where we need to
    // refresh the local token.
    derive(state) {
      // TODO: use this temporarily until parsed search is added back to router.
      const search = new URLSearchParams(window.location.search);
      const urlToken = search.get("t");
      const localToken = localStorage.getItem("token");
      const listRedirect = search.get("r");
      const tokenIsExpired = (token: ShowcaseToken) =>
        Date.now() / 1000 > (token.exp as number);

      let defaultUser: (ShowcaseToken & { listPath?: string }) | null = null;

      if (urlToken && !localToken) {
        const stringToken = String(urlToken);
        const decodedToken = jwtDecode<ShowcaseToken>(stringToken);

        if (!tokenIsExpired(decodedToken)) {
          defaultUser = decodedToken;
          localStorage.setItem("token", stringToken);
          sendTokenToServiceWorker();
        }
      } else if (localToken && !urlToken) {
        const decodedToken = jwtDecode<ShowcaseToken>(localToken);

        if (tokenIsExpired(decodedToken)) {
          localStorage.removeItem("token");
          sendTokenToServiceWorker();
        } else {
          defaultUser = decodedToken;
        }
      } else if (localToken && urlToken) {
        const decodedLocalToken = jwtDecode<ShowcaseToken>(localToken);
        const decodedUrlToken = jwtDecode<ShowcaseToken>(urlToken);

        if (tokenIsExpired(decodedLocalToken)) {
          if (tokenIsExpired(decodedUrlToken)) {
            localStorage.removeItem("token");
            sendTokenToServiceWorker();
          } else {
            // replace the local token with the URL token if the local token is
            // expired
            defaultUser = decodedUrlToken;
            localStorage.setItem("token", urlToken);
            sendTokenToServiceWorker();
          }
        } else if (!tokenIsExpired(decodedUrlToken)) {
          defaultUser = decodedUrlToken;
          localStorage.setItem("token", urlToken);
          sendTokenToServiceWorker();
        } else {
          defaultUser = decodedLocalToken;
        }
      }

      if (defaultUser) {
        datadogRum.setUser({
          id: defaultUser.userID,
          ...defaultUser,
        });
      }
      if (listRedirect) {
        localStorage.setItem("listPath", listRedirect);
      }

      state.value = defaultUser;
    },
  },
});

export const { derive, login, logout } = authSlice.actions;
