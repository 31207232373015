import { Avatar, Typography } from "@mg/dali/src";
// eslint-disable-next-line import/named
import { Link } from "@tanstack/react-router";

import { useAppSelector } from "../../../utils/hooks";
import { assetForUser } from "../../../utils/imageHandler";
import { profileRoute } from "../route";

export function Recommendations() {
  const creative = useAppSelector((state) => state.profile.value);

  if (
    !creative ||
    // @ts-expect-error TS2367: Backend incorrect types
    creative.type === "pipeline" ||
    // @ts-expect-error TS2367: Backend incorrect types
    !creative.recommendations?.total
  ) {
    return null;
  }

  return (
    <div className="grid gap-6 py-9" data-testid="recommendations-section">
      <Typography size="xl" weight="medium" className="text-carbon-900">
        More Creatives Like {creative.name}
      </Typography>

      <div className="grid gap-3 md:grid-cols-2">
        {/* @ts-expect-error TS2339: recommendations with actually exists */}
        {creative.recommendations?.results?.map((s) => (
          <Link to={profileRoute.to} params={{ profileId: s._id }} key={s._id}>
            <section className="grid auto-rows-min grid-cols-[auto_1fr] gap-2.5">
              <figure className="flex items-center gap-3">
                <Avatar
                  image={assetForUser(s.avatar)}
                  alt={`${s.name}'s headshot`}
                />
              </figure>

              <Typography
                weight="medium"
                className="self-center text-base-black"
              >
                {s.name}
              </Typography>

              <div
                className={"h-max rounded px-0.5 text-center text-base-white"}
              ></div>
            </section>
          </Link>
        ))}
      </div>
    </div>
  );
}
