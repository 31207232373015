import {
  DialogButton,
  Dialog,
  DialogActions,
  DialogContent,
  type DialogProps,
  ButtonNew,
  Select,
  Icon,
} from "@mg/dali/src";
import { type GetFormattedResponse } from "@mg/schemas/src/christo/concepts";
import { EditorState } from "draft-js";
// @ts-expect-error TS7016: Could not find a declaration file for module 'draft-js-export-markdown'.
import { stateToMarkdown } from "draft-js-export-markdown";
import { forwardRef, useState, type Ref, useEffect } from "react";

import { RichEditor } from "./RichEditor";

import { useUI } from "../contexts/ui";
import { useSendMessage } from "../utils/queries/messages";
import { useMessageablePeople } from "../utils/queries/network";
import { useSelections } from "../utils/selections";

export const SendMessageDialog = forwardRef<
  HTMLDialogElement,
  DialogProps & {
    ids?: string[];
  }
>(
  (
    props: DialogProps & {
      ids?: string[];
    },
    ref: Ref<HTMLDialogElement>,
  ) => {
    const { notify } = useUI();
    const [editorState, setEditorState] = useState(() =>
      EditorState.createEmpty(),
    );
    const [selectedPeople, setSelectedPeople] = useState<GetFormattedResponse>(
      [],
    );
    const selections = useSelections();
    const messageablePeople = useMessageablePeople();
    const messagePeopleMutation = useSendMessage();

    useEffect(() => {
      let selected: string[] = [];
      if (!Array.isArray(selections)) {
        selected = [selections as string];
      } else {
        selected = selections;
      }

      if ((props.ids || selections?.length) && messageablePeople.data) {
        setSelectedPeople(
          (props.ids ?? selected ?? [])
            ?.map((id) => {
              const person = messageablePeople.data?.find((p) => p._id === id);

              if (person != null) {
                return {
                  label: person.name,
                  value: person._id,
                };
              }

              return null;
            })
            .filter(Boolean),
        );
      }
    }, [messageablePeople.data, props.ids, selections]);

    return (
      <Dialog
        open={props.open}
        onClose={(evt) => {
          props.onClose && props.onClose(evt);
          setEditorState(EditorState.createEmpty());
        }}
        ref={ref}
        title="Message"
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();

            const contentState = editorState.getCurrentContent();
            const payload = {
              content: stateToMarkdown(contentState),
              recipientIds: selectedPeople.map((p) => p.value),
              bcc: [],
            };

            messagePeopleMutation.mutate(
              { payload },
              {
                async onSuccess() {
                  notify({
                    variant: "success",
                    title: `Message Sent!`,
                    leadingIcon: (
                      <Icon.CheckCircle color="rgb(var(--base-black))" />
                    ),
                  });
                  setEditorState(EditorState.createEmpty());
                  //@ts-expect-error TS2339: Property 'current' should exits at this point
                  ref?.current?.close();
                },
              },
            );
          }}
        >
          <DialogContent>
            <div className="grid gap-3.5">
              <Select<true>
                label="Recipients"
                placeholder="Start Typing ..."
                name="recipients"
                onChange={(values) => {
                  setSelectedPeople(values as GetFormattedResponse);
                }}
                options={
                  messageablePeople.data?.map((p) => ({
                    label: p.name,
                    value: p._id,
                  })) ?? []
                }
                value={selectedPeople}
                multiple
              />
              <RichEditor
                label="Message"
                editorState={editorState}
                onChange={setEditorState}
                placeholder="What message would you like to send?"
              />
            </div>
          </DialogContent>

          <DialogActions>
            <ButtonNew
              type="submit"
              theme="primary"
              disabled={
                messagePeopleMutation.isPending ||
                !selectedPeople.length ||
                !editorState.getCurrentContent().hasText()
              }
              isLoading={messagePeopleMutation.isPending}
            >
              Send Message
            </ButtonNew>

            <DialogButton theme="primary" variant="outlined" action="cancel">
              Cancel
            </DialogButton>
          </DialogActions>
        </form>
      </Dialog>
    );
  },
);

SendMessageDialog.displayName = "SendMessageDialog";
