import { Popover } from "@headlessui/react";
import { Icon, Input, Typography } from "@mg/dali/src";
import { type BaseRevisionBoard } from "@mg/schemas/src/christo/catalyst";
import {
  type Asset,
  EnterpriseProfileType,
  TicketWorkflowStep,
  type DesignReviewConfig,
  TicketCommentDisposition,
  TicketCommentBoard,
  TicketStatus,
  Ticket,
} from "@mg/schemas/src/commons";
import { Upload, UploadSimple } from "@phosphor-icons/react";
import {
  Button,
  Dialog,
  Flex,
  Select,
  Text,
  Tooltip,
  VisuallyHidden,
} from "@radix-ui/themes";
import { useNavigate, useParams, useSearch } from "@tanstack/react-router";
import {
  AssetRecordType,
  createShapeId,
  DefaultZoomMenu,
  DefaultZoomMenuContent,
  type Editor,
  exportToBlob,
  getHashForString,
  Tldraw,
  track,
  useEditor,
  useTools,
  debounce,
  type Box,
  type Vec,
  getSnapshot,
  TLImageAsset,
  TLVideoAsset,
  TLShapeId,
  type TLAsset,
  type TLShape,
} from "@tldraw/tldraw";
import cx from "classnames";
import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";

import { ApproveRevisionDialog } from "./ApproveRevisionDialog";
import { Checkerboard } from "./Checkerboard";
import { NoPermissionsView } from "./NoPermissionsView";
import { ShareTicketDialog } from "./ShareTicketDialog";
import { useYjsStore } from "./useYjsStore";
import "./pdf-editor.css";
import { VideoMode } from "./VideoPlayback";

import { AuthTooltip } from "../../../components/AuthTooltip";
import { AvatarWithInitials } from "../../../components/AvatarWithInitials";
import PendingProgress from "../../../components/PendingProgressBar";
import { ReactComponent as ComboLogo } from "../../../images/Handbubble.svg";
import {
  screenshotURLFromPayload,
  uploadImageAsset,
  uploadToS3,
  urlAndPayloadFromPayload,
} from "../../../services/upload";
import { useAnalytics } from "../../../utils/analytics";
import {
  canApproveTicket,
  canCreateRevisions,
  canMarketingAccess,
  canSetTicketStatus,
} from "../../../utils/auth";
import { deepEqual } from "../../../utils/constants";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import {
  useApproveTicketMutation,
  useEditProjectMutation,
  useRevisionMutation,
  useUpdateRevisionMutation,
} from "../../../utils/queries/projects";
import { setTicket, updateRevisionAtIndex } from "../../../utils/slices/ticket";
import {
  assetUrls,
  injectCustomCursorStyles,
} from "../../../utils/tldraw/assets";
import {
  externalAssetHandler,
  filterAndSortShapes,
  filterCommentsFromStore,
  getFilteredImageShapes,
  getShapesBounds,
  updateCameraBounds,
} from "../../../utils/tldraw/handlers";
import { isDocFile } from "../../../utils/tldraw/pdfs";
import { customShapeUtils } from "../../../utils/tldraw/shapeUtils";
import { customTools } from "../../../utils/tldraw/tools";
import { isKeyboardEventInInputField } from "../../../utils/validation";
import { ticketsRoute } from "../route";
import { CommentDrawer } from "../routes/components/comment-drawer";
import { useEditorAutoSave } from "../routes/components/useEditorAutoSave";
import { ticketRoute, useTicket } from "../routes/ticket";

export function Revision() {
  const { ticketId } = useParams({ from: ticketRoute.id });
  const { setTab, refreshTicket, headerHeight, setHasInitiatedAiReview } =
    useTicket();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.auth.value);
  const posthog = useAnalytics("Revisions");
  const {
    value: ticket,
    comments,
    revisions,
    revisionScreenshots,
  } = useAppSelector((state) => state.ticket);
  const { showResolvedComments, showDismissedComments } = useAppSelector(
    (state) => state.ui,
  );
  const createRevisionMutation = useRevisionMutation();
  const updateRevisionMutation = useUpdateRevisionMutation();
  const approveTicketMutation = useApproveTicketMutation(ticketId);
  const ticketMutation = useEditProjectMutation(ticketId);
  const dispatch = useAppDispatch();

  const { revIndex: activeRevisionIndex, tab } = useSearch({
    from: ticketRoute.id,
  });

  const reversedIndex = useMemo(() => {
    return revisions.length - 1 - (activeRevisionIndex ?? 0);
  }, [revisions.length, activeRevisionIndex]);

  const [selectedRevision, setSelectedRevision] = useState<
    BaseRevisionBoard | undefined
  >(activeRevisionIndex == null ? undefined : revisions[reversedIndex]);

  const setActiveRevisionIndex = useCallback(
    (idx: number, select = true) => {
      if (select) {
        setSelectedRevision(revisions[idx]);
        setHasInitiatedAiReview(false);
      }

      return navigate({
        replace: true,
        search: {
          revIndex: revisions.length - 1 - idx,
          tab,
        },
      });
    },
    [navigate, revisions, tab],
  );

  const [revisionDialogOpen, setRevisionDialogOpen] = useState(false);
  const [editor, setEditor] = useState<Editor>();
  const [shouldCreateBoard, setShouldCreateBoard] = useState(false);
  const [newRevisionAssets, setNewRevisionAssets] = useState({
    reviewFiles: [],
  });
  const [assetsUploading, setAssetsUploading] = useState(false);
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const videoRef = useRef<{
    handleCommentClicked: (_seekTime: number) => void;
    duration: number;
  }>(null);

  const store = useYjsStore({
    roomId: selectedRevision?._id ?? "empty-revision",
    shapeUtils: customShapeUtils,
  });
  const isDocMode = useMemo(() => {
    return (
      selectedRevision?.reviewFiles?.length === 1 &&
      isDocFile(selectedRevision.reviewFiles[0]?.source)
    );
  }, [selectedRevision]);
  const isVideoMode = useMemo(() => {
    const htmlVideoExtensions = [".mp4", ".webm", ".ogv", ".mov", ".m3u8"];

    return (
      selectedRevision?.reviewFiles?.length === 1 &&
      htmlVideoExtensions.includes(
        selectedRevision.reviewFiles[0].source
          .slice(selectedRevision.reviewFiles[0].source.lastIndexOf("."))
          .toLowerCase(),
      )
    );
  }, [selectedRevision]);

  const autosaveCallback = useCallback(async () => {
    if (
      editor == null ||
      store.store == null ||
      selectedRevision == null ||
      activeRevisionIndex == null ||
      selectedRevision.isPending ||
      isDocMode
    ) {
      return null;
    }

    const snapshot = filterCommentsFromStore(getSnapshot(store.store).document);

    const shapes = editor
      .getCurrentPageShapes()
      .filter(
        (shape) => shape.type !== "comment" && shape.type !== "comment-avatar",
      );

    const blob = await exportToBlob({
      editor,
      ids: shapes.map((shape) => shape.id),
      format: "png",
    });
    const fileName = ticket?.title.replace(/\W+/g, "-") + "-revision.png";
    const file = new File([blob], fileName, { type: blob.type });
    const payloadOriginal = (await uploadImageAsset(
      file,
      true,
      false,
    )) as FormData;
    const uploadParams = urlAndPayloadFromPayload(payloadOriginal);
    await uploadToS3(uploadParams);
    const screenshotUrl = screenshotURLFromPayload(payloadOriginal);

    updateRevisionMutation
      .mutateAsync({
        boardId: selectedRevision._id,
        ticketId: ticketId,
        payload: {
          shapes: snapshot,
          screenshotUrl,
        },
      })
      .then((response) => {
        dispatch(
          updateRevisionAtIndex({
            index: reversedIndex,
            revision: response,
            screenshotOnly: true,
          }),
        );
      });
  }, [
    activeRevisionIndex,
    editor,
    isDocMode,
    selectedRevision,
    ticket?.title,
    ticketId,
    revisions,
    store.store,
  ]);

  useEditorAutoSave(editor, autosaveCallback);

  // runs twice with zero or more revisions
  useEffect(() => {
    posthog.setContext({
      revisionBoard: selectedRevision,
    });
    setIsLoading(false);

    return () => {
      posthog.setContext({ revisionBoard: null });
    };
    // Do not put Posthog as a dependency here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRevision]);

  // effect runs when we navigate to this component and there is a revision we
  // can render.
  // Runs twice with zero or more revisions
  useEffect(() => {
    if (revisions.length > 0 && activeRevisionIndex == null) {
      setActiveRevisionIndex(0);
      return;
    }
  }, [revisions, activeRevisionIndex]);

  // this function is called only when a new revision is created.
  const handlePendingAssets = useCallback(
    async (revision: BaseRevisionBoard) => {
      if (editor == null || store.store == null || isVideoMode) {
        return;
      }
      setIsLoading(true);
      // we need to clear the existing assets and shapes so that we don't
      // double-add them to new revisions

      const existingAssets = editor.getAssets().map((asset) => asset.id);
      const existingShapes = Array.from(editor.getCurrentPageShapeIds());
      editor.deleteAssets(existingAssets);
      editor.deleteShapes(existingShapes);

      store.store.mergeRemoteChanges(() => {
        store.store.remove(existingShapes);
        store.store.remove(existingAssets);
      });

      const reviewFiles = revision.reviewFiles ?? [];

      if (reviewFiles.length > 0) {
        for (const asset of reviewFiles) {
          const shapeId = createShapeId();
          const assetId = AssetRecordType.createId(
            getHashForString(asset.source),
          );
          const fileExt = asset.source
            .slice(asset.source.lastIndexOf("."))
            .toLowerCase();
          const imageExtensions = [
            ".png",
            ".jpg",
            ".jpeg",
            ".webp",
            ".svg",
            ".gif",
          ];

          if (imageExtensions.includes(fileExt)) {
            const assetRecord = AssetRecordType.create({
              id: assetId,
              type: "image",
              props: {
                src: asset.source,
                h: asset.height as number,
                w: asset.width as number,
                name: asset.source.slice(asset.source.lastIndexOf("/")),
                isAnimated: [".gif"].includes(fileExt),
                mimeType: "image/png",
              },
            });

            editor.createAssets([assetRecord]);

            editor.createShape({
              id: shapeId,
              type: "image",
              x: asset.x,
              y: asset.y,
              props: {
                assetId,
                h: asset.height,
                w: asset.width,
              },
            });
            continue;
          }
          if (fileExt === ".pdf") {
            continue;
          }
          editor.createShape({
            id: shapeId,
            type: "external",
            x: asset.x,
            y: asset.y,
            meta: {
              file: {
                name: asset.source.slice(asset.source.lastIndexOf("/")),
              },
              href: asset.source,
              w: 300,
              h: 60,
            },
          });
        }

        setShouldCreateBoard(false);
        setSelectedRevision((curr) => {
          const res = { ...(curr ?? revision) };
          res.shapes = getSnapshot(store.store).document;

          return res;
        });
        editor.zoomToFit();
      }
      setIsLoading(false);
    },
    [store.store, isVideoMode, editor],
  );

  // when creating the very first revision, editor is always undefined, which
  // means we have to flag set and watch for changes to editor so that we can
  // create the board once it is defined.
  // Runs about 4 times
  useEffect(() => {
    if (
      editor != null &&
      selectedRevision != null &&
      !selectedRevision.isPending &&
      (shouldCreateBoard || selectedRevision.shapes == null)
    ) {
      handlePendingAssets(selectedRevision);
      injectCustomCursorStyles();
    }
  }, [shouldCreateBoard, editor, handlePendingAssets, selectedRevision]);

  useEffect(() => {
    if (
      editor != null &&
      selectedRevision != null &&
      !shouldCreateBoard &&
      selectedRevision.shapes == null &&
      !selectedRevision.isPending
    ) {
      handlePendingAssets(selectedRevision);
      injectCustomCursorStyles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handlePendingAssets, editor, selectedRevision]);

  // load the snapshot of the current board (if available) to TLDraw
  // Runs 4 times
  useEffect(() => {
    if (
      store.store == null ||
      selectedRevision?.shapes == null ||
      editor == null
    ) {
      return;
    }

    const currentSnapshot = store.store.getStoreSnapshot();

    if (!deepEqual(currentSnapshot.store, selectedRevision.shapes.store)) {
      const existingAssets = editor.getAssets().map((asset) => asset.id);
      const existingShapes = Array.from(editor.getCurrentPageShapeIds());
      editor.deleteAssets(existingAssets);
      editor.deleteShapes(existingShapes);
      store.store.mergeRemoteChanges(() => {
        store.store.remove(existingShapes);
        store.store.remove(existingAssets);
      });

      editor.createAssets(
        Object.values(selectedRevision.shapes.store).filter(
          (shape) =>
            (shape as TLAsset).type == "image" &&
            (shape as TLAsset).typeName == "asset",
        ) as TLAsset[],
      );

      editor.createShapes(
        Object.values(selectedRevision.shapes.store).filter(
          (shape) =>
            (shape as TLShape).type == "image" &&
            (shape as TLShape).typeName == "shape",
        ) as TLShape[],
      );

      editor.run(
        () => {
          editor.sendToBack(
            Object.values(selectedRevision.shapes.store).filter(
              (shape) =>
                (shape as TLShape).type == "image" &&
                (shape as TLShape).typeName == "shape",
            ) as TLShape[],
          );
        },
        {
          ignoreShapeLock: true,
        },
      );
      injectCustomCursorStyles();
    }

    // filter all comments for the current board
    const commentsForSelectedRevision = comments.filter((comment) => {
      if (
        !showDismissedComments &&
        comment.disposition == TicketCommentDisposition.DISMISSED
      ) {
        return false;
      }

      if (
        !showResolvedComments &&
        comment.disposition == TicketCommentDisposition.RESOLVED
      ) {
        return false;
      }
      if (
        user?.role != "ai" &&
        user?.role != "meaningful-gigs" &&
        comment.disposition == TicketCommentDisposition.DISMISSED
      ) {
        return false;
      }
      return (
        comment.boardId === selectedRevision._id &&
        comment.x != undefined &&
        comment.y != undefined
      );
    });

    // assign a shapeId if the comment doesn't have one.
    const commentsWithShapeIds = commentsForSelectedRevision.map((comment) => {
      // frontend
      if (comment.shapeId != null) {
        return comment;
      }

      const shapeId = createShapeId();
      const tempComment = { ...comment };
      // frontend
      tempComment.shapeId = shapeId;

      return tempComment;
    });

    const commentsOnBoard = editor
      .getCurrentPageShapes()
      .filter((shape) => shape.type == "comment-avatar")
      // @ts-expect-error TS2339: TLDraw incorrectly used the `object`
      .map((s) => s.meta.comment && JSON.parse(s.meta.comment)._id);
    const commentsToUpdate = editor
      .getCurrentPageShapes()
      .filter(
        (shape) => shape.type == "comment-avatar" || shape.type == "comment",
      )
      .map((s) => ({
        ...s,
        meta: {
          ...s.meta,
          comment: JSON.stringify(
            // @ts-expect-error TS2345: there's no reason the API will ever give
            comments.find((c) => c._id == s.props.commentId),
          ),
        },
      }));
    const newComments = commentsWithShapeIds.filter(
      // @ts-expect-error TS2345: there's no reason the API will ever give
      (c) => !commentsOnBoard.includes(c._id),
    );

    // creae the shapes on the board
    editor.run(() => {
      editor.createShapes(
        newComments.flatMap((comment) => {
          const avatarShapeId = createShapeId();
          const commentShapeId = comment.shapeId as TLShapeId;
          return [
            {
              id: avatarShapeId,
              type: "comment-avatar",
              x: comment.x,
              y: comment.y,
              props: {
                h: 25,
                w: 25,
              },
              meta: {
                linkedCommentId: commentShapeId,
                comment: JSON.stringify(comment),
              },
            },
            {
              id: commentShapeId,
              type: "comment",
              x: comment.x,
              y: (comment.y ?? 0) + 25 / editor.getZoomLevel(),
              meta: {
                comment: JSON.stringify(comment),
                linkedAvatarId: avatarShapeId,
              },
              props: {
                h: 0,
                w: 0,
                commentId: comment._id,
              },
            },
          ];
        }),
      );
      editor.updateShapes(commentsToUpdate);
    });
  }, [
    selectedRevision,
    store.store,
    editor,
    comments.length,
    showResolvedComments,
    showDismissedComments,
  ]);

  useEffect(() => {
    if (selectedRevision == null) {
      return;
    }

    const shouldTriggerRefetch =
      selectedRevision.isPending || !selectedRevision.isAIReady;

    if (!shouldTriggerRefetch) {
      return;
    }

    const INTERVAL_DURATION = 10_000;

    const timer = setInterval(() => {
      refreshTicket().then(({ data }) => {
        if (!data) {
          return;
        }

        const rev = data.revisionBoards[reversedIndex];
        setSelectedRevision(rev);
      });
    }, INTERVAL_DURATION);

    return () => {
      clearInterval(timer);
    };
  }, [selectedRevision]);

  const hasPermission = ticket && canMarketingAccess(ticket);
  const isReviewer =
    (user?.userID &&
      ticket?.reviewers?.some(
        (r) => (typeof r === "string" ? r : r._id) === user?.userID,
      )) ||
    user?.role === EnterpriseProfileType.ADMIN ||
    user?.role === EnterpriseProfileType.MEANINGFUL_GIGS;
  const canApprove = ticket && canApproveTicket(ticket as unknown as Ticket); // Work around mismatch between Ticket and GetTicketResponse dates

  function handleAsset(name: string, assets: Asset[]) {
    setAssetsUploading(false);

    return setNewRevisionAssets((prev) => ({
      ...prev,
      [name]: assets,
    }));
  }

  const renderNextStepAction = useCallback(() => {
    if (!hasPermission || ticket == null || ticket.workflowStep > tab) {
      return null;
    }
    function toggleCompletionStatus() {
      if (ticket == null) {
        return null;
      }

      const currentStatus = ticket.status;

      let newStatus = TicketStatus.COMPLETE;

      if (currentStatus !== TicketStatus.COMPLETE) {
        newStatus = TicketStatus.COMPLETE;
      } else {
        newStatus = TicketStatus.APPROVED;
      }
      const startTime = performance.now();
      ticketMutation
        .mutateAsync({ _id: ticket._id as string, status: newStatus })
        .then((response) => {
          posthog.capture("ticket_status_changed", {
            startingStatus: currentStatus,
            status: response.status,
            mutationDuration: performance.now() - startTime,
          });
          if (response.status === "complete") {
            navigate({ to: ticketsRoute.to });
          }
          dispatch(setTicket(response));
        });
    }

    const approvals =
      ticket.revisionBoards?.filter((board) => board.approval) ?? [];
    const designReviewConfig = ticket.workflow.steps.find(
      (step) => step.type === TicketWorkflowStep.DESIGN_REVIEW,
    )?.config as DesignReviewConfig | null | undefined;
    const meetsApprovalCountRequirement =
      designReviewConfig?.requiresApproval?.requiredReviewersForApproval ==
        null ||
      approvals.some(
        (board) =>
          board.reviews.filter((r) => r.approval).length >=
          (designReviewConfig?.requiresApproval?.requiredReviewersForApproval ??
            0),
      );

    const hasNextStep = ticket.workflow.steps.length > ticket.workflowStep + 1;
    const hasCompletionPermission = canSetTicketStatus(ticket);
    const isComplete = ticket.status === "complete";

    if (meetsApprovalCountRequirement && !hasNextStep) {
      if (!hasCompletionPermission) {
        return null;
      }
      return (
        <Button
          onClick={() => toggleCompletionStatus()}
          loading={ticketMutation.isPending}
          disabled={ticketMutation.isPending}
          size="1"
        >
          Mark As {isComplete ? "Incomplete" : "Complete"}
        </Button>
      );
    }

    if (canApprove && hasNextStep) {
      return (
        <Button
          size="1"
          onClick={() => {
            approveTicketMutation.mutateAsync(
              { ticketId: ticket._id as string },
              {
                onSuccess: (data) => {
                  posthog.capture("Approved Ticket", {
                    durationSincePageLoad:
                      performance.now() - (posthog.startTimeRef?.current ?? 0),
                  });
                  dispatch(setTicket(data));
                  setTab(tab + 1);
                },
              },
            );
          }}
        >
          Next: Delivery
        </Button>
      );
    }

    return null;
  }, [
    hasPermission,
    ticket,
    canApprove,
    posthog,
    dispatch,
    setTab,
    tab,
    approveTicketMutation,
    ticketMutation,
    navigate,
  ]);

  const missingFonts = useMemo(() => {
    if (!selectedRevision?.reviewFiles) return [];

    return selectedRevision.reviewFiles.reduce((fonts: string[], file) => {
      if (file.missingFonts?.length) {
        fonts.push(...file.missingFonts);
      }
      return fonts;
    }, []);
  }, [selectedRevision?.reviewFiles]);

  return (
    <>
      <ApproveRevisionDialog
        open={approveModalOpen}
        onOpenChange={setApproveModalOpen}
        isReviewer={isReviewer}
      />

      <Dialog.Root
        open={revisionDialogOpen}
        onOpenChange={setRevisionDialogOpen}
      >
        <Dialog.Content>
          <Dialog.Title>Add Design Revision</Dialog.Title>
          <VisuallyHidden>
            <Dialog.Description>
              Upload a new version of your design for review.
            </Dialog.Description>
          </VisuallyHidden>

          <form
            onSubmit={(e) => {
              e.preventDefault();

              const form = e.target;
              const formData = new FormData(form as HTMLFormElement);
              formData.delete("reviewFiles");

              createRevisionMutation
                .mutateAsync({
                  ticketId: ticketId,
                  payload: {
                    ...newRevisionAssets,
                  },
                })
                .then(() => {
                  setRevisionDialogOpen(false);
                  setSelectedRevision(undefined);
                  refreshTicket().then(({ data }) => {
                    if (data != null) {
                      if (!data.revisionBoards[0].isPending) {
                        setShouldCreateBoard(true);
                      }
                      setSelectedRevision(data.revisionBoards[0]);

                      setActiveRevisionIndex(-1, false);

                      posthog.capture("created_revision", {
                        boardId: data.revisionBoards[0]._id,
                      });
                    }
                  });
                });
            }}
            className="grid gap-4"
          >
            <FileUploadInput
              label="Files for Review"
              required
              onChange={(assets) => handleAsset("reviewFiles", assets)}
              onUploadStateChange={setAssetsUploading}
            />

            <div className="flex items-center justify-end gap-4">
              <Dialog.Close>
                <Button variant="soft">Cancel</Button>
              </Dialog.Close>
              <Button
                type="submit"
                disabled={createRevisionMutation.isPending || assetsUploading}
                loading={createRevisionMutation.isPending || assetsUploading}
              >
                Upload
              </Button>
            </div>
          </form>
        </Dialog.Content>
      </Dialog.Root>

      <header
        className="flex items-center gap-2 text-base-black"
        data-auth-trigger="ticket-header"
      >
        <Select.Root
          size="1"
          value={revisionScreenshots
            .findIndex((rev) => rev._id === selectedRevision?._id)
            .toString()}
          onValueChange={(value) => setActiveRevisionIndex(+value)}
        >
          <Select.Trigger
            className={cx({
              hidden: !revisions.length,
            })}
            variant="surface"
            color="gray"
          />
          <Select.Content>
            {revisionScreenshots.map((revision, i) => (
              <Select.Item key={revision._id} value={i.toString()}>
                V{revisionScreenshots.length - i}
              </Select.Item>
            ))}
          </Select.Content>
        </Select.Root>
        <Text
          className={cx({
            hidden: !revisions.length,
          })}
        >
          of {revisions?.length}
        </Text>
        {missingFonts.length > 0 && (
          <Tooltip
            content={
              <>
                {/* prettier-ignore */}
                <Text>
                  Warning: Some fonts used in this document are missing ({new Intl.ListFormat("en", {
                    style: "long",
                    type: "conjunction",
                  }).format(missingFonts.sort((a, b) => a.localeCompare(b)))}).
                  The document may not appear exactly as intended. Contact
                  Meaningful Gigs to resolve this.
                </Text>
              </>
            }
          >
            <Icon.Warning color="darkorange" className="cursor-pointer" />
          </Tooltip>
        )}
        {renderNextStepAction()}
        <Button
          className={cx({
            hidden: !canCreateRevisions(),
          })}
          size="1"
          onClick={() => setRevisionDialogOpen(true)}
        >
          Upload New Version <UploadSimple />
        </Button>

        <ShareTicketDialog />
      </header>

      <CommentDrawer
        editor={editor}
        setActiveRevisionIndex={setActiveRevisionIndex}
        videoRef={videoRef}
        isLoading={
          !!(
            createRevisionMutation.isPending ||
            isLoading ||
            selectedRevision?.isPending
          )
        }
      />

      <div
        className="fixed inset-0"
        style={{
          top: headerHeight,
          right: 320,
          left: isDocMode
            ? 148 // 148 is the fixed (by design) width of the pdf navigator
            : 0,
        }}
      >
        <Flex
          position="absolute"
          top="0"
          width="100%"
          direction="column"
          className={cx("z-10 whitespace-normal bg-puntt-neutral-gray-3", {
            hidden: ["loading", "synced-remote"].includes(store.status),
          })}
          gap="2"
        >
          <Flex
            p="2"
            className={cx({
              hidden: ["loading", "synced-remote"].includes(store.status),
            })}
          >
            <Text color="red">
              Real-time syncing disconnected. Your changes will still save
              automatically, but you may not see other people&apos;s cursors.
            </Text>
          </Flex>
        </Flex>

        {hasPermission ? (
          <>
            <div
              className={cx(
                "absolute left-1/2 top-1/2 z-[200] -translate-x-1/2 -translate-y-1/2",
                {
                  hidden: revisions.length > 0,
                },
              )}
            >
              <Typography size="6xl" className="text-carbon-400">
                Upload your first
                <br />
                design for review
              </Typography>
            </div>
            {(selectedRevision == null && revisions.length > 0) ||
            createRevisionMutation.isPending ||
            isLoading ||
            selectedRevision?.isPending ? (
              <PendingProgress
                isPending
                pendingText={
                  !selectedRevision?.isPending
                    ? "Loading this into view"
                    : undefined
                }
              />
            ) : isVideoMode ? (
              <VideoMode
                videoUrl={selectedRevision?.reviewFiles?.[0]?.source as string}
                boardType={TicketCommentBoard.REVISION}
                boardId={selectedRevision?._id as string}
                revisionIsPending={selectedRevision?.isPending}
                userIsReviewing={comments.some(
                  (c) => c.isPending && c.createdBy === user?.userID,
                )}
                ticketId={ticketId}
                ref={videoRef}
              />
            ) : (
              <Tldraw
                initialState="combo"
                assetUrls={assetUrls}
                store={store}
                key={selectedRevision?._id}
                // @ts-expect-error TS2322: This prop is needed, but the type doesn't know it exists
                persistenceKey={selectedRevision?._id}
                tools={customTools}
                maxAssetSize={100 * 1024 * 1024}
                components={{
                  Grid: Checkerboard,
                  Spinner: null,
                  ContextMenu: null,
                }}
                hideUi
                acceptedImageMimeTypes={[
                  "image/jpeg",
                  "image/png",
                  "image/gif",
                  "image/webp",
                  "image/svg+xml",
                  "application/illustrator",
                  "application/eps",
                  "application/postscript",
                  "application/msword",
                  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                  "application/pdf",
                  "application/zip",
                  "application/*",
                  "image/*",
                ]}
                shapeUtils={customShapeUtils}
                onMount={(editor) => {
                  setEditor(editor);

                  editor.registerExternalAssetHandler(
                    "file",
                    externalAssetHandler(editor) as Parameters<
                      typeof editor.registerExternalAssetHandler
                    >[0], // This can return null or undefined, which doesn't cause errors, but the tldraw type doesn't allow that
                  );

                  if (
                    selectedRevision &&
                    !selectedRevision.isPending &&
                    isDocMode
                  ) {
                    const loadDocMode = () => {
                      let targetBounds: Box;
                      if (selectedRevision.shapes?.store) {
                        const filteredImageShapes = getFilteredImageShapes(
                          selectedRevision.shapes.store,
                        );
                        targetBounds = getShapesBounds(filteredImageShapes);
                        updateCameraBounds(targetBounds, editor);
                      }
                      editor.updateInstanceState({ isGridMode: false });
                    };

                    loadDocMode();
                    return;
                  }
                  editor.zoomToFit();
                  editor.updateInstanceState({ isGridMode: true });
                }}
              >
                <Toolbar isDocMode={isDocMode} />
              </Tldraw>
            )}

            {!createRevisionMutation.isPending &&
            isDocMode &&
            selectedRevision &&
            editor != null ? (
              <DocViewerPageNavigator
                selectedRevision={selectedRevision}
                editor={editor}
              />
            ) : (
              <aside
                className={cx(
                  "fixed left-2 top-1/2 z-[200] grid max-h-[380px] -translate-y-1/2 gap-4 overflow-auto rounded-lg bg-base-white p-1 shadow-lg",
                  { hidden: isVideoMode },
                )}
              >
                <Button
                  size="1"
                  variant="outline"
                  onClick={() => setRevisionDialogOpen(true)}
                  className={cx({
                    hidden: !(
                      canCreateRevisions() &&
                      (user?.role === EnterpriseProfileType.CATALYST_AI ||
                        user?.role === EnterpriseProfileType.MEANINGFUL_GIGS)
                    ),
                  })}
                >
                  <Upload />
                  Add Revision
                </Button>

                {editor != null &&
                  revisionScreenshots.map((revision, i) => (
                    <figure
                      key={revision._id}
                      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                      role="button"
                      tabIndex={-1}
                      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                      onClick={() => {
                        setActiveRevisionIndex(i);
                        posthog.capture("toggled_revisions", {
                          boardId: revision._id,
                        });
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          setActiveRevisionIndex(i);
                          posthog.capture("toggled_revisions", {
                            boardId: revision._id,
                          });
                        }
                      }}
                      className="relative grid w-32 gap-1"
                    >
                      <div
                        className={cx(
                          "h-16 rounded-xl border bg-contain bg-center bg-no-repeat",
                          {
                            "border-egyptian-blue-400": i === reversedIndex,
                            "border-transparent hover:border-egyptian-blue-200":
                              i !== reversedIndex,
                          },
                        )}
                        style={{
                          backgroundImage: `url(${revision.screenshotUrl})`,
                          backgroundColor: "rgb(var(--carbon-50))",
                        }}
                      />

                      {typeof revision.createdBy !== "string" && (
                        <AvatarWithInitials
                          avatar={revision.createdBy.avatar}
                          name={revision.createdBy.name}
                          size={8}
                          className="!absolute right-1 top-7"
                        />
                      )}

                      <figcaption className="flex items-center justify-between truncate">
                        <Typography
                          size="sm"
                          className="truncate text-carbon-600"
                        >
                          V{revisionScreenshots.length - i}
                        </Typography>
                        <Typography size="xs" className="text-carbon-300">
                          {new Intl.DateTimeFormat("en-US", {
                            month: "numeric",
                            day: "numeric",
                            year: "2-digit",
                          }).format(new Date(revision.createdAt))}
                        </Typography>
                      </figcaption>
                    </figure>
                  ))}
              </aside>
            )}
          </>
        ) : (
          <NoPermissionsView />
        )}
      </div>
    </>
  );
}

export const Toolbar = track(({ isDocMode }: { isDocMode?: boolean }) => {
  const editor = useEditor();
  const tools = useTools();
  const currentTool = editor.getCurrentToolId();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const toolClasses = cx(
    "bg-transparent rounded-xl text-center [pointer-events:all] h-12 aspect-square flex flex-col justify-center items-center",
    "data-[isactive=true]:bg-egyptian-blue-200",
  );
  const toolLabelClasses = cx("text-[10px] leading-4");

  useEffect(() => {
    const handleKeyUp = (e: KeyboardEvent) => {
      switch (e.key) {
        case "Delete":
        case "Backspace": {
          const selectedShapes = editor
            .getSelectedShapes()
            .map((shape) => {
              if (
                shape.type !== "comment" &&
                shape.type !== "comment-avatar" &&
                editor.getEditingShapeId() !== shape.id
              ) {
                return shape.id;
              }
            })
            .filter((s): s is TLShapeId => Boolean(s));

          editor.deleteShapes(selectedShapes);
          break;
        }
      }
    };

    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [editor]);

  return (
    <Icon.IconContext.Provider value={{ size: 20, className: "mx-auto" }}>
      <div className="pointer-events-none absolute inset-0 z-[300]">
        <section className="absolute bottom-4 left-1/2 flex -translate-x-1/2 items-center gap-2 rounded-2xl border bg-base-white p-2">
          <DefaultZoomMenu>
            <DefaultZoomMenuContent />
          </DefaultZoomMenu>

          <button
            className={toolClasses}
            data-isactive={currentTool === "combo"}
            onClick={() => editor.setCurrentTool("combo")}
          >
            <ComboLogo className="hidden xs:block" />
            <Typography className={toolLabelClasses}>Comment</Typography>
          </button>
          <AuthTooltip>
            <Popover className="relative" data-auth-trigger="toolbar-extended">
              <Popover.Button className={toolClasses}>
                <Icon.CaretUp />
              </Popover.Button>

              <Popover.Panel className="absolute bottom-[calc(100%_+_1.2rem)] left-1/2 z-10 flex -translate-x-1/2 gap-2 rounded-2xl border bg-base-white p-2">
                {/* Arrow */}
                <div className="absolute -bottom-2 left-1/2 size-0 -translate-x-1/2 border-x-8 border-t-8 border-x-transparent border-t-base-white drop-shadow-sm" />
                <button
                  className={toolClasses}
                  data-isactive={currentTool === "select"}
                  onClick={() => editor.setCurrentTool("select")}
                >
                  <Icon.Cursor />
                  <Typography className={toolLabelClasses}>Select</Typography>
                </button>
                <button
                  className={toolClasses}
                  data-isactive={currentTool === "text"}
                  onClick={() => editor.setCurrentTool("text")}
                >
                  <Icon.CursorText />
                  <Typography className={toolLabelClasses}>Text</Typography>
                </button>
                {!isDocMode && (
                  <>
                    <input
                      type="file"
                      className="hidden"
                      multiple
                      ref={fileInputRef}
                      onChange={async (
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        const files = event.target.files;
                        if (files && files.length > 0) {
                          const handleImage = externalAssetHandler(editor);
                          Promise.all(
                            Array.from(files).map((file) =>
                              handleImage({ type: "file", file }),
                            ),
                          ).then((assets) => {
                            const filteredAssets = assets.filter(
                              (asset) => asset != null,
                            ) as (TLImageAsset | TLVideoAsset)[]; // Can't be a TLBookmarkAsset

                            editor.createAssets(filteredAssets);
                            filteredAssets.forEach((asset) => {
                              const shapeId = createShapeId();

                              editor.createShape({
                                id: shapeId,
                                type: "image",
                                props: {
                                  assetId: asset?.id,
                                  h: asset.props.h,
                                  w: asset.props.w,
                                },
                              });
                            });
                            editor.selectAll();
                            const selectedShapeIds =
                              editor.getSelectedShapeIds();

                            editor.packShapes(selectedShapeIds, 70);
                            editor.zoomToFit();
                            editor.selectNone();
                          });
                        }
                      }}
                    />
                    <button
                      className={toolClasses}
                      data-isactive={currentTool === "file"}
                      onClick={() => {
                        fileInputRef.current?.click();
                      }}
                    >
                      <Icon.File />
                      <Typography className={toolLabelClasses}>File</Typography>
                    </button>
                  </>
                )}
                <button
                  className={toolClasses}
                  data-isactive={currentTool === "circle"}
                  onClick={() => tools.ellipse.onSelect("toolbar")}
                >
                  <Icon.Circle />
                  <Typography className={toolLabelClasses}>Oval</Typography>
                </button>
                <button
                  className={toolClasses}
                  data-isactive={currentTool === "rectangle"}
                  onClick={() => tools.rectangle.onSelect("toolbar")}
                >
                  <Icon.Square />
                  <Typography className={toolLabelClasses}>
                    Rectangle
                  </Typography>
                </button>
                <button
                  className={toolClasses}
                  data-isactive={currentTool === "line"}
                  onClick={() => editor.setCurrentTool("line")}
                >
                  <Icon.LineSegment />
                  <Typography className={toolLabelClasses}>Line</Typography>
                </button>

                <button
                  className={toolClasses}
                  data-isactive={currentTool === "arrow"}
                  onClick={() => editor.setCurrentTool("arrow")}
                >
                  <Icon.ArrowUpRight />
                  <Typography className={toolLabelClasses}>Arrow</Typography>
                </button>
                <button
                  className={toolClasses}
                  data-isactive={currentTool === "draw"}
                  onClick={() => editor.setCurrentTool("draw")}
                >
                  <Icon.PencilLine />
                  <Typography className={toolLabelClasses}>Pencil</Typography>
                </button>
              </Popover.Panel>
            </Popover>
          </AuthTooltip>
        </section>
      </div>
    </Icon.IconContext.Provider>
  );
});

type FileUploadInputProps = {
  label: string;
  required?: boolean;
  onChange(assets: Asset[]): void;
  onUploadStateChange: Dispatch<SetStateAction<boolean>>;
};

function FileUploadInput(props: FileUploadInputProps) {
  const { onChange, onUploadStateChange, ...rest } = props;

  async function handleUpload(files?: FileList | null) {
    if (files != null && files.length > 0) {
      onUploadStateChange(true); // sets uploading indicator to disable submit CTA

      const payloads = (await Promise.all(
        Array.from(files).map((file) => uploadImageAsset(file)),
      )) as FormData[];

      const assets = await Promise.all(
        payloads.map(async (payload, i) => {
          const url = payload?.get("url") as string;
          const key = payload?.get("key") as string;
          payload.delete("url");

          await uploadToS3({ url, payload });

          return {
            source: `https://static.puntt.ai/${key}`,
            // @ts-expect-error TS1355: we know the type
            type: (files[i].type.includes("image")
              ? "image"
              : files[i].type.includes("video")
                ? "video"
                : "file") as const,
          };
        }),
      );

      return assets;
    }

    return [];
  }

  return (
    <Input
      type="file"
      size="sm"
      multiple
      {...rest}
      onChange={async ({ target }) => {
        const files = target.files;

        const assets = await handleUpload(files);

        return onChange(assets);
      }}
    />
  );
}

export const DocViewerPageNavigator = ({
  selectedRevision,
  editor,
}: {
  selectedRevision: BaseRevisionBoard;
  editor: Editor;
}) => {
  const [selectedPage, setSelectedPage] = useState(0);
  const { headerHeight } = useTicket();
  const currentPageShapes = editor.getCurrentPageShapes();
  const posthog = useAnalytics("Revisions");
  const selectedThumbnailRef = useRef<HTMLDivElement>(null);

  const filteredAndSortedImageShapes = useMemo(() => {
    const shapes = getFilteredImageShapes(currentPageShapes);
    return filterAndSortShapes(shapes);
  }, [currentPageShapes]);

  const checkVisibility = useCallback(
    (currentPagePoint: Vec) => {
      const result = editor.getShapesAtPoint(currentPagePoint);

      if (!result?.[0]) return;
      const resultIndex = currentPageShapes.findIndex(
        (shape) => shape.id === result[0]?.id,
      );
      setSelectedPage(resultIndex);
    },
    [currentPageShapes, editor],
  );

  const debouncedCheckVisibility = useCallback(debounce(checkVisibility, 50), [
    checkVisibility,
  ]);

  useEffect(() => {
    const handleEditorEvent = () => {
      const { currentPagePoint } = editor.inputs;
      debouncedCheckVisibility(currentPagePoint);
    };

    // Add the event listener
    editor.addListener("event", handleEditorEvent);

    // Clean up the event listener when the component unmounts
    return () => {
      editor.removeListener("event", handleEditorEvent);
    };
  }, [editor, debouncedCheckVisibility]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (isKeyboardEventInInputField(event)) return;

      if (event.key === "ArrowDown") {
        event.preventDefault();
        if (selectedPage < filteredAndSortedImageShapes.length - 1) {
          editor.setCamera({
            x: +filteredAndSortedImageShapes[selectedPage + 1].x,
            y: -filteredAndSortedImageShapes[selectedPage + 1].y + 5, // 5 is just for a little extra padding between the top
            z: editor.getCamera().z, // the zoom
          });
          setSelectedPage((prevPage) => prevPage + 1);
        }
      } else if (event.key === "ArrowUp") {
        event.preventDefault();
        if (selectedPage > 0) {
          editor.setCamera({
            x: +filteredAndSortedImageShapes[selectedPage - 1].x,
            y: -filteredAndSortedImageShapes[selectedPage - 1].y + 5, // 5 is just for a little extra padding between the top
            z: editor.getCamera().z, // the zoom
          });
          setSelectedPage((prevPage) => prevPage - 1);
        }
      }
    },
    [selectedPage, filteredAndSortedImageShapes, editor],
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    selectedThumbnailRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  }, [selectedPage]);

  return (
    <aside
      className="fixed left-0 z-[200] w-[148px] overflow-y-scroll bg-puntt-neutral-gray-9 p-1 pr-3"
      style={{ top: headerHeight, height: `calc(100% - ${headerHeight}px)` }}
    >
      {selectedRevision.thumbnails.map((url, i) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
          key={url}
          ref={i === selectedPage ? selectedThumbnailRef : null}
          className={cx("mb-2 cursor-pointer p-2", {
            "rounded-lg bg-puntt-cool-gray-8 shadow-lg": i === selectedPage,
          })}
          onClick={() => {
            const shape = filteredAndSortedImageShapes[i]; // the index should match the page number

            if (shape) {
              editor.setCamera({
                x: +shape.x,
                y: -shape.y + 5, // 5 is just for a little extra padding between the top
                z: editor.getCamera().z, // the zoom
              });
              posthog.capture("clicked_thumbnail", {
                page: i + 1,
              });
            }

            setSelectedPage(i);
          }}
        >
          <img
            src={url}
            alt={`Thumbnail of page ${i + 1}`}
            className="rounded-lg"
          />
          <Text size="1" weight="medium">
            Page {`${i + 1}`}
          </Text>
        </div>
      ))}
    </aside>
  );
};
