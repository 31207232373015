import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

import { useUI } from "../../contexts/ui";
import { getMessageableList, sendInvitations } from "../../services/network";
import { useAppDispatch } from "../hooks";
import { setMessageableList } from "../slices/network";

export const useMessageablePeople = () => {
  const dispatch = useAppDispatch();
  const query = useQuery({
    queryKey: ["messageable-people"],
    queryFn: getMessageableList,
  });

  useEffect(() => {
    if (query.data != null) {
      dispatch(setMessageableList(query.data));
    }
  }, [query.data, dispatch]);

  return query;
};

export function useSendInvite() {
  const { notify } = useUI();
  const mutation = useMutation({
    mutationKey: ["send-invite"],
    mutationFn: sendInvitations,
    onSuccess() {
      notify({
        variant: "success",
        title: "Invitation sent",
      });
    },
  });

  return mutation;
}
