import { batch } from "./batch";

import {
  screenshotURLFromPayload,
  uploadImageAsset,
  uploadToS3,
  urlAndPayloadFromPayload,
} from "../services/upload";
import { type FigmaImageData } from "../utils/figma-plugin-types";

const MAX_CONCURRENCY = 6;

/**
 * Uploads the images to S3 and returns the URLs of the uploaded images.
 */
export const uploadImagesToS3 = async (
  images: FigmaImageData[],
  onProgress?: () => void,
): Promise<string[]> => {
  const batchUpload = async (
    images: FigmaImageData[],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    batchIndex: number,
  ): Promise<string[]> => {
    return Promise.all(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      images.map(async (image, index) => {
        if (!image.data || !image.mimeType || image.mimeType !== "image/png") {
          throw new Error("Image data or mimeType missing");
        }
        const blob = new Blob([new Uint8Array(image.data)], {
          type: image.mimeType,
        });
        const filename = `figmaplugin-${Date.now()}.png`; // assume PNG for now
        const file = new File([blob], filename, { type: image.mimeType });
        const payload = await uploadImageAsset(file);
        if (!payload || !payload.get("url")) {
          throw new Error("Error uploading image to S3 - no URL in payload");
        }
        const uploadParams = urlAndPayloadFromPayload(payload);

        // Use the uploadToS3 function to upload the image to S3
        await uploadToS3(uploadParams);
        if (onProgress) {
          onProgress();
        }
        return screenshotURLFromPayload(payload);
      }),
    );
  };
  return batch(images, 1, batchUpload, MAX_CONCURRENCY);
};

// Parse ticketId as the part of path after /tickets/ and without any query params
// Example: https://app-dev.puntt.ai/tickets/666738f11a5c541856554f24?revIndex=0&tab=1
// TicketId = 666738f11a5c541856554f24
export const parseTicketIdFromUrl = (url?: string): string | undefined => {
  if (!url) {
    return undefined;
  }
  try {
    const { pathname } = new URL(url);
    const segments = pathname.split("/");

    // Ensure that the segments array has at least three elements
    if (segments.length > 2 && segments[1] === "tickets") {
      return segments[2];
    }
  } catch (error) {
    console.error("Invalid URL:", error);
  }
  return undefined;
};

// Modifies a URL by setting query parameters so the link opens the
// Design Review page.
// Removes other query parameters if any
export const ticketUrlForFigmaChip = (
  url: string,
  revIndex: number,
  tab: number,
): string => {
  const newUrl = new URL(url);
  newUrl.searchParams.set("revIndex", String(revIndex));
  newUrl.searchParams.set("tab", String(tab));
  return newUrl.href;
};
