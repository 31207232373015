import { Icon } from "@mg/dali/src";
import { type BaseTicketComment } from "@mg/schemas/src/christo/catalyst";
import cx from "classnames";
import { useMemo } from "react";

import { AvatarWithInitials } from "../../../components/AvatarWithInitials";
import { useAppSelector } from "../../../utils/hooks";
import { assetForUser } from "../../../utils/imageHandler";

export function CommentPin({
  comment,
  duration,
  onClick,
}: {
  comment: BaseTicketComment;
  duration: number;
  onClick: (videoStart: number) => void;
}) {
  const leftPercentage =
    comment.videoStart == 0 ? 0.5 : (comment?.videoStart ?? 0 / duration) * 100; // adding 0.5 percent for the ones with 0 start so that it appears on the track
  const halfIconWidth = 16 / 2; // 16 is the width of the icon
  const { highlightedCommentId, activeCommentId } = useAppSelector(
    (state) => state.ticket,
  );

  const iconComponent = useMemo(() => {
    if (comment.isAI) {
      return (
        <Icon.Robot
          className="rounded-full bg-amber-amber10 text-base-black"
          size={16}
        />
      );
    } else {
      return (
        <AvatarWithInitials
          // @ts-expect-error TS2339: schemas type is wrong
          avatar={assetForUser(comment?.createdBy?.avatar)}
          // @ts-expect-error TS2339: schemas type is wrong
          name={comment?.createdBy?.name}
          size={4}
        />
      );
    }
  }, [comment]);

  return (
    <button
      tabIndex={0}
      onClick={() => comment.videoStart != null && onClick(comment.videoStart)}
      onKeyDown={(e) => {
        if (
          (e.key === "Enter" || e.key === " ") &&
          comment.videoStart != null
        ) {
          onClick(comment.videoStart);
        }
      }}
      className={cx(
        "duration-250 absolute flex size-5 transform items-center justify-center rounded-full transition-transform ease-in-out hover:scale-125",
        {
          "scale-125":
            highlightedCommentId === comment._id ||
            activeCommentId === comment._id,
        },
      )}
      style={{
        left: `calc(${leftPercentage}% - ${halfIconWidth}px)`,
        top: 6,
        backgroundColor: comment?.isRequired
          ? "rgb(var(--puntt-red-9))"
          : "rgb(var(--puntt-cool-gray-8))",
        border: "0.3px solid white",
        transformOrigin: "0% 100%",
      }}
    >
      {iconComponent}
    </button>
  );
}
