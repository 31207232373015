import { Combobox, type ComboboxProps, Transition } from "@headlessui/react";
import { cva, cx } from "class-variance-authority";
import React, {
  Fragment,
  useMemo,
  useState,
  type ReactNode,
  useRef,
} from "react";
import { extendTailwindMerge } from "tailwind-merge";

import { Chip } from "../chip";
import { Typography, Icon } from "../foundation";
import { type TypographyProps } from "../foundation/typography/Typography";

const twMerge = extendTailwindMerge({ prefix: "dali-" });

export type Option = {
  label: string;
  value: string | number;
};

export type SelectProps<TMultiple extends boolean | undefined> = {
  /**
   * A visual label for the dropdown
   */
  label?: string;
  /**
   * Optionally, change the size of the dropdown container; defaults to 'default'
   */
  size?: "default" | "md" | "lg";
  /**
   * Optionally, shows an adornment to the left of the input. An adornment is
   * usually a piece of text (such as '$'), or an icon
   */
  startAdornment?: ReactNode;
  /**
   * Optionally, sets helper text beneath the input
   */
  helpText?: ReactNode;
  /**
   * An array of options overrides the base Option type to allow number values
   */
  options: Option[];
  /**
   * Optionally, set value to invalid state
   */
  invalid?: boolean;
  /**
   * Optionally, set value to warning state
   */
  warning?: boolean;
  /**
   * Optionally, render a green border and default icon to represent that the
   * user's input is valid and the goal is to explicitly provide that
   * experience. This is different than if `invalid={false}`
   */
  success?: boolean;
  /**
   * Optionally, allow custom values
   */
  allowCustom?: boolean;
  /**
   * Optionally, render a placeholder when no value is selected
   */
  placeholder?: string;
  /**
   * Optionally, sort all options alphabetically.
   */
  sortOptions?: boolean;
  /**
   * Optionally, set the maximum number of selections. Useful for when limiting
   * results to prevent a Dialog from scrolling
   */
  max?: number;
  /**
   * Optionally, disable rendering of selected items in the dropdown in the
   * event they will be rendered elsewhere (such as below the input)
   */
  renderSelections?: boolean;
} & ComboboxProps<Option, false, TMultiple, "div">;

const comboboxContainerClasses = cva(
  ["dali-grid", "dali-gap-1", "dali-relative", "dali-auto-rows-max"],
  {
    variants: {
      disabled: {
        true: ["dali-cursor-not-allowed"],
        false: [],
      },
    },
  },
);

const comboboxControlClasses = cva(
  [
    "dali-flex",
    "dali-gap-2.5",
    "dali-relative",
    "dali-border-2",
    "dali-rounded-lg",
    "dali-border-carbon-300",
    "focus-within:dali-ring-2",
    "focus-within:dali-ring-offset-2",
    "focus-within:dali-ring-base-black",
    "dali-transition-all",
  ],
  {
    variants: {
      success: {
        true: ["dali-border-malachite-600"],
        false: [],
      },
      warning: {
        true: ["dali-border-ochre-400"],
        false: [],
      },
      invalid: {
        true: ["dali-border-cadmium-600"],
        false: [],
      },
      multiple: {
        true: ["[&>div]:dali-items-start"],
        false: ["[&>div]:dali-items-center"],
      },
      size: {
        default: ["dali-py-2", "dali-px-4"],
        md: ["dali-py-2.5", "dali-px-4"],
        lg: ["dali-py-3.5", "dali-px-6"],
      },
    },
    compoundVariants: [
      {
        multiple: true,
        size: "default",
        className: "[&>div>span]:dali-pt-1",
      },
      {
        multiple: true,
        size: "md",
        className: "[&>div>span]:dali-pt-1",
      },
    ],
  },
);

const inputClasses = cva(
  [
    "dali-outline-transparent",
    "dali-bg-transparent",
    "dali-font-national2",
    "dali-font-text-regular",
    "dali-max-w-auto",
    "dali-w-full",
    "placeholder:dali-text-carbon-600",
  ],
  {
    variants: {
      size: {
        default: ["dali-text-base"],
        md: ["dali-text-lg"],
        lg: ["dali-text-xl"],
      },
      disabled: {
        true: ["dali-cursor-not-allowed"],
        false: [],
      },
    },
  },
);

const buttonClasses = cva(["dali-absolute dali-right-0"], {
  variants: {
    size: {
      default: ["-dali-top-0.5", "dali-px-4", "dali-py-3.5"],
      md: ["-dali-top-0.5", "dali-px-4", "dali-py-3.5"],
      lg: ["-dali-top-0.5", "dali-px-6", "dali-py-4"],
    },
  },
});

export function Select<TMultiple extends boolean | undefined>(
  props: SelectProps<TMultiple>,
) {
  const {
    helpText,
    size = "default",
    label,
    options,
    value = undefined,
    defaultValue = undefined,
    invalid = false,
    success = false,
    warning = false,
    startAdornment = null,
    allowCustom = false,
    placeholder,
    sortOptions = false,
    max = null,
    renderSelections = true,
    ...rest
  } = props;
  const { className: hash, children, onChange, ...pass } = rest;
  const [query, setQuery] = useState(
    value != null && !Array.isArray(value) && value.label.length > 0
      ? value.label
      : "",
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const internalOptions = useMemo(() => {
    const result = [...options];

    if (sortOptions) {
      result.sort((a, b) => a.label.localeCompare(b.label));
    }

    return result;
  }, [options, sortOptions]);

  const containerClasses = cx(
    twMerge(comboboxContainerClasses({ disabled: pass.disabled })),
    hash,
  );
  const controlClasses = cx(
    twMerge(
      comboboxControlClasses({
        invalid,
        size,
        success,
        warning,
        multiple: pass.multiple,
      }),
    ),
  );

  const menuItemFontSize: Record<
    NonNullable<SelectProps<true>["size"]>,
    NonNullable<TypographyProps["size"]>
  > = {
    default: "base",
    md: "lg",
    lg: "xl",
  };
  const iconSizes: Record<NonNullable<SelectProps<true>["size"]>, number> = {
    default: 16,
    md: 18,
    lg: 24,
  };

  const filteredOptions =
    query === "" || internalOptions.find((o) => o.label === query) != null
      ? internalOptions
      : internalOptions.filter((o) =>
          o.label.toLowerCase().includes(query.toLowerCase()),
        );

  const changeAndClear = (val: Option | Option[]) => {
    if (onChange != null) {
      onChange(val as { label: string; value: string | number }[] & Option);
    }
    if (!pass.multiple) {
      setQuery(val.label ?? value?.label ?? "");
    } else {
      setQuery("");
    }
  };
  const existingCustom =
    value != null &&
    Array.isArray(value) &&
    value.find((v) => v.value === query);

  return (
    <Icon.IconContext.Provider
      value={{
        weight: "bold",
        size: iconSizes[size],
        className: "dali-flex-shrink-0",
        color: pass.disabled
          ? "rgb(var(--carbon-300))"
          : "rgb(var(--base-black))",
      }}
    >
      {/* @ts-expect-error TS2746: TS thinks multiple children are here, disregarding the destructure above */}
      <Combobox
        as="div"
        value={value}
        defaultValue={defaultValue}
        className={containerClasses}
        nullable
        by="value"
        data-testid={`${label}-dropdown` ?? "dropdown"}
        onChange={changeAndClear}
        {...pass}
      >
        {label != null && (
          <Combobox.Label className="dali-mb-1">
            <Typography size="base" weight="normal">
              {label}
            </Typography>
          </Combobox.Label>
        )}
        <div className={controlClasses}>
          <div className="dali-flex dali-gap-4 dali-flex-1">
            {startAdornment != null && <span>{startAdornment}</span>}
            <div className="dali-flex dali-gap-2 dali-flex-wrap dali-pr-8 dali-flex-1">
              {pass.multiple &&
              value != null &&
              Array.isArray(value) &&
              renderSelections
                ? value.map((v, i) => (
                    <Chip
                      key={v.label}
                      label={v.label}
                      variant="primary-outlined"
                      disabled={pass.disabled}
                      trailingIcon={
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            if (onChange == null || value.length === 0) {
                              return;
                            }

                            const res = [...value];
                            res.splice(i, 1);
                            // @ts-expect-error TS2345: onChange incorrectly represents
                            // the array type by using the intersection type
                            // Option[] & Option instead of the union type Option[]
                            // | Option

                            changeAndClear(res);
                          }}
                        >
                          <Icon.XCircle />
                        </button>
                      }
                    />
                  ))
                : null}
              <Combobox.Input
                className={inputClasses({ size, disabled: pass.disabled })}
                onChange={(event) => {
                  setQuery(event.target.value);
                }}
                displayValue={(value: Option) =>
                  value != null ? value.label : ""
                }
                placeholder={
                  value != null &&
                  Array.isArray(value) &&
                  value.length > 0 &&
                  renderSelections
                    ? undefined
                    : placeholder
                }
                ref={inputRef}
                onClick={() => {
                  buttonRef?.current?.click();
                }}
                value={query}
              />
            </div>
          </div>
          <Combobox.Button
            className={twMerge(buttonClasses({ size }))}
            ref={buttonRef}
          >
            {({ open }) => (
              <Icon.CaretDown
                className={cx("dali-transition-transform", {
                  "dali-cursor-not-allowed": pass.disabled,
                  "dali-rotate-180": open,
                })}
              />
            )}
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="dali-transition dali-ease-in dali-duration-100"
          leaveFrom="dali-opacity-100"
          leaveTo="dali-opacity-0"
          afterLeave={() => pass.multiple && setQuery("")}
        >
          <Combobox.Options
            className={cx([
              "dali-border-2 dali-border-carbon-300 dali-absolute",
              "dali-top-full dali-max-h-60 dali-w-full dali-py-0.5",
              "dali-overflow-auto dali-rounded-lg dali-bg-base-white",
              "dali-z-10",
            ])}
            data-testid={`${label ?? "dropdown"}-option`}
          >
            {allowCustom && query.length > 0 && (
              <Combobox.Option
                value={{ label: query, value: query }}
                className={({ active }) =>
                  cx([
                    "dali-py-1 dali-px-4 dali-flex dali-gap-4",
                    "dali-items-center dali-cursor-pointer",
                    { "dali-bg-base-black dali-text-base-white": active },
                  ])
                }
              >
                <Icon.Check className="dali-opacity-0" />
                <Typography
                  size={menuItemFontSize[size]}
                  className="dali-italic"
                  data-testid={`${label ?? "dropdown"}-option-${query}`}
                >
                  {existingCustom ? "Remove" : "Create"} &ldquo;{query}&rdquo;
                </Typography>
              </Combobox.Option>
            )}
            {filteredOptions.map((o) => (
              <Combobox.Option
                // a more dynamic `key` prop will cause the menu to scroll to the
                // top after each selection is made. Caveat: it's possible for
                // duplicate labels to be present, which may have unintended
                // consequences.
                key={o.value}
                onClick={() => {
                  if (!props.multiple) {
                    setTimeout(() => {
                      inputRef.current?.blur();
                    }, 0);
                  }
                }}
                value={o}
                className={({ active, disabled }) =>
                  cx([
                    "dali-py-1 dali-px-4 dali-flex dali-gap-4",
                    "dali-items-center dali-cursor-pointer dali-truncate",
                    {
                      "dali-bg-base-black dali-text-base-white": active,
                      "dali-text-carbon-300": disabled && !active,
                    },
                  ])
                }
                disabled={
                  Array.isArray(value) &&
                  max != null &&
                  value.length === max &&
                  !value.find((v) => v.value === o.value)
                }
              >
                {({ selected, active }) => (
                  <>
                    <Icon.Check
                      className={cx({ "dali-opacity-0": !selected })}
                      color={
                        active && selected
                          ? "rgb(var(--base-white))"
                          : undefined
                      }
                    />
                    <Typography
                      size={menuItemFontSize[size]}
                      className="dali-flex-1 dali-truncate"
                      data-testid={`${label ?? "dropdown"}-option-${o.label}`}
                    >
                      {o.label}
                    </Typography>
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </Transition>

        {helpText != null && (
          <Typography
            size="sm"
            weight="normal"
            className={cx({
              "dali-text-cadmium-600": invalid,
              "dali-text-carbon-300": pass.disabled,
            })}
            data-testid={`${label ?? "dropdown"}-error`}
          >
            {helpText}
          </Typography>
        )}
      </Combobox>
    </Icon.IconContext.Provider>
  );
}
