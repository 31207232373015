import { Icon, Input, Typography } from "@mg/dali/src";
import { EnterpriseOnboardingStatus } from "@mg/schemas/src/commons";
import { useMutation } from "@tanstack/react-query";
// eslint-disable-next-line import/named, @typescript-eslint/no-unused-vars
import { Route, createRoute, useSearch } from "@tanstack/react-router";
import cx from "classnames";
import { useState } from "react";
import { z } from "zod";

import { ContactCard } from "./components/ContactCard";
import { ImportContactsMenu } from "./components/ImportContactsMenu";

import { archiveContact } from "../../services/contacts";
import { sendInvitations } from "../../services/network";
import { requiresAuth } from "../../utils/auth";
import { useContacts } from "../../utils/queries/contacts";
import { authLayoutRoute } from "../auth-layout/route";

const searchParser = z.object({
  types: z
    .array(z.nativeEnum(EnterpriseOnboardingStatus))
    .default([
      EnterpriseOnboardingStatus.IMPORTED,
      EnterpriseOnboardingStatus.INVITED,
    ]),
});

export const contactsRoute = createRoute({
  getParentRoute: () => authLayoutRoute,
  path: "contacts",
  validateSearch(search: Record<string, unknown>) {
    return searchParser.parse(search);
  },
  beforeLoad() {
    requiresAuth();
  },
  component: function Component() {
    const { types } = useSearch({ from: "/authenticated/contacts" });

    const [query, setQuery] = useState("");
    const [archivingId, setArchivingId] = useState("");
    const [archivedIds, setArchivedIds] = useState<string[]>([]);
    const [invitingEmail, setInvitingEmail] = useState("");
    const [invitedEmails, setInvitedEmails] = useState<string[]>([]);

    const contacts = useContacts(types);
    const archiveMutation = useMutation({
      mutationFn: archiveContact,
      onMutate(contactId) {
        setArchivingId(contactId);
      },
      onSuccess() {
        setArchivedIds((prev) => {
          const res = [...prev];
          res.push(archivingId);

          return res;
        });

        setArchivingId("");
      },
    });
    const inviteMutation = useMutation({
      mutationFn: sendInvitations,
      onMutate(variables) {
        setInvitingEmail(variables.invites[0].linkedinOrEmail);
      },
      onSuccess() {
        setInvitedEmails((prev) => {
          const res = [...prev];
          res.push(invitingEmail);

          return res;
        });
        setInvitingEmail("");
      },
    });

    if (contacts.isLoading) {
      return (
        <Typography
          as="h1"
          size="2xl"
          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
        >
          Loading...
          <Icon.CircleNotch className="mx-auto animate-spin" />
        </Typography>
      );
    }

    if (contacts.data == null) {
      return null;
    }

    const filteredContacts =
      query.length > 0
        ? contacts.data.filter(
            (c) =>
              c.name.toLocaleLowerCase().includes(query.toLocaleLowerCase()) ||
              c.title?.toLocaleLowerCase().includes(query.toLocaleLowerCase()),
          )
        : contacts.data;

    return (
      <div className="mb:pb-0 relative h-screen overflow-y-auto pb-24">
        <header className="sticky top-0 z-[1] bg-carbon-50 p-4">
          <Input
            size="sm"
            startAdornment={<Icon.MagnifyingGlass />}
            placeholder="Search Contacts by Name, Job Title, Company, or Location"
            value={query}
            // @ts-expect-error TS2399: Value does not exist on target
            onInput={({ target }) => setQuery(target.value)}
            className="bg-base-white"
            disabled={contacts.data == null || contacts.data.length === 0}
          />
        </header>

        <div
          className={cx(
            "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2",
            {
              hidden: contacts.data != null && contacts.data.length !== 0,
            },
          )}
        >
          <ImportContactsMenu />
        </div>

        <div className="grid gap-6 p-4 md:px-16">
          {filteredContacts.map((c) => (
            <ContactCard
              key={c._id}
              {...c}
              invitingEmail={invitingEmail}
              archivingId={archivingId}
              invitedEmails={invitedEmails}
              archivedIds={archivedIds}
              inviteMutation={inviteMutation}
              archiveMutation={archiveMutation}
            />
          ))}
        </div>
      </div>
    );
  },
});
