import {
  DialogQueue,
  createBannerQueue,
  createDialogQueue,
  createSnackbarQueue,
  SnackbarQueue,
  BannerQueue,
  type BannerProps,
  type DialogPromise,
  type PromptBodyProps,
  type SnackbarProps,
} from "@mg/dali/src";
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  type ProviderProps,
  useRef,
} from "react";

interface UIContextValues {
  alert: (dialog: Partial<DialogPromise>) => Promise<boolean | null>;
  bannerNotify(message: Partial<BannerProps>): void;
  confirm: (dialog: Partial<DialogPromise>) => Promise<boolean | null>;
  prompt: (
    dialog: Partial<DialogPromise> & Partial<PromptBodyProps>,
  ) => Promise<string | null>;
  notify: (message: SnackbarProps) => void;
  isMobile: boolean;
  rootRef: React.RefObject<HTMLElement>;
}

export const UIContext = createContext({} as UIContextValues);

export function UIProvider(
  props: JSX.ElementChildrenAttribute &
    Omit<ProviderProps<Partial<UIContextValues>>, "value">,
) {
  const { children, ...rest } = props;
  const { dialogs, alert, confirm, prompt } = createDialogQueue();
  const { messages, notify } = createSnackbarQueue();
  const { bannerNotify, messages: bannerMessages } = createBannerQueue();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const rootRef = useRef(null);

  useEffect(() => {
    function handleWindowResize() {
      if (window.innerWidth < 600) {
        return setIsMobile(true);
      }

      return setIsMobile(false);
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const uiValue = useMemo(
    () => ({
      bannerNotify,
      dialogs,
      alert,
      confirm,
      prompt,
      notify,
      messages,
      isMobile,
      rootRef,
    }),
    [
      bannerNotify,
      dialogs,
      alert,
      confirm,
      prompt,
      notify,
      messages,
      isMobile,
      rootRef,
    ],
  );

  return (
    // @ts-expect-error TS2322: the `prompt` dialog needs its type updated in
    // Dali
    <UIContext.Provider value={uiValue} {...rest}>
      <DialogQueue dialogs={dialogs} />
      <BannerQueue messages={bannerMessages} />
      <SnackbarQueue messages={messages} />
      {children}
    </UIContext.Provider>
  );
}

export const useUI = () => useContext(UIContext);
