import { Typography } from "@mg/dali/src";

import CreativeAtWork from "../../../images/creatives-at-work.png";

export const NoPermissionsView = () => (
  <div className="grid place-content-center py-12">
    <Typography size="6xl" className="mb-9 text-center text-carbon-300">
      You do not have access to this feature.
    </Typography>
    <Typography className="text-center text-carbon-300">
      <a href="mailto:success@meaningfulgigs.com" className="underline">
        Contact Meaningful Gigs
      </a>{" "}
      for assistance
    </Typography>
    <img
      src={CreativeAtWork}
      alt="Creatives at work"
      className="mx-auto size-[400px]"
    />
  </div>
);
