import { z } from "zod";

export enum ModelsNames {
  Connection = "Connection",
  CreativeList = "CreativeList",
  Domain = "Domain",
  Enterprise = "Enterprise",
  Event = "Event",
  Gig = "Gig",
  Recommendation = "Recommendation",
  User = "User",
  Note = "Note",
  Team = "Team",
  Ticket = "Ticket",
  UserChat = "UserChat",
  Workflow = "Workflow",
}

export const modelNamesSchema = z.nativeEnum(ModelsNames);

export enum SubReferenceTypes {
  TicketMessage = "T",
  TicketRevision = "TicketRevision",
  TicketReview = "TicketReview",
}

export const subReferenceTypesSchema = z.nativeEnum(SubReferenceTypes);
