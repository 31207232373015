import { ButtonNew, Typography } from "@mg/dali/src";

export function ShoutOut(props: {
  ctaText: string;
  title: string;
  handleClick: () => void;
}) {
  return (
    <article
      className="grid gap-3 rounded bg-carbon-50 px-32 pb-9 pt-10 text-center"
      data-testid="shout-out-component"
    >
      <Typography as="p" size="2xl" weight="normal">
        {props.title}
      </Typography>
      <ButtonNew
        theme="primary"
        size="sm"
        className="mx-auto max-w-max"
        onClick={props.handleClick}
      >
        {props.ctaText}
      </ButtonNew>
    </article>
  );
}
