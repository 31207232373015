/* eslint-disable-next-line import/named */
import { createRoute, redirect } from "@tanstack/react-router";

import { ticketRoute } from "./ticket";

import { authLayoutRoute } from "../../auth-layout/route";

export const ticketCompatibilityRoute = createRoute({
  getParentRoute: () => authLayoutRoute,
  path: "tickets/$ticketId",
  beforeLoad({ params }) {
    throw redirect({
      to: ticketRoute.to,
      params,
      search(prev) {
        return { ...prev, tab: 0 };
      },
    });
  },
});
