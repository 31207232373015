import { Menu, Transition } from "@headlessui/react";
import { Icon, Typography } from "@mg/dali/src";
import * as ToggleGroup from "@radix-ui/react-toggle-group";
import * as Tooltip from "@radix-ui/react-tooltip";
import { useMutation } from "@tanstack/react-query";
// eslint-disable-next-line import/named
import { createRoute, useParams } from "@tanstack/react-router";
import { Fragment, useRef, useState } from "react";

import { NetworkSummaryCard } from "../../components/NetworkSummaryCard";
import { SendMessageDialog } from "../../components/SendMessageDialog";
import { ShareDialogType, ShareDialog } from "../../components/ShareDialog";
import { useUI } from "../../contexts/ui";
import { removeCreativeToList } from "../../services/lists";
import { requiresAuth } from "../../utils/auth";
import { toggleItemClasses } from "../../utils/constants";
import { useAppSelector } from "../../utils/hooks";
import { useList } from "../../utils/queries/lists";
import { DeleteListDialog } from "../lists/components/DeleteListDialog";
import { DuplicateListDialog } from "../lists/components/DuplicateListDialog";
import { EditListName } from "../lists/components/EditListDialog";
import { listsRoute } from "../lists/route";

export const listRoute = createRoute({
  getParentRoute: () => listsRoute,
  path: "$listId",
  beforeLoad() {
    requiresAuth();
    localStorage.removeItem("listPath");
  },
  component: function Component() {
    const { notify } = useUI();
    const { listId } = useParams({ from: "/authenticated/lists/$listId" });
    const list = useList(listId);
    const user = useAppSelector((state) => state.auth.value);

    const isGuest = user?.role === "guest";

    const removeCreativeFromListMutation = useMutation({
      mutationFn: removeCreativeToList,
      onSuccess(_, params) {
        const creativeName = list.data?.creatives.find(
          (c) => c._id === params.creativeId,
        )?.name;
        list.refetch();
        notify({
          variant: "success",
          title: `Removed ${creativeName}`,
          message: `You have successfully removed ${creativeName} from ${list.data?.title}.`,
          leadingIcon: <Icon.CheckCircle color="rgb(var(--base-black))" />,
        });
      },
      onError() {
        notify({
          variant: "error",
          title: `Oops!`,
          message: `Something went wrong, please try again.`,
        });
      },
    });

    const [creativeToMessage, setCreativeToMessage] = useState<
      string[] | undefined
    >([]);
    const [presentation, setPresentation] = useState<"list" | "grid">("grid");
    const [shareListDialogOpen, setShareListDialogOpen] = useState(false);
    const [renameListDialogOpen, setRenameListDialogOpen] = useState(false);
    const [duplicateListDialogOpen, setDuplicateListDialogOpen] =
      useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const sendMessageDialogRef = useRef<HTMLDialogElement>(null);

    const listName = list.data?.title;

    if (list.isLoading) {
      return (
        <Typography
          as="h1"
          size="2xl"
          className="fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
        >
          Loading...
          <Icon.CircleNotch className="mx-auto animate-spin" />
        </Typography>
      );
    }

    return (
      <section className="grid flex-1 auto-rows-max gap-6">
        <header className="flex items-center gap-4">
          <Typography as="h4" size="2xl">
            {list.data?.title}
          </Typography>

          <div className="flex items-center gap-4">
            <ToggleGroup.Root
              type="single"
              value={presentation}
              onValueChange={(value: typeof presentation) =>
                setPresentation(value)
              }
              className="hidden max-w-max rounded-lg border border-carbon-100 [&>*:not(:last-child)]:border-r-carbon-100 [&>button:not(:last-child)]:border-r"
            >
              <ToggleGroup.Item value="list" className={toggleItemClasses}>
                <Icon.List />
              </ToggleGroup.Item>
              <ToggleGroup.Item value="grid" className={toggleItemClasses}>
                <Icon.GridFour />
              </ToggleGroup.Item>
            </ToggleGroup.Root>

            <Menu as="div" className="relative">
              <Menu.Button className="flex size-8 items-center justify-center rounded-lg transition-colors hover:bg-vermillion-50/50">
                <Icon.DotsThreeOutlineVertical className="mx-auto" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-[1] mt-2 grid w-max origin-top-right gap-2 rounded-xl bg-base-white px-4 py-2 text-left shadow-lg">
                  <Menu.Item>
                    <button
                      onClick={() => setShareListDialogOpen(true)}
                      className="flex items-center gap-3 rounded-lg p-2 transition-colors hover:bg-carbon-50"
                    >
                      <Icon.Share />
                      <Typography>Share List</Typography>
                    </button>
                  </Menu.Item>
                  <Menu.Item>
                    <button
                      onClick={() => setDuplicateListDialogOpen(true)}
                      className="flex items-center gap-3 rounded-lg p-2 transition-colors hover:bg-carbon-50"
                    >
                      <Icon.Copy />
                      <Typography>Duplicate List</Typography>
                    </button>
                  </Menu.Item>
                  <Menu.Item>
                    <button
                      onClick={() => setRenameListDialogOpen(true)}
                      className="flex items-center gap-3 rounded-lg p-2 transition-colors hover:bg-carbon-50"
                    >
                      <Icon.PencilSimpleLine />
                      <Typography>Rename List</Typography>
                    </button>
                  </Menu.Item>
                  <Menu.Item>
                    <button
                      onClick={() => setDeleteDialogOpen(true)}
                      className="flex items-center gap-3 rounded-lg p-2 text-cadmium-700 transition-colors hover:bg-cadmium-50"
                    >
                      <Icon.Trash />
                      <Typography>Delete List</Typography>
                    </button>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </header>

        <div className="grid grid-cols-[repeat(auto-fill,minmax(275px,1fr))] gap-6">
          {list.data?.creatives.map((creative) => (
            // @ts-expect-error TS2322: Typed incorrectly in BE.
            <NetworkSummaryCard
              key={creative._id}
              presentation={presentation}
              actions={
                !isGuest ? (
                  <>
                    <Tooltip.Provider>
                      <Tooltip.Root delayDuration={50}>
                        <Tooltip.Trigger>
                          <button
                            disabled={removeCreativeFromListMutation.isPending}
                            onClick={(e) => {
                              e.stopPropagation();
                              removeCreativeFromListMutation.mutate({
                                listId,
                                creativeId: creative._id,
                              });
                            }}
                            className="text-base-black"
                          >
                            {removeCreativeFromListMutation.isPending ? (
                              <Icon.CircleNotch
                                size={20}
                                className="animate-spin"
                              />
                            ) : (
                              <Icon.Trash size={20} />
                            )}
                          </button>
                        </Tooltip.Trigger>
                        <Tooltip.Portal>
                          <Tooltip.Content
                            className="z-10 select-none whitespace-pre rounded-[4px] bg-carbon-50 px-4 py-2.5 text-vermillion-600 shadow-lg will-change-[transform,opacity] data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade"
                            sideOffset={0}
                            side="top"
                          >
                            <Typography>Remove from list</Typography>
                            <Tooltip.Arrow className="fill-carbon-50" />
                          </Tooltip.Content>
                        </Tooltip.Portal>
                      </Tooltip.Root>
                    </Tooltip.Provider>
                  </>
                ) : null
              }
              {...creative}
            />
          ))}
        </div>
        <SendMessageDialog
          ref={sendMessageDialogRef}
          onClose={() => {
            if (sendMessageDialogRef.current != null) {
              sendMessageDialogRef.current.close();
            }
            setCreativeToMessage([]);
          }}
          ids={creativeToMessage}
        />
        <DeleteListDialog
          open={deleteDialogOpen}
          onOpenChange={setDeleteDialogOpen}
          title={listName as string}
        />
        <DuplicateListDialog
          open={duplicateListDialogOpen}
          onOpenChange={setDuplicateListDialogOpen}
          title={listName as string}
        />
        <EditListName
          open={renameListDialogOpen}
          onOpenChange={setRenameListDialogOpen}
          title={listName as string}
        />
        <ShareDialog
          open={shareListDialogOpen}
          onOpenChange={setShareListDialogOpen}
          type={ShareDialogType.List}
        />
      </section>
    );
  },
  errorComponent() {
    return "Uh oh! That one isn't found";
  },
});
