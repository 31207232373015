import {
  type Option,
  Select,
  Typography,
  ButtonNew,
  Chip,
  Icon,
} from "@mg/dali/src";
import * as Dialog from "@radix-ui/react-dialog";
import { useNavigate, useSearch } from "@tanstack/react-router";
import cx from "classnames";
import { useEffect, useState } from "react";

import { useAppSelector } from "../../../utils/hooks";
import { toConceptObject } from "../route";

export type AdvancedFilters = {
  timezone?: string[];
  level?: number[];
  specialties: string[];
  tags: string[];
  brands: string[];
  industries: string[];
  skills: string[];
  deliverables: string[];
  tools: string[];
};

type AdvancedFiltersDialogProps = {
  open: boolean;
  onOpenChange(open: boolean): void;
  timezones: string[]; // the possible timezones to select from
};

export const AdvancedFiltersDialog = (props: AdvancedFiltersDialogProps) => {
  const search = useSearch({ strict: false });
  const navigate = useNavigate();
  const network = useAppSelector((state) => state.network.value);

  const [filters, setFilters] = useState({
    tags: search.tags?.map((t) => ({ label: t, value: t })) ?? [],
    brands: search.brands?.map((t) => ({ label: t, value: t })) ?? [],
    specialties: search.specialties?.map((t) => ({ label: t, value: t })) ?? [],
    industries: search.industries?.map((t) => ({ label: t, value: t })) ?? [],
    skills: search.skills?.map((t) => ({ label: t, value: t })) ?? [],
    tools: search.tools?.map((t) => ({ label: t, value: t })) ?? [],
    deliverables:
      search.deliverables?.map((t) => ({ label: t, value: t })) ?? [],
  });

  useEffect(() => {
    setFilters({
      tags: search.tags?.map((t) => ({ label: t, value: t })) ?? [],
      brands: search.brands?.map((t) => ({ label: t, value: t })) ?? [],
      specialties:
        search.specialties?.map((t) => ({ label: t, value: t })) ?? [],
      industries: search.industries?.map((t) => ({ label: t, value: t })) ?? [],
      skills: search.skills?.map((t) => ({ label: t, value: t })) ?? [],
      tools: search.tools?.map((t) => ({ label: t, value: t })) ?? [],
      deliverables:
        search.deliverables?.map((t) => ({ label: t, value: t })) ?? [],
    });
  }, [
    search.tags,
    search.brands,
    search.specialties,
    search.industries,
    search.skills,
    search.tools,
    search.deliverables,
  ]);

  function handleChangeFilter(
    key: keyof typeof filters,
    value: number | Option[],
  ) {
    setFilters((prev) => {
      const res = { ...prev };
      //@ts-expect-error TS2322: Type 'number | Option[]' is not assignable to type 'Option[]'.
      res[key] = value;

      return res;
    });
  }

  function handleApplyFilters(filters: AdvancedFilters) {
    navigate({
      replace: true,
      search(prev) {
        Object.keys(filters).forEach((f) => {
          if (
            filters[f as unknown as keyof AdvancedFilters] == null ||
            (Array.isArray(filters[f as unknown as keyof AdvancedFilters]) &&
              filters[f as unknown as keyof AdvancedFilters] != null &&
              // @ts-expect-error TS2532: Not sure why its saying it might be undefined
              filters[f as unknown as keyof AdvancedFilters].length === 0)
          ) {
            // @ts-expect-error TS7006: Parameter 'f' implicitly has an 'any' type.
            delete prev[f];
          } else {
            // @ts-expect-error TS7006: Parameter 'f' implicitly has an 'any' type.
            prev[f] = filters[f as unknown as keyof AdvancedFilters];
          }
        });

        return prev;
      },
    });
  }

  return (
    <Dialog.Root {...props}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-[5] bg-base-black/50 data-[state=open]:animate-fade-in" />
        <Dialog.Content className="fixed left-1/2 top-1/2 z-[6] grid max-h-[90vh] w-[90vw] max-w-lg -translate-x-1/2 -translate-y-1/2 gap-6 overflow-auto rounded-lg bg-base-white p-6 text-base-black shadow-2xl focus:outline-none data-[state=open]:animate-dialog-content-show">
          <Dialog.Title asChild>
            <Typography size="2xl" weight="medium">
              Filters
            </Typography>
          </Dialog.Title>

          <Typography
            size="xl"
            weight="medium"
            className="text-carbon-800"
            id="specialties-label"
          >
            Specialties
          </Typography>

          <Select<true>
            multiple
            startAdornment={<Icon.Palette />}
            placeholder="Search or select"
            options={network?.filters.specialties?.map(toConceptObject) ?? []}
            value={filters.specialties}
            onChange={(values) => handleChangeFilter("specialties", values)}
            renderSelections={false}
          />

          <div
            className={cx("flex flex-wrap gap-2", {
              hidden: filters.specialties.length === 0,
            })}
          >
            {filters.specialties.map((s) => (
              <Chip
                key={s.value}
                variant="primary-outlined"
                label={s.label}
                trailingIcon={
                  <button
                    onClick={() => {
                      setFilters((prev) => {
                        const res = { ...prev };
                        res.specialties = res.specialties.filter(
                          (r) => r.value !== s.value,
                        );

                        return res;
                      });
                    }}
                  >
                    <Icon.XCircle />
                  </button>
                }
              />
            ))}
          </div>

          <Typography
            size="xl"
            weight="medium"
            className="text-carbon-800"
            id="specialties-label"
          >
            Deliverables
          </Typography>

          <Select<true>
            multiple
            startAdornment={<Icon.FileArrowUp />}
            placeholder="Search or select"
            options={network?.filters.deliverables?.map(toConceptObject) ?? []}
            value={filters.deliverables}
            onChange={(values) => handleChangeFilter("deliverables", values)}
            renderSelections={false}
          />

          <div
            className={cx("flex flex-wrap gap-2", {
              hidden: filters.deliverables.length === 0,
            })}
          >
            {filters.deliverables.map((s) => (
              <Chip
                key={s.value}
                variant="primary-outlined"
                label={s.label}
                trailingIcon={
                  <button
                    onClick={() => {
                      setFilters((prev) => {
                        const res = { ...prev };
                        res.deliverables = res.deliverables.filter(
                          (r) => r.value !== s.value,
                        );

                        return res;
                      });
                    }}
                  >
                    <Icon.XCircle />
                  </button>
                }
              />
            ))}
          </div>

          <Typography
            size="xl"
            weight="medium"
            className="text-carbon-800"
            id="specialties-label"
          >
            Skills
          </Typography>

          <Select<true>
            multiple
            startAdornment={<Icon.CompassTool />}
            placeholder="Search or select"
            options={network?.filters.skills?.map(toConceptObject) ?? []}
            value={filters.skills}
            onChange={(values) => handleChangeFilter("skills", values)}
            renderSelections={false}
          />

          <div
            className={cx("flex flex-wrap gap-2", {
              hidden: filters.skills.length === 0,
            })}
          >
            {filters.skills.map((s) => (
              <Chip
                key={s.value}
                variant="primary-outlined"
                label={s.label}
                trailingIcon={
                  <button
                    onClick={() => {
                      setFilters((prev) => {
                        const res = { ...prev };
                        res.skills = res.skills.filter(
                          (r) => r.value !== s.value,
                        );

                        return res;
                      });
                    }}
                  >
                    <Icon.XCircle />
                  </button>
                }
              />
            ))}
          </div>

          <Typography
            size="xl"
            weight="medium"
            className="text-carbon-800"
            id="specialties-label"
          >
            Tools
          </Typography>

          <Select<true>
            multiple
            startAdornment={<Icon.Hammer />}
            placeholder="Search or select"
            options={network?.filters.tools?.map(toConceptObject) ?? []}
            value={filters.tools}
            onChange={(values) => handleChangeFilter("tools", values)}
            renderSelections={false}
          />

          <div
            className={cx("flex flex-wrap gap-2", {
              hidden: filters.tools.length === 0,
            })}
          >
            {filters.tools.map((s) => (
              <Chip
                key={s.value}
                variant="primary-outlined"
                label={s.label}
                trailingIcon={
                  <button
                    onClick={() => {
                      setFilters((prev) => {
                        const res = { ...prev };
                        res.tools = res.tools.filter(
                          (r) => r.value !== s.value,
                        );

                        return res;
                      });
                    }}
                  >
                    <Icon.XCircle />
                  </button>
                }
              />
            ))}
          </div>

          <Typography
            size="xl"
            weight="medium"
            className="text-carbon-800"
            id="specialties-label"
          >
            Industries
          </Typography>

          <Select<true>
            multiple
            startAdornment={<Icon.Buildings />}
            placeholder="Search or select"
            options={network?.filters.industries?.map(toConceptObject) ?? []}
            value={filters.industries}
            onChange={(values) => handleChangeFilter("industries", values)}
            renderSelections={false}
          />

          <div
            className={cx("flex flex-wrap gap-2", {
              hidden: filters.industries.length === 0,
            })}
          >
            {filters.industries.map((s: { value: string; label: string }) => (
              <Chip
                key={s.value}
                variant="primary-outlined"
                label={s.label}
                trailingIcon={
                  <button
                    onClick={() => {
                      setFilters((prev) => {
                        const res = { ...prev };
                        res.industries = res.industries.filter(
                          (r: { value: string }) => r.value !== s.value,
                        );

                        return res;
                      });
                    }}
                  >
                    <Icon.XCircle />
                  </button>
                }
              />
            ))}
          </div>

          <Typography
            size="xl"
            weight="medium"
            className="text-carbon-800"
            id="specialties-label"
          >
            Brands
          </Typography>

          <Select<true>
            multiple
            startAdornment={<Icon.TrademarkRegistered />}
            placeholder="Search or select"
            options={network?.filters.brands?.map(toConceptObject) ?? []}
            value={filters.brands}
            onChange={(values) => handleChangeFilter("brands", values)}
            renderSelections={false}
          />

          <div
            className={cx("flex flex-wrap gap-2", {
              hidden: filters.brands.length === 0,
            })}
          >
            {filters.brands.map((s) => (
              <Chip
                key={s.value}
                variant="primary-outlined"
                label={s.label}
                trailingIcon={
                  <button
                    onClick={() => {
                      setFilters((prev) => {
                        const res = { ...prev };
                        res.brands = res.brands.filter(
                          (r) => r.value !== s.value,
                        );

                        return res;
                      });
                    }}
                  >
                    <Icon.XCircle />
                  </button>
                }
              />
            ))}
          </div>

          <Typography
            size="xl"
            weight="medium"
            className="text-carbon-800"
            id="specialties-label"
          >
            Tags
          </Typography>

          <Select<true>
            multiple
            startAdornment={<Icon.Hash />}
            placeholder="Search or select"
            options={network?.filters.tags?.map(toConceptObject) ?? []}
            value={filters.tags}
            onChange={(values) => handleChangeFilter("tags", values)}
            renderSelections={false}
          />

          <div
            className={cx("flex flex-wrap gap-2", {
              hidden: filters.tags.length === 0,
            })}
          >
            {filters.skills.map((s) => (
              <Chip
                key={s.value}
                variant="primary-outlined"
                label={s.label}
                trailingIcon={
                  <button
                    onClick={() => {
                      setFilters((prev) => {
                        const res = { ...prev };
                        res.tags = res.tags.filter((r) => r.value !== s.value);

                        return res;
                      });
                    }}
                  >
                    <Icon.XCircle />
                  </button>
                }
              />
            ))}
          </div>

          <div className="flex gap-4">
            <ButtonNew
              onClick={() => {
                handleApplyFilters({
                  tags: filters.tags.map((v) => v.value),
                  specialties: filters.specialties.map((v) => v.value),
                  brands: filters.brands.map((v) => v.value),
                  industries: filters.industries.map((v) => v.value),
                  skills: filters.skills.map((v) => v.value),
                  deliverables: filters.deliverables.map((v) => v.value),
                  tools: filters.tools.map((v) => v.value),
                });
                props.onOpenChange(false);
              }}
            >
              Apply Filters
            </ButtonNew>

            <Dialog.Close asChild>
              <ButtonNew variant="outlined">Cancel</ButtonNew>
            </Dialog.Close>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

AdvancedFiltersDialog.displayName = "AdvancedFiltersDialog";
