import { type MyNetworkResponse } from "@mg/schemas/src/christo/myNetwork";
import { useMutation } from "@tanstack/react-query";
import { useSearch } from "@tanstack/react-router";
import Fuse from "fuse.js";
import { useEffect, useState } from "react";

import { getNetwork } from "../../services/network";
import { useAppDispatch } from "../hooks";
import { setNetwork } from "../slices/network";

const fuseOptions = {
  isCaseSensitive: false,
  shouldSort: true,
  threshold: 0.2,
  includeScore: true,
  ignoreLocation: true,
  minMatchCharLength: 2,
  keys: [
    "skills",
    "specialties",
    "deliverables",
    "tools",
    "tags",
    "names",
    // "brands", -- disabling brands to be used as a search term
  ],
};

export type SearchSuggestion = {
  key: string;
  value: string;
  type: string;
  score?: number;
  [key: string]: unknown;
};

function mapFiltersToOptions(filters: MyNetworkResponse["filters"]) {
  const uniqueLabels: Set<string> = new Set();
  return (
    fuseOptions.keys
      .flatMap((key) =>
        (filters[key as keyof typeof filters] ?? []).map((v: string) => ({
          [key]: v,
          value: v,
          type: key,
          key: `${key}.${v}`,
        })),
      )
      // remove duplicated labels
      .filter((option) => {
        if (uniqueLabels.has(option.value)) {
          return false;
        }
        uniqueLabels.add(option.value);
        return true;
      })
  );
}

// TODO: this gets reinvoked on every instantiation, causing duplicate API
// calls. Address this by maybe putting things away in a slice, and erring
// towards only the API call being called via the SearchAndFilterBar component
export function useNetworkSearch() {
  const dispatch = useAppDispatch();
  const search = useSearch({ strict: false });

  const [suggestedTerms, setSuggestedTerms] = useState<SearchSuggestion[]>([]);
  const [fuse] = useState(new Fuse([] as SearchSuggestion[], fuseOptions));

  const networkMutation = useMutation({
    mutationKey: ["network"],
    mutationFn: getNetwork,
    onSuccess(data) {
      if (data != null) {
        fuse.setCollection(mapFiltersToOptions(data.filters));
      }

      dispatch(setNetwork(data));
      setSuggestedTerms(() => {
        if (!data.suggestions) return [];
        const res: SearchSuggestion[] = [];
        const keys = Object.keys(data.suggestions);
        for (const key of keys) {
          const values: string[] | undefined =
            data.suggestions[key as keyof typeof data.suggestions];
          if (!values) continue;
          for (const value of values) {
            res.push({
              [key]: value,
              value,
              type: key,
              key: `${key}.${value}`,
            });
          }
        }

        return res;
      });
    },
  });

  useEffect(() => {
    if (
      window.location.pathname.includes("network") ||
      window.location.pathname.includes("worked-with") ||
      window.location.pathname.includes("discover") ||
      window.location.pathname.includes("tickets")
    ) {
      networkMutation.mutate(search);
    }
    // Adding networkMutation to the dependency array will cause it to infinite
    // loop. Just depending on search alone gets the job done.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    search.specialties,
    search.skills,
    search.deliverables,
    search.tools,
    search.tags,
    search.timezone,
    search.level,
    search.brands,
    search.names,
    search.queries,
    window.location.pathname,
  ]);

  return {
    fuse,
    networkMutation,
    suggestedTerms,
  };
}
