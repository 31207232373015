import { type MyNetworkProfile } from "@mg/schemas/src/christo/myNetwork";
import Fuse from "fuse.js";
import { token_set_ratio } from "fuzzball";

import { type NetworkSearch } from "../components/SearchAndFilterBar";

type SearchableElement = {
  value: string;
  context: string;
  term?: string | number;
};
export type MatchedTerms = Record<string, SearchableElement[]>;

const createSearchableArray = (
  profile: MyNetworkProfile,
): SearchableElement[] => {
  const searchable: SearchableElement[] = [];

  const addElement = (value: string | undefined, context: string) => {
    if (typeof value === "string") {
      searchable.push({ value, context });
    }
  };

  addElement(profile.title, "title");
  addElement(profile.role, "role");
  addElement(profile.name, "name");

  profile.skills?.forEach((concept) => addElement(concept, "skills"));
  profile.tools?.forEach((concept) => addElement(concept, "tools"));
  profile.deliverables?.forEach((concept) =>
    addElement(concept, "deliverables"),
  );
  profile.specialties?.forEach((concept) => addElement(concept, "specialties"));
  profile.brands?.forEach((concept) => addElement(concept, "brands"));
  profile.industries?.forEach((concept) => addElement(concept, "industries"));
  profile.tags?.forEach((concept) => addElement(concept, "tags"));

  profile.highlights?.forEach((highlight, i) => {
    addElement(highlight.title, `${i}.highlight.title`);
    addElement(highlight.role, `${i}.highlight.role`);
    addElement(highlight.company, `${i}.highlight.company`);
    addElement(highlight.industry, `${i}.highlight.industry`);

    highlight.skills?.forEach((concept) =>
      addElement(concept, `${i}.highlight.skills`),
    );
    highlight.deliverables?.forEach((concept) =>
      addElement(concept, `${i}.highlight.deliverables`),
    );
  });

  return searchable;
};

export const searchProfileForTerms = (
  profile: MyNetworkProfile,
  searchTerms: NetworkSearch,
): MatchedTerms => {
  let results: SearchableElement[] = [];
  const searchableArray = createSearchableArray(profile);
  const globalSearchTerms = searchTerms.queries ?? [];
  const projectSearchArray = searchableArray.filter((arr) =>
    arr.context.includes("highlight"),
  );

  const globalFuse = new Fuse(searchableArray, {
    keys: ["value"],
    threshold: 0.35,
    includeMatches: true,
    includeScore: true,
  });
  globalSearchTerms.forEach((term) => {
    results = results.concat(
      globalFuse.search(term).map((result) => ({
        value: result.item.value,
        context: result.item.context,
        term,
      })),
    );
  });

  Object.entries(searchTerms).forEach(([key, terms]) => {
    if (key !== "queries" && terms) {
      const profileKey = key as keyof MyNetworkProfile;
      if (!profile[profileKey]) return;
      const fieldFuse = new Fuse(
        (profile[profileKey] ?? []) as readonly string[],
        {
          includeScore: true,
          threshold: 0.2,
        },
      );

      const allTerms = [
        ...(terms as string[]),
        ...(globalSearchTerms as string[]),
      ];
      allTerms.forEach((term) => {
        results = results.concat(
          fieldFuse.search(String(term)).map((result) => ({
            value: result.item,
            context: key,
            term,
          })),
        );
      });

      const highlightFuse = new Fuse(projectSearchArray, {
        keys: ["value"],
        threshold: 0.4,
      });

      (terms as string[]).forEach((term) => {
        results = results.concat(
          highlightFuse.search(String(term)).map((result) => ({
            value: result.item.value,
            context: result.item.context,
            term,
          })),
        );
      });
    }
  });

  Object.values(searchTerms)
    .flat()
    ?.forEach((term) => {
      if (profile.bio?.length) {
        const score = token_set_ratio(String(term), profile.bio as string);
        if (score > 85) {
          results.push({
            value: profile.bio as string,
            context: "bio",
            term: term as string,
          });
        }
      }
      profile.highlights?.forEach((highlight, i) => {
        if (!highlight.description?.length) return;
        const highlightScore = token_set_ratio(
          term as string,
          highlight.description as string,
        );

        if (highlightScore > 85) {
          results.push({
            value: highlight.description as string,
            context: `${i}.highlight.description`,
            term: term as string,
          });
        }
      });
    });

  return results.reduce<MatchedTerms>((acc, result) => {
    if (!acc[result.context]) {
      acc[result.context] = [result];
    } else {
      acc[result.context].push(result);
    }
    return acc;
  }, {});
};

export function highlightWords(text: string, wordsToHighlight: string[]) {
  const regexSafeWords = wordsToHighlight.map((word) =>
    word.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
  );
  const pattern = regexSafeWords.join("|");
  const regex = new RegExp(`(${pattern})`, "gi");

  const segments = text
    .split(regex)
    .filter(Boolean)
    .map((segment) => ({
      text: segment,
      highlight: regexSafeWords.some((word) =>
        new RegExp(word, "gi").test(segment),
      ),
    }));

  return segments;
}

export function findProjectMatches(matchedTerms: MatchedTerms, x: number) {
  const regex = new RegExp(`^${x}\\.highlight\\..+$`);
  const highlightMatches = Object.keys(matchedTerms).filter((key) =>
    regex.test(key),
  );
  if (!highlightMatches.length) return;
  return highlightMatches.reduce<MatchedTerms>((acc, key) => {
    acc[key.replace(`${x}.highlight.`, "")] = matchedTerms[key];
    return acc;
  }, {});
}
