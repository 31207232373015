import {
  type UpdateRevisionBoardsResponse,
  type GetRevisionsResponse,
  type GetTicketCommentsResponse,
  type GetTicketResponse,
} from "@mg/schemas/src/christo/catalyst";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

type TicketState = {
  value: GetTicketResponse | null;
  comments: GetTicketCommentsResponse;
  visibleCommentShapes: GetTicketCommentsResponse;
  revisions: GetRevisionsResponse;
  revisionScreenshots: GetRevisionsResponse;
  isLoading: boolean;
  activeCommentId: string | null;
  highlightedCommentId: string | null;
};

const initialState: TicketState = {
  value: null,
  visibleCommentShapes: [],
  comments: [],
  revisions: [],
  revisionScreenshots: [],
  /**
   * Useful for when flagging that the ticket needs to load something, e.g. a
   * file upload
   */
  isLoading: false,
  activeCommentId: null,
  highlightedCommentId: null,
};

export const ticketSlice = createSlice({
  name: "ticket",
  initialState,
  reducers: {
    setTicket(state, action: PayloadAction<GetTicketResponse | null>) {
      state.value = action.payload;

      if (action.payload == null) {
        return;
      }

      if (action.payload.revisionBoards != null) {
        state.revisions = action.payload.revisionBoards;
        state.revisionScreenshots = action.payload.revisionBoards;
      }
    },
    setComments(state, action: PayloadAction<GetTicketCommentsResponse>) {
      state.comments = action.payload;
    },
    setVisibleCommentShapes(
      state,
      action: PayloadAction<GetTicketCommentsResponse>,
    ) {
      state.visibleCommentShapes = action.payload;
    },
    setRevisions(state, action: PayloadAction<GetRevisionsResponse>) {
      state.revisions = action.payload;
      state.revisionScreenshots = action.payload;
    },
    updateRevisionAtIndex(
      state,
      action: PayloadAction<{
        index: number;
        revision: UpdateRevisionBoardsResponse;
        screenshotOnly?: boolean;
      }>,
    ) {
      const { index, revision } = action.payload;

      if (action.payload.screenshotOnly) {
        state.revisionScreenshots = state.revisionScreenshots.toSpliced(
          index,
          1,
          revision,
        );
        return;
      }
      state.revisions = state.revisions.toSpliced(index, 1, revision);
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setActiveCommentId(state, action: PayloadAction<string | null>) {
      state.activeCommentId = action.payload;
    },
    setHighlightedCommentId(state, action: PayloadAction<string | null>) {
      state.highlightedCommentId = action.payload;
    },
  },
});

export const {
  setTicket,
  setComments,
  setVisibleCommentShapes,
  setRevisions,
  setLoading,
  updateRevisionAtIndex,
  setActiveCommentId,
  setHighlightedCommentId,
} = ticketSlice.actions;
