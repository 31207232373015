import { ButtonNew } from "@mg/dali/src";
import { useState } from "react";

import { CustomCSVImportDialog } from "./dialogs/CustomCSVImportDialog";
import { LinkedinCSVImportDialog } from "./dialogs/LinkedinCSVImportDialog";
import { UpworkListImportDialog } from "./dialogs/UpworkListImportDialog";

export function ImportContactsMenu() {
  const [linkedinDialogOpen, setLinkedinDialogOpen] = useState(false);
  const [customDialogOpen, setCustomDialogOpen] = useState(false);
  const [upworkDialogOpen, setUpworkDialogOpen] = useState(false);

  return (
    <div className="flex flex-col gap-4">
      <ButtonNew
        variant="outlined"
        size="lg"
        className="px-16 text-center"
        onClick={() => setLinkedinDialogOpen(true)}
      >
        Import LinkedIn CSV
      </ButtonNew>
      <ButtonNew
        variant="outlined"
        size="lg"
        className="px-16 text-center"
        onClick={() => setCustomDialogOpen(true)}
      >
        Import Custom Spreadsheet
      </ButtonNew>
      <ButtonNew
        variant="outlined"
        size="lg"
        className="px-16 text-center"
        onClick={() => setUpworkDialogOpen(true)}
      >
        Share an Upwork List With Us
      </ButtonNew>
      <ButtonNew variant="outlined" size="lg" className="px-16 text-center">
        Shoot us an email
      </ButtonNew>
      <ButtonNew variant="ghost" size="lg" className="px-16 text-center">
        Check Status
      </ButtonNew>

      {/* DIALOGS */}
      <LinkedinCSVImportDialog
        open={linkedinDialogOpen}
        onOpenChange={setLinkedinDialogOpen}
      />
      <CustomCSVImportDialog
        open={customDialogOpen}
        onOpenChange={setCustomDialogOpen}
      />
      <UpworkListImportDialog
        open={upworkDialogOpen}
        onOpenChange={setUpworkDialogOpen}
      />
    </div>
  );
}
