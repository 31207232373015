import { z } from "zod";

export enum BillingMethod {
  FLAT_RATE = "flat-rate",
  HOURLY = "hourly",
}

export const billingMethodSchema = z.nativeEnum(BillingMethod);

export enum ContractType {
  NEW_ENTERPRISE = "new-enterprise",
  EXPANSION = "expansion",
  EXTENSION = "extension",
  UNKNOWN = "unknown",
}

export const contractTypeSchema = z.nativeEnum(ContractType);

export const contractSchema = z.object({
  type: contractTypeSchema,
  startDate: z.date(),
  endDate: z.date(),
  closeDate: z.coerce.date().optional(),
  bookingsAmount: z.number().optional(),
  payoutAmount: z.number().optional(),
  billingMethod: billingMethodSchema.optional(),
});

export type Contract = z.infer<typeof contractSchema>;
