import { Typography } from "@mg/dali/src";
import { type MyNetworkResponse } from "@mg/schemas/src/christo/myNetwork";
import * as Tooltip from "@radix-ui/react-tooltip";
import cx from "classnames";
import { useRef } from "react";

import { AvatarWithInitials } from "./AvatarWithInitials";

import { generateUUID } from "../utils/uuid";

export function TooltipAvatar(
  props: NonNullable<
    MyNetworkResponse["all"]["results"][number]["workedWith"]
  >[number] & {
    overlayCount?: number;
    content?: string[];
    onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  },
) {
  const uuid = useRef(generateUUID());

  return (
    <Tooltip.Provider
      key={uuid.current}
      data-testid="worked-with-tooltip-avatar"
    >
      <Tooltip.Root delayDuration={50}>
        <Tooltip.Trigger>
          <figure className="relative overflow-hidden rounded-full">
            <AvatarWithInitials
              avatar={props.avatar}
              name={props.name as string}
              size={9}
              onClick={props.onClick}
            />
            <figcaption
              className={cx(
                "absolute inset-0 flex items-center justify-center bg-base-black/50",
                {
                  hidden: props.overlayCount == null || props.overlayCount < 1,
                },
              )}
            >
              <Typography weight="bold" className="text-base-white">
                +{props.overlayCount}
              </Typography>
            </figcaption>
          </figure>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            className="z-10 select-none whitespace-pre rounded-[4px] bg-carbon-50 px-4 py-2.5 text-vermillion-600 shadow-lg will-change-[transform,opacity] data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade"
            sideOffset={0}
            side="top"
          >
            <Typography>{props.content?.join("\n") ?? props.name}</Typography>
            <Tooltip.Arrow className="fill-carbon-50" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}
