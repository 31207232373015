import { ButtonNew, Typography } from "@mg/dali/src";
import { useMutation } from "@tanstack/react-query";
// eslint-disable-next-line import/named, @typescript-eslint/no-unused-vars
import { Link, Route, createRoute, useNavigate } from "@tanstack/react-router";

import { reviewPortfolioRoute } from "./review-portfolio";

import { completeOnboarding } from "../../../services/userProfile";
import { requiresAuth } from "../../../utils/auth";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import { derive } from "../../../utils/slices/auth";
import { rootRoute } from "../../root/route";
import { userProfileRoute } from "../../userProfile/route";
import { BottomNavigation } from "../components/BottomNavigation";

export const whatsNextRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "onboarding/next-steps",
  beforeLoad() {
    requiresAuth();
  },
  component: function Component() {
    const navigate = useNavigate();
    const user = useAppSelector((state) => state.auth.value);
    const dispatch = useAppDispatch();

    const onboardingMutation = useMutation({
      mutationKey: ["complete-onboarding"],
      mutationFn: completeOnboarding,
      onSuccess(data) {
        localStorage.setItem("token", data.token);
        sendTokenToServiceWorker();
        dispatch(derive());
        navigate({ to: userProfileRoute.to });
      },
    });

    return (
      <div className="flex h-dvh flex-col">
        <section className="grid h-full grid-cols-1 overflow-auto md:grid-cols-2">
          <div className="grid auto-rows-max gap-6 px-10 py-9 text-base-black">
            <Typography size="2xl" weight="bold" className="text-carbon-600">
              Final
            </Typography>

            <Typography className="text-4xl md:text-6xl" weight="bold">
              Welcome Aboard!
            </Typography>

            <Typography size="xl" weight="normal">
              Ready to shine in the {user?.company} network? The more you engage
              and enrich your portfolio, the brighter you&apos;ll stand
              out—opening doors to exciting collaborations and coveted gigs!
            </Typography>

            <ButtonNew
              size="sm"
              onClick={() => onboardingMutation.mutate()}
              className="max-w-max"
              disabled={onboardingMutation.isPending}
              isLoading={onboardingMutation.isPending}
            >
              Review Portfolio
            </ButtonNew>
          </div>

          <figure className="hidden bg-carbon-50 md:block" />
        </section>

        <BottomNavigation activeStep={4}>
          <div className="flex items-center justify-between p-6">
            <Link to={reviewPortfolioRoute.to}>
              <ButtonNew size="sm" variant="ghost">
                Back
              </ButtonNew>
            </Link>

            <nav className="flex gap-4">
              <ButtonNew size="sm" onClick={() => onboardingMutation.mutate()}>
                Review Portfolio
              </ButtonNew>
            </nav>
          </div>
        </BottomNavigation>
      </div>
    );
  },
});
