import { z } from "zod";

import { highlightSchema } from "../commons";

export const aiHighlightSchema = highlightSchema.extend({
  title: z.string().optional(),
});

export type AIHighlight = z.infer<typeof aiHighlightSchema>;

// POST /v1/private/projects/ai
export const createProjectsWithAIBodySchema = z.array(aiHighlightSchema);

export type CreateProjectsWithAIBody = z.infer<
  typeof createProjectsWithAIBodySchema
>;

export const createProjectsWithAIRequest = z.object({
  body: createProjectsWithAIBodySchema,
});

export type CreateProjectsWithAIRequest = z.infer<
  typeof createProjectsWithAIRequest
>;

export const createProjectsWithAIResponseSchema = z.array(highlightSchema);

export type CreateProjectsWithAIResponse = z.infer<
  typeof createProjectsWithAIResponseSchema
>;

// PUT /v1/private/projects/ai
export const updateProjectsWithAIBodySchema = z.array(aiHighlightSchema);

export type UpdateProjectsWithAIBody = z.infer<
  typeof updateProjectsWithAIBodySchema
>;

export const updateProjectsWithAIRequest = z.object({
  body: updateProjectsWithAIBodySchema,
});

export type UpdateProjectsWithAIRequest = z.infer<
  typeof updateProjectsWithAIRequest
>;

export const updateProjectsWithAIResponseSchema = z.array(highlightSchema);

export type UpdateProjectsWithAIResponse = z.infer<
  typeof updateProjectsWithAIResponseSchema
>;
