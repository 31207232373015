import { Grid, Heading } from "@radix-ui/themes";
import cx from "classnames";

import { useAppSelector } from "../../../utils/hooks";

export function TicketsLoader() {
  const { drawerOpen } = useAppSelector((state) => state.ui);
  return (
    <Grid
      gap="4"
      className={cx("mt-40 text-center", {
        "ml-80": drawerOpen,
      })}
    >
      <Heading size="5">Loading tickets...</Heading>
    </Grid>
  );
}
