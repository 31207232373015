import { ButtonNew, Icon, Typography } from "@mg/dali/src";
import { type Asset } from "@mg/schemas/src/commons";
// eslint-disable-next-line import/named
import { createRoute, useNavigate, useParams } from "@tanstack/react-router";
import cx from "classnames";
import { Fragment, useEffect, useReducer, useRef } from "react";

import { LabeledMappedChips } from "../../components/LabeledMappedChips";
import { Markdown } from "../../components/Markdown";
import { requiresAuth } from "../../utils/auth";
import { useAppSelector } from "../../utils/hooks";
import { assetForUser } from "../../utils/imageHandler";
import { useProfile } from "../../utils/queries/profile";
import { authLayoutRoute } from "../auth-layout/route";
import { myWorkEditRoute } from "../userProfile/routes/MyWork/editRoute";

export const projectRoute = createRoute({
  getParentRoute: () => authLayoutRoute,
  path: "profile-project/$profileId/$projectId",
  beforeLoad() {
    requiresAuth();
  },
  component: function Component() {
    const { profileId, projectId } = useParams({ strict: false });
    const navigate = useNavigate();
    const profile = useProfile(profileId);
    const user = useAppSelector((state) => state.auth.value);
    const isUser = user?.userID === profile.data?._id;
    const [expanded, toggleExpansion] = useReducer((x) => !x, false);
    const [showButton, setShowButton] = useReducer((x) => !x, false);
    const textRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (textRef.current) {
        const style = window.getComputedStyle(textRef.current);
        const lineHeight = parseFloat(style.lineHeight);
        const lines = textRef.current.scrollHeight / lineHeight;

        if (lines > 5) {
          setShowButton();
        }
      }
    }, [profile.data?.bio]);

    if (profile.isLoading) {
      return <h2>Loading...</h2>;
    }

    if (profile.data == null) {
      return null;
    }

    const { highlights } = profile.data;
    const project = highlights?.find((h) => h._id === projectId);

    if (project == null) {
      return null;
    }
    function getEmbedURL(url: string) {
      let embedUrl = "";

      // Use URL constructor to parse the url
      const parsedUrl = new URL(url);

      if (parsedUrl.hostname.includes("youtube")) {
        // Check if the URL is already an embed URL
        if (parsedUrl.pathname.includes("embed")) {
          embedUrl = url;
        } else {
          const videoId = parsedUrl.searchParams.get("v");
          embedUrl = `https://www.youtube.com/embed/${videoId}`;
        }
      } else if (parsedUrl.hostname.includes("vimeo")) {
        // Check if the URL is already an embed URL
        if (parsedUrl.pathname.includes("video")) {
          embedUrl = url;
        } else {
          const videoId = parsedUrl.pathname.split("/")[1];
          embedUrl = `https://player.vimeo.com/video/${videoId}`;
        }
      } else {
        console.error("Invalid URL: The URL should be a YouTube or Vimeo URL.");
      }

      return embedUrl;
    }

    function renderAssets(assets?: Partial<Asset>[]) {
      return assets?.map((a, i) => {
        if (a.type === "image") {
          return (
            <Fragment key={i}>
              <link
                rel="preload"
                as="image"
                href={assetForUser(a.source, 1280)}
              />
              <img
                src={assetForUser(a.source, 1280)}
                alt={`Designer ${i + 1}`}
                style={{ width: "100%" }}
              />
            </Fragment>
          );
        } else if (a.type === "video") {
          return (
            <Fragment key={i}>
              <div className="relative w-full pt-[56.25%]">
                <iframe
                  src={getEmbedURL(a.source ?? "")}
                  width="640"
                  height="360"
                  className="absolute left-0 top-0 size-full"
                  title={`Designer video ${i + 1}`}
                />
              </div>
            </Fragment>
          );
        }

        return null;
      });
    }

    return (
      <div className="mx-auto grid h-dvh auto-rows-min gap-6 overflow-y-auto px-4 pb-24 md:px-10 md:py-9">
        <div className="sticky top-0 z-[1] flex items-center justify-between bg-base-white py-1 md:static md:-ml-8">
          <ButtonNew variant="ghost" onClick={() => history.back()}>
            <Icon.ArrowLeft />
            Back
          </ButtonNew>
          <ButtonNew
            variant="ghost"
            onClick={() =>
              navigate({
                to: myWorkEditRoute.to,
                // @ts-expect-error TS2353: too many search params are being
                // used in too many places and should get consolidated with a
                // single Zod parser in validateSearch
                search: { projectId: project._id, fromProject: true },
              })
            }
            className={cx({ hidden: !isUser })}
          >
            <Icon.PencilSimpleLine />
            Edit Project
          </ButtonNew>
        </div>
        <section className="grid gap-8">
          <Typography size="3xl" weight="bold">
            {project.title}
          </Typography>

          <div className="grid gap-8 md:grid-cols-[1fr_250px]">
            <div className="grid auto-rows-max gap-2">
              <Typography weight="medium" className="text-carbon-400">
                What I Did
              </Typography>

              <div
                className={cx({ "line-clamp-5": !expanded })}
                data-testid="bio-description-body"
                ref={textRef}
              >
                <Markdown>
                  {project.description ??
                    "There's no description for this project just yet..."}
                </Markdown>
              </div>
              <ButtonNew
                variant="ghost"
                theme="primary"
                className={cx("max-w-max", { hidden: !showButton })}
                size="xs"
                onClick={toggleExpansion}
                data-testid={
                  expanded
                    ? "what-i-did-less-details-btn"
                    : "what-i-did-more-details-btn"
                }
              >
                {expanded ? <Icon.MinusCircle /> : <Icon.PlusCircle />}
                {expanded ? "Hide" : "More"} Details
              </ButtonNew>
              <div className="mt-4 flex justify-between gap-9">
                <LabeledMappedChips
                  title="Deliverables & Skills"
                  values={[
                    ...(project.deliverables ?? []),
                    ...(project.skills ?? []),
                  ]}
                  hideWhenEmpty
                />

                <LabeledMappedChips
                  title="Tags"
                  values={project.tags ?? []}
                  hideWhenEmpty
                />
              </div>
            </div>
            <div className="grid auto-rows-max gap-4">
              {project.company && (
                <section className="grid gap-2">
                  <Typography weight="medium" className="text-carbon-400">
                    Company
                  </Typography>
                  <Typography
                    size="xl"
                    weight="medium"
                    className="text-base-black"
                  >
                    {project.company}
                  </Typography>
                </section>
              )}

              {project.role != null && (
                <section className="grid gap-2">
                  <Typography weight="medium" className="text-carbon-400">
                    My Role
                  </Typography>
                  <Typography
                    size="xl"
                    weight="medium"
                    className="text-base-black"
                  >
                    {project.role}
                  </Typography>
                </section>
              )}

              {project.industry != null && (
                <section
                  className={cx("grid gap-2", {
                    hidden: !project.industry,
                  })}
                >
                  <Typography weight="medium" className="text-carbon-400">
                    Industry
                  </Typography>
                  <Typography
                    size="xl"
                    weight="medium"
                    className="text-base-black"
                  >
                    {project.industry}
                  </Typography>
                </section>
              )}

              {project.year != null && (
                <section className="grid gap-2">
                  <Typography weight="medium" className="text-carbon-400">
                    Year
                  </Typography>
                  <Typography
                    size="xl"
                    weight="medium"
                    className="text-base-black"
                  >
                    {project.year}
                  </Typography>
                </section>
              )}
            </div>
          </div>
        </section>

        {renderAssets(project.assets)}
      </div>
    );
  },
});
