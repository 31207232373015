import { Chip, Icon, Typography } from "@mg/dali/src";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import * as Tooltip from "@radix-ui/react-tooltip";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
// eslint-disable-next-line import/named
import { Link, useNavigate, useSearch } from "@tanstack/react-router";
import cx from "classnames";
import { type KeyboardEventHandler, useState, useRef, useEffect } from "react";
import { z } from "zod";

import { AdvancedFiltersDialog } from "../routes/network/components/AdvancedFiltersDialog";
import { useAppSelector } from "../utils/hooks";
import {
  type SearchSuggestion,
  useNetworkSearch,
} from "../utils/hooks/useNetworkSearch";
import { searchParser } from "../utils/parsers/search";

export type NetworkSearch = Omit<z.infer<typeof networkSearchParser>, "t">;

const networkSearchParser = searchParser.extend({
  specialties: z.array(z.string()).optional().default([]),
  skills: z.array(z.string()).optional().default([]),
  deliverables: z.array(z.string()).optional().default([]),
  tools: z.array(z.string()).optional().default([]),
  tags: z.array(z.string()).optional().default([]),
  timezone: z.array(z.string()).optional().default([]),
  level: z.array(z.number()).optional().default([]),
  brands: z.array(z.string()).optional().default([]),
  names: z.array(z.string()).optional().default([]),
  queries: z.array(z.string()).optional().default([]),
});

const iconMap = {
  skills: <Icon.Lightning />,
  brands: <Icon.Buildings />,
  names: <Icon.IdentificationCard />,
  tools: <Icon.Toolbox />,
  deliverables: <Icon.Package />,
  specialties: <Icon.Sparkle />,
  tags: <Icon.Tag />,
};

export function SearchAndFilterBar() {
  const user = useAppSelector((state) => state.auth.value);
  const navigate = useNavigate();
  // @ts-expect-error TS2339: Incorrectly inheriting types from search.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, port, proj, ...search } = useSearch({ strict: false });
  const { networkMutation, suggestedTerms, fuse } = useNetworkSearch();

  const [query, setQuery] = useState("");
  const [filtersDialogOpen, setFiltersDialogOpen] = useState(false);
  const [renderedTerms, setRenderedTerms] = useState<SearchSuggestion[]>([]);
  const optionsRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const enterpriseName = user?.company ?? user?.enterpriseName ?? "network";
  const possibleTypos = renderedTerms.filter((s) => (s.score ?? 0) === 0.2);

  function handleChangeFilter(
    key: keyof NetworkSearch,
    value: string | string[],
  ) {
    navigate({
      replace: true,
      search(prev) {
        if (Array.isArray(value) && value.length > 0) {
          // @ts-expect-error TS2399: Incorrect params.
          delete prev.query;
          // @ts-expect-error TS7053: Incorrect params.
          prev[key] = value;
        } else {
          if ((Array.isArray(value) && value.length === 0) || value == null) {
            delete prev[key];
          } else {
            // @ts-expect-error TS7053: Incorrect params.
            prev[key] = value;
          }
        }
        return prev;
      },
    });
    inputRef.current?.blur();
  }

  const handleEnterKey: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.code === "Enter") {
      setQuery("");
      inputRef.current?.blur();
      return handleChangeFilter("queries", [...(search.queries ?? []), query]);
    }
  };

  useEffect(() => {
    if (query != null && query.length > 0) {
      setRenderedTerms(
        fuse.search(query).map((r) => ({ ...r.item, score: r.score })),
      );
    } else {
      setRenderedTerms(suggestedTerms);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, suggestedTerms, fuse]);

  // accessibility controls
  useEffect(() => {
    const searchInput = document.getElementById("network-search-input");
    const buttons =
      optionsRef.current?.querySelectorAll("button") ??
      ([] as unknown as NodeListOf<HTMLButtonElement>);

    function searchInputKeydown(e: KeyboardEvent) {
      if (e.key === "ArrowDown") {
        e.preventDefault();
        // If input is focused and ArrowDown is pressed, move focus to the first button
        if (query.length === 0) {
          buttons[1].focus();
        } else {
          buttons[0].focus();
        }
      }
    }

    searchInput?.addEventListener("keydown", searchInputKeydown);

    function buttonKeydown(index: number) {
      return function (e: KeyboardEvent) {
        if (e.code === "ArrowUp") {
          e.preventDefault();
          // If the first button is focused and ArrowUp is pressed, move focus to the last button
          if (index === 0) {
            buttons[buttons.length - 1].focus();
          } else {
            // Move focus to the previous button
            if (query.length === 0 && index === 1) {
              buttons[buttons.length - 1].focus();
            } else {
              buttons[index - 1].focus();
            }
          }
        } else if (e.code === "ArrowDown") {
          e.preventDefault();
          // If the last button is focused and ArrowDown is pressed, move focus to the first button
          if (index === buttons.length - 1) {
            if (query.length === 0) {
              buttons[1].focus();
            } else {
              buttons[0].focus();
            }
          } else {
            // Move focus to the next button
            buttons[index + 1].focus();
          }
        }
      };
    }

    buttons.forEach((button, index) => {
      button.addEventListener("keydown", buttonKeydown(index));
    });

    return () => {
      searchInput?.removeEventListener("keydown", searchInputKeydown);
      buttons.forEach((button, index) => {
        button.removeEventListener("keydown", buttonKeydown(index));
      });
    };
  }, [renderedTerms, query.length]);

  return (
    <section className="grid gap-4 border-b border-b-carbon-100 bg-base-white px-6 py-4">
      <div className="flex gap-4">
        <div className="group relative flex-1">
          <label
            htmlFor="network-search"
            className="relative block rounded-full border border-base-black transition-all focus-within:ring-1 focus-within:ring-base-black focus-within:ring-offset-0"
            aria-labelledby="label-name"
          >
            <VisuallyHidden.Root id="label-name">
              Search network
            </VisuallyHidden.Root>

            <Icon.MagnifyingGlass className="absolute left-4 top-1/2 -translate-y-1/2" />
            <input
              ref={inputRef}
              type="search"
              value={query}
              onInput={({ target }) =>
                setQuery((target as HTMLInputElement).value)
              }
              onKeyDown={handleEnterKey}
              id="network-search-input"
              name="network-search-input"
              className="w-full rounded-full border-0 bg-base-white py-2 pl-12 pr-3 font-national2 text-base font-normal outline-transparent"
              placeholder={`Search ${enterpriseName} for creatives you need`}
            />
          </label>

          <ScrollArea.Root
            type="auto"
            className="!absolute top-[calc(100%_+_0.5rem)] z-10 hidden max-h-72 w-96 max-w-full overflow-hidden rounded-lg bg-base-white shadow-xl [word-break:break-word] group-focus-within:grid"
            ref={optionsRef}
          >
            <ScrollArea.Viewport className="max-h-60 w-96 rounded-lg">
              <button
                className={cx("w-full px-4 py-1 text-left hover:bg-carbon-50", {
                  hidden: query == null || query.length < 1,
                })}
                onClick={() => {
                  setQuery("");
                  handleChangeFilter("queries", [
                    ...(search.queries ?? []),
                    query,
                  ]);
                }}
              >
                <Typography className="italic">
                  Search &ldquo;{query}&rdquo;
                </Typography>
              </button>
              {renderedTerms.map((result) => {
                const searches = Object.values(search).flatMap((e) => e);

                return (
                  <button
                    key={result.key}
                    className={cx(
                      "flex w-full items-center gap-2 px-4 py-1 text-left hover:bg-carbon-50",
                      {
                        hidden:
                          searches.includes(result.value) ||
                          (result.score ?? 0) > 0.1,
                      },
                    )}
                    onClick={() => {
                      setQuery("");
                      handleChangeFilter("queries", [
                        ...(search.queries ?? []),
                        result.value,
                      ]);
                    }}
                  >
                    {/* @ts-expect-error TS7053: Incorrectly inheriting types from search. */}
                    {iconMap[result.type]}
                    <Typography>{result.value}</Typography>
                  </button>
                );
              })}

              <Typography
                className={cx("mt-2 px-4", {
                  hidden: query === "" || possibleTypos.length === 0,
                })}
                weight="medium"
              >
                Did you mean...
              </Typography>
              {possibleTypos.slice(0, 3).map((result) => {
                const searches = Object.values(search).flatMap((e) => e);

                return (
                  <button
                    key={result.key}
                    className={cx(
                      "flex w-full items-center gap-2 px-4 py-1 text-left hover:bg-carbon-50",
                      {
                        hidden: searches.includes(result.value),
                      },
                    )}
                    onClick={() => {
                      setQuery("");
                      handleChangeFilter("queries", [
                        ...(search.queries ?? []),
                        result.value,
                      ]);
                    }}
                  >
                    {/* @ts-expect-error TS7053: Incorrectly inheriting types from search. */}
                    {iconMap[result.type]}
                    <Typography>{result.value}</Typography>
                  </button>
                );
              })}
            </ScrollArea.Viewport>

            <ScrollArea.Scrollbar
              className="flex touch-none select-none bg-carbon-50 p-0.5 transition-colors duration-[160ms] ease-out hover:bg-carbon-100 data-[orientation=horizontal]:h-2.5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col"
              orientation="vertical"
            >
              <ScrollArea.Thumb className="relative flex-1 rounded-[10px] bg-carbon-500 before:absolute before:left-1/2 before:top-1/2 before:size-full before:min-h-[44px] before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-['']" />
            </ScrollArea.Scrollbar>
            <ScrollArea.Scrollbar
              className="hover:bg-blackA5 flex touch-none select-none bg-carbon-50 p-0.5 transition-colors duration-[160ms] ease-out data-[orientation=horizontal]:h-2.5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col"
              orientation="horizontal"
            >
              <ScrollArea.Thumb className="relative flex-1 rounded-[10px] bg-carbon-500 before:absolute before:left-1/2 before:top-1/2 before:size-full before:min-h-[44px] before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-['']" />
            </ScrollArea.Scrollbar>
            <ScrollArea.Corner className="bg-carbon-100" />
          </ScrollArea.Root>
        </div>

        <Tooltip.Provider>
          <Tooltip.Root delayDuration={50}>
            <Tooltip.Trigger asChild>
              <button
                onClick={() => setFiltersDialogOpen(true)}
                className="flex aspect-square h-10 items-center justify-center rounded-full border border-base-black"
              >
                <Icon.Sliders className="rotate-90" size={24} />
              </button>
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content
                className="z-10 select-none rounded-[4px] bg-carbon-50 px-4 py-2.5 text-vermillion-600 shadow-lg will-change-[transform,opacity] data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade"
                sideOffset={5}
                align="end"
                side="bottom"
              >
                <Typography>Filters</Typography>
                <Tooltip.Arrow className="fill-carbon-50" />
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>

        <AdvancedFiltersDialog
          open={filtersDialogOpen}
          onOpenChange={setFiltersDialogOpen}
          timezones={networkMutation.data?.filters.timezone ?? []}
        />
      </div>

      <div
        className={cx("flex flex-wrap items-center gap-2", {
          hidden:
            Object.values(search)
              .flatMap((e) => e)
              .filter((e) => !!e).length === 0,
        })}
      >
        <Typography className="text-carbon-800">Active Filters:</Typography>

        {Object.keys(search).flatMap((key) => {
          // @ts-expect-error TS2339: Incorrectly inheriting types from search.
          return Array.isArray(search[key])
            ? // @ts-expect-error TS2339: Incorrectly inheriting types from search.
              search[key].map((term: string | number) => (
                <Chip
                  key={`.${key}.${term}`}
                  label={
                    key === "level"
                      ? "$".repeat(term as unknown as number)
                      : (term as string)
                  }
                  variant="carbon-light"
                  className="capitalize"
                  trailingIcon={
                    <button
                      onClick={() =>
                        handleChangeFilter(
                          key as keyof NetworkSearch,
                          // @ts-expect-error TS2339: Incorrectly inheriting types from search.
                          search[key].filter(
                            (val: string | number) => val !== term,
                          ),
                        )
                      }
                    >
                      <Icon.XCircle />
                    </button>
                  }
                />
              ))
            : null;
        })}

        <Link search={{}}>
          <Typography>Clear all</Typography>
        </Link>
      </div>
    </section>
  );
}
