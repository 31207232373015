import { useMutation, useQuery } from "@tanstack/react-query";

import {
  getFolderDownloadUrl,
  getFolderTree,
  getShareFolderLink,
  shareFolder,
} from "../../services/folders";

export function useFolderTree() {
  return useQuery({
    queryKey: ["folders", "tree"],
    queryFn: getFolderTree,
  });
}

export function useShareFolderLink() {
  return useMutation({
    mutationKey: ["folders", "share", "link"],
    mutationFn: getShareFolderLink,
  });
}

export function useShareFolder() {
  return useMutation({
    mutationKey: ["folders", "share"],
    mutationFn: shareFolder,
  });
}

export function useDownloadFolderLink() {
  return useMutation({
    mutationKey: ["folders", "download"],
    mutationFn: getFolderDownloadUrl,
  });
}
