import {
  type BaseTicketMessage,
  type GetUsersResponse,
} from "@mg/schemas/src/christo/catalyst";

import { Markdown } from "../../../../components/Markdown";
import { getAiRuleMap } from "../../../../utils/selections";
import { escapeRegex } from "../../../../utils/tldraw/handlers";

interface Props {
  messageContent: string;
  message?: BaseTicketMessage;
  rule?: string;
  users: GetUsersResponse | undefined;
  mentions?: string[];
}

export const MessageComponent: React.FC<Props> = ({
  message,
  messageContent,
  users,
  mentions,
  rule,
}: Props) => {
  const aiRuleMap = getAiRuleMap();
  const messageMentions = mentions ?? message?.mentions ?? [];
  const mentionedUsers = messageMentions?.map((m) =>
    users?.find((u) => u._id === m),
  );

  const processMessageContent = (content: string) => {
    const emails = mentionedUsers
      ?.map((u) => u?.email)
      .filter(Boolean) as string[];
    if (!emails) return content;
    const mentionRegex = new RegExp(
      `(^|\\W)@(${emails?.map(escapeRegex).join("|")})\\b`,
      "gi",
    );

    let match;
    const elements = [];
    let lastIndex = 0;

    while ((match = mentionRegex.exec(content)) !== null) {
      const mention = match[2];
      const mentionIndex = match.index;

      // Add the text before the mention
      if (mentionIndex > lastIndex) {
        elements.push(content.slice(lastIndex, mentionIndex));
      }

      // Check if the mention matches any user name
      const user = mentionedUsers?.find(
        (u) => u?.email?.toLowerCase() === mention.toLowerCase(),
      );
      if (user) {
        // Add the mention as a mailto link if the user is found
        // Add a non-breaking space to prevent underline connection between mentions
        if (mentionIndex > 0) {
          elements.push(" @");
        } else {
          elements.push("@");
        }
        elements.push(
          `[${user.name?.replace(/\]/g, "\\]")}](mailto:${user.email?.replace(
            /\)/g,
            "\\)",
          )})`,
        );
      } else {
        // If no matching user, add the mention as plain text
        elements.push(`@${mention}`);
      }

      lastIndex = mentionIndex + match[0].length;
    }

    // Add any remaining text after the last mention
    if (lastIndex < content.length) {
      elements.push(content.slice(lastIndex));
    }

    return `${renderAiRule()}${elements.join("")}`;
  };

  const processedContent = processMessageContent(messageContent);

  function renderAiRule() {
    if (rule == null || aiRuleMap[rule] == null) {
      return "";
    }

    return `**${aiRuleMap[rule]}:** `;
  }

  return <Markdown>{processedContent}</Markdown>;
};
