import { z } from "zod";

import { assetSchema } from "./asset";
import { conceptRatingSchema, conceptValueSchema } from "./concepts";
import { modelNamesSchema, subReferenceTypesSchema } from "./consts";
import { enterpriseSchema } from "./enterprise";

export enum UserPermission {
  EDIT_GIG = "edit-gig",
  MATCH_DESIGNER = "match",
  ADD_CLIENT = "add-client",
  ADD_DESIGNER = "add-designer",
  EDIT_DESIGNER = "edit-designer",
  MARK_REPORT_PAID = "mark-report-paid",
  PAY_REPORT = "pay-report",

  // CCs
  CAN_IMPERSONATE = "can-impersonate",
  ENTERPRISE_ADMIN = "enterprise-admin",

  // Creative Connect
  CONNECT_TEAM = "enterprise-team",
  CONNECT_GUEST = "enterprise-guest",

  // Creative Catalyst
  CATALYST_MANAGE_ASSETS = "catalyst-manage-assets",
  CATALYST_MANAGE_TEMPLATES = "catalyst-manage-templates",
  CATALYST_MANAGE_TICKETS = "catalyst-manage-tickets",
  CATALYST_REVIEW_TICKETS = "catalyst-review-tickets",
  CATALYST_READ_TICKETS = "catalyst-read-tickets",
}

export enum UserType {
  client = "client",
  designer = "designer",
  empath = "empath",
  rebel = "rebel",
}

export enum Level {
  INITIAL = 2,
  COMMUNITY = 3,
  SENIOR = 4,
  EXPERT = 5,
}

export const levelSchema = z.nativeEnum(Level);

export enum EmailStatus {
  VALID = "valid",
  INVALID = "invalid",
  UNKNOWN = "unknown",
}

export const emailStatusSchema = z.nativeEnum(EmailStatus);

export enum Status {
  // Got invited
  INVITED = "invited",
  // Completed step 0 in the onboarding
  HERO_ADDED = "hero-added",
  // Completed the onboarding
  ONBOARDED = "onboarded",
  // Added at least two projects (highlights)
  VETTABLE = "vettable",
  // Are assigned a level
  SEARCHABLE = "searchable",
}

export const statusSchema = z.nativeEnum(Status);

export enum NotificationType {
  NEW_CREATIVE_LIST = "new-creative-list",
  NEW_GIG_INTERVIEW = "new-gig-interview",
  NEW_GIG_ACTIVE = "new-gig-active",
  NEW_GIG_PENDING = "new-gig-pending",

  // Catalyst
  TICKET_ADD_REVIEWER = "ticket-add-reviewer",
  TICKET_REVIEW = "ticket-review",
  TICKET_APPROVED = "ticket-approved",
  TICKET_NEW_MESSAGE = "ticket-new-message",
  TICKET_NEW_REPLY_MESSAGE = "ticket-new-reply-message",
  TICKET_ADD_REVISION_BOARD = "ticket-add-revision-board",
  TICKET_ADD_CREATIVES = "ticket-add-creatives",
  TICKET_ASSIGN_CREATIVES = "ticket-assign-creatives",
  TICKET_BRIEF_INVALID = "ticket-brief-invalid",
  TICKET_MENTION = "ticket-mention",
}

export const notificationTypeSchema = z.nativeEnum(NotificationType);

export enum Availability {
  AVAILABLE_FULLTIME = "available-fulltime",
  AVAILABLE_PARTTIME = "available-parttime",
  UNAVAILABLE_BOOKED = "unavailable-booked",
  UNAVAILABLE_INACTIVE = "unavailable-inactive",
}

export const availabilitySchema = z.nativeEnum(Availability);

export enum EnterpriseProfileType {
  MEANINGFUL_GIGS = "meaningful-gigs",
  ADMIN = "admin",
  OPS = "ops",
  CATALYST_MARKETING = "marketing",
  CATALYST_CREATIVE = "creative",
  CATALYST_REQUESTER = "requester",
  CATALYST_AI = "ai",
  CONNECT_AGENCY = "agency",
  CONNECT_FREELANCER = "freelancer",
  CONNECT_EMPLOYEE = "employee",
  CONNECT_GUEST = "guest",
}

export const enterpriseProfileTypeSchema = z.nativeEnum(EnterpriseProfileType);

export const EnterpriseProfileTypePermissions: Record<
  string,
  UserPermission[]
> = {
  [EnterpriseProfileType.MEANINGFUL_GIGS]: [
    UserPermission.CAN_IMPERSONATE,
    UserPermission.ENTERPRISE_ADMIN,
    UserPermission.CATALYST_MANAGE_ASSETS,
    UserPermission.CATALYST_MANAGE_TEMPLATES,
    UserPermission.CATALYST_MANAGE_TICKETS,
    UserPermission.CATALYST_REVIEW_TICKETS,
    UserPermission.CATALYST_READ_TICKETS,
  ],
  [EnterpriseProfileType.ADMIN]: [
    UserPermission.ENTERPRISE_ADMIN,
    UserPermission.CATALYST_MANAGE_ASSETS,
    UserPermission.CATALYST_MANAGE_TEMPLATES,
    UserPermission.CATALYST_MANAGE_TICKETS,
    UserPermission.CATALYST_REVIEW_TICKETS,
    UserPermission.CATALYST_READ_TICKETS,
  ],
  [EnterpriseProfileType.OPS]: [
    UserPermission.CATALYST_MANAGE_ASSETS,
    UserPermission.CATALYST_MANAGE_TEMPLATES,
    UserPermission.CATALYST_MANAGE_TICKETS,
    UserPermission.CATALYST_REVIEW_TICKETS,
    UserPermission.CATALYST_READ_TICKETS,
  ],
  [EnterpriseProfileType.CATALYST_MARKETING]: [
    UserPermission.CATALYST_MANAGE_TICKETS,
    UserPermission.CATALYST_REVIEW_TICKETS,
    UserPermission.CATALYST_READ_TICKETS,
  ],
  [EnterpriseProfileType.CATALYST_CREATIVE]: [
    UserPermission.CATALYST_READ_TICKETS,
  ],
};

export const onboardingSchema = z.object({
  firstName: z.string(),
  step: z.coerce.number(),
  weeklyHours: z.coerce.number(),
});

export type Onboarding = z.infer<typeof onboardingSchema>;

export const referenceSchema = z.object({
  name: z.string(),
  company: z.string(),
  title: z.string(),
  text: z.string(),
  companySize: z.string().optional(),
  isVerified: z.boolean().optional(),
  rating: z.coerce.number().optional(),
  logo: z.string().optional(),
});

export type Reference = z.infer<typeof referenceSchema>;

export const highlightSchema = z.object({
  _id: z.string().optional(),
  title: z.string(),
  description: z.string().optional(),
  year: z.coerce.number().optional(),
  companySize: z.string().optional(),
  tags: z.array(z.string()).optional(),
  skills: z.array(conceptValueSchema).optional(),
  deliverables: z.array(conceptValueSchema).optional(),
  company: z.string().optional(),
  role: z.string().optional(),
  tools: z.array(conceptValueSchema).optional(),
  industry: conceptValueSchema.optional(),
  team: z.string().optional(),
  assets: z.array(assetSchema),
});

export type Highlight = z.infer<typeof highlightSchema>;

export const notificationMessageSchema = z.object({
  key: z.enum(["message", "ticket", "user"]),
  value: z.string().optional(),
});

export type NotificationMessage = z.infer<typeof notificationMessageSchema>;

export const notificationSchema = z.object({
  type: notificationTypeSchema,
  reference: z.union([z.string(), z.any()]).optional(),
  referenceType: modelNamesSchema.optional(),
  subReference: z.string().optional(),
  subReferenceType: subReferenceTypesSchema.optional(),
  message: z.string().optional(),
  messageArray: notificationMessageSchema.array().optional(),
  ctaLink: z.string().optional(),
  isRead: z.boolean().optional(),
  createdAt: z.union([z.date(), z.string()]),
});

export type Notification = z.infer<typeof notificationSchema>;

export const workingHoursSchema = z.object({
  startTime: z.coerce.number(),
  endTime: z.coerce.number(),
});

export type WorkingHours = z.infer<typeof workingHoursSchema>;

// @deprecated
// TODO: REMOVE THIS SCHEMA, WE MUST EXTEND FROM THE USER SCHEMA
export const designerPortfolioSchema = z.object({
  _id: z.string(),
  name: z.string(),
  hourlyRate: z.coerce.number(),
  avatar: z.string().optional(),
  website: z.string(),
  availability: availabilitySchema,
  isAvailable: z.boolean(),
  skills: z.array(conceptValueSchema),
  specialties: z.array(conceptValueSchema),
  highlights: z.array(highlightSchema),
  hero: assetSchema,
  level: levelSchema.optional(),
  score: z.coerce.number().optional(),
  references: z.array(referenceSchema),
});

export type DesignerPortfolio = z.infer<typeof designerPortfolioSchema>;

export const specialtyHeroesSchema = assetSchema.extend({
  isPrimary: z.boolean(),
  specialty: conceptValueSchema,
  tags: z.array(z.string()).min(1),
});

export type SpecialtyHero = z.infer<typeof specialtyHeroesSchema>;

export const searchFiltersSchema = z.object({
  specialties: z.array(conceptValueSchema).optional(),
  skills: z.array(conceptValueSchema).optional(),
  tools: z.array(conceptValueSchema).optional(),
  industries: z.array(conceptValueSchema).optional(),
  availability: z.array(availabilitySchema).optional(),
  hourlyRate: z.array(z.coerce.number()).optional(),
  level: z.array(levelSchema).optional(),
});

export const searchHistorySchema = z.object({
  _id: z.string().optional(),
  name: z.string(),
  filters: searchFiltersSchema.optional(),
  query: z.string().optional(),
  createdAt: z.coerce.date().optional(),
});

export type SearchHistory = z.infer<typeof searchHistorySchema>;

export enum EnterpriseOnboardingStatus {
  INVITED = "invited",
  ONBOARDED = "onboarded",
  IMPORTED = "imported",
  ARCHIVED = "archived",
  RECOMMENDED = "recommended",
}

export enum UploadStatus {
  PENDING = "pending",
  PROCESSING = "processing",
  ERROR = "error",
  SUCCESS = "success",
}

export const uploadStatusSchema = z.nativeEnum(UploadStatus);

export enum UploadType {
  LINKEDIN_CSV = "linkedin-csv",
  UPWORK_LIST = "upwork-list",
  CUSTOM_CSV = "custom-csv",
  EMAIL = "email",
}

export const uploadTypeSchema = z.nativeEnum(UploadType);

export const uploadSchema = z.object({
  _id: z.string().optional(),
  type: uploadTypeSchema,
  status: uploadStatusSchema,
  link: z.string().optional(),
  text: z.string().optional(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
});

export type Upload = z.infer<typeof uploadSchema>;

export enum RecommendationSignalType {
  RELATED_BRANDS = "related-brands",
  PAST_CONNECTIONS = "past-connections",
  SECOND_DEGREE_CONNECTIONS = "second-degree-connections",
  INDUSTRY_OVERLAP = "industry-overlap",
}

export const recommendationSignalSchema = z.object({
  userId: z.string().optional(),
  type: z.nativeEnum(RecommendationSignalType),
  companyName: z.string().optional(),
  projectName: z.string().optional(),
});

export type RecommendationSignal = z.infer<typeof recommendationSignalSchema>;

export const recommendationMetaSchema = z.object({
  // linkedInProjects: z.array(z.string()).optional(),
  // linkedInJobExperience: z
  //   .array(
  //     z.object({
  //       company: z.string(),
  //       title: z.string(),
  //       startDate: z.date(),
  //       endDate: z.date(),
  //       description: z.string(),
  //     }),
  //   )
  //   .optional(),
  linkedinData: z.string().optional(),
});

export type RecommendationMeta = z.infer<typeof recommendationMetaSchema>;

export const enterpriseProfileSchema = z.object({
  enterprise: z.string(),
  referredBy: z.string().optional(),
  title: z.string().optional(),
  type: enterpriseProfileTypeSchema,
  trustScore: z.coerce.number().min(0).max(5).optional(),

  // fields to mix taxonomies - probably to be removed
  skills: z.array(conceptRatingSchema).optional(),
  industries: z.array(conceptRatingSchema).optional(),
  softSkills: z.array(conceptRatingSchema).optional(),
  tools: z.array(conceptRatingSchema).optional(),

  // pipeline only field - probably to be removed
  note: z.string().optional(),

  // fix for any extra data
  tags: z.array(z.string()).optional(),
  workConnectionRequestsSent: z
    .array(z.union([z.string(), designerPortfolioSchema]))
    .optional(),
  workedWith: z
    .array(z.union([z.string(), designerPortfolioSchema]))
    .optional(),
  createdAt: z.date().optional(),
  uploads: z.array(uploadSchema).optional(),

  // TODO: Migrate Onboarding to Status
  onboarding: z.nativeEnum(EnterpriseOnboardingStatus).optional(),
  experienceAreas: z.array(z.string()).optional(),

  // Recommendation
  recommendationMeta: recommendationMetaSchema.optional(),
  recommendationSignals: z.array(recommendationSignalSchema).optional(),
});

export type EnterpriseProfile = z.infer<typeof enterpriseProfileSchema>;

export const positionSchema = z.object({
  startEndDate: z.object({
    start: z.object({
      month: z.coerce.number(),
      year: z.coerce.number(),
    }),
    end: z
      .object({
        month: z.coerce.number(),
        year: z.coerce.number(),
      })
      .optional(),
  }),
  title: z.string(),
  contractType: z.string().optional(),
  description: z.string().optional(),
  companyName: z.string(),
  companyLocation: z.string().optional(),
  companyLogo: z.string().optional(),
  linkedInUrl: z.string(),
  linkedInId: z.string(),
});

export type Position = z.infer<typeof positionSchema>;

export const userSchema = z.object({
  _id: z.string().optional(),
  type: z.string(), //z.nativeEnum(UserType),
  email: z.string().trim().email(),
  permissions: z.array(z.string()).optional(),
  timezone: z.string().optional(),
  namePronunciation: z.string().optional(),
  name: z.string().trim().optional(),
  avatar: z.string().optional(),
  company: z.coerce.string().optional(),
  enterprise: z.union([z.string(), enterpriseSchema]).optional(),
  enterpriseProfiles: z.array(enterpriseProfileSchema).optional(),
  title: conceptValueSchema.optional(),
  location: z.string().trim().optional(),
  weeklyHours: z.coerce.number().optional(),
  bio: z.string().optional(),
  skills: z.array(conceptValueSchema).optional(),
  deliverables: z.array(conceptValueSchema).optional(),
  website: z.string().trim().optional(),
  password: z.string().optional(),
  onboarding: onboardingSchema.optional(),
  invitedAt: z.date().optional(),
  registeredAt: z.date().optional(),
  onboardedAt: z.date().optional(),
  lastActiveAt: z.date().optional(),
  isEnterprise: z.boolean().optional(),
  companies: z.array(z.string()).optional(),
  industries: z.array(conceptValueSchema).optional(),
  softSkills: z.array(conceptValueSchema).optional(),
  yearStarted: z.coerce.number().optional(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
  hero: assetSchema.optional(),
  level: levelSchema.optional(),
  hourlyRate: z.coerce.number().optional(),
  stage: z.string().optional(),
  status: statusSchema.optional(),
  references: z.array(referenceSchema).optional(),
  isPortfolioActive: z.boolean().optional(),
  isOnboardingComplete: z.boolean().optional(),
  whatsapp: z.string().optional(),
  availableHours: z.coerce.number().optional(),
  linkedIn: z.string().optional(),
  yearsExperience: z.coerce.number().optional(),
  topClients: z.string().optional(), // Deprecated
  tools: z.array(conceptValueSchema).optional(),
  specialties: z.array(conceptValueSchema).optional(),
  onboardingStep: z.coerce.number().optional(),
  availability: availabilitySchema.optional(),
  highlights: z.array(highlightSchema).optional(),
  notifications: z.array(notificationSchema).optional(),
  pronoun: z.string().optional(),
  preferredName: z.string().optional(),
  searchHistory: z.array(searchHistorySchema).optional(),
  socialLinks: z.array(z.string()).optional(),
  specialtyHeroes: z.array(specialtyHeroesSchema).optional(),
  conceptRatings: z.array(conceptRatingSchema).optional(),
  languages: z.array(conceptValueSchema).optional(),
  employmentHistory: z.string().optional(),
  volunteerExperience: z.string().optional(),
  education: z.string().optional(),
  certifications: z.string().optional(),
  professionalAffiliations: z.string().optional(),
  brands: z.array(z.string()).optional(),
  workingHours: z
    .object({
      startTime: z.coerce.number(),
      endTime: z.coerce.number(),
    })
    .optional(),
  positionHistory: z.array(positionSchema).optional(),
  archivedAt: z.coerce.date().optional(),
  archivedEmail: z.string().trim().email().optional(),
});

export type User = z.infer<typeof userSchema>;

export const userPartialSchema = userSchema.partial();

export type UserPartial = z.infer<typeof userPartialSchema>;
