import { z } from "zod";

import { AssistantWriteActions } from "../commons";

export enum AssistantActions {
  SUGGEST_ACTIONS = "suggest-actions",
  DISPLAY_OPTIONS = "display-options",
  SHARE_LIST = "share-list",
  ADD_TO_LIST = "add-to-list",
  SHARE_PROFILE = "share-profile",
  INVITE_CONTACT = "invite-contact",
  INVITE_PIPELINE_TO_NETWORK = "invite-pipeline-to-network",
  SELECT_PROFILES = "select-profiles",
  MESSAGE_CONTACTS = "message-contacts",
  ADD_TO_PIPELINE = "add-to-pipeline",
}
export const assistantActionsSchema = z.nativeEnum(AssistantActions);

export enum AssistantPages {
  PROFILE = "profile",
  LISTS = "lists",
  NETWORK = "network",
  PIPELINE = "pipeline",
}

export const assistantPagesSchema = z.nativeEnum(AssistantPages);

export const assistantDataSchema = z
  .object({
    profileId: z.string(),
    listId: z.string(),
    label: z.string(),
    isMultipleSelection: z.boolean(),
  })
  .partial();

export type AssistantData = z.infer<typeof assistantDataSchema>;

export const assistantContextSchema = z.object({
  page: assistantPagesSchema,
  context: z.object({
    action: assistantActionsSchema,
    data: assistantDataSchema,
  }),
});

export type AssistantContext = z.infer<typeof assistantContextSchema>;

export const assistantResponseSchema = z.object({
  page: assistantPagesSchema,
  context: z.object({
    action: assistantActionsSchema,
    data: z.object({
      options: z.array(assistantContextSchema),
    }),
  }),
});

export const assistantRequestSchema = z.object({
  body: assistantContextSchema,
});

export type AssistantRequest = z.infer<typeof assistantRequestSchema>;

export type AssistantResponse = z.infer<typeof assistantResponseSchema>;

export const asisstantOptionsSchema = z.object({
  regenerate: z.boolean().optional(),
  fieldName: z.string().optional(),
});

export type AssistantOptions = z.infer<typeof asisstantOptionsSchema>;

export const assistantWriteContextSchema = z.object({
  content: z.string().optional(),
  contextText: z.string().optional(),
  action: z.nativeEnum(AssistantWriteActions),
  options: asisstantOptionsSchema.optional(),
});

export type AssistantWriteContext = z.infer<typeof assistantWriteContextSchema>;

export const assistantWriteRequestSchema = z.object({
  body: assistantWriteContextSchema,
});

export type AssistantWriteRequest = z.infer<typeof assistantWriteRequestSchema>;

export const assistantWriteResponseSchema = z.object({
  content: z.string(),
});

export type AssistantWriteResponse = z.infer<
  typeof assistantWriteResponseSchema
>;
