import { z } from "zod";

import { conceptValueSchema } from "./concepts";

export enum FileStatus {
  Uploaded = "uploaded",
  Converting = "converting",
  Converted = "converted",
  PreparingAI = "preparing",
  Ready = "ready",
  Error = "error",
}

export const PendingReady = [
  FileStatus.Converted,
  FileStatus.Ready,
  FileStatus.Error,
];

export const AiReady = [FileStatus.Ready, FileStatus.Error];

const cropSchema = z.object({
  x: z.coerce.number().optional(),
  y: z.coerce.number().optional(),
  width: z.coerce.number().optional(),
  height: z.coerce.number().optional(),
});

const assetAttributesSchema = z.object({
  crop: cropSchema,
});

export type AssetAttributes = z.infer<typeof assetAttributesSchema>;

export const assetSchema = z.object({
  source: z.string(),
  type: z.enum(["image", "video", "file", "other", "assistant"]).optional(),
  attributes: assetAttributesSchema.optional(),
  tags: z.array(conceptValueSchema).optional(),
  description: z.string().optional(),
  x: z.coerce.number().optional(),
  y: z.coerce.number().optional(),
  height: z.coerce.number().optional(),
  width: z.coerce.number().optional(),

  // All converting / preprocessing fields
  fileId: z.string().optional(), // OpenAI File ID
  originalSource: z.string().optional(),
  convertedKey: z.string().optional(),
  originalKey: z.string().optional(),
  status: z.nativeEnum(FileStatus).default(FileStatus.Uploaded).optional(),
  extractedImages: z.array(z.string()).optional(),
  shapes: z.any().optional(),
  thumbnails: z.array(z.string()).optional(),
});

export type Asset = z.infer<typeof assetSchema>;
